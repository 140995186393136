// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor3";


import { Base_URL } from '../../Config/BaseUrlConfig'



import {getSuperProfileDetailBegin , getSuperProfileDetailSuccess , getSuperProfileDetailFailure} from './../ActionCreators'
import {editSuperProfileDetailBegin , editSuperProfileDetailSuccess , editSuperProfileDetailFailure } from './../ActionCreators'
import {editSuperProfilePasswordBegin , editSuperProfilePasswordSuccess , editSuperProfilePasswordFailure } from './../ActionCreators'
import {editSuperProfilePicBegin , editSuperProfilePicSuccess , editSuperProfilePicFailure } from './../ActionCreators'






export const editSuperProfileDetail = (session) => {

   return (dispatch) => {
       dispatch(editSuperProfileDetailBegin());

       axios.post(Base_URL + '/superAdmin/editSuperBasic', session)
           .then((res) => {
               // console.log(res.data)
               dispatch(editSuperProfileDetailSuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   alert("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(editSuperProfileDetailFailure(err.response.data));

               }
           })
   }
}

export const getSuperProfileDetail = (x) => {

   return (dispatch) => {
       dispatch(getSuperProfileDetailBegin());


       axios.get(Base_URL + '/superAdmin/getSuperProfile', {params : {id : x}})
           .then((res) => {
            //    console.log(res.data)
               dispatch(getSuperProfileDetailSuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   alert("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(getSuperProfileDetailFailure(err.response.data));

               }
           })
   }
}

export const editSuperProfilePassword = (session) => {

   return (dispatch) => {
       dispatch(editSuperProfilePasswordBegin());

       axios.post(Base_URL + '/superAdmin/editSuperPassword', session)
           .then((res) => {
               // console.log(res.data)
               dispatch(editSuperProfilePasswordSuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   alert("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(editSuperProfilePasswordFailure(err.response.data));

               }
           })
   }
}

export const editSuperProfilePic = (session) => {

    return (dispatch) => {
        dispatch(editSuperProfilePicBegin());
 
        axios.post(Base_URL + '/superAdmin/addSuperProfilePic', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editSuperProfilePicSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")
 
                }
                else {
                    dispatch(editSuperProfilePicFailure(err.response.data));
 
                }
            })
    }
 }