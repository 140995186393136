import React, {useState} from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment'
import {Avatar} from '@material-ui/core'
import { AlignCenter } from 'react-feather';

const shade1 = "#3b4e87"
const shade2 = "#7a8dc5"
const shade3 = "#bfbfbf"

const styles = StyleSheet.create({
   header:{
      flexDirection: 'row',
      width:"100%",

   },
   headerCol1:{
      flexDirection: 'column',
      width:"50%",
   },
   headerCol2:{
      flexDirection: 'column',
      width:"50%",
      justifyContent:"flex-end",
      padding:'2px'
   },
   
   logo: {
      width: 50,
      height: 50,
     borderWidth:1,
      marginRight:"10px"
  },
  container: {
   flexDirection: 'row',   
   alignItems: 'right',   
   textAlign: 'center',
   fontStyle: 'bold',   
},
numb: {
   width: '50%',                
},
head: {
   width: '15%', 
   borderWidth:2, 
   borderColor:shade3,  
   textAlign:'center' ,
   paddingTop:'2px',
   paddingBottom:'-2px'             
},
head2: {
   width: '15%', 
   borderLeftWidth:2, 
   borderLeftColor:shade3,               
   borderRightWidth:2, 
   borderRightColor:shade3,
   borderBottomWidth:2, 
   borderBottomColor:shade3,
   paddingTop:'2px',
   paddingBottom:'-2px'               
},
content: {
   width: '35%',  
   borderRightWidth:2, 
   borderRightColor:shade3,
   borderTopWidth:2, 
   borderTopColor:shade3,              
   borderBottomWidth:2, 
   borderBottomColor:shade3,  
   paddingTop:'2px',
   paddingBottom:'-2px'            
},
content2: {
   width: '35%',  
   borderRightWidth:2, 
   borderRightColor:shade3,               
   borderBottomWidth:2, 
   borderBottomColor:shade3,   
   paddingTop:'2px',
   paddingBottom:'-2px'           
},
});



const Header = ({data}) => {
   
  const logo_image  = data.logo && Array.isArray(data.logo) && data.logo.length ?  data.logo[0] : "";
  const fix_image = "http://164.52.223.247:9797/static/avatar.png"
  console.log(logo_image)
  console.log(data)
   return(
   <>
   <View style={styles.header}>
      <View style={styles.headerCol1}>
      <View style={{flexDirection:'row', marginBottom:'10px'}}>
         <Image style={styles.logo} src={logo_image!=="" && logo_image!==undefined && logo_image!==null ? logo_image : fix_image} />
         <Text style={{fontSize:18, lineHeight:1.8}}>{data.client_name}</Text>

      </View>
      <Text style={{fontSize:12, lineHeight:1.5}}>{data.client_address}</Text>
      <Text style={{fontSize:12, lineHeight:1.5}}>Phone:{data.client_mobile}</Text>
      <Text style={{fontSize:12, lineHeight:1.5}}>Email:{data.client_email}</Text>
      <Text style={{fontSize:12, lineHeight:1.5}}>GST:{data.client_gst}</Text>

      </View>
      <View style={styles.headerCol2}>
         <View style={{flexDirection:'row', textAlign:'right', justifyContent:'flex-end'}}>
            <Text style={{fontSize:24,fontWeight:"heavy", lineHeight:1.8, color:"#7a8dc5"}}>PURCHASE ORDER</Text>
         </View>
         <View style={styles.container}>

            <Text style={styles.numb}></Text>
            <Text style={styles.head}>Date</Text>
            <Text style={styles.content}>{moment(data.date).format("DD/MM/YYYY")}</Text>            
         </View>
         <View style={styles.container}>
            <Text style={styles.numb}></Text>
            <Text style={styles.head2}>PO#</Text>
            <Text style={styles.content2}>{data.order_id}</Text>            
         </View>
      </View>
   </View>
   </>
 )};
 
 export default Header;