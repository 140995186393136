import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  useTheme,  
  Typography,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';



const Accordion = withStyles({
  root: {
    border: '0px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)' : {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);



const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "default",
    height: 100,
    width: 100
  },
  differenceIcon: {
    color: "default"
  },
  differenceValue: {
    color: "default",
    marginRight: theme.spacing(1)
  },
  DashCard:{
    border:"1px solid #E36A26",
    background:"#FFEAE0"
  }
}));

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const TrafficByDevice = ({ className, dashdata, ...rest }) => {
  const classes = useStyles();


  const theme = useTheme();
 
   const DashBoardData = useSelector((state) =>
   state.DashBoard &&
   state.DashBoard.SubData &&
   Object.keys(state.DashBoard.SubData) &&
   Object.keys(state.DashBoard.SubData).length 
   ? state.DashBoard.SubData.pending
   : {}
   );
 
   console.log(DashBoardData)
   const [expanded, setExpanded] = React.useState("panel1");

   const handleChange = (panel) => (event, newExpanded) => {
     setExpanded(newExpanded ? panel : false);
   };
 
  return (
    <Card className={classes.DashCard}>
      <CardHeader title="Pending" style={{textAlign:"center", fontSize:12}}/>
      <Divider />
      
      
      <CardContent>

     {DashBoardData && Object.keys(DashBoardData) && Object.keys(DashBoardData).length ?
      <>

        {Object.keys(DashBoardData).map((data, i) => {
          return(

            <>
            <Accordion expanded={expanded === `panel+${i}`} onChange={handleChange(`panel+${i}`)}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />} >
                <Typography>
                  {data==="mat_delivery"?"Material Delivery" : data==="mat_ind" ? "Material Indent" : data==="mch_ind" ? "Machine Indent" : data==="mch_delivery"? "Machine Delivery" : "Other"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{flexDirection:"column"}}>
                
                {DashBoardData[data].map(detail => (
                  <>
                  <Typography>
                  Order ID : {detail.order_id}
                  </Typography>                      
                  <Divider />
                  </>
                ))}
              </AccordionDetails>
            </Accordion>
            </>
          )
        })}
      </>
      :<></>
     }
      </CardContent>
    </Card>

  );
};

TrafficByDevice.propTypes = {
  className: PropTypes.string
};

export default TrafficByDevice;
