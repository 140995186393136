import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const shade4 = "#000000"
const shade1 = "#3b4e87"
const shade2 = "#7a8dc5"
const shade3 = "#bfbfbf"
const shade5 = "#ffffff"


const styles = StyleSheet.create({
   bilSection:{
      flexDirection: 'row',
      width:"100%",
      justifyContent:'space-between',
      marginTop:"5%"
   },
   venderSection:{
      flexDirection: 'column',
      width:"45%",
   },
   shipSection:{
      flexDirection: 'column',
      width:"45%",
   },
   headingSection:{
      backgroundColor:shade1,
      color:shade5,
      width:"100%",
      paddingVertical:"2px",
      paddingLeft:"5%",
      fontWeight:"black"
      
   },
   detailSection:{
      color:shade4,
      width:"100%",
      paddingVertical:"5px",
      paddingLeft:"5px",
      fontWeight:"black"
      
   }
});



const Bill = ({data}) => (
   <>
   <View style={styles.bilSection}>
      <View style={styles.venderSection}>
         <View style={styles.headingSection}>
            <Text>VENDOR</Text>
         </View>
         <View style={styles.detailSection}>
            <Text>{data.name}</Text>
            <Text>{data.mobile}</Text>
            <Text>{data.address}</Text>
            <Text>{data.gst}</Text>
         </View>
      </View>
      <View style={styles.shipSection}>
         <View style={styles.headingSection}>
            <Text>SHIP TO</Text>
         </View>
         <View style={styles.detailSection}>
            <Text>{data.site_name}</Text>
            <Text>{data.client_mobile}</Text>
            <Text>{data.site_address}</Text>
         </View>
      </View>
   </View>
   </>
 );
 
 export default Bill;