import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  GridList,
  GridListTile,
  GridListTileBar,
  ListItemIcon,
  CardMedia,
} from "@material-ui/core";



import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";




const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const {state} = useLocation();
  const [expanded, setExpanded] = React.useState(false);



  const listData = (state.mat_details && Array.isArray(state.mat_details) && state.mat_details.length ? state.mat_details : []);

  const total = listData.map(item => parseFloat(item.amt)).reduce((accumulator, currentValue) => accumulator + currentValue , 0)
  const gst = listData.map(item => parseFloat((item.amt)*(item.gst_per/100))*(parseFloat(item.gst_per)/(100))).reduce((accumulator, currentValue) => accumulator + currentValue , 0)


  return (
    <>
      <Page className={classes.root} title="Material Delivery">
      <ToastContainer    />
        <Container maxWidth={false}>

        <Card sx={{ maxWidth: 345 }}>
      <CardHeader        
        title="Basic Details"
        subheader=""
      />
      
      <CardContent>
         <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
                Order ID : {state.order_id}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
                 Site Name : {state.site_name}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Supplier Name : {state.name}
               </Typography>
            </Grid>
            
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Supplier Mobile : {state.mobile}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Supplier Address : {state.address}
               </Typography>
            </Grid>
            
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Engineer Name : {state.eng_name}
               </Typography>
            </Grid>
            
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Engineer Mobile : {state.eng_mobile}
               </Typography>
            </Grid>
            
            
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Delivery Date : {moment(state.delivery_date).format("DD/MM/YYYY")}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Priority : {state.priority}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Accepted BY : {state.accepted_by}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
            Status :
             {state.status==="Delivered" ? 
             <>
             <Typography style={{color:"green"}} variant='overline'> Delivered</Typography>
             </>             
             :
             <>
             <Typography style={{color:"#E36A26"}} variant='overline'> Pending</Typography>
             </>}                                  
            </Grid>
            <Grid item md={3} xs={12}>
                    
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
                  
                    <Typography variant="body2" color="text.secondary" spacing={3}>Location :  
                      {state.lat}, {state.long}                 
                    </Typography>
                  
                   : 
                   <Typography variant="body2" color="text.secondary" spacing={3}>Location: </Typography>
                   }
                    
            </Grid>
         </Grid>
         
        
        
      </CardContent>            
    </Card>

    <Grid container spacing={3} style={{paddingTop:"20px"}}>
      <Grid item lg={4}>
        <Card>
            <Typography 
              style={{
                height:"50px", 
                backgroundColor:"#E36A26", 
                color:"#fff",                  
                padding:"5%", 
                textAlign:"center"
                }} 
                variant="h5">
              Vehicle Loaded
            </Typography>
            <CardMedia component="img" onClick={()=>{window.open(state.images[0])}} image={state.images[0]} height="140" alt="Image Is Not Available"/>
        </Card>
      </Grid>
      <Grid item lg={4}>
        <Card>
            <Typography style={{
              height:"50px", 
              backgroundColor:"#E36A26", 
              color:"#fff",                
              padding:"5%", 
              textAlign:"center"
              }} 
              variant="h5">
              Material Image
            </Typography>
            <CardMedia component="img" onClick={()=>{window.open(state.images[1])}} image={state.images[1]} height="140" alt="Image Is Not Available"/>
        </Card>
      </Grid>
      <Grid item lg={4}>
        <Card>
            <Typography style={{
              height:"50px", 
              backgroundColor:"#E36A26", 
              color:"#fff",                
              padding:"5%", 
              textAlign:"center"
              }} 
              variant="h5">
              Challan Image
            </Typography>
            <CardMedia component="img" onClick={()=>{window.open(state.images[2])}} image={state.images[2]} height="140" alt="Image Is Not Available"/>
        </Card>
      </Grid>
    </Grid>

          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Material Details"
      columns={[
        
        { title: 'SR. No.', render:data=>(data.tableData.id+1) },     
        { title: 'Material Name', field: 'mat_name',editable: "never", },     
        { title: 'Material Type', field: 'mat_type',editable: "never", },
        { title: 'Quantity', render:data=>(data.new_quan + " " + data.unit) },     
        { title: 'GST Percentage', field: 'gst_per',editable: "never", },
        { title: 'Amount', field: 'amt',editable: "never", },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                search:false,
                paging:false
              }}

    />
             
            </Card>
          </Box>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item lg={4} md={4} sm={6}>
              <Card style={{padding:"5%"}}>
                <Grid container spacing={4}>
                <Grid item justifyContent="flex-start">
                  <Typography style={{                                        
                   
                    }} 
                    variant="h5">
                    Sub Total :
                  </Typography>
                  <Typography style={{                                        
                   
                    }} 
                    variant="h5">
                    GST :
                  </Typography>
                  <Typography style={{                                        
                   
                    }} 
                    variant="h5">
                    Grand Total :
                  </Typography>

                </Grid>
                <Grid item  justifyContent="flex-end">
                  <Typography style={{                                        
                    textAlign:"end"
                    }} 
                    variant="h5">
                    {parseFloat(total).toFixed(2)}
                  </Typography>
                  <Typography style={{                                        
                    textAlign:"end"
                    }} 
                    variant="h5">
                    {parseFloat(gst).toFixed(2)}
                  </Typography>
                  <Typography style={{                                        
                    textAlign:"end"
                    }} 
                    variant="h5">
                    {parseFloat(total+gst).toFixed(2)}
                  </Typography>

                </Grid>
                </Grid>
              </Card>
            </Grid>

          </Grid>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
