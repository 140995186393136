import axios from "../Config/Interceptor3";

import { Base_URL } from '../Config/BaseUrlConfig'

import {SuperDashboardBegin , SuperDashboardSuccess , SuperDashboardFailure} from './ActionCreators'


export const SuperDashboard = (x) => {
   
    return (dispatch) => {
        dispatch(SuperDashboardBegin());

        axios.get(Base_URL + '/superadmin/getDashboard')
            .then((res) => {
                dispatch(SuperDashboardSuccess(res.data));
                // console.log("data======", res.data)
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(SuperDashboardFailure(err.response.data));

                }
            })
    }
}
