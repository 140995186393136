import {GET_MATERIAL_DELIVERY_LOADING , GET_MATERIAL_DELIVERY_SUCCESS , GET_MATERIAL_DELIVERY_FAILURE, CLEAN_MATERIAL_DELIVERY_DATA} from '../../Actions/Types';
import {DEL_MATERIAL_DELIVERY_LOADING , DEL_MATERIAL_DELIVERY_SUCCESS , DEL_MATERIAL_DELIVERY_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const MaterialDelivery = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getData:[{}],
            getError:{},
            delData:[{}],
            delError:{}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,                
                loading:false,                
                getData:[{}],
                getError:{},
                delData:[{}],
                delError:{}
            }
    
    
        case CLEAN_MATERIAL_DELIVERY_DATA:
            return {
                ...state,                
                loading:false,                
                getData : [{}],
                getError:{},
                delData:[{}],
                delError:{}
            }
    
        case GET_MATERIAL_DELIVERY_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_MATERIAL_DELIVERY_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
                
            }
        case GET_MATERIAL_DELIVERY_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                
            }   
        
       
        case DEL_MATERIAL_DELIVERY_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_MATERIAL_DELIVERY_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_MATERIAL_DELIVERY_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
            

        default:
        return state
    
    }
    }
    
    
    
export default MaterialDelivery;
    