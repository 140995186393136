import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  
} from '@material-ui/core';
import Page from '../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useLocation , useNavigate} from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {editOtherSubAdmin} from '../../../../Actions/SubAdminActions/M_OtherSubAdmin'
import {cleanOtherSubAdminData} from "../../../../Actions/ActionCreators";
import {getSubRole} from '../../../../Actions/SubAdminActions/SubRole'


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));



const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate()
  



const handleChanget = (event) => {
setValues({...values, ['permit_role']:event.target.value});
};



  const id = useSelector(state => state.login.data.admin_id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));



  const [values, setValues] = useState({
    admin_id : state.admin_id, 
    name : state.name,
    mobile : state.mobile,
    email : state.email,
    password : state.password,    
    permit_role : state.permit_role,
    is_app_login:state.is_app_login,
    
    showPassword: false,

    });
  
  

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeToggle = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };



  const handleChangec = (event) => {
      console.log(event.target.checked)
    setValues({ ...values, ['mobile_visible']: event.target.checked });
  };

  const emailRegex = /\S+@\S+\.\S+/;
  const nameRegex = /([a-zA-Z]{1,}\s?[a-zA-Z]+)+/;
  const mobileRegex = /^[6-9][0-9]{9}$/;


  const handleSubmit = () =>{
      console.log(values)

      if (!emailRegex.test(values.email))
      {
        tostE("Please Enter Valid Email");
      }
      else if (!nameRegex.test(values.name))
      {
        tostE("Name Must be Character")
      }
      else if (!mobileRegex.test(values.mobile))
      {
        tostE("Please Enter Valid Mobile Number")
      }
      
      else if (values.permit_role == "")
      {
        tostE("Please Enter Valid Role")
      }

      else{
      
        dispatch(editOtherSubAdmin(values))
    
        setValues({
          ...values,
          name : "",
          mobile : "",
          email : "",
          password : "",
          permit_role : ""
         
        });
    
        navigate('/app/master/users_list')

      }  


  }





  const CData = useSelector((state) =>
  state.Sub_Role && state.Sub_Role.getData &&
    state.Sub_Role.getData.length &&
    Array.isArray(state.Sub_Role.getData)
      ? state.Sub_Role.getData
      : []
  );

  

  const addmsg = useSelector((state) => state.AddOtherSubAdmin.data && state.AddOtherSubAdmin.data.message ? state.AddOtherSubAdmin.data.message : null  )
  const adderr = useSelector((state) => state.AddOtherSubAdmin.error && state.AddOtherSubAdmin.error.message ? state.AddOtherSubAdmin.error.message : null  )
  
    useEffect( () => {
      var r  = new Promise((resolve , reject)=>{
       if(addmsg === 200){
        tostS(addmsg)
       }
  
      else
      if(adderr !== null){
        tostE(adderr)
       }
    
       resolve()
      
      }) 
      r.then(()=>{
       dispatch(cleanOtherSubAdminData())
      })
      
    }, [addmsg, ,adderr])
  
  
  



  return (
    <Page
      className={classes.root}
      title="Add User"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add User" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Mobile"
                name="mobile"
                onChange={handleChange}
                required
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            

            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.permit_role}
          name = "permit_role"
          onChange={handleChanget}
          label="Role"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
      {CData.map((data , i)=>
      
      <MenuItem key={i} value={data.sub_role_id}>{data.role_name}</MenuItem>
      
      ) }

          </Select>
          </FormControl>
          </Grid>
      
          
            
            
          
          </Grid>
          <Grid item md={6} xs={12} styel={{border:"1px solid grey" }}>
              <FormGroup row>
               
                 <FormControlLabel
                  control={<Switch checked={values.is_app_login} onChange={handleChangeToggle} name="is_app_login" />}
                  label="Allow App Login"
                />
                
              </FormGroup>
            </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
