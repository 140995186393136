import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MaterialTable from "material-table";
import tableIcons from "../../../Config/IconsFile";
import moment from "moment";
import XLSX from "xlsx";
import Page from "../../../../src/components/Page";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { tostS, tostE } from "../../../Config/Toast";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const MeasureMentBook = ({ className, ...rest }) => {
  const classes = useStyles();
  const id = useSelector((state) => state.login.data.r_subadmin);
  const selected_site = useSelector(
    (state) => state.SelectedSite?.sitedata?.site ?? null
  );

  const [boqData, setBoqData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selected_site) {
      handleSubmit();
    }
  }, [selected_site]);

  const handleSubmit = () => {
    axios
      .get(`${Base_URL}/subadmin/subadminGetMb`, {
        params: { site: selected_site, sub_admin_id: id },
      })
      .then((res) => {
        if (res.data?.boq_list?.length > 0) {
          setBoqData(res.data.boq_list);
          tostS("MB Fetched Successfully");
        } else {
          setBoqData([]);
          tostE("No MB Found");
        }
      })
      .catch((err) => {
        console.log(err);
        tostE("No mb found");
      });
  };

  const generateExcel = () => {
    const worksheetData = flattenedData.map((row) => ({
      "SR. No.": row.srNo,
      Date: row.showDate,
      "Item Description": row.item_description || "-",
      Quantity: row.quantity || "-",
      Length: row.length || "-",
      Breadth: row.breadth || "-",
      "No. of Items": row.noofitems || "-",
      Dia: row.dia || "-",
      Height: row.height || "-",
      "Unit Weight": row.unit_weight || "-",
      "Overall Quantity (till Date)": row.used_quantity || "-",
      Remarks: row.remark || "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BOQ Data");
    XLSX.writeFile(workbook, "BOQ_Data.xlsx");
  };

  const groupedData = boqData.reduce((acc, item) => {
    const date = item.created_at
      ? moment(item.created_at).format("YYYY-MM-DD")
      : "-";
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  let srNo = 1;
  const flattenedData = Object.keys(groupedData).flatMap((date) =>
    groupedData[date].map((item, i) => ({
      ...item,
      srNo: i === 0 ? srNo++ : "",
      showDate: i === 0 ? date : "",
    }))
  );

  return (
    <Page title="Site Drawings">
      <ToastContainer />
      <Container maxWidth={false}>
        <Box mt={5} className={clsx(classes.root, className)}></Box>
        <Box mt={3}>
          {boqData.length > 0 ? (
            <Card>
              <MaterialTable
                icons={tableIcons}
                title="Measurement BOOK"
                columns={[
                  {
                    title: "SR. No.",
                    field: "srNo",
                  },
                  {
                    title: "Date",
                    field: "showDate",
                    render: (rowData) => rowData.showDate || "-",
                  },
                  {
                    title: "Item Description",
                    field: "item_description",
                    render: (rowData) => rowData.item_description || "-",
                  },
                  {
                    title: "Quantity",
                    field: "quantity",
                    render: (rowData) => rowData.quantity || "-",
                  },
                  {
                    title: "Length",
                    field: "length",
                    render: (rowData) => rowData.length || "-",
                  },
                  {
                    title: "Breadth",
                    field: "breadth",
                    render: (rowData) => rowData.breadth || "-",
                  },
                  {
                    title: "No. of Items",
                    field: "noofitems",
                    render: (rowData) => rowData.noofitems || "-",
                  },
                  {
                    title: "Dia",
                    field: "dia",
                    render: (rowData) => rowData.dia || "-",
                  },
                  {
                    title: "Height",
                    field: "height",
                    render: (rowData) => rowData.height || "-",
                  },
                  {
                    title: "Unit Weight",
                    field: "unit_weight",
                    render: (rowData) => rowData.unit_weight || "-",
                  },
                  {
                    title: "Overall Quantity (till Date)",
                    field: "used_quantity",
                    render: (rowData) => rowData.used_quantity || "-",
                  },
                  {
                    title: "Remarks",
                    field: "remark",
                    render: (rowData) => rowData.remark || "-",
                  },
                ]}
                data={flattenedData}
              />
              <Button
                onClick={generateExcel}
                color="primary"
                style={{ float: "right" }}
                variant="contained"
              >
                Export to Excel
              </Button>
            </Card>
          ) : (
            <Typography variant="h6" align="center">
              No Measurement Book Available
            </Typography>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default MeasureMentBook;
