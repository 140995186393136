import {ADD_SITE_DRAW_LOADING , ADD_SITE_DRAW_SUCCESS , ADD_SITE_DRAW_FAILURE} from '../../Actions/Types';
import {GET_SITE_DRAW_LOADING , GET_SITE_DRAW_SUCCESS , GET_SITE_DRAW_FAILURE} from '../../Actions/Types';
import {EDIT_SITE_DRAW_LOADING , EDIT_SITE_DRAW_SUCCESS , EDIT_SITE_DRAW_FAILURE} from '../../Actions/Types';
import {DEL_SITE_DRAW_LOADING , DEL_SITE_DRAW_SUCCESS , DEL_SITE_DRAW_FAILURE} from '../../Actions/Types';
import {CLEAN_ALL_DATA, CLEAN_SITE_DRAW_DATA} from '../../Actions/Types'



const SubSiteDraw = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData:[{}],
            editError:{},
            delData:[{}],
            delError:{}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                
            }
    
    
        case CLEAN_SITE_DRAW_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData : [{}],
                getError:{},
                editData : [{}],
                editError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_SITE_DRAW_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                
                
            }
        case ADD_SITE_DRAW_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
                
            }
        case ADD_SITE_DRAW_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
            }
        case GET_SITE_DRAW_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
                
            }
        case GET_SITE_DRAW_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
                
            }
        case GET_SITE_DRAW_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:[{}],
            }

        case EDIT_SITE_DRAW_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}],
                
                
            }
        case EDIT_SITE_DRAW_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data,
                
            }
        case EDIT_SITE_DRAW_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:[{}],
            }            
        case DEL_SITE_DRAW_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}],
                
                
            }
        case DEL_SITE_DRAW_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
                
            }
        case DEL_SITE_DRAW_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:[{}],
            }
        

        default:
        return state
    
    }
    }
    
    
    
export default SubSiteDraw;
    