
import {CLIENT_NEW_MATERIAL_LOADING , 
    CLIENT_NEW_MATERIAL_SUCCESS , 
    CLIENT_NEW_MATERIAL_FAILURE} from '../../Actions/Types'
import {CLIENT_MACHINE_REPORT_LOADING,
    CLIENT_MACHINE_REPORT_SUCCESS,
    CLIENT_MACHINE_REPORT_FAILURE} from '../../Actions/Types'
import {CLIENT_MANPOWER_REPORT_LOADING,
    CLIENT_MANPOWER_REPORT_SUCCESS,
    CLIENT_MANPOWER_REPORT_FAILURE} from '../../Actions/Types'
import {CLIENT_SITE_EXPENSE_LOADING,
    CLIENT_SITE_EXPENSE_SUCCESS,
    CLIENT_SITE_EXPENSE_FAILURE} from '../../Actions/Types'
import {CLIENT_SITE_PROGRESS_LOADING,
    CLIENT_SITE_PROGRESS_SUCCESS,
    CLIENT_SITE_PROGRESS_FAILURE} from '../../Actions/Types'    
import {CLIENT_SITE_DRAWINGS_LOADING,
    CLIENT_SITE_DRAWINGS_SUCCESS,
    CLIENT_SITE_DRAWINGS_FAILURE,
    CLEAN_SITE_DRAWINGS_DATA} from '../../Actions/Types'
import {CLIENT_ADD_TICKET_LOADING,
        CLIENT_ADD_TICKET_SUCCESS,
        CLIENT_ADD_TICKET_FAILURE,
        CLIENT_GET_TICKET_LOADING,
        CLIENT_GET_TICKET_SUCCESS,
        CLIENT_GET_TICKET_FAILURE,
        CLEAN_TICKET_DATA} from '../../Actions/Types'
import {CLIENT_PROFILE_LOADING,
        CLIENT_PROFILE_SUCCESS,
        CLIENT_PROFILE_FAILURE,
        CLIENT_GET_PROFILE_LOADING,
        CLIENT_GET_PROFILE_SUCCESS,
        CLIENT_GET_PROFILE_FAILURE,
        CLEAN_PROFILE_DATA} from '../../Actions/Types'
import {CLIENT_PROFILE_PIC_LOADING,
        CLIENT_PROFILE_PIC_SUCCESS,
        CLIENT_PROFILE_PIC_FAILURE,
        CLEAN_PROFILE_PIC_DATA} from '../../Actions/Types'  
import {CLIENT_FAQ_LOADING,
    CLIENT_FAQ_SUCCESS,
    CLIENT_FAQ_FAILURE} from '../../Actions/Types'
import {CLEAN_ALL_DATA } from '../../Actions/Types';


import {filterDataManpower , filterDataMD , filterDataMacD} from './Functions'



const ClientAllGet = (state , action) => {
    console.log(action.type)
    if(typeof state === 'undefined') {
        return {
            loading:false,            
            newMatData:[],
            newMatError:{},
            
            machineReportData : [],
            machineReportError :{},

            manpowerReportData : [],
            manpowerReportError :{},

            siteExpData : [],
            siteExpError :{},

            siteDrawData : [],
            siteDrawError :{},

            faqData : [],
            faqError :{},

            siteProgData: [],
            siteProgError :{},

            ticketData:[],
            tickerError:{},
            ticketGetData:[],
            tickerGetError:{},
            
            profile:[],
            profileError:{},
            getprofile:[],
            getprofileError:{},
            profilePic:[],
            profilePicError:{}

        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,            
            loading:false,      

            newMatData:{},
            newMatError:{},
            
            machineReportData : {},
            machineReportError :{},

            manpowerReportData : {},
            manpowerReportError :{},

            siteExpData : {},
            siteExpError :{},

            siteDrawData : {},
            siteDrawError :{},

            siteProgData: {},
            siteProgError :{},

            faqData : {},
            faqError :{},

            ticketData:[],
            tickerError:{},
            ticketGetData:[],
            tickerGetError:{},
            
            profile:[],
            profileError:{},
            getprofile:[],
            getprofileError:{},
            profilePic:[],
            profilePicError:{}
            
        }

    case CLEAN_SITE_DRAWINGS_DATA:
        return {
            ...state,            
            loading:false,      
            siteDrawData : [],
            siteDrawError :{},           
        }


            //1


        case CLIENT_NEW_MATERIAL_LOADING:
            return {
                ...state,
                loading:true,
                newMatError: {},
                
            }
        case CLIENT_NEW_MATERIAL_SUCCESS:
            return {
                ...state,
                loading:false,
                newMatError:{},
                newMatData:filterDataMD(action.newMatData)
            }
        case CLIENT_NEW_MATERIAL_FAILURE:
            return {
                ...state,
                loading:false,
                newMatError:action.newMatError,
                newMatData:{},
            }  


            //2 
            
            
            case CLIENT_MACHINE_REPORT_LOADING:
                return {
                    ...state,
                    loading:true,
                    machineReportError: {},
                    
                }
            case CLIENT_MACHINE_REPORT_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    machineReportError:{},
                    machineReportData: filterDataMacD( action.machineReportData)
                }
            case CLIENT_MACHINE_REPORT_FAILURE:
                return {
                    ...state,
                    loading:false,
                    machineReportError:action.machineReportError,
                    machineReportData:{},
                }   



                 //3
            
            
            case CLIENT_MANPOWER_REPORT_LOADING:
                return {
                    ...state,
                    loading:true,
                    manpowerReportError: {},
                    
                }
            case CLIENT_MANPOWER_REPORT_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    manpowerReportError:{},
                    manpowerReportData:filterDataManpower(action.manpowerReportData)
                }
            case CLIENT_MANPOWER_REPORT_FAILURE:
                return {
                    ...state,
                    loading:false,
                    manpowerReportError:action.manpowerReportError,
                    manpowerReportData:{},
                }  


                      //4
            
            
            case CLIENT_SITE_EXPENSE_LOADING:
                return {
                    ...state,
                    loading:true,
                    siteExpError: {},
                    
                }
            case CLIENT_SITE_EXPENSE_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    siteExpError:{},
                    siteExpData:action.siteExpData
                }
            case CLIENT_SITE_EXPENSE_FAILURE:
                return {
                    ...state,
                    loading:false,
                    siteExpError:action.siteExpError,
                    siteExpData:{},
                }  


                //5
            
            
            case CLIENT_SITE_PROGRESS_LOADING:
                return {
                    ...state,
                    loading:true,
                    siteProgError: {},
                    
                }
            case CLIENT_SITE_PROGRESS_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    siteProgError:{},
                    siteProgData:action.siteProgData
                }
            case CLIENT_SITE_PROGRESS_FAILURE:
                return {
                    ...state,
                    loading:false,
                    siteProgError:action.siteProgError,
                    siteProgData:{},
                }  


                 //6
            
            
            case CLIENT_SITE_DRAWINGS_LOADING:
                return {
                    ...state,
                    loading:true,
                    siteDrawError: {},
                    
                }
            case CLIENT_SITE_DRAWINGS_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    siteDrawError:{},
                    siteDrawData:action.siteDrawData
                }
            case CLIENT_SITE_DRAWINGS_FAILURE:
                return {
                    ...state,
                    loading:false,
                    siteDrawError:action.siteDrawError,
                    siteDrawData:{},
                }  



                
                 //7
            
            
            case CLIENT_FAQ_LOADING:
                return {
                    ...state,
                    loading:true,
                    faqError: {},
                    
                }
            case CLIENT_FAQ_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    faqError:{},
                    faqData:action.faqData
                }
            case CLIENT_FAQ_FAILURE:
                return {
                    ...state,
                    loading:false,
                    faqError:action.faqError,
                    faqData:{},
                }  

            // 8

            case CLIENT_ADD_TICKET_LOADING:
                return {
                    ...state,
                    loading:true,
                    ticketError: {},
                    ticketData:[],
                }
            case CLIENT_ADD_TICKET_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    ticketError:{},
                    ticketData:action.Data
                }
            case CLIENT_ADD_TICKET_FAILURE:
                return {
                    ...state,
                    loading:false,
                    ticketError:action.Error,
                    ticketData:{},
                }  
    
            // 9

            case CLIENT_GET_TICKET_LOADING:
                return {
                    ...state,
                    loading:true,
                    ticketGetError: {},
                    ticketGetData:[],
                }
            case CLIENT_GET_TICKET_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    ticketGetError:{},
                    ticketGetData:action.Data
                }
            case CLIENT_GET_TICKET_FAILURE:
                return {
                    ...state,
                    loading:false,
                    ticketGetError:action.Error,
                    ticketGetData:{},
                }  
    
            case CLEAN_TICKET_DATA:
                return {
                    ...state,            
                    loading:false,      
                    ticketData:[],
                    ticketError:{},       
                }
            
            // 8

            case CLIENT_PROFILE_LOADING:
                return {
                    ...state,
                    loading:true,
                    profileError: {},
                    profile:[],
                }
            case CLIENT_PROFILE_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    profileError:{},
                    profile:action.Data
                }
            case CLIENT_PROFILE_FAILURE:
                return {
                    ...state,
                    loading:false,
                    profileError:action.Error,
                    profile:{},
                }  
    
            case CLIENT_GET_PROFILE_LOADING:
                return {
                    ...state,
                    loading:true,
                    getprofileError: {},
                    getprofile:[],
                }
            case CLIENT_GET_PROFILE_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    getprofileError:{},
                    getprofile:action.Data
                }
            case CLIENT_GET_PROFILE_FAILURE:
                return {
                    ...state,
                    loading:false,
                    getprofileError:action.Error,
                    getprofile:{},
                }  
    
            case CLEAN_PROFILE_DATA:
                return {
                    ...state,            
                    loading:false,      
                    profile:{},
                    profileError:{},       
                    getprofileError:{},       
                }


            // 9

            case CLIENT_PROFILE_PIC_LOADING:
                return {
                    ...state,
                    loading:true,
                    profilePicError: {},
                    profilePic:[],
                }
            case CLIENT_PROFILE_PIC_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    profilePicError:{},
                    profilePic:action.Data
                }
            case CLIENT_PROFILE_PIC_FAILURE:
                return {
                    ...state,
                    loading:false,
                    profilePicError:action.Error,
                    profilePic:{},
                }  
    
            case CLEAN_PROFILE_PIC_DATA:
                return {
                    ...state,            
                    loading:false,      
                    profilePic:[],
                    profilePicError:{},       
                }
            
    default:
        return state

}
}



export default ClientAllGet;
