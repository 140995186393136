// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor3";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {getGstBegin , getGstSuccess , getGstFailure } from './../ActionCreators'



import {addGstBegin , addGstSuccess , addGstFailure } from './../ActionCreators'
import {editGstBegin , editGstSuccess , editGstFailure } from './../ActionCreators'
import {delGstBegin , delGstSuccess , delGstFailure } from './../ActionCreators'




export const delGst = (x) => {

    return (dispatch) => {
        dispatch(delGstBegin());


        axios.get(Base_URL + '/superadmin/delgst' , {params :{gst_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delGstSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delGstFailure(err.response.data));

                }
            })
    }
}


export const addGst = (session) => {

    return (dispatch) => {
        dispatch(addGstBegin());

        axios.post(Base_URL + '/superadmin/addGst', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addGstSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addGstFailure(err.response.data));

                }
            })
    }
}


export const editGst = (session) => {

    return (dispatch) => {
        dispatch(editGstBegin());

        axios.post(Base_URL + '/superadmin/editgst', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editGstSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editGstFailure(err.response.data));

                }
            })
    }
}

export const getGst = () => {

    return (dispatch) => {
        dispatch(getGstBegin());


        axios.get(Base_URL + '/superadmin/getGst')
            .then((res) => {
                console.log(res.data)
                dispatch(getGstSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getGstFailure(err.response.data));

                }
            })
    }
}











