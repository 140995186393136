import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import tableIcons from '../../../../Config/IconsFile'


import {orderMachineIndent} from '../../../../Actions/SubAdminActions/Machine_Indent'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate();
  const listData = (state.machine_details && Array.isArray(state.machine_details) && state.machine_details.length ? state.machine_details : []);



  const id = useSelector(state => state.login.data.id);


  const [data, setData] = useState(listData);
  const [open, setOpen] = useState({show:false, mchne_id:"", machine_details:""});
  
  const [values, setValues] = useState({
   
  admin_id :id,
  mchne_id: state.mchne_id,
  machine_details:[],

  fixImg : "/static/images/avatars/upload.png"

  });


  const handleClose = () =>{

    setOpen({...open, show:false, mchne_id:"", machine_details:""});
  }

const handleOpen = (dataVal) =>{

  setOpen({
    ...open,
    show:true,
    machine_details:data,
    mchne_id:dataVal.mchne_id
  });

}


const handleConfirm = () =>{
  if(open.mchne_id!=="" && open.machine_details!==""){

    dispatch(orderMachineIndent(open))
    navigate("/app/machineindentlist")

  }

}


  const [columns, setColumns] = useState([
    { title: 'Machine Name', field: 'mac_name', editable:'never'},
    { title: 'Machine Type', field: 'mac_type', editable:'never'},
    { title: 'Indent Quantity', field: 'ind_quan', type: 'numeric' },
    { title: 'Accepted Quantity', field: 'acc_quan', type: 'numeric' },
    { title: 'Purchased Quantity', field: 'quan', type: 'numeric' },
    { title: 'Delivered Quantity', field: 'new_quan', type: 'numeric' },
  ]);



  return (
    <Page className={classes.root} title="Machine Indent Detail" >
      <ToastContainer    />
       

      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Place This Order??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader="" title="Single Machine Indent  View" />
                <Divider />
                <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Machine Indent ID : {state.mchne_id}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Order ID :  {state.order_id}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Site Name :  {state.site_name}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Engineer Name :  {state.name}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Engineer Mobile :  {state.mobile}</Typography>
                  </Grid>                  
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Required Date :  {moment(state.required_date).format("DD.MM.YYYY")}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Priority :  {state.priority}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    Status :
                    {state.status==="Accepted" ? 
                      <>
                      <Typography style={{color:"green"}} variant='overline'> Accepted</Typography>
                      </>
                      :
                      state.status==="Rejected" ? 
                      <>
                      <Typography style={{color:"red"}} variant='overline'> Rejected</Typography>
                      </>
                      :             
                      state.status==="Admin_Resolved" ? 
                      <>
                      <Typography style={{color:"Blue"}} variant='overline'> Admin Resolved</Typography>
                      </>
                      :
                      <>
                      <Typography style={{color:"#E36A26"}} variant='overline'> Pending</Typography>
                      </>}                                  
                  </Grid>
                  <Grid item xs={6} md={4}>
                    Order Status :
                    {state.is_order_place ? 
                    <>
                    <Typography style={{color:"green"}} variant='overline'> Ordered </Typography>
                    </>     
                            
                    :
                    <>
                    <Typography style={{color:"#E36A26"}} variant='overline'> Pending </Typography>
                    </>}                                  
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Accepted By :  {state.accepted_by}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Notes :  {state.notes}</Typography>
                  </Grid>
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""?
                  
                  <Grid item md={4} xs={12}>
                    
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
                  
                    <Typography variant="body2" color="text.secondary" spacing={3}>Location :  
                      {state.lat}, {state.long}                 
                    </Typography>
                  
                   : 
                   <Typography variant="body2" color="text.secondary" spacing={3}>Location: </Typography>
                   }
                    
                  </Grid>
                  :<></>
                  }
                </Grid>

                </CardContent>
                <Divider />
                
              </Card>
            </form>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Card className={clsx(classes.root2, className)} {...rest}>
          <MaterialTable
            icons={tableIcons}      
            title="Machine Indent Details"
            columns={columns}
            data={data}
            
            options={{
                    selection: false,
                    search:false,
                    paging:false
                  }}
                
          />
         
          </Card>
          </Box>
      </Container>
    </Page>
  );
};

export default AddBusiness;
