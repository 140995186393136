import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../../src/components/Page';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { useDispatch, useSelector } from 'react-redux';
import {verifyotp} from '../../Actions/resetPassword';
import {cleanResetPassData} from '../../Actions/ActionCreators'
import { tostE, tostS } from '../../Config/Toast';
import { ToastContainer } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#E36A26",
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {
    backgroundColor: "theme.palette.background.dark",
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    opacity:0.8,
    elevation:10,
    borderRadius:10
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const VerifyView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ResetEmail = useSelector(state => state.ResetPassword && state.ResetPassword.email && state.ResetPassword.email!=="" ? state.ResetPassword.email:null);


  const [val, setVal] = useState({
    email:ResetEmail,
    otp:"",

  })



const handleChange = (event) => {
  setVal({
    ...val,
    ['otp']:event.target.value
  })
}



const handleSubmit = () => {
  
  if(val.otp===""){
    tostE("Please Enter OTP!!")
  }else{
    dispatch(verifyotp(val))
  }

}
  return (
    <Page
      className={classes.root}
      title="Register"
    >
    <ToastContainer />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
        <Card className={classes.root2 , {elevation:10 , boxShadow:10}}>
          <CardContent>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Verify OTP
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Please Enter OTP Sent To your Email ID
              </Typography>
            </Box>
            
            <TextField              
              fullWidth              
              label="Enter OTP"
              margin="normal"
              name="otp"              
              type="number"  InputProps={{inputProps:{min:0}}}              
              variant="outlined"
              value={val.otp}
              onChange={handleChange}
            />
           
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Verify
              </Button>
            </Box> 
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Have an account?
              {' '}
              <Link
                component={RouterLink}
                to="/login"
                variant="h6"
              >
                Sign in
              </Link>
            </Typography>
          </CardContent>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};


export default VerifyView;