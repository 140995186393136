import {ADD_PURCHASE_ORDER_LOADING , ADD_PURCHASE_ORDER_SUCCESS , ADD_PURCHASE_ORDER_FAILURE } from '../../Actions/Types';
import {GET_PURCHASE_ORDER_LOADING , GET_PURCHASE_ORDER_SUCCESS , GET_PURCHASE_ORDER_FAILURE } from '../../Actions/Types';
import {DEL_PURCHASE_ORDER_LOADING , DEL_PURCHASE_ORDER_SUCCESS , DEL_PURCHASE_ORDER_FAILURE } from '../../Actions/Types';

import {CLEAN_ALL_DATA, CLEAN_PURCHASE_ORDER_DATA } from '../../Actions/Types';



const PurchaseOrder = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            Data:[{}],
            Error:{},            
            getData:[{}],
            getError:{},
            delData:[{}],
            delError:{},
            
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,            
            loading:false,  
            Data:[{}],
            Error:{},          
            getData:[{}],
            getError:{},
            delData:[{}],
            delError:{},
        }

    case CLEAN_PURCHASE_ORDER_DATA:
        return {
            ...state,            
            loading:false,  
            Data:[{}],
            Error:{},          
            getData:[{}],
            getError:{},
            delData:[{}],
            delError:{},
        }

        case ADD_PURCHASE_ORDER_LOADING:
            return {
                ...state,
                loading:true,
                Data:[{}],
                Error:{}
                
            }
        case ADD_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                loading:false,
                Data:action.data,
                Error:{}
            }
        case ADD_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                loading:false,
                Error:action.error,
                Data:{},
            }   
        
        case GET_PURCHASE_ORDER_LOADING:
            return {
                ...state,
                loading:true,
                getData:[{}],
                getError:{}
                
            }
        case GET_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                loading:false,
                getData:action.data,
                getError:{}
            }
        case GET_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        case DEL_PURCHASE_ORDER_LOADING:
            return {
                ...state,
                loading:true,
                delData:[{}],
                delError:{}
                
            }
        case DEL_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                loading:false,
                delData:action.data,
                delError:{}
            }
        case DEL_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }   
        
    default:
        return state

}
}



export default PurchaseOrder;
