import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';


const shade1 = "#3b4e87"
const shade2 = "#7a8dc5"
const shade3 = "#bfbfbf"
const shade4 = "#000000"
const shade5 = "#ffffff"

const styles = StyleSheet.create({
    footer:{
        flexDirection:'row',
        justifyContent:'space-between',
        width:'100%',
        marginTop:'5px'
    },
    commentSection:{
        flexDirection:'column',
        width:"50%",
        marginTop:"10px",
        borderColor:shade3,
        borderWidth:2,
    },
    commentHeading:{        
        backgroundColor:shade3,        
        paddingLeft:"5px",
        borderColor:shade3,
        borderWidth:1,
    },
    commentContent:{        
        backgroundColor:shade5,        
        paddingLeft:"5px",
        lineHeight:5
    },
    totalSection:{
        flexDirection:'column',
        width:"25%"
    },
    SubTotal:{
        flexDirection:'row',
        justifyContent:'space-between'
    },
    Total:{        
        borderTopColor:shade4,
        borderTopWidth:2,
        flexDirection:'row',
        justifyContent:'space-between'
    },
    container: {
        flexDirection: 'column',
        marginTop:'5%',
        width:'40%',
        borderColor: shade3,
        backgroundColor: shade5,
        color:shade4,
        borderWidth: 2,
        alignItems: 'left',       
        textAlign: 'left',
        fontStyle: 'bold',        
    },
  });


const InvoiceTableFooter = ({items, gst_type}) => {
    
    const total = items.map(item => item.amt!=="" && item.amt!==null && item.amt!==undefined ?  parseFloat(item.amt) : 0)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0);
    const gst = items.map(item =>  item.amt!=="" && item.amt!==null && item.amt!==undefined && item.gst_per!=="" && item.gst_per!==null && item.gst_per!==undefined  ? parseFloat(item.amt)*(parseFloat(item.gst_per)/(100)):0)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0);
    
    return(
        <> 
        <View style={styles.footer}>
            <View style={styles.commentSection}>
                <Text style={styles.commentHeading}>Comments or Special Instructions</Text>
                <Text style={styles.commentContent}>Thank you for your business.</Text>
            </View>
            <View style={styles.totalSection}>
                <View style={styles.SubTotal}>
                    <Text>SUBTOTAL</Text>
                    <Text>{parseFloat(total).toFixed(2)}</Text>
                </View>
                {gst_type=="IGST" ? 
                
                    <View style={styles.SubTotal}>
                        <Text>TAX(IGST)</Text>
                        <Text style={{width:"50%", textAlign:'right',paddingTop:'2px',paddingBottom:0, borderWidth:2, borderColor:shade3}}>{parseFloat(gst).toFixed(2)}</Text>
                    </View>
                :
                <>
                    <View style={styles.SubTotal}>
                        <Text>TAX(SGST)</Text>
                        <Text style={{width:"50%", textAlign:'right',paddingTop:'2px',paddingBottom:0, borderWidth:2, borderColor:shade3}}>{parseFloat(gst/2).toFixed(2)}</Text>
                    </View>
                    <View style={styles.SubTotal}>
                        <Text>TAX(CGST)</Text>
                        <Text style={{width:"50%", textAlign:'right',paddingTop:'2px',paddingBottom:0, borderWidth:2, borderColor:shade3}}>{parseFloat(gst/2).toFixed(2)}</Text>
                    </View>
                </>
                }
                <View style={styles.SubTotal}>
                    <Text>SHIPPING</Text>
                    <Text style={{borderWidth:2,borderTopWidth:0,width:"50%", textAlign:'right', borderColor:shade3}}>-</Text>
                </View>
                <View style={styles.SubTotal}>
                    <Text>OTHER</Text>
                    <Text style={{borderWidth:2, borderTopWidth:0, borderBottomWidth:0, width:"50%", textAlign:'right', borderColor:shade3}}>-</Text>
                </View>
                <View style={styles.Total}>
                    <Text>Total</Text>
                    <Text style={{width:'50%', backgroundColor:shade2, textAlign:'right'}}>{parseFloat(total+gst).toFixed(2)}</Text>
                </View>
            </View>
        </View>
        <View style={{flexDirection:'row',justifyContent:'flex-end'}}> 
            <View style={{width:'50%', flexDirection:'column'}}></View>
            <View style={styles.container}>
                <Text style={{lineHeight:4}}>Signature:</Text>
            </View> 
        </View>
       </> 
    )
};
  
  export default InvoiceTableFooter