

import axios from '../Config/Interceptor1'

// import axios from 'axios'


import { Base_URL } from '../Config/BaseUrlConfig'


import {loginBegin , loginSuccess , loginFailure} from './ActionCreators'
import {refreshClientBegin , refreshClientSuccess , refreshClientFailure} from './ActionCreators'
import {refreshSubAdminBegin , refreshSubAdminSuccess , refreshSubAdminFailure} from './ActionCreators'
import {refreshSuperAdminBegin , refreshSuperAdminSuccess , refreshSuperAdminFailure} from './ActionCreators'
import {logoutBegin , logoutSuccess , logoutFailure, cleanAllData} from './ActionCreators'





export const login = (session) => {
    console.log(session);
    var y = {
      email : session.mobile,
      password : session.password
    }
    return (dispatch) => {
        dispatch(loginBegin());

        axios.post(Base_URL + '/common/logincom', y)
            .then((res) => {
              console.log(res);
              var temp  = res.data.obj
                if(res.data.obj)
                {
                  
                  temp['token'] = res.headers.authorization
                }

                localStorage.setItem('x-auth', res.headers.authorization);
                dispatch(loginSuccess(temp));

            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(loginFailure(err.response.data));

                }
            })
    }
}




export const refreshTokenClient = () => {
  return (dispatch) => {
    dispatch(refreshClientBegin());

  return  axios.get( Base_URL + '/client/refreshclient')
    .then( (res) => {
      console.log(res)
      localStorage.setItem('x-auth', res.headers.authorization);
      dispatch(refreshClientSuccess(res.headers.authorization));
     return Promise.resolve(res.headers.authorization);
    }).catch( (err) => {
      dispatch(refreshClientFailure(err.response.data));
     return Promise.reject();
    })
  }
}




export const refreshTokenSubAdmin = () => {
  return (dispatch) => {
    dispatch(refreshSubAdminBegin());

  return  axios.get( Base_URL + '/Subadmin/refreshAdmin')
    .then( (res) => {
      console.log(res)
      localStorage.setItem('x-auth', res.headers.authorization);
      dispatch(refreshSubAdminSuccess(res.headers.authorization));
     return Promise.resolve(res.headers.authorization);
    }).catch( (err) => {
      dispatch(refreshSubAdminFailure(err.response.data));
     return Promise.reject();
    })
  }
}





export const refreshTokenSuperAdmin = () => {
  return (dispatch) => {
    dispatch(refreshSuperAdminBegin());

  return  axios.get( Base_URL + '/superadmin/refreshSuperAdmin')
    .then( (res) => {
      console.log(res)
      localStorage.setItem('x-auth', res.headers.authorization);
      dispatch(refreshSuperAdminSuccess(res.headers.authorization));
     return Promise.resolve(res.headers.authorization);
    }).catch( (err) => {
      dispatch(refreshSuperAdminFailure(err.response.data));
     return Promise.reject();
    })
  }
}







