import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import DetailIcon from '@material-ui/icons/List';


import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { ThemeProvider } from "@material-ui/styles";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {getDelivery} from '../../../../Actions/Inventory/Delivery';
 import {cleanDeliveryData} from '../../../../Actions/Inventory/ActionCreators'

const  TabPanel = (props)=> {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}





const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const navigate = useNavigate();
  
  const a_id = useSelector(state => state.login && state.login.data && state.login.data.r_subadmin?state.login.data.r_subadmin:"");

  const [tvalue, setTValue] = React.useState(0);

  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
  };
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const MatListData =  useSelector((state) =>
      state.Delivery && state.Delivery.getData &&
      Object.keys(state.Delivery.getData) && 
      Object.keys(state.Delivery.getData).length && 
      "Material" in state.Delivery.getData &&
      Array.isArray(state.Delivery.getData['Material']) && 
      state.Delivery.getData['Material'].length  
      ? (state.Delivery.getData['Material']).filter(data => !selected_site || data.site_id == selected_site)
      
      :[]);
  
  const MacListData =  useSelector((state) =>
      state.Delivery && state.Delivery.getData &&
      Object.keys(state.Delivery.getData) && 
      Object.keys(state.Delivery.getData).length && 
      "Machine" in state.Delivery.getData &&
      Array.isArray(state.Delivery.getData['Machine']) && 
      state.Delivery.getData['Machine'].length  
      ? (state.Delivery.getData['Machine']).filter(data => !selected_site || data.site_id == selected_site)
      
      :[]);


 
      const Accmsg = useSelector(state => state.Delivery && state.Delivery.acceptData && state.Delivery.acceptData.message ? state.Delivery.acceptData.message : null  )
      const Accerror = useSelector(state => state.Delivery && state.Delivery.acceptError && state.Delivery.acceptError.message ? state.Delivery.acceptError.message : null  )
    
      
    useEffect( () => {
    
      dispatch(getDelivery(a_id));
    
    
        let x  = new Promise((resolve ,reject)=>{
          if(Accmsg && typeof(Accmsg) === 'string')
          {
            tostS(Accmsg)
          }
          if(Accerror && typeof(Accerror) === 'string')
          {
              tostE(Accerror)
          }
    
          resolve()
    
        })
        x.then(()=>{
    
        dispatch(cleanDeliveryData())
        dispatch(getDelivery(a_id));
    
        })
    
    
        
      }, [ Accmsg  ,Accerror ])
    
    

  const handleAcceptMat = (data) => {
    navigate("/app/inventory/delivery/material/accept/"+data.dy_id, {state:data})
  }

  const handleAcceptMac = (data) => {
    navigate("/app/inventory/delivery/machine/accept/"+data.rm_id, {state:data})
  }

  return (
    <>
      <Page className={classes.root} title="Delivery">
      <ToastContainer    />
      <div>
      <AppBar position="static">
        <Tabs value={tvalue} onChange={handleChangetab} variant="fullWidth" textColor="inherit" aria-label="full width tabs example">
          <Tab label="Material" {...a11yProps(0)} />
          <Tab label="Machine" {...a11yProps(1)} />
          
        </Tabs>
      </AppBar>

      
      <TabPanel value={tvalue} index={0}>
      <Container maxWidth={false}>
          
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>
              <CardContent>
              <MaterialTable
                icons={tableIcons}      
                title="Material Delivery List"
                columns={[
                
                  { title: 'Order Id', field: 'order_id',editable: "never", },        
                  { title: 'Site Name', field: 'site_name',editable: "never", },        
                  { title: 'Priority', field: 'priority',editable: "never", },     
                  { title: 'Status', field: 'status', cellStyle:{color:"red"} },     
                  { title: 'Issue To', field: 'eng_name',editable: "never", },     
                  { title: 'Delivery Date',
                    render:(rowData) => (                      
                      moment(rowData.delivery_date).format("DD.MM.YYYY")                                            
                    )
                  
                  },        
                  { title:"Actions", 
                  render:(rowData) => (
                    
                    <Grid container spacing={3}>
                    
                    {/* <Grid item md={4}>
                      <Tooltip title="Detail">
                        <DetailIcon />

                      </Tooltip>
                    </Grid> */}
                    <Grid item md={4}>      
                      <Tooltip title="Accept Delivery">
                        <CheckIcon onClick={()=>{handleAcceptMat(rowData)}} />

                      </Tooltip>                
                    </Grid>
                    </Grid>
                  )

                  }      
                  

                ]}
                data={MatListData}
                  options={{
                          selection: false,
                          textAlign: "center",
                          headerStyle: { textAlign: "left" },
                          rowStyle: { textAlign: "center" }
                        }}

              />
             </CardContent>
            </Card>
          </Box>
        </Container>
     
      </TabPanel>
      <TabPanel value={tvalue} index={1}>
      <Container maxWidth={false}>
          
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Machine Delivery List"
      columns={[
       
        { title: 'Order Id', field: 'order_id',editable: "never", },        
        { title: 'Site Name', field: 'site_name',editable: "never", },        
        { title: 'Priority', field: 'priority',editable: "never", },     
        { title: 'Status', field: 'status',cellStyle:{color:"red"} },     
        { title: 'Issue To', field: 'eng_name',editable: "never", },     
        { title: 'Delivery Date',
          render:(rowData) => (                      
            moment(rowData.delivery_date).format("DD.MM.YYYY")                                            
          )
        
        },        
        { title:"Actions", 
                  render:(rowData) => (
                    
                    <Grid container spacing={3}>
                    
                    {/* <Grid item md={4}>
                      <Tooltip title="Detail">
                        <DetailIcon />

                      </Tooltip>
                    </Grid> */}
                    <Grid item md={4}>      
                      <Tooltip title="Accept Delivery">
                        <CheckIcon onClick={()=>{handleAcceptMac(rowData)}} />

                      </Tooltip>                
                    </Grid>
                    </Grid>
                  )

                  }    
      ]}
      data={MacListData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </TabPanel>      
         
    </div>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
