import axios from "../../Config/Interceptor1";


// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {ClientAddTicketBegin , ClientAddTicketSuccess , ClientAddTicketFailure} from '../ActionCreators'
import {ClientGetTicketBegin , ClientGetTicketSuccess , ClientGetTicketFailure} from '../ActionCreators'

export const ClientAddTicket = (session) => {

    return (dispatch) => {
        dispatch(ClientAddTicketBegin());

        axios.post(Base_URL + '/client/addticket', session)
            .then((res) => {
                console.log(res.data)
                dispatch(ClientAddTicketSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(ClientAddTicketFailure(err.response.data));

                }
            })
    }
}


export const ClientGetTicket = (x) => {

    return (dispatch) => {
        dispatch(ClientGetTicketBegin());

        axios.get(Base_URL + '/client/getticket' , {params : x})
            .then((res) => {
                dispatch(ClientGetTicketSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(ClientGetTicketFailure(err.response.data));

                }
            })
    }
}

