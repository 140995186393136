import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { useSelector, useDispatch, shallowEqual } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "default",
    height: 100,
    width: 100
  },
  differenceIcon: {
    color: "default"
  },
  differenceValue: {
    color: "default",
    marginRight: theme.spacing(1)
  },
  DashCard:{
    border:"1px solid #E36A26",
    background:"#FFEAE0"
  }
}));

const Budget = ({ className, ...rest }) => {
  const classes = useStyles();
  


  const DashBoardData = useSelector((state) =>
  state.DashBoard &&
  state.DashBoard.SubData &&
  Object.keys(state.DashBoard.SubData) &&
  Object.keys(state.DashBoard.SubData).length 
  ? state.DashBoard.SubData.users
  : 0
  );
  console.log(DashBoardData)


  // console.log(DashBoardData)
  return (
    <Card className={classes.DashCard}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Total Number of Users
            </Typography>
            <Typography color="textPrimary" variant="h3" >
              {DashBoardData}
            </Typography>
          </Grid>        
        </Grid>        
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string
};

export default Budget;
