import {ADD_PLAN_LOADING , ADD_PLAN_SUCCESS , ADD_PLAN_FAILURE } from '../../Actions/Types';
import {GET_PLAN_LOADING , GET_PLAN_SUCCESS , GET_PLAN_FAILURE } from '../../Actions/Types';
import {EDIT_PLAN_LOADING , EDIT_PLAN_SUCCESS , EDIT_PLAN_FAILURE } from '../../Actions/Types';
import {DEL_PLAN_LOADING , DEL_PLAN_SUCCESS , DEL_PLAN_FAILURE } from '../../Actions/Types';

import {CLEAN_ALL_DATA, CLEAN_PLAN_DATA} from '../../Actions/Types';

function rmOrder(allOrders, index) {
    if (Array.isArray(allOrders)) {
      let newOrders = allOrders;
      newOrders.splice(index, 1);
      return newOrders;
    } else {
      return allOrders;
    }
  }





const AddPlan = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            delData:[{}],
            delError:{}
            
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{}
            
        }

    case CLEAN_PLAN_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            editData : [{}],
            editError : {},
            delData:[{}],
            delError:{}
            
        }

    case ADD_PLAN_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case ADD_PLAN_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case ADD_PLAN_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }



        case GET_PLAN_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                
            }
        case GET_PLAN_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data
            }
        case GET_PLAN_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                data:{},
            }   
        
        case EDIT_PLAN_LOADING:
            return {
                ...state,
                loading:true,
                editData :[{}],
                editError : {},            
                
            }
        case EDIT_PLAN_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_PLAN_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }

        case DEL_PLAN_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_PLAN_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
                getData :rmOrder(state.getData, action.index)
            }
        case DEL_PLAN_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }

    default:
        return state

}
}



export default AddPlan;
