import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import {getRoles , delRoles} from '../../../Actions/SuperAdminActions/AddRoles'
import { cleanRoles } from '../../../Actions/ActionCreators'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  




  const listData = useSelector((state) =>
  state.AddRoles && state.AddRoles.getData &&
    state.AddRoles.getData.length &&
    Array.isArray(state.AddRoles.getData)
      ? state.AddRoles.getData
      : []
  );



  const [open, setOpen] = useState({show:false, id:""});

  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.role_id
    });
  
  }

  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delRoles(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }

  const addmsg = useSelector(state => state.AddRoles && state.AddRoles.data && state.AddRoles.data.status && state.AddRoles.data.status===200  ? state.AddRoles.data.status : null  )
 
  const Delmsg = useSelector(state => state.AddRoles && state.AddRoles.delData && state.AddRoles.delData.message ? state.AddRoles.delData.message : null  )
  const Delerror = useSelector(state => state.AddRoles && state.AddRoles.delError && state.AddRoles.delError.message ? state.AddRoles.delError.message : null  )




  const editmsg = useSelector(state => state.AddRoles && state.AddRoles.editData && state.AddRoles.editData.status && state.AddRoles.editData.status===200  ? state.AddRoles.editData.status : null  )
  //   const adderror = useSelector(state => state.Mh.error && state.Mh.error.message ? state.Mh.error.message : null  )
  
   
useEffect( () => {

    dispatch(getRoles())


    let x  = new Promise((resolve ,reject)=>{
      if(addmsg && addmsg!==null && addmsg===200)
      {
        tostS("Added SuccessFully !!")
      }
      if(editmsg &&  editmsg!==null && editmsg===200)
      {
        tostS("Updated Successfully !!")
      }
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanRoles())
    dispatch(getRoles())

    })


    
  }, [ Delmsg  ,Delerror, editmsg, addmsg ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/roles_edit/'+olData.role_id , { state: olData })
}


 







  


  const addNewBornbutton = () =>{
    navigate('/app/roles_add')
    console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Added Roles">
      <ToastContainer    />


      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Role??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add Role
              </Button>
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Added Roles"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'Role ID', field: 'role_id',editable: "never", },
        { title: 'Role Name', field: 'role_name',editable: "never", },          
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (
            rowData.role_id===1 && rowData.role_name==='All' ? 
            <></>:<Grid container className={classes.root2}>
              <Grid item xs={4}>

              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              {/* <Grid item xs={4}>
                <VisibilityIcon />
              </Grid> */}
            </Grid>
            
            
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
