import {GET_SUB_GST_LOADING , GET_SUB_GST_SUCCESS , GET_SUB_GST_FAILURE } from '../../Actions/Types';
import {CLEAN_ALL_DATA } from '../../Actions/Types';


const Gst = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,            
            getData:[{}],
            getError:{},
            
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{}
            
        }


        case GET_SUB_GST_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                
            }
        case GET_SUB_GST_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data
            }
        case GET_SUB_GST_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   


    default:
        return state

}
}



export default Gst;
