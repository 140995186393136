// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {getQualitySafetyBegin , getQualitySafetySuccess , getQualitySafetyFailure} from '../ActionCreators';
import {delQualitySafetyBegin , delQualitySafetySuccess ,  delQualitySafetyFailure} from '../ActionCreators';




export const getQualitySafety = (x) => {
    var parameter = x;
    if(parameter.start_date==="" || parameter.start_date===null || parameter.start_date===undefined){
        parameter.start_date=new Date('2001', '01', '01')
    }
    return (dispatch) => {
        dispatch(getQualitySafetyBegin());

        axios.get(Base_URL + '/subadmin/getqualandsafety' , {params : parameter})
            .then((res) => {
                dispatch(getQualitySafetySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getQualitySafetyFailure(err.response.data));

                }
            })
    }
}

export const delQualitySafety = (x) => {

   return (dispatch) => {
       dispatch(delQualitySafetyBegin());

       console.log(x);
       axios.get(Base_URL + '/subadmin/deleteQualtyAndSafety' , {params :{qs_id : x}})
           .then((res) => {
               console.log(res.data)
               dispatch(delQualitySafetySuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   alert("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(delQualitySafetyFailure(err.response.data));

               }
           })
   }
}
