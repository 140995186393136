import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getClientSites } from '../../../Actions/SubAdminActions/M_AddSites';
import {
    Container,
    Grid,
    makeStyles,
    Card,
    CardContent,
    Divider,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    LinearProgress,
  } from '@material-ui/core';
import Page from '../../../../src/components/Page';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSites } from '../../../Actions/SubAdminActions/M_AddSites';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from '../../../Config/Interceptor2';
import { Base_URL } from '../../../Config/BaseUrlConfig';
import { ToastContainer } from 'react-toastify';
import { tostS, tostE } from '../../../Config/Toast';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  progressBar: {
    width: '100%',
  },
}));

const ClientWeeklyReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = useSelector(state => state.login.data.admin_id);
  const SiteId = useSelector(state => state.login.data.site_id);
  const a_id = useSelector((state) =>
    state.login && state.login.data && state.login.data.r_subadmin
      ? state.login.data.r_subadmin
      : ""
  );
  const selected_site = useSelector(
    (state) =>
      state.SelectedSite &&
      state.SelectedSite.sitedata &&
      state.SelectedSite.sitedata.site
        ? state.SelectedSite.sitedata.site
        : null
  );
  const SiteData = useSelector(
    (state) =>
      state.AddSites &&
      state.AddSites.getData &&
      state.AddSites.getData.length &&
      Array.isArray(state.AddSites.getData)
        ? state.AddSites.getData
        : []
  );

  const [startDate, setStartDate] = useState(moment().startOf('day').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

  const [values, setValues] = useState({
    sub_admin_id: id,
    site_id: SiteId,
  });

  const [measurementBook, setMeasurementBook] = useState([]);

 
  useEffect(() => {
    console.log(id, '======================================================================================================');
    dispatch(getClientSites(id));
  }, []);



//   useEffect(() => {
//     console.log(selected_site,'jsjksjdsjsjsjsdksjsdjsdlkdkl');
//     if (selected_site && startDate && endDate) {
//       fetchMeasurementBook();
//     }
//   }, [selected_site, startDate, endDate]);

  //to generate excel
  const generateExcel = () => {
    if (measurementBook && measurementBook.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(measurementBook);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Measurement Book');
      XLSX.writeFile(workbook, 'Measurement_Book.xlsx');
    } else {
     tostE('No data found')
    }
  };
  

  const generatePDF = () => {
    if (measurementBook && measurementBook.length > 0) {
      const doc = new jsPDF();
  
      // Set document properties
      doc.setProperties({
        title: 'Measurement Book PDF',
        subject: 'Measurement Book data',
        author: 'Your Name',
        keywords: 'measurement, book, data',
      });
  
      // Add content to the PDF
      doc.setFontSize(18);
      doc.text('Measurement Book Data', 14, 10);
  
      // Aggregate measurementBook data by item_description
      const aggregatedData = Object.values(
        measurementBook.reduce((acc, row) => {
          if (!acc[row.item_description]) {
            acc[row.item_description] = { ...row };
          } else {
            acc[row.item_description].length += row.length || 0;
            acc[row.item_description].breadth += row.breadth || 0;
            acc[row.item_description].dia += row.dia || 0;
            acc[row.item_description].height += row.height || 0;
            acc[row.item_description].noofitems += row.noofitems || 0;
            acc[row.item_description].quantity += row.quantity || 0;
          }
          return acc;
        }, {})
      );
  
      // Prepare table data for autoTable
      const tableData = aggregatedData.map((row, index) => [
        index + 1, // Measurement ID
        row.item_description || '-', // Item Description
        row.length || '-', // Length
        row.breadth || '-', // Breadth
        row.dia || '-', // Diameter
        row.height || '-', // Height
        row.noofitems || '-', // No of Items
        row.quantity.toFixed(2), // Quantity
        row.unit || '-', // Unit
        row.remark || '-', // Remarks
        `${calculateProgress(row.quantity, row.boq_quantity).toFixed(2)}%`, // Progress
      ]);
  
      // Add table to the PDF
      doc.autoTable({
        head: [
          ['Measurement ID', 'Item Description', 'Length', 'Breadth', 'Diameter', 'Height', 'No of Items', 'Quantity', 'Unit', 'Remarks', 'Progress(%)']
        ],
        body: tableData,
        startY: 20, // Adjust startY position if needed
      });
  
      // Save the PDF
      doc.save('Measurement_Book.pdf');
    } else {
      tostE('No data found')
    }
  };
  

  const fetchMeasurementBook = () => {
    const requestData = {
      sub_admin_id: id,
      site_id: values.site_id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    };

    axios
      .post(`${Base_URL}/client/ClientgetMonthlyReport`, requestData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          if (response.data.measurement_book) {
            setMeasurementBook(response.data.measurement_book);
            tostS('Measurement book fetched successfully');
          } else {
            setMeasurementBook([]);
            tostE('No measurement book data available for the selected date and site.');
          }
        } else {
          tostE('Failed to fetch measurement book: ');
          setMeasurementBook([]);
        }
      })
      .catch((err) => {
        tostE('Measurement book not found for this day ');
        setMeasurementBook([]);
      });
  };

  const handleChangeSiteName = (event) => {
    setValues({ ...values, site_id: event.target.value });
  };

  const getSiteName = (site_id) => {
    const site = SiteData.find((site) => site.site_id === site_id);
    return site ? site.site_name : 'N/A';
  };

  const calculateProgress = (quantity, boqQuantity) => {
    if (boqQuantity === 0) return 0;
    return (quantity / boqQuantity) * 100;
  };

  return (
    <Page className={classes.root} title="Monthly Report">
      <ToastContainer />

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <h3 style={{ padding: '10px' }}>Monthly Report</h3>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="start-date-picker"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        value={startDate}
                        onChange={setStartDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="end-date-picker"
                        label="End Date"
                        format="MM/dd/yyyy"
                        value={endDate}
                        onChange={setEndDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">Site Name</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleChangeSiteName}
                        label="Site Name"
                        value={values.site_id}
                        inputProps={{ readOnly: true }}
                      >
                        {SiteData.map((data, i) =>
                          <MenuItem key={i} value={data.site_id}>{data.site_name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} style={{ float: "right" }}>
                    <Button onClick={fetchMeasurementBook} color="primary" variant="contained">
                      Get Measurement Book
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Measurement Book (MB)
                </Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="measurement book table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Measurement ID</TableCell>
                        <TableCell>Item Description</TableCell>
                        <TableCell>Length</TableCell>
                        <TableCell>Breadth</TableCell>
                        <TableCell>Diameter</TableCell>
                        <TableCell>Height</TableCell>
                        <TableCell>No of Items</TableCell>
                        <TableCell>Quantity</TableCell>
                        <
                        TableCell>Unit</TableCell>
{/* <TableCell>Total used</TableCell> */}
<TableCell>Remarks</TableCell>
<TableCell>Progress</TableCell> {/* Progress bar */}
</TableRow>
</TableHead>
<TableBody>
{measurementBook && measurementBook.length > 0 ? (
Object.values(
  measurementBook.reduce((acc, row) => {
    if (!acc[row.item_description]) {
      acc[row.item_description] = { ...row };
    } else {
      acc[row.item_description].length += row.length || 0;
      acc[row.item_description].breadth += row.breadth || 0;
      acc[row.item_description].dia += row.dia || 0;
      acc[row.item_description].height += row.height || 0;
      acc[row.item_description].noofitems += row.noofitems || 0;
      acc[row.item_description].quantity += row.quantity || 0;
    }
    return acc;
  }, {})
).map((row, index) => (
  <TableRow key={index}>
    <TableCell>{index + 1}</TableCell>
    <TableCell>{row.item_description}</TableCell>
    <TableCell>{row.length}</TableCell>
    <TableCell>{row.breadth}</TableCell>
    <TableCell>{row.dia}</TableCell>
    <TableCell>{row.height}</TableCell>
    <TableCell>{row.noofitems}</TableCell>
    <TableCell>{row.quantity.toFixed(2)}</TableCell>
    <TableCell>{row.unit}</TableCell>
    {/* <TableCell>{row.used_quantity}</TableCell> */}
    <TableCell>{row.remark}</TableCell>
    <TableCell>
      {/* Progress bar */}
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            className={classes.progressBar}
            variant="determinate"
            value={calculateProgress(row.quantity, row.boq_quantity)}
            style={{ width: '200px' }}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
          {calculateProgress(row.quantity, row.boq_quantity).toFixed(2)}%
          </Typography>
        </Box>
      </Box>
    </TableCell>
  </TableRow>
))
) : (
<TableRow>
  <TableCell colSpan={11} align="center">
    No Measurement Book Data
  </TableCell>
</TableRow>
)}
</TableBody>
</Table>
</TableContainer>
</CardContent>
</Card>
</Grid>
<Grid item lg={12} md={12} xs={12}>
            {/* Button to generate Excel */}
            <Button
              onClick={generateExcel}
              color="primary"
              variant="contained"
            >
              Export to Excel
            </Button>
 
             <Button
              onClick={generatePDF}
              color="secondary"
              variant="contained"
              style={{marginLeft:"5px"}}
            >
              
              Export to PDF
            </Button>
          </Grid>
</Grid>
</Container>
</Page>
);
};

export default ClientWeeklyReport;
