// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {getMachineDeliveryBegin , getMachineDeliverySuccess , getMachineDeliveryFailure} from '../ActionCreators';
import {delMachineDeliveryBegin , delMachineDeliverySuccess ,  delMachineDeliveryFailure} from '../ActionCreators';




export const getMachineDelivery = (x) => {
    var parameter = x;
    if(parameter.start_date==="" || parameter.start_date===null || parameter.start_date===undefined){
        parameter.start_date=new Date('2001', '01', '01')
    }
    return (dispatch) => {
        dispatch(getMachineDeliveryBegin());

        axios.get(Base_URL + '/subadmin/getMachineReport' , {params : parameter})
            .then((res) => {
                dispatch(getMachineDeliverySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getMachineDeliveryFailure(err.response.data));

                }
            })
    }
}

export const delMachineDelivery = (x) => {

   return (dispatch) => {
       dispatch(delMachineDeliveryBegin());

       console.log(x);
       axios.get(Base_URL + '/subadmin/deleteMachineReport' , {params :{rm_id : x}})
           .then((res) => {
               console.log(res.data)
               dispatch(delMachineDeliverySuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   alert("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(delMachineDeliveryFailure(err.response.data));

               }
           })
   }
}
