import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#E36A26'

const shade1 = "#3b4e87"
const shade2 = "#7a8dc5"
const shade3 = "#bfbfbf"
const shade4 = "#000000"
const shade5 = "#ffffff"

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: shade1,
        backgroundColor: shade1,
        color:shade5,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    description: {
        width: '38%',                
    },
    numb: {
        width: '7%',                
    },
    qty: {
        width: '15%',                
    },
    rate: {
        width: '15%',                
    },
    gst: {
        width: '10%',                
    },
    amount: {
        width: '15%'
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.numb}>Item#</Text>
        <Text style={styles.description}>Description</Text>
        <Text style={styles.qty}>QTY</Text>
        <Text style={styles.rate}>UNIT PRICE</Text>
        <Text style={styles.gst}>GST %</Text>
        <Text style={styles.amount}>TOTAL</Text>
    </View>
  );
  
  export default InvoiceTableHeader