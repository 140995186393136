import { DAILYREPORT_FAILURE, DAILYREPORT_LOADING, DAILYREPORT_SUCCESS, MONTHLYREPORT_FAILURE, MONTHLYREPORT_LOADING, MONTHLYREPORT_SUCCESS } from "../../Actions/Types";


console.log('hereeee');
const MonthlyReportData = (state , action) => {
    console.log(action.type) 
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],  
            editError : {},
            editPassData :[{}],
            editPassError : {},
            delData:[{}],
            delError:{}
        }
    }




switch(action.type) {
    
   
    case MONTHLYREPORT_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case MONTHLYREPORT_SUCCESS:

    console.log('hereeeeeeeee');
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case MONTHLYREPORT_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }

    default:
        return state

}
}



export default MonthlyReportData;
