import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";

import {getSubRole, delSubRole} from '../../../../Actions/SubAdminActions/SubRole'
import {cleanSubRole} from '../../../../Actions/ActionCreators'

import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);


  const [open, setOpen] = useState({show:false, id:""});


  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.sub_role_id
    });
  
  }
  
  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delSubRole(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const listData= useSelector((state) =>
  state.Sub_Role && state.Sub_Role.getData &&
    state.Sub_Role.getData.length &&
    Array.isArray(state.Sub_Role.getData)
      ? state.Sub_Role.getData
      : []
  );


  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);



  const sub_aid = useSelector(state => state.login.data.r_subadmin)  
 
  const Delmsg = useSelector(state => state.Sub_Role && state.Sub_Role.delData && state.Sub_Role.delData.message ? state.Sub_Role.delData.message : null  )
  const Delerror = useSelector(state => state.Sub_Role && state.Sub_Role.delError && state.Sub_Role.delError.message ? state.Sub_Role.delError.message : null  )






  

  
useEffect( () => {

    dispatch(getSubRole(sub_aid))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanSubRole())
    dispatch(getSubRole(sub_aid))

    })


    
  }, [ Delmsg  ,Delerror ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/master/role_edit/'+olData.sub_role_id , { state: olData })
}


// const onDel = (oldata) =>{

//   dispatch(delSubRole(oldata.sub_role_id ,oldata.tableData.id ))
// }
 

const [values, setValues] = useState({
   
  country:"",


  });



const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleDateChange = (date) => {
  setSelectedDate(date);
};


const handleChanged = (event) => {
  setValues({...values, ['country']:event.target.value});
};



const exportField = [
  {
    field : "sub_role_id" , 
    val : "Role ID"
  }, 
  {
    field : "role_name" , 
    val : "Role Name"
  }, 
  
]





  


  const addNewBornbutton = () =>{
    navigate('/app/master/role_list_add')
    console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Added Roles">
      <ToastContainer    />



      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Role??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>






        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Roles')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Roles')
              }>
                Export to PDF
            </Button>
            <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add Role
              </Button>
            </Box>
{/*                        
<div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
          <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  // value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              </ThemeProvider>

        </Grid>
   <Grid item md={6} lg={3}  xs={3}>
 
          <FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
        <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Filter By Supplier</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.country}
          onChange={handleChanged}
          label="Filter By Supplier"
          style={{width:"100%" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>


<MenuItem   value="Resource Type 1">Supplier-1</MenuItem>
<MenuItem   value="Resource Type 1">Supplier-2</MenuItem>
<MenuItem   value="Resource Type 1">Supplier-3</MenuItem>


        </Select>
      </FormControl>
        </Grid> 
        
        
         <Grid item  md={6} lg={3}  xs={3}>

        <FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
        <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Drawing Type</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.country}
          onChange={handleChanged}
          label="Filter By Status"
          style={{width:"100%" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>


<MenuItem   value="Resource Type 1">Drawing-1</MenuItem>
<MenuItem   value="Resource Type 1">Drawing-2</MenuItem>
<MenuItem   value="Resource Type 1">Drawing-3</MenuItem>


        </Select>
      </FormControl>

</Grid> 
<Grid item md={6} lg={3}  xs={3}>

<FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
<InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
<Select
fullWidth
labelId="demo-simple-select-outlined-label"
id="demo-simple-select-outlined"
value={values.country}
onChange={handleChanged}
label="Export As"
style={{width:"100%" }}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 2" onClick={()=>exportToExcel([{field :"sub_role_id" , val : "Role Id"} , {field :"role_name" , val : "Role Name"} , {field : "site_name" , val:"Site Name"}] , listData)}>Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem> 


</Select>
</FormControl>
</Grid>
        
      </Grid>
    </div>
     */}
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Added Roles"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'SR.No.', render : rowData =>(
          <>
          {rowData.tableData.id +1}
          </>
        ) },
        { title: 'Role Name', field: 'role_name',editable: "never", },          
        
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (

            rowData.role_name !== 'All' ? 
            
            <Grid container className={classes.root2}>
              {role_permit[4] ?
              <Grid item xs={4}>
                
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
              {role_permit[2] ?
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
              
            </Grid> : <></>


            
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
