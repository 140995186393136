import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {useNavigate} from 'react-router-dom'
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import { addSites, getSites } from '../../../../Actions/SubAdminActions/M_AddSites';
import {cleanSites} from '../../../../Actions/ActionCreators';


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate()



  const id = useSelector(state => state.login.data.admin_id);
  const plan_id = useSelector(state => state.login.data.plan_id);
 

  const [values, setValues] = useState({
   
  admin_id :id,
  start_date :moment(new Date(Date.now())),
  end_date :moment(new Date(Date.now())),
  plan_id : plan_id,
  site_name:"",
  site_address:"",
  budget:""
  

  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 


  const handleDateChange = (date) => {        
    setValues({...values, ['end_date'] : date  })

  };

  const handleDateChange1 = (date) => {    
    
    setValues({...values, ['start_date'] : date  })

  };

  const handleTimeChange = (date) =>{

    console.log(date)

  }


  const handleSubmit = () =>{
      console.log(values)

    if(values.site_name.length <= 2){
      tostE("Site Name Must be Valid (Greater than 2 Alphabets)")
    }else if (values.budget <= 0){
      tostE("Budget Must Be Positive")
    }else if (values.site_address.length <= 2){
    tostE("Site Address Must Be Valid (Greater than 2 Alphabets)")
    }

    else{
    dispatch(addSites(values))
    navigate('/app/master/site_list')
    setValues({
      ...values, 
      start_date :moment(new Date(Date.now())),
      end_date :moment(new Date(Date.now())),
      site_name:"",
      site_address:"",
      budget:""    
    });
    }
  }





  // useEffect( () => {
    
  //   var r  = new Promise((resolve , reject)=>{
  //    if(addmsg === 200){
  //     tostS("Site Added Successfully!!!")
  //    }

  //   else
  //   if(adderr !== null){
  //     tostE(adderr)
  //    }
  
  //    resolve()
    
  //   }) 
  //   r.then(()=>{
  //    dispatch(cleanSites())
  //   })
    
  // }, [addmsg, ,adderr])




  return (
    <Page
      className={classes.root}
      title="Add Site"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
        
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add Site" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Site Name"
                name="site_name"
                onChange={handleChange}                
                required
                value={values.site_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Budget"
                type = "number"
                name="budget"
                onChange={handleChange}
                required
                value={values.budget}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Site Address"
                name="site_address"
                onChange={handleChange}
                required
                value={values.site_address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}></Grid>
            
            <Grid item md={6} xs={12}>
            <ThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disablePast={true}
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Start Date"
                  format="dd/MM/yyyy"
                  value={values.start_date}
                  onChange={handleDateChange1}
                  variant="outlined"
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Grid>

            <Grid item md={6} xs={12}>
            <ThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disablePast={true}
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog"
                  label="End Date"
                  value={values.end_date}
                  format="dd/MM/yyyy"
                  onChange={handleDateChange}
                  variant="outlined"
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Grid>
      

            
          
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
