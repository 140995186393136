import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';


const shade1 = "#3b4e87"
const shade2 = "#7a8dc5"
const shade3 = "#bfbfbf"
const shade4 = "#000000"
const shade5 = "#ffffff"

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: shade3,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '38%',
        textAlign: 'center',
        borderRightColor: shade4,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '15%',
        borderRightColor: shade4,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: shade4,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    gst: {
        width: '10%',
        borderRightColor: shade4,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    numb: {
        width: '7%',
        borderRightColor: shade4,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
    },
  });


const InvoiceTableRow = ({items, type}) => {
    const rows = items.map( (item,i) => 
        <View style={styles.row} key={i}>
            <Text style={styles.numb}>{i+1}</Text>
            {type==="material" ?
            <Text style={styles.description}>{item.mat_name} {item.mat_type}</Text>
            :
            <Text style={styles.description}>{item.mac_name} {item.mac_type}</Text>
            
            }
            <Text style={styles.qty}>{item.quan} {item.unit}</Text>
            <Text style={styles.rate}>{item.amt!=="" && item.amt!==null && item.amt!==undefined ? parseFloat(item.amt).toFixed(2) : "-"}</Text>
            <Text style={styles.gst}>{item.gst_per!=="" && item.gst_per!==null && item.gst_per!==undefined ? parseFloat(item.gst_per).toFixed(2) :"-"}</Text>
            <Text style={styles.amount}>
                {item.amt!=="" ? 
                parseFloat(item.amt).toFixed(2)
                :"-"
                }

            
            </Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow