import axios from "../../Config/Interceptor2";


// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addSitesBegin , addSitesSuccess , addSitesFailure} from '../ActionCreators'
import {getSitesBegin , getSitesSuccess , getSitesFailure} from '../ActionCreators'
import {getSitesMasterBegin , getSitesMasterSuccess , getSitesMasterFailure} from '../ActionCreators'
import {delSitesBegin , delSitesSuccess , delSitesFailure} from '../ActionCreators'
import {editSitesBegin , editSitesSuccess , editSitesFailure} from '../ActionCreators';

export const addSites = (session) => {

    return (dispatch) => {
        dispatch(addSitesBegin());

        axios.post(Base_URL + '/subadmin/addsite', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addSitesSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addSitesFailure(err.response.data));

                }
            })
    }
}
 

export const getSites = (x,y) => {
    console.log("_________________________________")
    console.log(x,y)
    console.log("_________________________________")

    return (dispatch) => { 
        dispatch(getSitesBegin());
        if(y===null || y===undefined || y===""){
            axios.get(Base_URL + '/subadmin/getsite' , {params : {admin_id : x}})
                .then((res) => {
                    console.log(res,'============================================');
                    dispatch(getSitesSuccess(res.data));

                    console.log("without inventory")
                }).catch((err) => {
                    console.log(err)
                    if (!err.response) {
                        alert("Something Went wrong : Please Check Network or Server Connectivity")
    
                    }
                    else {
                        dispatch(getSitesFailure(err.response.data));
    
                    }
                })

        }else{
            axios.get(Base_URL + '/inventory/getsite' , {params : {admin_id : x, site_ids:y}})
                .then((res) => {
                    dispatch(getSitesSuccess(res.data));
                    console.log("with inventory")

                }).catch((err) => {
                    console.log(err)
                    if (!err.response) {
                        alert("Something Went wrong : Please Check Network or Server Connectivity")
    
                    }
                    else {
                        dispatch(getSitesFailure(err.response.data));
    
                    }
                })
        }
    }
}

   
export const getClientSites = (x,y) => {  
    console.log("_________________________________")
    console.log(x,y)
    console.log("_________________________________")
  
    return (dispatch) => {
        dispatch(getSitesBegin());
        if(y===null || y===undefined || y===""){
            axios.get(Base_URL + '/client/getClientallavisite' , {params : {admin_id : x}})
                .then((res) => {
                    console.log(res,'============================================');
                    dispatch(getSitesSuccess(res.data));

                    console.log("without inventory")
                }).catch((err) => {
                    console.log(err)
                    if (!err.response) {
                        alert("Something Went wrong : Please Check Network or Server Connectivity")
    
                    }
                    else {
                        dispatch(getSitesFailure(err.response.datan
                            
                        ));
    
                    }
                })

        }else{
            axios.get(Base_URL + '/client/getClientallavisite' , {params : {admin_id : x, site_ids:y}})
                .then((res) => {
                    dispatch(getSitesSuccess(res.data));
                    console.log("with inventory")

                }).catch((err) => {
                    console.log(err)
                    if (!err.response) {
                        alert("Something Went wrong : Please Check Network or Server Connectivity")
    
                    }
                    else {
                        dispatch(getSitesFailure(err.response.data));
    
                    }
                })
        }
    }
}



export const getSitesMaster = (x) => {

    return (dispatch) => {
        dispatch(getSitesMasterBegin());

        axios.get(Base_URL + '/subadmin/getsitesmaster' , {params : {admin_id : x}})
            .then((res) => {
                dispatch(getSitesMasterSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSitesMasterFailure(err.response.data));

                }
            })
    }
}

export const delSites = (data) => {

    return (dispatch) => {
        dispatch(delSitesBegin());

        axios.post(Base_URL + '/subadmin/editsiteact' , data)
            .then((res) => {
                console.log(res.data)
                dispatch(delSitesSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delSitesFailure(err.response.data));

                }
            })
    }
}

export const editSites = (session) => {

    return (dispatch) => {
        dispatch(editSitesBegin());

        axios.post(Base_URL + '/subAdmin/editsites', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editSitesSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editSitesFailure(err.response.data));

                }
            })
    }
}

