import axios from "../../Config/Interceptor1";


// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {newMatBegin , newMatSuccess , newMatFailure} from '../ActionCreators'
import { tostE } from "../../Config/Toast";


export const newMatDelivery = (x) => {
    console.log(x)
   
    return (dispatch) => {
        dispatch(newMatBegin());

        axios.get(Base_URL + '/client/getMatDelivery' , {params : x})
            .then((res) => {
                dispatch(newMatSuccess(res.data));
                console.log("data======", res.data)
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // tostE("Not Data Found!!")
                    dispatch(newMatFailure({}))
                }
                else {
                    dispatch(newMatFailure(err.response.data));

                }
            })
    }
}


