import {GET_MACHINE_INDENT_LOADING , GET_MACHINE_INDENT_SUCCESS , GET_MACHINE_INDENT_FAILURE, CLEAN_MACHINE_INDENT_DATA} from '../../Actions/Types';
import {DEL_MACHINE_INDENT_LOADING , DEL_MACHINE_INDENT_SUCCESS , DEL_MACHINE_INDENT_FAILURE} from '../../Actions/Types';
import {ORDER_MACHINE_INDENT_LOADING , ORDER_MACHINE_INDENT_SUCCESS , ORDER_MACHINE_INDENT_FAILURE} from '../../Actions/Types';
import {ACCEPT_MAC_INDENT_LOADING , ACCEPT_MAC_INDENT_SUCCESS , ACCEPT_MAC_INDENT_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const MachineIndent = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getData:[{}],
            getError:{},
            delData  : [{}] ,
            delError: {},
            editData  : [{}] ,
            editError: {},
            orderData  : [{}] ,
            orderError: {},
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,                
                loading:false,                
                getData:[{}],
                getError:{},
                delData : [{}], 
                delError:{},
                acceptData  : [{}] ,
                acceptError: {},
                orderData  : [{}] ,
                orderError: {},
            }
    
    
        case CLEAN_MACHINE_INDENT_DATA:
            return {
                ...state,                
                loading:false,                
                getData : [{}],
                getError:{},
                delData : [{}], 
                delError:{},
                acceptData  : [{}] ,
                acceptError: {},
                orderData  : [{}] ,
                orderError: {},
            }
    
        case GET_MACHINE_INDENT_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_MACHINE_INDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
                
            }
        case GET_MACHINE_INDENT_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                
            }   
        
        case DEL_MACHINE_INDENT_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_MACHINE_INDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_MACHINE_INDENT_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
        
        
        case ACCEPT_MAC_INDENT_LOADING:
            return {
                ...state,
                loading:true,
                acceptError: {},
                acceptData : [{}]
                
            }
        case ACCEPT_MAC_INDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                acceptError:{},
                acceptData:action.data,
            }
        case ACCEPT_MAC_INDENT_FAILURE:
            return {
                ...state,
                loading:false,
                acceptError:action.error,
                acceptData:{},
            }
        
        
        case ORDER_MACHINE_INDENT_LOADING:
            return {
                ...state,
                loading:true,
                orderError: {},
                orderData : [{}]
                
            }
        case ORDER_MACHINE_INDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                orderError:{},
                orderData:action.data,
            }
        case ORDER_MACHINE_INDENT_FAILURE:
            return {
                ...state,
                loading:false,
                orderError:action.error,
                orderData:{},
            }
        

        default:
        return state
    
    }
    }
    
    
    
export default MachineIndent;
    