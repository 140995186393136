import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
 
  return (
    <Page className={classes.root} title="Client Details" >
      <ToastContainer    />       
      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader="" title="Client Details" />
                <Divider />
                <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Name : {state.name}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Email :  {state.email}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Mobile No. :  {state.mobile}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                     <Typography variant="body2" color="text.secondary" spacing={3}>Number of Sites :  {state.number_of_sites}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Number of Users :  {state.users}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Storage Alloted :  {state.storage}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Plan Purchased :  {state.plan_name} ({state.plan_number})</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Plan Start Date :  {moment(state.plan_start_date).format("DD.MM.YYYY")}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Plan End Date :  {moment(state.plan_end_date).format("DD.MM.YYYY")}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Plan Price :  {state.price}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Payment Mode :  {state.order_type}</Typography>
                  </Grid>
                  
                  <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary" spacing={3}>
                  Status :
                    {state.is_active? 
                      <>
                      <Typography style={{color:"green"}} variant='overline'> Active</Typography>
                      </>                      
                      :
                      <>
                      <Typography style={{color:"red"}} variant='overline'> In-Active</Typography>
                      </>}   
                      </Typography>                               
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary" spacing={3}>
                    Geo Api :
                    {state.is_geo_api? 
                      <>
                      <Typography style={{color:"green"}} variant='overline'> Yes</Typography>
                      </>                      
                      :
                      <>
                      <Typography style={{color:"red"}} variant='overline'> No</Typography>
                      </>}    
                  </Typography>                              
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary" spacing={3}>
                    Whatsapp Api :
                    {state.is_whatsapp_api? 
                      <>
                      <Typography style={{color:"green"}} variant='overline'> Yes</Typography>
                      </>                      
                      :
                      <>
                      <Typography style={{color:"red"}} variant='overline'> No</Typography>
                      </>}     
                  </Typography>                             
                  </Grid>
                  
                </Grid>

                </CardContent>
                <Divider />
                
              </Card>
            </form>
          </Grid>
        </Grid>        
      </Container>
    </Page>
  );
};

export default AddBusiness;
