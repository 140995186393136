import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../../src/layouts/DashboardLayout';
import DashboardView from '../../../src/views/reports/DashboardView/index';
import ProductListView from '../../../src/views/product/ProductListView';
import SettingsView from '../../../src/views/settings/SettingsView';

import MaterialDeliveryReport from '../../Screens/Users/NewMaterialDeliveryReport/List'
import MaterialDeliveryReportDetails from '../../Screens/Users/NewMaterialDeliveryReport/Details'
import MachineReport from '../../Screens/Users/MachineReport/List'
import MachineReportDetails from '../../Screens/Users/MachineReport/Details'
import ManpowerReport from '../../Screens/Users/ManpowerReport/List'
import ManpowerReportDetails from '../../Screens/Users/ManpowerReport/Details'
import ExpenseReport from '../../Screens/Users/SiteExpense/List'
import SiteProgressReport from '../../Screens/Users/SiteProgress/List'
import SiteProgressReportDetail from '../../Screens/Users/SiteProgress/Details'
import TicketsList from '../../Screens/Users/Tickets/List'
import TicketsAdd from '../../Screens/Users/Tickets/Add'
import SiteDrawings from '../../Screens/Users/SiteDrawings/Add'
import FAQ from '../../Screens/Users/Faq/Add'
import Profile from '../../Screens/Users/Profile/Add'
import Demo from '../../Screens/Users/Demo/List'
import ViewBoq from '../../Screens/Users/BOQ/ViewBoq';
import ViewMB from '../../Screens/Users/BOQ/MB';
import ClienDailyReport from '../../Screens/Users/Reports/DailyReport';
import ClientWeeklyReport from '../../Screens/Users/Reports/MonthlyReport';
import EditBoq from '../../Screens/Users/BOQ/EditBoq';
import CreateBoq from '../../Screens/Users/BOQ/Add';
import AddManualBoq from '../../Screens/Users/BOQ/AddManual';










// Admin


const routes1 = [

  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
     
      { path: 'dashboard', element: <MaterialDeliveryReport/> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },

      { path: 'materialdeliveryreport', element: <MaterialDeliveryReport/> },
      { path: 'materialdeliveryreport/:id', element: <MaterialDeliveryReportDetails/> },
      { path: 'machinereport', element: <MachineReport/> },
      { path: 'machinereport/:id', element: <MachineReportDetails/> },
      { path: 'manpowerreport', element: <ManpowerReport/> },
      { path: 'manpowerreport/:id', element: <ManpowerReportDetails/> },
      { path: 'expensereport', element: <ExpenseReport/> },
      { path: 'sitereport', element: <SiteProgressReport/> },
      { path: 'sitereport/:id', element: <SiteProgressReportDetail/> },
      { path: 'addticket', element: <TicketsAdd/> },
      { path: 'listticket', element: <TicketsList/> },
      { path: 'sitedrawings', element: <SiteDrawings/> },
      { path: 'ClientBoq', element: <ViewBoq/> },
      { path: 'ClientMb', element: <ViewMB/> },
      { path: 'ClientEditBoq/:id', element: <EditBoq/> },
      { path: 'ClientDailyReport', element: <ClienDailyReport></ClienDailyReport>},
      { path: 'ClientWeeklyReport', element: <ClientWeeklyReport></ClientWeeklyReport>},
      { path: 'UploadBoq', element: <CreateBoq />},
      { path: 'UploadManualBoq', element: <AddManualBoq />},
      { path: 'faq', element: <FAQ/> },
      { path: 'profile', element: <Profile/> },
      { path: 'demo', element: <Demo/> },
      
      { path: '404', element: <DashboardView/> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }

    ]
  },

  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'login', element: <DashboardView /> },
      { path: 'register', element: <DashboardView /> },
      { path: '404', element: <DashboardView/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }
    ]
  }
  
];

export default routes1;
