import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { useSelector, useDispatch, shallowEqual } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "default",
    height: 100,
    width: 100
  },
  differenceIcon: {
    color: "default"
  },
  differenceValue: {
    color: "default",
    marginRight: theme.spacing(1)
  },
  DashCard:{
    border:"1px solid #E36A26",
    background:"#FFEAE0"
  }
}));

function LinearProgressWithLabel(props) {  
    
   var total_storage = parseInt(props.value.total)*(1024**3);
   var consumed_storage = parseInt(props.value.state)

   var consume_per = parseFloat((consumed_storage/total_storage)*100).toFixed(2);
   
   const convertBytes = (bytes) => {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    
      
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    
      if (i == 0) {
        return bytes + " " + sizes[i]
      }
    
      return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i]
    }

   return (
     <Box sx={{ display: 'flex', flexDirection:'column'}}>
     <Typography color="textSecondary" gutterBottom variant="h6">
              Total Consumed Storage
            </Typography>
       <Box sx={{ width: '100%', mr: 1 }}>
         <LinearProgress variant="determinate" value={consume_per} />
       </Box>
       <Box sx={{ minWidth: 35 }}>
         <Typography variant="caption" color="text.secondary">
         {`${consume_per}% (${convertBytes(consumed_storage)}/${props.value.total} GB)`}
         </Typography>
       </Box>
     </Box>
   );
 }
 
 export default function LinearWithValueLabel({ className,dashdata, ...rest }) {
   const TotalStore = useSelector(state => state.login.data && state.login.data.storage ? (state.login.data.storage): 0);
   
   const classes = useStyles();

   var total_storage = parseInt(useSelector(state => state.login.data && state.login.data.storage ? (state.login.data.storage).split(" ")[0] : 0))*(1024**3);
   var consumed_storage = parseInt(useSelector(state => state.DashBoard.SubData && state.DashBoard.SubData.size_used ? state.DashBoard.SubData.size_used : 0))

   var consume_per = parseFloat((consumed_storage/total_storage)*100).toFixed(2);
   
   const convertBytes = (bytes) => {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    
      if(bytes===0){
        return "0 Bytes"
      }
      
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

      if (i === 0) {
        console.log(bytes, sizes[i])
        return bytes + " " + sizes[i]
      }
    
      return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i]
    }
   
 
   return (
      <Card className={classes.DashCard}>
      <CardContent>

         <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection:'column'}}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Total Consumed Storage
            </Typography>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={parseFloat((consumed_storage/total_storage)*100).toFixed(2)} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="caption" color="text.secondary">{`${parseFloat((consumed_storage/total_storage)*100).toFixed(2)}% (${convertBytes(consumed_storage)}/${TotalStore})`}</Typography>
            </Box>
          </Box>
         </Box>
      </CardContent>
      </Card>
   );
 }

 
 LinearProgressWithLabel.propTypes = {
   /**
    * The value of the progress indicator for the determinate and buffer variants.
    * Value between 0 and 100.
    */
   value: PropTypes.array,
 };
 