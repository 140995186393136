import React from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
// import logo from '/static/images/logoWhite.png'

const styles = StyleSheet.create({
   
    footerContainer:{
        flexDirection: 'row',        
        backgroundColor:"#E36A26",
        marginTop:"5%",
        width:"100%",
        padding:"2px",
        fontSize:12
    },
    detailContainer:{
        flexDirection: 'column',        
        marginLeft:"5%",
        width:"40%"
    },
    detailTitle:{
             
        color:"#000000"
    },
    detailContent:{
                
        color:"#ffffff"
    },
    addContainer:{
        flexDirection: 'column',        
        fontSize:8,
        paddingTop:"20px",
        color:"#ffffff" ,          
        alignItem:"right",
        textAlign:"right",
        width:"40%"
    },
    
    addContent:{
                
        color:"#ffffff"
    },
    
    logo: {
        width: 74,
        height: 66,
       
    }
  });


  const InvoiceFooter = ({invoice}) => (
    <View style={styles.footerContainer}>
        <Image style={styles.logo} src="/static/images/logoWhite.png" />
        <View style={styles.detailContainer}>
            <Text style={styles.detailTitle}>{invoice.client_name}</Text>
            <Text style={styles.detailContent}>{invoice.client_address}</Text>
            <Text style={styles.detailContent}>{invoice.client_email}</Text>
            <Text style={styles.detailContent}>{invoice.client_mobile}</Text>
        </View>
        <View style={styles.addContainer}>
            <Text style={styles.addContent}>For questions concerning this Purchase Order, please contact</Text>
            <Text style={styles.addContent}>John Doe, (555) 112-7890, info@ www.thekedar.site</Text>
            <Text style={styles.addContent}>www.thekedar.site/support</Text>
        </View>
    </View>
  );
  
  export default InvoiceFooter