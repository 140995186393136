import { addVendor } from '../../Actions/SubAdminActions/M_Vendor';
import {ADD_VENDOR_LOADING , ADD_VENDOR_SUCCESS , ADD_VENDOR_FAILURE , CLEAN_VENDOR_DATA} from '../../Actions/Types';
import {GET_VENDOR_LOADING , GET_VENDOR_SUCCESS , GET_VENDOR_FAILURE} from '../../Actions/Types';
import {DEL_VENDOR_LOADING , DEL_VENDOR_SUCCESS , DEL_VENDOR_FAILURE} from '../../Actions/Types';
import {EDIT_VENDOR_LOADING , EDIT_VENDOR_SUCCESS , EDIT_VENDOR_FAILURE} from '../../Actions/Types';


const AddVendor = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {
        // case CLEAN_ALL_DATA:
        //     return {
        //         ...state,
        //         data: [{}],
        //         loading:false,
        //         error:{},
        //         getData:[{}],
        //         getError:{}
                
        //     }
    
    
        case CLEAN_VENDOR_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                // getData : [{}],
                editData : [{}],
                editError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_VENDOR_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                delData : [{}]
                
            }
        case ADD_VENDOR_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
                delData : [{}]
            }
        case ADD_VENDOR_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
                delData:[{}]
            }

        case GET_VENDOR_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                delData : [{}],
                
            }
        case GET_VENDOR_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data,
                delData:[{}]
            }
        case GET_VENDOR_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                delData : [{}]
            }   
        
        case DEL_VENDOR_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_VENDOR_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_VENDOR_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
                    
        case EDIT_VENDOR_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}]
                
            }
        case EDIT_VENDOR_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_VENDOR_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            

        default:
        return state
    
    }
    }
    
    
    
export default AddVendor;
    