import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';



import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";

import {getSitesMaster , delSites,getSites } from '../../../../Actions/SubAdminActions/M_AddSites'
import {cleanSites} from '../../../../Actions/ActionCreators'

import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);
  


  const [open, setOpen] = useState({show:false, id:""});


  const handleClose = () =>{

    setOpen({...open, show:false, site_id:"", sub_admin_id:"", is_active_site:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      site_id:data.site_id,
      sub_admin_id:data.admin_id,
      is_active_site:!data.is_active_site
    });
  
  }

  const handleConfirm = () =>{
    if(open.site_id!==""){
      const sendData = {
        sub_admin_id :open.sub_admin_id,
        is_active_site :open.is_active_site,
        site_id :open.site_id   
      }
      dispatch(delSites(sendData))
        // console.log(sendData)
  
      setOpen({
        ...open,
        show:false,
        site_id:"", 
        sub_admin_id:"", 
        is_active_site:""
      })
    }else{
      tostE("Can't Update Site")
    }
  }




  const listData = useSelector((state) =>
  state.AddSites && state.AddSites.masterData &&
    state.AddSites.masterData.length &&
    Array.isArray(state.AddSites.masterData)
      ? state.AddSites.masterData
      : []
  );




  
 



const [values, setValues] = useState({
  
});
 

const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

const handleDateChange = (date) => {
  console.log(date)
  setSelectedDate(date);
  
  var t =  moment(date).format("DD/MM/YYYY")
  console.log(t)
  // setValues({...values, ['end_date'] : t  })
  
};




const addmsg = useSelector((state) => state.AddSites.data && state.AddSites.data.status && state.AddSites.data.status === 200 ? state.AddSites.data.status : null  )
const adderr = useSelector((state) => state.AddSites.error && state.AddSites.error.mgs ? state.AddSites.error.mgs : null  )
const editmsg = useSelector((state) => state.AddSites.editData && state.AddSites.editData.message ? state.AddSites.editData.message : null  )
const editerr = useSelector((state) => state.AddSites.editError && state.AddSites.editError.mgs ? state.AddSites.editError.mgs : null  )

const Delmsg = useSelector(state => state.AddSites && state.AddSites.delData && state.AddSites.delData.message ? state.AddSites.delData.message : null  )
const Delerror = useSelector(state => state.AddSites && state.AddSites.delError && state.AddSites.delError.message ? state.AddSites.delError.message : null  )

const admin_id = useSelector(state => state.login.data.r_subadmin );

useEffect( () => {

    // dispatch(getBusiness())
    dispatch(getSitesMaster(admin_id))
    dispatch(getSites(admin_id))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      if(addmsg === 200){
        tostS("Site Added Successfully!!!")
       }
  
      if(adderr !== null){
        tostE(adderr)
       }
    
      if(editmsg && typeof(editmsg) === 'string'){
        tostS("Site Updated Successfully!!!")
       }
  
      if(editerr !== null){
        tostE(editerr)
       }
    

      resolve()

    })
    x.then(()=>{

    dispatch(cleanSites())
    dispatch(getSitesMaster(admin_id))

    })


    
  }, [ Delmsg  ,Delerror, addmsg, ,adderr, editerr, editmsg])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/master/site_list_edit/'+olData.site_id , { state: olData })
}


// const onDel = (oldata) =>{

//   dispatch(delSites(oldata.site_id ,oldata.tableData.id ))
// }
const exportField = [
  {
    field : "index" , 
    val : "SR. No."
  }, 
  {
    field : "site_name" , 
    val : "Site Name"
  }, 
  {
    field : "site_address" , 
    val : "Site Address"
  }, 
  {
    field : "start_date" , 
    val : "Start Date"
  }, 
  {
    field : "end_date" , 
    val : "End date"
  }, 
  {
    field : "budget" , 
    val : "Budget"
  }, 
  {
    field : "left_amount" , 
    val : "Left Amount"
  }, 
  
]




const getExportData = () => {
  const FilterData = listData;
  var exportData = []

  if(Array.isArray(FilterData) && FilterData.length){
    FilterData.map((element,i) => {
      exportData.push(
        {
          "index":i+1,
          "site_name":element.site_name,
          "site_address":element.site_address,
          "start_date":moment(element.start_date).format("DD/MM/YYYY"),
          "end_date":moment(element.end_date).format("DD/MM/YYYY"),
          "budget":element.budget,
          "left_amount":element.left_amount,
        }
      )
    })
  }

  return exportData;
}
  

const handleExportDataExcel = () => {

  getExportFileBlob(exportField,getExportData(), 'xlsx', 'Sites')
};

const handleExportDataPDF = () => {
  getExportFileBlob(exportField,getExportData(), 'pdf', 'Sites')
};


  const addNewBornbutton = () =>{
    navigate('/app/master/site_list_add')
    console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Site Master">
      <ToastContainer    />




      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to {open.is_active_site ?  "Activate" : "Deactivate"} this Site??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>




        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
                <Button 
                  color="primary" 
                  variant="contained"
                  className={classes.exportButton} 
                  onClick = {handleExportDataExcel}>
                    Export to Excel
                </Button>
                <Button 
                  color="primary" 
                  variant="contained"
                  className={classes.exportButton} 
                  onClick = {handleExportDataPDF}>
                    Export to PDF
                </Button>
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Create Site
              </Button>
            </Box>
            
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Site Master"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'Site ID',render: rowData =>(
          rowData.tableData.id+1
         )},
        { title: 'Site Name', field: 'site_name',editable: "never", },

        { 
          title: 'Start Date', field: 'start_date',editable: "never", 
          render:rowData => moment(rowData.start_date).format("DD-MM-YYYY")
        },     
        { 
          title: 'End Date', field: 'end_date',editable: "never", 
          render:rowData => moment(rowData.end_date).format("DD-MM-YYYY")
        },

        { title: 'Expected Cost', field: 'budget',editable: "never", },     
        { title: 'Left Amount', field: 'left_amount',editable: "never", },     
        { title: 'Status', render:rowData=>(
         <>
          {rowData.is_active_site ?
          <Typography style={{color:"green"}}>Active</Typography>
          :
          <Typography style={{color:"red"}}>In-Active</Typography>          
          }
         </>
        ) },     
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ?
              <Grid item xs={4}>
                
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
               }
              {/* <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
              </Grid> */}
              {role_permit[2] ?
              <Grid item xs={4}>
                {rowData.is_active_site ? 
                <ToggleOnIcon fontSize="large" onClick={()=>{handleOpen(rowData)}} style={{color:"green", cursor:"pointer"}}/>
                :

                <ToggleOffIcon fontSize="large" onClick={()=>{handleOpen(rowData)}} style={{color:"red", cursor:"pointer"}}/>
                }

              
              </Grid>
              :<></>
              
              }
            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
