import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {getProfileManCre , editProfileManCre , editProfileManPass , editProfileManPic, editSubLogo} from '../../../Actions/SubAdminActions/ProfileManagement';
import {cleanProfileManCreData} from '../../../Actions/ActionCreators';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Profile Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {
    marginTop:'10px'
  },
  avatar: {
    height: 150,
    width: 150
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()



  const login_data = useSelector(state => state.login && state.login.data ? state.login.data : {});

  

  const id = useSelector(state => state.login.data.admin_id);
  const admin_name = useSelector(state => state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && state.AddSubProfile.getData.length ? state.AddSubProfile.getData[0].name : "");
  const admin_email = useSelector(state => state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && state.AddSubProfile.getData.length ? state.AddSubProfile.getData[0].email:"" );
  const admin_mobile = useSelector(state =>state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && state.AddSubProfile.getData.length ? state.AddSubProfile.getData[0].mobile : "");
  const admin_folder = useSelector(state =>state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && state.AddSubProfile.getData.length ? state.AddSubProfile.getData[0].folder_name : "");
  const admin_image = useSelector(state =>state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && state.AddSubProfile.getData.length ? state.AddSubProfile.getData[0].image : "");
  const admin_address = useSelector(state =>state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && state.AddSubProfile.getData.length ? state.AddSubProfile.getData[0].subadmin_address : "");
  const admin_gst = useSelector(state =>state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && state.AddSubProfile.getData.length ? state.AddSubProfile.getData[0].subadmin_gst : "");
  const admin_logo = useSelector(state =>state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && state.AddSubProfile.getData.length ? state.AddSubProfile.getData[0].logo : "");

  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  const [values, setValues] = useState({
   
  admin_id : id,
  name : admin_name !=="" && admin_name !==null && admin_name !==undefined ? admin_name : login_data.name,
  email : admin_email !=="" && admin_email !==null && admin_email !==undefined ? admin_email : login_data.email,
  mobile : admin_mobile !=="" && admin_mobile !==null && admin_mobile !==undefined ? admin_mobile : login_data.mobile,
  subadmin_address : admin_address !=="" && admin_address !==null && admin_address !==undefined ? admin_address : login_data.subadmin_address,
  subadmin_gst : admin_gst !=="" && admin_gst !==null && admin_gst !==undefined ? admin_gst : login_data.subadmin_gst,
  password : "",
  confirm_password : "",
  folder_name : admin_folder,
  added_date :selectedDate,
  added_time :selectedDate,
  img : admin_image,
  image1:"",
  // tempImage1:"",

  // flag:false,

  logoImage:admin_logo,
 

  fixImg : "/static/images/avatars/upload.png"

  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 
  


  const emailRegex = /\S+@\S+\.\S+/;
  var nameRegex = /([a-zA-Z]{1,}\s?[a-zA-Z]+)+/;
  const mobileRegex = /^[6-9][0-9]{9}$/;




  const get_data = useSelector(state => state.AddSubProfile.getData);
  // const image_data = useSelector(state => state.AddSubProfile.getData[0].image);

  const handleSubmit = () =>{
    
    var confirmation = window.confirm("Are You Really Want To Update Your Profile");

    if (confirmation)
    {
      if(values.name==="" || values.name===null || values.name===undefined){
        tostE("Please Enter Name")
      }else if(values.email==="" || values.email===null || values.email===undefined){
        tostE("Please Enter Email")
      }else if(values.mobile==="" || values.mobile===null || values.mobile===undefined){
        tostE("Please Enter Mobile")
      }else{
        if (!emailRegex.test(values.email))
        {
          tostE("Please Enter Valid Email");
        }
        else if (!nameRegex.test(values.name))
        {
          tostE("Name Must be Character")
        }
        else if (!mobileRegex.test(values.mobile))
        {
          tostE("Please Enter Valid Mobile Number")
        }else{
          const sendData = {
            admin_id:id,
            name:values.name !=="" ? values.name : admin_name,
            email:values.email !=="" ? values.email : admin_email,
            mobile:values.mobile !=="" ? values.mobile : admin_mobile,
            subadmin_address:values.subadmin_address !=="" ? values.subadmin_address : admin_address,
            subadmin_gst:values.subadmin_gst !=="" ? values.subadmin_gst : admin_gst
          }
          dispatch(editProfileManCre(sendData))
          // console.log(sendData)
        }        
      }
    }
    else{
      setValues({
        ...values,
        ['name']:get_data[0].name,
        ['email']:get_data[0].email,
        ['mobile'] : get_data[0].mobile,
        ['subadmin_address'] : get_data[0].subadmin_address,
        ['subadmin_gst'] : get_data[0].subadmin_gst,
      })
    }
    // dispatch(getProfileManCre(id))

  }


  const handlePassword = () =>{
    if(values.password.length < 6){
      tostE("Minimum Password Length is Six")
    }else{

      if(values.password === "" || values.password === null || values.password === undefined)
      {
        tostE("Please Enter Password")
      }
      else if(values.confirm_password === "" || values.confirm_password === null || values.confirm_password === undefined)
      {
        tostE("Please Enter Confirm Password")
  
      }
      else if(values.password !== values.confirm_password )
      {
          tostE("Password Didn't Match !!")
      }else{
        dispatch(editProfileManPass(values));
        setValues({
          ...values,
          ['password']:"",
          ['confirm_password']:"",
      
        });
      }    
    }
    
  }






  const editmsg = useSelector((state) => state.AddSubProfile && state.AddSubProfile.editData && state.AddSubProfile.editData.message ? state.AddSubProfile.editData.message : null )


  useEffect( () => {
  
    dispatch(getProfileManCre(id))
  
    var r  = new Promise((resolve , reject)=>{
      if (editmsg !== null && typeof(editmsg) === 'string')
      {
        tostS(editmsg); 
      }
      resolve()
     
     }) 
     r.then(()=>{
      dispatch(cleanProfileManCreData())
      dispatch(getProfileManCre(id));
     // alert("Done");
     })
     
   }, [editmsg])






  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChangeimg = event => {
    const fileUploaded = event.target.files[0]
    
    if(event.target.files[0] && event.target.files.length !==0)

    {
      const formData = new FormData();
      formData.append("admin_id",id );
      formData.append("folder_name",admin_folder)
      formData.append("img", fileUploaded);
      
      
      dispatch(editProfileManPic(formData))

    }


  };
  
  /********* Image Handling   ************** */




  /********* Logo Image Handling   ************** */
  const hiddenLogoInput = useRef(null);

  const handleClickLogo = event => {
    hiddenLogoInput.current.click();
  };
  const handleChangeLogo = event => {
    const fileUploaded = event.target.files[0]
    
    if(event.target.files[0] && event.target.files.length !==0)

    {
      const formData = new FormData();
      formData.append("admin_id",id );
      formData.append("folder_name",admin_folder)
      formData.append("img", fileUploaded);
      
      
      dispatch(editSubLogo(formData))

    }


  };
  
  /*********Logo Image Handling   ************** */

  return (
    <Page
      className={classes.root}
      title="Profile"
    >
              <ToastContainer    />

              <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            {/* <Profile /> */}
            <Card
      className={clsx(classes.root2, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={values.img !== null ?  admin_image : values.fixImg }
          />
          <Typography variant="h3">Profile Picture</Typography> 
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeimg}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClick}
        >
        {/* { values.flag === false ? 'Upload Picture' : "Update Picture" } */}
        Upload Profile Picture
        
        </Button>
      </CardActions>
    </Card>
    {login_data.admin_id===parseInt(login_data.r_subadmin) ?
    
        <Card className={clsx(classes.root2, className)} {...rest}>
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={values.logoImage !== null ?  admin_logo : values.fixImg }
          />
          <Typography variant="h3">Logo Image</Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <input
        type="file"
        ref={hiddenLogoInput}
        onChange={handleChangeLogo}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClickLogo}
        >
        {/* { values.flag === false ? 'Upload Picture' : "Update Picture" } */}
        Upload Logo Image
        
        </Button>
      </CardActions>
    </Card>
    :<></>
    }
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Profile Management" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required                
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone/Mobile"
                name="mobile"
                onChange={handleChange}
                required
                type="number"
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="subadmin_address"
                onChange={handleChange}
                required
                value={values.subadmin_address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="GST"
                  name="subadmin_gst"
                  onChange={handleChange}
                  required
                  value={values.subadmin_gst}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            
            <Grid item md={6} xs={12}>

          <Button onClick={handleSubmit} color="primary" variant="contained">
            Update
          </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              
            </Grid>


            <Grid item md={6} xs={12}>
            <CardHeader subheader="" title="Change Authentication" />
             
            </Grid>

            <Grid item md={6} xs={12}>
             
            </Grid>


            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="New Password"
                name="password"
                onChange={handleChange}
                required
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirm_password"
                onChange={handleChange}
                required
                value={values.confirm_password}
                variant="outlined"
              />
            </Grid>
    
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-start" p={2}>
          <Button onClick={handlePassword} color="primary" variant="contained">
            Change
          </Button>
        </Box>
      </Card>
    </form>
            
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
