// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addSiteDrawBegin , addSiteDrawSuccess , addSiteDrawFailure} from '../ActionCreators'
import {getSiteDrawBegin , getSiteDrawSuccess , getSiteDrawFailure} from '../ActionCreators'
import {delSiteDrawBegin , delSiteDrawSuccess , delSiteDrawFailure} from '../ActionCreators'
import {editSiteDrawBegin , editSiteDrawSuccess , editSiteDrawFailure} from '../ActionCreators';

export const addSiteDraw = (session) => {



    var formData = new FormData()

        formData.append('sub_admin_id',session.sub_admin_id);
      formData.append('draw_title',session.draw_title);
      formData.append('sub_floor_id',session.sub_floor_id);
      formData.append('draw_cat_id',session.draw_cat_id);
      formData.append('site_id',session.site_id);
      formData.append('draw_type_id',session.draw_type_id);
      formData.append('folder_name',session.folder_name);
      formData.append('img',session.img );
      

    return (dispatch) => {
        dispatch(addSiteDrawBegin());

        axios.post(Base_URL + '/subadmin/addDrawings', formData)
            .then((res) => {
                console.log(res.data)
                dispatch(addSiteDrawSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addSiteDrawFailure(err.response.data));

                }
            })
    }
}


export const getSiteDraw = (x) => {

    return (dispatch) => {
        dispatch(getSiteDrawBegin());

        axios.get(Base_URL + '/subadmin/getDrawings' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getSiteDrawSuccess(res.data));
            }).catch((err) => { 
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSiteDrawFailure(err.response.data));

                }
            })
    }
}

export const delSiteDraw = (x) => {

    return (dispatch) => {
        dispatch(delSiteDrawBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delDrawings' , {params :{drawing_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delSiteDrawSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delSiteDrawFailure(err.response.data));

                }
            })
    }
}

export const editSiteDraw = (session) => {

    var formData = new FormData()

        formData.append('drawing_id',session.drawing_id);
        formData.append('draw_title',session.draw_title);
        formData.append('sub_floor_id',session.sub_floor_id);
        formData.append('draw_cat_id',session.draw_cat_id);
        formData.append('site_id',session.site_id);
        formData.append('draw_type_id',session.draw_type_id);
        formData.append('folder_name',session.folder_name);
        formData.append('img',session.image1 );
        formData.append('images',session.img );
      


    return (dispatch) => {
        dispatch(editSiteDrawBegin());

        axios.post(Base_URL + '/subadmin/editDrawings', formData)
            .then((res) => {
                // console.log(res.data)
                dispatch(editSiteDrawSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editSiteDrawFailure(err.response.data));

                }
            })
    }
}

