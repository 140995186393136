import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Container,
  Grid,
  makeStyles,
  Card,
  CardContent,
  Divider,
  Box,
  Button,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import Page from "../../../../src/components/Page";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getSites } from "../../../Actions/SubAdminActions/M_AddSites";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";
import { ToastContainer } from "react-toastify";
import { tostS, tostE } from "../../../Config/Toast";
import { useParams } from "react-router-dom"; // Import useParams

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  tableHeader: {
    fontWeight: "bold",
  },
}));

const DailyReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.login.data.r_subadmin);
  const selected_site = useSelector((state) =>
    state.SelectedSite &&
    state.SelectedSite.sitedata &&
    state.SelectedSite.sitedata.site
      ? state.SelectedSite.sitedata.site
      : null
  );
  const SiteData = useSelector((state) =>
    state.AddSites &&
    state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );

  const { date } = useParams();
  console.log(date);
  const [values, setValues] = useState({
    sub_admin_id: id,
    site_id: selected_site !== null ? selected_site : "",
  });

  const [dailyReport, setDailyReport] = useState(null);
  const [measurementBook, setMeasurementBook] = useState([]);

  useEffect(() => {
    dispatch(getSites(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchDailyReport();
  }, []);

  const fetchDailyReport = () => {
    const requestData = {
      sub_admin_id: id,
      site_id: selected_site,
      date: formatDate(date),
    };

    axios
      .post(`${Base_URL}/subadmin/IndividualReport`, requestData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          if (response.data.daily_report) {
            setDailyReport(response.data.daily_report);
            tostS("Daily report fetched successfully");
          } else {
            setDailyReport(null);
            tostE(
              "No daily report data available for the selected date and site."
            );
          }

          if (response.data.measurement_book) {
            setMeasurementBook(response.data.measurement_book);
            tostS("Measurement book fetched successfully");
          } else {
            setMeasurementBook([]);
            tostE(
              "No measurement book data available for the selected date and site."
            );
          }
        } else {
          tostE("Failed to fetch daily report: ");
          setDailyReport(null);
        }
      })
      .catch((err) => {
        tostE("Daily report not found for this day ");
        setDailyReport(null);
      });
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const handleChangeSiteName = (event) => {
    setValues({ ...values, site_id: event.target.value });
  };

  const getSiteName = (site_id) => {
    const site = SiteData.find((site) => site.site_id === site_id);
    return site ? site.site_name : "N/A";
  };

  const generateExcel = () => {
    if (measurementBook.length > 0) {
      const ws = XLSX.utils.json_to_sheet(measurementBook);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Measurement_Book");
      XLSX.writeFile(wb, "Measurement_Book.xlsx");
    } else {
      alert("No data available to export.");
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Set document properties
    doc.setProperties({
      title: "Daily Report",
      subject: "Daily Report and Measurement Book data",
      author: "Your Name",
      keywords: "daily report, measurement book, data",
    });

    // Define a function to format the Daily Report Details section
    const formatDailyReportDetails = () => {
      const {
        date,
        supervisor,
        weather,
        no_of_workers,
        machines_used,
        general_description,
      } = dailyReport;
      let content = [
        { label: "Date:", value: date },
        { label: "Site Name:", value: getSiteName(values.site_id) },
        { label: "Supervisor:", value: supervisor },
        { label: "Weather:", value: weather },
        { label: "No of Workers:", value: no_of_workers },
        { label: "Machines Used:", value: machines_used },
        { label: "Report Summary:", value: general_description },
      ];

      // Format content for PDF
      let formattedContent = content
        .map((item) => `${item.label} ${item.value}`)
        .join("\n");
      return formattedContent;
    };

    // Add content to the PDF
    doc.text("Daily Report Details", 14, 15);
    doc.text(formatDailyReportDetails(), 14, 25);

    // Add Measurement Book table
    doc.addPage(); // Add a new page for the table if needed
    doc.text("Measurement Book Data", 14, 15);
    doc.autoTable({
      head: [
        [
          "Measurement ID",
          "Item Description",
          "Length",
          "Breadth",
          "Diameter",
          "Height",
          "No of Items",
          "Quantity",
          "Unit",
          "Total used",
          "Remarks",
        ],
      ],
      body: measurementBook.map((row) => [
        row.measurement_id,
        row.item_description,
        row.length,
        row.breadth,
        row.diameter,
        row.height,
        row.no_of_items,
        row.quantity,
        row.unit,
        row.total_used,
        row.remarks,
      ]),
      startY: 25, // Adjust the Y position to leave space for the Daily Report Details
      theme: "grid",
      styles: { cellPadding: 1.5, fontSize: 8 },
    });

    // Save the PDF
    doc.save("Daily_Report.pdf");
  };

  return (
    <Page className={classes.root} title="Daily Report">
      <ToastContainer />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {console.log(dailyReport)}
          {dailyReport && (
            <Grid item lg={12} md={12} xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    Daily Report Details
                  </Typography>
                  <Grid style={{paddingTop:"20px"}} container spacing={5}>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        <strong>Date:</strong> {dailyReport[0].date}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        <strong>Site Name:</strong>{" "}
                        {getSiteName(values.site_id)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        <strong>Supervisor:</strong> {dailyReport[0].supervisor}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        <strong>Weather:</strong> {dailyReport[0].weather}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        <strong>No of Workers:</strong>{" "}
                        {dailyReport[0].no_of_workers}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        <strong>Machines Used:</strong>{" "}
                        {dailyReport[0].machines_used}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <strong>Report Summary:</strong>{" "}
                        {dailyReport[0].general_description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: "16px 0" }} />
                  <Typography variant="h6" gutterBottom>
                    Measurement Book (MB)
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table aria-label="measurement book table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Measurement ID</TableCell>
                          <TableCell>Item Description</TableCell>
                          <TableCell>Length</TableCell>
                          <TableCell>Breadth</TableCell>
                          <TableCell>Diameter</TableCell>
                          <TableCell>Height</TableCell>
                          <TableCell>No of Items</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Unit</TableCell>
                          <TableCell>Total used</TableCell>
                          <TableCell>Remarks</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {measurementBook && measurementBook.length > 0 ? (
                          measurementBook.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{row.item_description}</TableCell>
                              <TableCell>{row.length}</TableCell>
                              <TableCell>{row.breadth}</TableCell>
                              <TableCell>{row.dia}</TableCell>
                              <TableCell>{row.height}</TableCell>
                              <TableCell>{row.noofitems}</TableCell>
                              <TableCell>{row.quantity}</TableCell>
                              <TableCell>{row.unit}</TableCell>
                              <TableCell>{row.used_quantity}</TableCell>
                              <TableCell>{row.remark}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              No Measurement Book Data
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generateExcel}
                  >
                    Export to Excel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={generatePDF}
                  >
                    Export to PDF
                  </Button>
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default DailyReport;
