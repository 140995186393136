import React, { useEffect, useState } from 'react';
import axios from '../../../Config/Interceptor2';
import { Base_URL } from '../../../Config/BaseUrlConfig';
import { Typography, Box, Card, CardContent, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { tostE, tostS } from '../../../Config/Toast';
import { Chart } from 'react-google-charts';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  DashCard: {
    border: '1px solid #E36A26',
    background: '#FFEAE0',
  },
}));

export default function TotalProgress({ siteId, subAdminId }) {
  const classes = useStyles();
  const [progressData, setProgressData] = useState({
    totalBoqPrice: 0,
    totalMeasurementBookPrice: 0,
  });

  const id = useSelector(
    (state) =>
      state.login.data &&
      Object.keys(state.login.data).length &&
      state.login.data.r_subadmin
        ? state.login.data.r_subadmin
        : ''
  );

  const SelectSite = useSelector(
    (state) =>
      state.SelectedSite &&
      state.SelectedSite.sitedata &&
      Object.keys(state.SelectedSite.sitedata).length
        ? state.SelectedSite.sitedata.site
        : null
  );

  useEffect(() => {
    if (id && SelectSite) {
      fetchProgressData();
    }
  }, [id, SelectSite]);

  const fetchProgressData = async () => {
    try {
      const response = await axios.get(`${Base_URL}/subadmin/Progress`, {
        params: {
          admin_id: id,
          site_id: SelectSite,
        },
      });
      console.log(response.data);
      const { total_boq_price, total_measurement_book_price } = response.data;

      setProgressData({
        totalBoqPrice: total_boq_price,
        totalMeasurementBookPrice: total_measurement_book_price,
      });
    } catch (error) {
      console.error('Error fetching progress data:', error);
    }
  };

  const { totalBoqPrice, totalMeasurementBookPrice } = progressData;
  const progressPercentage =
    totalBoqPrice > 0
      ? (totalMeasurementBookPrice / totalBoqPrice) * 100
      : 0;

  const data = [
    ['Task', 'Progress'],
    ['Completed', progressPercentage],
    ['Remaining', 100 - progressPercentage],
  ];

  const options = {
    title: 'Total Progress (Based on BOQ)',
    pieHole: 0.4,
    slices: {
      0: { color: '#4CAF50' },  
      1: { color: '#FFCDD2' }, 
    },
  };

  return (
    <Card className={classes.DashCard}>
      <ToastContainer />
      <CardContent>
        <Box width="100%">
          <Chart
            chartType="PieChart"
            width="100%"
            height="260px"
            data={data}
            options={options}
          />
          <Typography variant="caption" color="textSecondary">
            {`${progressPercentage.toFixed(2)}% (${parseFloat(totalMeasurementBookPrice).toFixed(2)}/${parseFloat(totalBoqPrice).toFixed(2)} Total)`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
