import React ,  {useRef , useState , useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {addVendor} from '../../../../Actions/SubAdminActions/M_Vendor';
import {cleanVendorData} from '../../../../Actions/ActionCreators';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  const handleChangee = (event) => {
    setValues({...values, ['state']:event.target.value});
  };
  const handleChangef = (event) => {
    setValues({...values, ['city']:event.target.value});
  };
  const handleChanget = (event) => {
    setValues({...values, ['type_of_business']:event.target.value});
  };



  const id = useSelector(state => state.login.data.r_subadmin);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
    

  const [values, setValues] = useState({
    sub_admin_id :id,
    name : "",
    email : "",
    mobile : "",
    address : "",
    gst : "",
  
  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (date) =>{

    console.log(date)

  }


  const emailRegex = /\S+@\S+\.\S+/;
  const nameRegex = /([a-zA-Z]{1,}\s?[a-zA-Z]+)+/;
  const mobileRegex = /^[6-9][0-9]{9}$/;


  const handleSubmit = () =>{
      console.log(values)
      if (!nameRegex.test(values.name))
      {
        tostE("Please Enter Valid Name")
      }           
      else if (!mobileRegex.test(values.mobile))
      {
        tostE("Please Enter Valid Mobile Number")
      }
      else if(values.address.length < 2)
      {
        tostE("Enter Valid Address")
      }
      else if (!emailRegex.test(values.email))
      {
        tostE("Please Enter Valid Email");
      } 
      else{

        dispatch(addVendor(values))
        navigate('/app/master/vendor_list')
        

      }
  

  }






  const CData = useSelector((state) =>
  state.GetCountry && state.GetCountry.getdata &&
    state.GetCountry.getdata.length &&
    Array.isArray(state.GetCountry.getdata)
      ? state.GetCountry.getdata
      : []
  );


  
  const SData = useSelector((state) =>
  state.GetState && state.GetState.getdata &&
    state.GetState.getdata.length &&
    Array.isArray(state.GetState.getdata)
      ? state.GetState.getdata
      : []
  );

  const CiData = useSelector((state) =>
  state.GetCity && state.GetCity.getdata &&
    state.GetCity.getdata.length &&
    Array.isArray(state.GetCity.getdata)
      ? state.GetCity.getdata
      : []
  );



  
  const BlistData = useSelector((state) =>
  state.BusinessMaster &&  state.BusinessMaster.getdata &&
    state.BusinessMaster.getdata.length &&
    Array.isArray(state.BusinessMaster.getdata)
      ? state.BusinessMaster.getdata
      : []
  );





  

  // const addmsg = useSelector((state) => state.AddVendor.data && state.AddVendor.data.status && state.AddVendor.data.status === 200 ? state.AddVendor.data.message : null  )
  // const adderr = useSelector((state) => state.AddVendor.error && state.AddVendor.error.mgs ? state.AddVendor.error.mgs : null  )
  
  //   useEffect( () => {
  //     var r  = new Promise((resolve , reject)=>{
  //      if(addmsg !== null && typeof(addmsg) === 'string'){
  //       tostS("Vendor Added Successfully !!")
  //      }
  
  //     else
  //     if(adderr !== null){
  //       tostE(adderr)
  //      }
    
  //      resolve()
      
  //     }) 
  //     r.then(()=>{
  //      dispatch(cleanVendorData())
  //     })
      
  //   }, [addmsg, adderr])
  



  
  return (
    <Page
      className={classes.root}
      title="Add Vendor"
    >
              <ToastContainer />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add Vendor" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Mobile"
                name="mobile"
                onChange={handleChange}
                required
                type="number"  InputProps={{inputProps:{min:1}}}
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="GST"
                name="gst"
                onChange={handleChange}
                required
                value={values.gst}
                variant="outlined"
              />
            </Grid>
            

            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
