// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addResourceMachineBegin , addResourceMachineSuccess , addResourceMachineFailure } from '../ActionCreators';
import {getResourceMachineBegin , getResourceMachineSuccess , getResourceMachineFailure } from '../ActionCreators';
import {delResourceMachineBegin , delResourceMachineSuccess , delResourceMachineFailure } from '../ActionCreators';
import {editResourceMachineBegin , editResourceMachineSuccess , editResourceMachineFailure } from '../ActionCreators';


export const addResourceMachine = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addResourceMachineBegin());

        axios.post(Base_URL + '/subAdmin/addMachineMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addResourceMachineSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addResourceMachineFailure(err.response.data));

                }
            })
    }
}

export const getResourceMachine = (x) => {

    return (dispatch) => {
        dispatch(getResourceMachineBegin());

        axios.get(Base_URL + '/subAdmin/getMachineMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getResourceMachineSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getResourceMachineFailure(err.response.data));

                }
            })
    }
}

export const delResourceMachine = (x) => {

    return (dispatch) => {
        dispatch(delResourceMachineBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delMachineMaster' , {params :{sub_machine_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delResourceMachineSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delResourceMachineFailure(err.response.data));

                }
            })
    }
}

export const editResourceMachine = (session) => {

    return (dispatch) => {
        dispatch(editResourceMachineBegin());

        axios.post(Base_URL + '/subAdmin/editMachineMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editResourceMachineSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editResourceMachineFailure(err.response.data));

                }
            })
    }
}

