import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useLocation , useNavigate} from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../Config/Toast';
import {ToastContainer} from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {editGst} from '../../../Actions/SuperAdminActions/Gst'
import {cleanGst} from '../../../Actions/ActionCreators'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
const classes = useStyles();
const dispatch = useDispatch();
const {state} = useLocation();
const navigate = useNavigate()
const id = useSelector(state => state.login.data.id);
const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
const [values , setValues] = useState({
  gst_id : state.gst_id,
  slab_name : state.slab_name,
  percent : state.percent

});

const handleChange = (event) => {
  setValues({
    ...values,
    [event.target.name]: event.target.value,
  });
};

// const handleSlab = (e) =>{
  
//   setValues({...values, [e.target.name]:e.target.value})
// }

const handleSubmit = () =>{
  if(values.slab_name==="" || values.slab_name===null || values.slab_name===undefined){
    tostE("Please Enter Slab Name !!")
  }else if(values.percent==="" || values.percent===null || values.percent===undefined){
    tostE("Please Enter Percent !!")
  }else{

    dispatch(editGst(values))  
    navigate('/app/gst')    
  }
  // setValues({...values , ['slab_name'] :"" ,['percent'] :"" })
 
}

const CData = useSelector((state) =>
state.GetCountry && state.GetCountry.getdata &&
  state.GetCountry.getdata.length &&
  Array.isArray(state.GetCountry.getdata)
    ? state.GetCountry.getdata
    : []
);


  

  return (
    <Page
      className={classes.root}
      title="GST Update"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
        
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Update GST" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
             <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Slab Name"
                name="slab_name"
                onChange={handleChange}
                required
                value={values.slab_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Percentage"
                name="percent"
                type="number"  InputProps={{inputProps:{min:0}}}
                onChange={handleChange}
                required
                value={values.percent}
                variant="outlined"
              />
            </Grid>
            
            
            
            
            
        

      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Update details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
