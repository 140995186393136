import React , { useRef, useState, useEffect } from 'react';

import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import Users from './Users';
import { connect } from "react-redux";
import { ToastContainer} from 'react-toastify';
import {tostE , tostS , tostW} from '../../../Config/Toast';
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Buses from './Buses';
import Trains from './Trains';
import Loading from './Loading';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch =  useDispatch();

  // dispatch(getDashboard())
  


  // const homeData = useSelector((state) =>
  // state.Home.getdata &&
  // state.Home.getdata.length &&
  // Array.isArray(state.Home.getdata)
  // ? state.Home.getdata
  // : []
  // );
  
  const homeData = [];


  console.log(homeData)

  // dispatch(getDashboard())
  useEffect( () => {
    // alert("Bhanu")
    // dispatch(getDashboard())
  }, [  ])



  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <ToastContainer    />
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {/* <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="bhanu" dashdata={homeData} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalCustomers dashdata={homeData} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TasksProgress dashdata={homeData} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalProfit dashdata={homeData}  />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Users dashdata={homeData}  />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Buses dashdata={homeData}  />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Trains dashdata={homeData}  />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales dashdata={homeData} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficByDevice dashdata={homeData} />
          </Grid> */}
          {/* <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts dashdata={homeData} />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestOrders dashdata={homeData} />
          </Grid> */}
           {/* <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <Loading  />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};



export default Dashboard;
