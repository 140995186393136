// import axios from "../Config/Interceptor1";


// import axios from 'axios'
import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'



import {getSubFaqBegin , getSubFaqSuccess , getSubFaqFailure } from './../ActionCreators'



export const getSubFaq = () => {

    return (dispatch) => {
        dispatch(getSubFaqBegin());

        axios.get(Base_URL + '/subadmin/getFaqs')
            .then((res) => {
                dispatch(getSubFaqSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSubFaqFailure(err.response.data));

                }
            })
    }
}

