import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";






import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import { AddBox, ArrowDownward } from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },


}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()






  const AllListData= useSelector((state) =>
    state.AddBusiness && state.AddBusiness.getdata &&
      state.AddBusiness.getdata.length &&
      Array.isArray(state.AddBusiness.getdata)
      ? state.AddBusiness.getdata
      : []
  );







  const Delmsg = useSelector(state => state.AddBusiness && state.AddBusiness.deldata && state.AddBusiness.deldata.message ? state.AddBusiness.deldata.message : null)
  const Delerror = useSelector(state => state.AddBusiness && state.AddBusiness.delerror && state.AddBusiness.delerror.message ? state.AddBusiness.delerror.message : null)









  useEffect(() => {

    // dispatch(getBusiness())


    let x = new Promise((resolve, reject) => {
      if (Delmsg && typeof (Delmsg) === 'string') {
        tostS(Delmsg)
      }
      if (Delerror && typeof (Delerror) === 'string') {
        tostE(Delerror)
      }

      resolve()

    })
    x.then(() => {

      // dispatch(cleanBusinessData())
      // dispatch(getBusiness())

    })



  }, [Delmsg, Delerror])


  const navigate = useNavigate();

  const onEdit = (olData) => {
    //   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
  }


  const onDel = (oldata) => {

    //   dispatch(DelBusiness(oldata.bp_id ,oldata.tableData.id ))
  }







  const [values, setValues] = useState({

    country: "",


  });



  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleChanged = (event) => {
    setValues({ ...values, ['country']: event.target.value });
  };



  const addNewBornbutton = () => {
    navigate('/app/gstadd')
    console.log("Place order")

  }




  //------------------------------my Demo Data-------------------------------------------


  const [userData, setUserData] = useState({});

  const getData = async () => {
    const url = 'http://jsonplaceholder.typicode.com/posts/';
    const response = await fetch(url);
    const data = await response.json();
    // console.log(data)

  }

  useEffect(() => {

    getData()
  });


  const mydata = useSelector(state => state.Home.getdata)
  const loading = useSelector(state => state.Home.loading)

  useEffect(() => {

    // dispatch(getDemo())

    console.log(mydata)
  }, []);


const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <Page className={classes.root} title="Demo Progress Report">
        <ToastContainer />
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              {/* <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Place Order
              </Button> */}
            </Box>
            <div className={classes.root3}>
              <Grid container spacing={3}>
                <Grid item md={6} lg={3} xs={3}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        style={{ border: "1px  black" }}
                        id="date-picker-dialog"
                        label="Filter By Date"
                        format="MM/dd/yyyy"
                        // value={selectedDate}
                        style={{ width: '100%', borderColor: "orange" }}
                        onChange={handleDateChange}
                        inputVariant="outlined"
                        variant="outlined"
                        borderColor="red"

                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>

                </Grid>



                <Grid item md={6} lg={3} xs={3}>
                  <FormControl variant="outlined" style={{ width: "100%", margin: '15px' }}>
                    <InputLabel id="demo-simple-select-outlined-label" style={{ width: "100%" }}>Export As</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.country}
                      onChange={handleChanged}
                      label="Export As"
                      style={{ width: "100%" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Resource Type 1">CSV</MenuItem>
                      <MenuItem value="Resource Type 1">Excel Sheet</MenuItem>
                      <MenuItem value="Resource Type 1">Word</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
            </div>

            <Grid item md={6} lg={6} xs={4}>
                <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Demo
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">DEMO PAGE</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Demo Here
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Completed"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Add Demo
          </Button>
        </DialogActions>
      </Dialog>
    </div>
          </Grid>
          

          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

              <MaterialTable
                icons={tableIcons}
                title="Demo Progress Report"
                columns={[
                  // {
                  //   title: 'Images',
                  //   field: 'images',
                  //   editable: "never",
                  //   render: rowData => (
                  //     <Avatar
                  //     className={useStyles.avatar}
                  //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
                  //   >
                  //   </Avatar>
                  //   ),
                  // },
                  { title: 'User Id', field: 'userId', editable: "never", },
                  { title: 'Id', field: 'id', editable: "never", },
                  { title: 'Title', field: 'title', editable: "never", },
                  { title: 'Completed', field: 'completed', editable: "never", },

                  // {
                  //   title: 'Added Date', field: "added_date",editable: "never",
                  //   render: rowData => (
                  //     moment(rowData.added_date).format("DD/MM/YYYY")
                  //   ),
                  // },
                  {
                    title: 'Action',
                    render: rowData => (
                      <Grid container className={classes.root2}>
                        <Grid item xs={4}>

                          <EditIcon onClick={() => { onEdit(rowData) }} style={{ cursor: "pointer" }} />
                        </Grid>
                        <Grid item xs={4}>
                          <DeleteIcon onClick={() => { onDel(rowData) }} style={{ cursor: "pointer" }} />
                        </Grid>
                        {/* <Grid item xs={4}>
        <VisibilityIcon />
      </Grid> */}
                      </Grid>
                    ),
                  },

                ]}
                data={mydata}
                options={{
                  selection: false,
                  textAlign: "center",
                  headerStyle: { textAlign: "left" },
                  rowStyle: { textAlign: "center" }
                }}
              />

            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
