import { createStyles, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
      
    },
    '*::-webkit-scrollbar': {
      width: '0.4em',
      backgroundColor: 'rgba(255,154,103,.5)',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(255,154,103,0.7)',
      paddingTop:"5px"
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#E36A26',
      outline: '1px solid #f4f6f8',
      borderRadius:"15px",
      
    },    
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%',
      backgroundColor:"#E36A26"
    },
    '.MuiButton-containedPrimary': {
      // color: #fff;
      backgroundColor:'#E36A26',
  },
  // '.MuiPickersDatePickerRoot-toolbar':{
  //   backgroundColor:'#7bb53f'
  // },
  '.MuiPickersToolbar-toolbar' :{
    backgroundColor:'#7bb53f'
},
'.MuiMenu-paper': {
    // marginTop: '60px'
},
'.makeStyles-formControl' :{
     margin: '0px'
    // min-width: 100%;
},

// .MuiPopover-paper {
//     outline: 0;
//     position: absolute;
//     max-width: calc(100% - 32px);
//     min-width: 16px;
//     max-height: calc(100% - 32px);
//     min-height: 16px;
//     overflow-x: hidden;
//     overflow-y: auto;
// }
// .MuiPaper-elevation8 {
//     box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 5px 8px -2px rgb(0 0 0 / 25%);
// }


   
    
    
  ".MuiAppBar-colorPrimary" : {
    backgroundColor:"#E36A26" 
},
  ".MuiTypography-body1 " : {
    fontSize: '0.9rem',
  
    color: '#546e7a'
},
'MuiFormControl-root' : {
  margin: '0px'
},
//'.MuiSvgIcon-root': {
 //   color: "orange"
//}
'.MuiToolbar-root h6':{
  
    fontSize:'18px',
    fontWeight:"bold"  
},
"thead .MuiTableHead-root":{
  fontSize:'14px',
  fontWeight:"bold" 
},
".MuiCardHeader-title":{
  fontSize:"18px",
  fontWeight:"bold"
}

  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
