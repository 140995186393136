import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    header: {
        flexDirection:'row',
        padding:"10px"
    },
    headerContainer: {
        flexDirection: 'column',
        justifyContent:'flex-end',
        width:"50%"
    },
    shipContainer: {
        marginTop: 0,
        flexDirection: 'column',
        justifyContent:'flex-end',
        width:"50%"
    },
    billTo: {
        fontFamily: 'Helvetica-Oblique'        
    },
    details: {        
        fontFamily: 'Helvetica',
        justifyContent:"center"
    },
  });


  const BillTo = ({invoice}) => (
      <>
    <View style={styles.header}>
        <View style={styles.headerContainer}>
            <Text style={styles.billTo}>BILL TO </Text>
            <Text style={styles.details}>Vendor Name: {invoice.name}</Text>
            <Text style={styles.details}>GST NO: {invoice.company}</Text>
            <Text style={styles.details}>Vendor Address: {invoice.address}</Text>
            <Text style={styles.details}>Vendor Phone: {invoice.mobile}</Text>
            <Text style={styles.details}>Vendor Email Address : {invoice.email}</Text>

        </View>
        <View style={styles.shipContainer}>
            <Text style={styles.billTo}>Ship TO </Text>
            <Text style={styles.details}>Site Name: {invoice.site_name}</Text>
            <Text style={styles.details}>Site Address: {invoice.site_address}</Text>
            <Text style={styles.details}>Site In Charge: {""}</Text>
            <Text style={styles.details}>{` `}</Text>
            <Text style={styles.details}>Email Address : {""}</Text>

        </View>
        
    </View>
    
    </>
  );
  
  export default BillTo