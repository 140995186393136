import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  IconButton,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {cleanMachineCatData} from '../../../../../Actions/ActionCreators';
import {addMachineCat, getMachineCat, editMachineCat, delMachineCat} from '../../../../../Actions/SubAdminActions/M_MachineCat';
import {getExportFileBlob} from '../../../../../Config/excelPdfDownload'
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const [open3, setOpen3] = useState({show:false, id:""});


  const handleClose3 = () =>{

    setOpen3({...open3, show:false, id:""});
  }
  
  const handleOpen3 = (data) =>{
  
    setOpen3({
      ...open3,
      show:true,
      id:data.mch_id
    });
  
  }
  
  const handleConfirm3 = () =>{
    if(open3.id!==""){
      
      dispatch(delMachineCat(open3.id))

  
      setOpen3({
        ...open3,
        show:false,
        id:""
      })
    }
  }

  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)

  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);



  const listData = useSelector((state) =>
  state.MachineCat && state.MachineCat.getData &&
    state.MachineCat.getData.length &&
    Array.isArray(state.MachineCat.getData)
      ? state.MachineCat.getData
      : []
  );


  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);



  
 
  const Delmsg = useSelector(state => state.MachineCat && state.MachineCat.delData && state.MachineCat.delData.message ? state.MachineCat.delData.message : null  )
  const Delerror = useSelector(state => state.MachineCat && state.MachineCat.delError && state.MachineCat.delError.message ? state.MachineCat.delError.message : null  )
  const addmsg = useSelector(state => state.MachineCat && state.MachineCat.data && state.MachineCat.data.message ? state.MachineCat.data.message : null  )
  const adderr = useSelector(state => state.MachineCat && state.MachineCat.error && state.MachineCat.error.message ? state.MachineCat.error.message : null  )
  const editmsg = useSelector(state => state.MachineCat && state.MachineCat.editData && state.MachineCat.editData.message ? state.MachineCat.editData.message : null )
  

  const sub_aid = useSelector(state => state.login.data.r_subadmin);

  const [values, setValues] = useState({
   
    name:"",
    sub_admin_id:sub_aid,
  
    });
  
  
  
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  
  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  


  

  
useEffect( () => {

    dispatch(getMachineCat(sub_aid))


    let x  = new Promise((resolve ,reject)=>{
      if(addmsg !== null && typeof(addmsg) === 'string'){
        tostS(addmsg)
       }
    
      else
      if(adderr !== null){
        tostE(adderr)
       }
      else
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      else
      if (editmsg !== null && typeof(editmsg) === 'string')
      {
        tostS(editmsg);
      }
      else    
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanMachineCatData())
    dispatch(getMachineCat(sub_aid))

    })


    
  }, [ Delmsg  ,Delerror, addmsg, adderr, editmsg ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  setOpen2(true)
  setValues({
    ...values,
    ['name']:olData.name,
    ['mch_id']:olData.mch_id
  })
  
//   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
}


const onDel = (oldata) =>{

  dispatch(delMachineCat(oldata.mch_id))
}
 


const [open, setOpen] = React.useState(false);
const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setValues({
      ...values,
      ['name']:"",
      ['mch_id']:""
    })    
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);

  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleClose2 = () => {
    setOpen2(false);
    setValues({
      ...values,
      ['name']:"",
      ['mch_id']:""
    })

  };



  const handleChange = (event) => {
    //  console.log(event.target.value);
     setValues({
       ...values,
       [event.target.name]: event.target.value,
     });
   };

   const handleSubmit = () =>{
    //  console.log(values)
    if((values.name).trim()!==null &&(values.name).trim()!==undefined && (values.name).trim()!==""){
      if (values.mch_id !== ''){
        dispatch(editMachineCat(values))
      }
      else
      {
        dispatch(addMachineCat(values))
      }
      setValues({
        ...values,
        name : "",
      });
    }else{
      tostE("Please Enter Valid Name")
    }
   
  setOpen(false);
  setOpen2(false);
  }; 
  
  const exportField = [
    {
      field : "mch_id" , 
      val : "Machine Category ID"
    }, 
    {
      field : "name" , 
      val : "Machine Category Name"
    }, 
    
  ]


  // const addNewBornbutton = () =>{
  //   navigate('/app/master/machine_list_add')
  //   console.log("Place order")
    
  // }

  return (
    <>
      <Page className={classes.root} title="Machine Category Master">
      <ToastContainer    />

      


      <Dialog disableBackdropClick={true} open={open3.show} onClose={handleClose3} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Machine Category??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm3} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> 
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add Machine
              </Button>*/}


<div>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Machine Category')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Machine Category')
              }>
                Export to PDF
            </Button>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Machine Category
      </Button>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Machine Category Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Category Machine
          </DialogContentText>
          {/*<TextField
            autoFocus
            margin="dense"
            id="name"
            label="Machine Name"
            type="text"
            fullWidth
          />*/}
          <TextField
                fullWidth
                id="name"
                label="Machine Category Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                type="text"
                required
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Category
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Machine Category Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit Machine Category
          </DialogContentText>
          {/*<TextField
            autoFocus
            margin="dense"
            id="name"
            label="Machine Name"
            type="text"
            fullWidth
          />*/}
          <TextField
                fullWidth
                id="name"
                label="Machine Category Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                type="text"
                required
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update Category
          </Button>
        </DialogActions>
      </Dialog>
    </div>




            </Box>
          
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Machine"
      columns={[
      
        { title: 'Machine Category ID', render: rowData =>(
          rowData.tableData.id+1
          ) },
        { title: 'Machine Category Name', field: 'name',editable: "never", },     
        
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
            {role_permit[4] ?
              <Grid item xs={4}>
              {rowData.by_superadmin ? 
              <IconButton>
                <EditIcon onClick={()=>{tostW("Category Added by Super Admin can't be Edit")}} style={{cursor:"pointer"}} />

              </IconButton>
              :
                <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />

              }

              </Grid>
            :<></>
            }
            {role_permit[2] ?
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen3(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
            :<></>
            }
              
            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
