import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useLocation , useNavigate} from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import {editSiteDraw} from '../../../Actions/SubAdminActions/SiteDrawing';
import {cleanSiteDraw} from '../../../Actions/ActionCreators'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {getDrawingFloorType } from '../../../Actions/SubAdminActions/M_DrawingFloorType'
import {getDrawType} from '../../../Actions/SubAdminActions/M_DrawType'
import {getDraw} from '../../../Actions/SubAdminActions/M_Draw'
import {getSites} from '../../../Actions/SubAdminActions/M_AddSites'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Profile Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate();

  const handleChangedCat = (event) => {
    setValues({...values, ['draw_cat_id']:event.target.value});
  };
  const handleChangeDrawType = (event) => {
    setValues({...values, ['draw_type_id']:event.target.value});
  };
  const handleChangeFloorType = (event) => {
    setValues({...values, ['sub_floor_id']:event.target.value});
  };
  const handleChangeSiteName = (event) => {
    setValues({...values, ['site_id']:event.target.value});
  };



  const id = useSelector(state => state.login.data.r_subadmin);
  const sub_folder = useSelector(state => state.login.data.folder_name);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
    drawing_id :state.drawing_id,
    draw_title:state.draw_title,    
    sub_floor_id:state.sub_floor_id,
    draw_floor_name:state.draw_floor_name,
    draw_cat_id:state.draw_cat_id,
    draw_cat_name:state.draw_cat_name,    
    site_id:state.site_id,
    site_name:state.site_name,
    draw_type_id:state.draw_type_id,
    draw_type_name:state.draw_type_name,
    folder_name:sub_folder,  
    img:state.image[0],
    image1:"",
    fixImg : ""

  });

  
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 


  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  // const handleTimeChange = (date) =>{

  //   console.log(date)

  // }




  // const handleChangec = (event) => {
  //     console.log(event.target.checked)
  //   setValues({ ...values, ['mobile_visible']: event.target.checked });
  // };



  const DCatData = useSelector((state) =>
  state.SubDraw && state.SubDraw.getData &&
    state.SubDraw.getData.length &&
    Array.isArray(state.SubDraw.getData)
      ? state.SubDraw.getData
      : []
  );


  
  const DTypeData = useSelector((state) =>
  state.SubDrawType && state.SubDrawType.getData &&
    state.SubDrawType.getData.length &&
    Array.isArray(state.SubDrawType.getData)
      ? state.SubDrawType.getData
      : []
  );

  const FloorTypeData = useSelector((state) =>
  state.AddDrawingFloorType && state.AddDrawingFloorType.getData &&
    state.AddDrawingFloorType.getData.length &&
    Array.isArray(state.AddDrawingFloorType.getData)
      ? state.AddDrawingFloorType.getData
      : []
  );



  
  const SiteData = useSelector((state) =>
  state.AddSites &&  state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );




  const handleCountryMenu = (x) => {
    if(x !== "" && x !== undefined && x !== null )
    {
        // dispatch(getStateData(x))
    }
    else
    {
      console.log(".")
    }
  }

  const handleStateMenu = (y) => {
    if(y !== "" && y !== undefined && y !== null )
    {
        // dispatch(getCityData(y))
    }
    else
    {
      console.log(".")
    }
  }



  

//   useEffect( () => {
    // dispatch(getBusinessCat())

    // dispatch(getCountryData())
     
   //  }, [])


  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);
  // const formData = new FormData();
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChangeimg = event => {
    const fileUploaded = event.target.files[0];
    setValues({...values , ['image1']:fileUploaded , ['fixImg']:URL.createObjectURL(fileUploaded)})

    // if(event.target.files[0] && event.target.files.length !==0)
    // {
      
    //   formData.append('sub_admin_id',values.sub_admin_id);
    //   formData.append('draw_title',values.draw_title);
    //   formData.append('draw_floor',values.draw_floor);
    //   formData.append('draw_category',values.draw_category);
    //   formData.append('site_name',values.site_name);
    //   formData.append('draw_type',values.draw_type);
    //   formData.append('folder_name',values.folder_name);
    //   formData.append('img',values.image1);
      

    // }
    

  };
  
  /********* Image Handling   ************** */

  const handleSubmit = () =>{
    console.log(values)
    if(values.draw_title==="" || values.draw_title===null || values.draw_title===undefined){
      tostE("Please Enter Drawing Title !!")
    }else if(values.draw_cat_id==="" || values.draw_cat_id===null || values.draw_cat_id===undefined){
      tostE("Please Select Drawing Category !!")
    }else if(values.sub_floor_id==="" || values.sub_floor_id===null || values.sub_floor_id===undefined){
      tostE("Please Select Floor !!")
    }else if(values.draw_type_id==="" || values.draw_type_id===null || values.draw_type_id===undefined){
      tostE("Please Select drawing Type !!")
    }else if(values.site_id==="" || values.site_id===null || values.site_id===undefined){
      tostE("Please Select Site !!")
    }else if(values.img==="" || values.img===null || values.img===undefined){
      tostE("Please Select Drawing Image !!")
    }else{

      dispatch(editSiteDraw(values))
      navigate('/app/site_drawings')

    }

}



  return (
    <Page
      className={classes.root}
      title="Add Drawing"
    >
              <ToastContainer    />

              <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
         <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add Drawing" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Drawing Title"
                name="draw_title"
                onChange={handleChange}
                required
                value={values.draw_title}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"          
          onChange={handleChangedCat}
          label="Category"
          value={values.draw_cat_id}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          {DCatData.map((data , i)=>
          <MenuItem key={i} value={data.sub_draw_id}>{data.draw_cat_name}</MenuItem>
          )}        

        </Select>
      </FormControl>
      </Grid>


      <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Floor</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"          
          onChange={handleChangeFloorType}
          label="Floor"
          value={values.sub_floor_id}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          {FloorTypeData.map((data , i)=>
          <MenuItem key={i} value={data.sub_floor_id}>{data.draw_floor_name}</MenuItem>
          )}        

        </Select>
      </FormControl>
      </Grid>


      <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Drawing Type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"          
          onChange={handleChangeDrawType}
          label="Drawing Type"
          value={values.draw_type_id}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          {DTypeData.map((data , i)=>
          <MenuItem key={i} value={data.sub_draw_type_id}>{data.draw_type_name}</MenuItem>
          )}        

        </Select>
      </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Site Name</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"          
          onChange={handleChangeSiteName}
          label="Drawing Type"
          value={values.site_id}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          {SiteData.map((data , i)=>
          <MenuItem key={i} value={data.site_id}>{data.site_name}</MenuItem>
          )}        

        </Select>
      </FormControl>
      </Grid>

      <CardActions>
      <FormControl variant="outlined" className={classes.formControl}>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeimg}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClick}
        >
          Upload PDF
          <Typography style={{marginLeft:'5px', color:"Green"}}>
        {values.img !== '' ?  
            
            `File Uploaded `
        : null }
          </Typography>
        </Button>
        
      </FormControl>
      </CardActions>
        
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
