import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {getMaterialDelivery, delMaterialDelivery} from '../../../../Actions/SubAdminActions/Material_delivery';
import {cleanMaterialDeliveryData, MatDelStartDate, MatDelEndDate} from '../../../../Actions/ActionCreators'
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const tableRef = React.createRef();
  const sub_aid = useSelector(state => state.login.data.r_subadmin)
  
  
  const [open, setOpen] = useState({show:false, id:""});


  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.dy_id
    });
  
  }
  
  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delMaterialDelivery(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }
  
  
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)

  
  const start_date = useSelector(state => state.SubDateFilters && state.SubDateFilters.MatDelStartDate ? state.SubDateFilters.MatDelStartDate:new Date(Date.now()))
  const end_date = useSelector(state => state.SubDateFilters && state.SubDateFilters.MatDelEndDate ? state.SubDateFilters.MatDelEndDate:new Date(Date.now()))
    

  const [values, setValues] = useState({
    data:"",
    from_date: "",
  })
 



  const  filterData = (Wdata) => {
    
    var Fdata = []
    if(Array.isArray(Wdata) && Wdata.length){

      Fdata = Wdata.filter(data => (!selected_site || data.site_id==selected_site));
      return Fdata
    }else{

      return Fdata
    }
    
  
  } 
  
  
  const listData = useSelector((state) =>
  state.MaterialDelivery && state.MaterialDelivery.getData.data &&
    state.MaterialDelivery.getData.data.length &&
    Array.isArray(state.MaterialDelivery.getData.data)
      ? filterData(state.MaterialDelivery.getData.data)
      :[]
  );

  
  const countD= useSelector((state) =>
  state.MaterialDelivery && state.MaterialDelivery.getData &&
   Array.isArray(state.MaterialDelivery.getData.data)
      ? parseInt(state.MaterialDelivery.getData.total_length)
      : 0
  );



  const countF= useSelector((state) =>
  state.MaterialDelivery && state.MaterialDelivery.getData &&
   Array.isArray(state.MaterialDelivery.getData.data)
      ? parseInt(state.MaterialDelivery.getData.page)
      : 0
  );
  
  const [pagination , setPagination] = useState({
    per_page : 5,
    page : countF,

  })
  
 
 
 
 const handleStartDateChange = (date) => {
  const parameter = {sub_admin_id : sub_aid  ,page : pagination.page , per_page :pagination.per_page ,  start_date : date , end_date : moment( end_date ).add(1,'d')._d}

 
 dispatch(MatDelStartDate(date))
 dispatch(getMaterialDelivery(parameter))
 setValues({
  ...values,
  ['from_date']:date
})
 };
  
 const handleEndDateChange = (date) => {
  const parameter = {sub_admin_id : sub_aid  ,page : pagination.page , per_page :pagination.per_page ,  start_date : values.from_date , end_date : moment( date ).add(1,'d')._d}


 dispatch(MatDelEndDate(date))
 dispatch(getMaterialDelivery(parameter))
   
 
 };
   



  const Delmsg = useSelector(state => state.MaterialDelivery && state.MaterialDelivery.delData && state.MaterialDelivery.delData.message ? state.MaterialDelivery.delData.message : null  )
  const Delerror = useSelector(state => state.MaterialDelivery && state.MaterialDelivery.delError && state.MaterialDelivery.delError.message ? state.MaterialDelivery.delError.message : null  )
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.site_report ? state.login.data.role_permit.site_report : []);





  
  

  
useEffect( () => {
  var t  =  {sub_admin_id : sub_aid,page : pagination.page , per_page : pagination.per_page,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}

  dispatch(getMaterialDelivery(t))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanMaterialDeliveryData())
    dispatch(getMaterialDelivery(t))

    })


    
  }, [ Delmsg  ,Delerror ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
//   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
}


// const onDel = (oldata) =>{

//   dispatch(delMaterialDelivery(oldata.dy_id ,oldata.tableData.id ))
// }
 

const OnView = (olData) =>{
  navigate('/app/sitemaster_material_detail/'+olData.dy_id, {state: olData});
}




const exportField = [
  
  {
    field : "index" , 
    val : "SR. No."
  },
  {
    field : "site_name" , 
    val : "Site Name"
  },  
  {
    field : "name" , 
    val : "Supplier Name"
  },  
  {
    field : "mobile" , 
    val : "Supplier Contact"
  },    
  {
    field : "delivery_date" , 
    val : "Delivery Date"
  }, 
  {
    field : "priority" , 
    val : "Priority"
  }, 
  {
    field : "status" , 
    val : "Status"
  },
]




const getExportData = () => {
  const FilterData = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.delivery_date).format("DD.MM.YYYY")>=moment(start_date).format("DD.MM.YYYY")) && (moment(data.delivery_date).format("DD.MM.YYYY")<=moment(end_date).format("DD.MM.YYYY")));
  var exportData = []

  if(Array.isArray(FilterData) && FilterData.length){
    FilterData.map((element,i) => {
      exportData.push(
        {
          "index":i+1,
          "site_name":element.site_name,
          "name":element.name,
          "mobile":element.mobile,
          "delivery_date":moment(element.delivery_date).format("DD/MM/YYYY"),
          "priority":element.priority,
          "status":element.status,
        }
      )
    })
  }

  return exportData;
}
  

const handleExportDataExcel = () => {

  getExportFileBlob(exportField,getExportData(), 'xlsx', 'Material Delivery')
};

const handleExportDataPDF = () => {
  getExportFileBlob(exportField,getExportData(), 'pdf', 'Material Delivery')
};


  

const handlePageChange = (e , x) =>{
  const parameter = {sub_admin_id : sub_aid,site_id:selected_site  ,page : e , per_page :pagination.per_page ,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}
  var u   = new Promise((resolve ,reject)=>{
    
    if(x !== null  && x !== undefined )
    {
      dispatch(getMaterialDelivery(parameter))
      
    }
    
    resolve()
    
  })
  u.then(()=>{
    
    setPagination({...pagination , ['page']  : e})
  })

}

const handleRPP = (e) =>{
  console.log(e)
  // setPagination({...pagination , ['per_page']  : e})
  const parameter = {sub_admin_id : sub_aid,page : e , per_page :pagination.per_page ,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}

  var u   = new Promise((resolve ,reject)=>{
    
   
      dispatch(getMaterialDelivery(parameter))
      
    
    
    resolve()
    
  })
  u.then(()=>{
    
    // setPagination({...pagination , ['page']  : e})
    setPagination({...pagination , ['per_page']  : e})
  })

}


  return (
    <>
      <Page className={classes.root} title="Material Delivery">
      <ToastContainer    />



      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Material Delivery??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>




        <Container maxWidth={false}>
        <div className={clsx(classes.root2, className)} {...rest}>
        <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {handleExportDataExcel}>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              style={{marginLeft:"5px"}}
              variant="contained"
              className={classes.exportButton} 
              onClick = {handleExportDataPDF}>
                Export to PDF
            </Button>
          </Box>

          <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
          <ThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth                                        
                id="date-picker-dialog"
                label="From Date"
                InputLabelProps={{shrink:true}}
                format="dd/MM/yyyy"
                placeholder="DD/MM/YYYY"
                required={false}
                value={values.from_date}
                maxDate={end_date}
                style={{width:'100%' , borderColor:"orange"}}
                onChange={handleStartDateChange}
                inputVariant="outlined"
                variant="outlined"
                // borderColor="red"
                invalidDateMessage=""
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Grid>
        <Grid item  md={6} lg={3}  xs={3}>
          <ThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                style={{ border: "1px  black" }}
                id="date-picker-dialog"
                label="To Date"
                format="dd/MM/yyyy"
                minDate={start_date}
                value={end_date}
                style={{width:'100%' , borderColor:"orange"}}
                onChange={handleEndDateChange}
                inputVariant="outlined"
                variant="outlined"
                borderColor="red"
              
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Grid>
       

      </Grid>
    </div>
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}     
      tableRef={tableRef}

      title="Material Delivery"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'Order ID', field: 'order_id',editable: "never", },     
        { title: 'Site Name', field: 'site_name',editable: "never", },     
        { title: 'Supplier Name', field: 'name',editable: "never", },         
        { title: 'Delivered To', field: 'accepted_by',editable: "never", },     
        { title: 'Approved By', field: 'approved_by',editable: "never", }, 
        { title: 'Delivery Date', field: 'delivery_date',editable: "never", render: rowData => (
            moment(rowData.delivery_date).format("DD/MM/YYYY")
          ), },     
        { title: 'Priority', field: 'priority',editable: "never", },     
        { title: 'Status', 
            render: rowData => (
             rowData.status==="Delivered" ? 
             <>
             <Typography style={{color:"green"}} variant='overline'>Delivered</Typography>
             </>             
             :
             <>
             <Typography style={{color:"#E36A26"}} variant='overline'>Pending</Typography>
             </>
             
          
            )
        },           
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
      {/*<Grid item xs={4}>
        
       <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
      </Grid>*/}
      {(rowData.status==="Pending" || rowData.status==="Rejected") && role_permit[2] ? 
        <Grid item xs={3}>
          <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
        </Grid>
        :
        <></>
      }
      <Grid item xs={4}>
        <VisibilityIcon onClick={()=>{OnView(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      </Grid>
          ),
        },

      ]}
      components={{
        Pagination: props => (
                     <TablePagination
                     {...props}
                   
                count={countD}
                page={countF}
               
              />
            ),
                  }}

  onChangeRowsPerPage={(e)=>handleRPP(e)}
  onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}              


      data={listData}

        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                emptyRowsWhenPaging:false,  

              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
