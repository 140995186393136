import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useLocation , useNavigate} from 'react-router-dom';

import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';

import {editSubTick} from '../../../Actions/SubAdminActions/Tickets';
import {cleanSubTick} from '../../../Actions/ActionCreators'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



// const user = {
//   avatar: '/static/images/avatars/avatar_6.png',
//   city: 'Los Angeles',
//   country: 'USA',
//   jobTitle: 'Senior Developer',
//   name: 'Upload Profile Image',
//   timezone: moment(Date.now()).format('DD/MM/YY')
// };

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
const classes = useStyles();
const dispatch = useDispatch();
const {state} = useLocation();
const navigate = useNavigate();
const id = useSelector(state => state.login.data.id);
const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));



  const rb = useSelector((state)=> state.login && state.login.data && state.login.data.name ? state.login.data.name : ""    ) 
  const a_id = useSelector((state)=> state.login && state.login.data && state.login.data.admin_id ? state.login.data.admin_id : "")


  const [values, setValues] = useState({
    ticket_id:state.ticket_id,
    sub_admin_reply : state.sub_admin_reply,
    resolved_date:moment(new Date(Date.now())).format('DD/MM/YYYY '),
    status : "Resolved",
  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  const handlePriority  = (e) =>{

    setValues({...values  , ['priority'] : e.target.value})

  }

 
  const handleSubmit = () =>{


    console.log(values);
      
      dispatch(editSubTick(values));
      
  }

 

  // useEffect( () => {
    // dispatch(getBusinessCat())

    // dispatch(getCountryData())
     
    // }, [])





  const addmsg = useSelector(state => state.Sub_Tickets.editData && state.Sub_Tickets.editData.message ? state.Sub_Tickets.editData.message : null)
// //   const adderror = useSelector(state => state.Mh.error && state.Mh.error.message ? state.Mh.error.message : null  )

  
  useEffect( () => {
   if(addmsg !== null && typeof(addmsg)==='string'){
      var x  = new Promise((resolve ,reject)=>{
      
         
         setTimeout(function(){tostS(addmsg);},500);  
         
         resolve()
   
       })
       x.then(()=>{
   
       dispatch(cleanSubTick())
       navigate('/app/list_help')
       })
   }
    
     
    }, [addmsg])


  return (
    <Page
      className={classes.root}
      title="Resolve Issue FeedBack"
    >
              <ToastContainer    />

              <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          
          <Grid
            item
            lg={8}
            md={8}
            xs={12}
            style={{ margin:'auto' }}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Resolve Issue Reply" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Reply"
                name="sub_admin_reply"
                onChange={handleChange}
                required
                value={values.sub_admin_reply}
                variant="outlined"
              />
            </Grid>                                   
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Reply
          </Button>
        </Box>
      </Card>
    </form>
            
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
