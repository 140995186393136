import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#E36A26'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#E36A26',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });
{/* <Text style={styles.total}>{ Number.parseFloat(total).toFixed(2)}</Text> */}


const InvoiceTableFooter = ({items, gst_type}) => {
    const total = items.map(item => parseFloat(item.amt))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    const gst = items.map(item => parseFloat(item.amt)*(parseFloat(item.gst_per)/(100)))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    console.log("gst_type", gst_type)
    return(
        <>    
        <View style={styles.row}>
            <Text style={styles.description}>SUB TOTAL</Text>
            <Text style={styles.total}>{ Number.parseFloat(total).toFixed(2)}</Text>
        </View>
        {gst_type=="IGST" ?
            <View style={styles.row}>
                <Text style={styles.description}>IGST</Text>
                <Text style={styles.total}>{ Number.parseFloat(gst).toFixed(2)}</Text>
            </View>
        :
        <>
            <View style={styles.row}>
                <Text style={styles.description}>CGST</Text>
                <Text style={styles.total}>{ Number.parseFloat(gst/2).toFixed(2)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>SGST</Text>
                <Text style={styles.total}>{ Number.parseFloat(gst/2).toFixed(2)}</Text>
            </View>
        </>
        }
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.total}>{Number.parseFloat(total+gst).toFixed(2)}</Text>
        </View>
       </> 
    )
};
  
  export default InvoiceTableFooter