import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {editProfileManCreBegin,editProfileManCreSuccess,editProfileManCreFailure} from './../ActionCreators'
import {editProfileManPassBegin,editProfileManPassSuccess,editProfileManPassFailure} from './../ActionCreators'
import {editProfileManPicBegin,editProfileManPicSuccess,editProfileManPicFailure} from './../ActionCreators'
import {editSubLogoBegin,editSubLogoSuccess,editSubLogoFailure} from './../ActionCreators'
import {getProfileManCreBegin,getProfileManCreSuccess,getProfileManCreFailure} from './../ActionCreators'



export const editProfileManCre = (session) => {

    return (dispatch) => {
        dispatch(editProfileManCreBegin());

        axios.post(Base_URL + '/subadmin/editSubBasic', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editProfileManCreSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editProfileManCreFailure(err.response.data));

                }
            })
    }
}


export const editProfileManPass = (session) => {

    return (dispatch) => {
        dispatch(editProfileManPassBegin());

        axios.post(Base_URL + '/subadmin/editSubPassword', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editProfileManPassSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editProfileManPassFailure(err.response.data));

                }
            })
    }
}


export const editProfileManPic = (session) => {

    console.log(session);
    return (dispatch) => {
        dispatch(editProfileManPicBegin());

        axios.post(Base_URL + '/subadmin/addSubProfilePic', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editProfileManPicSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editProfileManPicFailure(err.response.data));

                }
            })
    }
}

export const editSubLogo = (session) => {

    console.log(session);
    return (dispatch) => {
        dispatch(editSubLogoBegin());

        axios.post(Base_URL + '/subadmin/addSubLogo', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editSubLogoSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editSubLogoFailure(err.response.data));

                }
            })
    }
}


export const getProfileManCre = (x) => {

    return (dispatch) => {
        dispatch(getProfileManCreBegin());

        axios.get(Base_URL + '/subadmin/getSubProfile' , {params : {admin_id : x}})
            .then((res) => {
                dispatch(getProfileManCreSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getProfileManCreFailure(err.response.data));

                }
            })
    }
}


