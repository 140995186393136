import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getExportFileBlob} from '../../../Config/excelPdfDownload'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardMedia
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {ClientGetTicket} from '../../../Actions/Client/Tickets'
import ReplyAllIcon from '@material-ui/icons/ReplyAll';


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});

 


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const tableRef = React.createRef();

  
  const a_id = useSelector((state)=> state.login && state.login.data && state.login.data.sub_admin_id ? state.login.data.sub_admin_id : "");
  const app_uid = useSelector((state) => state.login && state.login.data && state.login.data.apu_id ? state.login.data.apu_id : "");




 const listData = useSelector((state) =>
  state.ClientAllGet && state.ClientAllGet.ticketGetData.data &&
    state.ClientAllGet.ticketGetData.data.length &&
    Array.isArray(state.ClientAllGet.ticketGetData.data)
      ? state.ClientAllGet.ticketGetData.data
      : []
  );



  const countD  =  useSelector((state) => state.ClientAllGet && 
  state.ClientAllGet.ticketGetData.data &&
  Array.isArray(state.ClientAllGet.ticketGetData.data) ? 
  parseInt(state.ClientAllGet.ticketGetData.total_length) : 0 )
  
  
  const countF  =  useSelector((state) => state.ClientAllGet && 
  state.ClientAllGet.ticketGetData.data &&
  Array.isArray(state.ClientAllGet.ticketGetData.data) ? 
  parseInt(state.ClientAllGet.ticketGetData.page) : 0 )
  
  
  const [pagination , setPagination] = useState({
    per_page : 5,
    page : countF,
  
  })

  const [open, setOpen] = useState({show:false,data:[]});


  const handleClose = () =>{

    setOpen({...open, show:false, data:[]});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      data:data
    });
  
  }

  
 
  const Delmsg = useSelector(state => state.AddBusiness && state.AddBusiness.deldata && state.AddBusiness.deldata.message ? state.AddBusiness.deldata.message : null  )
  const Delerror = useSelector(state => state.AddBusiness && state.AddBusiness.delerror && state.AddBusiness.delerror.message ? state.AddBusiness.delerror.message : null  )


 
  
  const [values, setValues] = useState({
   
    country:"",
  
  
    });
  
  
  
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  
  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };



  

  
useEffect( () => {
  var t = {
    sub_admin_id : a_id,
    page : pagination.page, 
    per_page :pagination.per_page,  
    app_uid:app_uid  
                                                             
                     }
    dispatch(ClientGetTicket(t))

    
  }, [])


  const navigate = useNavigate();


  const addNewBornbutton = () =>{
    navigate('/app/addticket')
    console.log("Place order")
    
  }


  


const exportField = [
  
  {
    field : "ticket_id" , 
    val : "Ticket ID"
  },
  {
    field : "issue" , 
    val : "Issue Name"
  },
  {
    field : "raised_by" , 
    val : "Created By"
  },
  {
    field : "message" , 
    val : "Description"
  },
  {
    field : "priority" , 
    val : "Priority"
  },
  {
    field : "status" , 
    val : "Status"
  }, 
  
]



  return (
    <>
      <Page className={classes.root} title="Tickets">
      <ToastContainer    />

      
      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Reply</DialogTitle>
          <DialogContent>
            
              {Object.keys(open.data) && Object.keys(open.data).length ?
              
              <Typography variant="body">
              {open.data.sub_admin_reply!==null && open.data.sub_admin_reply!==undefined && open.data.sub_admin_reply!=="" ? 
              open.data.sub_admin_reply:
              "Reply is Not Received Yet!!"
              }
              
              
              </Typography>
              :
              <></>
               }
            

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            
          </DialogActions>
        </Dialog>



        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              <Button 
                color="primary" 
                variant="contained"
                className={classes.exportButton} 
                onClick = {
                  () => getExportFileBlob(exportField,listData, 'xlsx', 'Ticket')
                }>
                  Export to Excel
              </Button>
              <Button 
                color="primary" 
                variant="contained"
                className={classes.exportButton} 
                onClick = {
                  () => getExportFileBlob(exportField,listData, 'pdf', 'Ticket')
                }>
                  Export to PDF
              </Button>
              
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Raise Ticket
              </Button>
            </Box>
            
    
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Tickets"
      columns={[
       
        { title: 'Ticket ID', render : rowData =>(
          <>
          {rowData.tableData.id +1}
          </>
        )  },
        { title: 'Issue', field: 'issue',editable: "never", },
        { title: 'Message', field: 'message',editable: "never", },
        { title: 'Priority', field: 'priority',editable: "never", },
        { title: 'Status', field: 'status',editable: "never", },
        { title: 'View Reply',
          render: rowData => (
            <ReplyAllIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
          )
        },
   

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
