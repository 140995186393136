import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {PDFViewer,usePDF, pdf, Document,  BlobProvider} from '@react-pdf/renderer'


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import tableIcons from '../../../../Config/IconsFile'

import {getMaterialCategory} from '../../../../Actions/SubAdminActions/M_MaterialCategory';
import {getMaterialMat} from '../../../../Actions/SubAdminActions/M_MaterialMat';
import {getResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit'
import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites';
import {getVendor} from '../../../../Actions/SubAdminActions/M_Vendor';
import {getAppUser} from '../../../../Actions/SubAdminActions/AppUserManagement'
import {acceptMatIndent, orderMaterialIndent} from '../../../../Actions/SubAdminActions/Material_Indent'
import { getGst } from '../../../../Actions/SubAdminActions/Gst';

import FileDownloader from '../../../../components/FileDownloader'

import invoice from '../../../../components/data/invoice'
import Invoice from '../../../../components/reports/Invoice'


import { Link } from "react-router-dom";


import generatePDF from "./reportGenerator";

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));



const TicketsComponent = ({ tickets }) => {

   // a function that assigns bootstrap styling classes based on 
   // the status of the ticket
     const assignColorToTicketStatus = ticket => {
       if (ticket.status === "completed") {
         return "p-3 mb-2 bg-success text-white";
       } else if (ticket.status === "in_progress") {
         return "p-3 mb-2 bg-warning text-dark";
       } else if (ticket.status === "opened") {
         return "p-3 mb-2 bg-light text-dark";
       }
     };
     return (
       <div className="container">
         {tickets.length === 0 ? (
           "You currently have no tickets created"
         ) : (
           <table className="table">
             <thead>
               <tr>
                 <th scope="col">#</th>
                 <th scope="col">Title</th>
                 <th scope="col">Issue</th>
                 <th scope="col">Status</th>
                 <th scope="col"></th>
               </tr>
             </thead>
             <tbody>
               {tickets.map(ticket => (
                 <tr key={ticket.id}>
                   <td>{ticket.id}</td>
                   <td>{ticket.title}</td>
                   <td>{ticket.request}</td>
                   <td className={assignColorToTicketStatus(ticket)}>
                     {ticket.status}
                   </td>
                   <td>
                     <Link to={`/ticket/${ticket.id}`}>See comments</Link>
                   </td>
                 </tr>
               ))}
             </tbody>
           </table>
         )}
       </div>
     );
   };

const AddBusiness = ({ className, ...rest }) => {
  
   const classes = useStyles();

   const [tickets, setTickets] = useState([
      { id : 1,title : 'A',request : "R1",status : false},
      { id : 2,title : 'b',request : "R2",status : false},
   ]);



  const reportTickets = tickets.filter(ticket => ticket.status === "completed");


  return (
    <Page className={classes.root} title="Order Material" >
      <ToastContainer    />

      <div>
      <div className="container mb-4 mt-4 p-3">
        <div className="row">
         
            <button
              className="btn btn-primary"
              onClick={() => generatePDF(reportTickets)}
            >
              Generate monthly report
            </button>
         
        </div>
      </div>
      <TicketsComponent tickets={tickets} />
    </div>
      
      <FileDownloader />

    </Page>
  );
};

export default AddBusiness;
