import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { ThemeProvider } from "@material-ui/styles";

import {addStockTransfer, getItemData} from "../../../../Actions/Inventory/StockTransfer";
import {cleanStockTransferData} from "../../../../Actions/Inventory/ActionCreators";

import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites';
import {getResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit';
import {getStock} from '../../../../Actions/Inventory/Stock';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
   margin: theme.spacing(0),
   minWidth: '100%',
   maxWidth:"100%"
   },
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();

  const a_id = useSelector(state => state.login && state.login.data && state.login.data.r_subadmin?state.login.data.r_subadmin:"");
  const admin_name = useSelector(state => state.login && state.login.data && state.login.data.name ? state.login.data.name:null)
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : "")
  const id = useSelector(state => state.login && state.login.data 
   && state.login.data.admin_id ? state.login.data.admin_id:"")

  const SiteData = useSelector((state) =>
  state.AddSites && state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );
  
  const handleToSiteData = () =>{
   if(selected_site!==null && selected_site!=="" && selected_site!==undefined){
      const from_site_id = selected_site;
      var toSiteData = [...SiteData]
      const idx = toSiteData.indexOf(toSiteData.filter(data => data.site_id===from_site_id)[0])
      toSiteData.splice(idx, 1)   
      return toSiteData

   }
   return []
  }


  const [values, setValues] = useState({
   item_type:"",
   item_name:"",
   item_cat:"",
   item_unit:"",
   item_quan:"",
   from_id:selected_site,
   to_id:"",
   sub_admin_id:id,
   item_cat_data:[],
   item_name_data:[],
   from_data:SiteData,
   to_data:handleToSiteData(),
   to_site_disable:false,
   available_quan:"",
  });



  const StockData = useSelector(state => state.Stock && state.Stock.getData && Array.isArray(state.Stock.getData) && state.Stock.getData.length ? state.Stock.getData : []);


  const handleType = (event) => {
   const type_name = event.target.value;
   var arr = [];
   var unit = "";
   if(type_name==="Material"){
      MatCatData.map(data => {arr.push({"label" : data.name , "value" : data.mc_id})});
   }else if(type_name==="Machine"){
      MacCatData.map(data => {arr.push({"label" : data.name , "value" : data.mch_id})});
      unit=0;
   }

   setValues({
      ...values,
      ['item_type']:type_name,
      ['item_name_data']:[],
      ['item_cat_data']:arr,
      ['item_unit']:unit
   })   
   
  }
  

  const handleChangeCategory = (event) => {
   const CategoryVal = event.target.value;
   var NameArr = [];
   if (values.item_type==="Material"){       
     MatData.filter(data => data.mat_cat_id===CategoryVal).map(PushData => {NameArr.push({"label":PushData.name, "value":PushData.mm_id})})      
   }else if(values.item_type==="Machine"){
     MacData.filter(data => data.mch_cat_id===CategoryVal).map(PushData => {NameArr.push({"label":PushData.machine_name, "value":PushData.sub_machine_id})})
   }


   setValues({
     ...values, 
     ['item_cat']:CategoryVal,
     ['item_name_data']:NameArr,
     ['item_name']:"",
   });

   
 };
 

 
 const handleChangeName = (event) => {
   const i_name = event.target.value;
   var avail_quan=[]
   if (values.item_cat!=="" && values.item_type=="Material"){
      avail_quan = StockData.filter(data => (data.mc_id==values.item_cat && data.mm_id==i_name && data.site_id==values.from_id))
   }
   if (values.item_cat!=="" && values.item_type=="Machine"){
      avail_quan = StockData.filter(data => (data.mch_id==values.item_cat && data.sub_machine_id==i_name && data.site_id==values.from_id))
   }
  
   
   setValues({
      ...values, 
      ['item_name']: event.target.value,
      ['available_quan']:avail_quan
   });


 };


  const handleChange = (event) => {
     setValues({
        ...values,
        [event.target.name]:event.target.value
     })
  } 

  const handleUnitChange = (event) =>{
   setValues({
      ...values, 
      ['item_unit']:event.target.value,
    });
       
 };
 
 const handleChangeFromSite = (event) => {
   const from_site_id = event.target.value;
   var toSiteData = [...SiteData]
   const idx = toSiteData.indexOf(toSiteData.filter(data => data.site_id===from_site_id)[0])
   toSiteData.splice(idx, 1)
   console.log(toSiteData, "to site data")
   setValues({
      ...values, 
      ['from_id']:from_site_id,
      ['to_data']:toSiteData,
      ['to_id']:"",
      ['to_site_disable']:false
    });
    
   
  };
 
  const handleChangeToSite = (event) => {
   
   setValues({
      ...values, 
      ['to_id']:event.target.value,
    });
    

  };

  const addmsg = useSelector(state => state.StockTransfer.data && state.StockTransfer.data.message ? state.StockTransfer.data.message : null  )
  const adderror = useSelector(state => state.StockTransfer.error && state.StockTransfer.error.message ? state.StockTransfer.error.message : null  )
  
  const site_id_list = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.site_ids !== null ? state.login.data.role_permit.site_ids:null)


  useEffect(()=>{
   dispatch(getItemData(a_id))
   dispatch(getSites(a_id, site_id_list))
   dispatch(getResourceUnit(a_id))
   dispatch(getStock(a_id))

   let p = new Promise((resolve, reject) => {
      if(addmsg!==null && typeof(addmsg) === 'string')
      {
        tostS(addmsg)
      }
      if(adderror!==null && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }


      resolve()
   })
   p.then(() => {
      dispatch(cleanStockTransferData())
   })

  },[addmsg , adderror])

 

  const UnitData = useSelector((state) =>
  state.AddResourceUnit && state.AddResourceUnit.getData &&
    state.AddResourceUnit.getData.length &&
    Array.isArray(state.AddResourceUnit.getData)
      ? state.AddResourceUnit.getData
      : []
  );
  
  
  
  const MatCatData = useSelector((state) =>
  state.StockTransfer && state.StockTransfer.itemData &&
   Object.keys(state.StockTransfer.itemData) &&
   Object.keys(state.StockTransfer.itemData).length &&
   Array.isArray(state.StockTransfer.itemData['Material_Cat']) &&
    (state.StockTransfer.itemData['Material_Cat']).length
      ? state.StockTransfer.itemData['Material_Cat']
      : []
  );
  
  const MatData = useSelector((state) =>
  state.StockTransfer && state.StockTransfer.itemData &&
   Object.keys(state.StockTransfer.itemData) &&
   Object.keys(state.StockTransfer.itemData).length &&
   Array.isArray(state.StockTransfer.itemData['Material_Name']) &&
    (state.StockTransfer.itemData['Material_Name']).length
      ? state.StockTransfer.itemData['Material_Name']
      : []
  );
  
  
  
  const MacCatData = useSelector((state) =>
  state.StockTransfer && state.StockTransfer.itemData &&
   Object.keys(state.StockTransfer.itemData) &&
   Object.keys(state.StockTransfer.itemData).length &&
   Array.isArray(state.StockTransfer.itemData['Machine_Cat']) &&
    (state.StockTransfer.itemData['Machine_Cat']).length
      ? state.StockTransfer.itemData['Machine_Cat']
      : []
  );
  
  
  
  const MacData = useSelector((state) =>
  state.StockTransfer && state.StockTransfer.itemData &&
   Object.keys(state.StockTransfer.itemData) &&
   Object.keys(state.StockTransfer.itemData).length &&
   Array.isArray(state.StockTransfer.itemData['Machine_Name']) &&
    (state.StockTransfer.itemData['Machine_Name']).length
      ? state.StockTransfer.itemData['Machine_Name']
      : []
  );
  
  
  const handleSubmit =()=>{
   if(values.from_id===null || values.from_id===undefined || values.from_id===""){
      tostE("Please Select From Site!!");
   }else if(values.to_id===null || values.to_id===undefined || values.to_id===""){
      tostE("Please Select To Site!!");
   }else if(values.item_type===null || values.item_type===undefined || values.item_type===""){
      tostE("Please Select Item Type!!");
   }else if(values.item_cat===null || values.item_cat===undefined || values.item_cat===""){
      tostE("Please Select Item Category!!");
   }else if(values.item_name===null || values.item_name===undefined || values.item_name===""){
      tostE("Please Select Item!!");
   }else if(values.item_quan===null || values.item_quan===undefined || values.item_quan===""){
      tostE("Please Enter Item Quantity!!");
   }else if(values.item_unit===null || values.item_unit===undefined || values.item_unit===""){
      tostE("Please Enter Item Unit!!");
   }else{
      if(values.item_type==="Material" && values.item_quan>0){
         const ItemAvailQuan = values.available_quan.filter(data => data.sub_unit_id==values.item_unit)[0].item_quan;
         if (values.item_quan<=ItemAvailQuan){
            const sendData = {
               from_id:values.from_id,
               to_id:values.to_id,
               item_type:values.item_type,
               item_name:values.item_name,
               item_cat:values.item_cat,
               item_unit:values.item_unit,
               item_quan:values.item_quan,                  
               sub_admin_id:values.sub_admin_id,
               entry_type:"Site Transfer by "+admin_name,
               remark:"Site Transfer by "+admin_name
            }
            console.log(sendData, "Send Data");
            dispatch(addStockTransfer(sendData));
      
            setValues({
               ...values,
               item_type:"",
               item_name:"",
               item_cat:"",
               item_unit:"",
               item_quan:"",
               item_cat_data:[],
               item_name_data:[],
               available_quan:""
            })

         }else{
            tostE("Transfer Quantity can't exceed Available quantity")
         }
      }else{
         tostE("Please Enter Valid Quantity to Transfer.");
      }
   }
  }

  return (
    <>
      <Page className={classes.root} title="Transfer Item">
      <ToastContainer    />
         <Container maxWidth="lg">
            <Grid container spacing={3}>
               <Grid item md={12} lg={12} xs={12}>
                  <Card>
                     <CardHeader title ="Transfer Item"/>
                     <Divider />
                     <CardContent>
                     <Grid container spacing={3}>
            
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl} >
                              <InputLabel id="demo-simple-select-outlined-label">From Site</InputLabel>
                              <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.from_id}
                              onChange={handleChangeFromSite}
                              label="From Site"
                              style={{margin:0}}
                              >                              
                              {values.from_data.map((data , i)=>
                                 <MenuItem key={i}  value={data.site_id}>{data.site_name}</MenuItem>
                              )}
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">To Site</InputLabel>
                              <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.to_id}
                              onChange={handleChangeToSite}
                              label="To Site"
                              style={{margin:0}}
                              disabled={values.to_site_disable}
                              >                              
                              {values.to_data.map((data , i)=>
                                 <MenuItem key={i}  value={data.site_id}>{data.site_name}</MenuItem>
                              )}
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Item Type*</InputLabel>
                              <Select
                                 fullWidth
                                 labelId="demo-simple-select-outlined-label"
                                 id="demo-simple-select-outlined"
                                 label="Item Type"
                                 value={values.item_type}
                                 onChange={(e) => {handleType(e)}}
                              >
                                 <MenuItem value="Material">Material</MenuItem>
                                 <MenuItem value="Machine">Machine</MenuItem>
                              </Select>
                           </FormControl>
                        </Grid>                        
                     
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Item Category*</InputLabel>
                              <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.item_cat}
                              onChange={(e) => {handleChangeCategory(e)}}
                              label="Item Category"
                              style={{margin:0}}
                              >                              
                              {values.item_cat_data.map((data , i)=>
                                 <MenuItem key={i}  value={data.value}>{data.label}</MenuItem>
                              )}
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Item Name*</InputLabel>
                              <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.item_name}
                              onChange={(e) => {handleChangeName(e)}}
                              label="Item Name"
                              style={{margin:0}}
                              >                              
                              {values.item_name_data.map((data , i)=>
                                 <MenuItem key={i}  value={data.value}>{data.label}</MenuItem>
                              )}
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                        <TextField
                           fullWidth
                           label="Quantity"
                           name="item_quan"
                           helperText="Please Enter quantity less than or equal to Available quantity."
                           value={values.item_quan}
                           required
                           onChange={handleChange}
                           variant="outlined"
                        />                        
                        </Grid>
                        {values.item_type==="Material" ? 
                           <Grid item md={6} xs={12}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                 <InputLabel id="demo-simple-select-outlined-label">Unit*</InputLabel>
                                 <Select
                                 labelId="demo-simple-select-outlined-label"
                                 id="demo-simple-select-outlined"
                                 value={values.item_unit}
                                 onChange={handleUnitChange}
                                 label="Unit"
                                 style={{margin:0}}
                                 >
                                 
                                 {UnitData.map((data , i)=>
                                    <MenuItem key={i}  value={data.sub_unit_id}>{data.unit_name}</MenuItem>
                                 )}
                                 </Select>
                              </FormControl>
                           </Grid>
                        :<></>
                        }
                        {values.available_quan!=="" && Array.isArray(values.available_quan) && values.available_quan.length ?
                        <Grid item md={6} xs={12}>
                              <Typography variant="outlined">Available Quantity :</Typography>
                              {values.available_quan.map(data=>(                                 
                                 <>
                                 <Typography variant="caption" style={{color:"green"}}>
                                 {data.item_quan} {data.unit_name} | 
                                 </Typography>

                                 </>
                              
                              ))}
                           
                        </Grid>
                        
                        :
                        <></>
                         }
                     </Grid>
                     </CardContent>
                     <Divider />
                     <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>
                           Transfer
                        </Button>
                     </Box>
                  </Card>
               </Grid>
            </Grid>

         </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
