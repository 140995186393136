import {GET_MATERIAL_INDENT_LOADING , GET_MATERIAL_INDENT_SUCCESS , GET_MATERIAL_INDENT_FAILURE, CLEAN_MATERIAL_INDENT_DATA} from '../../Actions/Types';
import {DEL_MATERIAL_INDENT_LOADING , DEL_MATERIAL_INDENT_SUCCESS , DEL_MATERIAL_INDENT_FAILURE} from '../../Actions/Types';
import {ORDER_MATERIAL_INDENT_LOADING , ORDER_MATERIAL_INDENT_SUCCESS , ORDER_MATERIAL_INDENT_FAILURE} from '../../Actions/Types';
import {ACCEPT_MAT_INDENT_LOADING , ACCEPT_MAT_INDENT_SUCCESS , ACCEPT_MAT_INDENT_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const MaterialIndent = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getData:[{}],
            getError:{},
            delData  : [{}] ,
            delError: {},
            acceptData  : [{}] ,
            acceptError: {},
            orderData  : [{}] ,
            orderError: {},
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,                
                loading:false,                
                getData:[{}],
                getError:{},
                delData : [{}], 
                delError:{},
                acceptData  : [{}] ,
                acceptError: {},
                orderData  : [{}] ,
                orderError: {},
            }
    
    
        case CLEAN_MATERIAL_INDENT_DATA:
            return {
                ...state,                
                loading:false,                
                getData : [{}],
                getError:{},
                delData : [{}], 
                delError:{},
                acceptData  : [{}] ,
                acceptError: {},
                orderData  : [{}] ,
                orderError: {},
            }
    
        case GET_MATERIAL_INDENT_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_MATERIAL_INDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
                
            }
        case GET_MATERIAL_INDENT_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                
            }   
        
        case DEL_MATERIAL_INDENT_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_MATERIAL_INDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_MATERIAL_INDENT_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
        
        
        case ACCEPT_MAT_INDENT_LOADING:
            return {
                ...state,
                loading:true,
                acceptError: {},
                acceptData : [{}]
                
            }
        case ACCEPT_MAT_INDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                acceptError:{},
                acceptData:action.data,
            }
        case ACCEPT_MAT_INDENT_FAILURE:
            return {
                ...state,
                loading:false,
                acceptError:action.error,
                acceptData:{},
            }
        
        
        case ORDER_MATERIAL_INDENT_LOADING:
            return {
                ...state,
                loading:true,
                orderError: {},
                orderData : [{}]
                
            }
        case ORDER_MATERIAL_INDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                orderError:{},
                orderData:action.data,
            }
        case ORDER_MATERIAL_INDENT_FAILURE:
            return {
                ...state,
                loading:false,
                orderError:action.error,
                orderData:{},
            }
        

        default:
        return state
    
    }
    }
    
    
    
export default MaterialIndent;
    