import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
import   Refresh  from '@material-ui/icons/Refresh'


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable  from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CheckIcon from '@material-ui/icons/Check';
import DetailIcon from '@material-ui/icons/List';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";


import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";


import {getMaterialIndent, delMaterialIndent, editMaterialIndent, orderMaterialIndent} from '../../../../Actions/SubAdminActions/Material_Indent'
import {cleanMaterialIndentData, MatIndStartDate, MatIndEndDate} from '../../../../Actions/ActionCreators'
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'



const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
      
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
      
    },
  },
});



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  exportButton: {
    alignItem: 'right',
  },
}));

const ListView = ({ className, ...rest }) => {
  
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.procurement ? state.login.data.role_permit.procurement : []);
  
  const [open, setOpen] = useState({show:false, id:"", showOrder:false});
  
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)
  
  const start_date = useSelector(state => state.SubDateFilters && state.SubDateFilters.MatIndStartDate ? state.SubDateFilters.MatIndStartDate:new Date(Date.now()))
  const end_date = useSelector(state => state.SubDateFilters && state.SubDateFilters.MatIndEndDate ? state.SubDateFilters.MatIndEndDate:new Date(Date.now()))
  const tableRef = React.createRef();
  
  const [values, setValues] = useState({
    data:"",
    from_date: "",
  })
 

  
  const  filterData = (Wdata) => {
    
    var Fdata = []
    if(Array.isArray(Wdata) && Wdata.length){

      Fdata = Wdata.filter(data => (!selected_site || data.site_id==selected_site));
      console.log(Fdata)
      return Fdata
    }else{

      return Fdata
    }
    
  
  } 
  
  const listData = useSelector((state) =>
  state.MaterialIndent && state.MaterialIndent.getData &&
   Object.keys(state.MaterialIndent.getData).length &&
    Array.isArray(state.MaterialIndent.getData.data)
      ? filterData(state.MaterialIndent.getData.data) 
      : []
  );


  const countD= useSelector((state) =>
  state.MaterialIndent && state.MaterialIndent.getData &&
   Array.isArray(state.MaterialIndent.getData.data)
      ? parseInt(state.MaterialIndent.getData.total_length)
      : 0
  );



  const countF= useSelector((state) =>
  state.MaterialIndent && state.MaterialIndent.getData &&
   Array.isArray(state.MaterialIndent.getData.data)
      ? parseInt(state.MaterialIndent.getData.page)
      : 0
  );
  
  
  

const handleStartDateChange = (date) => {

  // filterData(listData, date, end_date)
  dispatch(MatIndStartDate(date))

  dispatch(getMaterialIndent({sub_admin_id : sub_aid  ,page : pagination.page , per_page :pagination.per_page ,  start_date : date , end_date : moment( end_date ).add(1,'d')._d}))
  setValues({
    ...values,
    ['from_date']:date
  })
};
 
const handleEndDateChange = (date) => {

  // filterData(listData, start_date, date)

  // const DataList = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.required_on).format("DD.MM.YYYY")>=moment(values.from_date).format("DD.MM.YYYY")) && (moment(data.required_on).format("DD.MM.YYYY")<=moment(date).format("DD.MM.YYYY")));
  dispatch(MatIndEndDate(date))

  dispatch(getMaterialIndent({sub_admin_id : sub_aid  ,page : pagination.page , per_page :pagination.per_page ,  start_date : values.from_date , end_date : moment( date ).add(1,'d')._d}))

};
  

  const handleClose = () =>{

    setOpen({...open, show:false, id:"", showOrder:false});
  }

const handleOpen = (data) =>{

  setOpen({
    ...open,
    show:true,
    showOrder:false,
    id:data.indent_id
  });

}


const handleOpenOrder = (data) =>{
  
  setOpen({
    ...open,
    show:false,
    showOrder:true,
    id:data.indent_id
  });

}


const handleConfirm = () =>{
  if(open.id!=="" && open.show!==false && open.showOrder===false){
    dispatch(delMaterialIndent(open.id))

    setOpen({
      ...open,
      show:false,
      showOrder:false,
      id:""
    })
  }

  if(open.id!=="" && open.show===false && open.showOrder!==false){
      
    var d = {
      "indent_id" : open.id
      }

    dispatch(orderMaterialIndent(d))


    setOpen({
      ...open,
      show:false,
      showOrder:false,
      id:""
    })
  }
}

  
 
  const Delmsg = useSelector(state => state.MaterialIndent && state.MaterialIndent.delData && state.MaterialIndent.delData.message ? state.MaterialIndent.delData.message : null  )
  const Delerror = useSelector(state => state.MaterialIndent && state.MaterialIndent.delError && state.MaterialIndent.delError.message ? state.MaterialIndent.delError.message : null  )


  const Editmsg = useSelector(state => state.MaterialIndent && state.MaterialIndent.acceptData && state.MaterialIndent.acceptData.message ? state.MaterialIndent.acceptData.message : null  )
  const Editerror = useSelector(state => state.MaterialIndent && state.MaterialIndent.acceptError && state.MaterialIndent.acceptError.message ? state.MaterialIndent.acceptError.message : null  )
  
  const Ordermsg = useSelector(state => state.MaterialIndent && state.MaterialIndent.orderData && state.MaterialIndent.orderData.message ? state.MaterialIndent.orderData.message : null  )
  const Ordererror = useSelector(state => state.MaterialIndent && state.MaterialIndent.orderError && state.MaterialIndent.orderError.message ? state.MaterialIndent.orderError.message : null  )


  const [pagination , setPagination] = useState({
    per_page : 5,
    page : countF,
  
  })

  
const sub_aid = useSelector(state => state.login.data.r_subadmin)
  
useEffect( () => {

  // if(listData.length == 0)
  // {
    var t  =  {sub_admin_id : sub_aid  ,page : pagination.page , per_page : pagination.per_page,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}

    dispatch(getMaterialIndent(t))
  // }
  


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      if(Ordermsg && typeof(Ordermsg) === 'string')
      {
        tostS("Order Created Successfully!!")
      }
      if(Ordererror && typeof(Ordererror) === 'string')
      {
          tostE(Ordererror)
      }
      
      if(Editmsg && typeof(Editmsg) === 'string')
      {
        tostS(Editmsg)
      }
      if(Editerror && typeof(Editerror) === 'string')
      {
          tostE(Editerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanMaterialIndentData()) 
    dispatch(getMaterialIndent(t))

    })


    
  }, [ Delmsg  ,Delerror,Editmsg  ,Editerror,Ordermsg  ,Ordererror  ])


  const navigate = useNavigate();


const onAccept = (olData) =>{
    navigate('/app/materialindent_accept/'+olData.indent_id , { state: olData })
  }
  

const onOrder = (olData) =>{
    navigate('/app/materialindent_order/'+olData.indent_id , { state: olData })
  }
  

const handleView = (olData) =>{
  // console.log(olData)
  navigate('/app/materialindent_detail/'+olData.indent_id , { state: olData })
}
 


  const exportField = [
    {
      field : "index" , 
      val : "Sr. No."
    },     
    {
      field : "order_id" , 
      val : "Order ID"
    }, 
    {
      field : "required_on" , 
      val : "Required On"
    }, 
    {
      field : "priority" , 
      val : "Priority"
    }, 
    {
      field : "site_name" , 
      val : "Site Name"
    },
    {
      field : "status" , 
      val : "Status"
    },
  ]

const getExportData = () => {
  const FilterData = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.required_on).format("DD.MM.YYYY")>=moment(start_date).format("DD.MM.YYYY")) && (moment(data.required_on).format("DD.MM.YYYY")<=moment(end_date).format("DD.MM.YYYY")));
  var exportData = []

  if(Array.isArray(FilterData) && FilterData.length){
    FilterData.map((element,i) => {
      exportData.push(
        {
          "index":i+1,
          "order_id":element.order_id,
          "required_on":moment(element.required_on).format("DD/MM/YYYY"),
          "priority":element.priority,
          "site_name":element.site_name,
          "status":element.status,
        }
      )
    })
  }

  return exportData;
}
  

const handleExportDataExcel = () => {

  getExportFileBlob(exportField,getExportData(), 'xlsx', 'Material Indent')
};

const handleExportDataPDF = () => {
  getExportFileBlob(exportField,getExportData(), 'pdf', 'Material Indent')
};




const handlePageChange = (e , x) =>{
 
  var u   = new Promise((resolve ,reject)=>{
    
    if(x !== null  && x !== undefined )
    {
      dispatch(getMaterialIndent({sub_admin_id : sub_aid  ,page : e , per_page :pagination.per_page ,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}))
      
    }
    
    resolve()
    
  })
  u.then(()=>{
    
    setPagination({...pagination , ['page']  : e})
  })

}

const handleRPP = (e) =>{
  console.log(e)
  // setPagination({...pagination , ['per_page']  : e})

  var u   = new Promise((resolve ,reject)=>{
    
   
      dispatch(getMaterialIndent({sub_admin_id : sub_aid  ,page : pagination.page, per_page :e ,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}))
      
    
    
    resolve()
    
  })
  u.then(()=>{
    
    // setPagination({...pagination , ['page']  : e})
    setPagination({...pagination , ['per_page']  : e})
  })

}




  return (
    <>
      <Page className={classes.root} title="Material Indent">
      <ToastContainer    />


      
      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Material Indent??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog disableBackdropClick={true} open={open.showOrder} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm Order</DialogTitle>
          <DialogContent>
            <Typography variant="h5">
              Are you sure you want to Order this Material Indent??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>




        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
          <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {handleExportDataExcel}>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              style={{marginLeft:"5px"}}
              variant="contained"
              className={classes.exportButton} 
              onClick = {handleExportDataPDF}>
                Export to PDF
            </Button>
          </Box> 
            <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
          <ThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth                                        
                id="date-picker-dialog"
                label="From Date"
                InputLabelProps={{shrink:true}}
                format="dd/MM/yyyy"
                placeholder="DD/MM/YYYY"
                required={false}
                value={values.from_date}
                maxDate={end_date}
                style={{width:'100%' , borderColor:"orange"}}
                onChange={handleStartDateChange}
                inputVariant="outlined"
                variant="outlined"
                // borderColor="red"
                invalidDateMessage=""
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Grid>
        <Grid item  md={6} lg={3}  xs={3}>
          <ThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                id="date-picker-dialog"
                label="To Date"
                format="dd/MM/yyyy"
                minDate={start_date}
                value={end_date}
                style={{width:'100%' , borderColor:"orange",  border: "1px  black"}}
                onChange={handleEndDateChange}
                inputVariant="outlined"
                variant="outlined"
                borderColor="red"
              
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Grid>
       

      </Grid>
    </div>
    
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
               tableRef={tableRef}
      icons={tableIcons}      
      
      title="Material Indents"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',addNewBornbuttonages && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        // {"create_on" : "" ,'order_id' :"" , 'req_date' :"",'prio' :"high" , 'creator' :"" , "status":'Pending' }
        
        // { title: 'SR. No.', render : rowData=> (
        //   <>
        //   {rowData.tableData.id+1}

        //   </>
        // ) }, 
        { title: 'Order ID', field: 'order_id',editable: "never", },     
        
        { title: 'Required Date', 
            render: rowData => (moment(rowData.required_on).format("DD.MM.YYYY"))
        },     
        { title: 'Priority', field: 'priority',editable: "never", },     
        { title: 'Site Name', field: 'site_name',editable: "never", },     
        { title: 'Accepted BY', field: 'accepted_by',editable: "never", },     
        { title: 'Status', 
            render: rowData => (
             rowData.status==="Accepted" ? 
             <>
             <Typography style={{color:"green"}} variant='overline'>Accepted</Typography>
             </>
             :
             rowData.status==="Rejected" ? 
             <>
             <Typography style={{color:"red"}} variant='overline'>Rejected</Typography>
             </>
             :             
             rowData.status==="Admin_Resolved" ? 
             <>
             <Typography style={{color:"Blue"}} variant='overline'>Admin Resolved</Typography>
             </>             
             :
             <>
             <Typography style={{color:"#E36A26"}} variant='overline'>Pending</Typography>
             </>
             
          
            )
        },     
        { title: 'Place Order', 
            render: rowData => (
             rowData.is_order_place===true ? 
             <>
             <Typography style={{color:"green"}} variant='overline'>Ordered</Typography>
             </>
             :             
             rowData.is_issued ? 
             <>
             <Typography style={{color:"Blue"}} variant='overline'>Issued</Typography>
             </>
             :
             <>
             <Typography style={{color:"#E36A26"}} variant='overline'>Pending</Typography>
             </>
             
          
            )
        },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              
              
              <Grid item xs={3}>
                <DetailIcon onClick={()=>{handleView(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              {(rowData.status==="Pending" || rowData.status==="Rejected") && role_permit[2] ? 
              <Grid item xs={3}>
                <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :
              <></>
              }
              {rowData.status==="Pending"?              
                <Grid item xs={3}>
                  <CheckIcon onClick={()=>{onAccept(rowData)}} style={{cursor:"pointer"}} />
                </Grid>
              :<></>
              }
              {rowData.status!=="Rejected" && !rowData.is_order_place && rowData.is_issued===false ?
              
                <Grid item xs={3}>
                  
                <ShoppingCartIcon onClick={()=>{onOrder(rowData)}} style={{cursor:"pointer"}} />
                </Grid>:<></>
              }
              </Grid>
          ),
        },

      ]}
      
      components={{
        Pagination: props => (
                     <TablePagination
                     {...props}
                   
                count={countD}
                page={countF}
               
              />
            ),
                  }}

  onChangeRowsPerPage={(e)=>handleRPP(e)}
  onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}              


  data={listData}
      

  options={{
                
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                emptyRowsWhenPaging:false,  
              }}
      
      
    
             

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
