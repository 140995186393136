import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { ThemeProvider } from "@material-ui/styles";

import {getStock} from '../../../../Actions/Inventory/Stock'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  exportButton: {
    alignItem: 'right',
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const navigate = useNavigate();
  const a_id = useSelector(state => state.login && state.login.data && state.login.data.r_subadmin?state.login.data.r_subadmin:"");

  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const StockData = useSelector(state => state.Stock.getData && 
    Array.isArray(state.Stock.getData) && state.Stock.getData.length ?
    (state.Stock.getData).filter(data => !selected_site || data.site_id == selected_site) : [])


  useEffect(() => {
      dispatch(getStock(a_id))
    
    }, [])

  const addButton = () =>{
    navigate('/app/inventory/add')
    
  }


  const exportField = [
    {
      field : "index" , 
      val : "Sr. No."
    },     
    {
      field : "item_type" , 
      val : "Item Type"
    }, 
    {
      field : "item_cat" , 
      val : "Item Category"
    }, 
    {
      field : "item_name" , 
      val : "Item Name"
    }, 
    {
      field : "entry_type" , 
      val : "Entry Type"
    },
    {
      field : "item_quan" , 
      val : "Quantity"
    },
    {
      field : "created_on" , 
      val : "Date"
    },
  ]
const getExportData = () => {
  const FilterData = StockData.filter(data => (!selected_site || data.site_id==selected_site));
  var exportData = []

  if(Array.isArray(FilterData) && FilterData.length){
    FilterData.map((element,i) => {
      exportData.push(
        {
          "index":i+1,
          "item_type":element.item_type,
          "item_cat":element.item_cat,
          "item_name":element.item_name,
          "entry_type":element.entry_type,
          "item_quan":element.item_type === "Material" ? element.item_quan + " "+ element.unit_name : element.item_quan,
          "created_on":moment(element.created_on).format("DD/MM/YYYY"),
        }
      )
    })
  }

  return exportData;
}

  const handleExportDataExcel = () => {

    getExportFileBlob(exportField,getExportData(), 'xlsx', 'Material Indent')
  };
  
  const handleExportDataPDF = () => {
    getExportFileBlob(exportField,getExportData(), 'pdf', 'Material Indent')
  };
  
  
  



  return (
    <>
      <Page className={classes.root} title="Inventory Dashboard">
      <ToastContainer    />
        <Container maxWidth={false}>
        <div className={clsx(classes.root2, className)} {...rest}>
          <Box display="flex" justifyContent="flex-start">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {handleExportDataExcel}>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              style={{marginLeft:"5px"}}
              variant="contained"
              className={classes.exportButton} 
              onClick = {handleExportDataPDF}>
                Export to PDF
            </Button>
          </Box> 
          <Box display="flex" justifyContent="flex-end">
            <Button 
              variant="contained" 
              onClick={addButton} 
              color="primary">
              Add Inventory
            </Button>
          </Box>
          
        </div>  
          <Box mt={3}>
            <Card style={{elevation:15}}>
              <MaterialTable
                icons={tableIcons}      
                title="Inventory Item Detail"
                columns={[          
                           
                  { title: 'SR. No.',
                    render: rowData => (
                      <Typography variant="text">
                      {rowData.tableData.id+1}
                      </Typography>
                    )
                   },     
                   { title: 'Site', field: 'site_name' },   
                  { title: 'Item Type', field: 'item_type',editable: "never", },     
                  { title: 'Item Category', field: 'item_cat',editable: "never", },     
                  { title: 'Item Name', field: 'item_name',editable: "never", },     
                  { title: 'Entry Type', field: 'entry_type',editable: "never", },     
                  { title: 'Quantity', 
                    render: rowData => (
                      <Typography variant="text">
                        {rowData.item_quan} {rowData.unit_name}
                      </Typography>
                    )
                   },     
                  { title: 'Date', render:rowData => (moment(rowData.created_on).format("DD.MM.YYYY")) },     

                ]}
                data={StockData}
                  options={{
                          selection: false,
                          textAlign: "center",
                          headerStyle: { textAlign: "left" },
                          rowStyle: { textAlign: "center" },
                          editCellStyle:{textAlign:"center"},                          
                          
                          
                        }}

              />
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
