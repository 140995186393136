import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';




import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// const useStyles = makeStyles(() => ({
//   root: {}
// }));


const TAX_RATE = 0.07;

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});


function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price}) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow('Payment-1', "Plan-1", 1500),
  createRow('Payment-1', 'Plan-2', 1600),
  createRow('Payment-1', 'Plan-2', 1700),
];

// const invoiceTaxes = TAX_RATE * invoiceSubtotal;
// const invoiceTotal = invoiceTaxes + invoiceSubtotal;


const Sales = ({ className, dashdata , ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const data = Object.keys(dashdata) && Object.keys(dashdata).length && dashdata.payment && Array.isArray(dashdata.payment) && dashdata.payment.length ? dashdata.payment :[]

  const invoiceSubtotal = subtotal(data);
  return (


    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table">
      
      
        <TableHead>          
          <TableRow>
            <TableCell>Payment</TableCell>
            <TableCell align="right">Plan</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>          
          {data.map((row) => (
            <TableRow key={row.order_type}>
              <TableCell>{row.order_type}</TableCell>
              <TableCell align="right">{row.plan_name}</TableCell>
              <TableCell align="right">{(row.price).toFixed(2)}</TableCell>
            </TableRow>
          ))}          
          <TableRow>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
   
      
  )
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;
