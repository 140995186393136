import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../../src/layouts/DashboardLayout';
// import DashboardView from '../../../src/views/reports/DashboardView/index';
import DashboardView from '../../../src/Screens/SuperAdmin/Dashboard/index';
import ProductListView from '../../../src/views/product/ProductListView';
import SettingsView from '../../../src/views/settings/SettingsView';


import Profile from '../../Screens/SuperAdmin/Profile/Add'
import ReportGen from '../../Screens/SuperAdmin/ReportGeneration/Add'
import GSTList from '../../Screens/SuperAdmin/GSTModule/List'
import GSTAdd from '../../Screens/SuperAdmin/GSTModule/Add'
import GSTEdit from '../../Screens/SuperAdmin/GSTModule/Edit'
import Tickets from '../../Screens/SuperAdmin/TicketsManagement/List'
import TicketsEdit from '../../Screens/SuperAdmin/TicketsManagement/Edit'
import Enquiry from '../../Screens/SuperAdmin/InquiryManagement/List'
import Website from '../../Screens/SuperAdmin/WebsiteManagement/Add'
import Api from '../../Screens/SuperAdmin/ApiManagement/Add'
import Orders from '../../Screens/SuperAdmin/OrderManagement/List'
import OrdersAdd from '../../Screens/SuperAdmin/OrderManagement/Add'
import OrdersEdit from '../../Screens/SuperAdmin/OrderManagement/Edit'
import OrdersDetail from '../../Screens/SuperAdmin/OrderManagement/Detail'
import RolesList from '../../Screens/SuperAdmin/RoleMaster/List'
import RolesAdd from '../../Screens/SuperAdmin/RoleMaster/Add'
import RolesEdit from '../../Screens/SuperAdmin/RoleMaster/Edit'
import AdminPanelUserAdd from '../../Screens/SuperAdmin/AdminPanelUsers/Add'
import AdminPanelUserList from '../../Screens/SuperAdmin/AdminPanelUsers/List'
import AdminPanelUserEdit from '../../Screens/SuperAdmin/AdminPanelUsers/Edit'
import PlanAdd from '../../Screens/SuperAdmin/PlanManagement/Add'
import PlanList from '../../Screens/SuperAdmin/PlanManagement/List'
import PlanEdit from '../../Screens/SuperAdmin/PlanManagement/Edit'

import SuperFaq from '../../Screens/SuperAdmin/FAQ/List'






// Admin


const routes1 = [

  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
     
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'profile', element: <Profile/> },
      { path: 'reportgen', element: <ReportGen/> },
      { path: 'gst', element: <GSTList/> },
      { path: 'gst_add', element: <GSTAdd/> },
      { path: 'gst_edit/:id', element: <GSTEdit/> },
      { path: 'tickets', element: <Tickets/> },
      { path: 'tickets/:id', element: <TicketsEdit/> },
      { path: 'enquiries', element: <Enquiry/> },
      { path: 'website', element: <Website/> },
      { path: 'api', element: <Api/> },
      { path: 'orders', element: <Orders/> },
      { path: 'orders_add', element: <OrdersAdd/> },
      { path: 'orders/edit/:id', element: <OrdersEdit/> },
      { path: 'orders/detail/:id', element: <OrdersDetail/> },
      { path: 'roles_add', element: <RolesAdd/> },
      { path: 'roles', element: <RolesList/> },
      { path: 'roles_edit/:id', element: <RolesEdit/> },
      { path: 'admin_panel_users', element: <AdminPanelUserList/> },
      { path: 'admin_panel_users_add', element: <AdminPanelUserAdd/> },
      { path: 'admin_panel_users_edit/:id', element: <AdminPanelUserEdit/> },
      { path: 'plans', element: <PlanList/> },
      { path: 'plans_add', element: <PlanAdd/> },
      { path: 'plans_edit/:id', element: <PlanEdit/> },
      { path: 'faqs', element: <SuperFaq/> },
      
      
      { path: '*', element: <Navigate to="/app/dashboard" /> }

    ]
  },

  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'login',  element: <DashboardView /> },
      { path: 'register', element: <DashboardView /> },
      { path: '404', element: <DashboardView/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }
    ]
  }
  
];

export default routes1;
