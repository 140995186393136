import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import tableIcons from '../../../../Config/IconsFile'

import {getMaterialCategory} from '../../../../Actions/SubAdminActions/M_MaterialCategory';
import {getMaterialMat} from '../../../../Actions/SubAdminActions/M_MaterialMat';
import {getResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit'
import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites';
import {getGst} from '../../../../Actions/SubAdminActions/Gst'
import {acceptDelivery} from '../../../../Actions/Inventory/Delivery'
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles(); 
  const {state} = useLocation();
  
  const admin_name = useSelector(state => state.login.data.name);


  const [columns, setColumns] = useState([
   { title: 'Material Name', field: 'mat_name'},
   { title: 'Material Type', field: 'mat_type'},
   { title: 'Indent Quantity', render:rowData=>( 
    rowData.ind_quan !=="" && rowData.ind_quan !==null && rowData.ind_quan !==undefined ?
    rowData.ind_quan+" "+rowData.unit
    :"Not Available"
    )},
   { title: 'Accepted Quantity', render:rowData=>(
    rowData.acc_quan !==null && rowData.acc_quan !==undefined && rowData.acc_quan !=="" ?
    rowData.acc_quan+" "+rowData.unit
    :"Not Available"  
     )},
   { title: 'Purchased Quantity', render:rowData=>(rowData.quan+" "+rowData.unit)},
   { title: 'Delivered Quantity', render:rowData=>(rowData.new_quan+" "+rowData.unit)},
 ]);


  return (
    <Page className={classes.root} title="Material Delivery Detail" >
      <ToastContainer    />  
      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader={"Order Id : "+state.order_id} title="Material Delivery Detail" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>                  
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Site Name :  {state.site_name}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Required Date :  {moment(state.required_on).format("DD.MM.YYYY")}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Delivery Date :  {moment(state.delivery_date).format("DD.MM.YYYY")}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Priority :  {state.priority}</Typography>
                  </Grid>
                  
                  
                  <Grid item md={4} xs={12}>
                    
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
                  
                    <Typography variant="body2" color="text.secondary" spacing={3}>Location :  
                      {state.lat}, {state.long}                 
                    </Typography>
                  
                   : 
                   <Typography variant="body2" color="text.secondary" spacing={3}>Location: </Typography>
                   }
                    
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Vendor Name :  {state.name}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Vendor Mobile :  {state.mobile}</Typography>
                  </Grid>                  
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Vendor Email :  {state.email}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="body2" color="text.secondary" spacing={3}>Vendor Address :  {state.address}</Typography>
                  </Grid>
                  
                </Grid>

                </CardContent>
                <Divider />
                
                            
              </Card>
            </form>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Card className={clsx(classes.root2, className)} {...rest}>
          <MaterialTable
            icons={tableIcons}      
            title="Material Details"
            columns={columns}

            data={state.mat_details}
            options={{
                    selection: false,
                    emptyRowsWhenPaging:false,
                }}
            
          />
          
          </Card>
          </Box>
      </Container>
    </Page>
  );
};

export default AddBusiness;
