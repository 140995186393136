import {ADD_ROLES_LOADING , ADD_ROLES_SUCCESS , ADD_ROLES_FAILURE } from '../../Actions/Types';
import {GET_ROLES_LOADING , GET_ROLES_SUCCESS , GET_ROLES_FAILURE } from '../../Actions/Types';
import {EDIT_ROLES_LOADING , EDIT_ROLES_SUCCESS , EDIT_ROLES_FAILURE } from '../../Actions/Types';
import {DEL_ROLES_LOADING , DEL_ROLES_SUCCESS , DEL_ROLES_FAILURE } from '../../Actions/Types';

import {CLEAN_ALL_DATA , CLEAN_ROLES_DATA} from '../../Actions/Types';





const AddRoles = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{},
            editData:[{}],
            editError:{},
            delData : [{}],
            delError: {}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{}
            
        }
        
        case CLEAN_ROLES_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{},
                editData:[{}],
                editError:{},
                delData : [{}],
                delError:{}
                
            }
    
    case ADD_ROLES_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            data : [{}]
        }
    case ADD_ROLES_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case ADD_ROLES_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }



    case GET_ROLES_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case GET_ROLES_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            getData:action.data
        }
    case GET_ROLES_FAILURE:
        return {
            ...state,
            loading:false,
            getError:action.error,
            data:{},
        }   

    case EDIT_ROLES_LOADING:
        return {
            ...state,
            loading:true,
            editError: {},
            editData : [{}]
            
        }
    case EDIT_ROLES_SUCCESS:
        return {
            ...state,
            loading:false,
            editError:{},
            editData:action.data,
        }
    case EDIT_ROLES_FAILURE:
        return {
            ...state,
            loading:false,
            editError:action.error,
            editData:[{}],
        }

    case DEL_ROLES_LOADING:
        return {
            ...state,
            loading:true,
            delError: {},
            delData : [{}]
            
        }
    case DEL_ROLES_SUCCESS:
        return {
            ...state,
            loading:false,
            delError:{},
            delData:action.data,
        }
    case DEL_ROLES_FAILURE:
        return {
            ...state,
            loading:false,
            delError:action.error,
            delData:[{}],
        }
        
        

    default:
        return state

}
}



export default AddRoles;
