import React  , {Component} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import Logo from '../../../src/components/Logo';

const useStyles = makeStyles(({
  root: {
   
  },
  toolbar: {
    height: 64,
    
  }
}));


class TopBar extends Component {


  constructor(props ) {

  
    super(props);
    this.state = {


      myL : '/'
      

        };
  }
 
render()
{
  // const classes = useStyles();
  // const {className , ...rest} = this.props;

  return (

    <AppBar
      className={clsx(useStyles.root, this.props.className)}
      elevation={0}
      {...this.props.rest}
    >
      <Toolbar className={useStyles.toolbar}>
        <RouterLink to={this.state.myL}>
          {/* <Logo /> */}
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};
}


TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
