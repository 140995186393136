
import {GRN_ORDER_REQ_LOADING , GRN_ORDER_REQ_SUCCESS , GRN_ORDER_REQ_FAILURE} from '../../Actions/Inventory/Types';
import {GRN_ISSUE_LOADING , GRN_ISSUE_SUCCESS , GRN_ISSUE_FAILURE} from '../../Actions/Inventory/Types';
import {GRN_ORDER_ACTION_LOADING , GRN_ORDER_ACTION_SUCCESS , GRN_ORDER_ACTION_FAILURE, CLEAN_ORDER_ACTION_DATA} from '../../Actions/Inventory/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const GRN = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            issueData: [{}],
            issueError:{},
            getData:{},
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{},
                issueData: [{}],
                issueError:{},
            }
    
    
        case CLEAN_ORDER_ACTION_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                issueData: [{}],
                issueError:{},
                
            }
    
    

        case GRN_ISSUE_LOADING:
            return {
                ...state,
                loading:true,
                issueError: {},
                issueData : {},
                
            }
        case GRN_ISSUE_SUCCESS:
            return {
                ...state,
                loading:false,
                issueError:{},
                issueData:action.data,
            }
        case GRN_ISSUE_FAILURE:
            return {
                ...state,
                loading:false,
                issueError:action.error,
                issueData:{},
            }   
        
    
    
    

        case GRN_ORDER_REQ_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : {},
                
            }
        case GRN_ORDER_REQ_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
            }
        case GRN_ORDER_REQ_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        
    
    

        case GRN_ORDER_ACTION_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : {},
                
            }
        case GRN_ORDER_ACTION_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
            }
        case GRN_ORDER_ACTION_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default GRN;
    