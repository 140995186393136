import {ADD_SITES_LOADING , ADD_SITES_SUCCESS , ADD_SITES_FAILURE , CLEAN_SITES_DATA} from '../../Actions/Types';
import {GET_SITES_LOADING , GET_SITES_SUCCESS , GET_SITES_FAILURE} from '../../Actions/Types';
import {GET_SITES_MASTER_LOADING , GET_SITES_MASTER_SUCCESS , GET_SITES_MASTER_FAILURE} from '../../Actions/Types';
import {DEL_SITES_LOADING , DEL_SITES_SUCCESS , DEL_SITES_FAILURE} from '../../Actions/Types';
import {EDIT_SITES_LOADING , EDIT_SITES_SUCCESS , EDIT_SITES_FAILURE} from '../../Actions/Types'; 

import { CLEAN_ALL_DATA } from '../../Actions/Types';


const AddSites = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            masterData:[{}],
            masterError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {      
        case CLEAN_SITES_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                // getData : [{}],
                editData : [{}],
                editError : {},
                masterError : {},
                delData : [{}], 
                delError:{}
            }
    
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData : [{}],
                editData : [{}],
                editError : {},
                masterError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_SITES_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                delData : [{}]
                
            }
        case ADD_SITES_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
                delData : [{}]
            }
        case ADD_SITES_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
            }

        case GET_SITES_MASTER_LOADING:
            return {
                ...state,
                loading:true,
                masterData:[{}],
                masterError:{}
            }
        case GET_SITES_MASTER_SUCCESS:
            return {
                ...state,
                loading:false,
                masterError:{},
                masterData:action.data,
            }
        case GET_SITES_MASTER_FAILURE:
            return {
                ...state,
                loading:false,
                masterError:action.error,
                masterData:{},
            }   
        

        case GET_SITES_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                Data : [{}],
                
            }
        case GET_SITES_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data,
                delData:[{}]
            }
        case GET_SITES_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                delData : [{}]
            }   
        
        case DEL_SITES_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_SITES_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_SITES_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
                    
        case EDIT_SITES_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}]
                
            }
        case EDIT_SITES_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_SITES_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            

        default:
        return state
    
    }
    }
    
    
    
export default AddSites;
    