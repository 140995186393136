// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {getSiteProgressBegin , getSiteProgressSuccess , getSiteProgressFailure} from '../ActionCreators';
import {delSiteProgressBegin , delSiteProgressSuccess ,  delSiteProgressFailure} from '../ActionCreators';




export const getSiteProgress = (x) => {
    var parameter = x;
    if(parameter.start_date==="" || parameter.start_date===null || parameter.start_date===undefined){
        parameter.start_date=new Date('2001', '01', '01')
    }
    return (dispatch) => {
        dispatch(getSiteProgressBegin());

        axios.get(Base_URL + '/subadmin/getWorkProgress' , {params : parameter})
            .then((res) => {
                dispatch(getSiteProgressSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSiteProgressFailure(err.response.data));

                }
            })
    }
}

export const delSiteProgress = (x) => {

    return (dispatch) => {
        dispatch(delSiteProgressBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delWorkProgress' , {params :{wp_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delSiteProgressSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delSiteProgressFailure(err.response.data));

                }
            })
    }
}
