import React, { useState , useEffect } from 'react';
import { Link as RouterLink  , useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {  useDispatch , shallowEqual } from 'react-redux'
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';



import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../../../src/components/Logo';
import {logout} from '../../Actions/Logout'
import {cleanAllData} from '../../Actions/ActionCreators'
import SettingsIcon from '@material-ui/icons/Settings';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';


import MyDrop from './CustomDrop'
import { isArray } from 'lodash';

const useStyles = makeStyles((theme) => ({

  root: {
    backgroundColor:"#E36A26",

  },
  typography2: {
    padding: theme.spacing(2),
  },
  typography3: {
    padding: theme.spacing(1),
  },
  ".MuiToolbar-root" : {
    // window.location.href.includes("master")
    // backgroundColor:`${window.location.href.includes('login') ?  "#E36A26" :  ""  }` 
    backgroundColor:"#E36A26" 
},
select: {
  '&:before': {
      borderColor: "white",
  },
  '&:after': {
      borderColor: "white",
  }
},
icon: {
  fill: "white",
},

'.mylogin' : {
  backgroundColor:"white"
},

  avatar: 
  {
    width: 60,
    height: 60
  }
}));

const TopBar = ({className,onMobileNavOpen,...rest }) => 
{
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch()


  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const open = Boolean(anchorEl);
  // console.log(open)
  // const id = open ? 'simple-popover' : undefined;


const token = useSelector(state => state.login.data.mobile)
const role = useSelector(state => state.login.data.role)
const super_permit_role = useSelector(state => state.login.data.role_permit )
const uid = useSelector(state => state.login.data.id)
const uemail = useSelector(state => state.login.data.email)
const role_permit = useSelector(state => state.login.data.role_permit)


const SData = useSelector((state) => 
state.AddSites && state.AddSites.getData && 
Array.isArray(state.AddSites.getData) &&
state.AddSites.getData.length ? 
state.AddSites.getData :[]
)
    
// console.log(super_permit_role)


  if(!token || token === null || token === undefined || token === '')
  {
    navigate('/login')
  }

  
  const [values, setValues] = useState({country:""});



const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleChanged = (event) => {
    setValues({
      ...values, ['country']:event.target.value
    });
  };


  const  handleSubmit = () =>{
    let x = new Promise((resolve , reject)=>{
      setAnchorEl(null);
     dispatch(cleanAllData())
    console.log("test")
    
    resolve()  
    })
    x.then(()=>{
    
      dispatch(logout(uemail)) 
    })
  }


  const handleSettings = () =>{
    if(window.location.href.includes('master'))
    {
      navigate('/app/dashboard')

    }
    else
    {
      navigate('/app/master/users_list')
    }
  }



  const changeToProfile = () =>{

  }


 const changeToLogout = () =>{



  let x = new Promise((resolve , reject)=>{

   dispatch(cleanAllData())
  console.log("test")
  
  resolve()  
  })
  x.then(()=>{
  
    dispatch(logout(uemail)) 
  })
    
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={15}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>


{/* <Grid  item md={6} lg={3}  xs={3}>

</Grid> */}
{role === "Subadmin"?

          <Grid  item md={6} lg={3}  xs={3} style={{marginLeft:"3%"}}>

            {SData && Array.isArray(SData) && SData.length ? 
            
            <MyDrop/>
            :<></>
            }


          </Grid>
:<>

</>}
        <Box flexGrow={1} />
        

        <Hidden  onBackdropClick={handleClose} mdDown>
          {/* <IconButton aria-describedby={id}  variant="contained" color="primary" onClick={handleClick} color="inherit">
              <AccountCircleIcon aria-describedby={id}  onClick={handleClick}  style={{fontSize:30}} />
            <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button className={classes.typography3}><ClearIcon onClick={handleClose} style={{fontSize:20 ,textAlign:"right"  ,color:"red"  , cursor:"pointer" }} /></Button>
        <Button className={classes.typography2} onClick={()=>navigate('/app/profile_management')} style={{cursor:"pointer" }}>Change Password</Button>
      </Popover>
    </div>
          </IconButton> */}
           <PopupState variant="popover" popupId="login-popup-popover">
      {(popupState) => (
        <div>
          <IconButton variant="contained" color="white" {...bindTrigger(popupState)}>
          <AccountCircleIcon style={{fontSize:30 , color:"white"}} />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box p={2}>
              {role === "Subadmin" ?
              <Typography  className={classes.typography3} onClick={()=>navigate('/app/profile_management')} style={{cursor:"pointer" }}>Change Password</Typography>
              
                : role === 'SuperAdmin' ?
              <Typography  className={classes.typography3} onClick={()=>navigate('/app/profie')} style={{cursor:"pointer" }}>Change Password</Typography>
              :
              <Typography  className={classes.typography3} onClick={()=>navigate('/app/profile')} style={{cursor:"pointer" }}>Change Password</Typography>
          }
              <Typography  className={classes.typography3} onClick={changeToLogout} style={{cursor:"pointer" }}>Logout</Typography>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
          <IconButton onClick={handleSubmit}  color="inherit">
            <InputIcon />
          </IconButton>
          
        {(role === "Subadmin") && (role_permit.master && role_permit.master.length && Array.isArray(role_permit.master)) && (role_permit.master[0]===true||role_permit.master[1]===true||role_permit.master[2]===true||role_permit.master[3]===true||role_permit.master[4]===true)?
          <IconButton onClick={handleSettings}  color="inherit">
            {! window.location.href.includes('/master') ?
              <SettingsIcon/>
              :
              <ReplyAllIcon/>
            }
          </IconButton>
          : null}
        </Hidden>
        
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
