import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';


import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {ClientAddTicket} from '../../../Actions/Client/Tickets'
import {cleanTicketData} from '../../../Actions/ActionCreators'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()




  const rb = useSelector((state)=> state.login && state.login.data && state.login.data.name ? state.login.data.name : ""    ); 
  const a_id = useSelector((state)=> state.login && state.login.data && state.login.data.admin_id ? state.login.data.admin_id : "");
  const app_uid = useSelector((state) => state.login && state.login.data && state.login.data.apu_id ? state.login.data.apu_id : "");
  const site_id = useSelector((state) => state.login && state.login.data && state.login.data.site_id ? state.login.data.site_id : "");

  const [values, setValues] = useState({
   
    issue : "",
    priority : "",
    message:"",
    raised_by : rb,
    sub_admin_id:a_id,
    app_uid:app_uid,
    site_id:site_id
  
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  const handlePriority  = (e) =>{

    setValues({...values  , ['priority'] : e.target.value})

  }

 
  const handleSubmit = () =>{
    if(values.issue==="" || values.issue===null || values.issue===undefined){
      tostE("Please Enter Issue !!")  
    }else if(values.message==="" || values.message===null || values.message===undefined){
      tostE("Please Enter Message !!")  
    }else if(values.priority==="" || values.priority===null || values.priority===undefined){
      tostE("Please Enter Priority")  
    }else{

      dispatch(ClientAddTicket(values));
      setValues({...values, ['issue']:"", ['message']:"", ["priority"]:""})
    }

      
  }

  const addmsg = useSelector(state => state.ClientAllGet.ticketData && state.ClientAllGet.ticketData.status && state.ClientAllGet.ticketData.status===200 ? state.ClientAllGet.ticketData.status : null)
  const addticketError = useSelector(state => state.ClientAllGet.ticketError && state.ClientAllGet.ticketError.message ? state.ClientAllGet.ticketError.message : null  )

  
  useEffect( () => {
    
    let x  = new Promise((resolve ,reject)=>{
      if(addmsg === 200)
      {
        tostS("Ticket Raised Successfully")
      }resolve()

    })
    x.then(()=>{

    dispatch(cleanTicketData())
   
    })
     
    }, [addmsg])


  return (
    <Page
      className={classes.root}
      title="Raise Ticket"
    >
              <ToastContainer    />

              <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          
          <Grid
            item
            lg={8}
            md={8}
            xs={12}
            style={{ margin:'auto' }}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Raise Ticket" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Issue"
                name="issue"
                onChange={handleChange}
                required
                value={values.issue}
                variant="outlined"
              />
            </Grid>
          
            
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Message"
                name="message"
                onChange={handleChange}
                required
                value={values.message}
                variant="outlined"
              />
            </Grid>

            
            <Grid item md={12} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Priority</InputLabel>
        <Select
        fullWidth
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.priority}
          onChange={handlePriority}
          label="Priority"
        >
         
          <MenuItem   value="High">High</MenuItem>
          <MenuItem   value="Medium">Medium</MenuItem>
          <MenuItem   value="Low">Low</MenuItem>
        </Select>
      </FormControl>
      </Grid>
           
      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Raise Ticket
          </Button>
        </Box>
      </Card>
    </form>
            
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
