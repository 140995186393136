// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addSiteExpBegin , addSiteExpSuccess , addSiteExpFailure} from '../ActionCreators'
import {getSiteExpBegin , getSiteExpSuccess , getSiteExpFailure} from '../ActionCreators'
import {delSiteExpBegin , delSiteExpSuccess , delSiteExpFailure} from '../ActionCreators'
import {editSiteExpBegin , editSiteExpSuccess , editSiteExpFailure} from '../ActionCreators';

export const addSiteExp = (session) => {

    return (dispatch) => {
        dispatch(addSiteExpBegin());

        axios.post(Base_URL + '/subAdmin/addExpMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addSiteExpSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addSiteExpFailure(err.response.data));

                }
            })
    }
}


export const getSiteExp = (x) => {

    return (dispatch) => {
        dispatch(getSiteExpBegin());

        axios.get(Base_URL + '/subAdmin/getExpMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getSiteExpSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSiteExpFailure(err.response.data));

                }
            })
    }
}

export const delSiteExp = (x) => {

    return (dispatch) => {
        dispatch(delSiteExpBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delExpMaster' , {params :{sub_exp_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delSiteExpSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delSiteExpFailure(err.response.data));

                }
            })
    }
}

export const editSiteExp = (session) => {

    return (dispatch) => {
        dispatch(editSiteExpBegin());

        axios.post(Base_URL + '/subAdmin/editExpMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editSiteExpSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editSiteExpFailure(err.response.data));

                }
            })
    }
}

