// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor3";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {addPanelUserBegin , addPanelUserSuccess , addPanelUserFailure } from './../ActionCreators'
import {getPanelUserBegin , getPanelUserSuccess , getPanelUserFailure} from './../ActionCreators'
import {delPanelUserBegin , delPanelUserSuccess , delPanelUserFailure} from './../ActionCreators'





export const addPanelUser = (session) => {

    return (dispatch) => {
        dispatch(addPanelUserBegin());

        axios.post(Base_URL + '/superadmin/regsuperadmin', session)
            .then((res) => {
                dispatch(addPanelUserSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addPanelUserFailure(err.response.data));

                }
            })
    }
}



export const getPanelUser = (x) => {

    return (dispatch) => {
        dispatch(getPanelUserBegin());

        axios.get(Base_URL + '/superadmin/getPanelUser', {params:x})
            .then((res) => {
                dispatch(getPanelUserSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getPanelUserFailure(err.response.data));

                }
            })
    }
}

export const delPanelUser = (x) => {

    console.log(x);
    return (dispatch) => {
        dispatch(delPanelUserBegin());

        axios.get(Base_URL + '/superAdmin/delpanelusers' , {params : {id : x}})
            .then((res) => {
                dispatch(delPanelUserSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delPanelUserFailure(err.response.data));

                }
            })
    }
}










