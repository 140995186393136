import axios from "../../Config/Interceptor2";
import { Base_URL } from "../../Config/BaseUrlConfig";
import { GetBoqFailure, GetBoqLoading, GetBoqSucess, addBOQFailure, addBOQSuccess } from "../ActionCreators";
import { ToastContainer } from "react-toastify";
import { tostS, tostE } from "../../Config/Toast";

export const addSiteBoq = (
  site_id,
  boqData,
  sub_admin_id,
  navigate,
  percentageBoqType,
  percentageBoqValue
) => {
  return (dispatch) => {
    const formData = {
      site_id,
      boqData,
      sub_admin_id,
      percentageBoqType,
      percentageBoqValue,
    };
    console.log("here");

    axios
      .post(Base_URL + "/subadmin/addboqexcel", formData)
      .then((res) => {
        tostS("BOQ Added Sucessfully");
        navigate("/app/boq");

        dispatch(addBOQSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        if (!err.response) {
          alert(
            "Something went wrong: Please check network or server connectivity"
          );
        } else {
          const errorMessage = err.response.data.message || "An error occurred";
          console.log(errorMessage);
          tostE(errorMessage);
          dispatch(addBOQFailure(err.response.data));
        }
      });
  };
};

export const FetchBoq = (a_id, selected_site) => {
  return (dispatch) => {
    dispatch(GetBoqLoading());

    axios
      .get(`${Base_URL}/subadmin/getBoq`, {
        params: { sub_admin_id: a_id, site: selected_site },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          if (response.data.daily_report && response.data.daily_report.length > 0) {
            dispatch(GetBoqSucess(response.data));
          } else {
            // Dispatch success with an empty response array if no reports are found
            dispatch(GetBoqSucess({ ...response.data, daily_report: [] }));
          }
        } else {
          dispatch(GetBoqFailure(response.data));
        }
      })
      .catch((err) => {
        dispatch(GetBoqFailure(err.response ? err.response.data : "Network Error"));
        console.log(err);
      });
  };
};