import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { ThemeProvider } from "@material-ui/styles";

import {getStockTransfer} from '../../../../Actions/Inventory/StockTransfer'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const navigate = useNavigate();
  const a_id = useSelector(state => state.login && state.login.data && state.login.data.r_subadmin?state.login.data.r_subadmin:"");

  const StockTransferData = useSelector(state => state.StockTransfer.getData && 
    Array.isArray(state.StockTransfer.getData) && state.StockTransfer.getData.length ?
    state.StockTransfer.getData : [])
console.log(StockTransferData);

  useEffect(() => {
    dispatch(getStockTransfer(a_id))
  }, [])

  const addButton = () =>{
    navigate('/app/inventory/transfer/add')
    
  }

  return (
    <>
      <Page className={classes.root} title="Site Transfer">
      <ToastContainer    />
        <Container>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={addButton} color="primary">Add</Button>
          </Box>
          
          <Box mt={3}>
            <Card style={{elevation:15}}>
              <MaterialTable
                icons={tableIcons}      
                title="Site Transfer"
                columns={[                  
                  { title: 'From Site', field: 'from_site',editable: "never", },     
                  { title: 'To Site', field: 'to_site',editable: "never", },     
                  { title: 'Item Type', field: 'item_type',editable: "never", },     
                  { title: 'Item Category', field: 'item_cat',editable: "never", },     
                  { title: 'Item Name', field: 'item_name',editable: "never", },     
                  { title: 'Quantity', 
                    render: rowData => (
                      <Typography variant="text">
                        {rowData.quant} {rowData.unit_name}
                      </Typography>
                    )
                   },
                  { title: 'Date', render:rowData => (moment(rowData.created_on).format("DD.MM.YYYY")) },   
                ]}
                data={StockTransferData}
                  options={{
                          selection: false,
                          textAlign: "center",
                          headerStyle: { textAlign: "left" },
                          rowStyle: { textAlign: "center" },
                          search:false
                        }}

              />
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
