import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PasswordIcon from '@material-ui/icons/VpnKey';

import tableIcons from '../../../Config/IconsFile'


import {getPanelUser , delPanelUser} from '../../../Actions/SuperAdminActions/PanelUser'
import {editSuperProfilePassword} from '../../../Actions/SuperAdminActions/Profile';
import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput,
  Tooltip
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {cleanPanelUserData, cleanSuperProfileDetail } from "../../../Actions/ActionCreators";
import {getExportFileBlob} from '../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
   
  const tableRef = React.createRef();
  
  const [pass, setPass] = useState({
    id:"",
    password:"",
    confirm_pass:"",
    showPassword:false,
    showConfirmPass:false,
    show:false
  });
  const handleClosePass = () =>{

    setPass({
      ...pass, 
      show:false, 
      id:"", 
      password:"",
      confirm_pass:"",
      showPassword:false,
      showConfirmPass:false, 
    });
  }
  
  const handleOpenPass = (data) =>{
  
    setPass({
      ...pass,
      show:true,      
      showPass:false,
      id:data.id,
    });
  
  }


  const handleConfirmPass = () =>{    
    if(pass.id!=="" && pass.show!==false){
      if(pass.password.length<6){
        tostE("Password Length must be at least 6 Characters!!")
      }else if(pass.password===pass.confirm_pass){

        var SendData = {
          id:pass.id,
          password:pass.password
        }
        console.log(SendData)
        dispatch(editSuperProfilePassword(SendData))
        setPass({
          ...pass, 
          show:false, 
          id:"", 
          password:"",
          confirm_pass:"",
          showPassword:false,
          showConfirmPass:false, 
        })
      }else{
        tostE("Please Enter Same Password")
      }
    }
    
  }

  const role_permit = useSelector(state => state.login.data && state.login.data.role_permit);

const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleDateChange = (date) => {
    setSelectedDate(date);
  };



  
  const listData = useSelector((state) =>
  state.AddPanelUser && state.AddPanelUser.getData.data &&
    Object.keys(state.AddPanelUser.getData.data).length &&
    Array.isArray(state.AddPanelUser.getData.data)
      ? state.AddPanelUser.getData.data
      : []
  );


  const countD= useSelector((state) =>
  state.AddPanelUser && state.AddPanelUser.getData.data &&
   Array.isArray(state.AddPanelUser.getData.data)
      ? parseInt(state.AddPanelUser.getData.total_length)
      : 0
  );



  const countF= useSelector((state) =>
  state.AddPanelUser && state.AddPanelUser.getData.data &&
   Array.isArray(state.AddPanelUser.getData.data)
      ? parseInt(state.AddPanelUser.getData.page)
      : 0
  );

  const [pagination , setPagination] = useState({
    per_page : 5,
    page : countF,

  })
  const [open, setOpen] = useState({show:false, id:""});

  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.id
    });
  
  }

  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delPanelUser(open.id))
  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }



  
  const addmsg = useSelector(state => state.SuperProfile.editData && state.SuperProfile.editData.message ? state.SuperProfile.editData.message : null  )
  const adderror = useSelector(state => state.SuperProfile.editError && state.SuperProfile.editError.message ? state.SuperProfile.editError.message : null  )


 
  const Delmsg = useSelector(state => state.AddPanelUser && state.AddPanelUser.delData && state.AddPanelUser.delData.message ? state.AddPanelUser.delData.message : null  )
  const Delerror = useSelector(state => state.AddPanelUser && state.AddPanelUser.delError && state.AddPanelUser.delError.message ? state.AddPanelUser.delError.message : null  )

  const EditPassmsg = useSelector(state => state.SuperProfile && state.SuperProfile.editData && state.SuperProfile.editData.message ? state.SuperProfile.editData.message : null  )
  const EditPasserror = useSelector(state => state.SuperProfile && state.SuperProfile.editError && state.SuperProfile.editError.message ? state.SuperProfile.editError.message : null  )





  

  
useEffect( () => {
  var t  =  {page : pagination.page , per_page : pagination.per_page}

    dispatch(getPanelUser(t))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }
      if(EditPassmsg && typeof(EditPassmsg) === 'string')
      {
        tostS(EditPassmsg)
      }
      if(EditPasserror && typeof(EditPasserror) === 'string')
      {
          tostE(EditPasserror)
      }
      if(addmsg !== null && typeof(addmsg) === 'string')
      {
        tostS(addmsg);
      }
      if(adderror !== null && typeof(adderror) === 'string')
      {
        tostE(adderror);
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanPanelUserData())
    dispatch(cleanSuperProfileDetail())
    dispatch(getPanelUser(t))
    

    })


    
  }, [ Delmsg  ,Delerror, EditPassmsg, EditPasserror, addmsg , adderror ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/admin_panel_users_edit/'+olData.id , { state: olData })
}


 

const handleChange = (event) => {
  setPass({
    ...pass,
    [event.target.name]: event.target.value,
  });
};


const handleClickShowPassword = () => {
  setPass({
    ...pass,
    showPassword: !pass.showPassword,
  });
};
const handleClickShowConfirmPass = () => {
  setPass({
    ...pass,
    showConfirmPass: !pass.showConfirmPass,
  });
};

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};






const exportField = [
  {
    field : "name" , 
    val : "Name"
  }, 
  {
    field : "mobile" , 
    val : "Mobile"
  }, 
  {
    field : "role_name" , 
    val : "Role"
  }, 
  {
    field : "email" , 
    val : "Email"
  }, 
  
]


const handlePageChange = (e , x) =>{
  const parameter = {page : e , per_page :pagination.per_page }
  var u   = new Promise((resolve ,reject)=>{
    
    if(x !== null  && x !== undefined )
    {
      dispatch(getPanelUser(parameter))
      
    }
    
    resolve()
    
  })
  u.then(()=>{
    
    setPagination({...pagination , ['page']  : e})
  })

}

const handleRPP = (e) =>{
  console.log(e)
  // setPagination({...pagination , ['per_page']  : e})
  const parameter = {page : e , per_page :pagination.per_page}

  var u   = new Promise((resolve ,reject)=>{
    
   
      dispatch(getPanelUser(parameter))
      
    
    
    resolve()
    
  })
  u.then(()=>{
    
    // setPagination({...pagination , ['page']  : e})
    setPagination({...pagination , ['per_page']  : e})
  })

}


  


  const addNewBornbutton = () =>{
    navigate('/app/admin_panel_users_add')
    console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Super Admin Panel Users">
      <ToastContainer    />


      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this User??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick={true} open={pass.show} onClose={handleClosePass} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="password"
                type={pass.showPassword ? 'text' : 'password'}
                value={pass.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {pass.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
            </Grid>
            <br/>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="confirm_pass"
                type={pass.showConfirmPass ? 'text' : 'password'}
                value={pass.confirm_pass}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPass}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {pass.showConfirmPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            </Grid>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePass} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirmPass} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
          
            <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'AdminPanelUsers')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'AdminPanelUsers')
              }>
                Export to PDF
            </Button>
            {(role_permit.admin_panel_user  && Array.isArray(role_permit.admin_panel_user) && role_permit.admin_panel_user.length) && role_permit.admin_panel_user[3]===true? 
              (
                <>

                <Button onClick={addNewBornbutton} color="primary" variant="contained">
                  Add User
                </Button>
                </>
              )
              : (<></>)} 
            </Box>           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}    
      tableRef={tableRef}  
      title="Admin Panel Users"
      columns={[
        { title: 'SR. No.', render : rowData =>(
          <>
          {rowData.tableData.id +1}
          </>
        ) },
        { title: 'Name', field: 'name',editable: "never", },          
        { title: 'Mobile', field: 'mobile',editable: "never", },          
        { title: 'Role Assigned', field: 'role_name',editable: "never", },          
        { title: 'Email', field: 'email',editable: "never", },          

        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
            <Grid item xs={3}>
                <Tooltip title="Change Password" arrow>
                  <PasswordIcon  onClick={()=>{handleOpenPass(rowData)}} style={{cursor:"pointer"}} />                    
                </Tooltip>
              </Grid>
              
                <Grid item xs={3}>
                {(role_permit.admin_panel_user && role_permit.admin_panel_user.length && Array.isArray(role_permit.admin_panel_user)) && role_permit.admin_panel_user[4]===true? 
                  <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
                :<></>}
                </Grid>
                <Grid item xs={3}>
                {role_permit && (role_permit.admin_panel_user && role_permit.admin_panel_user.length && Array.isArray(role_permit.admin_panel_user)) && role_permit.admin_panel_user[2]===true? 
                  <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
                :<></>}
                </Grid>
                          
            </Grid>
          ),
        },

      ]}
      data={listData}
          options={{
              
              textAlign: "center",
              headerStyle: { textAlign: "left" },
              rowStyle: { textAlign: "center" },
              emptyRowsWhenPaging:false,  
            }}
            components={{
        Pagination: props => (
                     <TablePagination
                     {...props}
                   
                count={countD}
                page={countF}
               
              />
            ),
                  }}

      onChangeRowsPerPage={(e)=>handleRPP(e)}
      onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}              

          
    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
