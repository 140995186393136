import {GET_SITE_MAN_LOADING , GET_SITE_MAN_SUCCESS , GET_SITE_MAN_FAILURE, CLEAN_SITE_MAN_DATA} from '../../Actions/Types';
import {DEL_SITE_MAN_LOADING , DEL_SITE_MAN_SUCCESS , DEL_SITE_MAN_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const SiteManpower = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getData:[{}],
            getError:{},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},                
            }
    
    
        case CLEAN_SITE_MAN_DATA:
            return {
                ...state,                
                loading:false,                
                getData : [{}],
                getError:{},
                delData : [{}], 
                delError:{}
            }
    
        case GET_SITE_MAN_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_SITE_MAN_SUCCESS:
            return {
                ...state,
                loading:false,                
                getData:action.data,
                getError:{}
            }
        case GET_SITE_MAN_FAILURE:
            return {
                ...state,
                loading:false,                
                getData:{},
                getError:action.error,
            }   
        
        case DEL_SITE_MAN_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_SITE_MAN_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_SITE_MAN_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
         

        default:
        return state
    
    }
    }
    
    
    
export default SiteManpower;
    