import {GET_SUPER_PROFILE_DETAIL_LOADING , GET_SUPER_PROFILE_DETAIL_SUCCESS , GET_SUPER_PROFILE_DETAIL_FAILURE } from '../../Actions/Types';
import {EDIT_SUPER_PROFILE_DETAIL_LOADING , EDIT_SUPER_PROFILE_DETAIL_SUCCESS , EDIT_SUPER_PROFILE_DETAIL_FAILURE } from '../../Actions/Types';
import {EDIT_SUPER_PROFILE_PASSWORD_LOADING , EDIT_SUPER_PROFILE_PASSWORD_SUCCESS , EDIT_SUPER_PROFILE_PASSWORD_FAILURE } from '../../Actions/Types';
import {EDIT_SUPER_PROFILE_PIC_LOADING , EDIT_SUPER_PROFILE_PIC_SUCCESS , EDIT_SUPER_PROFILE_PIC_FAILURE } from '../../Actions/Types';

import {CLEAN_ALL_DATA , CLEAN_SUPER_PROFILE_DETAIL_DATA} from '../../Actions/Types';





const SuperProfile = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            // data: [{}],
            loading:false,
            // error:{},
            getData:[{}],
            getError:{},
            editData:[{}],
            editError:{},
            // delData : [{}],
            // delError: {}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            // data: [{}],
            loading:false,
            // error:{},
            getData:[{}],
            getError:{}
            
        }
        
        case CLEAN_SUPER_PROFILE_DETAIL_DATA:
            return {
                ...state,
               //  data: [{}],
                loading:false,
               //  error:{},
                // getData:[{}],
                getError:{},
                editData:[{}],
                editError:{},
               //  delData : [{}],
               //  delError:{}
                
            }
    
    

    case GET_SUPER_PROFILE_DETAIL_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case GET_SUPER_PROFILE_DETAIL_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            getData:action.data
        }
    case GET_SUPER_PROFILE_DETAIL_FAILURE:
        return {
            ...state,
            loading:false,
            getError:action.error,
            data:{},
        }   

    case EDIT_SUPER_PROFILE_DETAIL_LOADING:
        return {
            ...state,
            loading:true,
            editError: {},
            editData : [{}]
            
        }
    case EDIT_SUPER_PROFILE_DETAIL_SUCCESS:
        return {
            ...state,
            loading:false,
            editError:{},
            editData:action.data,
        }
    case EDIT_SUPER_PROFILE_DETAIL_FAILURE:
        return {
            ...state,
            loading:false,
            editError:action.error,
            editData:[{}],
        }

    case EDIT_SUPER_PROFILE_PASSWORD_LOADING:
        return {
            ...state,
            loading:true,
            editError: {},
            editData : [{}]
            
        }
    case EDIT_SUPER_PROFILE_PASSWORD_SUCCESS:
        return {
            ...state,
            loading:false,
            editError:{},
            editData:action.data,
        }
    case EDIT_SUPER_PROFILE_PASSWORD_FAILURE:
        return {
            ...state,
            loading:false,
            editError:action.error,
            editData:[{}],
        }

    case EDIT_SUPER_PROFILE_PIC_LOADING:
        return {
            ...state,
            loading:true,
            editError: {},
            editData : [{}]
            
        }
    case EDIT_SUPER_PROFILE_PIC_SUCCESS:
        return {
            ...state,
            loading:false,
            editError:{},
            editData:action.data,
        }
    case EDIT_SUPER_PROFILE_PIC_FAILURE:
        return {
            ...state,
            loading:false,
            editError:action.error,
            editData:[{}],
        }
        
    

    default:
        return state

}
}



export default SuperProfile;
