import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import Page from '../../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {PDFViewer,usePDF, pdf, Document,  BlobProvider} from '@react-pdf/renderer'


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import tableIcons from '../../../../Config/IconsFile'

import {getMaterialCategory} from '../../../../Actions/SubAdminActions/M_MaterialCategory';
import {getMaterialMat} from '../../../../Actions/SubAdminActions/M_MaterialMat';
import {getResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit'
import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites';
import {getVendor} from '../../../../Actions/SubAdminActions/M_Vendor';
import {getAppUser} from '../../../../Actions/SubAdminActions/AppUserManagement'
import {acceptMatIndent, orderMaterialIndent} from '../../../../Actions/SubAdminActions/Material_Indent'
import { getGst } from '../../../../Actions/SubAdminActions/Gst';



import invoice from '../../../../components/data/invoice'
import Invoice from '../../../../components/reports/Invoice'



const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate();
  const listData = (state.mat_name && Array.isArray(state.mat_name) && state.mat_name.length ? state.mat_name : []);


  const AppUserData = useSelector((stateData) =>
  stateData.AppUserMgmt && stateData.AppUserMgmt.getData &&
    stateData.AppUserMgmt.getData.length &&
    Array.isArray(stateData.AppUserMgmt.getData)
      ? (stateData.AppUserMgmt.getData).filter(userData => userData.assign_role==="Site_Engineer")
      : []
  );

  // const a_id = AppUserData.length ?  AppUserData.filter(data => data.site_id==state.site_id)[0].apu_id : 0

  const id = useSelector(state => state.login.data.r_subadmin);
  const name = useSelector(state => state.login &&state.login.data && state.login.data.name ? state.login.data.name:null);
  const folder = useSelector(state => state.login.data.folder_name);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  const [data, setData] = useState(listData);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [show, setShow] = useState(false);

  const [values, setValues] = useState({
  
    sub_admin_id :id,
    indent_id: state.indent_id,
    mat_details:"",    
    priority:state.priority,
    app_uid:state.app_uid,
    accepted_by:name,
    site_id:state.site_id,
    vd_id:"",
    gst_type:"IGST",
    vendor_name:"",
    vendor_address:"",
    vendor_email:"",
    vendor_mobile:"",
    delivery_date:state.required_on,
    order_id:state.order_id,
    folder_name:folder,
    lat:"",
    long:"",
    pdfInvoice:"",
    img:"",
    img:"",
    fixImg : "/static/images/avatars/upload.png",
    
    

  });
  

  const MyDoc= () => {

    const invoice = {
      "order_id": values.order_id,
      "name": "name",
      "address": "address",
      "mobile": "mobile",
      "created_on": values.delivery_date,
      "delivery_date": values.delivery_date,
      "email":"email",
      "items":  values.mat_details
    }
  
  
    return (
      <>
      <Invoice invoice={invoice}/>
      </>
    );
  
    
  }





  const handleClose = () =>{
   setValues({
      ...values,
      ['mat_details']:"",
     
    })
    setOpen(false);
    setOpen2(false);
    
  }
  
  

const handleOpen = () =>{


  var flag = true;       
  for(let a=0;a<MatList.length;a++){  
    if(MatList[a]['mat_type_id']!==""){
      if(MatList[a]['mat_id']!==""){               
        if(MatList[a]['quan']!==""){
          if(MatList[a]['unit_id']!==""){
            console.log("All Validation Done")

          }else{
            flag = false;                  
            tostE(`Please Select unit for ${a+1}st Purchase item`);
            break;
    
          }
        }else{
          flag = false;                
          tostE(`Please Enter Quantity for ${a+1}st Purchase item`);
          break;
  
        }
      }else{
        flag = false;              
        tostE(`Please Select Material for ${a+1}st Purchase item`);
        break;

      }
    }else{
      flag = false;            
      tostE(`Please Select Category for ${a+1}st Purchase item`);
      break;

    }       
    
  }

  if (flag){
    let myPdf = <BlobProvider document={MyDoc}/>
    
    // var blobPDF  =  new Blob([doc.output('blob')] , {type : "application/pdf" } , "newtest.pdf")


    var file = new File([myPdf], 'filename.pdf', { type: 'application/pdf', lastModified:Date.now()});
    console.log(file)
    setValues({
      ...values,
      ['mat_details']:MatList,
      ['pdfInvoice']:file
    })
    setOpen(true)
    
  }





}

const handleOpen2 = () =>{
  setValues({
    ...values,
    ['status']:"Rejected"
  })
  setOpen2(true)
  
}


const  handleReject = () =>{
   const SData = {
      
      status : "Rejected",
      accepted_by : values.accepted_by,      
      indent_id : values.indent_id
   }
  dispatch(acceptMatIndent(SData))
  navigate("/app/materialindentlist")
  setOpen2(false);

}


  

const handleChangeGstType = (event) =>{
  setValues({
    ...values,
    ['gst_type']:event.target.value
  })
}


const handleAccept = () =>{
  if(values.vd_id!=="" && values.vd_id!==null && values.vd_id!==undefined){
    
    
    if(values.mat_details!=""){
      const FData = {
         sub_admin_id :values.sub_admin_id,
         indent_id: values.indent_id,
         mat_details:values.mat_details,    
         priority:values.priority,
         accepted_by:values.accepted_by,
         site_id:values.site_id,
         vd_id:values.vd_id,
         gst_type:values.gst_type,
         vendor_name:values.vendor_name,
         vendor_address:values.vendor_address,
         vendor_mobile:values.vendor_mobile,
         vendor_email:values.vendor_email,
         delivery_date:values.delivery_date,
         order_id:values.order_id,
         folder_name:values.folder_name,
         lat:values.lat,
         app_uid:values.app_uid,
         long:values.long,
         img1:values.img,
         img2:values.img,
         pdfInvoice:values.pdfInvoice,
         fixImg : "/static/images/avatars/upload.png"
      
   
      }
      console.log(FData);
      dispatch(orderMaterialIndent(FData))
      navigate("/app/materialindentlist")
      
   
     }else {
       tostE("Order can't Be generated!!")
     }
  }else{
    tostE("Please Select a vendor!!")
  }
  setOpen(false);

}


const handleChangeVendor = (event) => {
   setValues({...values, ['vd_id']:event.target.value});

   if(event.target.value==='other'){
      setShow(true)
   }else{
      setShow(false)
   }

 };
 const handleChangePriority = (event) => {
   setValues({
     ...values,
     ['priority']: event.target.value,
   });
 };


const handleChangeSite = (event) => {
  setValues({...values, ['site_id']:event.target.value});

};
 
const handleDateChange = (date) => {
  setValues({
    ...values,
    ['delivery_date']:date
  })
};

const handleChange = (event) => {
   setValues({...values, [event.target.name]:event.target.value});
 };

const SiteData = useSelector((state) =>
state.AddSites && state.AddSites.getData &&
  state.AddSites.getData.length &&
  Array.isArray(state.AddSites.getData)
    ? state.AddSites.getData
    : []
);



const VendorData = useSelector((state) =>
state.AddVendor && state.AddVendor.getData &&
  state.AddVendor.getData.length &&
  Array.isArray(state.AddVendor.getData)
    ? state.AddVendor.getData
    : []
);

const UnitData = useSelector((state) =>
state.AddResourceUnit && state.AddResourceUnit.getData &&
  state.AddResourceUnit.getData.length &&
  Array.isArray(state.AddResourceUnit.getData)
    ? state.AddResourceUnit.getData
    : []
);


const GstData = useSelector((state) =>
state.SubGst && state.SubGst.getData &&
  state.SubGst.getData.length &&
  Array.isArray(state.SubGst.getData)
    ? state.SubGst.getData
    : []
);

const MatCatData = useSelector((state) =>
state.AddMaterialCategory && state.AddMaterialCategory.getData &&
  state.AddMaterialCategory.getData.length &&
  Array.isArray(state.AddMaterialCategory.getData)
    ? state.AddMaterialCategory.getData
    : []
);

const MatData = useSelector((state) =>
state.MaterialMatMaster && state.MaterialMatMaster.getData &&
  state.MaterialMatMaster.getData.length &&
  Array.isArray(state.MaterialMatMaster.getData)
    ? state.MaterialMatMaster.getData
    : []
);



const MacCatData = useSelector((state) =>
state.MachineCat && state.MachineCat.getData &&
  state.MachineCat.getData.length &&
  Array.isArray(state.MachineCat.getData)
    ? state.MachineCat.getData
    : []
);





useEffect(() => {
  dispatch(getSites(id))
  dispatch(getResourceUnit(id))
  dispatch(getMaterialCategory(id))
  dispatch(getMaterialMat(id))
  dispatch(getGst())
  dispatch(getAppUser(id))
  dispatch(getVendor(id))

  
}, [])


// **************** Dynamic Material ********************** //
var getMatListData = state.mat_name;
for(let i=0;i<getMatListData.length;i++){
  const selected_id = getMatListData[i]['mat_type_id']
  getMatListData[i]['getData']=MatData.filter(m_type => m_type.mat_cat_id==selected_id);
}
const [MatList, setMatList] = useState(getMatListData)

  // handle input change 
  
  const handleChangeMatInput = (event, idx) =>{
    const list = [...MatList];
    const index = idx;  
    const field_name = event.target.name;  
    list[index][field_name] = event.target.value;     
    setMatList([...list])
    
  }
  
  const handleChangeMatQuantity = (event, idx) =>{
    const list = [...MatList];
    const index = idx;        
    list[index]['quan'] = event.target.value;     
    list[index]['new_quan'] = event.target.value;     
    setMatList([...list])
    
  }
  
  const handleMatCatChange = (event, idx) =>{
    var list = [...MatList];
    const index = idx;
    const selected_id = event.target.value;
    var mat_type_insert =  MatCatData.filter(type_name => type_name.mc_id==selected_id)[0].name;
    list[index].mat_type_id = event.target.value;
    list[index].mat_type = mat_type_insert;
    list[index].getData = MatData.filter(m_type => m_type.mat_cat_id==selected_id)
    setMatList([...list])


    // dispatch(getPurchaseMatName(id, event.target.value))
  }
  
  const handleMatChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var mat_insert =  MatData.filter(type_name => type_name.mm_id==event.target.value)[0].mat_name;
    list[index].mat_id = event.target.value;
    list[index].mat_name = mat_insert;
    setMatList([...list])
    
  }
  
  const handleUnitChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var unit_insert =  UnitData.filter(type_name => type_name.sub_unit_id==event.target.value)[0].unit_name;
    list[index].unit_id = event.target.value;
    list[index].unit = unit_insert;
    setMatList([...list])
    
  }
  
  


  const handleGstChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var gst_insert =  GstData.filter(type_name => type_name.gst_id==event.target.value)[0].percent;
    list[index].gst = event.target.value;
    list[index].gst_per = gst_insert;

    setMatList([...list])
    
  }
  

  // handle click event of remove


  const handleRemoveClick = (index) => {
    const list = [...MatList];
    list.splice(index, 1);
    setMatList(list);
  };

  // handle click of add button

  const handleAddClick = () => {

    setMatList([...MatList, {amt: "", amt_error: false, gst: "", gst_per: "", gst_error: false, mat_error: false, mat_id: "", mat_name: "", mat_type: "", mat_type_error: false, mat_type_id: "", quan: "",new_quan:"", quan_error: false, unit_id:"", unit:"", getData : []}])
  }
  





  return (
    <Page className={classes.root} title="Order Material" >
      <ToastContainer    />
      
      

      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to place this order??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleAccept} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

      

      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Reject??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleReject} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader={"Order Id : "+state.order_id} title="Material Indent Order" />
                <Divider />
                <CardContent style={{marginBottom:"15px"}}>
                  <Grid container spacing={3}>                    
                    <Grid item md={12} xs={12}>
                      <Grid item md={6} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            
                            fullWidth
                            margin="normal"
                            style={{ border: "1px  black" }}
                            id="date-picker-dialog"
                            label="Delivery Date"
                            format="dd.MM.yyyy"
                            value={values.delivery_date}
                            inputVariant="outlined"
                            onChange={handleDateChange}
                            variant="outlined"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Site</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.site_id}
                          onChange={handleChangeSite}
                          label="Site"
                          style={{margin:0}}
                          inputProps={{
                                      readOnly:true
                                    }}   
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          {SiteData.map((data , i)=>
                            <MenuItem key={i}  value={data.site_id}>{data.site_name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Vendor</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.vd_id}
                          onChange={handleChangeVendor}
                          label="Vendor"
                          style={{margin:0}}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          {VendorData.map((data , i)=>
                            <MenuItem key={i}  value={data.vd_id}>{data.name}</MenuItem>
                          )}
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    
                  {show?
                  <>
                     <Grid item md={4} xs={12}>
                        <TextField
                        fullWidth
                        label="Vendor Name"
                        name="vendor_name"
                        onChange={handleChange}
                        required
                        value={values.vendor_name}
                        variant="outlined"
                        />
                     </Grid>
                     <Grid item md={4} xs={12}>
                        <TextField
                        fullWidth
                        label="Vendor Address"
                        name="vendor_address"
                        onChange={handleChange}
                        required
                        value={values.vendor_address}
                        variant="outlined"
                        />
                     </Grid>
                     <Grid item md={4} xs={12}>
                        <TextField
                        fullWidth
                        label="Vendor Mobile"
                        name="vendor_mobile"
                        onChange={handleChange}
                        required
                        value={values.vendor_mobile}
                        variant="outlined"
                        />
                     </Grid>
                     
                     <Grid item md={4} xs={12}>
                        <TextField
                        fullWidth
                        label="Vendor Email"
                        name="vendor_email"
                        onChange={handleChange}
                        required
                        value={values.vendor_email}
                        variant="outlined"
                        />
                     </Grid>
                     </>
                  :<></>
                  }


                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel component="legend">GST Type</FormLabel>
                      <RadioGroup row aria-label="GST Type" value={values.gst_type} onChange={(e) => handleChangeGstType(e)} name="row-radio-buttons-group">
                        <FormControlLabel value="IGST" control={<Radio size="small" color={"primary"} />} label="IGST" />
                        <FormControlLabel value="CGST+SGST" control={<Radio size="small" color={"primary"} />} label="CGST+SGST" />                  
                      </RadioGroup>
                    </FormControl>
                  </Grid>


                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Priority</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.priority}
                          onChange={handleChangePriority}
                          label="Priority"
                          
                        >
                          <MenuItem value="Low">Low</MenuItem>
                          <MenuItem value="Medium">Medium</MenuItem>
                          <MenuItem value="High">High</MenuItem>
                          
                        </Select>
                      </FormControl>
                    </Grid>
                    
                  </Grid>

                </CardContent>
                <Divider />
                
                <>     
                  {MatList && MatList.length > 0 && Array.isArray(MatList) ?
                    <>
                      { MatList.map((field, idx) => {
                        return (
                          <>
                          <Card style={{border:"1px solid #db630b", margin:"15px", padding:"10px"}}>
                            <CardContent style={{borderBottomWidth:1 , borderBottomColor:"black"}}>
                              <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Material Category</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={Object.keys(field) && Object.keys(field).length && field.mat_type_id ?field.mat_type_id:""}
                                      onChange={e => handleMatCatChange(e,idx)}
                                      label="Material Category"  
                                                                        
                                    >
                                      
                                      {MatCatData.map((data , i)=>
                                        <MenuItem key={i} value={data.mc_id}>{data.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Material Name</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.mat_id}
                                      onChange={e => handleMatChange(e,idx)}
                                      label="Material Name"
                                      style={{margin:0}}
                                      // renderValue={(selected) => (
                                      //   (field.getData.filter(d=> d.mm_id==selected)[0].mat_name).slice(0,25)
                                      // )}
                                    >
                                      {field.getData.map((data , i)=>
                                        <MenuItem key={i}  value={data.mm_id} >
                                        {data.mat_name}
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Quantity"
                                    name="quan"
                                    type="number"  
                                    InputProps={{inputProps:{min:0}}}
                                    onChange={e => handleChangeMatQuantity(e, idx)}
                                    required
                                    value={field.quan}
                                    variant="outlined"
                                    
                                  />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.unit_id}
                                      onChange={e => handleUnitChange(e,idx)}
                                      label="Unit"
                                      
                                    >
                                      {UnitData.map((data , i)=>
                                        <MenuItem key={i}  value={data.sub_unit_id}>{data.unit_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {values.status!=="Eng_Resolved" ? 
                                  <>
                                    <Grid item md={3} xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Amount"
                                        name="amt"
                                        type="number"  InputProps={{inputProps:{min:0}}}
                                        onChange={e => handleChangeMatInput(e, idx)}
                                        required
                                        value={field.amt}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                      <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">GST</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={field.gst}
                                          onChange={e => handleGstChange(e,idx)}
                                          label="GST"
                                          style={{margin:0}}
                                        >
                                          {GstData.map((data , i)=>
                                            <MenuItem key={i}  value={data.gst_id}>{data.percent}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </>
                                :<></>
                                
                                }
                                {MatList.length>1?
                                
                                <Grid item md={3} xs={12}>
                                  <Box display="flex" justifyContent="flex-end" p={2}>
                                    <Button onClick={() => handleRemoveClick(idx)} color="primary" variant="contained">
                                    Delete
                                    </Button>
                                  </Box>
                                
                                    
                                </Grid>
                                :<></>
                                }
                              </Grid>
                            </CardContent>
                          </Card>
                          
                          </>
                        
                        );
                      })}
                    </>: <></>
                  }
                  </>
                              
                        
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={() => handleAddClick()} color="primary" variant="contained">
                      Add More
                    </Button>
                  </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" p={2}>
                  <Button onClick={() => handleOpen()}   color="primary" variant="contained" style={{marginRight:"5px"}}>
                    Create Order
                  </Button>
                  {state.status==="Pending"?
                  
                     <Button onClick={() => handleOpen2()}   color="secondary" variant="contained" style={{marginLeft:"5px"}}>
                     Reject
                     </Button>                  
                  :<></>
                  }
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                                   
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
        
      </Container>
    </Page>
  );
};

export default AddBusiness;
