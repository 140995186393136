// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addMachineCatBegin , addMachineCatSuccess , addMachineCatFailure } from '../ActionCreators';
import {getMachineCatBegin , getMachineCatSuccess , getMachineCatFailure } from '../ActionCreators';
import {delMachineCatBegin , delMachineCatSuccess , delMachineCatFailure } from '../ActionCreators';
import {editMachineCatBegin , editMachineCatSuccess , editMachineCatFailure } from '../ActionCreators';


export const addMachineCat = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addMachineCatBegin());

        axios.post(Base_URL + '/subAdmin/addMachineCat', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addMachineCatSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addMachineCatFailure(err.response.data));

                }
            })
    }
}

export const getMachineCat = (x) => {

    return (dispatch) => {
        dispatch(getMachineCatBegin());

        axios.get(Base_URL + '/subAdmin/getMachineCat' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getMachineCatSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getMachineCatFailure(err.response.data));

                }
            })
    }
}

export const delMachineCat = (x) => {
    console.log(x);
    return (dispatch) => {
        dispatch(delMachineCatBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/deleteMachineCat' , {params :{mch_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delMachineCatSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delMachineCatFailure(err.response.data));

                }
            })
    }
}

export const editMachineCat = (session) => {

    return (dispatch) => {
        dispatch(editMachineCatBegin());

        axios.post(Base_URL + '/subAdmin/editMachineCat', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editMachineCatSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editMachineCatFailure(err.response.data));

                }
            })
    }
}

