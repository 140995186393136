import {ADD_APP_USER_MGMT_LOADING , ADD_APP_USER_MGMT_SUCCESS , ADD_APP_USER_MGMT_FAILURE} from '../../Actions/Types';
import {GET_APP_USER_MGMT_LOADING , GET_APP_USER_MGMT_SUCCESS , GET_APP_USER_MGMT_FAILURE} from '../../Actions/Types';
import {EDIT_APP_USER_MGMT_LOADING , EDIT_APP_USER_MGMT_SUCCESS , EDIT_APP_USER_MGMT_FAILURE} from '../../Actions/Types';
import {DEL_APP_USER_MGMT_LOADING , DEL_APP_USER_MGMT_SUCCESS , DEL_APP_USER_MGMT_FAILURE} from '../../Actions/Types';
import {CLEAN_ALL_DATA, CLEAN_APP_USER_MGMT_DATA} from '../../Actions/Types'
import {CHP_AUM_FAILURE , CHP_AUM_LOADING , CHP_AUM_SUCCESS  , CLEAN_CHP_AUM_DATA} from '../../Actions/Types'



const AppUserMgmt = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData:[{}],
            editError:{},
            delData:[{}],
            delError:{},
            passData : [{}],
            passError : {}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                 data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData:[{}],
            editError:{},
            delData:[{}],
            delError:{},
            passData : [{}],
            passError : {}
                
            }


            case CLEAN_CHP_AUM_DATA:
                return {
                    ...state,
                    loading:false,
                    passData : [{}],
                    passError : {}
                }
    
    
        case CLEAN_APP_USER_MGMT_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData : [{}],
                getError:{},
                editData : [{}],
                editError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_APP_USER_MGMT_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                
                
            }
        case ADD_APP_USER_MGMT_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
                
            }
        case ADD_APP_USER_MGMT_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
            }
        case GET_APP_USER_MGMT_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
                
            }
        case GET_APP_USER_MGMT_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
                
            }
        case GET_APP_USER_MGMT_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:[{}],
            }

        case EDIT_APP_USER_MGMT_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}],
                
                
            }
        case EDIT_APP_USER_MGMT_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data,
                
            }
        case EDIT_APP_USER_MGMT_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:[{}],
            }            
        case DEL_APP_USER_MGMT_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}],
                
                
            }
        case DEL_APP_USER_MGMT_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
                
            }
        case DEL_APP_USER_MGMT_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:[{}],
            }


            case CHP_AUM_LOADING:
                return {
                    ...state,
                    loading:true,
                    passError: {},
                    passData : [{}],
                    
                    
                }
            case CHP_AUM_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    passError:{},
                    passData:action.data,
                    
                }
            case CHP_AUM_FAILURE:
                return {
                    ...state,
                    loading:false,
                    passError:action.error,
                    passData:[{}],
                }
        

        default:
        return state
    
    }
    }
    
    
    
export default AppUserMgmt;
    