// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor3";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {addRolesBegin , addRolesSuccess , addRolesFailure } from './../ActionCreators'
import {getRolesBegin , getRolesSuccess , getRolesFailure } from './../ActionCreators'
import {editRolesBegin , editRolesSuccess , editRolesFailure } from './../ActionCreators'
import {delRolesBegin , delRolesSuccess , delRolesFailure } from './../ActionCreators'



export const addRoles = (session) => {

    return (dispatch) => {
        dispatch(addRolesBegin());

        axios.post(Base_URL + '/superadmin/addRole', session)
            .then((res) => {
                dispatch(addRolesSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addRolesFailure(err.response.data));

                }
            })
    }
}



export const editRoles = (session) => {
    return (dispatch) => {
        dispatch(editRolesBegin());
        axios.post(Base_URL + '/superadmin/editRole', session)
            .then((res) => {
                dispatch(editRolesSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")
                }
                else {
                    dispatch(editRolesFailure(err.response.data));

                }
            })
    }
}


export const getRoles = () => {

    return (dispatch) => {
        dispatch(getRolesBegin());

        axios.get(Base_URL + '/superadmin/getRole')
            .then((res) => {
                dispatch(getRolesSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getRolesFailure(err.response.data));

                }
            })
    }
}


export const delRoles = (x) => {

    // console.log('role_id_deleted' , x);

    return (dispatch) => {
        dispatch(delRolesBegin());

        axios.get(Base_URL + '/superadmin/delRole' , {params : {role_id : x}})
            .then((res) => {
                dispatch(delRolesSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delRolesFailure(err.response.data));

                }
            })
    }
}









