import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { getSites } from "../../Actions/SubAdminActions/M_AddSites";
import { array } from "prop-types";

import { SelectedSite } from "../../Actions/ActionCreators";
import { SubDashboard } from "../../Actions/SubDashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    color: "white",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    maxHeight: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    color: "white",
  },
  icon: { color: "white" },
}));

const SMenu = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const sub_id = useSelector((state) => state.login.data.r_subadmin);
  const roleData = useSelector((state) => state.login.data.role_permit);

  const siteIds = roleData.site_ids || []; // Get site_ids or empty array if not present

  const SData = useSelector((state) =>
    state.AddSites &&
    state.AddSites.getData &&
    Array.isArray(state.AddSites.getData) &&
    state.AddSites.getData.length
      ? state.AddSites.getData
      : []
  );

  const filteredSData = siteIds.length
    ? SData.filter((data) => siteIds.includes(data.site_id))
    : SData;
  console.log(filteredSData);

  // const SData = useSelector((state) =>
  //   state.AddSites && state.AddSites.getData &&
  //   Array.isArray(state.AddSites.getData) &&
  //   state.AddSites.getData.length ?
  //   state.AddSites.getData :[]
  // )

  const s_value = useSelector((state) =>
    state.SelectedSite &&
    Object.keys(state.SelectedSite.sitedata) &&
    Object.keys(state.SelectedSite.sitedata).length &&
    state.SelectedSite.sitedata.site !== null
      ? state.SelectedSite.sitedata.site
      : null
  );

  const [values, setValues] = React.useState({
    site: useSelector((state) =>
      state.SelectedSite &&
      Object.keys(state.SelectedSite.sitedata) &&
      Object.keys(state.SelectedSite.sitedata).length &&
      state.SelectedSite.sitedata.site !== null
        ? state.SelectedSite.sitedata.site
        : ""
    ),
    name: useSelector((state) =>
      state.SelectedSite &&
      Object.keys(state.SelectedSite.sitedata) &&
      Object.keys(state.SelectedSite.sitedata).length &&
      state.SelectedSite.sitedata.name !== null
        ? state.SelectedSite.sitedata.name
        : ""
    ),
  });

  useEffect(() => {
    getSites(sub_id);
    if (s_value === null) {
      dispatch(
        SelectedSite({
          site:
            SData && Array.isArray(SData) && SData.length && SData[0]
              ? SData[0].site_id
              : null,
          name:
            SData && Array.isArray(SData) && SData.length && SData[0]
              ? SData[0].site_name
              : null,
        })
      );
    }
  }, [s_value, SData]);

  const id = useSelector((state) =>
    state.login.data &&
    Object.keys(state.login.data) &&
    Object.keys(state.login.data).length &&
    state.login.data.r_subadmin
      ? state.login.data.r_subadmin
      : ""
  );

  const folder_name = useSelector((state) =>
    state.login.data &&
    Object.keys(state.login.data) &&
    Object.keys(state.login.data).length &&
    state.login.data.folder_name
      ? state.login.data.folder_name
      : ""
  );

  function handleChange(event) {
    const s_id = event.target.value;
    var s_name = "";
    if (s_id !== "" && s_id !== undefined && s_id !== null) {
      s_name = SData.filter((data) => data.site_id == s_id)[0].site_name;
    }
    const site_data = { site: s_id, name: s_name };

    dispatch(SelectedSite(site_data));
    dispatch(SubDashboard(id, folder_name, s_id));
  }

  return (
    <>
      {s_value !== null && s_value !== undefined && s_value !== "" ? (
        <form className={classes.root}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              shrink={true}
              style={{ color: "white" }}
            >
              Select Site
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={s_value}
              value={s_value}
              onChange={handleChange}
              label="Select Site"
              classes={{
                select: classes.select,
                icon: classes.icon,
              }}
            >
              {filteredSData.map((data, i) => (
                <MenuItem key={i} value={data.site_id}>
                  {data.site_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      ) : (
        <></>
      )}
    </>
  );
};

export default SMenu;
