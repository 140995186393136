import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  IconButton,
  InputAdornment,
  OutlinedInput
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';
import {useLocation , useNavigate} from 'react-router-dom';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";

import {editClients} from '../../../Actions/SuperAdminActions/AddClients';
import {getPlan} from '../../../Actions/SuperAdminActions/AddPlan';
import {cleanClientsData} from '../../../Actions/ActionCreators'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});




const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const navigate = useNavigate();


  const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  const [selectedDate2, setSelectedDate2] = useState(new Date(Date.now()));

  const [values, setValues] = useState({  
      admin_id:state.admin_id,     
      name:state.name,
      mobile:state.mobile,
      email :state.email ,
      plan_id :state.plan_id ,
      is_active :state.is_active ,
      is_plan_active :state.is_plan_active,
      invoice_link:state.invoice_link,
      order_type :state.order_type ,
      is_app_login :state.is_app_login ,
      plan_start_date :state.plan_start_date ,
      plan_end_date :state.plan_end_date ,
      plan_duration:state.plan_duration,
      r_subadmin :state.r_subadmin ,
      permit_role :state.permit_role ,
      showPassword: false,

    });
  
    
  const handleChangee = (event) => {
    setValues({...values, ['state']:event.target.value});
  };
  
  const handleChanget = (event) => {
    setValues({...values, ['type_of_business']:event.target.value});
  };

  
  const handleChangef = (event) => {
    setValues({...values, ['order_type']:event.target.value});
  };


  const planData = useSelector((state) =>
  state.AddPlan && state.AddPlan.getData &&
    state.AddPlan.getData.length &&
    Array.isArray(state.AddPlan.getData)
      ? state.AddPlan.getData
      : []
  );



  const handleChanged = (event) => {
    const pId = event.target.value;
    const pDuration = (planData.filter(duration => duration.plan_id==pId))[0].duration    
    const end_date = moment(values.plan_start_date).add({days:pDuration})
    

    setValues({
      ...values , 
      ['plan_id']:pId,
      ['plan_end_date']:end_date
    })
    console.log(values)      
  };


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleDateChange = (date) => {    
    const start_date = date;
    console.log(date);
    if( values.plan_id==""){
      tostW("Please Select Plan First")
    }else{
      const pId = values.plan_id;
      const pDuration = (planData.filter(duration => duration.plan_id==pId))[0].duration      
      var end_date = moment(start_date).add({days:pDuration})
     
    }
    
    if(moment(start_date).isAfter(Date.now())){
      setValues({
        ...values,
        ['is_plan_active']:false,
        ['plan_start_date']:start_date,
        ['plan_end_date']:end_date
      })
    }else{
      setValues({
        ...values,
        ['is_plan_active']:true,
        ['plan_start_date']:start_date,
        ['plan_end_date']:end_date
      })
    }

    // setValues({...values , ['plan_start_date']:start_date})

  };
  
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
    var t =  moment(new Date(date)).format("DD/MM/YYYY")
    console.log(t)
    setValues({...values , ['end_date']:t})
  };

  const handleTimeChange = (date) =>{

    console.log(date)

  }




  const handleChangec = (event) => {
      console.log(event.target.checked)
    setValues({ ...values, ['mobile_visible']: event.target.checked });
  };


 


    /********* Image Handling   ************** */

    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);
    const hiddenFileInput3 = useRef(null);
    const hiddenFileInput4 = useRef(null);
  
    const handleClick1 = (event) => {
      hiddenFileInput1.current.click();
    };
    
    const handleClick2 = (event) => {
      hiddenFileInput2.current.click();
    };
  
    const handleClick3 = (event) => {
      hiddenFileInput3.current.click();
    };
  
    const handleClick4 = (event) => {
      hiddenFileInput4.current.click();
    };
    
  
    const handleChangeimg1 = (event) => {
      if (event.target.files[0] && event.target.files.length !== 0) {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        console.log(URL.createObjectURL(fileUploaded));
        var x = URL.createObjectURL(fileUploaded);
  
        setValues({ ...values, ["tempimg1"]: x, ["image1"]: fileUploaded });
      } else {
        console.log("yaah ");
      }
    };
  
    const handleChangeimg2 = (event) => {
      if (event.target.files[0] && event.target.files.length !== 0) {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        console.log(URL.createObjectURL(fileUploaded));
        var x = URL.createObjectURL(fileUploaded);
  
        setValues({ ...values, ["tempimg2"]: x, ["image2"]: fileUploaded });
      } else {
        console.log("yaah ");
      }
    };
    const handleChangeimg3 = (event) => {
      if (event.target.files[0] && event.target.files.length !== 0) {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        console.log(URL.createObjectURL(fileUploaded));
        var x = URL.createObjectURL(fileUploaded);
  
        setValues({ ...values, ["tempimg3"]: x, ["image3"]: fileUploaded });
      } else {
        console.log("yaah ");
      }
    };
    const handleChangeimg4 = (event) => {
      if (event.target.files[0] && event.target.files.length !== 0) {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        console.log(URL.createObjectURL(fileUploaded));
        var x = URL.createObjectURL(fileUploaded);
  
        setValues({ ...values, ["tempimg4"]: x, ["image4"]: fileUploaded });
      } else {
        console.log("yaah ");
      }
    };
  
    /********* Image Handling   ************** */




    

  const handleStateMenu = (y) => {
    if(y !== "" && y !== undefined && y !== null )
    {
        // dispatch(getCityData(y))
    }
    else
    {
      console.log(".")
    }
  }



  

  const addmsg = useSelector(state => state.AddClients && state.AddClients.data && state.AddClients.data.message ? state.AddClients.data.message : null  )
  const adderror = useSelector(state => state.AddClients && state.AddClients.error && state.AddClients.error.message ? state.AddClients.error.message : null  )


  useEffect( () => {

    dispatch(getPlan())
    let x  = new Promise((resolve ,reject)=>{
      if(addmsg && typeof(addmsg) === 'string')
      {
        tostS("Added Successfully!!")
      }
      if(adderror && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanClientsData())
   

    })

  
     
    }, [addmsg , adderror])


  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChangeimg = event => {

    if(event.target.files[0] && event.target.files.length !==0)
    {
      const fileUploaded = event.target.files[0];
      console.log(fileUploaded)
      setValues({...values , ['image1']:fileUploaded , ['tempImage1']:URL.createObjectURL(fileUploaded)})
    }
    else
    {
      console.log('yahah')
    }

  };
  
  /********* Image Handling   ************** */



  const handleChangeToggle = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };


  const handleChangeToggleActive = (event) => {
    const active = event.target.checked;
    console.log(active)
    if(active){
      
      setValues({ 
        ...values, 
        ['is_active']: event.target.checked, 
      });
    }else{
      setValues({ 
        ...values, 
        ['is_active']: event.target.checked, 
        ['is_app_login']: event.target.checked 
      });
    }
  };

  const handleSubmit = () =>{
    var mobileReg = /^[6-9]{1}[0-9]{9}$/;
    var nameReg = /([a-zA-Z]{1,}\s?[a-zA-Z]+)+/;
    console.log("++++++++++++++" , values.mobile);
    console.log(mobileReg.test(values.mobile));
    console.log("+++++++++++++++");
    console.log(nameReg.test(values.name));
    if(nameReg.test(values.name)!=true){
      tostW("Please Enter a Valid Name!!")
    }else if(mobileReg.test(values.mobile)!=true){

      tostW("Please Enter a Valid Mobile Number!!");

    }else if(values.name==="" || values.name===null || values.name===undefined){
      tostE("Please Enter Name!!")
    }else if(values.mobile==="" || values.mobile===null || values.mobile===undefined){
      tostE("Please Enter Mobile Number!!")
    }else if(values.email==="" || values.email===null || values.email===undefined){
      tostE("Please Enter Email!!")
    }else if(values.plan_id==="" || values.plan_id===null || values.plan_id===undefined){
      tostE("Please Select Plan!!")
    }else if(values.order_type==="" || values.order_type===null || values.order_type===undefined){
      tostE("Please Enter Billing Type!!")
    }else{

      var r =  {    
        admin_id:values.admin_id,
        name : values.name,
        mobile: values.mobile,
        email:values.email,
        plan_id:values.plan_id,
        is_active:values.is_active,
        is_plan_active:values.is_plan_active,
        invoice_link:values.invoice_link,
        order_type:values.order_type,
        is_app_login:values.is_app_login,
        plan_start_date:values.plan_start_date,
        plan_end_date:values.plan_end_date,
        plan_duration:values.plan_duration,
        r_subadmin:values.r_subadmin,
        permit_role:values.permit_role,
            
      }
  
  
      dispatch(editClients(r))
      navigate("/app/orders")

    }

  }



  return (
    <Page
      className={classes.root}
      title="Create Clients"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
        
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Create Clients" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
         
          
             <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"  InputProps={{inputProps:{min:0}}}
                label="Mobile"
                name="mobile"
                onChange={handleChange}
                required
                value={values.mobile}
                variant="outlined"
               
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>                              
            
            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Plan</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.plan_id}
          onChange={handleChanged}
          
          label="Plan"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          {planData.map((data , i)=>
          <MenuItem key={i}  value={data.plan_id}>{data.plan_name}</MenuItem>
          )}        




        </Select>
      </FormControl>
      </Grid>
            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Billing</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.order_type}
          onChange={handleChangef}
          label="Plan Mode"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        <MenuItem   value="Cash">Cash</MenuItem>

        </Select>
      </FormControl>
      </Grid>

      <Grid item md={6} xs={12}>
      <ThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth                  
                  margin="normal"
                  style={{ border: "1px  black" , marginTop:-1}}
                  id="date-picker-dialog"
                  label="Plan Start Date"
                  format="dd/MM/yyyy"
                  value={values.plan_start_date}
                  onChange={handleDateChange}
                  variant="outlined"
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  
                />
              </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Grid>


            <Grid item md={6} xs={12}>
              <ThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" , marginTop:-1}}
                  id="date-picker-dialog"
                  label="End Date"
                  format="dd/MM/yyyy"
                  value={values.plan_end_date}                  
                  variant="outlined"
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Grid>

            <Grid item md={6} xs={12} styel={{border:"1px solid grey" }}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={values.is_active} onChange={handleChangeToggleActive} name="is_active" />}
                  label="Client Active"
                />
                 <FormControlLabel
                  control={<Switch checked={values.is_app_login} onChange={handleChangeToggle} name="is_app_login" />}
                  label="Allow App Login"
                />
                 <FormControlLabel
                  control={<Switch checked={values.is_plan_active} onChange={handleChangeToggle} name="is_plan_active" />}
                  label="Plan Active"
                  readOnly
                />
              </FormGroup>
            </Grid>
      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
