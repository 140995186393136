import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  CardMedia
  
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";




const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const {state} = useLocation();
  const [expanded, setExpanded] = React.useState(false);



  const SlistData = (state.send_mat_details && Array.isArray(state.send_mat_details) && state.send_mat_details.length ? state.send_mat_details : []);
  const RlistData = (state.recieve_mat_details && Array.isArray(state.recieve_mat_details) && state.recieve_mat_details.length ? state.recieve_mat_details : []);

  
   
   // const listData = {state.send_mat_details}

//   const [values, setValues] = useState({
   
//    //  quantity:state.send_mat_details.quan,

  
//     });



// const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


// const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };


//   const handleChanged = (event) => {
//     setValues({...values, ['country']:event.target.value});
//   };

  
 
  const Delmsg = useSelector(state => state.AddBusiness && state.AddBusiness.deldata && state.AddBusiness.deldata.message ? state.AddBusiness.deldata.message : null  )
  const Delerror = useSelector(state => state.AddBusiness && state.AddBusiness.delerror && state.AddBusiness.delerror.message ? state.AddBusiness.delerror.message : null  )





  const sub_id = useSelector(state => state.login.data.admin_id)
  
  

  
useEffect( () => {

    // dispatch(getBusiness())


    let x  = new Promise((resolve ,reject)=>{
       
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

   //  dispatch(cleanMaterialSupplyData())
   //  dispatch(getMaterialSupply(sub_id, 1))

    })


    
  }, [ Delmsg  ,Delerror ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
//   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
}


const onDel = (oldata) =>{

//   dispatch(DelBusiness(oldata.bp_id ,oldata.tableData.id ))
}
 







  


  const addNewBornbutton = () =>{
    // navigate('/app/materialindent')
    console.log("prchase order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Material Supply">
      <ToastContainer    />
        <Container maxWidth={false}>

        <Card sx={{ maxWidth: 345 }}>
      <CardHeader        
        title="Basic Details"
        subheader=""
      />
      
      <CardContent>
         <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
                Supply ID : {state.supp_id}
               </Typography>
            </Grid>
            
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Engineer Name : {state.name}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               From : {state.from_site_name}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               To : {state.to_site_name}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Send Type : {state.send_type}
               </Typography>
            </Grid>
            
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Delivery Date : {moment(state.delivery_date).format("DD/MM/YYYY")}
               </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Priority : {state.priority}
               </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                    
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
                  
                    <Typography>Location :  
                      {state.lat}, {state.long}                 
                    </Typography>
                  
                   : 
                   <Typography>Location: </Typography>
                   }
                    
                  </Grid>
         </Grid>
         
        
        
      </CardContent>
      
      
    </Card>

    
    <Grid container spacing={3} style={{paddingTop:"20px"}}>
      <Grid item lg={6}>
        <Card>
            <Typography 
              style={{
                height:"50px", 
                backgroundColor:"#E36A26", 
                color:"#fff",                  
                padding:"3%", 
                textAlign:"center"
                }} 
                variant="h5">
              Vehicle Loaded
            </Typography>
            <CardMedia onClick={()=>{window.open(state.images[0])}} component="img" image={state.images[0]} height="140" alt="Image Is Not Available"/>
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card>
            <Typography style={{
              height:"50px", 
              backgroundColor:"#E36A26", 
              color:"#fff",                
              padding:"3%", 
              textAlign:"center"
              }} 
              variant="h5">
              Material Image
            </Typography>
            <CardMedia onClick={()=>{window.open(state.images[1])}} component="img" image={state.images[1]} height="140" alt="Image Is Not Available"/>
        </Card>
      </Grid>
    </Grid>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Send Material Details"
      columns={[
        
        
        { title: 'Material Name', field: 'mat_name',editable: "never", },     
        { title: 'Material Type', field: 'mat_type',editable: "never", },
       
        { title: 'Quantity', render:rowData=>(rowData.quan + " " + rowData.unit)},  
      ]}
      data={SlistData}
        options={{
                selection: false,
                search:false,
                paging:false,
                emptyRowsWhenPaging:false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
    </Card>

    {state.send_type==='RCV' ? 
    <>
    
    <Grid container spacing={3} style={{paddingTop:"20px"}}>
      <Grid item lg={6}>
        <Card>
            <Typography 
              style={{
                height:"50px", 
                backgroundColor:"#E36A26", 
                color:"#fff",                  
                padding:"3%", 
                textAlign:"center"
                }} 
                variant="h5">
              Vehicle Loaded
            </Typography>
            <CardMedia onClick={()=>{window.open(state.image_rcv[0])}} component="img" image={state.image_rcv[0]} height="140" alt="Image Is Not Available"/>
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card>
            <Typography style={{
              height:"50px", 
              backgroundColor:"#E36A26", 
              color:"#fff",                
              padding:"3%", 
              textAlign:"center"
              }} 
              variant="h5">
              Material Image
            </Typography>
            <CardMedia onClick={()=>{window.open(state.image_rcv[1])}} component="img" image={state.image_rcv[1]} height="140" alt="Image Is Not Available"/>
        </Card>
      </Grid>
    </Grid>
    <Box mt={3}>
    <Card className={clsx(classes.root2, className)} {...rest}>
            <MaterialTable
            
      icons={tableIcons}      
      title="Receive Material Details"
      columns={[
        
        { title: 'Material Name', field: 'mat_name',editable: "never", },     
        { title: 'Material Type', field: 'mat_type',editable: "never", },
        { title: 'Quantity', render:rowData=>(rowData.quan + " " + rowData.unit)},                
       

      ]}
      data={RlistData}
        options={{
                selection: false,
                textAlign: "center",
                search:false,
                paging:false,
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
            </Card>
    </Box>
    </>
    :<></>
             
    }

          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
