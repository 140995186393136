import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { ThemeProvider } from "@material-ui/styles";

import {addStock} from "../../../../Actions/Inventory/Stock";
import {cleanStockData} from "../../../../Actions/Inventory/ActionCreators";

import {getMaterialCategory} from "../../../../Actions/SubAdminActions/M_MaterialCategory";
import {getMaterialMat} from "../../../../Actions/SubAdminActions/M_MaterialMat";
import {getMachineCat} from "../../../../Actions/SubAdminActions/M_MachineCat";
import {getResourceMachine} from "../../../../Actions/SubAdminActions/M_ResourceMachine";
import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites';
import {getResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
   margin: theme.spacing(0),
   minWidth: '100%',
   maxWidth:"100%",
   },
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();

  const a_id = useSelector(state => state.login && state.login.data && state.login.data.r_subadmin?state.login.data.r_subadmin:"");
  const id = useSelector(state => state.login && state.login.data && state.login.data.admin_id?state.login.data.admin_id:"");
   
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)

   const site_id_list = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.site_ids !== null ? state.login.data.role_permit.site_ids:null)

   const admin_name = useSelector(state => state.login && state.login.data && state.login.data.name ? state.login.data.name:null)


  const [values, setValues] = useState({
   item_type:"",
   item_name:"",
   item_cat:"",
   item_unit:"",
   item_quan:"",
   entry_type:"direct",
   remark:"Direct Added to inventory by "+admin_name,
   site_id:selected_site!==null ? selected_site: "",
   sub_admin_id:a_id,
   item_cat_data:[],
   item_name_data:[]
  });

  const handleType = (event) => {
   const type_name = event.target.value;
   var arr = [];
   var unit = "";
   if(type_name==="Material"){
      MatCatData.map(data => {arr.push({"label" : data.name , "value" : data.mc_id})});

   }else if(type_name==="Machine"){
      MacCatData.map(data => {arr.push({"label" : data.name , "value" : data.mch_id})});
      unit=0;
   }

   setValues({
      ...values,
      ['item_type']:type_name,
      ['item_name_data']:[],
      ['item_cat_data']:arr,
      ['item_unit']:unit
   })   
   
  }
  

  const handleChangeCategory = (event) => {
   const CategoryVal = event.target.value;
   var NameArr = [];
   if (values.item_type==="Material"){       
     MatData.filter(data => data.mat_cat_id===CategoryVal).map(PushData => {NameArr.push({"label":PushData.mat_name, "value":PushData.mm_id})})      
   }else if(values.item_type==="Machine"){
     MacData.filter(data => data.mch_cat_id===CategoryVal).map(PushData => {NameArr.push({"label":PushData.machine_name, "value":PushData.sub_machine_id})})
   }


   setValues({
     ...values, 
     ['item_cat']:CategoryVal,
     ['item_name_data']:NameArr,
     ['item_name']:"",
   });

   
 };
 

 
 const handleChangeName = (event) => {
   setValues({...values, ['item_name']: event.target.value,});
 };


  const handleChange = (event) => {
     setValues({
        ...values,
        [event.target.name]:event.target.value
     })
  } 

  const handleUnitChange = (event) =>{
   setValues({
      ...values, 
      ['item_unit']:event.target.value,
    });
       
 };
 
 const handleChangeSite = (event) => {
   
   setValues({
      ...values, 
      ['site_id']:event.target.value,
    });
    

  };

  const addmsg = useSelector(state => state.Stock.data && state.Stock.data.message ? state.Stock.data.message : null  )
  const adderror = useSelector(state => state.Stock.error && state.Stock.error.message ? state.Stock.error.message : null  )
  


  useEffect(()=>{
   dispatch(getMaterialCategory(a_id))
   dispatch(getMaterialMat(a_id))
   dispatch(getMachineCat(a_id))
   dispatch(getResourceMachine(a_id))
   dispatch(getSites(a_id, site_id_list))
   dispatch(getResourceUnit(a_id))

   let p = new Promise((resolve, reject) => {
      if(addmsg!==null && typeof(addmsg) === 'string')
      {
        tostS(addmsg)
      }
      if(adderror!==null && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }


      resolve()
   })
   p.then(() => {
      dispatch(cleanStockData())
   })

  },[addmsg , adderror])

  
const SiteData = useSelector((state) =>
state.AddSites && state.AddSites.getData &&
  state.AddSites.getData.length &&
  Array.isArray(state.AddSites.getData)
    ? state.AddSites.getData
    : []
);


  const UnitData = useSelector((state) =>
  state.AddResourceUnit && state.AddResourceUnit.getData &&
    state.AddResourceUnit.getData.length &&
    Array.isArray(state.AddResourceUnit.getData)
      ? state.AddResourceUnit.getData
      : []
  );
  
  
  
  const MatCatData = useSelector((state) =>
  state.AddMaterialCategory && state.AddMaterialCategory.getData &&
    state.AddMaterialCategory.getData.length &&
    Array.isArray(state.AddMaterialCategory.getData)
      ? state.AddMaterialCategory.getData
      : []
  );
  
  const MatData = useSelector((state) =>
  state.MaterialMatMaster && state.MaterialMatMaster.getData &&
    state.MaterialMatMaster.getData.length &&
    Array.isArray(state.MaterialMatMaster.getData)
      ? state.MaterialMatMaster.getData
      : []
  );
  
  
  
  const MacCatData = useSelector((state) =>
  state.MachineCat && state.MachineCat.getData &&
    state.MachineCat.getData.length &&
    Array.isArray(state.MachineCat.getData)
      ? state.MachineCat.getData
      : []
  );
  
  
  
  const MacData = useSelector((state) =>
  state.AddResourceMachine && state.AddResourceMachine.getData &&
    state.AddResourceMachine.getData.length &&
    Array.isArray(state.AddResourceMachine.getData)
      ? state.AddResourceMachine.getData
      : []
  );
  
  
  const handleSubmit =()=>{
   if(values.site_id===null || values.site_id===undefined || values.site_id===""){
      tostE("Please Select Site!!");
   }else if(values.item_type===null || values.item_type===undefined || values.item_type===""){
        tostE("Please Select Item Type!!");
   }else if(values.item_cat===null || values.item_cat===undefined || values.item_cat===""){
      tostE("Please Select Item Category!!");
   }else if(values.item_name===null || values.item_name===undefined || values.item_name===""){
      tostE("Please Select Item!!");
   }else if(values.item_quan===null || values.item_quan===undefined || values.item_quan==="" || values.item_quan<1){
      tostE("Please Enter Valid Item Quantity!!");
   }else if(values.item_unit===null || values.item_unit===undefined || values.item_unit===""){
      tostE("Please Enter Item Unit!!");
   }else{
      const sendData = {
         item_type:values.item_type,
         item_name:values.item_name,
         item_cat:values.item_cat,
         item_unit:values.item_unit,
         item_quan:values.item_quan,
         entry_type:values.entry_type,
         site_id:values.site_id,
         sub_admin_id:values.sub_admin_id,
         remark:values.remark
      }
      console.log(sendData, "Send Data");
      dispatch(addStock(sendData));
      setValues({
         ...values,
         item_type:"",
         item_name:"",
         item_cat:"",
         item_unit:"",
         item_quan:"",
         site_id:"",
         item_cat_data:[],
         item_name_data:[]
      })
   }
  }

  return (
    <>
      <Page className={classes.root} title="Material Supply">
      <ToastContainer    />
         <Container maxWidth="lg">
            <Grid container spacing={3}>
               <Grid item md={12} lg={12} xs={12}>
                  <Card>
                     <CardHeader title ="Add New Item"/>
                     <CardContent>
                     <Grid container spacing={3}>
            
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Site</InputLabel>
                              <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.site_id}
                              onChange={handleChangeSite}
                              label="Site"
                              style={{margin:0}}
                              >
                              <MenuItem value=""><em>None</em></MenuItem>
                              {SiteData.map((data , i)=>
                                 <MenuItem key={i}  value={data.site_id}>{data.site_name}</MenuItem>
                              )}
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Item Type*</InputLabel>
                              <Select
                                 fullWidth
                                 labelId="demo-simple-select-outlined-label"
                                 id="demo-simple-select-outlined"
                                 label="Item Type"
                                 value={values.item_type}
                                 onChange={(e) => {handleType(e)}}
                              >
                                 <MenuItem value="Material">Material</MenuItem>
                                 <MenuItem value="Machine">Machine</MenuItem>
                              </Select>
                           </FormControl>
                        </Grid>                        
                     
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Item Category*</InputLabel>
                              <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.item_cat}
                              onChange={(e) => {handleChangeCategory(e)}}
                              label="Item Category"
                              style={{margin:0}}
                              >                              
                              {values.item_cat_data.map((data , i)=>
                                 <MenuItem key={i}  value={data.value}>{data.label}</MenuItem>
                              )}
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                           <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Item Name*</InputLabel>
                              <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.item_name}
                              onChange={(e) => {handleChangeName(e)}}
                              label="Item Name"
                              style={{margin:0}}
                              >                              
                              {values.item_name_data.map((data , i)=>
                                 <MenuItem key={i}  value={data.value}>{data.label}</MenuItem>
                              )}
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                        <TextField
                           fullWidth
                           label="Quantity"
                           name="item_quan"
                           type="number"
                           InputProps={{inputProps:{min:1}}}                           
                           value={values.item_quan}
                           required
                           onChange={handleChange}
                           variant="outlined"
                        />
                        </Grid>
                        {values.item_type==="Material" ? 
                           <Grid item md={6} xs={12}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                 <InputLabel id="demo-simple-select-outlined-label">Unit*</InputLabel>
                                 <Select
                                 labelId="demo-simple-select-outlined-label"
                                 id="demo-simple-select-outlined"
                                 value={values.item_unit}
                                 onChange={handleUnitChange}
                                 label="Unit"
                                 style={{margin:0}}
                                 >
                                 
                                 {UnitData.map((data , i)=>
                                    <MenuItem key={i}  value={data.sub_unit_id}>{data.unit_name}</MenuItem>
                                 )}
                                 </Select>
                              </FormControl>
                           </Grid>
                        :<></>
                        }
                     </Grid>
                     </CardContent>
                     <Divider />
                     <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>
                           Add Item
                        </Button>
                     </Box>
                  </Card>
               </Grid>
            </Grid>

         </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
