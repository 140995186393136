// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor3";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {getSuperTickBegin , getSuperTickSuccess , getSuperTickFailure } from './../ActionCreators';
import {delSuperTickBegin , delSuperTickSuccess , delSuperTickFailure } from './../ActionCreators'
import {editSuperTickBegin , editSuperTickSuccess , editSuperTickFailure } from './../ActionCreators'





export const getTickets = () => {

    return (dispatch) => {
        dispatch(getSuperTickBegin());

        axios.get(Base_URL + '/superadmin/gettickets')
            .then((res) => {
                dispatch(getSuperTickSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSuperTickFailure(err.response.data));

                }
            })
    }
}


export const delTickets = (x) => {

    return (dispatch) => {
        dispatch(delSuperTickBegin());


        axios.get(Base_URL + '/superadmin/delTicket' , {params :{t_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delSuperTickSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delSuperTickFailure(err.response.data));

                }
            })
    }
}


export const editTickets = (session) => {
    // console.log(session);
    return (dispatch) => {
        dispatch(editSuperTickBegin());


        axios.post(Base_URL + '/superAdmin/editticket', session)

            .then((res) => {
                // console.log(res.data)
                dispatch(editSuperTickSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editSuperTickFailure(err.response.data));

                }
            })
    }
}








