
import {GET_STOCK_SUMMARY_LOADING , GET_STOCK_SUMMARY_SUCCESS , GET_STOCK_SUMMARY_FAILURE} from '../../Actions/Inventory/Types';
import {GET_CONSUMED_DATA_LOADING , GET_CONSUMED_DATA_SUCCESS , GET_CONSUMED_DATA_FAILURE} from '../../Actions/Inventory/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const History = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            consumedData: [{}],
            consumedError:{},
            getData:{},
            getError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                loading:false,
                consumedData: [{}],
                consumedError:{},
                getData:[{}],
                getError:{}
                
            }
    
        
        case GET_STOCK_SUMMARY_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : {},
                
            }
        case GET_STOCK_SUMMARY_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
            }
        case GET_STOCK_SUMMARY_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        
        
        case GET_CONSUMED_DATA_LOADING:
            return {
                ...state,
                loading:true,
                consumedError: {},
                consumedData : {},
                
            }
        case GET_CONSUMED_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                consumedError:{},
                consumedData:action.data,
            }
        case GET_CONSUMED_DATA_FAILURE:
            return {
                ...state,
                loading:false,
                consumedError:action.error,
                consumedData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default History;
    