import { ADD_BOQ_FAILURE, ADD_BOQ_SUCCESS, DAILYREPORT_FAILURE, DAILYREPORT_LOADING, DAILYREPORT_SUCCESS, GETBOQ_FAILURE, GETBOQ_LOADING, GETBOQ_SUCCESS, GET_Report_FAILURE, GET_Report_LOADING, GET_Report_SUCCESS, MONTHLYREPORT_FAILURE, MONTHLYREPORT_LOADING, MONTHLYREPORT_SUCCESS, SELECTED_SITE } from './Types';

// sUB ADMIN dATE fILTER iMPORT
import { MAT_IND_START_DATE, MAT_IND_END_DATE } from './Types';
import { MAC_IND_START_DATE, MAC_IND_END_DATE } from './Types';
import { MAT_DEL_START_DATE, MAT_DEL_END_DATE } from './Types';
import { MAT_SUPP_START_DATE, MAT_SUPP_END_DATE } from './Types';
import { MAN_REPO_START_DATE, MAN_REPO_END_DATE } from './Types';
import { MAC_REPO_START_DATE, MAC_REPO_END_DATE } from './Types';
import { SITE_EXP_START_DATE, SITE_EXP_END_DATE } from './Types';
import { QS_START_DATE, QS_END_DATE } from './Types';
import { SITE_PROGRESS_START_DATE, SITE_PROGRESS_END_DATE } from './Types';

// cLIENT dATE fILTER iMPORTS

import { CLIENT_MAT_DEL_START_DATE, CLIENT_MAT_DEL_END_DATE } from './Types';
import { CLIENT_MAN_REPO_START_DATE, CLIENT_MAN_REPO_END_DATE } from './Types';
import { CLIENT_MAC_REPO_START_DATE, CLIENT_MAC_REPO_END_DATE } from './Types';
import { CLIENT_SITE_EXP_START_DATE, CLIENT_SITE_EXP_END_DATE } from './Types';
import { CLIENT_SITE_PROGRESS_START_DATE, CLIENT_SITE_PROGRESS_END_DATE } from './Types';



import {DEMO_LOGIN_USER_LOADING , DEMO_LOGIN_USER_SUCCESS , DEMO_LOGIN_USER_FAILURE } from './Types' ;
import {SUB_DASHBOARD_LOADING , SUB_DASHBOARD_SUCCESS , SUB_DASHBOARD_FAILURE } from './Types' ;
import {SUPER_DASHBOARD_LOADING , SUPER_DASHBOARD_SUCCESS , SUPER_DASHBOARD_FAILURE } from './Types' ;
import {LOGIN_LOADING , LOGIN_SUCCESS , LOGIN_FAILURE, CLEAN_LOGIN_MSG } from './Types' ;
import {REFRESH_CLIENT_LOADING , REFRESH_CLIENT_SUCCESS , REFRESH_CLIENT_FAILURE } from './Types' ;
import {REFRESH_SUBADMIN_LOADING , REFRESH_SUBADMIN_SUCCESS , REFRESH_SUBADMIN_FAILURE } from './Types' ;
import {REFRESH_SUPERADMIN_LOADING , REFRESH_SUPERADMIN_SUCCESS , REFRESH_SUPERADMIN_FAILURE } from './Types' ;
import {LOGOUT_LOADING , LOGOUT_SUCCESS , LOGOUT_FAILURE } from './Types' ;



import {GEN_OTP_LOADING , GEN_OTP_SUCCESS , GEN_OTP_FAILURE } from './Types' ;
import {VERIFY_OTP_LOADING , VERIFY_OTP_SUCCESS , VERIFY_OTP_FAILURE } from './Types' ;
import {RESET_PASS_LOADING , RESET_PASS_SUCCESS , RESET_PASS_FAILURE, CLEAN_RESET_PASS_DATA } from './Types' ;

import {OTP_EMAIL } from './Types' ;

import {CLEAN_ALL_DATA } from './Types' ;

import {ADD_CLIENTS_LOADING , ADD_CLIENTS_SUCCESS , ADD_CLIENTS_FAILURE, CLEAN_CLIENTS_DATA} from './Types' ;
import {GET_CLIENTS_LOADING , GET_CLIENTS_SUCCESS , GET_CLIENTS_FAILURE} from './Types' ;
import {EDIT_CLIENTS_LOADING , EDIT_CLIENTS_SUCCESS , EDIT_CLIENTS_FAILURE} from './Types' ;
import {EDIT_CLIENTS_PASS_LOADING , EDIT_CLIENTS_PASS_SUCCESS , EDIT_CLIENTS_PASS_FAILURE} from './Types' ;
import {DELETE_CLIENTS_LOADING , DELETE_CLIENTS_SUCCESS , DELETE_CLIENTS_FAILURE} from './Types' ;
import {GET_BACKUP_LOADING , GET_BACKUP_SUCCESS , GET_BACKUP_FAILURE} from './Types' ;

import {GET_ROLES_LOADING , GET_ROLES_SUCCESS , GET_ROLES_FAILURE} from './Types' ;
import {ADD_ROLES_LOADING , ADD_ROLES_SUCCESS , ADD_ROLES_FAILURE} from './Types' ;
import {EDIT_ROLES_LOADING , EDIT_ROLES_SUCCESS , EDIT_ROLES_FAILURE} from './Types' ;
import {DEL_ROLES_LOADING , DEL_ROLES_SUCCESS , DEL_ROLES_FAILURE} from './Types' ;


import {ADD_PLAN_LOADING , ADD_PLAN_SUCCESS , ADD_PLAN_FAILURE} from './Types' ;
import {GET_PLAN_LOADING , GET_PLAN_SUCCESS , GET_PLAN_FAILURE, CLEAN_PLAN_DATA} from './Types' ;
import {EDIT_PLAN_LOADING , EDIT_PLAN_SUCCESS , EDIT_PLAN_FAILURE} from './Types' ;
import {DEL_PLAN_LOADING , DEL_PLAN_SUCCESS , DEL_PLAN_FAILURE} from './Types' ;

import {GET_PANEL_USER_LOADING , GET_PANEL_USER_SUCCESS , GET_PANEL_USER_FAILURE , CLEAN_PANEL_USER_DATA} from './Types' ;
import {ADD_PANEL_USER_LOADING , ADD_PANEL_USER_SUCCESS , ADD_PANEL_USER_FAILURE} from './Types' ;
import {DEL_PANEL_USER_LOADING , DEL_PANEL_USER_SUCCESS , DEL_PANEL_USER_FAILURE} from './Types' ;

import {GET_SUPER_ENQ_LOADING , GET_SUPER_ENQ_SUCCESS , GET_SUPER_ENQ_FAILURE} from './Types' ;

import {GET_SUPER_TICK_LOADING , GET_SUPER_TICK_SUCCESS , GET_SUPER_TICK_FAILURE, CLEAN_SUPER_TICK_DATA} from './Types' ;
import {DEL_SUPER_TICK_LOADING , DEL_SUPER_TICK_SUCCESS , DEL_SUPER_TICK_FAILURE} from './Types' ;
import {EDIT_SUPER_TICK_LOADING , EDIT_SUPER_TICK_SUCCESS , EDIT_SUPER_TICK_FAILURE} from './Types' ;

import {GET_SUB_TICK_LOADING , GET_SUB_TICK_SUCCESS , GET_SUB_TICK_FAILURE, CLEAN_SUB_TICK_DATA} from './Types' ;
import {GET_ALL_TICK_LOADING , GET_ALL_TICK_SUCCESS , GET_ALL_TICK_FAILURE} from './Types' ;
import {ADD_SUB_TICK_LOADING , ADD_SUB_TICK_SUCCESS , ADD_SUB_TICK_FAILURE} from './Types' ;
import {EDIT_SUB_TICK_LOADING , EDIT_SUB_TICK_SUCCESS , EDIT_SUB_TICK_FAILURE} from './Types' ;
import {DEL_SUB_TICK_LOADING , DEL_SUB_TICK_SUCCESS , DEL_SUB_TICK_FAILURE} from './Types' ;
import {DEL_TICKET_LOADING , DEL_TICKET_SUCCESS , DEL_TICKET_FAILURE} from './Types' ;


import {ADD_APP_USER_MGMT_LOADING , ADD_APP_USER_MGMT_SUCCESS , ADD_APP_USER_MGMT_FAILURE, CLEAN_APP_USER_MGMT_DATA} from './Types' ;
import {GET_APP_USER_MGMT_LOADING , GET_APP_USER_MGMT_SUCCESS , GET_APP_USER_MGMT_FAILURE} from './Types' ;
import {EDIT_APP_USER_MGMT_LOADING , EDIT_APP_USER_MGMT_SUCCESS , EDIT_APP_USER_MGMT_FAILURE} from './Types' ;
import {DEL_APP_USER_MGMT_LOADING , DEL_APP_USER_MGMT_SUCCESS , DEL_APP_USER_MGMT_FAILURE} from './Types' ;

import {ADD_SUB_ROLE_LOADING , ADD_SUB_ROLE_SUCCESS , ADD_SUB_ROLE_FAILURE, CLEAN_SUB_ROLE_DATA} from './Types' ;
import {GET_SUB_ROLE_LOADING , GET_SUB_ROLE_SUCCESS , GET_SUB_ROLE_FAILURE} from './Types' ;
import {EDIT_SUB_ROLE_LOADING , EDIT_SUB_ROLE_SUCCESS , EDIT_SUB_ROLE_FAILURE} from './Types' ;
import {DEL_SUB_ROLE_LOADING , DEL_SUB_ROLE_SUCCESS , DEL_SUB_ROLE_FAILURE} from './Types' ;


import {GET_GST_LOADING , GET_GST_SUCCESS , GET_GST_FAILURE} from './Types' ;
import {GET_SUB_GST_LOADING , GET_SUB_GST_SUCCESS , GET_SUB_GST_FAILURE} from './Types' ;
import {ADD_GST_LOADING , ADD_GST_SUCCESS , ADD_GST_FAILURE , CLEAN_GST_DATA} from './Types' ;
import {EDIT_GST_LOADING , EDIT_GST_SUCCESS , EDIT_GST_FAILURE } from './Types' ;
import {DEL_GST_LOADING , DEL_GST_SUCCESS , DEL_GST_FAILURE , CLEAN_ROLES_DATA} from './Types' ;

import {GET_DRAWING_FLOOR_TYPE_LOADING , GET_DRAWING_FLOOR_TYPE_SUCCESS , GET_DRAWING_FLOOR_TYPE_FAILURE} from './Types' ;
import {ADD_DRAWING_FLOOR_TYPE_LOADING , ADD_DRAWING_FLOOR_TYPE_SUCCESS , ADD_DRAWING_FLOOR_TYPE_FAILURE , CLEAN_DRAWING_FLOOR_TYPE_DATA} from './Types' ;
import {EDIT_DRAWING_FLOOR_TYPE_LOADING , EDIT_DRAWING_FLOOR_TYPE_SUCCESS , EDIT_DRAWING_FLOOR_TYPE_FAILURE } from './Types' ;
import {DEL_DRAWING_FLOOR_TYPE_LOADING , DEL_DRAWING_FLOOR_TYPE_SUCCESS , DEL_DRAWING_FLOOR_TYPE_FAILURE } from './Types' ;

//Demo

import {ADD_SITES_LOADING , ADD_SITES_SUCCESS , ADD_SITES_FAILURE , CLEAN_SITES_DATA} from './Types';
import {GET_SITES_LOADING , GET_SITES_SUCCESS , GET_SITES_FAILURE} from './Types';
import {GET_SITES_MASTER_LOADING , GET_SITES_MASTER_SUCCESS , GET_SITES_MASTER_FAILURE} from './Types';
import {DEL_SITES_LOADING , DEL_SITES_SUCCESS , DEL_SITES_FAILURE} from './Types';
import {EDIT_SITES_LOADING , EDIT_SITES_SUCCESS , EDIT_SITES_FAILURE} from './Types';


import {GET_SUPER_PROFILE_DETAIL_LOADING , GET_SUPER_PROFILE_DETAIL_SUCCESS , GET_SUPER_PROFILE_DETAIL_FAILURE, CLEAN_SUPER_PROFILE_DETAIL_DATA} from './Types';
import {EDIT_SUPER_PROFILE_DETAIL_LOADING , EDIT_SUPER_PROFILE_DETAIL_SUCCESS , EDIT_SUPER_PROFILE_DETAIL_FAILURE} from './Types';
import {EDIT_SUPER_PROFILE_PASSWORD_LOADING , EDIT_SUPER_PROFILE_PASSWORD_SUCCESS , EDIT_SUPER_PROFILE_PASSWORD_FAILURE} from './Types';
import {EDIT_SUPER_PROFILE_PIC_LOADING , EDIT_SUPER_PROFILE_PIC_SUCCESS , EDIT_SUPER_PROFILE_PIC_FAILURE} from './Types';

import {ADD_SITE_EXPENSE_LOADING , ADD_SITE_EXPENSE_SUCCESS , ADD_SITE_EXPENSE_FAILURE , CLEAN_SITE_EXPENSE_DATA} from './Types';
import {GET_SITE_EXPENSE_LOADING , GET_SITE_EXPENSE_SUCCESS , GET_SITE_EXPENSE_FAILURE} from './Types';
import {DEL_SITE_EXPENSE_LOADING , DEL_SITE_EXPENSE_SUCCESS , DEL_SITE_EXPENSE_FAILURE} from './Types';
import {EDIT_SITE_EXPENSE_LOADING , EDIT_SITE_EXPENSE_SUCCESS , EDIT_SITE_EXPENSE_FAILURE} from './Types';

import {ADD_DRAW_LOADING , ADD_DRAW_SUCCESS , ADD_DRAW_FAILURE , CLEAN_DRAW_DATA} from './Types';
import {GET_DRAW_LOADING , GET_DRAW_SUCCESS , GET_DRAW_FAILURE} from './Types';
import {DEL_DRAW_LOADING , DEL_DRAW_SUCCESS , DEL_DRAW_FAILURE} from './Types';
import {EDIT_DRAW_LOADING , EDIT_DRAW_SUCCESS , EDIT_DRAW_FAILURE} from './Types';

import {ADD_DRAW_TYPE_LOADING , ADD_DRAW_TYPE_SUCCESS , ADD_DRAW_TYPE_FAILURE , CLEAN_DRAW_TYPE_DATA} from './Types';
import {GET_DRAW_TYPE_LOADING , GET_DRAW_TYPE_SUCCESS , GET_DRAW_TYPE_FAILURE} from './Types';
import {DEL_DRAW_TYPE_LOADING , DEL_DRAW_TYPE_SUCCESS , DEL_DRAW_TYPE_FAILURE} from './Types';
import {EDIT_DRAW_TYPE_LOADING , EDIT_DRAW_TYPE_SUCCESS , EDIT_DRAW_TYPE_FAILURE} from './Types';

import {ADD_SITE_DRAW_LOADING , ADD_SITE_DRAW_SUCCESS , ADD_SITE_DRAW_FAILURE , CLEAN_SITE_DRAW_DATA} from './Types';
import {GET_SITE_DRAW_LOADING , GET_SITE_DRAW_SUCCESS , GET_SITE_DRAW_FAILURE} from './Types';
import {DEL_SITE_DRAW_LOADING , DEL_SITE_DRAW_SUCCESS , DEL_SITE_DRAW_FAILURE} from './Types';
import {EDIT_SITE_DRAW_LOADING , EDIT_SITE_DRAW_SUCCESS , EDIT_SITE_DRAW_FAILURE} from './Types';

import {ADD_MANPOWER_CATEGORY_LOADING , ADD_MANPOWER_CATEGORY_SUCCESS , ADD_MANPOWER_CATEGORY_FAILURE , CLEAN_MANPOWER_CATEGORY_DATA} from './Types';
import {GET_MANPOWER_CATEGORY_LOADING , GET_MANPOWER_CATEGORY_SUCCESS , GET_MANPOWER_CATEGORY_FAILURE} from './Types';
import {DEL_MANPOWER_CATEGORY_LOADING , DEL_MANPOWER_CATEGORY_SUCCESS , DEL_MANPOWER_CATEGORY_FAILURE} from './Types';
import {EDIT_MANPOWER_CATEGORY_LOADING , EDIT_MANPOWER_CATEGORY_SUCCESS , EDIT_MANPOWER_CATEGORY_FAILURE} from './Types';

import {ADD_MATERIAL_CATEGORY_LOADING , ADD_MATERIAL_CATEGORY_SUCCESS , ADD_MATERIAL_CATEGORY_FAILURE ,CLEAN_MATERIAL_CATEGORY_DATA} from './Types';
import {GET_MATERIAL_CATEGORY_LOADING , GET_MATERIAL_CATEGORY_SUCCESS , GET_MATERIAL_CATEGORY_FAILURE} from './Types';
import {DEL_MATERIAL_CATEGORY_LOADING , DEL_MATERIAL_CATEGORY_SUCCESS , DEL_MATERIAL_CATEGORY_FAILURE} from './Types';
import {EDIT_MATERIAL_CATEGORY_LOADING , EDIT_MATERIAL_CATEGORY_SUCCESS , EDIT_MATERIAL_CATEGORY_FAILURE} from './Types';

import {ADD_MANPOWER_MAN_LOADING , ADD_MANPOWER_MAN_SUCCESS , ADD_MANPOWER_MAN_FAILURE , CLEAN_MANPOWER_MAN_DATA} from './Types';
import {GET_MANPOWER_MAN_LOADING , GET_MANPOWER_MAN_SUCCESS , GET_MANPOWER_MAN_FAILURE} from './Types';
import {DEL_MANPOWER_MAN_LOADING , DEL_MANPOWER_MAN_SUCCESS , DEL_MANPOWER_MAN_FAILURE} from './Types';
import {EDIT_MANPOWER_MAN_LOADING , EDIT_MANPOWER_MAN_SUCCESS , EDIT_MANPOWER_MAN_FAILURE} from './Types';

import {ADD_MATERIAL_MAT_LOADING , ADD_MATERIAL_MAT_SUCCESS , ADD_MATERIAL_MAT_FAILURE ,CLEAN_MATERIAL_MAT_DATA} from './Types';
import {GET_MATERIAL_MAT_LOADING , GET_MATERIAL_MAT_SUCCESS , GET_MATERIAL_MAT_FAILURE} from './Types';
import {DEL_MATERIAL_MAT_LOADING , DEL_MATERIAL_MAT_SUCCESS , DEL_MATERIAL_MAT_FAILURE} from './Types';
import {EDIT_MATERIAL_MAT_LOADING , EDIT_MATERIAL_MAT_SUCCESS , EDIT_MATERIAL_MAT_FAILURE} from './Types';

import {ACCEPT_MAT_INDENT_LOADING , ACCEPT_MAT_INDENT_SUCCESS , ACCEPT_MAT_INDENT_FAILURE} from './Types';
import {GET_MATERIAL_INDENT_LOADING , GET_MATERIAL_INDENT_SUCCESS , GET_MATERIAL_INDENT_FAILURE, CLEAN_MATERIAL_INDENT_DATA} from './Types';
import {DEL_MATERIAL_INDENT_LOADING , DEL_MATERIAL_INDENT_SUCCESS , DEL_MATERIAL_INDENT_FAILURE} from './Types';
import {ORDER_MATERIAL_INDENT_LOADING , ORDER_MATERIAL_INDENT_SUCCESS , ORDER_MATERIAL_INDENT_FAILURE} from './Types';
import {EDIT_MATERIAL_INDENT_LOADING , EDIT_MATERIAL_INDENT_SUCCESS , EDIT_MATERIAL_INDENT_FAILURE} from './Types';


import {ACCEPT_MAC_INDENT_LOADING , ACCEPT_MAC_INDENT_SUCCESS , ACCEPT_MAC_INDENT_FAILURE} from './Types';
import {GET_MACHINE_INDENT_LOADING , GET_MACHINE_INDENT_SUCCESS , GET_MACHINE_INDENT_FAILURE, CLEAN_MACHINE_INDENT_DATA} from './Types';
import {DEL_MACHINE_INDENT_LOADING , DEL_MACHINE_INDENT_SUCCESS , DEL_MACHINE_INDENT_FAILURE} from './Types';
import {ORDER_MACHINE_INDENT_LOADING , ORDER_MACHINE_INDENT_SUCCESS , ORDER_MACHINE_INDENT_FAILURE} from './Types';
import {EDIT_MACHINE_INDENT_LOADING , EDIT_MACHINE_INDENT_SUCCESS , EDIT_MACHINE_INDENT_FAILURE} from './Types';

import {GET_MACHINE_DELIVERY_LOADING , GET_MACHINE_DELIVERY_SUCCESS , GET_MACHINE_DELIVERY_FAILURE, CLEAN_MACHINE_DELIVERY_DATA} from './Types';
import {DEL_MACHINE_DELIVERY_LOADING , DEL_MACHINE_DELIVERY_SUCCESS , DEL_MACHINE_DELIVERY_FAILURE} from './Types';

import {GET_MATERIAL_SUPPLY_LOADING , GET_MATERIAL_SUPPLY_SUCCESS , GET_MATERIAL_SUPPLY_FAILURE, CLEAN_MATERIAL_SUPPLY_DATA} from './Types';
import {DEL_MATERIAL_SUPPLY_LOADING , DEL_MATERIAL_SUPPLY_SUCCESS , DEL_MATERIAL_SUPPLY_FAILURE} from './Types';

import {GET_MATERIAL_DELIVERY_LOADING , GET_MATERIAL_DELIVERY_SUCCESS , GET_MATERIAL_DELIVERY_FAILURE, CLEAN_MATERIAL_DELIVERY_DATA} from './Types';
import {DEL_MATERIAL_DELIVERY_LOADING , DEL_MATERIAL_DELIVERY_SUCCESS , DEL_MATERIAL_DELIVERY_FAILURE} from './Types';

import {GET_CASH_EXPENSE_LOADING , GET_CASH_EXPENSE_SUCCESS , GET_CASH_EXPENSE_FAILURE, CLEAN_CASH_EXPENSE_DATA} from './Types';
import {DEL_CASH_EXPENSE_LOADING , DEL_CASH_EXPENSE_SUCCESS , DEL_CASH_EXPENSE_FAILURE} from './Types';

import {GET_SITE_MAN_LOADING , GET_SITE_MAN_SUCCESS , GET_SITE_MAN_FAILURE, CLEAN_SITE_MAN_DATA} from './Types';
import {DEL_SITE_MAN_LOADING , DEL_SITE_MAN_SUCCESS , DEL_SITE_MAN_FAILURE} from './Types';

import {GET_QUALITY_SAFETY_LOADING , GET_QUALITY_SAFETY_SUCCESS , GET_QUALITY_SAFETY_FAILURE, CLEAN_QUALITY_SAFETY_DATA} from './Types';
import {DEL_QUALITY_SAFETY_LOADING , DEL_QUALITY_SAFETY_SUCCESS , DEL_QUALITY_SAFETY_FAILURE} from './Types';

import {GET_SITE_PROGRESS_LOADING , GET_SITE_PROGRESS_SUCCESS , GET_SITE_PROGRESS_FAILURE, CLEAN_SITE_PROGRESS_DATA} from './Types';
import {DEL_SITE_PROGRESS_LOADING , DEL_SITE_PROGRESS_SUCCESS , DEL_SITE_PROGRESS_FAILURE} from './Types';

import {ADD_VENDOR_LOADING , ADD_VENDOR_SUCCESS , ADD_VENDOR_FAILURE , CLEAN_VENDOR_DATA} from './Types';
import {GET_VENDOR_LOADING , GET_VENDOR_SUCCESS , GET_VENDOR_FAILURE } from './Types';
import {EDIT_VENDOR_LOADING , EDIT_VENDOR_SUCCESS , EDIT_VENDOR_FAILURE } from './Types';
import {DEL_VENDOR_LOADING , DEL_VENDOR_SUCCESS , DEL_VENDOR_FAILURE } from './Types';


import {ADD_RESOURCE_UNIT_LOADING , ADD_RESOURCE_UNIT_SUCCESS , ADD_RESOURCE_UNIT_FAILURE , CLEAN_RESOURCE_UNIT_DATA} from './Types';
import {GET_RESOURCE_UNIT_LOADING , GET_RESOURCE_UNIT_SUCCESS , GET_RESOURCE_UNIT_FAILURE} from './Types';
import {DEL_RESOURCE_UNIT_LOADING , DEL_RESOURCE_UNIT_SUCCESS , DEL_RESOURCE_UNIT_FAILURE} from './Types';
import {EDIT_RESOURCE_UNIT_LOADING , EDIT_RESOURCE_UNIT_SUCCESS , EDIT_RESOURCE_UNIT_FAILURE} from './Types';

import {ADD_RESOURCE_MACHINE_LOADING , ADD_RESOURCE_MACHINE_SUCCESS , ADD_RESOURCE_MACHINE_FAILURE , CLEAN_RESOURCE_MACHINE_DATA} from './Types';
import {GET_RESOURCE_MACHINE_LOADING , GET_RESOURCE_MACHINE_SUCCESS , GET_RESOURCE_MACHINE_FAILURE} from './Types';
import {DEL_RESOURCE_MACHINE_LOADING , DEL_RESOURCE_MACHINE_SUCCESS , DEL_RESOURCE_MACHINE_FAILURE} from './Types';
import {EDIT_RESOURCE_MACHINE_LOADING , EDIT_RESOURCE_MACHINE_SUCCESS , EDIT_RESOURCE_MACHINE_FAILURE} from './Types';

import {ADD_MACHINE_CAT_LOADING , ADD_MACHINE_CAT_SUCCESS , ADD_MACHINE_CAT_FAILURE , CLEAN_MACHINE_CAT_DATA} from './Types';
import {GET_MACHINE_CAT_LOADING , GET_MACHINE_CAT_SUCCESS , GET_MACHINE_CAT_FAILURE} from './Types';
import {DEL_MACHINE_CAT_LOADING , DEL_MACHINE_CAT_SUCCESS , DEL_MACHINE_CAT_FAILURE} from './Types';
import {EDIT_MACHINE_CAT_LOADING , EDIT_MACHINE_CAT_SUCCESS , EDIT_MACHINE_CAT_FAILURE} from './Types';


import {ADD_RESOURCE_QUALITY_LOADING , ADD_RESOURCE_QUALITY_SUCCESS , ADD_RESOURCE_QUALITY_FAILURE , CLEAN_RESOURCE_QUALITY_DATA} from './Types'
import {GET_RESOURCE_QUALITY_LOADING , GET_RESOURCE_QUALITY_SUCCESS , GET_RESOURCE_QUALITY_FAILURE } from './Types'
import {DEL_RESOURCE_QUALITY_LOADING , DEL_RESOURCE_QUALITY_SUCCESS , DEL_RESOURCE_QUALITY_FAILURE } from './Types'
import {EDIT_RESOURCE_QUALITY_LOADING , EDIT_RESOURCE_QUALITY_SUCCESS , EDIT_RESOURCE_QUALITY_FAILURE } from './Types'

import {ADD_FAQ_LOADING , ADD_FAQ_SUCCESS , ADD_FAQ_FAILURE , CLEAN_FAQ_DATA} from './Types'
import {GET_FAQ_LOADING , GET_FAQ_SUCCESS , GET_FAQ_FAILURE } from './Types'
import {DEL_FAQ_LOADING , DEL_FAQ_SUCCESS , DEL_FAQ_FAILURE } from './Types'
import {EDIT_FAQ_LOADING , EDIT_FAQ_SUCCESS , EDIT_FAQ_FAILURE } from './Types'

import {GET_SUB_FAQ_LOADING , GET_SUB_FAQ_SUCCESS , GET_SUB_FAQ_FAILURE} from './Types'


import {ADD_PURCHASE_ORDER_LOADING , ADD_PURCHASE_ORDER_SUCCESS , ADD_PURCHASE_ORDER_FAILURE, CLEAN_PURCHASE_ORDER_DATA} from './Types'
import {GET_PURCHASE_ORDER_LOADING , GET_PURCHASE_ORDER_SUCCESS , GET_PURCHASE_ORDER_FAILURE} from './Types'
import {DEL_PURCHASE_ORDER_LOADING , DEL_PURCHASE_ORDER_SUCCESS , DEL_PURCHASE_ORDER_FAILURE} from './Types'

import { ADD_OTHER_SUB_ADMIN_LOADING , ADD_OTHER_SUB_ADMIN_SUCCESS , ADD_OTHER_SUB_ADMIN_FAILURE , CLEAN_OTHER_SUB_ADMIN_DATA} from './Types';
import { GET_OTHER_SUB_ADMIN_LOADING , GET_OTHER_SUB_ADMIN_SUCCESS , GET_OTHER_SUB_ADMIN_FAILURE } from './Types';
import { EDIT_OTHER_SUB_ADMIN_LOADING , EDIT_OTHER_SUB_ADMIN_SUCCESS , EDIT_OTHER_SUB_ADMIN_FAILURE } from './Types';
import { DEL_OTHER_SUB_ADMIN_LOADING , DEL_OTHER_SUB_ADMIN_SUCCESS , DEL_OTHER_SUB_ADMIN_FAILURE } from './Types';
import { BLOCK_OTHER_SUB_ADMIN_LOADING , BLOCK_OTHER_SUB_ADMIN_SUCCESS , BLOCK_OTHER_SUB_ADMIN_FAILURE } from './Types';

import {EDIT_PROFILE_MAN_CRE_ADMIN_LOADING , EDIT_PROFILE_MAN_CRE_ADMIN_SUCCESS , EDIT_PROFILE_MAN_CRE_ADMIN_FAILURE} from './Types';
import {EDIT_PROFILE_MAN_PASS_ADMIN_LOADING , EDIT_PROFILE_MAN_PASS_ADMIN_SUCCESS , EDIT_PROFILE_MAN_PASS_ADMIN_FAILURE} from './Types';
import {EDIT_PROFILE_MAN_PIC_ADMIN_LOADING , EDIT_PROFILE_MAN_PIC_ADMIN_SUCCESS , EDIT_PROFILE_MAN_PIC_ADMIN_FAILURE} from './Types';
import {EDIT_SUB_LOGO_LOADING , EDIT_SUB_LOGO_SUCCESS , EDIT_SUB_LOGO_FAILURE} from './Types';
import {GET_PROFILE_MAN_CRE_ADMIN_LOADING , GET_PROFILE_MAN_CRE_ADMIN_SUCCESS , GET_PROFILE_MAN_CRE_ADMIN_FAILURE , CLEAN_PROFILE_MAN_CRE_ADMIN_DATA} from './Types';



import {CLIENT_NEW_MATERIAL_LOADING , 
        CLIENT_NEW_MATERIAL_SUCCESS , 
        CLIENT_NEW_MATERIAL_FAILURE} from './Types'
import {CLIENT_MACHINE_REPORT_LOADING,
        CLIENT_MACHINE_REPORT_SUCCESS,
        CLIENT_MACHINE_REPORT_FAILURE} from './Types'
import {CLIENT_MANPOWER_REPORT_LOADING,
        CLIENT_MANPOWER_REPORT_SUCCESS,
        CLIENT_MANPOWER_REPORT_FAILURE} from './Types'
import {CLIENT_SITE_EXPENSE_LOADING,
        CLIENT_SITE_EXPENSE_SUCCESS,
        CLIENT_SITE_EXPENSE_FAILURE} from './Types'
import {CLIENT_SITE_PROGRESS_LOADING,
        CLIENT_SITE_PROGRESS_SUCCESS,
        CLIENT_SITE_PROGRESS_FAILURE} from './Types'    
import {CLIENT_ADD_TICKET_LOADING,
        CLIENT_ADD_TICKET_SUCCESS,
        CLIENT_ADD_TICKET_FAILURE,
        CLIENT_GET_TICKET_LOADING,
        CLIENT_GET_TICKET_SUCCESS,
        CLIENT_GET_TICKET_FAILURE,
        CLEAN_TICKET_DATA} from './Types'
import {CLIENT_PROFILE_LOADING,
        CLIENT_PROFILE_SUCCESS,
        CLIENT_PROFILE_FAILURE,
        CLIENT_GET_PROFILE_LOADING,
        CLIENT_GET_PROFILE_SUCCESS,
        CLIENT_GET_PROFILE_FAILURE,
        CLEAN_PROFILE_DATA} from './Types'
import {CLIENT_PROFILE_PIC_LOADING,
        CLIENT_PROFILE_PIC_SUCCESS,
        CLIENT_PROFILE_PIC_FAILURE,
        CLEAN_PROFILE_PIC_DATA} from './Types'
import {CLIENT_SITE_DRAWINGS_LOADING,
        CLIENT_SITE_DRAWINGS_SUCCESS,
        CLIENT_SITE_DRAWINGS_FAILURE,
        CLEAN_SITE_DRAWINGS_DATA} from './Types'

import {CLIENT_FAQ_LOADING,
        CLIENT_FAQ_SUCCESS,
        CLIENT_FAQ_FAILURE} from './Types'

import {CHP_AUM_LOADING ,CHP_AUM_SUCCESS ,CHP_AUM_FAILURE , CLEAN_CHP_AUM_DATA} from './Types'

export const demoLoginBegin = () => ({
    type : DEMO_LOGIN_USER_LOADING
})

export const demoLoginSuccess = (logindata) => ({
    type : DEMO_LOGIN_USER_SUCCESS,
    logindata ,
})

export const demoLoginFailure = (loginerror) => ({
    type : DEMO_LOGIN_USER_FAILURE,
    loginerror
    
})

export const refreshClientBegin = () => ({
    type : REFRESH_CLIENT_LOADING
})

export const refreshClientSuccess = (data) => ({
    type : REFRESH_CLIENT_SUCCESS,
    data ,
})

export const refreshClientFailure = (error) => ({
    type : REFRESH_CLIENT_FAILURE,
    error
    
})

export const refreshSubAdminBegin = () => ({
    type : REFRESH_SUBADMIN_LOADING
})

export const refreshSubAdminSuccess = (data) => ({
    type : REFRESH_SUBADMIN_SUCCESS,
    data ,
})

export const refreshSubAdminFailure = (error) => ({
    type : REFRESH_SUBADMIN_FAILURE,
    error
    
})

export const refreshSuperAdminBegin = () => ({
    type : REFRESH_SUPERADMIN_LOADING
})

export const refreshSuperAdminSuccess = (data) => ({
    type : REFRESH_SUPERADMIN_SUCCESS,
    data ,
})

export const refreshSuperAdminFailure = (error) => ({
    type : REFRESH_SUPERADMIN_FAILURE,
    error
    
})

export const SubDashboardBegin = () => ({
    type : SUB_DASHBOARD_LOADING
})

export const SubDashboardSuccess = (data) => ({
    type : SUB_DASHBOARD_SUCCESS,
    data ,
})

export const SubDashboardFailure = (error) => ({
    type : SUB_DASHBOARD_FAILURE,
    error
    
})

export const SuperDashboardBegin = () => ({
    type : SUPER_DASHBOARD_LOADING
})

export const SuperDashboardSuccess = (data) => ({
    type : SUPER_DASHBOARD_SUCCESS,
    data ,
})

export const SuperDashboardFailure = (error) => ({
    type : SUPER_DASHBOARD_FAILURE,
    error
    
})


export const SelectedSite = (SiteData) =>({
    type:SELECTED_SITE,
    SiteData
})

// SUBADMIN dATE FILTERS
export const MatIndStartDate = (data) =>({
    type:MAT_IND_START_DATE,
    data
})

export const MatIndEndDate = (data) =>({
    type:MAT_IND_END_DATE,
    data
})
export const MacIndStartDate = (data) =>({
    type:MAC_IND_START_DATE,
    data
})

export const MacIndEndDate = (data) =>({
    type:MAC_IND_END_DATE,
    data
})
export const MatDelStartDate = (data) =>({
    type:MAT_DEL_START_DATE,
    data
})

export const MatDelEndDate = (data) =>({
    type:MAT_DEL_END_DATE,
    data
})
export const MatSuppStartDate = (data) =>({
    type:MAT_SUPP_START_DATE,
    data
})

export const MatSuppEndDate = (data) =>({
    type:MAT_SUPP_END_DATE,
    data
})
export const ManRepoStartDate = (data) =>({
    type:MAN_REPO_START_DATE,
    data
})

export const ManRepoEndDate = (data) =>({
    type:MAN_REPO_END_DATE,
    data
})
export const MacRepoStartDate = (data) =>({
    type:MAC_REPO_START_DATE,
    data
})

export const MacRepoEndDate = (data) =>({
    type:MAC_REPO_END_DATE,
    data
})
export const SiteExpStartDate = (data) =>({
    type:SITE_EXP_START_DATE,
    data
})

export const SiteExpEndDate = (data) =>({
    type:SITE_EXP_END_DATE,
    data
})
export const QsStartDate = (data) =>({
    type:QS_START_DATE,
    data
})

export const QsEndDate = (data) =>({
    type:QS_END_DATE,
    data
})
export const SiteProgressStartDate = (data) =>({
    type:SITE_PROGRESS_START_DATE,
    data
})

export const SiteProgressEndDate = (data) =>({
    type:SITE_PROGRESS_END_DATE,
    data
})





// cLIENT dATE fILTERS



export const ClientMatDelStartDate = (data) =>({
    type:CLIENT_MAT_DEL_START_DATE,
    data
})

export const ClientMatDelEndDate = (data) =>({
    type:CLIENT_MAT_DEL_END_DATE,
    data
})

export const ClientManRepoStartDate = (data) =>({
    type:CLIENT_MAN_REPO_START_DATE,
    data
})

export const ClientManRepoEndDate = (data) =>({
    type:CLIENT_MAN_REPO_END_DATE,
    data
})

export const ClientMacRepoStartDate = (data) =>({
    type:CLIENT_MAC_REPO_START_DATE,
    data
})

export const ClientMacRepoEndDate = (data) =>({
    type:CLIENT_MAC_REPO_END_DATE,
    data
})
export const ClientSiteExpStartDate = (data) =>({
    type:CLIENT_SITE_EXP_START_DATE,
    data
})

export const ClientSiteExpEndDate = (data) =>({
    type:CLIENT_SITE_EXP_END_DATE,
    data
})

export const ClientSiteProgressStartDate = (data) =>({
    type:CLIENT_SITE_PROGRESS_START_DATE,
    data
})

export const ClientSiteProgressEndDate = (data) =>({
    type:CLIENT_SITE_PROGRESS_END_DATE,
    data
})

export const loginBegin = () => ({
    type : LOGIN_LOADING
})

export const loginSuccess = (logindata) => ({
    type : LOGIN_SUCCESS,
    logindata ,
})

export const loginFailure = (loginerror) => ({
    type : LOGIN_FAILURE,
    loginerror
    
})


export const cleanLoginData = () => ({
    type : CLEAN_LOGIN_MSG
    
})



export const otpEmail = (data) => ({
    type : OTP_EMAIL,
    data
})




export const GenotpBegin = () => ({
    type : GEN_OTP_LOADING
})

export const GenotpSuccess = (data) => ({
    type : GEN_OTP_SUCCESS,
    data ,
})

export const GenotpFailure = (error) => ({
    type : GEN_OTP_FAILURE,
    error
    
})


export const verifyotpBegin = () => ({
    type : VERIFY_OTP_LOADING
})

export const verifyotpSuccess = (data) => ({
    type : VERIFY_OTP_SUCCESS,
    data ,
})

export const verifyotpFailure = (error) => ({
    type : VERIFY_OTP_FAILURE,
    error
    
})


export const resetPassBegin = () => ({
    type : RESET_PASS_LOADING
})

export const resetPassSuccess = (data) => ({
    type : RESET_PASS_SUCCESS,
    data ,
})

export const resetPassFailure = (error) => ({
    type : RESET_PASS_FAILURE,
    error
    
})






export const cleanResetPassData = () => ({
    type : CLEAN_RESET_PASS_DATA
    
})








export const logoutBegin = () => ({
    type : LOGOUT_LOADING
})

export const logoutSuccess = () => ({
    type : LOGOUT_SUCCESS
})

export const logoutFailure = () => ({
    type : LOGOUT_FAILURE
    
})




export const addPlanBegin = () => ({
    type : ADD_PLAN_LOADING
})

export const addPlanSuccess = (data) => ({
    type : ADD_PLAN_SUCCESS,
    data
})

export const addPlanFailure = (error) => ({
    type : ADD_PLAN_FAILURE,
    error
    
})

export const editPlanBegin = () => ({
    type : EDIT_PLAN_LOADING
})

export const editPlanSuccess = (data) => ({
    type : EDIT_PLAN_SUCCESS,
    data
})

export const editPlanFailure = (error) => ({
    type : EDIT_PLAN_FAILURE,
    error
    
})


export const getPlanBegin = () => ({
    type : GET_PLAN_LOADING
})

export const getPlanSuccess = (data) => ({
    type : GET_PLAN_SUCCESS,
    data
})

export const getPlanFailure = (error) => ({
    type : GET_PLAN_FAILURE,
    error
    
})

export const delPlanBegin = () => ({
    type : DEL_PLAN_LOADING
})

export const delPlanSuccess = (data) => ({
    type : DEL_PLAN_SUCCESS,
    data
})

export const delPlanFailure = (error) => ({
    type : DEL_PLAN_FAILURE,
    error
    
})

export const cleanPlanData = () => ({
    type : CLEAN_PLAN_DATA
    
})


export const addClientsBegin = () => ({
    type : ADD_CLIENTS_LOADING
})

export const addClientsSuccess = (data) => ({
    type : ADD_CLIENTS_SUCCESS,
    data
})

export const addClientsFailure = (error) => ({
    type : ADD_CLIENTS_FAILURE,
    error
    
})


export const getClientsBegin = () => ({
    type : GET_CLIENTS_LOADING
})

export const getClientsSuccess = (data) => ({
    type : GET_CLIENTS_SUCCESS,
    data
})

export const getClientsFailure = (error) => ({
    type : GET_CLIENTS_FAILURE,
    error
    
})

export const getReportsBegin = () => ({
    type : GET_Report_LOADING
})

export const getReportSucess = (data) => ({
  
    type : GET_Report_SUCCESS,
    data
})

export const getReportFailure = (error) => ({
    type : GET_Report_FAILURE,
    error
    
})

export const editClientsBegin = () => ({
    type : EDIT_CLIENTS_LOADING
})

export const editClientsSuccess = (data) => ({
    type : EDIT_CLIENTS_SUCCESS,
    data
})

export const editClientsFailure = (error) => ({
    type : EDIT_CLIENTS_FAILURE,
    error
    
})

export const editClientsPassBegin = () => ({
    type : EDIT_CLIENTS_PASS_LOADING
})

export const editClientsPassSuccess = (data) => ({
    type : EDIT_CLIENTS_PASS_SUCCESS,
    data
})

export const editClientsPassFailure = (error) => ({
    type : EDIT_CLIENTS_PASS_FAILURE,
    error
    
})

export const delClientsBegin = () => ({
    type : DELETE_CLIENTS_LOADING
})

export const delClientsSuccess = (data) => ({
    type : DELETE_CLIENTS_SUCCESS,
    data
})

export const delClientsFailure = (error) => ({
    type : DELETE_CLIENTS_FAILURE,
    error
    
})

export const getBackupBegin = () => ({
    type : GET_BACKUP_LOADING
})

export const getBackupSuccess = (data) => ({
    type : GET_BACKUP_SUCCESS,
    data
})

export const getBackupFailure = (error) => ({
    type : GET_BACKUP_FAILURE,
    error
    
})

export const cleanClientsData = () => ({
    type : CLEAN_CLIENTS_DATA    
})

export const addRolesBegin = () => ({
    type : ADD_ROLES_LOADING
})

export const addRolesSuccess = (data) => ({
    type : ADD_ROLES_SUCCESS,
    data
})

export const addRolesFailure = (error) => ({
    type : ADD_ROLES_FAILURE,
    error
    
})

export const editRolesBegin = () => ({
    type : EDIT_ROLES_LOADING
})

export const editRolesSuccess = (data) => ({
    type : EDIT_ROLES_SUCCESS,
    data
})

export const editRolesFailure = (error) => ({
    type : EDIT_ROLES_FAILURE,
    error
    
})



export const delRolesBegin = () => ({
    type : DEL_ROLES_LOADING
})

export const delRolesSuccess = (data) => ({
    type : DEL_ROLES_SUCCESS,
    data
})

export const delRolesFailure = (error) => ({
    type : DEL_ROLES_FAILURE,
    error
    
})

export const cleanRoles = () => ({
    type : CLEAN_ROLES_DATA    
})


export const addGstBegin = () => ({
    type : ADD_GST_LOADING
})

export const addGstSuccess = (data) => ({
    type : ADD_GST_SUCCESS,
    data
})

export const addGstFailure = (error) => ({
    type : ADD_GST_FAILURE,
    error
    
})



export const getGstBegin = () => ({
    type : GET_GST_LOADING
})

export const getGstSuccess = (data) => ({
    type : GET_GST_SUCCESS,
    data
})

export const getGstFailure = (error) => ({
    type : GET_GST_FAILURE,
    error
    
})



export const getSubGstBegin = () => ({
    type : GET_SUB_GST_LOADING
})

export const getSubGstSuccess = (data) => ({
    type : GET_SUB_GST_SUCCESS,
    data
})

export const getSubGstFailure = (error) => ({
    type : GET_SUB_GST_FAILURE,
    error
    
})


export const editGstBegin = () => ({
    type : EDIT_GST_LOADING
})

export const editGstSuccess = (data) => ({
    type : EDIT_GST_SUCCESS,
    data
})

export const editGstFailure = (error) => ({
    type : EDIT_GST_FAILURE,
    error
    
})



export const delGstBegin = () => ({
    type : DEL_GST_LOADING
})

export const delGstSuccess = (data) => ({
    type : DEL_GST_SUCCESS,
    data
})

export const delGstFailure = (error) => ({
    type : DEL_GST_FAILURE,
    error
    
})


export const cleanGst = () => ({
    type : CLEAN_GST_DATA
    
})







export const getRolesBegin = () => ({
    type : GET_ROLES_LOADING
})

export const getRolesSuccess = (data) => ({
    type : GET_ROLES_SUCCESS,
    data
})

export const getRolesFailure = (error) => ({
    type : GET_ROLES_FAILURE,
    error
    
})



export const getPanelUserBegin = () => ({
    type : GET_PANEL_USER_LOADING
})

export const getPanelUserSuccess = (data) => ({
    type : GET_PANEL_USER_SUCCESS,
    data
})

export const getPanelUserFailure = (error) => ({
    type : GET_PANEL_USER_FAILURE,
    error
    
})

export const delPanelUserBegin = () => ({
    type : DEL_PANEL_USER_LOADING
})

export const delPanelUserSuccess = (data) => ({
    type : DEL_PANEL_USER_SUCCESS,
    data
})

export const delPanelUserFailure = (error) => ({
    type : DEL_PANEL_USER_FAILURE,
    error
    
})

export const cleanPanelUserData = () => ({
    type : CLEAN_PANEL_USER_DATA
})


export const getSuperEnqBegin = () => ({
    type : GET_SUPER_ENQ_LOADING
})

export const getSuperEnqSuccess = (data) => ({
    type : GET_SUPER_ENQ_SUCCESS,
    data
})

export const getSuperEnqFailure = (error) => ({
    type : GET_SUPER_ENQ_FAILURE,
    error
    
})



export const getSuperTickBegin = () => ({
    type : GET_SUPER_TICK_LOADING
})

export const getSuperTickSuccess = (data) => ({
    type : GET_SUPER_TICK_SUCCESS,
    data
})

export const getSuperTickFailure = (error) => ({
    type : GET_SUPER_TICK_FAILURE,
    error
    
})


export const editSuperTickBegin = () => ({
    type : EDIT_SUPER_TICK_LOADING
})

export const editSuperTickSuccess = (data) => ({
    type : EDIT_SUPER_TICK_SUCCESS,
    data
})

export const editSuperTickFailure = (error) => ({
    type : EDIT_SUPER_TICK_FAILURE,
    error
    
})

export const delSuperTickBegin = () => ({
    type : DEL_SUPER_TICK_LOADING
})

export const delSuperTickSuccess = (data) => ({
    type : DEL_SUPER_TICK_SUCCESS,
    data
})

export const delSuperTickFailure = (error) => ({
    type : DEL_SUPER_TICK_FAILURE,
    error
    
})


export const cleanSuperTick = () => ({
    type : CLEAN_SUPER_TICK_DATA
    
})


export const addPanelUserBegin = () => ({
    type : ADD_PANEL_USER_LOADING
})

export const addPanelUserSuccess = (data) => ({
    type : ADD_PANEL_USER_SUCCESS,
    data
})

export const addPanelUserFailure = (error) => ({
    type : ADD_PANEL_USER_FAILURE,
    error
    
})

export const cleanAllData = () => ({
    type : CLEAN_ALL_DATA
    
})

export const getSuperProfileDetailBegin = () => ({
    type : GET_SUPER_PROFILE_DETAIL_LOADING
})

export const getSuperProfileDetailSuccess = (data) => ({
    type : GET_SUPER_PROFILE_DETAIL_SUCCESS,
    data
})

export const getSuperProfileDetailFailure = (error) => ({
    type : GET_SUPER_PROFILE_DETAIL_FAILURE,
    error
    
})

export const editSuperProfileDetailBegin = () => ({
    type : EDIT_SUPER_PROFILE_DETAIL_LOADING
})

export const editSuperProfileDetailSuccess = (data) => ({
    type : EDIT_SUPER_PROFILE_DETAIL_SUCCESS,
    data
})

export const editSuperProfileDetailFailure = (error) => ({
    type : EDIT_SUPER_PROFILE_DETAIL_FAILURE,
    error
    
})

export const editSuperProfilePasswordBegin = () => ({
    type : EDIT_SUPER_PROFILE_PASSWORD_LOADING
})

export const editSuperProfilePasswordSuccess = (data) => ({
    type : EDIT_SUPER_PROFILE_PASSWORD_SUCCESS,
    data
})

export const editSuperProfilePasswordFailure = (error) => ({
    type : EDIT_SUPER_PROFILE_PASSWORD_FAILURE,
    error
    
})

export const editSuperProfilePicBegin = () => ({
    type : EDIT_SUPER_PROFILE_PIC_LOADING
})

export const editSuperProfilePicSuccess = (data) => ({
    type : EDIT_SUPER_PROFILE_PIC_SUCCESS,
    data
})

export const editSuperProfilePicFailure = (error) => ({
    type : EDIT_SUPER_PROFILE_PIC_FAILURE,
    error
    
})

export const cleanSuperProfileDetail = () => ({
    type : CLEAN_SUPER_PROFILE_DETAIL_DATA
    
})



export const addFaqBegin = () => ({
    type : ADD_FAQ_LOADING
})

export const addFaqSuccess = (data) => ({
    type : ADD_FAQ_SUCCESS,
    data
})

export const addFaqFailure = (error) => ({
    type : ADD_FAQ_FAILURE,
    error  
})



export const getFaqBegin = () => ({
    type : GET_FAQ_LOADING
})

export const getFaqSuccess = (data) => ({
    type : GET_FAQ_SUCCESS,
    data
})

export const getFaqFailure = (error) => ({
    type : GET_FAQ_FAILURE,
    error
})


export const delFaqBegin = () => ({
    type : DEL_FAQ_LOADING,
})

export const delFaqSuccess = (data) => ({
    type : DEL_FAQ_SUCCESS,
    data
})

export const delFaqFailure = (error) => ({
    type : DEL_FAQ_FAILURE,
    error
    
})


export const editFaqBegin = () => ({
    type : EDIT_FAQ_LOADING
})

export const editFaqSuccess = (data) => ({
    type : EDIT_FAQ_SUCCESS,
    data
})

export const editFaqFailure = (error) => ({
    type : EDIT_FAQ_FAILURE,
    error  
})

export const cleanFaqData = () => ({
    type : CLEAN_FAQ_DATA
    
})




// SubAdmin


export const getSubFaqBegin = () => ({
    type : GET_SUB_FAQ_LOADING
})

export const getSubFaqSuccess = (data) => ({
    type : GET_SUB_FAQ_SUCCESS,
    data
})

export const getSubFaqFailure = (error) => ({
    type : GET_SUB_FAQ_FAILURE,
    error
})

export const addSitesBegin = () => ({
    type : ADD_SITES_LOADING
})

export const addSitesSuccess = (data) => ({
    type : ADD_SITES_SUCCESS,
    data
})

export const addSitesFailure = (error) => ({
    type : ADD_SITES_FAILURE,
    error  
})

export const cleanSites = () => ({
    type : CLEAN_SITES_DATA
    
})


export const getSitesBegin = () => ({
    type : GET_SITES_LOADING
})

export const getSitesSuccess = (data) => ({
    type : GET_SITES_SUCCESS,
    data
})

export const getSitesFailure = (error) => ({
    type : GET_SITES_FAILURE,
    error
})



export const getSitesMasterBegin = () => ({
    type : GET_SITES_MASTER_LOADING
})

export const getSitesMasterSuccess = (data) => ({
    type : GET_SITES_MASTER_SUCCESS,
    data
})

export const getSitesMasterFailure = (error) => ({
    type : GET_SITES_MASTER_FAILURE,
    error
})


export const delSitesBegin = () => ({
    type : DEL_SITES_LOADING,
})

export const delSitesSuccess = (data) => ({
    type : DEL_SITES_SUCCESS,
    data
})

export const delSitesFailure = (error) => ({
    type : DEL_SITES_FAILURE,
    error
    
})


export const editSitesBegin = () => ({
    type : EDIT_SITES_LOADING
})

export const editSitesSuccess = (data) => ({
    type : EDIT_SITES_SUCCESS,
    data
})

export const editSitesFailure = (error) => ({
    type : EDIT_SITES_FAILURE,
    error  
})



export const getSubTickBegin = () => ({
    type : GET_SUB_TICK_LOADING
})

export const getSubTickSuccess = (data) => ({
    type : GET_SUB_TICK_SUCCESS,
    data
})

export const getSubTickFailure = (error) => ({
    type : GET_SUB_TICK_FAILURE,
    error
    
})



export const getAllTickBegin = () => ({
    type : GET_ALL_TICK_LOADING
})

export const getAllTickSuccess = (data) => ({
    type : GET_ALL_TICK_SUCCESS,
    data
})

export const getAllTickFailure = (error) => ({
    type : GET_ALL_TICK_FAILURE,
    error
    
})


export const addSubTickBegin = () => ({
    type : ADD_SUB_TICK_LOADING
})

export const addSubTickSuccess = (data) => ({
    type : ADD_SUB_TICK_SUCCESS,
    data
})

export const addSubTickFailure = (error) => ({
    type : ADD_SUB_TICK_FAILURE,
    error
    
})


export const editSubTickBegin = () => ({
    type : EDIT_SUB_TICK_LOADING
})

export const editSubTickSuccess = (data) => ({
    type : EDIT_SUB_TICK_SUCCESS,
    data
})

export const editSubTickFailure = (error) => ({
    type : EDIT_SUB_TICK_FAILURE,
    error
    
})

export const delSubTickBegin = () => ({
    type : DEL_SUB_TICK_LOADING
})

export const delSubTickSuccess = (data) => ({
    type : DEL_SUB_TICK_SUCCESS,
    data
})

export const delSubTickFailure = (error) => ({
    type : DEL_SUB_TICK_FAILURE,
    error
    
})


export const delTicketBegin = () => ({
    type : DEL_TICKET_LOADING
})

export const delTicketSuccess = (data) => ({
    type : DEL_TICKET_SUCCESS,
    data
})

export const delTicketFailure = (error) => ({
    type : DEL_TICKET_FAILURE,
    error
    
})


export const cleanSubTick = () => ({
    type : CLEAN_SUB_TICK_DATA
    
})


export const addAppUserMgmtBegin = () => ({
    type : ADD_APP_USER_MGMT_LOADING
})

export const addAppUserMgmtSuccess = (data) => ({
    type : ADD_APP_USER_MGMT_SUCCESS,
    data
})

export const addAppUserMgmtFailure = (error) => ({
    type : ADD_APP_USER_MGMT_FAILURE,
    error
    
})

export const getAppUserMgmtBegin = () => ({
    type : GET_APP_USER_MGMT_LOADING
})

export const getAppUserMgmtSuccess = (data) => ({
    type : GET_APP_USER_MGMT_SUCCESS,
    data
})

export const getAppUserMgmtFailure = (error) => ({
    type : GET_APP_USER_MGMT_FAILURE,
    error
    
})

export const editAppUserMgmtBegin = () => ({
    type : EDIT_APP_USER_MGMT_LOADING
})

export const editAppUserMgmtSuccess = (data) => ({
    type : EDIT_APP_USER_MGMT_SUCCESS,
    data
})

export const editAppUserMgmtFailure = (error) => ({
    type : EDIT_APP_USER_MGMT_FAILURE,
    error
    
})

export const delAppUserMgmtBegin = () => ({
    type : DEL_APP_USER_MGMT_LOADING
})

export const delAppUserMgmtSuccess = (data) => ({
    type : DEL_APP_USER_MGMT_SUCCESS,
    data
})

export const delAppUserMgmtFailure = (error) => ({
    type : DEL_APP_USER_MGMT_FAILURE,
    error
    
})


export const cleanAppUserMgmt = () => ({
    type : CLEAN_APP_USER_MGMT_DATA
    
})

export const addSiteExpBegin = () => ({
    type : ADD_SITE_EXPENSE_LOADING
})

export const addSiteExpSuccess = (data) => ({
    type : ADD_SITE_EXPENSE_SUCCESS,
    data
})

export const addSiteExpFailure = (error) => ({
    type : ADD_SITE_EXPENSE_FAILURE,
    error
    
})

export const getSiteExpBegin = () => ({
    type : GET_SITE_EXPENSE_LOADING
})

export const getSiteExpSuccess = (data) => ({
    type : GET_SITE_EXPENSE_SUCCESS,
    data
})

export const getSiteExpFailure = (error) => ({
    type : GET_SITE_EXPENSE_FAILURE,
    error
    
})

export const editSiteExpBegin = () => ({
    type : EDIT_SITE_EXPENSE_LOADING
})

export const editSiteExpSuccess = (data) => ({
    type : EDIT_SITE_EXPENSE_SUCCESS,
    data
})

export const editSiteExpFailure = (error) => ({
    type : EDIT_SITE_EXPENSE_FAILURE,
    error
    
})

export const delSiteExpBegin = () => ({
    type : DEL_SITE_EXPENSE_LOADING
})

export const delSiteExpSuccess = (data) => ({
    type : DEL_SITE_EXPENSE_SUCCESS,
    data
})

export const delSiteExpFailure = (error) => ({
    type : DEL_SITE_EXPENSE_FAILURE,
    error
    
})


export const cleanSiteExp = () => ({
    type : CLEAN_SITE_EXPENSE_DATA
    
})

export const addDrawBegin = () => ({
    type : ADD_DRAW_LOADING
})

export const addDrawSuccess = (data) => ({
    type : ADD_DRAW_SUCCESS,
    data
})

export const addDrawFailure = (error) => ({
    type : ADD_DRAW_FAILURE,
    error
    
})

export const getDrawBegin = () => ({
    type : GET_DRAW_LOADING
})

export const getDrawSuccess = (data) => ({
    type : GET_DRAW_SUCCESS,
    data
})

export const getDrawFailure = (error) => ({
    type : GET_DRAW_FAILURE,
    error
    
})

export const editDrawBegin = () => ({
    type : EDIT_DRAW_LOADING
})

export const editDrawSuccess = (data) => ({
    type : EDIT_DRAW_SUCCESS,
    data
})

export const editDrawFailure = (error) => ({
    type : EDIT_DRAW_FAILURE,
    error
    
})

export const delDrawBegin = () => ({
    type : DEL_DRAW_LOADING
})

export const delDrawSuccess = (data) => ({
    type : DEL_DRAW_SUCCESS,
    data
})

export const delDrawFailure = (error) => ({
    type : DEL_DRAW_FAILURE,
    error
    
})


export const cleanDraw = () => ({
    type : CLEAN_DRAW_DATA
    
})


export const addDrawTypeBegin = () => ({
    type : ADD_DRAW_TYPE_LOADING
})

export const addDrawTypeSuccess = (data) => ({
    type : ADD_DRAW_TYPE_SUCCESS,
    data
})

export const addDrawTypeFailure = (error) => ({
    type : ADD_DRAW_TYPE_FAILURE,
    error
    
})

export const getDrawTypeBegin = () => ({
    type : GET_DRAW_TYPE_LOADING
})

export const getDrawTypeSuccess = (data) => ({
    type : GET_DRAW_TYPE_SUCCESS,
    data
})

export const getDrawTypeFailure = (error) => ({
    type : GET_DRAW_TYPE_FAILURE,
    error
    
})

export const editDrawTypeBegin = () => ({
    type : EDIT_DRAW_TYPE_LOADING
})

export const editDrawTypeSuccess = (data) => ({
    type : EDIT_DRAW_TYPE_SUCCESS,
    data
})

export const editDrawTypeFailure = (error) => ({
    type : EDIT_DRAW_TYPE_FAILURE,
    error
    
})

export const delDrawTypeBegin = () => ({
    type : DEL_DRAW_TYPE_LOADING
})

export const delDrawTypeSuccess = (data) => ({
    type : DEL_DRAW_TYPE_SUCCESS,
    data
})

export const delDrawTypeFailure = (error) => ({
    type : DEL_DRAW_TYPE_FAILURE,
    error
    
})


export const cleanDrawType = () => ({
    type : CLEAN_DRAW_TYPE_DATA
    
})


export const addSiteDrawBegin = () => ({
    type : ADD_SITE_DRAW_LOADING
})

export const addSiteDrawSuccess = (data) => ({
    type : ADD_SITE_DRAW_SUCCESS,
    data
})

export const addSiteDrawFailure = (error) => ({
    type : ADD_SITE_DRAW_FAILURE,
    error
    
})


export const addBOQSuccess = (data) => ({
    type : ADD_BOQ_SUCCESS,
    data
})

export const addBOQFailure = (error) => ({
    type : ADD_BOQ_FAILURE,
    error
    
})


export const getSiteDrawBegin = () => ({
    type : GET_SITE_DRAW_LOADING
})

export const getSiteDrawSuccess = (data) => ({
    type : GET_SITE_DRAW_SUCCESS,
    data
})

export const getSiteDrawFailure = (error) => ({
    type : GET_SITE_DRAW_FAILURE,
    error
    
})

export const editSiteDrawBegin = () => ({
    type : EDIT_SITE_DRAW_LOADING
})

export const editSiteDrawSuccess = (data) => ({
    type : EDIT_SITE_DRAW_SUCCESS,
    data
})

export const editSiteDrawFailure = (error) => ({
    type : EDIT_SITE_DRAW_FAILURE,
    error
    
})

export const delSiteDrawBegin = () => ({
    type : DEL_SITE_DRAW_LOADING
})

export const delSiteDrawSuccess = (data) => ({
    type : DEL_SITE_DRAW_SUCCESS,
    data
})

export const delSiteDrawFailure = (error) => ({
    type : DEL_SITE_DRAW_FAILURE,
    error
    
})


export const cleanSiteDraw = () => ({
    type : CLEAN_SITE_DRAW_DATA
    
})


export const addSubRoleBegin = () => ({
    type : ADD_SUB_ROLE_LOADING
})

export const addSubRoleSuccess = (data) => ({
    type : ADD_SUB_ROLE_SUCCESS,
    data
})

export const addSubRoleFailure = (error) => ({
    type : ADD_SUB_ROLE_FAILURE,
    error
    
})

export const getSubRoleBegin = () => ({
    type : GET_SUB_ROLE_LOADING
})

export const getSubRoleSuccess = (data) => ({
    type : GET_SUB_ROLE_SUCCESS,
    data
})

export const getSubRoleFailure = (error) => ({
    type : GET_SUB_ROLE_FAILURE,
    error
    
})

export const editSubRoleBegin = () => ({
    type : EDIT_SUB_ROLE_LOADING
})

export const editSubRoleSuccess = (data) => ({
    type : EDIT_SUB_ROLE_SUCCESS,
    data
})

export const editSubRoleFailure = (error) => ({
    type : EDIT_SUB_ROLE_FAILURE,
    error
    
})

export const delSubRoleBegin = () => ({
    type : DEL_SUB_ROLE_LOADING
})

export const delSubRoleSuccess = (data) => ({
    type : DEL_SUB_ROLE_SUCCESS,
    data
})

export const delSubRoleFailure = (error) => ({
    type : DEL_SUB_ROLE_FAILURE,
    error
    
})


export const cleanSubRole = () => ({
    type : CLEAN_SUB_ROLE_DATA
    
})


export const addManpowerCategoryBegin = () => ({
    type : ADD_MANPOWER_CATEGORY_LOADING
})

export const addManpowerCategorySuccess = (data) => ({
    type : ADD_MANPOWER_CATEGORY_SUCCESS,
    data
})

export const addManpowerCategoryFailure = (error) => ({
    type : ADD_MANPOWER_CATEGORY_FAILURE,
    error
    
})

export const cleanManpowerCategoryData = () => ({
    type : CLEAN_MANPOWER_CATEGORY_DATA  
})


export const getManpowerCategoryBegin = () => ({
    type : GET_MANPOWER_CATEGORY_LOADING
})

export const getManpowerCategorySuccess = (data) => ({
    type : GET_MANPOWER_CATEGORY_SUCCESS,
    data
})

export const getManpowerCategoryFailure = (error) => ({
    type : GET_MANPOWER_CATEGORY_FAILURE,
    error
    
})

export const delManpowerCategoryBegin = () => ({
    type : DEL_MANPOWER_CATEGORY_LOADING
})

export const delManpowerCategorySuccess = (data) => ({
    type : DEL_MANPOWER_CATEGORY_SUCCESS,
    data
})

export const delManpowerCategoryFailure = (error) => ({
    type : DEL_MANPOWER_CATEGORY_FAILURE,
    error
    
})

export const editManpowerCategoryBegin = () => ({
    type : EDIT_MANPOWER_CATEGORY_LOADING
})

export const editManpowerCategorySuccess = (data) => ({
    type : EDIT_MANPOWER_CATEGORY_SUCCESS,
    data
})

export const editManpowerCategoryFailure = (error) => ({
    type : EDIT_MANPOWER_CATEGORY_FAILURE,
    error
    
})

export const addManpowerManBegin = () => ({
    type : ADD_MANPOWER_MAN_LOADING
})

export const addManpowerManSuccess = (data) => ({
    type : ADD_MANPOWER_MAN_SUCCESS,
    data
})

export const addManpowerManFailure = (error) => ({
    type : ADD_MANPOWER_MAN_FAILURE,
    error
    
})

export const cleanManpowerManData = () => ({
    type : CLEAN_MANPOWER_MAN_DATA  
})


export const getManpowerManBegin = () => ({
    type : GET_MANPOWER_MAN_LOADING
})

export const getManpowerManSuccess = (data) => ({
    type : GET_MANPOWER_MAN_SUCCESS,
    data
})

export const getManpowerManFailure = (error) => ({
    type : GET_MANPOWER_MAN_FAILURE,
    error
    
})

export const delManpowerManBegin = () => ({
    type : DEL_MANPOWER_MAN_LOADING
})

export const delManpowerManSuccess = (data) => ({
    type : DEL_MANPOWER_MAN_SUCCESS,
    data
})

export const delManpowerManFailure = (error) => ({
    type : DEL_MANPOWER_MAN_FAILURE,
    error
    
})

export const editManpowerManBegin = () => ({
    type : EDIT_MANPOWER_MAN_LOADING
})

export const editManpowerManSuccess = (data) => ({
    type : EDIT_MANPOWER_MAN_SUCCESS,
    data
})

export const editManpowerManFailure = (error) => ({
    type : EDIT_MANPOWER_MAN_FAILURE,
    error
    
})


export const addMaterialCategoryBegin = () => ({
    type : ADD_MATERIAL_CATEGORY_LOADING
})

export const addMaterialCategorySuccess = (data) => ({
    type : ADD_MATERIAL_CATEGORY_SUCCESS,
    data
})

export const addMaterialCategoryFailure = (error) => ({
    type : ADD_MATERIAL_CATEGORY_FAILURE,
    error
    
})

export const getMaterialCategoryBegin = () => ({
    type : GET_MATERIAL_CATEGORY_LOADING
})

export const getMaterialCategorySuccess = (data) => ({
    type : GET_MATERIAL_CATEGORY_SUCCESS,
    data
})

export const getMaterialCategoryFailure = (error) => ({
    type : GET_MATERIAL_CATEGORY_FAILURE,
    error
    
})

export const delMaterialCategoryBegin = () => ({
    type : DEL_MATERIAL_CATEGORY_LOADING
})

export const delMaterialCategorySuccess = (data) => ({
    type : DEL_MATERIAL_CATEGORY_SUCCESS,
    data
})

export const delMaterialCategoryFailure = (error) => ({
    type : DEL_MATERIAL_CATEGORY_FAILURE,
    error
    
})

export const editMaterialCategoryBegin = () => ({
    type : EDIT_MATERIAL_CATEGORY_LOADING
})

export const editMaterialCategorySuccess = (data) => ({
    type : EDIT_MATERIAL_CATEGORY_SUCCESS,
    data
})

export const editMaterialCategoryFailure = (error) => ({
    type : EDIT_MATERIAL_CATEGORY_FAILURE,
    error  
})

export const cleanMaterialCategoryData = () => ({
    type : CLEAN_MATERIAL_CATEGORY_DATA  
})


export const addMaterialMatBegin = () => ({
    type : ADD_MATERIAL_MAT_LOADING
})

export const addMaterialMatSuccess = (data) => ({
    type : ADD_MATERIAL_MAT_SUCCESS,
    data
})

export const addMaterialMatFailure = (error) => ({
    type : ADD_MATERIAL_MAT_FAILURE,
    error
    
})

export const getMaterialMatBegin = () => ({
    type : GET_MATERIAL_MAT_LOADING
})

export const getMaterialMatSuccess = (data) => ({
    type : GET_MATERIAL_MAT_SUCCESS,
    data
})

export const getMaterialMatFailure = (error) => ({
    type : GET_MATERIAL_MAT_FAILURE,
    error
    
})

export const delMaterialMatBegin = () => ({
    type : DEL_MATERIAL_MAT_LOADING
})

export const delMaterialMatSuccess = (data) => ({
    type : DEL_MATERIAL_MAT_SUCCESS,
    data
})

export const delMaterialMatFailure = (error) => ({
    type : DEL_MATERIAL_MAT_FAILURE,
    error
    
})

export const editMaterialMatBegin = () => ({
    type : EDIT_MATERIAL_MAT_LOADING
})

export const editMaterialMatSuccess = (data) => ({
    type : EDIT_MATERIAL_MAT_SUCCESS,
    data
})

export const editMaterialMatFailure = (error) => ({
    type : EDIT_MATERIAL_MAT_FAILURE,
    error  
})


export const cleanMaterialMatData = () => ({
    type : CLEAN_MATERIAL_MAT_DATA  
})



export const acceptMatIndentBegin = () => ({
    type : ACCEPT_MAT_INDENT_LOADING
})

export const acceptMatIndentSuccess = (data) => ({
    type : ACCEPT_MAT_INDENT_SUCCESS,
    data
})

export const acceptMatIndentFailure = (error) => ({
    type : ACCEPT_MAT_INDENT_FAILURE,
    error
    
})


export const getMaterialIndentBegin = () => ({
    type : GET_MATERIAL_INDENT_LOADING
})

export const getMaterialIndentSuccess = (data) => ({
    type : GET_MATERIAL_INDENT_SUCCESS,
    data
})

export const getMaterialIndentFailure = (error) => ({
    type : GET_MATERIAL_INDENT_FAILURE,
    error
    
})

export const delMaterialIndentBegin = () => ({
    type : DEL_MATERIAL_INDENT_LOADING
})

export const delMaterialIndentSuccess = (data) => ({
    type : DEL_MATERIAL_INDENT_SUCCESS,
    data
})

export const delMaterialIndentFailure = (error) => ({
    type : DEL_MATERIAL_INDENT_FAILURE,
    error
    
})


export const orderMaterialIndentBegin = () => ({
    type : ORDER_MATERIAL_INDENT_LOADING
})

export const orderMaterialIndentSuccess = (data) => ({
    type : ORDER_MATERIAL_INDENT_SUCCESS,
    data
})

export const orderMaterialIndentFailure = (error) => ({
    type : ORDER_MATERIAL_INDENT_FAILURE,
    error
    
})

export const cleanMaterialIndentData = () => ({
    type : CLEAN_MATERIAL_INDENT_DATA  
})



export const acceptMacIndentBegin = () => ({
    type : ACCEPT_MAC_INDENT_LOADING
})

export const acceptMacIndentSuccess = (data) => ({
    type : ACCEPT_MAC_INDENT_SUCCESS,
    data
})

export const acceptMacIndentFailure = (error) => ({
    type : ACCEPT_MAC_INDENT_FAILURE,
    error
    
})

export const getMachineIndentBegin = () => ({
    type : GET_MACHINE_INDENT_LOADING
})

export const getMachineIndentSuccess = (data) => ({
    type : GET_MACHINE_INDENT_SUCCESS,
    data
})

export const getMachineIndentFailure = (error) => ({
    type : GET_MACHINE_INDENT_FAILURE,
    error
    
})

export const delMachineIndentBegin = () => ({
    type : DEL_MACHINE_INDENT_LOADING
})

export const delMachineIndentSuccess = (data) => ({
    type : DEL_MACHINE_INDENT_SUCCESS,
    data
})

export const delMachineIndentFailure = (error) => ({
    type : DEL_MACHINE_INDENT_FAILURE,
    error
    
})

export const orderMachineIndentBegin = () => ({
    type : ORDER_MACHINE_INDENT_LOADING
})

export const orderMachineIndentSuccess = (data) => ({
    type : ORDER_MACHINE_INDENT_SUCCESS,
    data
})

export const orderMachineIndentFailure = (error) => ({
    type : ORDER_MACHINE_INDENT_FAILURE,
    error
    
})


export const cleanMachineIndentData = () => ({
    type : CLEAN_MACHINE_INDENT_DATA  
})



export const getMachineDeliveryBegin = () => ({
    type : GET_MACHINE_DELIVERY_LOADING
})

export const getMachineDeliverySuccess = (data) => ({
    type : GET_MACHINE_DELIVERY_SUCCESS,
    data
})

export const getMachineDeliveryFailure = (error) => ({
    type : GET_MACHINE_DELIVERY_FAILURE,
    error
    
})

export const delMachineDeliveryBegin = () => ({
    type : DEL_MACHINE_DELIVERY_LOADING
})

export const delMachineDeliverySuccess = (data) => ({
    type : DEL_MACHINE_DELIVERY_SUCCESS,
    data
})

export const delMachineDeliveryFailure = (error) => ({
    type : DEL_MACHINE_DELIVERY_FAILURE,
    error
    
})

export const cleanMachineDeliveryData = () => ({
    type : CLEAN_MACHINE_DELIVERY_DATA  
})



export const getMaterialSupplyBegin = () => ({
    type : GET_MATERIAL_SUPPLY_LOADING
})

export const getMaterialSupplySuccess = (data) => ({
    type : GET_MATERIAL_SUPPLY_SUCCESS,
    data
})

export const getMaterialSupplyFailure = (error) => ({
    type : GET_MATERIAL_SUPPLY_FAILURE,
    error
    
})

export const delMaterialSupplyBegin = () => ({
    type : DEL_MATERIAL_SUPPLY_LOADING
})

export const delMaterialSupplySuccess = (data) => ({
    type : DEL_MATERIAL_SUPPLY_SUCCESS,
    data
})

export const delMaterialSupplyFailure = (error) => ({
    type : DEL_MATERIAL_SUPPLY_FAILURE,
    error
    
})

export const cleanMaterialSupplyData = () => ({
    type : CLEAN_MATERIAL_SUPPLY_DATA  
})


export const getMaterialDeliveryBegin = () => ({
    type : GET_MATERIAL_DELIVERY_LOADING
})

export const getMaterialDeliverySuccess = (data) => ({
    type : GET_MATERIAL_DELIVERY_SUCCESS,
    data
})

export const getMaterialDeliveryFailure = (error) => ({
    type : GET_MATERIAL_DELIVERY_FAILURE,
    error
    
})

export const delMaterialDeliveryBegin = () => ({
    type : DEL_MATERIAL_DELIVERY_LOADING
})

export const delMaterialDeliverySuccess = (data) => ({
    type : DEL_MATERIAL_DELIVERY_SUCCESS,
    data
})

export const delMaterialDeliveryFailure = (error) => ({
    type : DEL_MATERIAL_DELIVERY_FAILURE,
    error
    
})

export const cleanMaterialDeliveryData = () => ({
    type : CLEAN_MATERIAL_DELIVERY_DATA  
})

export const getCashExpBegin = () => ({
    type : GET_CASH_EXPENSE_LOADING
})

export const getCashExpSuccess = (data) => ({
    type : GET_CASH_EXPENSE_SUCCESS,
    data
})

export const getCashExpFailure = (error) => ({
    type : GET_CASH_EXPENSE_FAILURE,
    error
    
})


export const delCashExpBegin = () => ({
    type : DEL_CASH_EXPENSE_LOADING
})

export const delCashExpSuccess = (data) => ({
    type : DEL_CASH_EXPENSE_SUCCESS,
    data
})

export const delCashExpFailure = (error) => ({
    type : DEL_CASH_EXPENSE_FAILURE,
    error
    
})


export const cleanCashExp = () => ({
    type : CLEAN_CASH_EXPENSE_DATA
    
})

export const getSiteManBegin = () => ({
    type : GET_SITE_MAN_LOADING
})

export const getSiteManSuccess = (data) => ({
    type : GET_SITE_MAN_SUCCESS,
    data
})

export const getSiteManFailure = (error) => ({
    type : GET_SITE_MAN_FAILURE,
    error
    
})

export const delSiteManBegin = () => ({
    type : DEL_SITE_MAN_LOADING
})

export const delSiteManSuccess = (data) => ({
    type : DEL_SITE_MAN_SUCCESS,
    data
})

export const delSiteManFailure = (error) => ({
    type : DEL_SITE_MAN_FAILURE,
    error
    
})


export const cleanSiteMan = () => ({
    type : CLEAN_SITE_MAN_DATA
    
})


export const getQualitySafetyBegin = () => ({
    type : GET_QUALITY_SAFETY_LOADING
})

export const getQualitySafetySuccess = (data) => ({
    type : GET_QUALITY_SAFETY_SUCCESS,
    data
})

export const getQualitySafetyFailure = (error) => ({
    type : GET_QUALITY_SAFETY_FAILURE,
    error
    
})


export const delQualitySafetyBegin = () => ({
    type : DEL_QUALITY_SAFETY_LOADING
})

export const delQualitySafetySuccess = (data) => ({
    type : DEL_QUALITY_SAFETY_SUCCESS,
    data
})

export const delQualitySafetyFailure = (error) => ({
    type : DEL_QUALITY_SAFETY_FAILURE,
    error
    
})

export const cleanQualitySafetyData = () => ({
    type : CLEAN_QUALITY_SAFETY_DATA  
})



export const getSiteProgressBegin = () => ({
    type : GET_SITE_PROGRESS_LOADING
})

export const getSiteProgressSuccess = (data) => ({
    type : GET_SITE_PROGRESS_SUCCESS,
    data
})

export const getSiteProgressFailure = (error) => ({
    type : GET_SITE_PROGRESS_FAILURE,
    error
    
})


export const delSiteProgressBegin = () => ({
    type : DEL_SITE_PROGRESS_LOADING
})

export const delSiteProgressSuccess = (data) => ({
    type : DEL_SITE_PROGRESS_SUCCESS,
    data
})

export const delSiteProgressFailure = (error) => ({
    type : DEL_SITE_PROGRESS_FAILURE,
    error
    
})

export const cleanSiteProgressData = () => ({
    type : CLEAN_SITE_PROGRESS_DATA  
})


export const addVendorBegin = () => ({
    type : ADD_VENDOR_LOADING
})

export const addVendorSuccess = (data) => ({
    type : ADD_VENDOR_SUCCESS,
    data
})

export const addVendorFailure = (error) => ({
    type : ADD_VENDOR_FAILURE,
    error
    
})

export const getVendorBegin = () => ({
    type : GET_VENDOR_LOADING
})

export const getVendorSuccess = (data) => ({
    type : GET_VENDOR_SUCCESS,
    data
})

export const getVendorFailure = (error) => ({
    type : GET_VENDOR_FAILURE,
    error
    
})

export const editVendorBegin = () => ({
    type : EDIT_VENDOR_LOADING
})

export const editVendorSuccess = (data) => ({
    type : EDIT_VENDOR_SUCCESS,
    data
})

export const editVendorFailure = (error) => ({
    type : EDIT_VENDOR_FAILURE,
    error  
})

export const delVendorBegin = () => ({
    type : DEL_VENDOR_LOADING
})

export const delVendorSuccess = (data) => ({
    type : DEL_VENDOR_SUCCESS,
    data
})

export const delVendorFailure = (error) => ({
    type : DEL_VENDOR_FAILURE,
    error  
})

export const cleanVendorData = () => ({
    type : CLEAN_VENDOR_DATA  
})



export const addResourceUnitBegin = () => ({
    type : ADD_RESOURCE_UNIT_LOADING
})

export const addResourceUnitSuccess = (data) => ({
    type : ADD_RESOURCE_UNIT_SUCCESS,
    data
})

export const addResourceUnitFailure = (error) => ({
    type : ADD_RESOURCE_UNIT_FAILURE,
    error
    
})

export const getResourceUnitBegin = () => ({
    type : GET_RESOURCE_UNIT_LOADING
})

export const getResourceUnitSuccess = (data) => ({
    type : GET_RESOURCE_UNIT_SUCCESS,
    data
})

export const getResourceUnitFailure = (error) => ({
    type : GET_RESOURCE_UNIT_FAILURE,
    error
    
})

export const delResourceUnitBegin = () => ({
    type : DEL_RESOURCE_UNIT_LOADING
})

export const delResourceUnitSuccess = (data) => ({
    type : DEL_RESOURCE_UNIT_SUCCESS,
    data
})

export const delResourceUnitFailure = (error) => ({
    type : DEL_RESOURCE_UNIT_FAILURE,
    error
    
})

export const editResourceUnitBegin = () => ({
    type : EDIT_RESOURCE_UNIT_LOADING
})

export const editResourceUnitSuccess = (data) => ({
    type : EDIT_RESOURCE_UNIT_SUCCESS,
    data
})

export const editResourceUnitFailure = (error) => ({
    type : EDIT_RESOURCE_UNIT_FAILURE,
    error
    
})


export const cleanResourceUnitData = () => ({
    type : CLEAN_RESOURCE_UNIT_DATA  
})


export const addResourceMachineBegin = () => ({
    type : ADD_RESOURCE_MACHINE_LOADING
})

export const addResourceMachineSuccess = (data) => ({
    type : ADD_RESOURCE_MACHINE_SUCCESS,
    data
})

export const addResourceMachineFailure = (error) => ({
    type : ADD_RESOURCE_MACHINE_FAILURE,
    error
    
})

export const getResourceMachineBegin = () => ({
    type : GET_RESOURCE_MACHINE_LOADING
})

export const getResourceMachineSuccess = (data) => ({
    type : GET_RESOURCE_MACHINE_SUCCESS,
    data
})

export const getResourceMachineFailure = (error) => ({
    type : GET_RESOURCE_MACHINE_FAILURE,
    error
    
})

export const delResourceMachineBegin = () => ({
    type : DEL_RESOURCE_MACHINE_LOADING
})

export const delResourceMachineSuccess = (data) => ({
    type : DEL_RESOURCE_MACHINE_SUCCESS,
    data
})

export const delResourceMachineFailure = (error) => ({
    type : DEL_RESOURCE_MACHINE_FAILURE,
    error
    
})

export const editResourceMachineBegin = () => ({
    type : EDIT_RESOURCE_MACHINE_LOADING
})

export const editResourceMachineSuccess = (data) => ({
    type : EDIT_RESOURCE_MACHINE_SUCCESS,
    data
})

export const editResourceMachineFailure = (error) => ({
    type : EDIT_RESOURCE_MACHINE_FAILURE,
    error
    
})


export const cleanResourceMachineData = () => ({
    type : CLEAN_RESOURCE_MACHINE_DATA  
})

export const addMachineCatBegin = () => ({
    type : ADD_MACHINE_CAT_LOADING
})

export const addMachineCatSuccess = (data) => ({
    type : ADD_MACHINE_CAT_SUCCESS,
    data
})

export const addMachineCatFailure = (error) => ({
    type : ADD_MACHINE_CAT_FAILURE,
    error
    
})

export const getMachineCatBegin = () => ({
    type : GET_MACHINE_CAT_LOADING
})

export const getMachineCatSuccess = (data) => ({
    type : GET_MACHINE_CAT_SUCCESS,
    data
})

export const getMachineCatFailure = (error) => ({
    type : GET_MACHINE_CAT_FAILURE,
    error
    
})

export const delMachineCatBegin = () => ({
    type : DEL_MACHINE_CAT_LOADING
})

export const delMachineCatSuccess = (data) => ({
    type : DEL_MACHINE_CAT_SUCCESS,
    data
})

export const delMachineCatFailure = (error) => ({
    type : DEL_MACHINE_CAT_FAILURE,
    error
    
})

export const editMachineCatBegin = () => ({
    type : EDIT_MACHINE_CAT_LOADING
})

export const editMachineCatSuccess = (data) => ({
    type : EDIT_MACHINE_CAT_SUCCESS,
    data
})

export const editMachineCatFailure = (error) => ({
    type : EDIT_MACHINE_CAT_FAILURE,
    error
    
})


export const cleanMachineCatData = () => ({
    type : CLEAN_MACHINE_CAT_DATA  
})



export const addResourceQualityBegin = () => ({
    type : ADD_RESOURCE_QUALITY_LOADING
})

export const addResourceQualitySuccess = (data) => ({
    type : ADD_RESOURCE_QUALITY_SUCCESS,
    data
})

export const addResourceQualityFailure = (error) => ({
    type : ADD_RESOURCE_QUALITY_FAILURE,
    error
    
})

export const getResourceQualityBegin = () => ({
    type : GET_RESOURCE_QUALITY_LOADING
})

export const getResourceQualitySuccess = (data) => ({
    type : GET_RESOURCE_QUALITY_SUCCESS,
    data
})

export const getResourceQualityFailure = (error) => ({
    type : GET_RESOURCE_QUALITY_FAILURE,
    error
    
})

export const delResourceQualityBegin = () => ({
    type : DEL_RESOURCE_QUALITY_LOADING
})

export const delResourceQualitySuccess = (data) => ({
    type : DEL_RESOURCE_QUALITY_SUCCESS,
    data
})

export const delResourceQualityFailure = (error) => ({
    type : DEL_RESOURCE_QUALITY_FAILURE,
    error
    
})

export const editResourceQualityBegin = () => ({
    type : EDIT_RESOURCE_QUALITY_LOADING
})

export const editResourceQualitySuccess = (data) => ({
    type : EDIT_RESOURCE_QUALITY_SUCCESS,
    data
})

export const editResourceQualityFailure = (error) => ({
    type : EDIT_RESOURCE_QUALITY_FAILURE,
    error
    
})


export const cleanResourceQualityData = () => ({
    type : CLEAN_RESOURCE_QUALITY_DATA  
})


export const addOtherSubAdminBegin = () => ({
    type : ADD_OTHER_SUB_ADMIN_LOADING
})

export const addOtherSubAdminSuccess = (data) => ({
    type : ADD_OTHER_SUB_ADMIN_SUCCESS,
    data
})

export const addOtherSubAdminFailure = (error) => ({
    type : ADD_OTHER_SUB_ADMIN_FAILURE,
    error
    
})


export const delOtherSubAdminBegin = () => ({
    type : DEL_OTHER_SUB_ADMIN_LOADING
})

export const delOtherSubAdminSuccess = (data) => ({
    type : DEL_OTHER_SUB_ADMIN_SUCCESS,
    data
})

export const delOtherSubAdminFailure = (error) => ({
    type : DEL_OTHER_SUB_ADMIN_FAILURE,
    error
    
})

export const blockOtherSubAdminBegin = () => ({
    type : BLOCK_OTHER_SUB_ADMIN_LOADING
})

export const blockOtherSubAdminSuccess = (data) => ({
    type : BLOCK_OTHER_SUB_ADMIN_SUCCESS,
    data
})

export const blockOtherSubAdminFailure = (error) => ({
    type : BLOCK_OTHER_SUB_ADMIN_FAILURE,
    error
    
})


export const editOtherSubAdminBegin = () => ({
    type : EDIT_OTHER_SUB_ADMIN_LOADING
})

export const editOtherSubAdminSuccess = (data) => ({
    type : EDIT_OTHER_SUB_ADMIN_SUCCESS,
    data
})

export const editOtherSubAdminFailure = (error) => ({
    type : EDIT_OTHER_SUB_ADMIN_FAILURE,
    error
    
})

export const getOtherSubAdminBegin = () => ({
    type : GET_OTHER_SUB_ADMIN_LOADING
})

export const getOtherSubAdminSuccess = (data) => ({
    type : GET_OTHER_SUB_ADMIN_SUCCESS,
    data
})

export const getOtherSubAdminFailure = (error) => ({
    type : GET_OTHER_SUB_ADMIN_FAILURE,
    error
    
})


export const cleanOtherSubAdminData = () => ({
    type : CLEAN_OTHER_SUB_ADMIN_DATA  
})



export const editProfileManCreBegin = () => ({
    type : EDIT_PROFILE_MAN_CRE_ADMIN_LOADING
})

export const editProfileManCreSuccess = (data) => ({
    type : EDIT_PROFILE_MAN_CRE_ADMIN_SUCCESS,
    data
})

export const editProfileManCreFailure = (error) => ({
    type : EDIT_PROFILE_MAN_CRE_ADMIN_FAILURE,
    error
    
})

export const editProfileManPassBegin = () => ({
    type : EDIT_PROFILE_MAN_PASS_ADMIN_LOADING
})

export const editProfileManPassSuccess = (data) => ({
    type : EDIT_PROFILE_MAN_PASS_ADMIN_SUCCESS,
    data
})

export const editProfileManPassFailure = (error) => ({
    type : EDIT_PROFILE_MAN_PASS_ADMIN_FAILURE,
    error
    
})

export const editProfileManPicBegin = () => ({
    type : EDIT_PROFILE_MAN_PIC_ADMIN_LOADING
})

export const editProfileManPicSuccess = (data) => ({
    type : EDIT_PROFILE_MAN_PIC_ADMIN_SUCCESS,
    data
})

export const editProfileManPicFailure = (error) => ({
    type : EDIT_PROFILE_MAN_PIC_ADMIN_FAILURE,
    error
    
})


export const editSubLogoBegin = () => ({
    type : EDIT_SUB_LOGO_LOADING
})

export const editSubLogoSuccess = (data) => ({
    type : EDIT_SUB_LOGO_SUCCESS,
    data
})

export const editSubLogoFailure = (error) => ({
    type : EDIT_SUB_LOGO_FAILURE,
    error
    
})


export const getProfileManCreBegin = () => ({
    type : GET_PROFILE_MAN_CRE_ADMIN_LOADING
})

export const getProfileManCreSuccess = (data) => ({
    type : GET_PROFILE_MAN_CRE_ADMIN_SUCCESS,
    data
})

export const getProfileManCreFailure = (error) => ({
    type : GET_PROFILE_MAN_CRE_ADMIN_FAILURE,
    error
})


export const cleanProfileManCreData = (error) => ({
    type : CLEAN_PROFILE_MAN_CRE_ADMIN_DATA,
    error
    
})




export const addDrawingFloorTypeBegin = () => ({
    type : ADD_DRAWING_FLOOR_TYPE_LOADING
})

export const addDrawingFloorTypeSuccess = (data) => ({
    type : ADD_DRAWING_FLOOR_TYPE_SUCCESS,
    data
})

export const addDrawingFloorTypeFailure = (error) => ({
    type : ADD_DRAWING_FLOOR_TYPE_FAILURE,
    error
    
})

export const editDrawingFloorTypeBegin = () => ({
    type : EDIT_DRAWING_FLOOR_TYPE_LOADING
})

export const editDrawingFloorTypeSuccess = (data) => ({
    type : EDIT_DRAWING_FLOOR_TYPE_SUCCESS,
    data
})

export const editDrawingFloorTypeFailure = (error) => ({
    type : EDIT_DRAWING_FLOOR_TYPE_FAILURE,
    error
    
})


export const getDrawingFloorTypeBegin = () => ({
    type : GET_DRAWING_FLOOR_TYPE_LOADING
})

export const getDrawingFloorTypeSuccess = (data) => ({
    type : GET_DRAWING_FLOOR_TYPE_SUCCESS,
    data
})

export const getDrawingFloorTypeFailure = (error) => ({
    type : GET_DRAWING_FLOOR_TYPE_FAILURE,
    error
    
})

export const delDrawingFloorTypeBegin = () => ({
    type : DEL_DRAWING_FLOOR_TYPE_LOADING
})

export const delDrawingFloorTypeSuccess = (data) => ({
    type : DEL_DRAWING_FLOOR_TYPE_SUCCESS,
    data
})

export const delDrawingFloorTypeFailure = (error) => ({
    type : DEL_DRAWING_FLOOR_TYPE_FAILURE,
    error
    
})

export const cleanDrawingFloorTypeData = () => ({
    type : CLEAN_DRAWING_FLOOR_TYPE_DATA
    
})


export const addPurchaseOrderBegin = () => ({
    type : ADD_PURCHASE_ORDER_LOADING
})

export const addPurchaseOrderSuccess = (data) => ({
    type : ADD_PURCHASE_ORDER_SUCCESS,
    data
})

export const addPurchaseOrderFailure = (error) => ({
    type : ADD_PURCHASE_ORDER_FAILURE,
    error
    
})

export const getPurchaseOrderBegin = () => ({
    type : GET_PURCHASE_ORDER_LOADING
})

export const getPurchaseOrderSuccess = (data) => ({
    type : GET_PURCHASE_ORDER_SUCCESS,
    data
})

export const getPurchaseOrderFailure = (error) => ({
    type : GET_PURCHASE_ORDER_FAILURE,
    error
    
})

export const delPurchaseOrderBegin = () => ({
    type : DEL_PURCHASE_ORDER_LOADING
})

export const delPurchaseOrderSuccess = (data) => ({
    type : DEL_PURCHASE_ORDER_SUCCESS,
    data
})

export const delPurchaseOrderFailure = (error) => ({
    type : DEL_PURCHASE_ORDER_FAILURE,
    error
    
})

export const cleanPurchaseOrderData = () => ({
    type : CLEAN_PURCHASE_ORDER_DATA
    
})





// bhanu



export const chpAumBegin = () => ({
    type : CHP_AUM_LOADING
})

export const chpAumSuccess = (data) => ({
    type : CHP_AUM_SUCCESS,
    data
})

export const chpAumFailure = (error) => ({
    type : CHP_AUM_FAILURE,
    error
    
})

export const cleanChpAum = () => ({
    type : CLEAN_CHP_AUM_DATA
    
})





// Client


export const newMatBegin = () => ({
    type : CLIENT_NEW_MATERIAL_LOADING
})

export const newMatSuccess = (newMatData) => ({
    type : CLIENT_NEW_MATERIAL_SUCCESS,
    newMatData
})

export const newMatFailure = (newMatError) => ({
    type : CLIENT_NEW_MATERIAL_FAILURE,
    newMatError
    
})




export const machineReportBegin = () => ({
    type : CLIENT_MACHINE_REPORT_LOADING
})

export const machineReportSuccess = (machineReportData) => ({
    type : CLIENT_MACHINE_REPORT_SUCCESS,
    machineReportData
})

export const machineReportFailure = (machineReportError) => ({
    type : CLIENT_MACHINE_REPORT_FAILURE,
    machineReportError
    
})




export const manpowerReportBegin = () => ({
    type : CLIENT_MANPOWER_REPORT_LOADING
})

export const manpowerReportSuccess = (manpowerReportData) => ({
    type : CLIENT_MANPOWER_REPORT_SUCCESS,
    manpowerReportData
})

export const manpowerReportFailure = (manpowerReportError) => ({
    type : CLIENT_MANPOWER_REPORT_FAILURE,
    manpowerReportError
    
})



export const siteExpBegin = () => ({
    type : CLIENT_SITE_EXPENSE_LOADING
})

export const siteExpSuccess = (siteExpData) => ({
    type : CLIENT_SITE_EXPENSE_SUCCESS,
    siteExpData
})

export const siteExpFailure = (siteExpError) => ({
    type : CLIENT_SITE_EXPENSE_FAILURE,
    siteExpError
    
})




export const siteProgBegin = () => ({
    type : CLIENT_SITE_PROGRESS_LOADING
})

export const siteProgSuccess = (siteProgData) => ({
    type : CLIENT_SITE_PROGRESS_SUCCESS,
    siteProgData
})

export const siteProgFailure = (siteProgError) => ({
    type : CLIENT_SITE_PROGRESS_FAILURE,
    siteProgError
    
})


export const siteDrawBegin = () => ({
    type : CLIENT_SITE_DRAWINGS_LOADING
})

export const siteDrawSuccess = (siteDrawData) => ({
    type : CLIENT_SITE_DRAWINGS_SUCCESS,
    siteDrawData
})

export const siteDrawFailure = (siteDrawError) => ({
    type : CLIENT_SITE_DRAWINGS_FAILURE,
    siteDrawError
    
})

export const cleanSiteDrawingData = () => ({
    type : CLEAN_SITE_DRAWINGS_DATA,
    
    
})


export const ClientAddTicketBegin = () => ({
    type : CLIENT_ADD_TICKET_LOADING
})

export const ClientAddTicketSuccess = (Data) => ({
    type : CLIENT_ADD_TICKET_SUCCESS,
    Data
})

export const ClientAddTicketFailure = (Error) => ({
    type : CLIENT_ADD_TICKET_FAILURE,
    Error    
})

export const ClientGetTicketBegin = () => ({
    type : CLIENT_GET_TICKET_LOADING
})

export const ClientGetTicketSuccess = (Data) => ({
    type : CLIENT_GET_TICKET_SUCCESS,
    Data
})

export const ClientGetTicketFailure = (Error) => ({
    type : CLIENT_GET_TICKET_FAILURE,
    Error    
})

export const cleanTicketData = () => ({
    type : CLEAN_TICKET_DATA,
    
    
})


export const ClientProfileBegin = () => ({
    type : CLIENT_PROFILE_LOADING
})

export const ClientProfileSuccess = (Data) => ({
    type : CLIENT_PROFILE_SUCCESS,
    Data
})

export const ClientProfileFailure = (Error) => ({
    type : CLIENT_PROFILE_FAILURE,
    Error    
})


export const ClientGetProfileBegin = () => ({
    type : CLIENT_GET_PROFILE_LOADING
})

export const ClientGetProfileSuccess = (Data) => ({
    type : CLIENT_GET_PROFILE_SUCCESS,
    Data
})

export const ClientGetProfileFailure = (Error) => ({
    type : CLIENT_GET_PROFILE_FAILURE,
    Error    
})

export const cleanProfileData = () => ({
    type : CLEAN_PROFILE_DATA,
    
    
})


export const ClientProfilePicBegin = () => ({
    type : CLIENT_PROFILE_PIC_LOADING
})

export const ClientProfilePicSuccess = (Data) => ({
    type : CLIENT_PROFILE_PIC_SUCCESS,
    Data
})

export const ClientProfilePicFailure = (Error) => ({
    type : CLIENT_PROFILE_PIC_FAILURE,
    Error    
})

export const cleanProfilePicData = () => ({
    type : CLEAN_PROFILE_PIC_DATA,
    
    
})


export const faqBegin = () => ({
    type : CLIENT_FAQ_LOADING
})

export const faqSuccess = (faqData) => ({
    type : CLIENT_FAQ_SUCCESS,
    faqData
})

export const faqFailure = (faqError) => ({
    type : CLIENT_FAQ_FAILURE,
    faqError
    
})



export const DailyReportBegin = () => ({
    type : DAILYREPORT_LOADING
})

export const DailyReportSucess = (data) => ({
    type : DAILYREPORT_SUCCESS,
    data
})

export const DailyReportFailure = (error) => ({
    type : DAILYREPORT_FAILURE,
    error
    
})
export const MonthlyReportBegin = () => ({
    type : MONTHLYREPORT_LOADING
})

export const MonthlyReportSucess = (data) => ({
    type : MONTHLYREPORT_SUCCESS,
    data
})

export const MonthlyReportFailure = (error) => ({
    type : MONTHLYREPORT_FAILURE,
    error
    
})

export const GetBoqLoading = () => ({
    type : GETBOQ_LOADING
})

export const GetBoqSucess = (data) => ({
    type : GETBOQ_SUCCESS,
    data
})

export const GetBoqFailure = (error) => ({
    type : GETBOQ_FAILURE,
    error
    
})