// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addMaterialMatBegin , addMaterialMatSuccess , addMaterialMatFailure } from '../ActionCreators';
import {getMaterialMatBegin , getMaterialMatSuccess , getMaterialMatFailure} from '../ActionCreators';
import {delMaterialMatBegin , delMaterialMatSuccess ,  delMaterialMatFailure} from '../ActionCreators';
import {editMaterialMatBegin , editMaterialMatSuccess ,  editMaterialMatFailure} from '../ActionCreators';

export const addMaterialMat = (session) => {    
    return (dispatch) => {
        dispatch(addMaterialMatBegin());

        axios.post(Base_URL + '/subAdmin/addMaterialMatMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addMaterialMatSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addMaterialMatFailure(err.response.data));

                }
            })
    }
}

export const getMaterialMat = (x) => {

    return (dispatch) => {
        dispatch(getMaterialMatBegin());

        axios.get(Base_URL + '/subAdmin/getMaterialMatMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getMaterialMatSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getMaterialMatFailure(err.response.data));

                }
            })
    }
}

export const delMaterialMat = (x) => {

    return (dispatch) => {
        dispatch(delMaterialMatBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delMaterialMatMaster' , {params :{mm_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delMaterialMatSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delMaterialMatFailure(err.response.data));

                }
            })
    }
}

export const editMaterialMat = (session) => {

    return (dispatch) => {
        dispatch(editMaterialMatBegin());

        axios.post(Base_URL + '/subAdmin/editMaterialMatMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editMaterialMatSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editMaterialMatFailure(err.response.data));

                }
            })
    }
}
