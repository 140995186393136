import axios from "../../Config/Interceptor1";


// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {siteDrawBegin , siteDrawSuccess , siteDrawFailure} from '../ActionCreators'


export const siteDraw = (x) => {
   
    return (dispatch) => {
        dispatch(siteDrawBegin());
        
        axios.get(Base_URL + '/client/getdraw' , {params : x})
            .then((res) => {
                dispatch(siteDrawSuccess(res.data));
                console.log("data======", res.data)
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")
                    dispatch(siteDrawFailure({}));

                }
                else {
                    dispatch(siteDrawFailure(err.response.data));

                }
            })
    }
}


