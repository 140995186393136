import {ADD_FAQ_LOADING , ADD_FAQ_SUCCESS , ADD_FAQ_FAILURE } from '../../Actions/Types';
import {GET_FAQ_LOADING , GET_FAQ_SUCCESS , GET_FAQ_FAILURE } from '../../Actions/Types';

import {EDIT_FAQ_LOADING , EDIT_FAQ_SUCCESS , EDIT_FAQ_FAILURE } from '../../Actions/Types';
import {DEL_FAQ_LOADING , DEL_FAQ_SUCCESS , DEL_FAQ_FAILURE } from '../../Actions/Types';
import {CLEAN_ALL_DATA , CLEAN_FAQ_DATA} from '../../Actions/Types';



const AddFaq = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{}
            
        }


        case CLEAN_FAQ_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            editData : [{}],
            editError : {},
            delData : [{}], 
            delError:{}
        }

    
    case ADD_FAQ_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            data : [{}]
            
        }
    case ADD_FAQ_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case ADD_FAQ_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }



         
    case EDIT_FAQ_LOADING:
        return {
            ...state,
            loading:true,
            editError: {},
            editData : [{}]
            
        }
    case EDIT_FAQ_SUCCESS:
        return {
            ...state,
            loading:false,
            editError:{},
            editData:action.data
        }
    case EDIT_FAQ_FAILURE:
        return {
            ...state,
            loading:false,
            editError:action.error,
            editData:{},
        }



    case DEL_FAQ_LOADING:
        return {
            ...state,
            loading:true,
            delError: {},
            delData : [{}]
            
        }
    case DEL_FAQ_SUCCESS:
        return {
            ...state,
            loading:false,
            delError:{},
            delData:action.data,
            
        }
    case DEL_FAQ_FAILURE:
        return {
            ...state,
            loading:false,
            delError:action.error,
            delData:{},
        }




        case GET_FAQ_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                
            }
        case GET_FAQ_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data
            }
        case GET_FAQ_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   


    default:
        return state

}
}



export default AddFaq;
