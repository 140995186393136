// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addSubRoleBegin , addSubRoleSuccess , addSubRoleFailure} from './../ActionCreators'
import {getSubRoleBegin , getSubRoleSuccess , getSubRoleFailure} from './../ActionCreators'
import {delSubRoleBegin , delSubRoleSuccess , delSubRoleFailure} from './../ActionCreators'
import {editSubRoleBegin , editSubRoleSuccess , editSubRoleFailure} from './../ActionCreators';

export const addSubRole = (session) => {

    return (dispatch) => {
        dispatch(addSubRoleBegin());

        axios.post(Base_URL + '/subadmin/addSubRole', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addSubRoleSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addSubRoleFailure(err.response.data));

                }
            })
    }
}


export const getSubRole = (x) => {

    return (dispatch) => {
        dispatch(getSubRoleBegin());

        axios.get(Base_URL + '/subadmin/getSubRole' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getSubRoleSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSubRoleFailure(err.response.data));

                }
            })
    }
}

export const delSubRole = (x) => {

    return (dispatch) => {
        dispatch(delSubRoleBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delSubRole' , {params :{sub_role_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delSubRoleSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delSubRoleFailure(err.response.data));

                }
            })
    }
}

export const editSubRole = (session) => {

    return (dispatch) => {
        dispatch(editSubRoleBegin());

        axios.post(Base_URL + '/subadmin/editSubRole', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editSubRoleSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editSubRoleFailure(err.response.data));

                }
            })
    }
}

