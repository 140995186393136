import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import MaterialTable from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/CloudDownload";
import tableIcons from "../../../Config/IconsFile";
import Loader from "react-loader-spinner";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardMedia,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {
  getSiteDraw,
  delSiteDraw,
} from "../../../Actions/SubAdminActions/SiteDrawing";
import { cleanSiteDraw } from "../../../Actions/ActionCreators";
import { getExportFileBlob } from "../../../Config/excelPdfDownload";
import * as XLSX from "xlsx";

const BoqListing = () => {
  const navigate = useNavigate();
  const [deletingBoq, setDeletingBoq] = useState(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [boqData, setBoqData] = useState([]); // State variable to store fetched BOQ data
  const [loading, setLoading] = useState(true);
  const [openDeleteAllConfirm, setOpenDeleteAllConfirm] = useState(false);

  const handleOpenDeleteAllConfirm = () => {
    setOpenDeleteAllConfirm(true);
  };

  const handleCloseDeleteAllConfirm = () => {
    setOpenDeleteAllConfirm(false);
  };

  const handleConfirmDeleteAll = () => {
    deleteAllBoq();
    setOpenDeleteAllConfirm(false);
  };
  const a_id = useSelector((state) =>
    state.login && state.login.data && state.login.data.r_subadmin
      ? state.login.data.r_subadmin
      : ""
  );
  const selected_site = useSelector((state) =>
    state.SelectedSite &&
    state.SelectedSite.sitedata &&
    state.SelectedSite.sitedata.site
      ? state.SelectedSite.sitedata.site
      : null
  );

  const role_permit = useSelector((state) =>
    state.login &&
    state.login.data &&
    state.login.data.role_permit &&
    state.login.data.role_permit.procurement
      ? state.login.data.role_permit.site_boq
      : []
  );
  useEffect(() => {
    
    const getSiteDraw = (a_id, selected_site) => {
      setLoading(true);
      axios
        .get(Base_URL + "/subadmin/getBoq", {
          params: { sub_admin_id: a_id, site: selected_site },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data && res.data.boq_list) {
            setBoqData(res.data.boq_list);
          } else {
            setBoqData([]); // No boq_list in response, set state variable to empty array
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          if (!err.response) {
            alert(
              "Something Went wrong : Please Check Network or Server Connectivity"
            );
          }
        });
    };

    getSiteDraw(a_id, selected_site);
  }, [selected_site, a_id]);

  const onEdit = (olData) => {
    console.log(olData);
    navigate("/app/edit_boq/" + olData.boq_id, { state: olData });
  };

  const onDelete = (rowData) => {
    setDeletingBoq(rowData);
  };

  const deleteBoq = () => {
    axios
      .get(Base_URL + "/subadmin/deleteBoq", {
        params: { boq_id: deletingBoq.boq_id },
      })
      .then((response) => {
        if (response.data.status === 200) {
          tostS("Deletion successful");
          // Remove the deleted item from the state variable
          setBoqData(
            boqData.filter((item) => item.boq_id !== deletingBoq.boq_id)
          );
        } else {
          tostE("Failed to delete BOQ: " + response.data.message);
        }
      })
      .catch((err) => {
        tostE("Error deleting BOQ: " + err.message);
      });
    setDeletingBoq(null); // Clear the deletingBoq state
  };

  const addBoq = () => {
    setOpenCreateDialog(true);
  };

  const handleClose = () => {
    setOpenCreateDialog(false);
  };
  // const handleOpenDeleteAllDialog = () => {
  //   setOpenDeleteAllDialog(true);
  // };
  // const handleCloseDeleteAllDialog = () => {
  //   setOpenDeleteAllDialog(false);
  // };
  const totalPriceSum = boqData.reduce((acc, curr) => {
    const totalPrice = parseFloat(curr.total_price);
    return !isNaN(totalPrice) ? acc + totalPrice : acc;
  }, 0);
  const percentageValue =
    boqData.length > 0 && !isNaN(parseFloat(boqData[0].percentage_boq_value))
      ? parseFloat(boqData[0].percentage_boq_value)
      : 0;
  const percentageType =
    boqData.length > 0 && boqData[0].percentage_boq_type !== null
      ? boqData[0].percentage_boq_type
      : "";

  const finalTotalPrice =
    percentageType === "positive"
      ? totalPriceSum + (totalPriceSum * percentageValue) / 100
      : percentageType === "negative"
      ? totalPriceSum - (totalPriceSum * percentageValue) / 100
      : totalPriceSum;

  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(boqData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BOQ Data");
    XLSX.writeFile(workbook, "BOQ_Data.xlsx");
  };

  const deleteAllBoq = () => {
    axios
      .get(Base_URL + "/subadmin/deleteAllBoq", {
        params: { site_id: selected_site },
      })
      .then((response) => {
        if (response.data.status === 200) {
          tostS("All BOQ items deleted successfully");
          setBoqData([]);
        } else {
          tostE("Failed to delete all BOQ items: " + response.data.message);
        }
      })
      .catch((err) => {
        tostE("Error deleting all BOQ items: " + err.message);
      });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Loader type="Bars" color="#00BFFF" height={80} width={80} />
      </Box>
    );
  }

  return (
    <>
      <Page title="BOQ">
        <Dialog open={openCreateDialog} onClose={handleClose}>
          <DialogTitle>Create New BOQ</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">
              Choose a method to create a new BOQ:
            </Typography>
            <Button
              onClick={() => {
                navigate("/app/add_manually_boq");
                handleClose();
              }}
              color="primary"
              style={{ marginRight: "20px" }}
            >
              Add Manually
            </Button>
            <Button
              onClick={() => {
                navigate("/app/create_boq");
                handleClose();
              }}
              color="primary"
            >
              Upload Excel
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={!!deletingBoq} onClose={() => setDeletingBoq(null)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this BOQ?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeletingBoq(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={deleteBoq} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <ToastContainer />
        <Container maxWidth={false}>
          <div>
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ paddingTop: "10px" }}
            >
              {role_permit[3] ? (
                <Button onClick={addBoq} color="primary" variant="contained">
                  Create
                </Button>
              ) : (
                <></>
              )}

              {boqData.length > 0 && (
                <Button
                  onClick={generateExcel}
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "10px" }}
                >
                  Export to Excel
                </Button>
              )}

              {/* {boqData.length > 0 && (
                <Button
                  style={{ marginLeft: "15px" }}
                  onClick={() => navigate("/app/generate_bill_mb")}
                  color="primary"
                  variant="contained"
                >
                  Generate Bill
                </Button>
              )} */}

              {/* {boqData.length > 0 && (
                <Button
                  style={{ marginLeft: "15px" }}
                  onClick={() => navigate("/app/View_MB")}
                  color="primary"
                  variant="contained"
                >
                  Measurement Book
                </Button>
              )} */}
            </Box>
          </div>

          <Box mt={3}>
            <Card>
              <MaterialTable
                icons={tableIcons}
                title="BOQ (Bill of Quantity)"
                columns={[
                  {
                    title: "SR. No.",
                    render: (rowData) => rowData.tableData.id + 1,
                  },
                  { title: "Item Description", field: "item_description" },
                  {
                    title: "Quantity",
                    field: "quantity",
                    render: (rowData) =>
                      isNaN(rowData.quantity) ? "___" : rowData.quantity,
                  },
                  {
                    title: "Used Quantity",
                    field: "used_quantity",
                    render: (rowData) => {
                      const usedQuantity = parseInt(rowData.used_quantity);
                      return isNaN(usedQuantity) ? "___" : usedQuantity;
                    },
                  },

                  { title: "Unit", field: "unit" },
                  {
                    title: "Rate",
                    field: "unit_price",
                    render: (rowData) =>
                      isNaN(rowData.unit_price) ? "___" : rowData.unit_price,
                  },
                  {
                    title: "Total Price",
                    field: "total_price",
                    render: (rowData) =>
                      isNaN(rowData.total_price) ? "___" : parseFloat(rowData.total_price).toFixed(2),
                  },
                  {
                    title: "Progress",
                    render: (rowData) => (
                      <Box textAlign="center">
                        <Typography variant="body2">
                          {`${(
                            (rowData.used_quantity / rowData.quantity) *
                            100
                          ).toFixed(0)}%`}
                        </Typography>
                        <Box mt={1}>
                          <LinearProgress
                            variant="determinate"
                            value={
                              (rowData.used_quantity / rowData.quantity) *
                                100 || 0
                            }
                          />
                        </Box>
                      </Box>
                    ),
                  },
                  {
                    title: "Action",
                    render: (rowData) =>
                      rowData.editaccess ? (
                        "---"
                      ) : (
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <EditIcon
                              onClick={() => {
                                onEdit(rowData);
                              }}
                              style={{ cursor: "pointer", marginRight: 10 }}
                            />
                          </Grid>
                          <Grid item>
                            <DeleteIcon
                              onClick={() => onDelete(rowData)}
                              style={{ cursor: "pointer" }}
                            />
                          </Grid>
                        </Grid>
                      ),
                  },
                ]}
                data={boqData}
              />
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{
                  padding: "10px",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="h5">
                  Total Amount: {totalPriceSum.toFixed(2)}
                </Typography>
                {boqData.some(
                  (item) => parseFloat(item.percentage_boq_value) !== 0
                ) && (
                  <Typography variant="h5" style={{ marginTop: "10px" }}>
                    Percentage BOQ: {percentageValue} ({percentageType})
                  </Typography>
                )}
                <Typography variant="h5" style={{ marginTop: "10px" }}>
                  Final Total Amount: {finalTotalPrice.toFixed(2)}
                </Typography>
              </Box>
            </Card>
          </Box>
          {boqData && boqData.length > 0 && !boqData[0].editaccess ? (
            <Button
              onClick={handleOpenDeleteAllConfirm}
              color="secondary"
              variant="contained"
            >
              Delete All
            </Button>
          ) : (
            <></>
          )}

          {/* <Button
            onClick={handleOpenDeleteAllConfirm}
            color="secondary"
            variant="contained"
          >
            Delete All
          </Button> */}
        </Container>
        <Dialog
          open={openDeleteAllConfirm}
          onClose={handleCloseDeleteAllConfirm}
        >
          <DialogTitle>Confirm Delete All</DialogTitle>
          <DialogContent>
            Are you sure you want to delete all BOQ items for this site?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteAllConfirm} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDeleteAll} color="primary" autoFocus>
              Delete All
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    </>
  );
};

export default BoqListing;
