import React ,  {useRef , useState , useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  IconButton,
  OutlinedInput,
  InputAdornment,
  
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {ClientProfile, ClientProfilePic , ClientGetProfile} from '../../../Actions/Client/Profile'
import {cleanProfileData, cleanProfilePicData} from '../../../Actions/ActionCreators'
import {cleanChpAum} from "../../../Actions/ActionCreators";
import { changeClientAppUserPassword} from "../../../Actions/SubAdminActions/AppUserManagement";

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Profile Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false);


  const login_data = useSelector(state => state.login && state.login.data ? state.login.data : {});


  const id = useSelector(state => state.login.data.apu_id);
  const a_name = useSelector(state => state.ClientAllGet && state.ClientAllGet.getprofile && Array.isArray(state.ClientAllGet.getprofile) && state.ClientAllGet.getprofile.length ? state.ClientAllGet.getprofile[0].name : "");
  const a_email = useSelector(state => state.ClientAllGet && state.ClientAllGet.getprofile && Array.isArray(state.ClientAllGet.getprofile) && state.ClientAllGet.getprofile.length ? state.ClientAllGet.getprofile[0].email:"" );
  const a_mobile = useSelector(state =>state.ClientAllGet && state.ClientAllGet.getprofile && Array.isArray(state.ClientAllGet.getprofile) && state.ClientAllGet.getprofile.length ? state.ClientAllGet.getprofile[0].mobile : "");
  const a_folder = useSelector(state =>state.ClientAllGet && state.ClientAllGet.getprofile && Array.isArray(state.ClientAllGet.getprofile) && state.ClientAllGet.getprofile.length ? state.ClientAllGet.getprofile[0].folder_name : "");
  const a_image = useSelector(state => 
  state.ClientAllGet && 
  state.ClientAllGet.getprofile && 
  Array.isArray(state.ClientAllGet.getprofile) && 
  state.ClientAllGet.getprofile.length && 
  state.ClientAllGet.getprofile[0].image && 
  Array.isArray(state.ClientAllGet.getprofile[0].image) && 
  state.ClientAllGet.getprofile[0].image.length ? 
  state.ClientAllGet.getprofile[0].image[0] : 
  ""
);

  const a_address = useSelector(state =>state.ClientAllGet && state.ClientAllGet.getprofile && Array.isArray(state.ClientAllGet.getprofile) && state.ClientAllGet.getprofile.length ? state.ClientAllGet.getprofile[0].address : "");

  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  const [values, setValues] = useState({
   
  apu_id : id,
  assign_role:"Client",
  name : a_name !=="" && a_name !==null && a_name !==undefined ? a_name : login_data.name,
  email : a_email !=="" && a_email !==null && a_email !==undefined ? a_email : login_data.email,
  mobile : a_mobile !=="" && a_mobile !==null && a_mobile !==undefined ? a_mobile : login_data.mobile,  
  address : a_address !=="" && a_address !==null && a_address !==undefined ? a_address : login_data.address,
  folder_name : a_folder,  
  img : a_image,
  image1:"",
  // tempImage1:"",

  // flag:false,

 

  fixImg : "/static/images/avatars/upload.png"

  });

console.log(a_image);




  const emailRegex = /\S+@\S+\.\S+/;
  var nameRegex = /([a-zA-Z]{1,}\s?[a-zA-Z]+)+/;
  const mobileRegex = /^[6-9][0-9]{9}$/;

  

  const [pass, setPass] = useState({
    apu_id:id,
    password:"",
    confirm_pass:"",
    showPassword:false,
    showConfirmPass:false
  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePass = (event) => {
    setPass({
      ...pass,
      [event.target.name]: event.target.value,
    });
  };

 

  const get_data = useSelector(state => state.ClientAllGet.getprofile);

  // const image_data = useSelector(state => state.AddSubProfile.getData[0].image);

  const handleSubmit = () => {
    setOpenDialog(true);
  };
  const handleConfirm = () => {
    // Handle confirmation here
    if(values.name==="" || values.name===null || values.name===undefined){
      tostE("Please Enter Name")
    }else if(values.email==="" || values.email===null || values.email===undefined){
      tostE("Please Enter Email")
    }else if(values.mobile==="" || values.mobile===null || values.mobile===undefined){
      tostE("Please Enter Mobile")
    }else{
      if (!emailRegex.test(values.email))
      {
        tostE("Please Enter Valid Email");
      }
      else if (!nameRegex.test(values.name))
      {
        tostE("Name Must be Character")
      }
      else if (!mobileRegex.test(values.mobile))
      {
        tostE("Please Enter Valid Mobile Number")
      }else{
        const sendData = {
          app_uid:id,
          assign_role:"Client",
          name:values.name,
          email:values.email,
          mobile:values.mobile,
          address: values.address
        }
        console.log(sendData)
        dispatch(ClientProfile(sendData))
      }
    }
    setOpenDialog(false);
  };
  
  const handleConfirm5 = () =>{
    console.log(pass)


    if(pass.password === "" || pass.password === null || pass.password === undefined)
    {
      tostE("Please Enter Password")
    }
    else if(pass.confirm_pass === "" || pass.confirm_pass === null || pass.confirm_pass === undefined)
    {
      tostE("Please Enter Confirm Password")

    }
    else if(pass.password !== pass.confirm_pass )
    {
        tostE("Password Didn't Match !!")
    }
    else
    {
      console.log(pass)
      dispatch(changeClientAppUserPassword({apu_id : id , password : pass.password}))
      setPass({
        ...pass,
        password:"",
        confirm_pass:""
      })
    
    }
    

  }
  
  const handleClickShowPassword = () => {
    setPass({
      ...pass,
      showPassword: !pass.showPassword,
    });
  };
  const handleClickShowConfirmPass = () => {
    setPass({
      ...pass,
      showConfirmPass: !pass.showConfirmPass,
    });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  const profilemsg = useSelector((state) => state.ClientAllGet && state.ClientAllGet.profile && state.ClientAllGet.profile.message ? state.ClientAllGet.profile.message : null )
  const profileError = useSelector((state) => state.ClientAllGet && state.ClientAllGet.profileError && state.ClientAllGet.profileError.message ? state.ClientAllGet.profileError.message : null )
  const profilepicmsg = useSelector((state) => state.ClientAllGet && state.ClientAllGet.profilePic && state.ClientAllGet.profilePic.message ? state.ClientAllGet.profilePic.message : null )

  const chpMsg = useSelector(state => state.AppUserMgmt && state.AppUserMgmt.passData && state.AppUserMgmt.passData.message ? state.AppUserMgmt.passData.message : null  )
  const chpError = useSelector(state => state.AppUserMgmt && state.AppUserMgmt.passError && state.AppUserMgmt.passError.message ? state.AppUserMgmt.passError.message : null  )
 
 
  useEffect( () => {
  
    dispatch(ClientGetProfile(id))
  
    var r  = new Promise((resolve , reject)=>{
      if (profilemsg !== null && typeof(profilemsg) === 'string')
      {
        tostS(profilemsg); 
      }
      if (profileError !== null && typeof(profileError) === 'string')
      {
        tostS(profileError); 
      }
      if (profilepicmsg !== null && typeof(profilepicmsg) === 'string')
      {
        tostS(profilepicmsg); 
      }

      if(chpMsg && typeof(chpMsg) === 'string')
      {
        tostS(chpMsg)
      }
      if(chpError && typeof(chpError) === 'string')
      {
          tostE(chpError)
      }
      resolve()
     
     }) 
     r.then(()=>{
      dispatch(cleanChpAum())
      dispatch(cleanProfileData());
      dispatch(cleanProfilePicData());
      dispatch(ClientGetProfile(id));

    })
     
   }, [profilemsg,profileError, profilepicmsg, chpMsg ,chpError ])






  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChangeimg = event => {
    const fileUploaded = event.target.files[0]
    setValues({...values , ['img']:fileUploaded , ['fixImg']:URL.createObjectURL(fileUploaded)})
    if(event.target.files[0] && event.target.files.length !==0)

    {
      const formData = new FormData();
      formData.append("apu_id",id );
      formData.append("folder_name",a_folder)
      formData.append("img", fileUploaded);
      
      
      dispatch(ClientProfilePic(formData))

    }


  };
  
  /********* Image Handling   ************** */

  return (
    <Page
      className={classes.root}
      title="Profile"
    >
              <ToastContainer    />

              <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            {/* <Profile /> */}
            <Card
      className={clsx(classes.root2, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
         
            className={classes.avatar}
            src={values.img !== null ?  a_image : values.fixImg }
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>

          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeimg}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClick}
        >
        {/* { values.flag === false ? 'Upload Picture' : "Update Picture" } */}
        Upload Picture
        
        </Button>
      </CardActions>
    </Card>
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Profile Management" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required                
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone/Mobile"
                name="mobile"
                onChange={handleChange}
                required
                type="number"
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>

          <Button onClick={handleSubmit} color="primary" variant="contained">
            Update
          </Button>

          <Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Are You Really Want To Update Your Profile?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog(false)} color="primary">
      No
    </Button>
    <Button onClick={handleConfirm} color="primary" autoFocus>
      Yes
    </Button>
  </DialogActions>
</Dialog>

            </Grid>
            <Grid item md={6} xs={12}>
              
            </Grid>


            <Grid item md={6} xs={12}>
            <CardHeader subheader="" title="Change Authentication" />
             
            </Grid>

            <Grid item md={6} xs={12}>
             
            </Grid>


            <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="password"
                type={pass.showPassword ? 'text' : 'password'}
                value={pass.password}
                onChange={handleChangePass}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {pass.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="confirm_pass"
                type={pass.showConfirmPass ? 'text' : 'password'}
                value={pass.confirm_pass}
                onChange={handleChangePass}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPass}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {pass.showConfirmPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            </Grid>
    
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-start" p={2}>

          
          <Button onClick={handleConfirm5} color="primary" variant="contained">
            Change
          </Button>
        </Box>
      </Card>
    </form>
            
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
