import {CLEAN_ALL_DATA, SELECTED_SITE} from '../../Actions/Types'

const SelectedSite = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            sitedata:{site : null, name : null}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                

                ...state,
                sitedata: {site : null, name : null},
                loading:false,                             
            }                
        case SELECTED_SITE:

            return {
                ...state,
                sitedata: action.SiteData.site  === null  || action.SiteData.site  ===  undefined || action.SiteData.site  ===  ""  ? {site : null , name : null }  :action.SiteData,
                loading:false,                             
            }                

        default:
        return state
    
    }
    }
    
    
    
export default SelectedSite;
    