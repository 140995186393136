import { combineReducers  } from "redux";
import login from './Login';

import Demo from './Demo';


import AddPlan from './SuperAdmin/PlanAdd'
import AddClients from './SuperAdmin/AddClients'
import AddRoles from './SuperAdmin/AddRole'
import AddPanelUser from './SuperAdmin/PanelUser'
import Enquiry from './SuperAdmin/Enquiry'
import Tickets from './SuperAdmin/Tickets'
import Gst from './SuperAdmin/Gst'
import SuperProfile from './SuperAdmin/Profile'
import AddFaq from "./SuperAdmin/Faq";


import Sub_Role from './SubAdmin/SubRole'
import  Sub_Tickets from './SubAdmin/Tickets'
import AppUserMgmt from './SubAdmin/AppUserManagement'
import AddSites from './SubAdmin/AddSites'
import AddManpowerCategory from './SubAdmin/M_ManpowerCategory'
import AddMaterialCategory from "./SubAdmin/M_MaterialCategory";
import AddVendor from "./SubAdmin/M_Vendor";
import AddResourceUnit from "./SubAdmin/M_ResourceUnit";
import AddResourceMachine from "./SubAdmin/M_ResourceMachine";

import AddResourceQuality from "./SubAdmin/M_ResourceQuality";
import SubSiteExp from './SubAdmin/M_SiteExpense';
import AddOtherSubAdmin from "./SubAdmin/M_OtherSubAdmin";
import SubDraw from './SubAdmin/M_Draw';
import SubSiteDraw from './SubAdmin/SiteDrawing';
import SubDrawType from './SubAdmin/M_DrawType';
import AddSubProfile from './SubAdmin/ProfileManagement';
import AddDrawingFloorType from './SubAdmin/M_DrawingFloorType';

import ManpowerManMaster from './SubAdmin/M_ManpowerMan';
import MaterialMatMaster from "./SubAdmin/M_MaterialMat";
import MaterialIndent  from "./SubAdmin/Material_Indent";
import MaterialSupply from "./SubAdmin/MaterialSupply";
import MaterialDelivery from "./SubAdmin/MaterialDelivery"
import MachineIndent from "./SubAdmin/Machine_Indent"
import MachineDelivery from "./SubAdmin/MachineDelivery";
import CashExp from "./SubAdmin/CashExpense";
import QualitySafety from './SubAdmin/QualitySafety'
import SiteProgress from "./SubAdmin/SiteProgress";
import SiteManpower from "./SubAdmin/SiteMan"

import MachineCat from "./SubAdmin/M_MachineCat";

import SubFaq from './SubAdmin/Faq'

import PurchaseOrder from "./SubAdmin/PurchaseOrder";

import SelectedSite from "./SubAdmin/SelectedSite";
import SubDateFilters from "./SubAdmin/DateFilters";
import ClientDateFilters from "./Client/DateFilters";


import DashBoard from './Dashboard'
import ResetPassword from "./ResetPassword";

import Stock from "./Inventory/Stock";
import StockTransfer from "./Inventory/Transfer";
import Delivery from "./Inventory/Delivery";
import GRN from "./Inventory/GRN";
import History from "./Inventory/History"


import  ClientAllGet from './Client/AllGet'


import SubGst from './SubAdmin/SubGst'
import GetReortAdmin from "./SuperAdmin/GetReports";
import DailyReportData from "./SubAdmin/DailyReports";
import MonthlyReportData from "./SubAdmin/MonthlyReport";
import BOQ from "./SubAdmin/BOQ";
import BOQDATA from "./SubAdmin/GetBoq";


export default combineReducers({
    login,
    AddFaq,
    Demo,
    AddPlan,
    AddClients,
    AddRoles,
    AddPanelUser,
    Enquiry,
    Tickets,
    SuperProfile,
    Gst,
    Sub_Role,
    Sub_Tickets,
    AppUserMgmt,
    AddSites,
    SubGst,
    SubSiteExp,
    SubDraw,
    SubDrawType,
    SubSiteDraw,
    AddManpowerCategory,
    AddMaterialCategory,
    AddVendor,
    AddResourceUnit,
    AddResourceMachine,
    AddResourceQuality,
    AddOtherSubAdmin,
    AddSubProfile,

    AddDrawingFloorType,

    ManpowerManMaster,
    MaterialMatMaster,
    MaterialIndent,
    MaterialSupply,
    MaterialDelivery,
    QualitySafety,
    MachineIndent,
    MachineDelivery,
    SiteProgress,
    CashExp,
    MachineCat,
    SiteManpower,
    SubFaq,
    PurchaseOrder,

    SelectedSite,
    SubDateFilters,
    ClientDateFilters,
    
    DashBoard,
    Stock,
    StockTransfer,
    GRN,
    Delivery,
    History,
    
    ResetPassword,
    ClientAllGet,
GetReortAdmin,
DailyReportData,
MonthlyReportData,
BOQ,
BOQDATA


});