// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addResourceQualityBegin , addResourceQualitySuccess , addResourceQualityFailure} from '../ActionCreators';
import {getResourceQualityBegin , getResourceQualitySuccess , getResourceQualityFailure } from '../ActionCreators';
import {delResourceQualityBegin , delResourceQualitySuccess , delResourceQualityFailure } from '../ActionCreators';
import {editResourceQualityBegin , editResourceQualitySuccess , editResourceQualityFailure } from '../ActionCreators';


export const addResourceQuality = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addResourceQualityBegin());

        axios.post(Base_URL + '/subAdmin/addQasMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addResourceQualitySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addResourceQualityFailure(err.response.data));

                }
            })
    }
}

export const getResourceQuality = (x) => {

    return (dispatch) => {
        dispatch(getResourceQualityBegin());

        axios.get(Base_URL + '/subAdmin/getQasMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getResourceQualitySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getResourceQualityFailure(err.response.data));

                }
            })
    }
}

export const delResourceQuality = (x) => {

    return (dispatch) => {
        dispatch(delResourceQualityBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delQasMaster' , {params :{sub_qas_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delResourceQualitySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delResourceQualityFailure(err.response.data));

                }
            })
    }
}

export const editResourceQuality = (session) => {

    return (dispatch) => {
        dispatch(editResourceQualityBegin());

        axios.post(Base_URL + '/subAdmin/editQasMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editResourceQualitySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editResourceQualityFailure(err.response.data));

                }
            })
    }
}

