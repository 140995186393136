import { DAILYREPORT_FAILURE, DAILYREPORT_LOADING, DAILYREPORT_SUCCESS } from "../../Actions/Types";


console.log('hereeee');
const DailyReportData = (state , action) => {
    console.log(action.type) 
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],  
            editError : {},
            editPassData :[{}],
            editPassError : {},
            delData:[{}],
            delError:{}
        }
    }




switch(action.type) {
    
   
    case DAILYREPORT_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case DAILYREPORT_SUCCESS:

    console.log('hereeeeeeeee');
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case DAILYREPORT_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }

    default:
        return state

}
}



export default DailyReportData;
