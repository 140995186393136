import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Card,
  CardContent,
  Divider,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
} from "@material-ui/core";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import jspdf-autotable for table creation

import { useLocation } from "react-router-dom";
import { tostE } from "../../../Config/Toast"; // Assuming this is defined somewhere

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const BillDetailsPage = () => {
  const classes = useStyles();
  const { state } = useLocation();

  // State variables
  const [billData, setBillData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (state) {
      console.log(state);
      setBillData(state.measurement_data); // Set measurement_data from state
      calculateTotalAmount(state.measurement_data); // Calculate total amount
    } else {
      // Handle case where state is null or undefined (optional)
      tostE("No bill data found");
    }
  }, [state]);

  const calculateTotalAmount = (data) => {
    let total = 0;
    data.forEach((row) => {
      total += row.quantity * row.unit_price;
    });
    setTotalAmount(total);
  };

  const handleExportPDF = () => {
    if (!billData || billData.length === 0) {
      tostE("No data available for export");
      return;
    }

    // Create new jsPDF instance
    const doc = new jsPDF();
    // Define table headers
    const headers = [
      ["Item Description", "Executed Quantity", "Unit Price", "Amount"]
    ];

    // Extract data from billData
    const data = billData.map((row) => [
      row.item_description,
      row.quantity,
      row.unit_price,
      (row.quantity * row.unit_price).toFixed(2), // Calculate amount per row and fix to 2 decimals
    ]);

    // Add total row to data
    const totalRow = ["Total", "", "", totalAmount.toFixed(2)]; // Total row data
    data.push(totalRow);

    // Set table options
    const options = {
      startY: 30, // Starting Y position for table (adjusted for date text)
      theme: 'grid',
      styles: {
        cellPadding: 4,
        fontSize: 10,
        halign: 'center',
        valign: 'middle'
      },
      columnStyles: {
        3: { halign: 'right' } // Right align the total amount column
      },
      foot: [[
        {content: "Total", colSpan: 3, styles: {halign: 'right'}},
        totalAmount.toFixed(2)
      ]],
    };

    // Add table to PDF
    doc.autoTable({
      head: headers,
      body: data,
      ...options
    });

    // Save PDF
    doc.save("measurement_bill.pdf");
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">Bill Details</Typography>
              <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExportPDF} // Call handleExportPDF function on button click
                >
                  Export to PDF
                </Button>
              </Box>
              <TableContainer component={Paper}>
                <Table aria-label="bill table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item Description</TableCell>
                      <TableCell>Executed Quantity</TableCell>
                      <TableCell>unit</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Remark</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {billData && billData.length > 0 ? (
                      billData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.item_description || "-"}</TableCell>
                          <TableCell>{row.quantity || "-"}</TableCell>
                          <TableCell>{row.unit || "-"}</TableCell>
                          <TableCell>{row.unit_price || "-"}</TableCell>
                          <TableCell>
                            {(row.quantity * row.unit_price).toFixed(2) || "-"}
                          </TableCell>
                          <TableCell>{row.remark || "-"}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box display="flex" justifyContent="flex-end" p={2}>
                <Typography variant="h6">
                  Total Amount: {totalAmount.toFixed(2)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BillDetailsPage;
