import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {getMaterialDelivery} from '../../../Actions/SubAdminActions/Material_delivery'
import {getMachineDelivery} from '../../../Actions/SubAdminActions/Machine_delivery'
import {getSiteMan} from '../../../Actions/SubAdminActions/SiteMan'
import {getCashExp} from '../../../Actions/SubAdminActions/CashExpense'


import {getMaterialCategory} from '../../../Actions/SubAdminActions/M_MaterialCategory'
import {getMachineCat} from '../../../Actions/SubAdminActions/M_MachineCat'
import {getManpowerCategory} from '../../../Actions/SubAdminActions/M_ManpowerCategory'
import {getSiteExp} from '../../../Actions/SubAdminActions/M_SiteExpense'


import {getMaterialMat} from '../../../Actions/SubAdminActions/M_MaterialMat'
import {getResourceMachine} from '../../../Actions/SubAdminActions/M_ResourceMachine'
import {getManpowerMan} from '../../../Actions/SubAdminActions/M_ManpowerMan'



import {getExportFileBlob} from '../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  
  const id = useSelector(state => state.login.data.r_subadmin);


  const [values, setValues] = useState({
   
    admin_id :id,
    start_date:new Date(Date.now()),
    end_date:new Date(Date.now()),
    report_type:"",
    category:"",
    name:"",
    category_data:[],
    name_data:[],
    disableCategory:true,
    disableName:true
  });
  
  

  const MatDeliveryData = useSelector((state) => state.MaterialDelivery && state.MaterialDelivery.getData && Array.isArray(state.MaterialDelivery.getData) && state.MaterialDelivery.getData.length ? (state.MaterialDelivery.getData).filter(data => data.status==="Delivered"):[])    
  const MacDeliveryData = useSelector((state) => state.MachineDelivery && state.MachineDelivery.getData && Array.isArray(state.MachineDelivery.getData) && state.MachineDelivery.getData.length ? (state.MachineDelivery.getData).filter(data => data.status==="Delivered"):[])    
  const SiteManpowerData = useSelector((state) => state.SiteManpower && state.SiteManpower.getData && Array.isArray(state.SiteManpower.getData) && state.SiteManpower.getData.length ? (state.SiteManpower.getData).filter(data => data.status==="Accepted"):[])    
  const CashExpData = useSelector((state) => state.CashExp && state.CashExp.getData && Array.isArray(state.CashExp.getData) && state.CashExp.getData.length ? (state.CashExp.getData).filter(data => data.status==="Accepted"):[])    
  
  const MatCatData = useSelector((state) => state.AddMaterialCategory && state.AddMaterialCategory.getData && Array.isArray(state.AddMaterialCategory.getData) && state.AddMaterialCategory.getData.length ? state.AddMaterialCategory.getData:[])    
  const MacCatData = useSelector((state) => state.MachineCat && state.MachineCat.getData && Array.isArray(state.MachineCat.getData) && state.MachineCat.getData.length ? state.MachineCat.getData:[])    
  const ManCatData = useSelector((state) => state.AddManpowerCategory && state.AddManpowerCategory.getData && Array.isArray(state.AddManpowerCategory.getData) && state.AddManpowerCategory.getData.length ? state.AddManpowerCategory.getData:[])    
  const ExpCatData = useSelector((state) => state.SubSiteExp && state.SubSiteExp.getData && Array.isArray(state.SubSiteExp.getData) && state.SubSiteExp.getData.length ? state.SubSiteExp.getData:[])    
  
  const MatNameData = useSelector((state) => state.MaterialMatMaster && state.MaterialMatMaster.getData && Array.isArray(state.MaterialMatMaster.getData) && state.MaterialMatMaster.getData.length ? state.MaterialMatMaster.getData:[])    
  const MacNameData = useSelector((state) => state.AddResourceMachine && state.AddResourceMachine.getData && Array.isArray(state.AddResourceMachine.getData) && state.AddResourceMachine.getData.length ? state.AddResourceMachine.getData:[])    
  const ManNameData = useSelector((state) => state.ManpowerManMaster && state.ManpowerManMaster.getData && Array.isArray(state.ManpowerManMaster.getData) && state.ManpowerManMaster.getData.length ? state.ManpowerManMaster.getData:[])    



  const handleChangeType = (event) => {
    const ReportType = event.target.value;
    var arr = [];
    if (ReportType==="Material"){      
      MatCatData.map(data => {arr.push({"label" : data.name , "value" : data.mc_id})});
    }else if(ReportType==="Machine"){
      MacCatData.map(data => {arr.push({"label" : data.name , "value" : data.mch_id})});
    }else if(ReportType==="Manpower"){
      ManCatData.map(data => {arr.push({"label" : data.name , "value" : data.mpc_id})});
    }else if(ReportType==="Expense"){
      ExpCatData.map(data => {arr.push({"label" : data.exp_name , "value" : data.sub_exp_id})});
      
    }
    setValues({
        ...values,
        ['report_type']:ReportType,
        ['category_data']:arr,
        ['name_data']:[],
        ['category']:"",
        ['name']:"",
        ['disableCategory']:false});
  };
  

  const handleChangeCategory = (event) => {
    const CategoryVal = event.target.value;
    var NameArr = [];
    if (values.report_type==="Material"){       
      MatNameData.filter(data => data.mat_cat_id===CategoryVal).map(PushData => {NameArr.push({"label":PushData.mat_name, "value":PushData.mm_id})})      
    }else if(values.report_type==="Machine"){
      MacNameData.filter(data => data.mch_cat_id===CategoryVal).map(PushData => {NameArr.push({"label":PushData.machine_name, "value":PushData.sub_machine_id})})
    }else if(values.report_type==="Manpower"){
      ManNameData.filter(data => data.mp_cat_id===CategoryVal).map(PushData => {NameArr.push({"label":PushData.manp_mas, "value":PushData.mp_id})})
    }


    setValues({
      ...values, 
      ['category']:CategoryVal,
      ['name_data']:NameArr,
      ['name']:"",
      ['disableName']:false  
    });

    
  };
  
  
  const handleChangeName = (event) => {
    setValues({...values, ['name']: event.target.value,});
  };

 


  const handleStartDateChange = (date) => {
    setValues({
      ...values,
      ['start_date']:date
    })
    
  };
  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      ['end_date']:date
    })
    
  };


  useEffect(() => {
    const parameter={sub_admin_id:id, start_date:values.start_date, end_date:values.end_date, page:"report", per_page:"report"}

    dispatch(getMaterialDelivery(parameter))
    dispatch(getMachineDelivery(parameter))
    dispatch(getSiteMan(parameter))
    dispatch(getCashExp(parameter))

    dispatch(getMaterialCategory(id))
    dispatch(getMachineCat(id))
    dispatch(getManpowerCategory(id))
    dispatch(getSiteExp(id))

    dispatch(getMaterialMat(id))
    dispatch(getResourceMachine(id))
    dispatch(getManpowerMan(id))
  },[])


  
  const getMaterialDataExport = () => {
    const FilterData = MatDeliveryData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.delivery_date).format("DD/MM/YYYY")>=moment(values.start_date).format("DD/MM/YYYY")) && (moment(data.delivery_date).format("DD/MM/YYYY")<=moment(values.end_date).format("DD/MM/YYYY")))
    var MatDataArray = []
    if(Array.isArray(FilterData) && FilterData.length){
      FilterData.forEach(element => {
        
        element.mat_details.map((dataPush) => {
          if(values.category==="All"){                        
            MatDataArray.push(
              {
                "date":moment(element.delivery_date).format("DD.MM.YYYY"), 
                "mat_name":dataPush.mat_name,
                "quantity":dataPush.new_quan,
                "price":dataPush.amt,
                "gst":dataPush.gst_per,
                "site_name":element.site_name,
                "accepted_by":element.accepted_by
              })  
            }else{
              if (values.name!=="All"){

                if(dataPush.mat_id===values.name){
                  MatDataArray.push(
                    {
                      "date":moment(element.delivery_date).format("DD.MM.YYYY"), 
                      "mat_name":dataPush.mat_name,
                      "quantity":dataPush.new_quan,
                      "price":dataPush.amt,
                      "gst":dataPush.gst_per,
                      "site_name":element.site_name,
                      "accepted_by":element.accepted_by
                    })
                }
              }else{
                if(dataPush.mat_type_id===values.category){
                  MatDataArray.push(
                    {
                      "date":moment(element.delivery_date).format("DD.MM.YYYY"), 
                      "mat_name":dataPush.mat_name,
                      "quantity":dataPush.new_quan,
                      "price":dataPush.amt,
                      "gst":dataPush.gst_per,
                      "site_name":element.site_name,
                      "accepted_by":element.accepted_by
                    })
                }
              }
            }
        })
      })                  
    }      
  
    console.log(MatDataArray)
    return MatDataArray;
  }
  
  const getMachineDataExport = () => {
    const FilterData = MacDeliveryData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.recieve_date).format("DD/MM/YYYY")>=moment(values.start_date).format("DD/MM/YYYY")) && (moment(data.recieve_date).format("DD/MM/YYYY")<=moment(values.end_date).format("DD/MM/YYYY")))
    var MacDataArray = []
    if(Array.isArray(FilterData) && FilterData.length){
      FilterData.forEach(element => {
        
        element.machine_details.map((dataPush) => {
          if(values.category==="All"){
            MacDataArray.push(
              {
                "date":moment(element.recieve_date).format("DD.MM.YYYY"), 
                "mac_name":dataPush.mac_name,
                "quantity":dataPush.new_quan,
                "price":dataPush.amt,
                "gst":dataPush.gst_per,
                "site_name":element.site_name,
                "accepted_by":element.accepted_by
              })
          }else{
            if(values.name!=="All"){
              if(dataPush.mac_id===values.name){

                MacDataArray.push(
                  {
                    "date":moment(element.recieve_date).format("DD.MM.YYYY"), 
                    "mac_name":dataPush.mac_name,
                    "quantity":dataPush.new_quan,
                    "price":dataPush.amt,
                    "gst":dataPush.gst_per,
                    "site_name":element.site_name,
                    "accepted_by":element.accepted_by
                  })
              }
            }else{
              if(dataPush.mac_type_id===values.category){
                MacDataArray.push(
                  {
                    "date":moment(element.recieve_date).format("DD.MM.YYYY"), 
                    "mac_name":dataPush.mac_name,
                    "quantity":dataPush.new_quan,
                    "price":dataPush.amt,
                    "gst":dataPush.gst_per,
                    "site_name":element.site_name,
                    "accepted_by":element.accepted_by
                  })
              }
            }
          }

        })  
        
      });
      
    }
    console.log(MacDataArray)
    return MacDataArray;
  }
  
  const getManpowerDataExport = () => {
    const FilterData = SiteManpowerData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.date).format("DD/MM/YYYY")>=moment(values.start_date).format("DD/MM/YYYY")) && (moment(data.date).format("DD/MM/YYYY")<=moment(values.end_date).format("DD/MM/YYYY")))
    var ManDataArray = []
    if(Array.isArray(FilterData) && FilterData.length){
      FilterData.forEach(element => {
        
        element.manpower_details.map((dataPush) => {
          
          if(values.category==="All"){
            ManDataArray.push(
              {
                "date":moment(element.date).format("DD.MM.YYYY"), 
                "name":dataPush.name,
                "quantity":dataPush.quantity,
                "rate":dataPush.rate,
                "site_name":element.site_name,
                "accepted_by":element.accepted_by
              })
          }else{
            if(values.name==="All"){
              if(dataPush.mp_cat_id===values.category){
                ManDataArray.push(
                  {
                    "date":moment(element.date).format("DD.MM.YYYY"), 
                    "name":dataPush.name,
                    "quantity":dataPush.quantity,
                    "rate":dataPush.rate,
                    "site_name":element.site_name,
                    "accepted_by":element.accepted_by
                  })
              }
            }else{
              if(dataPush.id===values.name){
                ManDataArray.push(
                  {
                    "date":moment(element.date).format("DD.MM.YYYY"), 
                    "name":dataPush.name,
                    "quantity":dataPush.quantity,
                    "rate":dataPush.rate,
                    "site_name":element.site_name,
                    "accepted_by":element.accepted_by
                  })
              }
            }
          }
                    
        })
        
      });
      
    }
    console.log(ManDataArray)
    return ManDataArray;
  }

  
  const getExpenseDataExport = () => {
    const FilterData = CashExpData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.expense_date).format("DD/MM/YYYY")>=moment(values.start_date).format("DD/MM/YYYY")) && (moment(data.expense_date).format("DD/MM/YYYY")<=moment(values.end_date).format("DD/MM/YYYY")))
    var ExpDataArray = []
    if(Array.isArray(FilterData) && FilterData.length){
      FilterData.map(element => {

        if(values.category!=="All"){
          if(element.exp_id===values.category){
            ExpDataArray.push(
              {
                "date":moment(element.expense_date).format("DD.MM.YYYY"), 
                "exp_name":element.exp_name,
                "amt":element.amt,
                "site_name":element.site_name,
                "remark":element.remark[0]
              })  
          }
        }else{
          ExpDataArray.push(
            {
              "date":moment(element.expense_date).format("DD.MM.YYYY"), 
              "exp_name":element.exp_name,
              "amt":element.amt,
              "site_name":element.site_name,
              "remark":element.remark[0]
            })
        }

                
      });
      
    }
    console.log(ExpDataArray)
    return ExpDataArray;
  }

  const handleSubmit = () =>{
    
    if(values.report_type!=="" && values.report_type!==null && values.report_type!==undefined){
      if(values.category!=="" && values.category!==null && values.category!==undefined){

        new Promise((resolve, reject) => {

          if (values.report_type==="Material"){ 
            if((values.category!=="All") && (values.name==="" || values.name===null || values.name===undefined)){
              tostE("Please Select Name");
              reject()
            }else{
              const ExportReportCol = [
                {field:"date", val:"Date"},
                {field:"mat_name", val:"Material Name"},
                {field:"quantity", val:"Quantity"},
                {field:"price", val:"Price"},
                {field:"gst", val:"GST"},
                {field:"site_name", val:"Site"},
                {field:"accepted_by", val:"Accepted By"},
              ]        
              
              getExportFileBlob(ExportReportCol,getMaterialDataExport(), 'xlsx', 'Material Report')         

            }
            
          }else if(values.report_type==="Machine"){
            if((values.category!=="All") && (values.name==="" || values.name===null || values.name===undefined)){
              tostE("Please Select Name");
              reject()
            }else{
              
              const ExportReportCol = [
                {field:"date", val:"Date"},
                {field:"mac_name", val:"Machine Name"},
                {field:"quantity", val:"Quantity"},
                {field:"price", val:"Price"},
                {field:"gst", val:"GST"},
                {field:"site_name", val:"Site"},
                {field:"accepted_by", val:"Accepted By"},
              ] 
              getExportFileBlob(ExportReportCol,getMachineDataExport(), 'xlsx', 'Machine Report')         
            }
          }else if(values.report_type==="Manpower"){
            if((values.category!=="All") && (values.name==="" || values.name===null || values.name===undefined)){
              tostE("Please Select Name");
              reject()
            }else{
              const ExportReportCol = [
                {field:"date", val:"Date"},
                {field:"name", val:"Manpower Name"},
                {field:"quantity", val:"Quantity"},
                {field:"rate", val:"Rate"},
                {field:"site_name", val:"Site"},
                {field:"accepted_by", val:"Accepted By"},
              ] 
              getExportFileBlob(ExportReportCol,getManpowerDataExport(), 'xlsx', 'Manpower Report')         

            }
          }else if(values.report_type==="Expense"){
            const ExportReportCol = [
              {field:"date", val:"Date"},
              {field:"exp_name", val:"Expense Name"},
              {field:"amt", val:"Amount"},
              {field:"site_name", val:"Site"},
              {field:"remark", val:"Remark"},
            ] 
            getExportFileBlob(ExportReportCol,getExpenseDataExport(), 'xlsx', 'Expense Report')         
            
          }
  
          resolve()
        }).then(() => {
          
          setValues({
            ...values,
            start_date:new Date(Date.now()),
            end_date:new Date(Date.now()),
            report_type:"",
            category:"",
            name:"",
            category_data:[],
            name_data:[],
            disableCategory:true,
            disableName:true
          })
        }).catch((err) =>{console.log("report can't be generated")})
      }else{
        tostE("Please Select the Category!!")
      }
    }else{
      tostE("Please Select the Report Type!!")
    }

  }



  return (
    <Page className={classes.root} title="Report Generation">
      <ToastContainer    />

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          
          <Grid item lg={12} md={12} xs={12}>
          
            <Card>
              <CardHeader subheader="" title="Generate Report" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        style={{ border: "1px  black" }}
                        id="date-picker-dialog"
                        label="From"
                        format="dd/MM/yyyy"
                        value={values.start_date}
                        onChange={handleStartDateChange}
                        variant="outlined"
                        inputVariant='outlined'
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </Grid>
                  <Grid item md={6} xs={12}>
                  <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        style={{ border: "1px  black" }}
                        id="date-picker-dialog"
                        label="To"
                        minDate={values.start_date}                        
                        format="dd/MM/yyyy"
                        value={values.end_date}
                        onChange={handleEndDateChange}
                        inputVariant='outlined'
                        variant="outlined"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </Grid>
                  
                  <Grid item md={4} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">Report Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={values.report_type}
                        onChange={handleChangeType}
                        label="Report Type"
                      >
                        <MenuItem value="Material">Material</MenuItem>
                        <MenuItem value="Machine">Machine</MenuItem>
                        <MenuItem value="Manpower">Manpower</MenuItem>
                        <MenuItem value="Expense">Expense</MenuItem>                        
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl} disabled={values.disableCategory}>
                      <InputLabel id="demo-simple-select-outlined-label">Resource Category</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={values.category}
                        onChange={handleChangeCategory}
                        label="Resource Category"
                      >    
                        <MenuItem value="All">All</MenuItem>                                        
                        {values.category_data && Array.isArray(values.category_data) && values.category_data.length ? values.category_data.map((data , i)=>
                        <MenuItem key={i}  value={data.value}>{data.label}</MenuItem>
                      ):<></>}        
                      
                      </Select>
                    </FormControl>
                  </Grid>
                  {values.report_type!=="Expense" && values.category!=="All" ? 
                  <Grid item md={4} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl} disabled={values.disableName}>
                      <InputLabel id="demo-simple-select-outlined-label">Resource Name</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={values.name}
                        onChange={handleChangeName}
                        label="Resource Name"
                      >
                        <MenuItem value="All">All</MenuItem>
                        {values.name_data && Array.isArray(values.name_data) && values.name_data.length ? values.name_data.map((data , i)=>
                        <MenuItem key={i}  value={data.value}>{data.label}</MenuItem>
                      ):<></>}        
                      
                      </Select>
                    </FormControl>
                  </Grid>
                  :<></>
                  }

                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                  Export Report
                </Button>
              </Box>
            </Card>
    
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
