import {GET_CASH_EXPENSE_LOADING , GET_CASH_EXPENSE_SUCCESS , GET_CASH_EXPENSE_FAILURE, CLEAN_CASH_EXPENSE_DATA} from '../../Actions/Types';
import {DEL_CASH_EXPENSE_LOADING , DEL_CASH_EXPENSE_SUCCESS , DEL_CASH_EXPENSE_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const CashExp = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getData:[{}],
            getError:{},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},                
            }
    
    
        case CLEAN_CASH_EXPENSE_DATA:
            return {
                ...state,                
                loading:false,                
                getData : [{}],
                getError:{},
                delData : [{}], 
                delError:{}
            }
    
        case GET_CASH_EXPENSE_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_CASH_EXPENSE_SUCCESS:
            return {
                ...state,
                loading:false,                
                getData:action.data,
                getError:{}
            }
        case GET_CASH_EXPENSE_FAILURE:
            return {
                ...state,
                loading:false,                
                getData:{},
                getError:action.error,
            }   
        
        case DEL_CASH_EXPENSE_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_CASH_EXPENSE_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_CASH_EXPENSE_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
         

        default:
        return state
    
    }
    }
    
    
    
export default CashExp;
    