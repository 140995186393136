// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addMaterialCategoryBegin , addMaterialCategorySuccess , addMaterialCategoryFailure } from '../ActionCreators';
import {getMaterialCategoryBegin , getMaterialCategorySuccess , getMaterialCategoryFailure} from '../ActionCreators';
import {delMaterialCategoryBegin , delMaterialCategorySuccess ,  delMaterialCategoryFailure} from '../ActionCreators';
import {editMaterialCategoryBegin , editMaterialCategorySuccess ,  editMaterialCategoryFailure} from '../ActionCreators';

export const addMaterialCategory = (session) => {

    console.log("Gourav");
    return (dispatch) => {
        dispatch(addMaterialCategoryBegin());

        axios.post(Base_URL + '/subAdmin/addmatcat', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addMaterialCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addMaterialCategoryFailure(err.response.data));

                }
            })
    }
}

export const getMaterialCategory = (x) => {

    return (dispatch) => {
        dispatch(getMaterialCategoryBegin());

        axios.get(Base_URL + '/subadmin/getMaterialCat' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getMaterialCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getMaterialCategoryFailure(err.response.data));

                }
            })
    }
}

export const delMaterialCategory = (x) => {

    return (dispatch) => {
        dispatch(delMaterialCategoryBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delMatCat' , {params :{mc_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delMaterialCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delMaterialCategoryFailure(err.response.data));

                }
            })
    }
}

export const editMaterialCategory = (session) => {

    return (dispatch) => {
        dispatch(editMaterialCategoryBegin());

        axios.post(Base_URL + '/subadmin/editMaterialCat', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editMaterialCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editMaterialCategoryFailure(err.response.data));

                }
            })
    }
}
