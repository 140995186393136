import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import DetailIcon from '@material-ui/icons/List';
import {
  Container,
  Grid,
  makeStyles,
  Card,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
  TablePagination
} from "@material-ui/core";
import Page from "../../../../src/components/Page";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getSites } from "../../../Actions/SubAdminActions/M_AddSites";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";
import { ToastContainer } from "react-toastify";
import { tostS, tostE } from "../../../Config/Toast";
import { FetchDailyReports } from '../../../Actions/SubAdminActions/DailyReport';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  tableHeader: {
    fontWeight: 'bold',
  },
}));

const DailyReportList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const id = useSelector((state) => state.login.data.r_subadmin);
  const selected_site = useSelector(
    (state) =>
      state.SelectedSite &&
      state.SelectedSite.sitedata &&
      state.SelectedSite.sitedata.site
        ? state.SelectedSite.sitedata.site
        : null
  );

  const SiteData = useSelector(
    (state) =>
      state.AddSites &&
      state.AddSites.getData &&
      state.AddSites.getData.length &&
      Array.isArray(state.AddSites.getData)
        ? state.AddSites.getData
        : []
  );

  // const [dailyReports, setDailyReports] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const dailyReports = useSelector(state => state.DailyReportData.data?.daily_report ?? []);
  const successMessage = useSelector(state => state.DailyReportData.data?.message);
  const errorMessage = useSelector(state => state.DailyReportData.error?.message);

  useEffect(() => {
    if (successMessage) {
      tostS(successMessage);
    }
    if (errorMessage) {
      tostE(errorMessage);
    }
  }, [successMessage, errorMessage]);

  console.log(dailyReports);
  useEffect(() => {
    dispatch(getSites(id));
  }, [dispatch, id]);



  const GetDailyReports = () => {
    const requestData = {
      sub_admin_id: id,
      site_id: selected_site,
    };


    dispatch((FetchDailyReports(requestData)))

    // axios
    //   .post(`${Base_URL}/subadmin/getDailyReport`, requestData)
    //   .then((response) => {
    //     if (response.data.status === 200) {
    //       if (response.data.daily_report && response.data.daily_report.length > 0) {
    //         setDailyReports(response.data.daily_report);
    //         tostS('Daily reports fetched successfully');
    //       } else {
    //         setDailyReports([]);
    //         tostE('No daily reports available for the selected site.');
    //       }
    //     } else {
    //       tostE('Failed to fetch daily reports');
    //       setDailyReports([]);
    //     }
    //   })
    //   .catch((err) => {
    //     tostE('Failed to fetch daily reports');
    //     setDailyReports([]);
    //   });
  };

  useEffect(() => {
    if (selected_site) {
      GetDailyReports();
    }
  }, [selected_site]);

 

  const handleViewReportDetails = (report) => {
    console.log(report);
    navigate(`/app/generate_Daily_Report/${report.date}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page>
      <ToastContainer></ToastContainer>
      <Container maxWidth="lg">
        <Grid style={{ marginTop: "10px" }} container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardContent>
                <Typography style={{ padding: "5px" }} variant="h3">Daily Reports</Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="daily reports table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>Date</TableCell>
                        <TableCell className={classes.tableHeader}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dailyReports.length > 0 ? (
                        dailyReports
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((report, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {moment(report.date).format("MMM DD, YYYY")}
                              </TableCell>
                              <TableCell>
                                <DetailIcon onClick={() => { handleViewReportDetails(report) }} style={{ cursor: "pointer" }} />
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            No reports available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dailyReports.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DailyReportList;
