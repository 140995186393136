
import {SUB_DASHBOARD_LOADING , SUB_DASHBOARD_SUCCESS , SUB_DASHBOARD_FAILURE } from '../Actions/Types';
import {SUPER_DASHBOARD_LOADING , SUPER_DASHBOARD_SUCCESS , SUPER_DASHBOARD_FAILURE } from '../Actions/Types';

import {CLEAN_ALL_DATA } from '../Actions/Types';



const Dashboard = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,            
            SubData:{},
            SubError:{},
            SuperData:{},
            SuperError:{}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,            
            loading:false,            
            SubData:{},
            SubError:{},
            SuperData:{},
            SuperError:{}
            
        }
        case SUB_DASHBOARD_LOADING:
            return {
                ...state,
                loading:true,
                SubError: {},
                SubData: {},
                
            }
        case SUB_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading:false,
                SubError:{},
                SubData:action.data
            }
        case SUB_DASHBOARD_FAILURE:
            return {
                ...state,
                loading:false,
                SubError:action.error,
                SubData:{},
            }   

        case SUPER_DASHBOARD_LOADING:
            return {
                ...state,
                loading:true,
                SuperError: {},
                SuperData: {},
                
            }
        case SUPER_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading:false,
                SuperError:{},
                SuperData:action.data
            }
        case SUPER_DASHBOARD_FAILURE:
            return {
                ...state,
                loading:false,
                SuperError:action.error,
                SuperData:{},
            }   


    default:
        return state

}
}



export default Dashboard;
