
import {ADD_STOCK_TRANSFER_LOADING , ADD_STOCK_TRANSFER_SUCCESS , ADD_STOCK_TRANSFER_FAILURE , CLEAN_STOCK_TRANSFER_DATA} from '../../Actions/Inventory/Types';
import {GET_STOCK_TRANSFER_LOADING , GET_STOCK_TRANSFER_SUCCESS , GET_STOCK_TRANSFER_FAILURE} from '../../Actions/Inventory/Types';
import {GET_ITEM_DATA_LOADING , GET_ITEM_DATA_SUCCESS , GET_ITEM_DATA_FAILURE} from '../../Actions/Inventory/Types';
import {DEL_STOCK_TRANSFER_LOADING , DEL_STOCK_TRANSFER_SUCCESS , DEL_STOCK_TRANSFER_FAILURE} from '../../Actions/Inventory/Types';
import {EDIT_STOCK_TRANSFER_LOADING , EDIT_STOCK_TRANSFER_SUCCESS , EDIT_STOCK_TRANSFER_FAILURE} from '../../Actions/Inventory/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const StockTransfer = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            itemData:[{}],
            itemError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{}
                
            }
    
    
        case CLEAN_STOCK_TRANSFER_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},                
                editData : [{}],
                editError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_STOCK_TRANSFER_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                
            }
        case ADD_STOCK_TRANSFER_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
            }
        case ADD_STOCK_TRANSFER_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
            }

        case GET_STOCK_TRANSFER_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                getData : [{}],
                
            }
        case GET_STOCK_TRANSFER_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data,
            }
        case GET_STOCK_TRANSFER_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        

        case GET_ITEM_DATA_LOADING:
            return {
                ...state,
                loading:true,
                itemError: {},
                itemData : [{}],
                
            }
        case GET_ITEM_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                itemError:{},
                itemData:action.data,
            }
        case GET_ITEM_DATA_FAILURE:
            return {
                ...state,
                loading:false,
                itemError:action.error,
                itemData:{},
            }   
        

        case DEL_STOCK_TRANSFER_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_STOCK_TRANSFER_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_STOCK_TRANSFER_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
                    
        case EDIT_STOCK_TRANSFER_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}]
                
            }
        case EDIT_STOCK_TRANSFER_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_STOCK_TRANSFER_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            

        default:
        return state
    
    }
    }
    
    
    
export default StockTransfer;
    