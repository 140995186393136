import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {useLocation , useNavigate} from 'react-router-dom';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {editAppUser} from '../../../../Actions/SubAdminActions/AppUserManagement'
import {cleanAppUserMgmt} from '../../../../Actions/ActionCreators'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {state} = useLocation();

  const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  const site_id = useSelector(state => state.login.data.site_id)
  const sub_aid = useSelector(state => state.login.data.r_subadmin);
  const sub_folder_name = useSelector(state => state.login.data.folder_name)
  const [values, setValues] = useState({
     apu_id:state.apu_id,
    site_id:state.site_id,
    name :state.name,
    mobile:state.mobile,
    email:state.email,
    assign_role :state.assign_role,
    active:true,
    role:"Client",
    sub_admin_id:sub_aid,
    folder_name:sub_folder_name
  });


  const handleChangeRole = (event) => {
    setValues({
      ...values,
      ['assign_role']: event.target.value,
    });
  };



  const handleChange= (event) => {
    
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    
    
  };

  const handleSiteMenu = (c) =>{
    setValues({...values ,  ['site_id'] : c.target.value})

  }



  const handleSubmit = () =>{
    var mobileReg = /^[6-9]{1}[0-9]{9}$/;
    var nameReg = /^[A-Za-z]{2,}$/;
    console.log("++++++++++++++" , values.mobile);
    console.log(mobileReg.test(values.mobile));
    console.log("+++++++++++++++");
    console.log(nameReg.test(values.name));
    if(mobileReg.test(values.mobile.trim())!=true){
      tostW("Please Enter a Valid Mobile Number!!");

    }else if(nameReg.test(values.name.trim())!=true){
      tostW("Please Enter a Valid Name!!")

    }else
    {
      console.log(values, "iudifsdfousadhfhsdfdfdbfhdsbvfjs")
      dispatch(editAppUser(values));
    navigate('/app/master/app_user_list')
    }
    
    
    

  }


  const CData = useSelector((state) =>
  state.AddSites && state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );


  
  const SData = useSelector((state) =>
  state.GetState && state.GetState.getdata &&
    state.GetState.getdata.length &&
    Array.isArray(state.GetState.getdata)
      ? state.GetState.getdata
      : []
  );

  const CiData = useSelector((state) =>
  state.GetCity && state.GetCity.getdata &&
    state.GetCity.getdata.length &&
    Array.isArray(state.GetCity.getdata)
      ? state.GetCity.getdata
      : []
  );



  
  const BlistData = useSelector((state) =>
  state.BusinessMaster &&  state.BusinessMaster.getdata &&
    state.BusinessMaster.getdata.length &&
    Array.isArray(state.BusinessMaster.getdata)
      ? state.BusinessMaster.getdata
      : []
  );






  const addmsg = useSelector(state => state.AppUserMgmt.data && state.AppUserMgmt.data.apu_id ? state.AppUserMgmt.data.apu_id : null  )
  const adderror = useSelector(state => state.AppUserMgmt.error && state.AppUserMgmt.error.message ? state.AppUserMgmt.error.message : null  )


  useEffect( () => {

    let x  = new Promise((resolve ,reject)=>{
      if(addmsg)
      {
        tostS("Added Successfully")
      }
      if(adderror && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanAppUserMgmt())
   

    })

  
     
    }, [addmsg , adderror])

  


  return (
    <Page
      className={classes.root}
      title="App User "
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
        
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="App User" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type='number'                                
                label="Mobile"
                name="mobile"
                onChange={handleChange}
                required
                              
                value={values.mobile}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Role Assigned</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.assign_role}
          onChange={handleChangeRole}
          label="Role Assigned"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value='Site_Engineer'>Site Engineer</MenuItem>
          <MenuItem value='Site_Incharge'>Site In-Charge</MenuItem>
          <MenuItem value='Client'>Client</MenuItem>
          {/* {CData.map((data , i)=>
          <MenuItem key={i} onClick={()=>handleCountryMenu(data.c_id)} value={data.name}>{data.name}/{data.native}</MenuItem>
          )}         */}

        </Select>
      </FormControl>
      </Grid>


      <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Site</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.site_id}
          onChange={handleSiteMenu}
          label="Site"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {/* <MenuItem value='Site-1'>Site-1</MenuItem> */}
          {CData.map((data , i)=>
          <MenuItem key={i}  value={data.site_id}>{data.site_name}</MenuItem>
          )}        

        </Select>
      </FormControl>
      </Grid>

            {values.assign_role !== "" && values.assign_role === 'Client' ?
    
            
      <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="EmailField"
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}                
                variant="outlined"/>
      </Grid>    

      : <></> }

      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
