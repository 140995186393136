import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {addResourceQuality , getResourceQuality , delResourceQuality , editResourceQuality} from "../../../../Actions/SubAdminActions/M_ResourceQuality";
import {cleanResourceQualityData} from "../../../../Actions/ActionCreators";
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const [open3, setOpen3] = useState({show:false, id:""});
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);


  const handleClose3 = () =>{

    setOpen3({...open3, show:false, id:""});
  }
  
  const handleOpen3 = (data) =>{
  
    setOpen3({
      ...open3,
      show:true,
      id:data.sub_qas_id
    });
  
  }
  
  const handleConfirm3 = () =>{
    if(open3.id!==""){
      
      dispatch(delResourceQuality(open3.id))

  
      setOpen3({
        ...open3,
        show:false,
        id:""
      })
    }
  }


const id = useSelector(state => state.login.data.r_subadmin);

const [values , setValues] = useState({

  sub_admin_id : id,
  qas_name : "",
  priority:"",
  sub_qas_id : ""

});
  

const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const listData= useSelector((state) =>
  state.AddResourceQuality && state.AddResourceQuality.getData &&
    state.AddResourceQuality.getData.length &&
    Array.isArray(state.AddResourceQuality.getData)
      ? state.AddResourceQuality.getData
      : []
  );
  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);

  const handleSubmit = () =>{
    //  console.log(values)
    if (values.sub_qas_id !== '')
    {
      // alert("Hey");
      dispatch(editResourceQuality(values))
    }
    else
    {
      // alert("start");
      dispatch(addResourceQuality(values))
  
    }
   setValues({
     ...values,
     qas_name : "",
     priority:"",
     sub_qas_id : ""
    
  });
setOpen(false);
setOpen2(false);  
};

const addmsg = useSelector((state) => state.AddResourceQuality.data && state.AddResourceQuality.data.status && state.AddResourceQuality.data.status === 200 ? state.AddResourceQuality.data.message : null  )
const adderr = useSelector((state) => state.AddResourceQuality.error && state.AddResourceQuality.error.message ? state.AddResourceQuality.error.message : null  )
const delmsg = useSelector((state) => state.AddResourceQuality && state.AddResourceQuality.delData && state.AddResourceQuality.delData.message ? state.AddResourceQuality.delData.message : null  )
const delerr = useSelector((state) => state.AddResourceQuality && state.AddResourceQuality.delError && state.AddResourceQuality.delError.message ? state.AddResourceQuality.delError.message : null  )
const editmsg = useSelector((state) => state.AddResourceQuality && state.AddResourceQuality.editData && state.AddResourceQuality.editData.message ? state.AddResourceQuality.editData.message : null )

useEffect( () => {

  // dispatch(getMaterialCategory(id))
  var r  = new Promise((resolve , reject)=>{
   if(addmsg !== null && typeof(addmsg) === 'string'){
    tostS(addmsg)
   }

  else
  if(adderr !== null){
    tostE(adderr)
   }
  else
  if (delmsg !== null && typeof(delmsg) === 'string')
  {
    tostS(delmsg)
  }
  else
  if (delerr !== null && typeof(delerr) === 'string')
  {
    tostE(delerr)
  }
  else
  if (editmsg !== null && typeof(editmsg) === 'string')
  {
    tostS(editmsg);
  }

   resolve()
  
  }) 
  r.then(()=>{
   dispatch(cleanResourceQualityData())
   dispatch(getResourceQuality(id))
  //  dispatch()
  // alert("Done");
  })
  
}, [addmsg, ,adderr , delmsg , delerr, editmsg])



  


const navigate = useNavigate();

const onEdit = (olData) =>{
  setOpen2(true)
  setValues({...values, ['qas_name'] : olData.qas_name, ['priority']:olData.priority, ['sub_qas_id'] : olData.sub_qas_id })

//   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
}


// const onDel = (oldata) =>{

//   dispatch(delResourceQuality(oldata.sub_qas_id ,oldata.tableData.id ))
// }
 





const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleChange = (event) => {
  //  console.log(event.target.value);
   setValues({
     ...values,
     [event.target.name]: event.target.value,
   });
 };


const handleDateChange = (date) => {
  setSelectedDate(date);
};


const handleChanged = (event) => {
  setValues({...values, ['priority']:event.target.value});
};




const [open, setOpen] = React.useState(false);
const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setValues({...values, ['qas_name'] : "",['priority']:"" , ['sub_qas_id'] : "" })

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
const handleClose2 = () => {
  setOpen2(false);
  setValues({...values, ['qas_name'] : "", ['priority']:"" , ['sub_qas_id'] : "" })

};



  const addNewBornbutton = () =>{
    navigate('/app/master/issue_list_add')
    console.log("Place order")
    
  }
  const exportField = [
    {
      field : "sub_qas_id" , 
      val : "Issue ID"
    }, 
    {
      field : "qas_name" , 
      val : "Issue Name"
    }, 
    {
      field : "priority" , 
      val : "Priority"
    }, 
    
  ]


  return (
    <>
      <Page className={classes.root} title="Issues">
      <ToastContainer    />




      <Dialog disableBackdropClick={true} open={open3.show} onClose={handleClose3} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Issue??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm3} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>




        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">


                <div>
                <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Quality-Safety')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Quality-Safety')
              }>
                Export to PDF
            </Button>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Issue
      </Button>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Issue Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Issue
          </DialogContentText>
          <TextField
                fullWidth
                id="name"
                label="Issue Name"
                type="text"
                required
                name = "qas_name"
                value = {values.qas_name}
                onChange = {handleChange}
                variant="outlined"
              />

              <br/><br/>

            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Issue Priority</InputLabel>
                <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.country}
                onChange={handleChanged}
                label="Issue Priority"
                style={{width:"100%" }}
                >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>


                <MenuItem   value="High">High</MenuItem>
                <MenuItem   value="Medium">Medium</MenuItem>
                <MenuItem   value="Low">Low</MenuItem>


                </Select>
              </FormControl>
            </MuiPickersUtilsProvider>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Add Issue
              </Button>
            </DialogActions>
          </Dialog>




      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Issue Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update Issue
          </DialogContentText>
          <TextField
                fullWidth
                id="name"
                label="Issue Name"
                type="text"
                required
                name = "qas_name"
                value = {values.qas_name}
                onChange = {handleChange}
                variant="outlined"
              />

              <br/><br/>

              <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                  <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Issue Priority</InputLabel>
                  <Select
                  fullWidth
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={values.priority}
                  onChange={handleChanged}
                  label="Issue Priority"
                  style={{width:"100%" }}
                  >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>


                  <MenuItem   value="High">High</MenuItem>
                  <MenuItem   value="Medium">Medium</MenuItem>
                  <MenuItem   value="Low">Low</MenuItem>


                  </Select>
                </FormControl>
              </MuiPickersUtilsProvider>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose2} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                  Update
                </Button>
              </DialogActions>
            </Dialog>


    </div>


            </Box>
            {/* <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  // value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              </ThemeProvider>

        </Grid>
       

        
<Grid item md={6} lg={3}  xs={3}>

<FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
<InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
<Select
fullWidth
labelId="demo-simple-select-outlined-label"
id="demo-simple-select-outlined"
value={values.priority}
onChange={handleChanged}
label="Export As"
style={{width:"100%" }}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 2" onClick={()=> exportToExcel([{field : "sub_qas_id" , val : "Issue ID"} , {field : "qas_name" , val : "Issue Name"}] , listData)}>Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem>


</Select>
</FormControl>
</Grid>
        
      </Grid>
    </div> */}
    
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Issues"
      columns={[

        { title: 'Issue ID',render: rowData =>(
          rowData.tableData.id+1
          )  },
        { title: 'Issue Name', field: 'qas_name',editable: "never", },     
        { title: 'Priority', field: 'priority',editable: "never", },     
        // { title: 'Date', field: 'country',editable: "never", },     
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ?
              <Grid item xs={4}>
                
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>              
              }
              {role_permit[2] ?
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen3(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
