import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';


import { makeStyles } from '@material-ui/core/styles';



import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        {/* <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
            
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const rows = [
  createData('Enquiry-1', 159, 6.0, 24, 4.0, 3.99),
  createData('Enquiry-2', 237, 9.0, 37, 4.3, 4.99),
  createData('Enquiry-3', 262, 16.0, 24, 6.0, 3.79),
  createData('Enquiry-4', 305, 3.7, 67, 4.3, 2.5),
  createData('Enquiry-5', 356, 16.0, 49, 3.9, 1.5),
];

const TrafficByDevice = ({ className, dashdata, ...rest }) => {
  // const classes = useStyles();
  // const { row } = props;
  // const [open, setOpen] = React.useState(false);
  // const classes = useRowStyles();
  const theme = useTheme();
   const x  =   parseInt(dashdata && Array.isArray(dashdata) && dashdata.length && Array.isArray(dashdata[0].buscount) && dashdata[0].buscount.length   ? dashdata[0].buscount[0].count : 0)
   const y  =   parseInt(dashdata && Array.isArray(dashdata) && dashdata.length && Array.isArray(dashdata[0].trainscount) && dashdata[0].trainscount.length  ? dashdata[0].trainscount[0].count : 0)

  const data = {
    datasets: [
      {
        data: [x, y],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Desktop', 'Tablet']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: "Buses",
      value: dashdata && Array.isArray(dashdata) && dashdata.length && Array.isArray(dashdata[0].buscount) && dashdata[0].buscount.length   ? dashdata[0].buscount[0].count : 0,
      
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: 'Trains',
      value:100-  parseInt(dashdata && Array.isArray(dashdata) && dashdata.length && Array.isArray(dashdata[0].buscount) && dashdata[0].buscount.length  ? dashdata[0].buscount[0].count : 0),
      icon: TabletIcon,
      color: colors.red[600]
    },
 
  ];

  return (
    <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Enquiries / Follow-ups</TableCell>
          {/* <TableCell align="right">Calories</TableCell> */}
          {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
          <TableCell align="right">Carbs&nbsp;(g)</TableCell>
          <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <Row key={row.name} row={row} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
    // <Card
    //   className={clsx(classes.root, className)}
    //   {...rest}
    // >
    //   <CardHeader title="Transport Comparison" />
    //   <Divider />
    //   <CardContent>
    //     <Box
    //       height={300}
    //       position="relative"
    //     >
    //       <Doughnut
    //         data={data}
    //         options={options}
    //       />
    //     </Box>
    //     <Box
    //       display="flex"
    //       justifyContent="center"
    //       mt={2}
    //     >
    //       {devices.map(({
    //         color,
    //         icon: Icon,
    //         title,
    //         value
    //       }) => (
    //         <Box
    //           key={title}
    //           p={1}
    //           textAlign="center"
    //         >
    //           <Icon color="action" />
    //           <Typography
    //             color="textPrimary"
    //             variant="body1"
    //           >
    //             {title}
    //           </Typography>
    //           <Typography
    //             style={{ color }}
    //             variant="h2"
    //           >
    //             {value}
    //             %
    //           </Typography>
    //         </Box>
    //       ))}
    //     </Box>
    //   </CardContent>
    // </Card>
  );
};

TrafficByDevice.propTypes = {
  className: PropTypes.string
};

export default TrafficByDevice;
