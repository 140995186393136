import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
import jsPDF from 'jspdf';

import Paper from '@material-ui/core/Paper';


import { Link as RouterLink, useNavigate  } from 'react-router-dom';

import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PasswordIcon from '@material-ui/icons/VpnKey';
import BackupDownloadIcon from '@material-ui/icons/CloudDownload';
import DetailIcon from '@material-ui/icons/List';
import tableIcons from '../../../Config/IconsFile'
import DownloadIcon from 'react-feather/dist/icons/download';
import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Tooltip,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput,
  CircularProgress
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


import {getClients, delClients, editClientsPass, getBackup} from '../../../Actions/SuperAdminActions/AddClients'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {cleanClientsData} from "../../../Actions/ActionCreators";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {getExportFileBlob} from '../../../Config/excelPdfDownload'


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
 
  const tableRef = React.createRef();
  

  const [zipLoading  , setZipLoading ] = useState(null)
  
  const [open, setOpen] = useState({show:false, id:"", showPass:false, folder_name:""});

const [pass, setPass] = useState({
  admin_id:"",
  password:"",
  confirm_pass:"",
  showPassword:false,
  showConfirmPass:false
});

  const handleClose = () =>{

    setOpen({...open, show:false, id:"", showPass:false , folder_name:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,      
      showPass:false,
      id:data.admin_id,
      folder_name:data.folder_name
    });
  
  }
  const handleOpen2 = (data) =>{
  
    setPass({
      ['admin_id']:data.admin_id
    })
    
    setOpen({
      ...open,
      showPass:true,
      show:false,      
      id:"",
    });

  
  }
  const handleConfirm = () =>{


   
    if(open.id!=="" && open.show!==false && open.showPass===false){
      dispatch(delClients(open.id, open.folder_name))

  
      setOpen({
        ...open,
        show:false,        
        id:"",
        folder_name:""
      })
    }

    if(!pass.password && !pass.confirm_pass){
      tostE('enter all fields')
      return
    }

    if(pass.password.length<6 || pass.confirm_pass.length<6){
      tostE('Enter a password with more than 6 characters')
      return
    }

    if(open.id==="" && open.show===false && open.showPass!==false){
      if(pass.password===pass.confirm_pass){

        var SendData = {
          admin_id:pass.admin_id,
          password:pass.password
        }

        dispatch(editClientsPass(SendData))
        setOpen({
          ...open,
          showPass:false,
          show:false,        
          id:""
        })
      }else{
        tostE("Please Enter Same Password")
      }
    }
    
  }

  

  const listData = useSelector((state) =>
  state.AddClients && state.AddClients.getData &&
    Object.keys(state.AddClients.getData).length &&
    Array.isArray(state.AddClients.getData.data)
      ? state.AddClients.getData.data
      : []
  );

  
  const countD= useSelector((state) =>
  state.AddClients && state.AddClients.getData.data &&
   Array.isArray(state.AddClients.getData.data)
      ? parseInt(state.AddClients.getData.total_length)
      : 0
  );



  const countF= useSelector((state) =>
  state.AddClients && state.AddClients.getData.data &&
   Array.isArray(state.AddClients.getData.data)
      ? parseInt(state.AddClients.getData.page)
      : 0
  );

  const [pagination , setPagination] = useState({
    per_page : 5,
    page : countF,

  })
 
  const BackupLoad = useSelector(state => state.AddClients && state.AddClients.loading ? state.AddClients.loading : null  )
  const BackupMsg = useSelector(state => state.AddClients && state.AddClients.backupData && state.AddClients.backupData.message ? state.AddClients.backupData.message : null  )
  const BackupErr = useSelector(state => state.AddClients && state.AddClients.backupError && state.AddClients.backupError.message ? state.AddClients.backupError.message : null  )
  const Delmsg = useSelector(state => state.AddClients && state.AddClients.delData && state.AddClients.delData.message ? state.AddClients.delData.message : null  )
  const Delerror = useSelector(state => state.AddClients && state.AddClients.delError && state.AddClients.delError.message ? state.AddClients.delError.message : null  )
  const Editmsg = useSelector(state => state.AddClients && state.AddClients.editData && state.AddClients.editData.message ? state.AddClients.editData.message : null  )
  const Editerror = useSelector(state => state.AddClients && state.AddClients.editError && state.AddClients.editError.message ? state.AddClients.editError.message : null  )
  const EditPassmsg = useSelector(state => state.AddClients && state.AddClients.editPassData && state.AddClients.editPassData.message ? state.AddClients.editPassData.message : null  )
  const EditPasserror = useSelector(state => state.AddClients && state.AddClients.editPassError && state.AddClients.editPassError.message ? state.AddClients.editPassError.message : null  )


  // dispatch(getClients())



  

  
useEffect( () => {
  var t  =  {page : pagination.page , per_page : pagination.per_page}

    dispatch(getClients(t))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }
      if(BackupMsg && typeof(BackupMsg) === 'string')
      {
        tostS(BackupMsg)
      }
      if(BackupErr && typeof(BackupErr) === 'string')
      {
          tostE(BackupErr)
      }
      if(Editmsg && typeof(Editmsg) === 'string')
      {
        tostS(Editmsg)
      }
      if(Editerror && typeof(Editerror) === 'string')
      {
          tostE(Editerror)
      }
      if(EditPassmsg && typeof(EditPassmsg) === 'string')
      {
        tostS(EditPassmsg)
      }
      if(EditPasserror && typeof(EditPasserror) === 'string')
      {
          tostE(EditPasserror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanClientsData())
    dispatch(getClients(t))

    })


    
  }, [ Delmsg  ,Delerror, Editmsg, Editerror, EditPassmsg, EditPasserror, BackupMsg, BackupErr ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/orders/edit/'+olData.admin_id , { state: olData })
}

const generateBackup = (data) =>{
  const formattedData = {
    admin_id: data.admin_id,
    name: data.name,
    email: data.email,
    mobile: data.mobile,
    number_of_sites: data.number_of_sites,
    users: data.users,
    storage: data.storage,
    plan_name: data.plan_name,
    plan_number: data.plan_number,
    plan_start_date: data.plan_start_date,
    plan_end_date: data.plan_end_date,
    price: data.price,
    order_type: data.order_type,
    is_active: data.is_active,
    is_geo_api: data.is_geo_api,
    is_whatsapp_api: data.is_whatsapp_api
};
  console.log(data);
  console.log(data.tableData.id)
  setZipLoading(data.tableData.id)
  dispatch(getBackup(data.folder_name , formattedData))
}


const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  return `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
};

const generateInvoice = (rawInvoiceData) => {
  // Create a new jsPDF instance
  const doc = new jsPDF();

  // Add invoice details
  doc.setFontSize(20);
  doc.text('Invoice Details:', 10, 10);
  doc.setFontSize(14);
  doc.text(`Name: ${rawInvoiceData.name}`, 10, 20);
  doc.text(`Mobile: ${rawInvoiceData.mobile}`, 10, 30);
  doc.text(`Email: ${rawInvoiceData.email}`, 10, 40);
  doc.text(`Plan Name: ${rawInvoiceData.plan_name}`, 10, 50);
  doc.text(`Plan Number: ${rawInvoiceData.plan_number}`, 10, 60);
  doc.text(`Number of Sites: ${rawInvoiceData.number_of_sites}`, 10, 70);
  doc.text(`Price: $${rawInvoiceData.price}`, 10, 80);
  doc.text(`Storage: ${rawInvoiceData.storage}`, 10, 90);
  doc.text(`Order Type: ${rawInvoiceData.order_type}`, 10, 110);
  doc.text(`Plan Start Date: ${formatDate(rawInvoiceData.plan_start_date)}`, 10, 120);
  doc.text(`Plan End Date: ${formatDate(rawInvoiceData.plan_end_date)}`, 10, 130);

  // Save the PDF
  doc.save('invoice.pdf');
};

const handleDownloadInvoice = (rawInvoiceData) => {
 
  generateInvoice(rawInvoiceData);
};



const handleDetail = (data) => {
  navigate('/app/orders/detail/'+data.admin_id, {state:data})
}


const handleChange = (event) => {


  setPass({
    ...pass,
    [event.target.name]: event.target.value,
  });
};


const handleClickShowPassword = () => {
  setPass({
    ...pass,
    showPassword: !pass.showPassword,
  });
};
const handleClickShowConfirmPass = () => {
  setPass({
    ...pass,
    showConfirmPass: !pass.showConfirmPass,
  });
};

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};




const exportField = [
  {
    field : "name" , 
    val : "Name"
  }, 
  {
    field : "mobile" , 
    val : "Mobile"
  }, 
  {
    field : "email" , 
    val : "Email"
  }, 
  {
    field : "plan_number" , 
    val : "Plan Purchased"
  }, 
  {
    field : "plan_start_date", 
    val : "Plan Start Date"
  }, 
  {
    field : "plan_end_date" , 
    val : "Plan End Date"
  },
  {
    field : "price" , 
    val : "Price Paid"
  },
  {
    field : "invoice_link" , 
    val : "Invoice"
  },
  {
    field : "order_type" , 
    val : "Order Type"
  },
]


  
const handlePageChange = (e , x) =>{
  const parameter = {page : e , per_page :pagination.per_page }
  var u   = new Promise((resolve ,reject)=>{
    
    if(x !== null  && x !== undefined )
    {
      dispatch(getClients(parameter))
      
    }
    
    resolve()
    
  })
  u.then(()=>{
    
    setPagination({...pagination , ['page']  : e})
  })

}

const handleRPP = (e) =>{
  console.log(e)
  // setPagination({...pagination , ['per_page']  : e})
  const parameter = {page : e , per_page :pagination.per_page}

  var u   = new Promise((resolve ,reject)=>{
    
   
      dispatch(getClients(parameter))
      
    
    
    resolve()
    
  })
  u.then(()=>{
    
    // setPagination({...pagination , ['page']  : e})
    setPagination({...pagination , ['per_page']  : e})
  })

}


  


  const addNewBornbutton = () =>{
    navigate('/app/orders_add')
    console.log("Place order")
    
  }




  const [values, setValues] = useState({
   
    country:"",

  
    });



const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };


  return (
    <>
      <Page className={classes.root} title="Order Purchased">
      <ToastContainer    />

      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Sub Admin??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      
      
      <Dialog disableBackdropClick={true} open={open.showPass} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="password"
                type={pass.showPassword ? 'text' : 'password'}
                value={pass.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {pass.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
            </Grid>
            <br/>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="confirm_pass"
                type={pass.showConfirmPass ? 'text' : 'password'}
                value={pass.confirm_pass}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPass}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {pass.showConfirmPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            </Grid>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            
            <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'OrderManagement')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'OrderManagement')
              }>
                Export to PDF
            </Button>
            <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Create Clients
              </Button>

              </Box>
            
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}    
      tableRef={tableRef}  
      title="Order Purchased List"
      columns={[
        {
          title: 'SR. NO.',                    
          render: rowData => (
            rowData.tableData.id+1
          ),
        },
        { title: 'Name', field: 'name',editable: "never", },
        { title: 'Mobile', field: 'mobile',editable: "never", },     
        { title: 'Plan Purchased', field: 'plan_name',editable: "never", },   
        { title: 'Status',
          render: rowData => (
            rowData.is_active ?
            <Typography variant="body2" style = {{color:"green"}}>Active</Typography>
            :
            <Typography variant="body2" style = {{color:"red"}}>In-Active</Typography>
            
          )
        },                   
       { title: "Generate Backup",
        render : (rowData) =>(


           BackupLoad===true && rowData.tableData.id === zipLoading ?

          <CircularProgress size={30}/>
          :
          <Tooltip title="generate Backup" arrow>

          <BackupDownloadIcon onClick={()=>{generateBackup(rowData)}} style={{cursor:"pointer"}} />
          </Tooltip>
          

          
        )
       },
       { title: "Download Invoice",
        render : (rowData) =>(

             


           
          <Tooltip title="DownLoad Invoice" arrow>

          <DownloadIcon onClick={()=>{handleDownloadInvoice(rowData)}}  style={{cursor:"pointer"}} />
          </Tooltip>
          

          
        )
       },
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              <Grid item xs={3}>
                <Tooltip title="View Detail" arrow>
                  <DetailIcon onClick = {() => {handleDetail(rowData)}}  style={{cursor:"pointer"}} />                    
                </Tooltip>
              </Grid>
              <Grid item xs={3}>
                <Tooltip title="Edit Details" arrow>
                  <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
                </Tooltip>
              </Grid>
              <Grid item xs={3}>
                <Tooltip title="Delete">
                  <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
                </Tooltip>
              </Grid>
              <Grid item xs={3}>
                <Tooltip title="Change Password" arrow>
                  <PasswordIcon  onClick={()=>{handleOpen2(rowData)}} style={{cursor:"pointer"}} />                    
                </Tooltip>
              </Grid>
              
            </Grid>
          ),
        },

      ]}
      data={listData}
          options={{              
              textAlign: "center",
              headerStyle: { textAlign: "left" },
              rowStyle: { textAlign: "center" },
              emptyRowsWhenPaging:false,  
            }}
            components={{
        Pagination: props => (
                     <TablePagination
                     {...props}
                   
                count={countD}
                page={countF}
               
              />
            ),
                  }}

      onChangeRowsPerPage={(e)=>handleRPP(e)}
      onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}              

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
