import axios  from "../Config/Interceptor1";





import { Base_URL } from '../Config/BaseUrlConfig'




import {logoutBegin , logoutSuccess , logoutFailure, cleanAllData} from './ActionCreators'







export const logout = (v) => {
      console.log(v);
    return (dispatch) => {
        dispatch(logoutBegin());

       axios
        .get(Base_URL + '/common/logoutcom', {params : {'email' : v}})
            .then((res) => {
              console.log(res)
                dispatch(logoutSuccess(res.data));
                dispatch(cleanAllData())
            }).catch((err) => {
              console.log(err)
                dispatch(logoutFailure(err.response.data));
            })
    }
}









