import {GET_SUPER_ENQ_LOADING , GET_SUPER_ENQ_SUCCESS , GET_SUPER_ENQ_FAILURE } from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types';





const AddEnq = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [],
            loading:false,
            error:{},
            getData:[],
            getError:{}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [],
            loading:false,
            error:{},
            getData:[],
            getError:{}
            
        }

    
 



        case GET_SUPER_ENQ_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                
            }
        case GET_SUPER_ENQ_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data
            }
        case GET_SUPER_ENQ_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                data:{},
            }   


    default:
        return state

}
}



export default AddEnq;
