// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor3";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {addFaqBegin , addFaqSuccess , addFaqFailure } from './../ActionCreators'
import {getFaqBegin , getFaqSuccess , getFaqFailure } from './../ActionCreators'
import {editFaqBegin , editFaqSuccess , editFaqFailure } from './../ActionCreators'
import {delFaqBegin , delFaqSuccess , delFaqFailure } from './../ActionCreators'





export const addFaq = (session) => {

    return (dispatch) => {
        dispatch(addFaqBegin());

        axios.post(Base_URL + '/superadmin/addFaqs', session)
            .then((res) => {
                dispatch(addFaqSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addFaqFailure(err.response.data));

                }
            })
    }
}



export const getFaq = () => {

    return (dispatch) => {
        dispatch(getFaqBegin());

        axios.get(Base_URL + '/superadmin/getFaqs')
            .then((res) => {
                dispatch(getFaqSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getFaqFailure(err.response.data));

                }
            })
    }
}



export const editFaq = (session) => {
    console.log(session);
    return (dispatch) => {
        dispatch(editFaqBegin());

        axios.post(Base_URL + '/superadmin/updateFaqs', session)
            .then((res) => {
                dispatch(editFaqSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editFaqFailure(err.response.data));

                }
            })
    }
}


export const delFaq = (x) => {

    return (dispatch) => {
        dispatch(delFaqBegin());


        axios.get(Base_URL + '/superadmin/delFaqs' , {params :{faq_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delFaqSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delFaqFailure(err.response.data));

                }
            })
    }
}







