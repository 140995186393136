import React   from 'react';

import  {useSelector} from 'react-redux' 
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../src/layouts/DashboardLayout';
import MainLayout from '../../src/layouts/MainLayout';
import DashboardView from '../../src/views/reports/DashboardView/index';
import LoginView from '../../src/views/auth/LoginView';
import NotFoundView from '../../src/views/errors/NotFoundView';
import ProductListView from '../../src/views/product/ProductListView';
import RegisterView from '../../src/views/auth/RegisterView';
import SettingsView from '../../src/views/settings/SettingsView';




// Admin


const routes1 = [

  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
     
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      
      { path: '404', element: <NotFoundView/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }

    ]
  },

  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'login', element: <DashboardView /> },
      { path: 'register', element: <DashboardView /> },
      { path: '404', element: <DashboardView/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
  
];

export default routes1;
