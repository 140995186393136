
import axios from "../../Config/Interceptor2";

// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {getCashExpBegin , getCashExpSuccess , getCashExpFailure} from '../ActionCreators'
import {delCashExpBegin , delCashExpSuccess , delCashExpFailure} from '../ActionCreators'


export const getCashExp = (x) => {
    var parameter = x;
    if(parameter.start_date==="" || parameter.start_date===null || parameter.start_date===undefined){
        parameter.start_date=new Date('2001', '01', '01')
    }
    return (dispatch) => {
        dispatch(getCashExpBegin());

        axios.get(Base_URL + '/subAdmin/getCashExpense' , {params : parameter})
            .then((res) => {
                dispatch(getCashExpSuccess(res.data));
                console.log("data======", res.data)
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getCashExpFailure(err.response.data));

                }
            })
    }
}

export const delCashExp = (x) => {

    return (dispatch) => {
        dispatch(delCashExpBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delCashExpense' , {params :{ce_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delCashExpSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delCashExpFailure(err.response.data));

                }
            })
    }
}
