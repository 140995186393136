// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {acceptMatIndentBegin , acceptMatIndentSuccess , acceptMatIndentFailure} from '../ActionCreators';
import {getMaterialIndentBegin , getMaterialIndentSuccess , getMaterialIndentFailure} from '../ActionCreators';
import {delMaterialIndentBegin , delMaterialIndentSuccess ,  delMaterialIndentFailure} from '../ActionCreators';
import {orderMaterialIndentBegin , orderMaterialIndentSuccess ,  orderMaterialIndentFailure} from '../ActionCreators';



export const acceptMatIndent = (session) => {

    return (dispatch) => {
        dispatch(acceptMatIndentBegin());

        axios.post(Base_URL + '/subAdmin/editMaterialIndent', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(acceptMatIndentSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(acceptMatIndentFailure(err.response.data));

                }
            })
    }
}



export const getMaterialIndent = (t) => {

    var parameter = t;
    if(parameter.start_date==="" || parameter.start_date===null || parameter.start_date===undefined){
        parameter.start_date=new Date('2001', '01', '01')
    }

    return (dispatch) => {
        dispatch(getMaterialIndentBegin());

        axios.get(Base_URL + '/subadmin/getMaterialIndent' , {params : parameter})
            .then((res) => {
                dispatch(getMaterialIndentSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getMaterialIndentFailure(err.response.data));

                }
            })
    }
}

export const delMaterialIndent = (x) => {

    return (dispatch) => {
        dispatch(delMaterialIndentBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delMaterialIndent' , {params :{indent_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delMaterialIndentSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delMaterialIndentFailure(err.response.data));

                }
            })
    }
}


export const orderMaterialIndent = (session) => {


    var formData = new FormData();
 

    
    formData.append("sub_admin_id",session.sub_admin_id)
    formData.append("indent_id",session.indent_id)
    formData.append("mat_details",JSON.stringify(session.mat_details))
    formData.append("priority",session.priority)
    formData.append("app_uid",session.app_uid)
    formData.append("accepted_by",session.accepted_by)
    formData.append("site_id",session.site_id)
    formData.append("vd_id",session.vd_id)
    formData.append("gst_type",session.gst_type)
    formData.append("vendor_input_name",session.vendor_name)
    formData.append("vendor_address",session.vendor_address)
    formData.append("vendor_mobile",session.vendor_mobile)
    formData.append("vendor_email",session.vendor_email)
    formData.append("delivery_date",session.delivery_date)
    formData.append("order_id",session.order_id)
    formData.append("folder_name",session.folder_name)
    formData.append("lat",session.lat)
    formData.append("long",session.long)
    formData.append("img",session.pdfInvoice)
    formData.append("img",session.img1)
    formData.append("img",session.img2)

    return (dispatch) => {
        dispatch(orderMaterialIndentBegin());

        axios.post(Base_URL + '/subAdmin/addMaterialDel', formData)
            .then((res) => {
                // console.log(res.data)
                dispatch(orderMaterialIndentSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(orderMaterialIndentFailure(err.response.data));

                }
            })
    }
}


// const FData = {
//     sub_admin_id :id,
//     indent_id: values.indent_id,
//     mat_details:MatList,    
//     priority:values.priority,
//     status:values.status,
//     accepted_by:values.accepted_by,
//     site_id:values.site_id,
//     vd_id:values.vd_id,
//     vendor_name:values.vendor_name,
//     vendor_address:values.vendor_address,
//     vendor_mobile:values.vendor_mobile,
//     delivery_date:values.delivery_date,
//     order_id:values.order_id,
//     folder_name:values.folder_name,
//     lat:values.lat,
//     long:values.long,
//     img1:values.img,
//     img2:values.img,
//     fixImg : "/static/images/avatars/upload.png"
 

//  }