
import axios from "../Config/Interceptor2";


import { Base_URL } from '../Config/BaseUrlConfig'

import {SubDashboardBegin , SubDashboardSuccess , SubDashboardFailure} from './ActionCreators'



export const SubDashboard = (x, y,z) => {
   
    return (dispatch) => {
        dispatch(SubDashboardBegin());

        axios.get(Base_URL + '/subadmin/getdashboard' , {params : {admin_id : x, folder_name:y, site_id: z}})
            .then((res) => {
                dispatch(SubDashboardSuccess(res.data));
                console.log("data======", res.data)
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(SubDashboardFailure(err.response.data));

                }
            })
    }
}
