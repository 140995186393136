import React, { useState } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';


const mapStyles = {
   width: "100%",
   height: "100%",
 };
 
 export function CustomMap({ google, data}){
    
  console.log(data)

  const [ selected, setSelected ] = useState(false);

   return (
         
      
       <Map
           google={google}
           containerStyle={{
               position: "static",
               width: "100%",
               height: "100%"
           }}
           style={{
               width: "100%",
               height: "100%"
           }}
           center={data.location}
           initialCenter={data.location}
           zoom={16}
           disableDefaultUI={true}
       >
           <Marker position={data.location} onClick={() => setSelected(true)} />
           
           <InfoWindow
              position={data.location}
              visible={selected}
              onClose={() => setSelected(false)}
              >
              
                <h6>Location</h6>
                <hr/>
                <p>Latitude:{data.location.lat}</p>
                <p>Longitude:{data.location.lng}</p>
              
            </InfoWindow>
            
           
       </Map>
   )
};

export default GoogleApiWrapper({
   apiKey: ("AIzaSyDwttm9hrWIFnzdQodRgq79HCnLsJkt1MA")
})(CustomMap);