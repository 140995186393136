import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../../src/components/Page';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { useDispatch, useSelector } from 'react-redux';
import {Genotp} from '../../Actions/resetPassword';
import {cleanResetPassData, otpEmail} from '../../Actions/ActionCreators'
import { tostE, tostS } from '../../Config/Toast';
import { ToastContainer } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#E36A26",
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {
    backgroundColor: "theme.palette.background.dark",
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    opacity:0.8,
    elevation:10,
    borderRadius:10
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [val, setVal] = useState({
    email:"",
    emailError:false
  })

  const resetMsg = useSelector(state => state.ResetPassword && state.ResetPassword.data && Object.keys(state.ResetPassword.data) && Object.keys(state.ResetPassword.data).length && state.ResetPassword.data.status===200 ? state.ResetPassword.data.status : null)
  const resetError = useSelector(state => state.ResetPassword && state.ResetPassword.data && Object.keys(state.ResetPassword.data) && Object.keys(state.ResetPassword.data).length && state.ResetPassword.data.status===404 ? state.ResetPassword.data.status : null)

  useEffect(() => {
    let p1 = new Promise((resolve, reject) => {
      if(resetMsg!==null && resetMsg===200){
        tostS("OTP Sent Successfully!!")
        dispatch(otpEmail(val.email))
      }
      if(resetError!==null && resetError===404){
        tostE("Incorrect Email ID !!")
        dispatch(otpEmail(""))
      }

      resolve()
    })
    p1.then(() => {
      console.log("otp generated")
      dispatch(cleanResetPassData())
    })
  }, [resetMsg, resetError])




const handleChange = (event) => {
  setVal({
    ...val,
    [event.target.name]:event.target.value
  })
}

const emailRegex = /\S+@\S+\.\S+/;
const handleSubmit = () => {
  if (!emailRegex.test(val.email))
  {
    tostE("Please Enter Valid Email");
    setVal({
      ...val,
      ['email']:"",
      ["emailError"]:true
    })

  }else{

    dispatch(Genotp(val))
    dispatch(otpEmail(val.email))
  }
  

}
  return (
    <Page
      className={classes.root}
      title="Register"
    >
    <ToastContainer />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
        <Card className={classes.root2 , {elevation:10 , boxShadow:10}}>
          <CardContent>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Reset Your Password
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Use your Email to Reset Password.
              </Typography>
            </Box>
            
            <TextField              
              fullWidth              
              label="Email"
              margin="normal"
              name="email"              
              type="email"              
              variant="outlined"
              error={val.emailError}              
              value={val.email}
              onChange={handleChange}
            />
            {val.emailError ? 
            <Typography variant={"caption"} style={{color:"red"}}>Please Enter a valid Email</Typography>
            :<></>
            }
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Reset Now
              </Button>
            </Box> 
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Have an account?
              {' '}
              <Link
                component={RouterLink}
                to="/login"
                variant="h6"
              >
                Sign in
              </Link>
            </Typography>
          </CardContent>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};


export default RegisterView;