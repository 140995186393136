// import axios from "../Config/Interceptor1";


import axios from '../../Config/Interceptor2'


import { Base_URL } from '../../Config/BaseUrlConfig'



import {getStockSummaryBegin , getStockSummarySuccess , getStockSummaryFailure} from './ActionCreators'
import {getConsumedDataBegin , getConsumedDataSuccess , getConsumedDataFailure} from './ActionCreators'


export const getStockSummary = (x) => {

    return (dispatch) => {
        dispatch(getStockSummaryBegin());

        axios.get(Base_URL + '/subadmin/getss' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getStockSummarySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getStockSummaryFailure(err.response.data));

                }
            })
    }
}




export const getConsumedData = (x) => {

    return (dispatch) => {
        dispatch(getConsumedDataBegin());

        axios.get(Base_URL + '/subadmin/getSConsumed' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getConsumedDataSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getConsumedDataFailure(err.response.data));

                }
            })
    }
}
