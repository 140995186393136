import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../../src/layouts/DashboardLayout';
import DashboardView from '../../../src/views/reports/DashboardView/index';
import DashboardView2 from '../../../src/Screens/SubAdmin/Dasboard/index';
import ProductListView from '../../../src/views/product/ProductListView';
import SettingsView from '../../../src/views/settings/SettingsView';



import MaterialIndentList from '../../Screens/SubAdmin/Procurement/MaterialIndent/List'
import MaterialIndentAccept from '../../Screens/SubAdmin/Procurement/MaterialIndent/Accept'
import MaterialIndentOrder from '../../Screens/SubAdmin/Procurement/MaterialIndent/Order'
import MaterialIndentDetail from '../../Screens/SubAdmin/Procurement/MaterialIndent/Details'
import MachineIndentList from '../../Screens/SubAdmin/Procurement/MachineIndent/List'
import MachineIndentAccept from '../../Screens/SubAdmin/Procurement/MachineIndent/Accept'
import MachineIndentDetail from '../../Screens/SubAdmin/Procurement/MachineIndent/Details'
import MachineIndentOrder from '../../Screens/SubAdmin/Procurement/MachineIndent/Order'
import PurchaseOrderList from '../../Screens/SubAdmin/Procurement/PurchaseOrders/List'
import PurchaseOrderAdd from '../../Screens/SubAdmin/Procurement/PurchaseOrders/Add'
import PurchaseOrderMacDetail from '../../Screens/SubAdmin/Procurement/PurchaseOrders/MacDetail'
import PurchaseOrderMatDetail from '../../Screens/SubAdmin/Procurement/PurchaseOrders/MatDetail'

import SiteMaster_Material from '../../Screens/SubAdmin/SiteReport/Material/List'
import SiteMaster_Material_Detail from '../../Screens/SubAdmin/SiteReport/Material/Detail'
import SiteMaster_Material_Supply from '../../Screens/SubAdmin/SiteReport/MaterialSupply/List'
import SiteMaster_Material_Supply_Detail from '../../Screens/SubAdmin/SiteReport/MaterialSupply/Detail'
import SiteMaster_Manpower from '../../Screens/SubAdmin/SiteReport/Manpower/List'
import SiteMaster_Manpower_Detail from '../../Screens/SubAdmin/SiteReport/Manpower/Detail'
import SiteMaster_Machine from '../../Screens/SubAdmin/SiteReport/Machine/List'
import SiteMaster_Machine_Detail from '../../Screens/SubAdmin/SiteReport/Machine/Detail'
import SiteMaster_Expense from '../../Screens/SubAdmin/SiteReport/SiteExpense/List'
import SiteMaster_QS from '../../Screens/SubAdmin/SiteReport/QualitySafety/List'
import SiteMaster_QSDetail from '../../Screens/SubAdmin/SiteReport/QualitySafety/Detail'
import SiteMaster_Progress from '../../Screens/SubAdmin/SiteReport/SiteProgress/List'
import SiteMaster_ProgressDetail from '../../Screens/SubAdmin/SiteReport/SiteProgress/Details'


// Inventory Imports

import I_Dashboard from '../../Screens/SubAdmin/Inventory/Dashboard/List'
import I_DashboardAdd from '../../Screens/SubAdmin/Inventory/Dashboard/Add'
import I_Delivery from '../../Screens/SubAdmin/Inventory/Delivery/List'
import I_AcceptMat from '../../Screens/SubAdmin/Inventory/Delivery/AcceptMaterial'
import I_AcceptMac from '../../Screens/SubAdmin/Inventory/Delivery/AcceptMachine'
import I_AcceptedDelivery from '../../Screens/SubAdmin/Inventory/AcceptedDelivery/List'
import I_AcceptedMatDelDetail from '../../Screens/SubAdmin/Inventory/AcceptedDelivery/DetailMaterial'
import I_AcceptedMacDelDetail from '../../Screens/SubAdmin/Inventory/AcceptedDelivery/DetailMachine'
import I_Grn from '../../Screens/SubAdmin/Inventory/GRN/List'
import I_Grn_OrderApprove from '../../Screens/SubAdmin/Inventory/GRN/ApproveOrder'
import I_Grn_OrderApproveMachine from '../../Screens/SubAdmin/Inventory/GRN/ApproveMachineOrder'
import I_Grn_OrderDetail from '../../Screens/SubAdmin/Inventory/GRN/details'
import I_History from '../../Screens/SubAdmin/Inventory/History/List'
import I_Transfer from '../../Screens/SubAdmin/Inventory/Transfer/List'
import I_TransferAdd from '../../Screens/SubAdmin/Inventory/Transfer/Add'
import I_IssueItem from '../../Screens/SubAdmin/Inventory/GRN/IssueItem'


import Report_Gen from '../../Screens/SubAdmin/ReportGeneration/Add'

import Profile_Management from '../../Screens/SubAdmin/ProfileManagement/Add'

import Site_Drawings from '../../Screens/SubAdmin/SiteDrawings/List'
import Site_Drawings_Add from '../../Screens/SubAdmin/SiteDrawings/Add'
import Site_Drawings_Edit from '../../Screens/SubAdmin/SiteDrawings/Edit'

import Help_Add from '../../Screens/SubAdmin/Help/Add'
import Help_Edit from '../../Screens/SubAdmin/Help/Edit'
import Help_List from '../../Screens/SubAdmin/Help/List'

import FAQ from '../../Screens/SubAdmin/FAQ/List'





//Master Routes
import M_User_Add from '../../Screens/SubAdmin/Master/User/Add'
import M_User_List from '../../Screens/SubAdmin/Master/User/List'
import M_User_Edit from '../../Screens/SubAdmin/Master/User/Edit'
import M_Site_List from '../../Screens/SubAdmin/Master/SiteMaster/List'
import M_Site_Add from '../../Screens/SubAdmin/Master/SiteMaster/Add'
import M_Site_Edit from '../../Screens/SubAdmin/Master/SiteMaster/Edit'
import M_App_User_Add from '../../Screens/SubAdmin/Master/AppUserManagement/Add'
import M_App_User_List from '../../Screens/SubAdmin/Master/AppUserManagement/List'
import M_App_User_Edit from '../../Screens/SubAdmin/Master/AppUserManagement/Edit'
import M_Unit_List from '../../Screens/SubAdmin/Master/UnitMaster/List'
import M_Unit_Add from '../../Screens/SubAdmin/Master/UnitMaster/Add'
import M_Mat_Category_List from '../../Screens/SubAdmin/Master/MaterialMaster/CategoryMaster/List'
import M_Mat_Category_Add from '../../Screens/SubAdmin/Master/MaterialMaster/CategoryMaster/Add'

import M_Machine_List from '../../Screens/SubAdmin/Master/MachineMaster/Machine/List'
import M_Mach_Category_List from '../../Screens/SubAdmin/Master/MachineMaster/CategoryMaster/List'
import M_Issue_List from '../../Screens/SubAdmin/Master/Quality&SafetyMaster/List'
import M_Issue_Add from '../../Screens/SubAdmin/Master/Quality&SafetyMaster/Add'
import M_Expense_Add from '../../Screens/SubAdmin/Master/SiteExpenseMaster/Add'
import M_Expense_List from '../../Screens/SubAdmin/Master/SiteExpenseMaster/List'

import M_Material_List from '../../Screens/SubAdmin/Master/MaterialMaster/Material/List'
import M_Drawing_Cat_List from '../../Screens/SubAdmin/Master/DrawingMaster/List'
import M_Drawing_Type_List from '../../Screens/SubAdmin/Master/DrawingType/List'
import M_Floor_Type_List from '../../Screens/SubAdmin/Master/FloorType/List'
import M_Drawing_Cat_Add from '../../Screens/SubAdmin/Master/DrawingMaster/Add'
import M_Manpower_Cat_Add from '../../Screens/SubAdmin/Master/ManpowerMaster/CategoryMaster/Add'
import M_Manpower_Cat_List from '../../Screens/SubAdmin/Master/ManpowerMaster/CategoryMaster/List'
import M_Manpower_List from '../../Screens/SubAdmin/Master/ManpowerMaster/Manpower/List'

import Roles_List from '../../Screens/SubAdmin/Master/Role/List'
import Roles_List_Add from '../../Screens/SubAdmin/Master/Role/Add'
import Role_Edit from '../../Screens/SubAdmin/Master/Role/Edit'
import Vendor_List from '../../Screens/SubAdmin/Master/VendorMaster/List'
import Vendor_List_Add from '../../Screens/SubAdmin/Master/VendorMaster/Add'
import Vendor_List_Edit from '../../Screens/SubAdmin/Master/VendorMaster/Edit'
import TestY from '../../Screens/SubAdmin/Master/VendorMaster/test'
import TestPdf from '../../Screens/SubAdmin/Procurement/MaterialIndent/Test'

import { useRoutes } from 'react-router-dom';
import BoqListing from '../../Screens/SubAdmin/BOQ/add';
import CreateBoq from '../../Screens/SubAdmin/BOQ/CreateBoq';
import EditBoq from '../../Screens/SubAdmin/BOQ/edit';
import AddManualBoq from '../../Screens/SubAdmin/BOQ/Manually';
import GenerateBill from '../../Screens/SubAdmin/BOQ/GenerateBill';
import DailyReport from '../../Screens/SubAdmin/DailyReport/DailyReport';
import WeeklyReport from '../../Screens/SubAdmin/DailyReport/WeeklyReport';
import MeasureMentBook from '../../Screens/SubAdmin/BOQ/MeasureMentBook';
import SiteTestReports from '../../Screens/SubAdmin/SiteReport/SiteTestReports/SiteTestReports';
import BillDetailsPage from '../../Screens/SubAdmin/BOQ/BillDetails';
import DailyReportList from '../../Screens/SubAdmin/DailyReport/List';

// import {useSelector} from 'react-redux'


// Admin

const  All =()=>{

  return useRoutes([
    {
    path: 'app',
    element: <DashboardLayout />,
    children: [

      
     
      { path: 'dashboard', element: <DashboardView2 /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },

      { path: 'materialindentlist', element: <MaterialIndentList/> },
      { path: 'testpdf', element: <TestPdf/> },
      { path: 'materialindent_accept/:id', element: <MaterialIndentAccept/> },
      { path: 'materialindent_order/:id', element: <MaterialIndentOrder/> },
      { path: 'materialindent_detail/:id', element: <MaterialIndentDetail/> },
      { path: 'machineindentlist', element: <MachineIndentList/> },
      { path: 'machineindent_accept/:id', element: <MachineIndentAccept/> },
      { path: 'machineindent_order/:id', element: <MachineIndentOrder/> },
      { path: 'machineindent_detail/:id', element: <MachineIndentDetail/> },
      { path: 'purchaseorderlist', element: <PurchaseOrderList/> },
      { path: 'purchaseorderlist_add', element: <PurchaseOrderAdd/> },
      { path: 'purchaseorderlist_macdetail/:id', element: <PurchaseOrderMacDetail/> },
      { path: 'purchaseorderlist_matdetail/:id', element: <PurchaseOrderMatDetail/> },
      
      { path: 'sitemaster_material', element: <SiteMaster_Material/> },
      { path: 'sitemaster_material_detail/:id', element: <SiteMaster_Material_Detail/> },
      { path: 'sitemaster_mat_supply', element: <SiteMaster_Material_Supply/> },
      { path: 'sitemaster_mat_supply_detail/:id', element: <SiteMaster_Material_Supply_Detail/> },
      { path: 'sitemaster_manpower', element: <SiteMaster_Manpower/> },
      { path: 'sitemaster_manpower_detail/:id', element: <SiteMaster_Manpower_Detail/> },
      { path: 'sitemaster_machine', element: <SiteMaster_Machine/> },
      { path: 'sitemaster_machine/detail/:id', element: <SiteMaster_Machine_Detail/> },
      { path: 'sitemaster_expense', element: <SiteMaster_Expense/> },
      { path: 'sitemaster_q&s', element: <SiteMaster_QS/> },
      { path: 'sitemaster_q&s/detail/:id', element: <SiteMaster_QSDetail/> },
      { path: 'sitemaster_progress', element: <SiteMaster_Progress/> },
      { path: 'sitemaster_progress/detail/:id', element: <SiteMaster_ProgressDetail/> },

      
// Inventory Routes

      { path: 'inventory/dashboard', element: <I_Dashboard/> },
      { path: 'inventory/add', element: <I_DashboardAdd/> },
      { path: 'inventory/delivery', element: <I_Delivery/> },
      { path: 'inventory/delivery/material/accept/:id', element: <I_AcceptMat/> },
      { path: 'inventory/delivery/machine/accept/:id', element: <I_AcceptMac/> },
      { path: 'inventory/accepted-delivery', element: <I_AcceptedDelivery/> },
      { path: 'inventory/accepted-delivery/material/detail/:id', element: <I_AcceptedMatDelDetail/> },
      { path: 'inventory/accepted-delivery/machine/detail/:id', element: <I_AcceptedMacDelDetail/> },
      { path: 'inventory/grn', element: <I_Grn/> },
      { path: 'inventory/grn/issue', element: <I_IssueItem/> },
      { path: 'inventory/grn/approve-material/:id', element: <I_Grn_OrderApprove/> },
      { path: 'inventory/grn/approve-machine/:id', element: <I_Grn_OrderApproveMachine/> },
      { path: 'inventory/grn/detail/:id', element: <I_Grn_OrderDetail/> },
      { path: 'inventory/history', element: <I_History/> },
      { path: 'inventory/transfer', element: <I_Transfer/> },
      { path: 'inventory/transfer/add', element: <I_TransferAdd/> },
      




      { path: 'report_gen', element: <Report_Gen/> },
      
      { path: 'profile_management', element: <Profile_Management/> },
      
      { path: 'site_drawings', element: <Site_Drawings/> },
      { path: 'site_drawings_add', element: <Site_Drawings_Add/> },
      { path: 'site_drawings_edit/:id', element: <Site_Drawings_Edit/> },


      { path: 'boq', element: <BoqListing></BoqListing>},
      { path: 'create_boq', element: <CreateBoq></CreateBoq>},
      { path: 'edit_boq/:id', element: <EditBoq></EditBoq>},
      { path: 'add_manually_boq', element: <AddManualBoq></AddManualBoq>},
      { path: 'generate_bill_mb', element: <GenerateBill></GenerateBill>},
      { path: 'generate_Daily_Report/:date', element: <DailyReport></DailyReport>},
      { path: 'View_Daily_Report', element:<DailyReportList></DailyReportList>},
      { path: 'generate_Monthly_Report', element: <WeeklyReport></WeeklyReport>},
      { path: 'ViewReportDetails/:id', element:<BillDetailsPage />},
      { path: 'View_MB', element: <MeasureMentBook />},
      { path: 'Test_Reports', element: <SiteTestReports />},
      
      { path: 'list_help_add', element: <Help_Add/> },
      { path: 'help_edit/:id', element: <Help_Edit/>},
      { path: 'list_help', element: <Help_List/> },
      
      { path: 'faq_list', element: <FAQ/> },



      { path: 'master/users_list', element: <M_User_List/>  },
      { path: 'master/users_add', element: <M_User_Add/> },
      { path: 'master/users_edit/:id', element: <M_User_Edit/> },
      { path: 'master/site_list', element: <M_Site_List/> },
      { path: 'master/site_list_add', element: <M_Site_Add/> },
      { path: 'master/site_list_edit/:id', element: <M_Site_Edit/> },
      { path: 'master/app_user_list_add', element: <M_App_User_Add/> },
      { path: 'master/app_user_list', element: <M_App_User_List/> },
      { path: 'master/app_user_edit/:id', element: <M_App_User_Edit/> },      
      { path: 'master/unit_list', element: <M_Unit_List/> },
      { path: 'master/unit_list_add', element: <M_Unit_Add/> },
      { path: 'master/mat_category_list_add', element: <M_Mat_Category_Add/> },
      { path: 'master/mat_category_list', element: <M_Mat_Category_List/> },
      { path: 'master/machine_list', element: <M_Machine_List/> },
      { path: 'master/machine_cat_list', element: <M_Mach_Category_List/> },
      { path: 'master/issue_list_add', element: <M_Issue_Add/> },
      { path: 'master/issue_list', element: <M_Issue_List/> },
      { path: 'master/expense_list', element: <M_Expense_List/> },
      { path: 'master/expense_list_add', element: <M_Expense_Add/> },
      
      { path: 'master/material_list', element: <M_Material_List/> },
      { path: 'master/draw_cat_list', element: <M_Drawing_Cat_List/> },
      { path: 'master/draw_type_list', element: <M_Drawing_Type_List/> },
      { path: 'master/floor_type_list', element: <M_Floor_Type_List/> },
      { path: 'master/draw_cat_list_add', element: <M_Drawing_Cat_Add/> },
      { path: 'master/manpower_cat_list_add', element: <M_Manpower_Cat_Add/> },
      { path: 'master/manpower_cat_list', element: <M_Manpower_Cat_List/> },
      { path: 'master/manpower_list', element: <M_Manpower_List/> },
      
      { path: 'master/role_list', element: <Roles_List/> },
      { path: 'master/role_list_add', element: <Roles_List_Add/> },
      { path: 'master/role_edit/:id', element: <Role_Edit/> },
      { path: 'master/vendor_list', element: <Vendor_List/> },
      { path: 'master/vendor_list_add', element: <Vendor_List_Add/> },
      { path: 'master/vendor_list_edit/:id', element: <Vendor_List_Edit/> },
      { path: 'master/test', element: <TestY/> },
      
      
      
      
      
      
      
      { path: '404', element: <DashboardView/> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }

    ]
  },

  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'login', element: <DashboardView /> },
      { path: 'register', element: <DashboardView /> },
      { path: '404', element: <DashboardView/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }
    ]
  }
  
]);


}


export default All



 



