import React , { useRef, useState, useEffect } from 'react';

import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import PendingActions from './PendingActions';
import SpaceConsumed from './Space';
import Backup from './Backup';



import Users from './Users';
import { connect } from "react-redux";
import { ToastContainer} from 'react-toastify';
import {tostE , tostS , tostW} from '../../../Config/Toast';
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Buses from './Buses';
import Trains from './Trains';
import Loading from './Loading';

import Paper from '@material-ui/core/Paper';


import {getSites} from '../../../Actions/SubAdminActions/M_AddSites'
import {getAppUser} from '../../../Actions/SubAdminActions/AppUserManagement'

import {SubDashboard} from '../../../Actions/SubDashboard'

import {SelectedSite} from '../../../Actions/ActionCreators'
import TotalProgress from './TotalProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root2: {
    flexGrow: 1,
  },
  paper2: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch =  useDispatch();


  const id = useSelector((state) => state.login.data && Object.keys(state.login.data) && Object.keys(state.login.data).length && state.login.data.r_subadmin ? state.login.data.r_subadmin :"");
  
  const folder_name = useSelector((state) => state.login.data && Object.keys(state.login.data) && Object.keys(state.login.data).length && state.login.data.folder_name ? state.login.data.folder_name :"");

  const site_id_list = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.site_ids !== null ? state.login.data.role_permit.site_ids:null)

  const getDataDash = (data) => {
    if (data!==null && data!==undefined && data!==""){
      dispatch(SubDashboard(id, folder_name, data))

      return data
    }
  }

  const SelectSite = useSelector(state => state.SelectedSite && state.SelectedSite.sitedata && Object.keys(state.SelectedSite.sitedata) && Object.keys(state.SelectedSite.sitedata).length ?state.SelectedSite.sitedata.site : null)

  
  // dispatch(getDashboard())
  


  const homeData = useSelector((state) =>
  state.Dashboard &&
  state.Dashboard.SubData &&
  Object.keys(state.Dashboard.SubData) &&
  Object.keys(state.Dashboard.SubData).length 
  ? state.Dashboard.SubData
  : {}
  );



  useEffect( () => {
    dispatch(getSites(id, site_id_list))
    dispatch(getAppUser(id))
    
    dispatch(SubDashboard(id, folder_name, SelectSite))

   
  }, [])



  return (
    <Page className={classes.root} title="Dashboard">
      <ToastContainer    />
      <Container maxWidth={false}>
        <Grid container justify="center" spacing={3}>
          
          {/* 3 Top cards */}

          <Grid item lg={3} sm={12} xl={4} xs={12}>
            <Budget/>
          </Grid>
          <Grid item lg={4} sm={12} xl={4} xs={12}>
            <TotalCustomers/>
          </Grid>            
          <Grid item lg={4} sm={12} xl={4} xs={12}>
            <SpaceConsumed/>
          </Grid>            
          
          {/* 4 Cards */}
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress/>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Sales/>
          </Grid>
       
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid>                   
          {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Backup />
          </Grid>                    */}
          
         
          {/* 2 detailed card */}
          <Grid item lg={4} md={6} xl={3} xs={6}>
            <PendingActions/>
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={6}>
            <TrafficByDevice/>
          </Grid>
         
          <Grid item lg={4} md={6} xl={3} xs={12}>
           <TotalProgress />
          </Grid>
          
        </Grid>


        
      </Container>
    
    </Page>
  );
};



export default Dashboard;
