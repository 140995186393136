import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useLocation , useNavigate} from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../../Config/Toast';
import {ToastContainer} from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {editSites} from '../../../../Actions/SubAdminActions/M_AddSites';
import {cleanSites} from '../../../../Actions/ActionCreators'

const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#E36A26',
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        day: {
          color: '#E36A26',
        },
        daySelected: {
          backgroundColor: '#E36A26',
        },
        current: {
          color: '#E36A26',
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#E36A26',
        },
      },
    },
  });
  
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
const classes = useStyles();
const dispatch = useDispatch();
const {state} = useLocation();
const navigate = useNavigate()

const id = useSelector(state => state.login.data.admin_id);

const [selectedDate, setSelectedDate] = useState(moment(state.end_date).format('MM/DD/YY'));
const [selectedDate1, setSelectedDate1] = useState(moment(state.start_date).format('MM/DD/YY'));

const [values, setValues] = useState({
   
    admin_id :state.admin_id,
    start_date :state.start_date,
    end_date :state.end_date,
    plan_id : state.plan_id,
    site_name:state.site_name,
    site_address:state.site_address,
    budget:state.budget,
    site_id : state.site_id
  
    });
  
const handleChange = (event) => {
  setValues({
    ...values,
    [event.target.name]: event.target.value,
  });
};

// const handleSlab = (e) =>{
  
//   setValues({...values, [e.target.name]:e.target.value})
// }

const handleDateChange = (date) => {    
    setValues({...values, ['end_date'] : date  })

  };

  const handleDateChange1 = (date) => {
    
    setValues({...values, ['start_date'] : date  })

  };
const handleSubmit = () =>{

  if (values.budget <= 0)
  {
    tostE("Budget Must Be Positive")
  }
  else if(values.site_name.length < 2)
  {
    tostE("Site Name Must be Valid")
  }
  else if (values.site_address.length < 2)
  {
    tostE("Site Address Must Be Valid")
  }

  else{
    dispatch(editSites(values))   
    navigate('/app/master/site_list')   
  }
  // setValues({...values , ['slab_name'] :"" ,['percent'] :"" })
 
}

const CData = useSelector((state) =>
state.GetCountry && state.GetCountry.getdata &&
  state.GetCountry.getdata.length &&
  Array.isArray(state.GetCountry.getdata)
    ? state.GetCountry.getdata
    : []
);

// const addmsg = useSelector((state) => state.AddSites.editData && state.AddSites.editData.message ? state.AddSites.editData.message : null  )


// useEffect( () => {   
//       if(addmsg !== null && typeof(addmsg) === 'string'){   
        
//         var r  = new Promise((resolve , reject)=>{
        
//         setTimeout(function(){tostS(addmsg);},1000);

//       resolve()
//       }) 
//     r.then(()=>{ 
 
//      dispatch(cleanSites())
//      navigate('/app/master/site_list')
//     })
//     }
    
     
//     }, [addmsg])






  

    return (
        <Page
          className={classes.root}
          title="Edit Site"
        >
                  <ToastContainer    />
    
          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
            >
            
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader subheader="" title="Edit Site" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Site Name"
                    name="site_name"
                    onChange={handleChange}
                    required
                    value={values.site_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Bugdet"
                    name="budget"
                    onChange={handleChange}
                    required
                    value={values.budget}
                    variant="outlined"
                  />
                </Grid>
                
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Site Address"
                    name="site_address"
                    onChange={handleChange}
                    required
                    value={values.site_address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}></Grid>
                
                <Grid item md={6} xs={12}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      style={{ border: "1px  black" }}
                      id="date-picker-dialog"
                      label="Start Date"
                      format="dd/MM/yyyy"
                      value={values.start_date}
                      onChange={handleDateChange1}
                      variant="outlined"
                      inputVariant="outlined"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
    
                <Grid item md={6} xs={12}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="date-picker-dialog"
                      label="End Date"
                      value={values.end_date}
                      format="dd/MM/yyyy"
                      onChange={handleDateChange}
                      variant="outlined"
                      inputVariant="outlined"
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
          
    
                
              
              
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button onClick={handleSubmit} color="primary" variant="contained">
                Update details
              </Button>
            </Box>
          </Card>
        </form>
                {/* <ProfileDetails /> */}
              </Grid>
            </Grid>
          </Container>
        </Page>
      );
    };
    
export default AddBusiness;
