import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DownIcon from "@material-ui/icons/ArrowDownward"
import UpIcon from "@material-ui/icons/ArrowUpward"

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { ThemeProvider } from "@material-ui/styles";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {getConsumedData, getStockSummary} from '../../../../Actions/Inventory/History';

const  TabPanel = (props)=> {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}





const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const navigate = useNavigate();
  
  const a_id = useSelector(state => state.login && state.login.data && state.login.data.r_subadmin?state.login.data.r_subadmin:"");


    const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.name ? state.SelectedSite.sitedata.name : null)
  
  

    const filterData =(data) =>{
      var result = []
      console.log(data)
      if(Array.isArray(data) && data.length){
        result = data.filter(data => (!selected_site || data.site_name == selected_site));
        return result;
      }
  
      console.log(result)
      return result;
    }
  
  const [tvalue, setTValue] = React.useState(0);

  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
  };

  const ShowReverse = (d) => {
    return d.reverse()
  }

  const StockSummaryData =  useSelector(state => state.History.getData && 
    Array.isArray(state.History.getData) && state.History.getData.length ?
    filterData(state.History.getData) : [])
  
  const ConsumedData =  useSelector(state => state.History.consumedData && 
    Array.isArray(state.History.consumedData) && state.History.consumedData.length ?
    filterData(state.History.consumedData) : [])


    useEffect(() => {
      dispatch(getConsumedData(a_id))
      dispatch(getStockSummary(a_id))
    }, [])
  
  return (
    <>
      <Page className={classes.root} title="History">
      <ToastContainer    />
      <div>
      <AppBar position="static">
        <Tabs value={tvalue} onChange={handleChangetab} variant="fullWidth" textColor="inherit" aria-label="full width tabs example">
          <Tab label="Stock History" {...a11yProps(0)} />
          <Tab label="Consumed History" {...a11yProps(1)} />
          
        </Tabs>
      </AppBar>

      
      <TabPanel value={tvalue} index={0}>
      <Container maxWidth={false}>
          
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>
              <CardContent>
              <MaterialTable
                icons={tableIcons}      
                title="Stock History"
                columns={[
    
                  { title: 'Site Name', field: 'site_name',editable: "never", },        
                  { title: 'Item Type', field: 'item_type',editable: "never", },     
                  { title: 'Item Category', field: 'item_cat',editable: "never", },     
                  { title: 'Item Name', field: 'item_name',editable: "never", },     
                  { title: 'Old Quantity',
                    render : rowData => (
                      <Typography variant="body2">
                      {rowData.old_quan} {rowData.unit_name}

                      </Typography>
                    )
                  },         
                  { title: '', 
                    render: rowData => (
                      
                      <Typography variant="body2" style = {{fontWeight:"bold", color:parseInt(rowData.old_quan)>parseInt(rowData.new_quan) ? "red":"green"}}>
                      {parseInt(rowData.old_quan)>parseInt(rowData.new_quan) ? <DownIcon style={{height:"18px"}}/> : <UpIcon style={{height:"18px"}}/>}
                      {Math.abs(parseInt(rowData.old_quan) - parseInt(rowData.new_quan))} {rowData.unit_name}
                      </Typography>
                    )
                  
                   },     
                   { title: 'New Quantity', 
                      render : rowData => (
                          <Typography variant="body2">
                          {rowData.new_quan} {rowData.unit_name}

                          </Typography>
                        )
                      
                      },   
                    { title: 'Date', render:rowData => (moment(rowData.created_on).format("DD.MM.YYYY")) }, 
                    { title: 'Remark', field:"remark" }, 

                ]}
                data={StockSummaryData.reverse()}
                  options={{
                          selection: false,
                          textAlign: "center",
                          headerStyle: { textAlign: "left" },
                          rowStyle: { textAlign: "center" }
                        }}

              />
             </CardContent>
            </Card>
          </Box>
        </Container>
     
      </TabPanel>
      <TabPanel value={tvalue} index={1}>
      <Container maxWidth={false}>
          
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Consumed History"
      columns={[
       
        { title: 'Site Name', field: 'site_name',editable: "never", },        
        { title: 'Item Type', field: 'item_type',editable: "never", },     
        { title: 'Item Category', field: 'item_cat',editable: "never", },     
        { title: 'Item Name', field: 'item_name',editable: "never", },     
        { title: 'Old Quantity',
          render : rowData => (
            <Typography variant="body2">
            {rowData.old_quan} {rowData.unit_name}

            </Typography>
          )
         },     
        { title: '', 
          render: rowData => (
            
            <Typography variant="body2" style = {{fontWeight:"bold", color:parseInt(rowData.old_quan)>parseInt(rowData.new_quan) ? "red":"green"}}>
            {parseInt(rowData.old_quan)>parseInt(rowData.new_quan) ? <DownIcon style={{height:"18px"}}/> : <UpIcon style={{height:"18px"}}/>}
            {Math.abs(parseInt(rowData.old_quan) - parseInt(rowData.new_quan))} {rowData.unit_name}
            </Typography>
          )
        
          },     
        { title: 'New Quantity', 
        render : rowData => (
            <Typography variant="body2">
            {rowData.new_quan} {rowData.unit_name}

            </Typography>
          )
        
        },     
        { title: 'Date', render:rowData => (moment(rowData.created_on).format("DD.MM.YYYY")) }, 
        
      ]}
      data={ConsumedData.reverse()}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </TabPanel>      
         
    </div>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
