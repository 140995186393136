import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';

import {addRoles} from '../../../Actions/SuperAdminActions/AddRoles'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { cleanRoles } from '../../../Actions/ActionCreators';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },

}));




const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#E36A26",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);





const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const navigate = useNavigate()





  const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
  admin_id :id,
  added_date :selectedDate,
   role_name : "",

  dash_all  : false,   
  dash_view : false,    
  dash_del  : false,   
  dash_add  : false,   
  dash_upd    : false,
 
  om_all    : false, 
  om_view    : false, 
  om_del     : false,
  om_add     : false,
  om_upd     : false,
 
  
 
  rm_all     : false,
  rm_view     : false,
  rm_del     : false,
  rm_add     : false,
  rm_upd     : false,
 
  adp_all     : false,
  adp_view    : false, 
  adp_del     : false,
  adp_add     : false,
  adp_upd     : false,
 
  plan_all     : false,
  plan_view    : false, 
  plan_del     : false,
  plan_add     : false,
  plan_upd     : false,
   
  api_all     : false,
  api_view     : false,
  api_del     : false,
  api_add     : false,
  api_upd     : false,
 
  web_all     : false,
  web_view     : false,
  web_del     : false,
  web_add     : false,
  web_upd     : false,
   
  inq_all     : false,
  inq_view     : false,
  inq_del     : false,
  inq_add     : false,
  inq_upd     : false,
   
  tick_all     : false,
  tick_view    : false, 
  tick_del     : false,
  tick_add     : false,
  tick_upd     : false,
   
  gst_all     : false,
  gst_view    : false, 
  gst_del     : false,
  gst_add     : false,
  gst_upd     : false,
 
  report_all   : false,  
  report_view  : false,   
  report_del    : false, 
  report_add    : false, 
  report_upd    : false,
 
  profile_all   : false,  
  profile_view   : false,  
  profile_del    : false, 
  profile_add    : false, 
  profile_upd    : false, 
 

  fixImg : "/static/images/avatars/upload.png"

  });


  const handleChange = (event) => {
    
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 

  const [checked, setChecked] = React.useState(false);

  const handleChangecheck = (event) => {


    var str = event.target.name;



    const words = str.split('_');


    if(words[1] === 'all')
    {
      
    setValues({...values, [words[0]+'_'+'all']: event.target.checked, [words[0]+'_'+'view']: event.target.checked ,[words[0]+'_'+'del']: event.target.checked,[words[0]+'_'+'add']: event.target.checked,[words[0]+'_'+'upd']: event.target.checked});
    
    }
    else if(words[1] === 'del'  )
    {

          if( values[words[0]+'_'+'add'] === false && values[words[0]+'_'+'upd'] === false )
          {
          
            setValues({...values,[words[0]+'_'+'view']: event.target.checked ,[words[0]+'_'+'del']: event.target.checked});

          }
          else if( values[words[0]+'_'+'add'] === true && values[words[0]+'_'+'upd'] === true &&  values[words[0]+'_'+'view'])
          {
            setValues({...values,[words[0]+'_'+'all']: event.target.checked ,[words[0]+'_'+'del']: event.target.checked});

          }
          else
          {
                setValues({...values,[words[0]+'_'+'del']: event.target.checked});

          }

               



    }
    else if( words[1] === 'upd' )
    {
            if( values[words[0]+'_'+'add'] === false && values[words[0]+'_'+'del'] === false )
            {
            
              setValues({...values,[words[0]+'_'+'view']: event.target.checked ,[words[0]+'_'+'upd']: event.target.checked});

            }
            else if( values[words[0]+'_'+'add'] === true && values[words[0]+'_'+'del'] === true &&  values[words[0]+'_'+'view'])
            {
              setValues({...values,[words[0]+'_'+'all']: event.target.checked ,[words[0]+'_'+'upd']: event.target.checked});

            }
            else
            {
                  setValues({...values,[words[0]+'_'+'upd']: event.target.checked});

            }
  
      }
      else if( words[1] === 'add' )
      {
            if( values[words[0]+'_'+'upd'] === false && values[words[0]+'_'+'del'] === false )
            {
            
              setValues({...values,[words[0]+'_'+'view']: event.target.checked ,[words[0]+'_'+'add']: event.target.checked});

            }
            else if( values[words[0]+'_'+'upd'] === true && values[words[0]+'_'+'del'] === true &&  values[words[0]+'_'+'view'])
            {
              setValues({...values,[words[0]+'_'+'all']: event.target.checked ,[words[0]+'_'+'add']: event.target.checked});

            }
            else
            {
                  setValues({...values,[words[0]+'_'+'add']: event.target.checked});

            }


    
        }
        else if( words[1] === 'view' )
        
        {
          
                      var x =   words[0]+'_'+'add';
                        var  y =  words[0]+'_'+'upd';
                        var z = words[0]+'_'+'del';
                      
                      



                      if(values[x] === true || values[y] === true  || values[z] === true)
                      {
                          tostE("View Permission is needed for Add/Update/Delete")
                      }
                      else
                      {
                        setValues({...values,[words[0]+'_'+'view']: event.target.checked });

                      }

                  
          }
    else
    {
                        if(words[1] !== 'view')
                        {
                          setValues({
                            ...values,
                            [event.target.name]: event.target.checked,
                          });
                        }
      
    }
  };
 



 

  const handleSubmit = () =>{
// Sequence should be All , View , del , add , upd


var flag =  false

var x  = ['dash' , 'om' , 'rm' , 'adp' , 'plan' ,'api' , 'web' , 'inq' , 'tick' ,  'gst' , 'report' , "profile"]
var y = ['all' , 'add' , 'del'  , 'upd' , 'view']


for(let i = 0 ; i < x.length ; i++)
{
  for(let j = 0 ; j < y.length ; j++)
  {
      if(values[x[i]+'_'+y[j]])
      {
        flag = true
      }
  }
}

if(flag === true)
{
  const f  =  {
    role_name : values.role_name,
    dashboard : [
        values.dash_all,   
        values.dash_view,    
        values.dash_del,   
        values.dash_add,   
        values.dash_upd
    ], 
    order_management : [
        values.om_all,   
        values.om_view,    
        values.om_del,   
        values.om_add,   
        values.om_upd
     ] , 
    role_master : [
      values.rm_all,   
      values.rm_view,    
      values.rm_del,   
      values.rm_add,   
      values.rm_upd
     ] , 
    admin_panel_user : [
      values.adp_all,   
      values.adp_view,    
      values.adp_del,   
      values.adp_add,   
      values.adp_upd
     ] , 
    plan_management : [
     values.plan_all,   
      values.plan_view,    
      values.plan_del,   
      values.plan_add,   
      values.plan_upd
    ], 
    api_management : [
      values.api_all,   
      values.api_view,    
      values.api_del,   
      values.api_add,   
       values.api_upd
     ] , 
    web_management : [
      values.web_all,   
      values.web_view,    
      values.web_del,   
      values.web_add,   
     values.web_upd
     ] , 
    inquiry_management : [
      values.inq_all,   
      values.inq_view,    
      values.inq_del,   
      values.inq_add,   
      values.inq_upd
    ], 
    tickets_management : [
      values.tick_all,   
      values.tick_view,    
      values.tick_del,   
      values.tick_add,   
      values.tick_upd
    ], 
    gst_management : [
      values.gst_all,   
      values.gst_view,    
      values.gst_del,   
      values.gst_add,   
      values.gst_upd
     ] , 
    report_generation : [
      values.report_all,   
      values.report_view,    
      values.report_del,   
      values.report_add,   
      values.report_upd
     ] , 
    profile : [
      values.profile_all,   
      values.profile_all,    
     values.profile_all,   
       values.profile_all,   
     values.profile_all
    ]
  }
  if (values.role_name === ""){
    tostE("Please Enter Role Name!!")
  }
  else{
    dispatch(addRoles(f))
    navigate('/app/roles')
  }
  
}
else
{
  tostE("Please Select At least one Permission!!");
}
  }


    

      


// console.log(f)
//       dispatch(addRoles(f))
//     setValues({
//       ...values,
//       role_name : "",

//       dash_all  : false,   
//       dash_view : false,    
//       dash_del  : false,   
//       dash_add  : false,   
//       dash_upd    : false,
     
//       om_all    : false, 
//       om_view    : false, 
//       om_del     : false,
//       om_add     : false,
//       om_upd     : false,
     
      
     
//       rm_all     : false,
//       rm_view     : false,
//       rm_del     : false,
//       rm_add     : false,
//       rm_upd     : false,
     
//       adp_all     : false,
//       adp_view    : false, 
//       adp_del     : false,
//       adp_add     : false,
//       adp_upd     : false,
     
//       plan_all     : false,
//       plan_view    : false, 
//       plan_del     : false,
//       plan_add     : false,
//       plan_upd     : false,
       
//       api_all     : false,
//       api_view     : false,
//       api_del     : false,
//       api_add     : false,
//       api_upd     : false,
     
//       web_all     : false,
//       web_view     : false,
//       web_del     : false,
//       web_add     : false,
//       web_upd     : false,
       
//       inq_all     : false,
//       inq_view     : false,
//       inq_del     : false,
//       inq_add     : false,
//       inq_upd     : false,
       
//       tick_all     : false,
//       tick_view    : false, 
//       tick_del     : false,
//       tick_add     : false,
//       tick_upd     : false,
       
//       gst_all     : false,
//       gst_view    : false, 
//       gst_del     : false,
//       gst_add     : false,
//       gst_upd     : false,
     
//       report_all   : false,  
//       report_view  : false,   
//       report_del    : false, 
//       report_add    : false, 
//       report_upd    : false,
     
//       profile_all   : false,  
//       profile_view   : false,  
//       profile_del    : false, 
//       profile_add    : false, 
//       profile_upd    : false, 
  
     
//     });

//   }




    /********* Image Handling   ************** */

 
    
    
 




  
  // const SData = useSelector((state) =>
  // state.GetState && state.GetState.getdata &&
  //   state.GetState.getdata.length &&
  //   Array.isArray(state.GetState.getdata)
  //     ? state.GetState.getdata
  //     : []
  // );

  



  return (
    <Page
      className={classes.root}
      title="Create Clients"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Create Clients" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
         
             <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Role Name"
                name="role_name"
                onChange={handleChange}
                required
                value={values.role_name}
                variant="outlined" 
              />
            </Grid>
            <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >

            <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Area</StyledTableCell>
            <StyledTableCell align="right">All</StyledTableCell>
            <StyledTableCell align="right"><VisibilityIcon/></StyledTableCell>
            <StyledTableCell align="right"><DeleteIcon/></StyledTableCell>
            <StyledTableCell align="right"><AddBoxIcon/></StyledTableCell>
            <StyledTableCell align="right"><EditIcon/></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>







            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Dashboard</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="dash_all" checked={values.dash_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="dash_view" checked={values.dash_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="dash_del" checked={values.dash_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="dash_add" checked={values.dash_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="dash_upd" checked={values.dash_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Order Management</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="om_all" checked={values.om_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="om_view" checked={values.om_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="om_del" checked={values.om_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="om_add" checked={values.om_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="om_upd" checked={values.om_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>


            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Role Master</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rm_all" checked={values.rm_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rm_view" checked={values.rm_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rm_del" checked={values.rm_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rm_add" checked={values.rm_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rm_upd" checked={values.rm_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Admin Panel</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="adp_all" checked={values.adp_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="adp_view" checked={values.adp_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="adp_del" checked={values.adp_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="adp_add" checked={values.adp_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="adp_upd" checked={values.adp_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Plan Management</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="plan_all" checked={values.plan_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="plan_view" checked={values.plan_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="plan_del" checked={values.plan_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="plan_add" checked={values.plan_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="plan_upd" checked={values.plan_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">API Management</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="api_all" checked={values.api_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="api_view" checked={values.api_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="api_del" checked={values.api_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="api_add" checked={values.api_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="api_upd" checked={values.api_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">WEB Management</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="web_all" checked={values.web_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="web_view" checked={values.web_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="web_del" checked={values.web_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="web_add" checked={values.web_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="web_upd" checked={values.web_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Inquiry Management</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inq_all" checked={values.inq_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inq_view" checked={values.inq_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inq_del" checked={values.inq_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inq_add" checked={values.inq_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inq_upd" checked={values.inq_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Tickets Management</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="tick_all" checked={values.tick_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="tick_view" checked={values.tick_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="tick_del" checked={values.tick_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="tick_add" checked={values.tick_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="tick_upd" checked={values.tick_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">GST Management</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="gst_all" checked={values.gst_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="gst_view" checked={values.gst_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="gst_del" checked={values.gst_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="gst_add" checked={values.gst_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="gst_upd" checked={values.gst_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>


            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Report Generation</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="report_all" checked={values.report_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="report_view" checked={values.report_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="report_del" checked={values.report_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="report_add" checked={values.report_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="report_upd" checked={values.report_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Profile</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_all" checked={values.profile_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_view" checked={values.profile_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_del" checked={values.profile_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_add" checked={values.profile_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_upd" checked={values.profile_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

        </TableBody>
      </Table>
    </TableContainer>
          
           </Grid> 
       

      
           {/* createData('Dashboard', <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Order Management',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Role Master',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Admin Panel User',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Plan Management',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('API Management',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Web Management',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Iquiry Management',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Tickets Management',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('GST Module',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Report Generation',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
  createData('Profile',<Cb/>, <Cb/>, <Cb/>, <Cb/>, <Cb/>,<Cb/>),
             */}
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
