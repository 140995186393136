// import axios from "../Config/Interceptor1";


import axios from '../../Config/Interceptor2'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addStockBegin , addStockSuccess , addStockFailure} from './ActionCreators'
import {getStockBegin , getStockSuccess , getStockFailure} from './ActionCreators'
import {delStockBegin , delStockSuccess , delStockFailure} from './ActionCreators'
import {editStockBegin , editStockSuccess , editStockFailure} from './ActionCreators';


export const addStock = (session) => {

    return (dispatch) => {
        dispatch(addStockBegin());

        axios.post(Base_URL + '/inventory/addStock', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addStockSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addStockFailure(err.response.data));

                }
            })
    }
}


export const getStock = (x) => {

    return (dispatch) => {
        dispatch(getStockBegin());

        axios.get(Base_URL + '/inventory/getStock' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getStockSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getStockFailure(err.response.data));

                }
            })
    }
}

export const delStock = (x) => {

    return (dispatch) => {
        dispatch(delStockBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delStock' , {params :{site_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delStockSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delStockFailure(err.response.data));

                }
            })
    }
}

export const editStock = (session) => {

    return (dispatch) => {
        dispatch(editStockBegin());

        axios.post(Base_URL + '/subAdmin/editStock', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editStockSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editStockFailure(err.response.data));

                }
            })
    }
}

