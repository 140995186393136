import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useLocation , useNavigate} from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {editSuperProfileDetail, getSuperProfileDetail, editSuperProfilePassword, editSuperProfilePic} from '../../../Actions/SuperAdminActions/Profile'
import { cleanSuperProfileDetail } from '../../../Actions/ActionCreators';



const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Profile Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const navigate = useNavigate();
  

  const login_data = useSelector(state => state.login && state.login.data ? state.login.data : {});
  const id = useSelector(state => state.login.data.id);
  const detail = useSelector(state => state.SuperProfile && state.SuperProfile.getData && Array.isArray(state.SuperProfile.getData) && state.SuperProfile.getData.length ? state.SuperProfile.getData : []);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
    id :id,
    name:detail[0].name !=="" && detail[0].name !==null && detail[0].name !==undefined ? detail[0].name : login_data.name,
    email:detail[0].email !=="" && detail[0].email !==null && detail[0].email !==undefined ? detail[0].email : login_data.email,
    mobile:detail[0].mobile !=="" && detail[0].mobile !==null && detail[0].mobile !==undefined ? detail[0].mobile : login_data.mobile,
    address:detail[0].address !=="" && detail[0].address !==null && detail[0].address !==undefined ? detail[0].address : login_data.address,
    permit_role:detail[0].permit_role !=="" && detail[0].permit_role !==null && detail[0].permit_role !==undefined ? detail[0].permit_role : login_data.permit_role,
    password:"",
    confirm_password:"",
    flag:false,
    image1:"",
    tempImage1:detail[0].image,
    fixImg : "/static/images/avatars/upload.png"

  });


  const handleChangeDetail = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  

  const handleSubmitPass = () =>{
    // console.log(values)
    // dispatch(addBusiness(values))
    if(values.password.length < 6){
      tostE("Minimum Password Length is Six")
    }else{

      if(values.password === "" || values.password === null || values.password === undefined)
      {
        tostE("Please Enter Password")
      }
      else if(values.confirm_password === "" || values.confirm_password === null || values.confirm_password === undefined)
      {
        tostE("Please Enter Confirm Password")
  
      }
      else if(values.password !== values.confirm_password )
      {
          tostE("Password Didn't Match !!")
      }else{
        dispatch(editSuperProfilePassword(values));
        setValues({
          ...values,
          ['password']:"",
          ['confirm_password']:"",
      
        });
      }    
    }
  }

  // console.log(values)


  
  const emailRegex = /\S+@\S+\.\S+/;
  var nameRegex = /([a-zA-Z]{1,}\s?[a-zA-Z]+)+/;
  const mobileRegex = /^[6-9][0-9]{9}$/;



  const handleSubmitDetail = () =>{
    // console.log(values)
    var confirmation = window.confirm("Are You Really Want To Update Your Profile");

    if (confirmation)
    {
      if(values.name==="" || values.name===null || values.name===undefined){
        tostE("Please Enter Name")
      }else if(values.email==="" || values.email===null || values.email===undefined){
        tostE("Please Enter Email")
      }else if(values.mobile==="" || values.mobile===null || values.mobile===undefined){
        tostE("Please Enter Mobile")
      }else{
        if (!emailRegex.test(values.email))
        {
          tostE("Please Enter Valid Email");
        }
        else if (!nameRegex.test(values.name))
        {
          tostE("Name Must be Character")
        }
        else if (!mobileRegex.test(values.mobile))
        {
          tostE("Please Enter Valid Mobile Number")
        }else{
          const sendData = {
            id:id,
            name:values.name !=="" ? values.name : detail[0].name,
            email:values.email !=="" ? values.email : detail[0].email,
            mobile:values.mobile !=="" ? values.mobile : detail[0].mobile,
            permit_role:values.permit_role !=="" ? values.permit_role : detail[0].permit_role,
            address:values.address !=="" ? values.address : detail[0].address
          }
          console.log(sendData)
          dispatch(editSuperProfileDetail(sendData))
        }
      }
     
    }
    else{
      setValues({
        ...values,
        ['name']:detail[0].name,
        ['email']:detail[0].email,
        ['mobile'] : detail[0].mobile,
        ['address'] : detail[0].address,
      })
    }
  
  

}


  const addmsg = useSelector(state => state.SuperProfile.editData && state.SuperProfile.editData.message ? state.SuperProfile.editData.message : null  )
  const adderror = useSelector(state => state.SuperProfile.editError && state.SuperProfile.editError.message ? state.SuperProfile.editError.message : null  )


  useEffect( () => {
    
    dispatch(getSuperProfileDetail(id))
    
    var x  = new Promise((resolve ,reject)=>{
      if(addmsg !== null && typeof(addmsg) === 'string')
      {
        tostS("Updated Successfully");
      }
      if(adderror !== null && typeof(adderror) === 'string')
      {
        tostE(adderror);
      }

      resolve()

    })
    x.then(()=>{

    dispatch(getSuperProfileDetail(id))
    dispatch(cleanSuperProfileDetail())
   

    })

  
     
    }, [addmsg , adderror])


  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);

  
  const handleClick = event => {
    hiddenFileInput.current.click();
    
  };

  const handleChangeimg = event => {

    const fileUploaded = event.target.files[0];
    if(event.target.files[0] && event.target.files.length !==0){
      const formData = new FormData();
      formData.append('id',values.id);
      formData.append('img',fileUploaded);
      dispatch(editSuperProfilePic(formData));
      
     
    }
   
  };
  
  /********* Image Handling   ************** */

  return (
    <Page
      className={classes.root}
      title="Profile"
    >
              <ToastContainer    />

              <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            {/* <Profile /> */}
            <Card
      className={clsx(classes.root2, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={values.tempImage1 !== '' ?  detail[0].image : values.fixImg }
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>

          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeimg}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClick}
        >
         {/* { values.flag===false ? 'Upload Picture':'Update Picture'} */}
         Upload Picture
        </Button>
      </CardActions>
    </Card>
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Profile Management" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChangeDetail}
                required
                defaultValue={detail[0].name}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChangeDetail}
                required
                value={values.email !== "" ? values.email : detail[0].email}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone/Moblie"
                name="mobile"
                type="number"
                onChange={handleChangeDetail}
                required
                value={values.mobile !== "" ? values.mobile : detail[0].mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                onChange={handleChangeDetail}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>

          <Button onClick={handleSubmitDetail} color="primary" variant="contained">
            Update
          </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              
            </Grid>


            <Grid item md={6} xs={12}>
            <CardHeader subheader="" title="Change Authentication" />
             
            </Grid>

            <Grid item md={6} xs={12}>
             
            </Grid>


            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="New Password"
                name="password"
                onChange={handleChangeDetail}
                required
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirm_password"
                onChange={handleChangeDetail}
                required
                value={values.confirm_password}
                variant="outlined"
              />
            </Grid>
           



            
            
            
           

      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-start" p={2}>
          <Button onClick={handleSubmitPass} color="primary" variant="contained">
            Change
          </Button>
        </Box>
      </Card>
    </form>
            
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
