import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  CardHeader,
  Button
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import useFileDownloader from "../../../Hooks/useFileDownloader";
import Header from '../../../components/DownloadHeader';
import BackupDownloadIcon from '@material-ui/icons/CloudDownload';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "default",
    height: 100,
    width: 100
  },
  differenceIcon: {
    color: "default"
  },
  differenceValue: {
    color: "default",
    marginRight: theme.spacing(1)
  },
  DashCard:{
    border:"1px solid #E36A26",
    background:"#FFEAE0",
    padding:"5px"
  }
}));

const Budget = ({ className, ...rest }) => {
  const classes = useStyles();
//   const [downloadFile, downloaderComponentUI] = useFileDownloader();

  const DashBoardData = useSelector((state) =>
  state.DashBoard &&
  state.DashBoard.SubData &&
  Object.keys(state.DashBoard.SubData) &&
  Object.keys(state.DashBoard.SubData).length 
  ? state.DashBoard.SubData.users
  : 0
  );

   const downloadBackup = () => {
      
   }

  return (
     <>
    <Card className={classes.DashCard}>
      <CardContent>
        <Grid container spacing={3}>
         <Typography>Generate Backup</Typography>
         <Button variant='outlined' onClick={() => downloadBackup()} style={{marginTop:"5px"}} >
            Download Backup
         </Button>
                  
        </Grid>        
      </CardContent>
    </Card>
    
    </>
  );
};

Budget.propTypes = {
  className: PropTypes.string
};

export default Budget;
