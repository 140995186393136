// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor3";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {getSuperEnqBegin , getSuperEnqSuccess , getSuperEnqFailure } from './../ActionCreators'






export const getEnq = () => {

    return (dispatch) => {
        dispatch(getSuperEnqBegin());

        axios.get(Base_URL + '/superadmin/getsubenq')
            .then((res) => {
                dispatch(getSuperEnqSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSuperEnqFailure(err.response.data));

                }
            })
    }
}











