import {GET_QUALITY_SAFETY_LOADING , GET_QUALITY_SAFETY_SUCCESS , GET_QUALITY_SAFETY_FAILURE, CLEAN_QUALITY_SAFETY_DATA} from '../../Actions/Types';
import {DEL_QUALITY_SAFETY_LOADING , DEL_QUALITY_SAFETY_SUCCESS , DEL_QUALITY_SAFETY_FAILURE} from '../../Actions/Types';


import {CLEAN_ALL_DATA} from '../../Actions/Types'

const QualitySafety = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getData:[{}],
            getError:{},
            delData:[{}],
            delError:{}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,                
                loading:false,                
                getData:[{}],
                getError:{},
                delData:[{}],
                delError:{}
            }
    
    
        case CLEAN_QUALITY_SAFETY_DATA:
            return {
                ...state,                
                loading:false,                
                getData : [{}],
                getError:{},
                delData:[{}],
                delError:{}
            }
    
        case GET_QUALITY_SAFETY_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_QUALITY_SAFETY_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
                
            }
        case GET_QUALITY_SAFETY_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                
            }   
        
        case DEL_QUALITY_SAFETY_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}],
                
            }
        case DEL_QUALITY_SAFETY_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
                
            }
        case DEL_QUALITY_SAFETY_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
                
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default QualitySafety;
    