import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { tostS, tostE } from "../../../Config/Toast";
import {
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@material-ui/core";
import Page from "../../../../src/components/Page";
import { useSelector, useDispatch } from "react-redux";
import { getSites } from "../../../Actions/SubAdminActions/M_AddSites";
import { addSiteBoq } from "../../../Actions/SubAdminActions/BOQ"; // Assuming you have an action to add BOQ
import * as XLSX from "xlsx";

const CreateBoq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useSelector((state) => state.login.data.r_subadmin);
  const selected_site = useSelector((state) =>
    state.SelectedSite &&
    state.SelectedSite.sitedata &&
    state.SelectedSite.sitedata.site
      ? state.SelectedSite.sitedata.site
      : null
  );

  const [values, setValues] = useState({
    sub_admin_id: id,
    site_id: selected_site !== null ? selected_site : "",
  });

  const [file, setFile] = useState(null);
  const [percentageBoqValue, setPercentageBoqValue] = useState("");
  const [percentageBoqType, setPercentageBoqType] = useState("");

  const handleChangeSiteName = (event) => {
    setValues({ ...values, site_id: event.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!file) {
      tostE("No files uploaded");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet);

      // Send the extracted data to the backend
      dispatch(addSiteBoq(values.site_id, json, values.sub_admin_id, navigate, percentageBoqType, percentageBoqValue));
    };

    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    dispatch(getSites(id));
  }, [dispatch, id]);

  const SiteData = useSelector((state) =>
    state.AddSites &&
    state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );

  const handlePercentageBoqTypeChange = (event) => {
    setPercentageBoqType(event.target.value);
  };

  const handlePercentageBoqValueChange = (event) => {
    setPercentageBoqValue(event.target.value);
  };

  return (
    <Page title="Add BOQ">
      <ToastContainer />
      <Container maxWidth="lg" style={{ marginTop: "50px" }}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <form autoComplete="off" noValidate>
              <Card>
                <CardHeader subheader="" title="Add BOQ" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="site-select-label">
                          Site Name
                        </InputLabel>
                        <Select
                          labelId="site-select-label"
                          id="site-select"
                          onChange={handleChangeSiteName}
                          label="Site Name"
                          value={values.site_id}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {SiteData.map((data, i) => (
                            <MenuItem key={i} value={data.site_id}>
                              {data.site_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <CardActions>
                      <FormControl variant="outlined" style={{marginLeft:"70px", padding:"20px"}}>
                        <input
                          type="file"
                          onChange={handleFileChange}
                          accept=".xlsx, .xls"
                        />
                        {/* <Button
                          color="primary"
                          fullWidth
                          variant="text"
                          onClick={handleSubmit}
                        >
                          Upload Excel
                        </Button> */}
                      </FormControl>
                    </CardActions>
                  </Grid>
                  <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="percentage-boq-select-label">
                        Percentage BOQ
                      </InputLabel>
                      <Select
                        labelId="percentage-boq-select-label"
                        id="percentage-boq-select"
                        onChange={handlePercentageBoqTypeChange}
                        label="Percentage BOQ"
                        value={percentageBoqType}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="positive">Positive</MenuItem>
                        <MenuItem value="negative">Negative</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {percentageBoqType && (
                    <Grid style={{ paddingTop: "10px" }} item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Percentage Value"
                        name="percentageBoqValue"
                        onChange={handlePercentageBoqValueChange}
                        type="number"
                        value={percentageBoqValue}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateBoq;
