// import { addResourceQuality } from '../../Actions/SubAdminActions/M_ResourceQuality';
import {ADD_OTHER_SUB_ADMIN_LOADING , ADD_OTHER_SUB_ADMIN_SUCCESS , ADD_OTHER_SUB_ADMIN_FAILURE , CLEAN_OTHER_SUB_ADMIN_DATA} from '../../Actions/Types';
import {GET_OTHER_SUB_ADMIN_LOADING , GET_OTHER_SUB_ADMIN_SUCCESS , GET_OTHER_SUB_ADMIN_FAILURE} from '../../Actions/Types';
import {DEL_OTHER_SUB_ADMIN_LOADING , DEL_OTHER_SUB_ADMIN_SUCCESS , DEL_OTHER_SUB_ADMIN_FAILURE} from '../../Actions/Types';
import {EDIT_OTHER_SUB_ADMIN_LOADING , EDIT_OTHER_SUB_ADMIN_SUCCESS , EDIT_OTHER_SUB_ADMIN_FAILURE} from '../../Actions/Types';
import {BLOCK_OTHER_SUB_ADMIN_LOADING , BLOCK_OTHER_SUB_ADMIN_SUCCESS , BLOCK_OTHER_SUB_ADMIN_FAILURE} from '../../Actions/Types';


const AddOtherSubAdmin = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData :{},
            editError : {},
            delData  : [{}] ,
            delError: {},
            blockData  : [{}] ,
            blockError: {}
        }
    }

    switch(action.type) {
        // case CLEAN_ALL_DATA:
        //     return {
        //         ...state,
        //         data: [{}],
        //         loading:false,
        //         error:{},
        //         getData:[{}],
        //         getError:{}
                
        //     }
    
    
        case CLEAN_OTHER_SUB_ADMIN_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                // getData : [{}],
                editData : {},
                editError : {},
                delData : [{}], 
                delError:{},
                blockData  : [{}] ,
                blockError: {}
            }
    
        
        case ADD_OTHER_SUB_ADMIN_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                delData : [{}]
                
            }
        case ADD_OTHER_SUB_ADMIN_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
                delData : [{}]
            }
        case ADD_OTHER_SUB_ADMIN_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
                delData:[{}]
            }

        case GET_OTHER_SUB_ADMIN_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                delData : [{}],
                
            }
        case GET_OTHER_SUB_ADMIN_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data,
                delData:[{}]
            }
        case GET_OTHER_SUB_ADMIN_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                delData : [{}]
            }   
        
        case DEL_OTHER_SUB_ADMIN_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_OTHER_SUB_ADMIN_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_OTHER_SUB_ADMIN_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
       
       
        case BLOCK_OTHER_SUB_ADMIN_LOADING:
            return {
                ...state,
                loading:true,
                blockError: {},
                blockData : [{}]
                
            }
        case BLOCK_OTHER_SUB_ADMIN_SUCCESS:
            return {
                ...state,
                loading:false,
                blockError:{},
                blockData:action.data,
            }
        case BLOCK_OTHER_SUB_ADMIN_FAILURE:
            return {
                ...state,
                loading:false,
                blockError:action.error,
                blockData:{},
            }
                    
        case EDIT_OTHER_SUB_ADMIN_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : {}
                
            }
        case EDIT_OTHER_SUB_ADMIN_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_OTHER_SUB_ADMIN_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            

        default:
        return state
    
    }
    }
    
    
    
export default AddOtherSubAdmin;
    