import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {getRoles} from '../../../Actions/SuperAdminActions/AddRoles'

import {addPanelUser} from '../../../Actions/SuperAdminActions/PanelUser'
import {cleanPanelUserData} from '../../../Actions/ActionCreators'


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()



  const handleChangef = (event) => {
    setValues({...values, ['permit_role']:event.target.value});
  };



  const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
  admin_id :id,
    name :"",
    mobile :"" ,
    email : "" ,
    password :"" , 
    permit_role:"",
    showPassword: false,
  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  const handleSubmit = () =>{
    var mobileReg = /^[6-9]{1}[0-9]{9}$/;
    var nameReg = /([a-zA-Z]{1,}\s?[a-zA-Z]+)+/;
    console.log("++++++++++++++" , values.mobile);
    console.log(mobileReg.test(values.mobile));
    console.log("+++++++++++++++");
    console.log(nameReg.test(values.name));

    if(mobileReg.test(values.mobile)!=true){
      tostW("Please Enter a Valid Mobile Number!!");

    }else if(nameReg.test(values.name)!=true){
      tostW("Please Enter a Valid Name!!")

    }else{

      if (values.name !== "" &&values.name !== null && values.name !== undefined){
        if (values.mobile !== "" &&values.mobile !== null && values.mobile !== undefined){
          if (values.email !== "" &&values.email !== null && values.email !== undefined){
            if (values.password !== "" &&values.password !== null && values.password !== undefined){
              if (values.permit_role !== "" &&values.permit_role !== null && values.permit_role !== undefined){
                dispatch(addPanelUser(values))
                setValues({
                  ...values,['name'] : "" , ['mobile'] : "" , ['email'] : "" , ['password'] : ""
                })
              }else{
                tostE("Please Select role!!")
              }
            }else{
              tostE("Please Enter Password !!")
            }
          }else{
            tostE("Please Enter Email !!")
          }
        }else{
          tostE("Please Enter Mobile number!!")
        }  
      }else{
        tostE("Please Enter Name!!")
      }
    }
    
    
  }




   



  const RData = useSelector((state) =>
  state.AddRoles && state.AddRoles.getData &&
    state.AddRoles.getData.length &&
    Array.isArray(state.AddRoles.getData)
      ? state.AddRoles.getData
      : []
  );


  
  



const addmsg = useSelector((state) => state.AddPanelUser.data && state.AddPanelUser.data.id ? state.AddPanelUser.data.id : null  )
 



  

  useEffect( () => {
    dispatch(getRoles())
    var r  = new Promise((resolve , reject)=>{

      if(addmsg !== null && addmsg !== '' && addmsg !== undefined)
      {
        tostS("SuperAdmin Added Successfully")
      }
      resolve()
     }) 
     r.then(()=>{
      dispatch(cleanPanelUserData())
     })
  }, [addmsg])
  
  






  
  
  /********* Image Handling   ************** */

  return (
    <Page
      className={classes.root}
      title="Admin Clients"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
         
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Admin Clients" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
         
             <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Mobile"
                name="mobile"
                onChange={handleChange}
                required
                type="number"  InputProps={{inputProps:{min:1}}}
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                onChange={handleChange}
                required
                value={values.password}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.permit_role}
          onChange={handleChangef}
          label="Role"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          
           {RData.map((data , i)=>
          <MenuItem key={i}  value={data.role_id}>{data.role_name}</MenuItem>
        )}       

        </Select>
      </FormControl>
      </Grid>

          
            
       

      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
