
import {ADD_STOCK_LOADING , ADD_STOCK_SUCCESS , ADD_STOCK_FAILURE , CLEAN_STOCK_DATA} from '../../Actions/Inventory/Types';
import {GET_STOCK_LOADING , GET_STOCK_SUCCESS , GET_STOCK_FAILURE} from '../../Actions/Inventory/Types';
import {DEL_STOCK_LOADING , DEL_STOCK_SUCCESS , DEL_STOCK_FAILURE} from '../../Actions/Inventory/Types';
import {EDIT_STOCK_LOADING , EDIT_STOCK_SUCCESS , EDIT_STOCK_FAILURE} from '../../Actions/Inventory/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const Stock = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:{},
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{}
                
            }
    
    
        case CLEAN_STOCK_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},                
                editData : [{}],
                editError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_STOCK_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                
            }
        case ADD_STOCK_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
            }
        case ADD_STOCK_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
            }

        case GET_STOCK_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                getData : {},
                
            }
        case GET_STOCK_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data,
            }
        case GET_STOCK_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        
        case DEL_STOCK_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_STOCK_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_STOCK_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
                    
        case EDIT_STOCK_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}]
                
            }
        case EDIT_STOCK_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_STOCK_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            

        default:
        return state
    
    }
    }
    
    
    
export default Stock;
    