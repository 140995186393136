import React from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
// import logo from '/static/images/logo.png'
import moment from 'moment'
const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor:"#E36A26",
        alignItems: 'center',    
        marginTop:"10px"
    },
    reportTitle:{
        color: '#ffffff',       
        fontSize: 20,
        textTransform:"capitalize",
        fontFamily: 'Helvetica',
    },
    headerContainer:{
        flexDirection: 'row',                  
        width:"100%",
        // padding:"2px",
        fontSize:12
    },
    clientContainer:{
        flexDirection: 'column',        
        marginLeft:"5%",
        width:"40%"
    },
    clientTitle:{
        fontSize:24,
        fontFamily: 'Helvetica',
        color:"#70011b"
    },
    clientContent:{
                
        color:"#000000"
    },
    dateContainer:{
        flexDirection: 'column',                                       
        alignItem:"right",
        textAlign:"right",
        width:"40%"
    },
    
    dateStyle:{    
        fontSize:24,
        fontFamily: 'Helvetica',            
        color:"#70011b"
    },
    
    dateContent:{                
        color:"#000000"
    },
    
    logo: {
        width: 74,
        height: 66,
       
    }
  });


  const InvoiceTitle = ({invoice, title}) => (
    <>
    <View style={styles.headerContainer}>
        <Image style={styles.logo} src="/static/images/logo.png" />  
        <View style={styles.clientContainer}>
            <Text style={styles.clientTitle}>{invoice.client_name}</Text>
            <Text style={styles.clientContent}>{invoice.client_address}</Text>
        </View>      
        <View style={styles.dateContainer}>
            <Text style={styles.dateStyle}>{moment(invoice.delivery_date).format("MMMM DD,YYYY")}</Text>
            <Text style={styles.dateContent}>Purchase Order Date</Text>
        </View>      
    </View>
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title} # {invoice.order_id}</Text>
    </View>
    </>
  );
  
  export default InvoiceTitle