import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

import {cleanMachineDeliveryData, MacRepoStartDate, MacRepoEndDate} from "../../../../Actions/ActionCreators";
import {getMachineDelivery, delMachineDelivery} from "../../../../Actions/SubAdminActions/Machine_delivery"

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const tableRef = React.createRef();
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.site_report ? state.login.data.role_permit.site_report : []);

  var id = useSelector(state => state.login.data.r_subadmin);
  
  const [values, setValues] = useState({
    data:"",
    from_date: "",
  })
 

  const [open, setOpen] = useState({show:false, id:""});


  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.rm_id
    });
  
  }
  
  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delMachineDelivery(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  
  const start_date = useSelector(state => state.SubDateFilters && state.SubDateFilters.MacRepoStartDate ? state.SubDateFilters.MacRepoStartDate:new Date(Date.now()))
  const end_date = useSelector(state => state.SubDateFilters && state.SubDateFilters.MacRepoEndDate ? state.SubDateFilters.MacRepoEndDate:new Date(Date.now()))
    

  const  filterData = (Wdata) => {
    
    var Fdata = []
    if(Array.isArray(Wdata) && Wdata.length){

      Fdata = Wdata.filter(data => (!selected_site || data.site_id==selected_site));
      return Fdata
    }else{

      return Fdata
    }
    
  
  } 
  

  const listData = useSelector((state) =>
  state.MachineDelivery && state.MachineDelivery.getData.data &&
    state.MachineDelivery.getData.data.length &&
    Array.isArray(state.MachineDelivery.getData.data)
      ? filterData(state.MachineDelivery.getData.data)
      : []
  );



  const countD= useSelector((state) =>
  state.MachineDelivery && state.MachineDelivery.getData.data &&
   Array.isArray(state.MachineDelivery.getData.data)
      ? parseInt(state.MachineDelivery.getData.total_length)
      : 0
  );



  const countF= useSelector((state) =>
  state.MachineDelivery && state.MachineDelivery.getData.data &&
   Array.isArray(state.MachineDelivery.getData.data)
      ? parseInt(state.MachineDelivery.getData.page)
      : 0
  );
  
  const [pagination , setPagination] = useState({
    per_page : 5,
    page : countF,

  })
  
 
 
  const handleStartDateChange = (date) => {
    const parameter = {sub_admin_id : id,page : pagination.page , per_page :pagination.per_page ,  start_date : date , end_date : moment( end_date ).add(1,'d')._d}

   
   dispatch(MacRepoStartDate(date))
   
   
   dispatch(getMachineDelivery(parameter))
   setValues({
    ...values,
    ['from_date']:date
  })
   };
    
   const handleEndDateChange = (date) => {
    const parameter = {sub_admin_id : id,page : pagination.page , per_page :pagination.per_page ,  start_date : values.from_date , end_date : moment( date ).add(1,'d')._d}

  
   dispatch(MacRepoEndDate(date))
     
   dispatch(getMachineDelivery(parameter))

   };
     
  
 
  const Delmsg = useSelector(state => state.MachineDelivery && state.MachineDelivery.delData && state.MachineDelivery.delData.message ? state.MachineDelivery.delData.message : null  )
  const Delerror = useSelector(state => state.MachineDelivery && state.MachineDelivery.delError && state.MachineDelivery.delError.message ? state.MachineDelivery.delError.message : null  )


  
useEffect( () => {
  var t  =  {sub_admin_id : id,page : pagination.page , per_page : pagination.per_page,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}

  dispatch(getMachineDelivery(t))

    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanMachineDeliveryData())
    dispatch(getMachineDelivery(t))

    })


    
  }, [ Delmsg  ,Delerror ])


  const navigate = useNavigate();

const onView = (olData) =>{
  navigate('/app/sitemaster_machine/detail/'+olData.rm_id , { state: olData })
}


// const onDel = (oldata) =>{

//   dispatch(delMachineDelivery(oldata.rm_id ,oldata.tableData.id ))
// }
 



const exportField = [
  
  {
    field : "site_name" , 
    val : "Site Name"
  },
  {
    field : "site_address" , 
    val : "Site Address"
  },
  {
    field : "budget" , 
    val : "Budget"
  },
  {
    field : "created_on" , 
    val : "Created On"
  },
  {
    field : "end_date" , 
    val : "End Date"
  }, 
  {
    field : "priority" , 
    val : "Priority"
  }, 
  {
    field : "status" , 
    val : "Status"
  },
]



  

const handlePageChange = (e , x) =>{
  const parameter = {sub_admin_id : id,page : e , per_page :pagination.per_page ,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}
  var u   = new Promise((resolve ,reject)=>{
    
    if(x !== null  && x !== undefined )
    {
      dispatch(getMachineDelivery(parameter))
      
    }
    
    resolve()
    
  })
  u.then(()=>{
    
    setPagination({...pagination , ['page']  : e})
  })

}

const handleRPP = (e) =>{
  console.log(e)
  // setPagination({...pagination , ['per_page']  : e})
  const parameter = {sub_admin_id : id,page : e , per_page :pagination.per_page ,  start_date : values.from_date , end_date : moment( end_date ).add(1,'d')._d}

  var u   = new Promise((resolve ,reject)=>{
    
   
      dispatch(getMachineDelivery(parameter))
      
    
    
    resolve()
    
  })
  u.then(()=>{
    
    // setPagination({...pagination , ['page']  : e})
    setPagination({...pagination , ['per_page']  : e})
  })

}


  const addNewBornbutton = () =>{
    // navigate('/app/materialindent')
    console.log("prchase order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Machine">
      <ToastContainer    />


      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Machine Report ??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>




        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
          <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Machine Report')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Machine Report')
              }>
                Export to PDF
            </Button>
          </Box> 
          <div className={classes.root3}>
            <Grid container spacing={3}>
              <Grid item  md={6} lg={3}  xs={3}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth                                        
                      id="date-picker-dialog"
                      label="From Date"
                      InputLabelProps={{shrink:true}}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      required={false}
                      value={values.from_date}
                      maxDate={end_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={handleStartDateChange}
                      inputVariant="outlined"
                      variant="outlined"
                      // borderColor="red"
                      invalidDateMessage=""
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
              <Grid item  md={6} lg={3}  xs={3}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      style={{ border: "1px  black" }}
                      id="date-picker-dialog"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={start_date}
                      value={end_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={handleEndDateChange}
                      inputVariant="outlined"
                      variant="outlined"
                      borderColor="red"
                      
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
            

            </Grid>
          </div>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      tableRef={tableRef}

      title="Machine Report"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),\
        // 
        { title: 'Site Name', field: 'site_name',editable: "never", },     
        { title: 'Site Address', field: 'site_address',editable: "never", },
        { title: 'Delivered to', field: 'accepted_by',editable: "never", },     
        { title: 'Received Date', render:rowData => (moment(rowData.date).format("DD.MM.YYYY")) },     
        { title: 'Approved By', field: 'approved_by',editable: "never", },  
        { title: 'Priority', field: 'priority',editable: "never", },     
        { title: 'Status', 
            render: rowData => (
             rowData.status==="Delivered" ? 
             <>
             <Typography style={{color:"green"}} variant='overline'>Delivered</Typography>
             </>             
             :
             <>
             <Typography style={{color:"#E36A26"}} variant='overline'>Pending</Typography>
             </>
             
          
            )
        },   
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[2] ?
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
              
              <Grid item xs={4}>
                <VisibilityIcon onClick={()=>{onView(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
            </Grid>
          ),
        },

      ]}
      data={listData}
      options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                emptyRowsWhenPaging:false,  

              }}
      components={{
        Pagination: props => (
                     <TablePagination
                     {...props}
                   
                count={countD}
                page={countF}
               
              />
            ),
                  }}

      onChangeRowsPerPage={(e)=>handleRPP(e)}
      onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}              


    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
