import 'react-perfect-scrollbar/dist/css/styles.css';
import React , {useState} from 'react';


import {Provider , connect} from 'react-redux'
import { useRoutes , Route , BrowserRouter , Routes ,  } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from '../src/components/GlobalStyles';
import '../src/mixins/chartjs';
import theme from '../src/theme';
import routes from './Routes/routes';
import routes2 from './Routes/routes1';

import SuperAdminRoutes from './Routes/SuperAdminRoutes/SuperAdminRoutes'
import SubAdminRoutes from './Routes/SubAdminRoutes/SubAdminRoutes'
import UserRoutes from './Routes/UserRoutes/UserRoutes'
import history from './Config/history';


import PropTypes from 'prop-types'

import store from './Store/Store';
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

import UPS from './Load'


const appAuth = true;
















const App = () => {
 

  store.subscribe(() => {
    localStorage.setItem('reduxStore' , JSON.stringify(store.getState()));
    // console.log(store.getState().login.data.token)
    // console.log(store.getState().login.data.mobile)
  })
  
  const routing = useRoutes(routes);
  // const routing2 = useRoutes(routes2);
  const SuperAdminRouting = useRoutes(SuperAdminRoutes);
  // const SubAdminRouting = useRoutes(SubAdminRoutes);
  const UserRouting = useRoutes(UserRoutes);




//   {!store.getState().login.data.mobile ?
//     routing
//     :
//     routing2
// }  




  return (

    <Provider store={store} history={history}>
    <ThemeProvider theme={theme}>
      <UPS/>
  
      <GlobalStyles />
      {store.getState().login.data.mobile && store.getState().login.data.role === "Superadmin"  ?

      SuperAdminRouting
      :
      store.getState().login.data.mobile && store.getState().login.data.role === "Subadmin"  ?
      <SubAdminRoutes />
      :
      store.getState().login.data.mobile && store.getState().login.data.role === "Client" ?
      UserRouting

      :
      routing
  }

     



    </ThemeProvider>
    </Provider>   
  );

};



App.propTypes = {
  auth: PropTypes.object.isRequired
};


const mapStateToProps = state => ({
  auth: state.setuser.data.name 
});

export default App;
