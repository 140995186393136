import axios from "../../Config/Interceptor1";


// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {siteExpBegin , siteExpSuccess , siteExpFailure} from '../ActionCreators'


export const siteExp = (x) => {
   
    return (dispatch) => {
        dispatch(siteExpBegin());

        axios.get(Base_URL + '/client/getSiteExpense' , {params : x})
            .then((res) => {
                dispatch(siteExpSuccess(res.data));
                console.log("data======", res.data)
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")
                    dispatch(siteExpFailure({}));

                }
                else {
                    dispatch(siteExpFailure(err.response.data));

                }
            })
    }
}


