import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {getDrawType, addDrawType, delDrawType,editDrawType} from '../../../../Actions/SubAdminActions/M_DrawType'
import {cleanDrawType} from '../../../../Actions/ActionCreators'
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)
  const [open3, setOpen3] = useState({show:false, id:""});

  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);

  const handleClose3 = () =>{

    setOpen3({...open3, show:false, id:""});
  }
  
  const handleOpen3 = (data) =>{
  
    setOpen3({
      ...open3,
      show:true,
      id:data.sub_draw_type_id
    });
  
  }
  
  const handleConfirm3 = () =>{
    if(open3.id!==""){
      
      dispatch(delDrawType(open3.id))

  
      setOpen3({
        ...open3,
        show:false,
        id:""
      })
    }
  }


  const listData = useSelector((state) =>
  state.SubDrawType && state.SubDrawType.getData &&
    state.SubDrawType.getData.length &&
    Array.isArray(state.SubDrawType.getData)
      ? state.SubDrawType.getData
      : []
  );  
  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);

  const sub_aid = useSelector(state => state.login.data.r_subadmin);

  const [values, setValues] = useState({
    sub_admin_id:sub_aid,
    draw_type_name:"",
  
  
    });
  
    const Delmsg = useSelector(state => state.SubDrawType && state.SubDrawType.delData && state.SubDrawType.delData.message ? state.SubDrawType.delData.message : null  )
    const Delerror = useSelector(state => state.SubDrawType && state.SubDrawType.delError && state.SubDrawType.delError.message ? state.SubDrawType.delError.message : null  )
    const addmsg = useSelector(state => state.SubDrawType && state.SubDrawType.data && state.SubDrawType.data.message ? state.SubDrawType.data.message : null  )
    const adderr = useSelector(state => state.SubDrawType && state.SubDrawType.error && state.SubDrawType.error.message ? state.SubDrawType.error.message : null  )
    const editmsg = useSelector(state => state.SubDrawType && state.SubDrawType.editData && state.SubDrawType.editData.message ? state.SubDrawType.editData.message : null )
    
useEffect( () => {

  // dispatch(getBusiness())


  let x  = new Promise((resolve ,reject)=>{
    if(addmsg !== null && typeof(addmsg) === 'string'){
      tostS(addmsg)
     }
  
    else
    if(adderr !== null){
      tostE(adderr)
     }
    else
    if(Delmsg && typeof(Delmsg) === 'string')
    {
      tostS(Delmsg)
    }
    else
    if (editmsg !== null && typeof(editmsg) === 'string')
    {
      tostS(editmsg);
    }
    else
    if(Delerror && typeof(Delerror) === 'string')
    {
        tostE(Delerror)
    }

    resolve()

  })
  x.then(()=>{

  dispatch(cleanDrawType())
  dispatch(getDrawType(sub_aid))

  })


  
}, [ Delmsg  ,Delerror, addmsg, adderr, editmsg ])


const navigate = useNavigate();

const onEdit = (olData) =>{
  setOpen2(true)
  setValues({
    ...values,
    ['draw_type_name']:olData.draw_type_name,
    ['sub_draw_type_id']:olData.sub_draw_type_id
  })
  // navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
}


const onDel = (olData) =>{

  dispatch(delDrawType(olData.sub_draw_type_id, olData.tableData.id))
}

  
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  
  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  
    const handleClickOpen = () => {
      setValues({
        ...values,
        ['draw_type_name']:"",
        ['sub_draw_type_id']:""
      })
      setOpen(true);
  
    };
    const handleClickOpen2 = () => {
      setOpen2(true);
  
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleClose2 = () => {
      setOpen2(false);
      setValues({
        ...values,
        ['draw_type_name']:"",
        ['sub_draw_type_id']:""
      })
  
    };
  
    const handleChange = (event) => {
      //  console.log(event.target.value);
       setValues({
         ...values,
         [event.target.name]: event.target.value,
       });
     };
  
     const handleSubmit = () =>{
      //  console.log(values)
      if (values.sub_draw_type_id !== ''){
        if((values.draw_type_name).trim() === "" || (values.draw_type_name).trim() === null || (values.draw_type_name).trim() === undefined)
        {
          tostE("Please Enter Valid Drawing Type Name !!")
        }else{

          dispatch(editDrawType(values))
        }
      }
      else
      {
        if((values.draw_type_name).trim() === "" || (values.draw_type_name).trim() === null || (values.draw_type_name).trim() === undefined)
        {
          tostE("Please Enter Valid Drawing Type Name !!")
        }
        else{

          dispatch(addDrawType(values))
        }
      }
      setValues({
        ...values,
        draw_type_name : "",
      });
    setOpen(false);
    setOpen2(false);
    }; 
      

    const exportField = [
      {
        field : "sub_draw_type_id" , 
        val : "Type ID"
      }, 
      {
        field : "draw_type_name" , 
        val : "Type Name"
      }, 
     
    ]
  
  

//   const addNewBornbutton = () =>{
//     navigate('/app/master/draw_cat_list_add')
//     console.log("Place order")
    
//   }

  return (
    <>
      <Page className={classes.root} title="Drawing Type">
      <ToastContainer    />

      

      <Dialog disableBackdropClick={true} open={open3.show} onClose={handleClose3} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Drawing Type??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm3} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>



        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> 
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add Category
              </Button>*/}



              <div>
              <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'draw_type')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'draw_type')
              }>
                Export to PDF
            </Button>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Drawing Type
      </Button>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Drawing Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Type
          </DialogContentText>
          {/*<TextField
            autoFocus
            margin="dense"
            id="name"
            label="Category Name"
            type="text"
            fullWidth
          />*/}
          <TextField
                fullWidth
                id="name"
                label="Drawing Type"
                type="text"
                name="draw_type_name"
                value={values.draw_type_name}
                onChange={handleChange}
                required
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Drawing Type
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Drawing Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit Drawing Type
          </DialogContentText>
          {/*<TextField
            autoFocus
            margin="dense"
            id="name"
            label="Category Name"
            type="text"
            fullWidth
          />*/}
          <TextField
                fullWidth
                id="name"
                label="Drawing Type"
                type="text"
                name="draw_type_name"
                value={values.draw_type_name}
                onChange={handleChange}
                required
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update Drawing Type
          </Button>
        </DialogActions>
      </Dialog>
    </div>


    
            </Box>
            {/* <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  // value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              </ThemeProvider>

        </Grid>
       

        
<Grid item md={6} lg={3}  xs={3}>

<FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
<InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
<Select
fullWidth
labelId="demo-simple-select-outlined-label"
id="demo-simple-select-outlined"
value={values.country}
onChange={handleChanged}
label="Export As"
style={{width:"100%" }}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 2" onClick = {() => exportToExcel([{field: "sub_draw_type_id" , val:"Type ID"} , {field:"draw_type_name" , val :"Type Name"}] , listData)}>Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem>


</Select>
</FormControl>
</Grid>
        
      </Grid>
    </div> */}
    
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Drawing Type"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'SR.No.', 
        render : rowData =>(
          <>
          {rowData.tableData.id +1}
          </>
        )
      },
        { title: 'Type Name', field: 'draw_type_name',editable: "never", },     
        
        // { title: 'Left Amount', field: 'mobile_number',editable: "never", },     
        // { title: 'Status', field: 'business',editable: "never", },     
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ?
              <Grid item xs={4}>
                
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
              {role_permit[2] ?
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen3(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
