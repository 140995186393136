import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import {getPlan, delPlan} from '../../../Actions/SuperAdminActions/AddPlan';
import {cleanPlanData} from '../../../Actions/ActionCreators'
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {getExportFileBlob} from '../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  



  const [values, setValues] = useState({
   
    country:"",

  
    });


    const [open, setOpen] = useState({show:false, id:""});

    const handleClose = () =>{
  
      setOpen({...open, show:false, id:""});
    }
    
    const handleOpen = (data) =>{
    
      setOpen({
        ...open,
        show:true,
        id:data.plan_id
      });
    
    }
  
    const handleConfirm = () =>{
      if(open.id!==""){
        
        dispatch(delPlan(open.id))
  
    
        setOpen({
          ...open,
          show:false,
          id:""
        })
      }
    }
  

const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };


  const listData = useSelector((state) =>
  state.AddPlan && state.AddPlan.getData &&
    state.AddPlan.getData.length &&
    Array.isArray(state.AddPlan.getData)
      ? state.AddPlan.getData
      : []
  );



  // console.log(listData)




  
 
  const Delmsg = useSelector(state => state.AddPlan && state.AddPlan.delData && state.AddPlan.delData.message ? state.AddPlan.delData.message : null  )
  const editmsg = useSelector(state => state.AddPlan && state.AddPlan.editData && state.AddPlan.editData.message ? state.AddPlan.editData.message : null  )
  const Delerror = useSelector(state => state.AddPlan && state.AddPlan.delerror && state.AddPlan.delerror.message ? state.AddPlan.delerror.message : null  )






  

  
useEffect( () => {

    dispatch(getPlan())


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(editmsg && typeof(editmsg) === 'string')
      {
        tostS(editmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
        tostS(Delerror)
      }
      
      resolve()

    })
    x.then(()=>{

    dispatch(cleanPlanData())
    dispatch(getPlan())

    })


    
  }, [ Delmsg, Delerror, editmsg])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/plans_edit/' + olData.plan_id , { state: olData })
}





const exportField = [
  {
    field : "plan_number" , 
    val : "Plan Number"
  }, 
  {
    field : "plan_name" , 
    val : "Plan Name"
  }, 
  {
    field : "number_of_sites" , 
    val : "Sites"
  }, 
  {
    field : "users" , 
    val : "Users"
  }, 
  {
    field : "i_geo_api", 
    val : "Geo Api"
  }, 
  {
    field : "is_whatsapp_api" , 
    val : "Whatsapp Api"
  },
  {
    field : "storage" , 
    val : "Storage"
  },
  
]




  


  const addNewBornbutton = () =>{
    navigate('/app/plans_add')
    console.log("Place order")
    
  }


  const auth = true;

  return (
    <>
      <Page className={classes.root} title="Plans List">
      <ToastContainer    />

        <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
            <DialogContent>
              <Typography variant="h6">
                Are you sure you want to Delete this Plan Management??
              </Typography>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" variant="outlined">
                Confirm
              </Button>
            </DialogActions>
        </Dialog>

        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Plans')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Plans')
              }>
                Export to PDF
            </Button>
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add Plan
              </Button>
            </Box>
            {/* <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  xs={4}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Billing Date"
                  format="MM/dd/yyyy"
                  value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
</ThemeProvider>

        </Grid>
        <Grid item xs={4}>
 
          <FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
        <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.country}
          onChange={handleChanged}
          label="Export As"
          style={{width:"100%" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 2" onClick = {() => exportToExcel([{field : "plan_number" , val:"Plan Number"} , {field : "plan_name" , val:"Plan Name"} , {field : "number_of_sites" , val:"Sites"} ,{field : "users" , val:"Users"} , {field : "is_geo_api" , val:"Geo Api"} , {field : "is_whatsapp_api" , val:"What's app Api"} , {field : "storage" , val:"Storage"}] , listData)}>Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem>


        </Select>
      </FormControl>
        </Grid>
        <Grid item xs={4} style={{justifyContent:"center"}}>
         
        </Grid>
      </Grid>
    </div> */}

            
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Plans List"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'Plan Number', field: 'plan_number',editable: "never", },          
        { title: 'Plan Name', field: 'plan_name',editable: "never", },          
        { title: 'Sites', field: 'number_of_sites',editable: "never", },          
        { title: 'Users', field: 'users',editable: "never", },          
        { title: 'Geo Api', field: 'is_geo_api',editable: "never", 
        render: rowData => (
           rowData.is_geo_api === true ? 'Yes' : "No"
          ),
        },          
        { title: 'Whatsapp Api', field: 'is_whatsapp_api',editable: "never",
        render: rowData => (
          rowData.is_whatsapp_api === true ? 'Yes' : "No"
            ), },          
        {
          title: 'Storage', field: "storage",editable: "never"},

        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>

      <Grid item xs={4}>
        
      <EditIcon  onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      
      <Grid item xs={4}>
        <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      {/* <Grid item xs={4}>
        <VisibilityIcon />
      </Grid> */}



      </Grid>
          ),
        },




      ]}
      data={listData}

        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}


    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
