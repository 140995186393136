import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PasswordIcon from '@material-ui/icons/VpnKey';
import BlockIcon from '@material-ui/icons/Block'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput,
  Tooltip
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {getOtherSubAdmin , delOtherSubAdmin, blockOtherSubAdmin} from '../../../../Actions/SubAdminActions/M_OtherSubAdmin';
import { cleanOtherSubAdminData, cleanProfileManCreData } from "../../../../Actions/ActionCreators";
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'
import {editProfileManPass} from '../../../../Actions/SubAdminActions/ProfileManagement';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);
  
  const [values, setValues] = useState({
    admin_id:'',
    password:'',
    confirm_pass:'',
    confirmation:false,
    show_pass:false,
    show_confirm_pass:false,
    
  })

  const [OpenPass, setOpenPass]  = useState(false);

  const handleOpenPass = (data) =>{    
    setOpenPass(true);

    setValues({
      ...values,
      ['admin_id']:data.admin_id
    })
  }

  const handleClosePass = () => {
    setOpenPass(false)
    setValues({
      ...values,
      admin_id:'',
      password:'',
      confirm_pass:'',
      confirmation:false,
      show_pass:false,
      show_confirm_pass:false
    })
  }

  const handlePassConfirmationOpen = () =>{
    if(values.password === "" || values.password === null || values.password === undefined)
    {
      tostE("Please Enter Password")
    }
    else if(values.confirm_pass === "" || values.confirm_pass === null || values.confirm_pass === undefined)
    {
      tostE("Please Enter Confirm Password")

    }
    else if(values.password !== values.confirm_pass )
    {
        tostE("Password Didn't Match !!")
    }else{
      setValues({
        ...values,
        confirmation:true
      })

    }
  }
  
  const handlePassConfirmationClose = () =>{
    setValues({
      ...values,
      confirmation:false
    })
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  
  
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      show_pass: !values.show_pass,
    });
  };
  const handleClickShowConfirmPass = () => {
    setValues({
      ...values,
      show_confirm_pass: !values.show_confirm_pass,
    });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleConfirmPassChange = () =>{
    
    
      console.log(values)
      dispatch(editProfileManPass({admin_id : values.admin_id , password : values.password}))
      
      setOpenPass(false)
      setValues({
      ...values,
      admin_id:'',
      password:'',
      confirm_pass:'',
      confirmation:false,
      show_pass:false,
      show_confirm_pass:false
      })
    
   

  }

  const [open, setOpen] = useState({show:false, id:""});


  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.admin_id
    });
  
  }
  
  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delOtherSubAdmin(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }


  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const listData= useSelector((state) =>
  state.AddOtherSubAdmin && state.AddOtherSubAdmin.getData &&
    state.AddOtherSubAdmin.getData.length &&
    Array.isArray(state.AddOtherSubAdmin.getData)
      ? state.AddOtherSubAdmin.getData
      : []
  );


  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);


const sub_admin_id = useSelector(state => state.login.data.r_subadmin)
  
 
const Delmsg = useSelector(state => state.AddOtherSubAdmin && state.AddOtherSubAdmin.delData && state.AddOtherSubAdmin.delData.message ? state.AddOtherSubAdmin.delData.message : null  )
const Delerror = useSelector(state => state.AddOtherSubAdmin && state.AddOtherSubAdmin.delError && state.AddOtherSubAdmin.delError.message ? state.AddOtherSubAdmin.delError.message : null  )
const EditData = useSelector(state => state.AddOtherSubAdmin && state.AddOtherSubAdmin.editData && state.AddOtherSubAdmin.editData.message ? state.AddOtherSubAdmin.editData.message : null)
const BlockData = useSelector(state => state.AddOtherSubAdmin && state.AddOtherSubAdmin.blockData && state.AddOtherSubAdmin.blockData.message ? state.AddOtherSubAdmin.blockData.message : null)

// console.log(EditData);
const chpMsg = useSelector((state) => state.AddSubProfile && state.AddSubProfile.editData && state.AddSubProfile.editData.message ? state.AddSubProfile.editData.message : null )

  

  
useEffect( () => {

    dispatch(getOtherSubAdmin(sub_admin_id))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      if (EditData && typeof(EditData) === 'string')
      {
        tostS(EditData);
      }
      if (BlockData && typeof(BlockData) === 'string')
      {
        tostS(BlockData);
      }
      if(chpMsg && typeof(chpMsg) === 'string')
      {
        tostS(chpMsg)
      }
      
      resolve()

    })
    x.then(()=>{
      
      
    dispatch(cleanProfileManCreData())

    dispatch(cleanOtherSubAdminData())
    dispatch(getOtherSubAdmin(sub_admin_id))

    })


    
  }, [ Delmsg  ,Delerror , EditData, chpMsg, BlockData ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/master/users_edit/'+olData.admin_id , { state: olData })
}




  const addNewBornbutton = () =>{
    navigate('/app/master/users_add')
    console.log("Place order")
    
  }

  const exportField = [
    {
      field : "name" , 
      val : "Name"
    }, 
    {
      field : "mobile" , 
      val : "Mobile"
    }, 
    {
      field : "email" , 
      val : "Email"
    }, 
    {
      field : "role_name" , 
      val : "Role"
    }, 
    
  ]

  const [block, setBlock] = useState({
    admin_id:'',
    show:false,
    active:''
  })

  const OpenBlock = (data) =>{
    setBlock({
      ...block,
      admin_id:data.admin_id,
      active:!data.is_active,
      show:true
    })
  }
  
  const CloseBlock = () =>{
    setBlock({
      ...block,
      admin_id:'',
      active:'',
      show:false
    })
  }


  const ConfirmBlock = () =>{
    if(block.admin_id!=='' && block.show){
      console.log(block)
      dispatch(blockOtherSubAdmin(block.admin_id, block.active))
      setBlock({
        ...block,
        admin_id:'',
        active:'',
        show:false
      })
    }else{
      tostE("User Can't be blocked!!");
    }

  }





  return (
    <>
      <Page className={classes.root} title="Added Users">
      <ToastContainer    />

      <Dialog disableBackdropClick={true} open={OpenPass} onClose={handleClosePass} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="password"
                type={values.show_pass ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.show_pass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
            </Grid>
            <br/>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="confirm_pass"
                type={values.show_confirm_pass ? 'text' : 'password'}
                value={values.confirm_pass}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPass}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.show_confirm_pass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            </Grid>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePass} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handlePassConfirmationOpen} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog disableBackdropClick={true} open={values.confirmation} onClose={handlePassConfirmationClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Change Password??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handlePassConfirmationClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirmPassChange} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this User??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      
      <Dialog disableBackdropClick={true} open={block.show} onClose={CloseBlock} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Change this User's Active Status??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={CloseBlock} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={ConfirmBlock} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>







        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Users')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Users')
              }>
                Export to PDF
            </Button>
          
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Create New
              </Button>
            </Box>
            {/* <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  // value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>

              </ThemeProvider>
        </Grid>
       

        
<Grid item md={6} lg={3}  xs={3}>

<FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
<InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
<Select
fullWidth
labelId="demo-simple-select-outlined-label"
id="demo-simple-select-outlined"
value={values.country}
onChange={handleChanged}
label="Export As"
style={{width:"100%" }}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 2" onClick={()=>exportToExcel([{field :"name" , val:"Name"} , {field :"mobile" , val:"Mobile"} , {field : "email" , val : "Email"} , {field:"permit_role" , val : "Role Assigned"}] , listData)}>Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 3">Word</MenuItem>


</Select>
</FormControl>
</Grid>
        
      </Grid>
    </div> */}
    
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Added Users"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'Name', field: 'name',editable: "never", },
        { title: 'Mobile', field: 'mobile',editable: "never", },     
        { title: 'Email', field: 'email',editable: "never", },     
        { title: 'Role Assigned', field: 'role_name',editable: "never", },     
        { title: 'Active', render:rowData => (

          rowData.is_active ? <Typography style={{color:'green'}}>Active</Typography>
          : <Typography style={{color:'red'}}>In-Active</Typography>
        )},     
        // { title: 'Date', field: 'date',editable: "never", },     
        // { title: 'Status', field: 'business',editable: "never", },     
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ? 
              <Grid item xs={3}>
            
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              
              }
              {role_permit[2] ? 
              <Grid item xs={3}>
                <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
              <Grid item xs={3}>
                <PasswordIcon onClick={() => {handleOpenPass(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              <Grid item xs={3}>
                <BlockIcon onClick={() => {OpenBlock(rowData)}} style={{cursor:"pointer"}}  />
              </Grid>
            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                // exportButton : true,
                // exportAllData : true,
                // exportCsv  : (columns, data) => {
                //  hg(columns,data)
                 
                // }
                
              }}
        localization={{
          toolbar: {
            exportCSVName: "Export some Excel Format",
            exportPDFName: "Export as PDF Format"
          }
        }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
