// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addDrawBegin , addDrawSuccess , addDrawFailure} from '../ActionCreators'
import {getDrawBegin , getDrawSuccess , getDrawFailure} from '../ActionCreators'
import {delDrawBegin , delDrawSuccess , delDrawFailure} from '../ActionCreators'
import {editDrawBegin , editDrawSuccess , editDrawFailure} from '../ActionCreators';

export const addDraw = (session) => {

    return (dispatch) => {
        dispatch(addDrawBegin());

        axios.post(Base_URL + '/subadmin/addDrawMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addDrawSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addDrawFailure(err.response.data));

                }
            })
    }
}


export const getDraw = (x) => {

    return (dispatch) => {
        dispatch(getDrawBegin());

        axios.get(Base_URL + '/subadmin/getDrawMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getDrawSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getDrawFailure(err.response.data));

                }
            })
    }
}

export const delDraw = (x) => {

    return (dispatch) => {
        dispatch(delDrawBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delDrawMaster' , {params :{sub_draw_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delDrawSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delDrawFailure(err.response.data));

                }
            })
    }
}

export const editDraw = (session) => {

    return (dispatch) => {
        dispatch(editDrawBegin());

        axios.post(Base_URL + '/subadmin/editDrawMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editDrawSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editDrawFailure(err.response.data));

                }
            })
    }
}

