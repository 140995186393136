import axios from "../../Config/Interceptor3";


// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'


import {addPlanBegin , addPlanSuccess , addPlanFailure } from './../ActionCreators'
import {getPlanBegin , getPlanSuccess , getPlanFailure } from './../ActionCreators'
import {editPlanBegin , editPlanSuccess , editPlanFailure } from './../ActionCreators'
import {delPlanBegin , delPlanSuccess , delPlanFailure } from './../ActionCreators'





export const addPlan = (session) => {

    return (dispatch) => {
        dispatch(addPlanBegin());

        axios.post(Base_URL + '/superadmin/addPlan', session)
            .then((res) => {
                dispatch(addPlanSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addPlanFailure(err.response.data));

                }
            })
    }
}



export const getPlan = () => {

    return (dispatch) => {
        dispatch(getPlanBegin());

        axios.get(Base_URL + '/superAdmin/getPlan')
            .then((res) => {
                dispatch(getPlanSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getPlanFailure(err.response.data));

                }
            })
    }
}



export const editPlan = (session) => {
    console.log(session);
    return (dispatch) => {
        dispatch(editPlanBegin());

        axios.post(Base_URL + '/superadmin/editPlan', session)
            .then((res) => {
                dispatch(editPlanSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editPlanFailure(err.response.data));

                }
            })
    }
}


export const delPlan = (x) => {

    return (dispatch) => {
        dispatch(delPlanBegin());


        axios.get(Base_URL + '/superadmin/delPlan' , {params :{plan_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delPlanSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delPlanFailure(err.response.data));

                }
            })
    }
}







