import {
  Box,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import tableIcons from "../../../Config/IconsFile";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import Page from "../../../../src/components/Page";
import clsx from "clsx";
import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import { getClientSites } from "../../../Actions/SubAdminActions/M_AddSites";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";

const ViewBoq = ({ className, ...rest }) => {
  const [boqData, setBoqData] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [deletingBoq, setDeletingBoq] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.login.data.admin_id);
  const SiteId = useSelector((state) => state.login.data.site_id);

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    root2: {},
    avatar: {
      marginRight: theme.spacing(2),
    },
    importButton: {
      marginRight: theme.spacing(1),
    },
    exportButton: {
      marginRight: theme.spacing(1),
    },
    root3: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    formControl: {
      margin: theme.spacing(0),
      minWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    rootq: {
      width: "100%",
      marginTop: "15px",
    },
    headingq: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeadingq: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));

  const classes = useStyles();

  const SiteData = useSelector((state) =>
    state.AddSites &&
    state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );

  const [values, setValues] = useState({
    sub_admin_id: id,
    site_id: SiteId,
  });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  const handleChangeSiteName = (event) => {
    setValues({ ...values, site_id: event.target.value });
  };

  useEffect(() => {
    dispatch(getClientSites(id));
  }, [dispatch, id]);

  const onDelete = (rowData) => {
    console.log("Delete button clicked for: ", rowData);
    setDeletingBoq(rowData);
    handleConfirm("deleteBoq");
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = () => {
    axios
      .get(Base_URL + "/client/ClientgetBoq", {
        params: { site: values.site_id, sub_admin_id: values.id },
      })
      .then((res) => {
        if (res.data && res.data.boq_list && res.data.boq_list.length > 0) {
          setBoqData(res.data.boq_list);
          tostS("BOQ Fetched Successfully");
        } else {
          setBoqData([]);
          tostE("No BOQ Found");
        }
      })
      .catch((err) => {
        if (!err.response) {
          tostE(
            ' "Something Went wrong : Please Check Network or Server Connectivity"'
          );
        }
      });
  };

  const deleteBoq = () => {
    console.log("Deleting BOQ: ", deletingBoq);
    axios
      .delete(Base_URL + "/client/ClientDeleteBOQ", {
        params: { boq_id: deletingBoq.boq_id },
      })
      .then((response) => {
        if (response.data.status === 200) {
          tostS("Deletion successful");
          setBoqData(
            boqData.filter((item) => item.boq_id !== deletingBoq.boq_id)
          );
        } else {
          tostE("Failed to delete BOQ: " + response.data.message);
        }
      })
      .catch((err) => {
        tostE("Error deleting BOQ: " + err.message);
      });
    setDeletingBoq(null);
  };

  const totalPriceSum = boqData.reduce((acc, curr) => {
    const totalPrice = parseFloat(curr.total_price);
    return !isNaN(totalPrice) ? acc + totalPrice : acc;
  }, 0);

  const percentageValue =
    boqData.length > 0 && !isNaN(parseFloat(boqData[0].percentage_boq_value))
      ? parseFloat(boqData[0].percentage_boq_value)
      : 0;
  const percentageType =
    boqData.length > 0 && boqData[0].percentage_boq_type !== null
      ? boqData[0].percentage_boq_type
      : "";

  const finalTotalPrice =
    percentageType === "positive"
      ? totalPriceSum + (totalPriceSum * percentageValue) / 100
      : percentageType === "negative"
      ? totalPriceSum - (totalPriceSum * percentageValue) / 100
      : totalPriceSum;

  const handle = () => {
    navigate("/app/ClientMb");
  };

  const onEdit = (olData) => {
    navigate("/app/ClientEditBoq/" + olData.boq_id, { state: olData });
  };

  const acceptAllBoqs = () => {
    axios
      .post(`${Base_URL}/client/ApproveAllBoqs`, {
        site_id: values.site_id,
        action: "approve",
      })
      .then((res) => {
        if (res.status === 200) {
          tostS("All BOQs approved successfully");
          handleSubmit();
          setBoqData((prevBoqData) =>
            prevBoqData.map((boq) => ({ ...boq, isApproved: true }))
          );
          setIsApproved(true);
        }
      })
      .catch((err) => {
        tostE("Could not approve all BOQs");
      });
  };

  const grantEditAccess = () => {
    axios
      .post(`${Base_URL}/client/EditAccesss`, {
        site_id: values.site_id,
        sub_admin_id: values.sub_admin_id,
        action: boqData[0].editaccess ? "remove" : "grant",
      })
      .then((res) => {
        if (res.status === 200) {
          const message = boqData[0].editaccess
            ? "Edit access removed"
            : "Edit access granted";
          handleSubmit();
          tostS(message);
          setEditAccess(!editAccess);
        }
      })
      .catch((err) => {
        const message = boqData[0].editaccess
          ? "Could not remove edit access"
          : "Could not grant edit access";
        tostE(message);
      });
  };

  const handleConfirm = (action) => {
    console.log("Handle confirm action: ", action);
    setConfirmAction(action);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setConfirmAction(null);
  };

  const handleConfirmAccept = () => {
    console.log("Confirm action accepted: ", confirmAction);
    if (confirmAction === "grantEditAccess") {
      grantEditAccess();
    } else if (confirmAction === "deleteBoq") {
      deleteBoq();
    }
    handleConfirmClose();
  };

  return (
    <Page className={classes.root} title="Site Drawings">
      <ToastContainer />
      <Container maxWidth={false}>
        <Box mt={3}>
          {boqData.length > 0 && (
            <>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Button
                  onClick={acceptAllBoqs}
                  color="secondary"
                  variant="contained"
                  disabled={boqData[0].approved}
                >
                  {boqData[0].approved ? "Approved" : "Accept BOQ"}
                </Button>
                <Button
                  onClick={() => handleConfirm("grantEditAccess")}
                  color="primary"
                  variant="contained"
                >
                  {editAccess
                    ? "Grant Edit Access from Contractor"
                    : "Remove Edit Access to Contractor"}
                </Button>
              </Box>
              <Card>
                <MaterialTable
                  icons={tableIcons}
                  title="BOQ"
                  columns={[
                    {
                      title: "SR. No.",
                      render: (rowData) => rowData.tableData.id + 1,
                    },
                    { title: "Item Description", field: "item_description" },
                    {
                      title: "Quantity",
                      field: "quantity",
                      render: (rowData) =>
                        isNaN(rowData.quantity) ? "___" : rowData.quantity,
                    },
                    {
                      title: "Used Quantity",
                      field: "used_quantity",
                      render: (rowData) => {
                        const usedQuantity = parseInt(rowData.used_quantity);
                        return isNaN(usedQuantity) ? "___" : usedQuantity;
                      },
                    },
                    { title: "Total Price", field: "total_price" },
                    {
                      title: "Actions",
                      field: "actions",
                      render: (rowData) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button onClick={() => onEdit(rowData)}>
                            <EditIcon />
                          </Button>
                          <Button onClick={() => onDelete(rowData)}>
                            <DeleteIcon />
                          </Button>
                        </div>
                      ),
                    },
                  ]}
                  data={boqData}
                />

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{
                    padding: "10px",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography variant="h5">
                    Total Amount: {totalPriceSum.toFixed(2)}
                  </Typography>
                  {boqData.some(
                    (item) => parseFloat(item.percentage_boq_value) !== 0
                  ) && (
                    <Typography variant="h5" style={{ marginTop: "10px" }}>
                      Percentage BOQ: {percentageValue} ({percentageType})
                    </Typography>
                  )}
                  <Typography variant="h5" style={{ marginTop: "10px" }}>
                    Final Total Amount: {finalTotalPrice.toFixed(2)}
                  </Typography>
                </Box>
              </Card>
            </>
          )}
        </Box>
      </Container>
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>
          {confirmAction === "grantEditAccess"
            ? editAccess
              ? "Grant Edit Access"
              : "Remove Edit Access"
            : "Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmAction === "grantEditAccess"
              ? editAccess
                ? "Are you sure you want to Grant edit access?"
                : "Are you sure you want to Remove edit access?"
              : "Are you sure you want to delete this BOQ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAccept} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default ViewBoq;
