// import { addMatraialCategory } from '../../Actions/SubAdminActions/M_MaterialCategory';
import {ADD_DRAWING_FLOOR_TYPE_LOADING , ADD_DRAWING_FLOOR_TYPE_SUCCESS , ADD_DRAWING_FLOOR_TYPE_FAILURE , CLEAN_DRAWING_FLOOR_TYPE_DATA} from '../../Actions/Types';
import {GET_DRAWING_FLOOR_TYPE_LOADING , GET_DRAWING_FLOOR_TYPE_SUCCESS , GET_DRAWING_FLOOR_TYPE_FAILURE} from '../../Actions/Types';
import {DEL_DRAWING_FLOOR_TYPE_LOADING , DEL_DRAWING_FLOOR_TYPE_SUCCESS , DEL_DRAWING_FLOOR_TYPE_FAILURE} from '../../Actions/Types';
import {EDIT_DRAWING_FLOOR_TYPE_LOADING , EDIT_DRAWING_FLOOR_TYPE_SUCCESS , EDIT_DRAWING_FLOOR_TYPE_FAILURE} from '../../Actions/Types';


const AddDrawingFloorType = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {
        // case CLEAN_ALL_DATA:
        //     return {
        //         ...state,
        //         data: [{}],
        //         loading:false,
        //         error:{},
        //         getData:[{}],
        //         getError:{}
                
        //     }
    
    
        case CLEAN_DRAWING_FLOOR_TYPE_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                // getData : [{}],
                editData : [{}],
                editError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_DRAWING_FLOOR_TYPE_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                delData : [{}]
                
            }
        case ADD_DRAWING_FLOOR_TYPE_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
                delData : [{}]
            }
        case ADD_DRAWING_FLOOR_TYPE_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
                delData:[{}]
            }

        case GET_DRAWING_FLOOR_TYPE_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                delData : [{}],
                
            }
        case GET_DRAWING_FLOOR_TYPE_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data,
                delData:[{}]
            }
        case GET_DRAWING_FLOOR_TYPE_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                delData : [{}]
            }   
        
        case DEL_DRAWING_FLOOR_TYPE_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_DRAWING_FLOOR_TYPE_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_DRAWING_FLOOR_TYPE_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
                    
        case EDIT_DRAWING_FLOOR_TYPE_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}]
                
            }
        case EDIT_DRAWING_FLOOR_TYPE_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_DRAWING_FLOOR_TYPE_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            

        default:
        return state
    
    }
    }
    
    
    
export default AddDrawingFloorType;
    