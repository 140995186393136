import React, { useState, useEffect } from 'react';
import axios from "../../../Config/Interceptor3";


import { Base_URL } from '../../../Config/BaseUrlConfig'
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  CardHeader,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { tostS, tostW, tostE } from '../../../Config/Toast';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from "@date-io/date-fns";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { GetReport } from '../../../Actions/SuperAdminActions/GetReport';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    maxHeight: 400,
  },
}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = useSelector(state => state.login.data.id);
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date(Date.now()));
  const [selectedDateTo, setSelectedDateTo] = useState(new Date(Date.now()));
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  // const [reportData, setReportData] = useState([]);

  const planData = useSelector((state) =>
    state.AddPlan && state.AddPlan.getData &&
      state.AddPlan.getData.length &&
      Array.isArray(state.AddPlan.getData)
        ? state.AddPlan.getData
        : []
  );

  const reportData = useSelector(state => state.GetReortAdmin.data);

  console.log(reportData);

  const handleGenerateReport = () => {
    const requestData = {
      from: moment(selectedDateFrom).format('YYYY-MM-DD'),
      to: moment(selectedDateTo).format('YYYY-MM-DD'),
      plan: selectedPlan,
      status: selectedStatus,
      user_id: id
    };

    dispatch(GetReport(requestData))

     
      // axios.post(Base_URL + '/superadmin/getReport', requestData)
      //     .then((res) => {
      //       setReportData(res.data.subadmins); 
      //       tostS("Sucessfully Generated")
      //     }).catch((err) => {
      //         console.log(err)
      //         if (!err.response) {
      //             alert("Something Went wrong : Please Check Network or Server Connectivity")

      //         }
      //         else {
      //           tostE('no data found')

      //         }
      //     })

  };

  return (
    <Page
      className={classes.root}
      title="Report Generation"
    >
      <ToastContainer />
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
              autoComplete="off"
              noValidate
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader subheader="" title="Generate Report" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            margin="normal"
                            id="date-picker-dialog"
                            label="From"
                            format="MM/dd/yyyy"
                            value={selectedDateFrom}
                            onChange={(date) => setSelectedDateFrom(date)}
                            variant="outlined"
                            inputVariant="outlined"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            margin="normal"
                            id="date-picker-dialog"
                            label="To Date"
                            format="MM/dd/yyyy"
                            value={selectedDateTo}
                            onChange={(date) => setSelectedDateTo(date)}
                            variant="outlined"
                            inputVariant="outlined"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="select-plan-label">Plan</InputLabel>
                        <Select
                          labelId="select-plan-label"
                          id="select-plan"
                          value={selectedPlan}
                          onChange={(e) => setSelectedPlan(e.target.value)}
                          label="Plan"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {planData.map((data, i) =>
                            <MenuItem key={i} value={data.plan_id}>{data.plan_name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="select-status-label">Plan Mode</InputLabel>
                        <Select
                          labelId="select-status-label"
                          id="select-status"
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                          label="Plan Mode"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleGenerateReport}
                  >
                    Generate Report
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
          {/* Display Table with Report Data */}
          {reportData && reportData.subadmins && reportData.subadmins.length > 0 &&
            <Grid item xs={12}>
              <Card>
                <CardHeader subheader="Generated Report Details" title="Report Details" />
                <Divider />
                <CardContent>
                  <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Subadmin ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell>Plan Start Date</TableCell>
                          <TableCell>Plan End Date</TableCell>
                          {/* Add more table headers as per your data structure */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {reportData.subadmins.map((subadmin) => (
                          <TableRow key={subadmin.admin_id}>
                            <TableCell>{subadmin.admin_id}</TableCell>
                            <TableCell>{subadmin.name}</TableCell>
                            <TableCell>{subadmin.email}</TableCell>
                            <TableCell>{subadmin.role}</TableCell>
                            <TableCell>{moment(subadmin.plan_start_date).format('MMMM Do YYYY')}</TableCell>
                            <TableCell>{moment(subadmin.plan_end_date).format('MMMM Do YYYY')}</TableCell>
                            {/* Add more table cells as per your data structure */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          }
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
