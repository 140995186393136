import {ADD_CLIENTS_LOADING , ADD_CLIENTS_SUCCESS , ADD_CLIENTS_FAILURE, CLEAN_CLIENTS_DATA } from '../../Actions/Types';
import {GET_CLIENTS_LOADING , GET_CLIENTS_SUCCESS , GET_CLIENTS_FAILURE } from '../../Actions/Types';
import {EDIT_CLIENTS_LOADING , EDIT_CLIENTS_SUCCESS , EDIT_CLIENTS_FAILURE } from '../../Actions/Types';
import {EDIT_CLIENTS_PASS_LOADING , EDIT_CLIENTS_PASS_SUCCESS , EDIT_CLIENTS_PASS_FAILURE } from '../../Actions/Types';
import {DELETE_CLIENTS_LOADING , DELETE_CLIENTS_SUCCESS , DELETE_CLIENTS_FAILURE } from '../../Actions/Types';
import {GET_BACKUP_LOADING , GET_BACKUP_SUCCESS , GET_BACKUP_FAILURE } from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types';





const AddClients = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            editPassData :[{}],
            editPassError : {},
            delData:[{}],
            delError:{}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            editPassData :[{}],
            editPassError : {},
            delData:[{}],
            delError:{},
            backupData:[{}],
            backupError:{}
            
        }

        case CLEAN_CLIENTS_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{},
                editData :[{}],
                editError : {},
                editPassData :[{}],
                editPassError : {},
                delData:[{}],
                delError:{},
                backupData:[{}],
                backupError:{}
            
            }
    
    case ADD_CLIENTS_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case ADD_CLIENTS_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case ADD_CLIENTS_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }



        case GET_CLIENTS_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                
            }
        case GET_CLIENTS_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data
            }
        case GET_CLIENTS_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                data:{},
            }   
        case EDIT_CLIENTS_LOADING:
            return {
                ...state,
                loading:true,
                editData :[{}],
                editError : {},            
                
            }
        case EDIT_CLIENTS_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_CLIENTS_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }

        case EDIT_CLIENTS_PASS_LOADING:
            return {
                ...state,
                loading:true,
                editPassData :[{}],
                editPassError : {},            
                
            }
        case EDIT_CLIENTS_PASS_SUCCESS:
            return {
                ...state,
                loading:false,
                editPassError:{},
                editPassData:action.data
            }
        case EDIT_CLIENTS_PASS_FAILURE:
            return {
                ...state,
                loading:false,
                editPassError:action.error,
                editPassData:{},
            }

        case DELETE_CLIENTS_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DELETE_CLIENTS_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DELETE_CLIENTS_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }

        case GET_BACKUP_LOADING:
            return {
                ...state,
                loading:true,
                backupError: {},
                backupData : [{}]
                
            }
        case GET_BACKUP_SUCCESS:
            return {
                ...state,
                loading:false,
                backupError:{},
                backupData:action.data,
            }
        case GET_BACKUP_FAILURE:
            return {
                ...state,
                loading:false,
                backupError:action.error,
                backupData:{},
            }

    default:
        return state

}
}



export default AddClients;
