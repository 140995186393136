import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import axios from "../../../../Config/Interceptor2";
import { Base_URL } from "../../../../Config/BaseUrlConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
  card: {
    marginBottom: theme.spacing(4),
  },
}));

const SiteTestReports = () => {
  const classes = useStyles();
  const [testReports, setTestReports] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
console.log(selectedReport);
  const selected_site = useSelector((state) =>
    state.SelectedSite &&
    state.SelectedSite.sitedata &&
    state.SelectedSite.sitedata.site
      ? state.SelectedSite.sitedata.site
      : null
  );

  const a_id = useSelector((state) =>
    state.login && state.login.data && state.login.data.r_subadmin
      ? state.login.data.r_subadmin
      : ""
  );

  useEffect(() => {
    if (selected_site && a_id) {
      fetchTestReports();
    }
  }, [selected_site, ]);

  const fetchTestReports = async () => {
    try {
      const response = await axios.get(`${Base_URL}/subadmin/getTestReports`, {
        params: {
          site_id: selected_site,
          admin_id: a_id,
        },
      });
      console.log(response);
      setTestReports(response.data.data); // Assuming response.data.data is the array of reports
    } catch (error) {
      console.error("Error fetching test reports:", error);
      setTestReports([])
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (report) => {
    setSelectedReport(report);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedReport(null);
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Site Test Reports
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>

                <TableCell>Created On</TableCell>
                <TableCell>Item Description</TableCell>
                <TableCell>No of Samples</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(testReports) &&
                testReports
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((report) => (
                    <TableRow key={report.test_id}>
                      <TableCell>{report.test_id}</TableCell>

                      <TableCell>{report.created_on}</TableCell>
                      <TableCell>{report.item_description}</TableCell>
                      <TableCell>{report.noofsample}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleClickOpen(report)}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={testReports.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>

      {selectedReport && (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Test Report Details</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Item Description: {selectedReport.item_description}
            </Typography>
            {/* <Typography variant="body1">
              Site ID: {selectedReport.site_id}
            </Typography> */}
            <Typography variant="body1">
              Created On: {selectedReport.created_on}
            </Typography>
            <Typography variant="body1">
              No of Samples: {selectedReport.noofsample}
            </Typography>
            <img
              src={selectedReport.image} 
              alt="report"
              style={{ width: "100%", marginBottom: "10px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default SiteTestReports;
