import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import tableIcons from '../../../Config/IconsFile'


import {orderMachineIndent} from '../../../Actions/SubAdminActions/Machine_Indent'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate();
  const listData = (state.machine_details && Array.isArray(state.machine_details) && state.machine_details.length ? state.machine_details : []);

  const [columns, setColumns] = useState([
    { title: 'Machine Name', field: 'mac_name', editable:'never'},
    { title: 'Machine Type', field: 'mac_type', editable:'never'},
    { title: 'Quantity', field: 'quan' },
    { title: 'Amount', field: 'amt' },
  ]);



  return (
    <Page className={classes.root} title="Machine Detail" >
      <ToastContainer    />
       

      
      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader="" title="Machine Detail View" />
                <Divider />
                <CardContent>
                <Grid container spacing={3}>
                  
                  <Grid item md={6} xs={12}>
                    <Typography>Order ID :  {state.order_id}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Site Name :  {state.site_name}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Required Date :  {moment(state.required_date).format("DD.MM.YYYY")}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Priority :  {state.priority}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Status :  {state.status}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Accepted By :  {state.accepted_by}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Notes :  {state.notes}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
                  
                    <Typography>Location :  
                      {state.lat}, {state.long}                 
                    </Typography>
                  
                   : 
                   <Typography>Location: </Typography>
                   }
                    
                  </Grid>
                </Grid>

                </CardContent>
                <Divider />
                
              </Card>
            </form>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Card className={clsx(classes.root2, className)} {...rest}>
          <MaterialTable
            icons={tableIcons}      
            title="Machine Details"
            columns={columns}
            data={listData}
            
            options={{
                    selection: false,
                    textAlign: "center",
                    headerStyle: { textAlign: "left" },
                    rowStyle: { textAlign: "center" },
                    emptyRowsWhenPaging:false,  
                    search:false,
                    paging:false
                  }}
                
          />
         
          </Card>
          </Box>
      </Container>
    </Page>
  );
};

export default AddBusiness;
