import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const  TabPanel = (props)=> {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Logo Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  rootn: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()


  const [tvalue, setTValue] = React.useState(0);

  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
  };

  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  const handleChangee = (event) => {
    setValues({...values, ['state']:event.target.value});
  };
  const handleChangef = (event) => {
    setValues({...values, ['city']:event.target.value});
  };
  const handleChanget = (event) => {
    setValues({...values, ['type_of_business']:event.target.value});
  };



  const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
  admin_id :id,
  added_date :selectedDate,
  added_time :selectedDate,
  image1:"",
  tempImage1:"",
  name : "",
  business : "",
  designation: "",
  office_address: "",
  mobile_number: "",
  country:"",
  state:'',
  city:"",
  type_of_business:"",

  image1: "",
  image2:'',
  image3:"",
  image4:"",
  tempimg1: "",
  tempimg2: "",
  tempimg3: "",
  tempimg4: "",

  fixImg : "/static/images/avatars/upload.png"

  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (date) =>{

    console.log(date)

  }




  const handleChangec = (event) => {
      console.log(event.target.checked)
    setValues({ ...values, ['mobile_visible']: event.target.checked });
  };


  const handleSubmit = () =>{
      console.log(values)
    // dispatch(addBusiness(values))
    setValues({
      ...values,
    image1:"",
  tempImage1:"",
  name : "",
  business : "",
  designation: "",
  office_address: "",
  mobile_number: "",
  country:"",
  state:"",
  city:"",
  image1: "",
  image2:'',
  image3:"",
  image4:"",
  tempimg1: "",
  tempimg2: "",
  tempimg3: "",
  tempimg4: "",
     
    });

  }




    /********* Image Handling   ************** */

    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);
    const hiddenFileInput3 = useRef(null);
    const hiddenFileInput4 = useRef(null);
  
    const handleClick1 = (event) => {
      hiddenFileInput1.current.click();
    };
    
    const handleClick2 = (event) => {
      hiddenFileInput2.current.click();
    };
  
    const handleClick3 = (event) => {
      hiddenFileInput3.current.click();
    };
  
    const handleClick4 = (event) => {
      hiddenFileInput4.current.click();
    };
    
  
    const handleChangeimg1 = (event) => {
      if (event.target.files[0] && event.target.files.length !== 0) {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        console.log(URL.createObjectURL(fileUploaded));
        var x = URL.createObjectURL(fileUploaded);
  
        setValues({ ...values, ["tempimg1"]: x, ["image1"]: fileUploaded });
      } else {
        console.log("yaah ");
      }
    };
  
    const handleChangeimg2 = (event) => {
      if (event.target.files[0] && event.target.files.length !== 0) {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        console.log(URL.createObjectURL(fileUploaded));
        var x = URL.createObjectURL(fileUploaded);
  
        setValues({ ...values, ["tempimg2"]: x, ["image2"]: fileUploaded });
      } else {
        console.log("yaah ");
      }
    };
    const handleChangeimg3 = (event) => {
      if (event.target.files[0] && event.target.files.length !== 0) {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        console.log(URL.createObjectURL(fileUploaded));
        var x = URL.createObjectURL(fileUploaded);
  
        setValues({ ...values, ["tempimg3"]: x, ["image3"]: fileUploaded });
      } else {
        console.log("yaah ");
      }
    };
    const handleChangeimg4 = (event) => {
      if (event.target.files[0] && event.target.files.length !== 0) {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        console.log(URL.createObjectURL(fileUploaded));
        var x = URL.createObjectURL(fileUploaded);
  
        setValues({ ...values, ["tempimg4"]: x, ["image4"]: fileUploaded });
      } else {
        console.log("yaah ");
      }
    };
  
    /********* Image Handling   ************** */



  const CData = useSelector((state) =>
  state.GetCountry && state.GetCountry.getdata &&
    state.GetCountry.getdata.length &&
    Array.isArray(state.GetCountry.getdata)
      ? state.GetCountry.getdata
      : []
  );


  
  const SData = useSelector((state) =>
  state.GetState && state.GetState.getdata &&
    state.GetState.getdata.length &&
    Array.isArray(state.GetState.getdata)
      ? state.GetState.getdata
      : []
  );

  const CiData = useSelector((state) =>
  state.GetCity && state.GetCity.getdata &&
    state.GetCity.getdata.length &&
    Array.isArray(state.GetCity.getdata)
      ? state.GetCity.getdata
      : []
  );



  
  const BlistData = useSelector((state) =>
  state.BusinessMaster &&  state.BusinessMaster.getdata &&
    state.BusinessMaster.getdata.length &&
    Array.isArray(state.BusinessMaster.getdata)
      ? state.BusinessMaster.getdata
      : []
  );




  const handleCountryMenu = (x) => {
    if(x !== "" && x !== undefined && x !== null )
    {
        // dispatch(getStateData(x))
    }
    else
    {
      console.log(".")
    }
  }

  const handleStateMenu = (y) => {
    if(y !== "" && y !== undefined && y !== null )
    {
        // dispatch(getCityData(y))
    }
    else
    {
      console.log(".")
    }
  }



  

  useEffect( () => {
    // dispatch(getBusinessCat())

    // dispatch(getCountryData())
     
    }, [])





//   const addmsg = useSelector(state => state.Mh.data && state.Mh.data.mh_id ? state.Mh.data.mh_id : null  )
//   const adderror = useSelector(state => state.Mh.error && state.Mh.error.message ? state.Mh.error.message : null  )


//   useEffect( () => {

//     let x  = new Promise((resolve ,reject)=>{
//       if(addmsg && typeof(addmsg) === 'number')
//       {
//         tostS("Added Successfully")
//       }
//       if(adderror && typeof(adderror) === 'string')
//       {
//         tostE(adderror)
//       }

//       resolve()

//     })
//     x.then(()=>{

//     dispatch(cleanMhData())
   

//     })

  
     
//     }, [addmsg , adderror])


  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChangeimg = event => {

    if(event.target.files[0] && event.target.files.length !==0)
    {
      const fileUploaded = event.target.files[0];
      console.log(fileUploaded)
      setValues({...values , ['image1']:fileUploaded , ['tempImage1']:URL.createObjectURL(fileUploaded)})
    }
    else
    {
      console.log('yahah')
    }

  };
  
  /********* Image Handling   ************** */

  return (
    <Page
      className={classes.root}
      title="Report Generation"
    >
              <ToastContainer    />


              
              <div className={classes.rootn}>
      <AppBar position="static">
        <Tabs value={tvalue} onChange={handleChangetab} aria-label="simple tabs example">
          <Tab label="Logo" {...a11yProps(0)} />
          <Tab label="Screenshots" {...a11yProps(1)} />
          <Tab label="Feature Listing" {...a11yProps(2)} />
          <Tab label="Pricing Plans" {...a11yProps(3)} />
          <Tab label="Testimonials" {...a11yProps(4)} />
          <Tab label="Contact" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tvalue} index={0}>



      <Container maxWidth="lg">

<Grid
  container
  spacing={3}
>
  <Grid
    item
    lg={12}
    md={12}
    xs={12}
  >
    <Card
className={clsx(classes.root2, className)}
{...rest}
>
<CardContent>
<Box
  alignItems="center"
  display="flex"
  flexDirection="column"
>
  <Avatar
    className={classes.avatar}
    src={values.tempImage1 !== '' ?  values.tempImage1 : values.fixImg }
  />
  <Typography
    color="textPrimary"
    gutterBottom
    variant="h3"
  >
    {user.name}
  </Typography>

  <Typography
    className={classes.dateText}
    color="textSecondary"
    variant="body1"
  >
    {`${moment().format('hh:mm A')} ${user.timezone}`}
  </Typography>
</Box>
</CardContent>
<Divider />
<CardActions>
<input
type="file"
ref={hiddenFileInput}
onChange={handleChangeimg}
style={{display: 'none'}} 
/>
<Button
  color="primary"
  fullWidth
  variant="text"
  onClick={handleClick}
>
  Upload Picture
</Button>
</CardActions>
</Card>
  </Grid>
 
</Grid>
</Container>
      </TabPanel>
      <TabPanel value={tvalue} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={tvalue} index={2}>
        Item Three
      </TabPanel>
    </div>             
     
    </Page>
  );
};

export default AddBusiness;
