import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";


import {siteProg} from '../../../Actions/Client/SiteProgress'

import {ClientSiteProgressStartDate, ClientSiteProgressEndDate} from '../../../Actions/ActionCreators'
import {getExportFileBlob} from '../../../Config/excelPdfDownload'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const useStyles3 = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  rootq: {
    width: '100%',
  },
  headingq: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeadingq: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

}));


const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const tableRef = React.createRef();

  
  const [values, setValues] = useState({
   
    start_date:""
  
    });
  
  
  
  
  const start_date = useSelector(state => state.ClientDateFilters && state.ClientDateFilters.SiteProgressStartDate ? state.ClientDateFilters.SiteProgressStartDate:new Date(Date.now()))
  const end_date = useSelector(state => state.ClientDateFilters && state.ClientDateFilters.SiteProgressEndDate ? state.ClientDateFilters.SiteProgressEndDate:new Date(Date.now()))
    

  // const  filterData = (Wdata,s_date, e_date) => {
    
  //   var Fdata = []
  //   if(Array.isArray(Wdata) && Wdata.length){
  //     var jt  = new Promise((resolve , reject)=>{

  //       //         Fdata = Wdata.filter(data => (data.status==="Accepted") && (moment(data.date).format("YYYY.MM.DD")>=moment(s_date).format("YYYY.MM.DD")) && (moment(data.date).format("YYYY.MM.DD")<=moment(e_date).format("YYYY.MM.DD")));
  //       // console.log(Fdata)
  //       Fdata = Wdata
  //             resolve()
  //             })
        
  //             jt.then(()=>{
        
                
  //               for(let i =  0  ; i <  Fdata.length ; i++)
  //               {
        
  //                 Fdata[i]['get_sum'] = getSum(Fdata[i])
        
  //                 if(Fdata[i].manpower_details.length > 1)
  //                 {
  //                     Fdata[i]['man_cat'] = Fdata[i].manpower_details[0].manp_cat + ` ${Fdata[i].manpower_details.length - 1} More`
  //                     Fdata[i]['man_name'] = Fdata[i].manpower_details[0].name + ` ${Fdata[i].manpower_details.length - 1} More`
  //                 }
  //                 else
  //                 {
  //                   Fdata[i]['man_cat'] = Fdata[i].manpower_details[0].manp_cat
  //                   Fdata[i]['man_name'] = Fdata[i].manpower_details[0].name
  //                 }
  //               }
        
  //             })
        
  //             return Fdata
  //   }else{

  //     return Fdata
  //   }
    
  
  // } 
  

//  const listData = useSelector((state) =>
//   state.ClientAllGet && state.ClientAllGet.siteProgData &&
//     state.ClientAllGet.siteProgData.length &&
//     Array.isArray(state.ClientAllGet.siteProgData)
//       ? filterData(state.ClientAllGet.siteProgData, start_date, end_date)
//       : filterData([], start_date, end_date)
//   );


  const sub_admin_id =  useSelector((state) => state.login.data.sub_admin_id)
  const site_id =  useSelector((state) => state.login.data.site_id)


  const loading  = useSelector((state)=> state.ClientAllGet && state.ClientAllGet.loading)

  const listData = useSelector((state) =>
  state.ClientAllGet && state.ClientAllGet.siteProgData.data &&
  Object.keys(state.ClientAllGet.siteProgData).length &&
    Array.isArray(state.ClientAllGet.siteProgData.data)
      ? state.ClientAllGet.siteProgData.data
      : []
  );

  


const countD  =  useSelector((state) => state.ClientAllGet && 
state.ClientAllGet.siteProgData.data &&
Array.isArray(state.ClientAllGet.siteProgData.data) ? 
parseInt(state.ClientAllGet.siteProgData.total_length) : 0 )


const countF  =  useSelector((state) => state.ClientAllGet && 
state.ClientAllGet.siteProgData.data &&
Array.isArray(state.ClientAllGet.siteProgData.data) ? 
parseInt(state.ClientAllGet.siteProgData.page) : 0 )


const [pagination , setPagination] = useState({
  per_page : 5,
  page : countF,

})
  const handleStartDateChange = (date) => {
    var t = {
      sub_admin_id : sub_admin_id,
                        page : pagination.page, 
                        per_page :pagination.per_page,  
                         start_date : date, 
                         end_date : moment( end_date ).add(1,'d')._d, 
                         site_id:site_id
                                                               
                       }
    // filterData(listData, date, end_date)
    dispatch(siteProg(t))

    //  const DataList = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.delivery_date).format("DD.MM.YYYY")>=moment(date).format("DD.MM.YYYY")) && (moment(data.delivery_date).format("DD.MM.YYYY")<=moment(values.end_date).format("DD.MM.YYYY")));
   dispatch(ClientSiteProgressStartDate(date))
   setValues({
     ...values,
     ['start_date']:date
   })
   };
    
   const handleEndDateChange = (date) => {
    var t = {
      sub_admin_id : sub_admin_id,
                        page : pagination.page, 
                        per_page :pagination.per_page,  
                         start_date : values.start_date, 
                         end_date : moment( date ).add(1,'d')._d, 
                         site_id:site_id
                                                               
                       }
      dispatch(siteProg(t))
    // filterData(listData, start_date, date)
  
    //  const DataList = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.delivery_date).format("DD.MM.YYYY")>=moment(values.from_date).format("DD.MM.YYYY")) && (moment(data.delivery_date).format("DD.MM.YYYY")<=moment(date).format("DD.MM.YYYY")));
   dispatch(ClientSiteProgressEndDate(date))
     
   
   };
     
   
  
useEffect( () => {
  var t = {
    sub_admin_id : sub_admin_id,
                      page : pagination.page, 
                      per_page :pagination.per_page,  
                       start_date : values.start_date, 
                       end_date : moment( end_date ).add(1,'d')._d, 
                       site_id:site_id
                                                             
                     }
    dispatch(siteProg(t))




    
  }, [ ])


  const navigate = useNavigate();






// const getSum  = (data) =>{
//   console.log('teet')


//  var t  =  data.mat_details.reduce((x  ,   y  , z)=>{
//     console.log(x)
//     console.log(y)
//     if(x.amt ===  null  || x.amt === undefined || x.amt === "")
//     {
//       x = {amt : 0 } 
//       if(y.amt ===  null  || y.amt === undefined || y.amt === "")
//       {
//         return   x = {amt : x.amt  + 0} 
//       }
//       else
//       {
//         return    x = {amt : x.amt  + y.amt} 
//       }
//     }
//     else
//     {
//       if(y.amt ===  null  || y.amt === undefined || y.amt === "")
//       {
//         return   x = {amt : x.amt  + 0} 
//       }
//       else
//       {
//       return  x = {amt : x.amt  + y.amt} 
//       }
//     }
  
   
//   })

//   console.log(t)
//   console.log(typeof(t.amt))
//   return t.amt
// }


const handleSingleView = (oldata) =>{
  navigate('/app/sitereport/' + oldata.tableData.id , {state:oldata})

}
  

const exportField = [
  
  {
    field : "wp_id" , 
    val : "Progress ID"
  },
  {
    field : "site_name" , 
    val : "Site Name"
  },
  {
    field : "progress_name" , 
    val : "Progress Name"
  },
  {
    field : "remark" , 
    val : "Remark"
  },   
  {
    field : "status" , 
    val : "Status"
  },
  {
    field : "wp_date" , 
    val : "Date"
  },
]

 
  

const handlePageChange = (e , x) =>{
  
  
   
     var u   = new Promise((resolve ,reject)=>{
       
       if(x !== null  && x !== undefined )
       {
         dispatch(siteProg(
          {
         sub_admin_id : sub_admin_id  ,
         page : e, 
         per_page :pagination.per_page,
         start_date : values.start_date, 
         end_date : moment( end_date ).add(1,'d')._d, 
         site_id:site_id
       
       }
       ))
       }
       
       resolve()
       
     })
     u.then(()=>{
       
       setPagination({...pagination , ['page']  : e})
     })
   
   }
  
  
   
   
   const handleRPP = (e) =>{
     console.log("handleRPP")
     console.log(e)
   
     var u   = new Promise((resolve ,reject)=>{
  
      
     if(!loading){
  
    
      
         dispatch(siteProg(
           {
          sub_admin_id : sub_admin_id  ,
          page : pagination.page, 
          per_page :e ,
          start_date : values.start_date, 
          end_date : moment( end_date ).add(1,'d')._d, 
          site_id:site_id
        
        }
        ))
  
      }
         
       
       
       resolve()
       
     })
     u.then(()=>{
       
       setPagination({...pagination , ['per_page']  : e})
     })
   
   }
    
  return (
    <>
      <Page className={classes.root} title="Material Delivery Report">
      <ToastContainer    />
        <Container maxWidth={false}>
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              <Button 
                color="primary" 
                variant="contained"
                className={classes.exportButton} 
                onClick = {
                  () => getExportFileBlob(exportField,listData, 'xlsx', 'SiteProgress')
                }>
                  Export to Excel
              </Button>
              <Button 
                color="primary" 
                variant="contained"
                className={classes.exportButton} 
                onClick = {
                  () => getExportFileBlob(exportField,listData, 'pdf', 'SiteProgress')
                }>
                  Export to PDF
              </Button>
            </Box>
            <div className={classes.root3}>
            <Grid container spacing={3}>
              <Grid item  md={6} lg={3}  xs={3}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                  <KeyboardDatePicker
                      fullWidth                                        
                      id="date-picker-dialog"
                      label="From Date"
                      InputLabelProps={{shrink:true}}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      required={false}
                      value={values.start_date}
                      maxDate={end_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={handleStartDateChange}
                      inputVariant="outlined"
                      variant="outlined"
                      // borderColor="red"
                      invalidDateMessage=""
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
              <Grid item  md={6} lg={3}  xs={3}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      style={{ border: "1px  black" }}
                      id="date-picker-dialog"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={values.start_date}
                      value={end_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={(date)=>{handleEndDateChange(date)}}
                      inputVariant="outlined"
                      variant="outlined"
                      borderColor="red"              
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
            

            </Grid>
          </div>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>
            <MaterialTable
      icons={tableIcons}     
      tableRef={tableRef}    
      title="Site Progress"
      columns={[
        { title: 'Work Progress Name', field: 'progress_name',editable: "never", },     
        { title: 'Date', field: 'wp_date',editable: "never", render: rowData => (
            moment(rowData.wp_date).format("DD/MM/YYYY")
          ),},     
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
      
              <Grid item xs={4}>
                <VisibilityIcon onClick={() => {handleSingleView(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
            </Grid>
          )}

      ]}
      
      data={listData}
              options={{
                
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                emptyRowsWhenPaging:false,  
              }}

    components={{
        Pagination: props => (
                     <TablePagination
                     {...props}
                     
                count={countD}
                page={countF}
                
                />
                ),
              }}
              
              onChangeRowsPerPage={(e)=>handleRPP(e)}
              onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}             
    
    />
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
