import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
import {getExportFileBlob} from '../../../Config/excelPdfDownload'



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplyAllIcon from '@material-ui/icons/ReplyOutlined'
import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,  
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
  AppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {addSubTick, getSubTick,getAllTick, delSubTick, delTicket} from '../../../Actions/SubAdminActions/Tickets'
import {cleanSubTick} from '../../../Actions/ActionCreators'





const  TabPanel = (props)=> {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}










const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  
  const a_id = useSelector((state)=> state.login && state.login.data && state.login.data.r_subadmin ? state.login.data.r_subadmin : "")
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.help ? state.login.data.role_permit.help : []);



  const [tvalue, setTValue] = React.useState(0);

  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
  };

  const [open2, setOpen2] = useState({show:false,data:[]});


  const handleClose2 = () =>{

    setOpen2({...open2, show:false, data:[]});
  }
  
  const handleOpen2 = (data) =>{
  
    setOpen2({
      ...open2,
      show:true,
      data:data
    });
  
  }

  const [open, setOpen] = useState({show:false, id:"", all:false});


  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.ticket_id,
      all:false
    });
  
  }
  
  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delSubTick(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }
  
  const handleOpenTick = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.t_id,
      all:true
    });
  
  }
  
  const handleConfirmTick = () =>{
    if(open.id!==""){
      
      dispatch(delTicket(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""        
      })
    }
  }
  

  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const listData = useSelector((state) =>
  state.Sub_Tickets
  && state.Sub_Tickets.getData 
  && state.Sub_Tickets.getData.length &&
    Array.isArray(state.Sub_Tickets.getData)
      ? state.Sub_Tickets.getData
      : []
  );

  const listAllData = useSelector((state) =>
  state.Sub_Tickets
  && state.Sub_Tickets.allData 
  && state.Sub_Tickets.allData.length &&
    Array.isArray(state.Sub_Tickets.allData)
      ? state.Sub_Tickets.allData
      : []
  );

  
    const navigate = useNavigate();
  
 
  const Delmsg = useSelector(state => state.Sub_Tickets && state.Sub_Tickets.delData && state.Sub_Tickets.delData.message ? state.Sub_Tickets.delData.message : null  )
  const Delerror = useSelector(state => state.Sub_Tickets && state.Sub_Tickets.delError && state.Sub_Tickets.delError.message ? state.Sub_Tickets.delError.message : null  )

  
  useEffect( () => {

    dispatch(getSubTick(a_id))
    dispatch(getAllTick(a_id))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanSubTick())
    dispatch(getSubTick(a_id))
    dispatch(getAllTick(a_id))

    })


    
  }, [ Delmsg  ,Delerror ])


//   

const onEdit = (olData) =>{
  navigate('/app/help_edit/'+olData.ticket_id , { state: olData })
}


// const onDel = (oldata) =>{

//   dispatch(delSubTick(oldata.ticket_id ,oldata.tableData.id ))
// }
 





const exportField = [
  
  {
    field : "ticket_id" , 
    val : "Ticket ID"
  },
  {
    field : "issue" , 
    val : "Issue Name"
  },
  {
    field : "raised_by" , 
    val : "Created By"
  },
  {
    field : "message" , 
    val : "Description"
  },
  {
    field : "priority" , 
    val : "Priority"
  },
  {
    field : "status" , 
    val : "Status"
  }, 
  
]




const exportAllField = [
  
  {
    field : "t_id" , 
    val : "Ticket ID"
  },
  {
    field : "title" , 
    val : "Issue Name"
  },  
  {
    field : "detail" , 
    val : "Description"
  },
  {
    field : "priority" , 
    val : "Priority"
  },
  {
    field : "status" , 
    val : "Status"
  }, 
  
]



  


  const addNewBornbutton = () =>{
    navigate('/app/list_help_add')
    // console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Help Tickets">
      <ToastContainer    />




      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Help Ticket??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={() => {open.all? handleConfirmTick() :  handleConfirm()}} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog disableBackdropClick={true} open={open2.show} onClose={handleClose2} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Reply</DialogTitle>
          <DialogContent>
            
              {Object.keys(open2.data) && Object.keys(open2.data).length ?
              
              <Typography variant="body">
              {open2.data.sub_admin_reply!==null && open2.data.sub_admin_reply!==undefined && open2.data.sub_admin_reply!=="" ? 
              <>
                <Typography>
                Reply : {open2.data.sub_admin_reply}

                </Typography>
                <Typography>
                Resolved Date : {moment(open2.data.resolved_date).format("DD.MM.YYYY")}

                </Typography>
                
              </>
              
              :
              "Reply is Not Received Yet!!"
              }
              
              
              </Typography>
              :
              <></>
               }
            

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2} color="primary" variant="outlined">
              Cancel
            </Button>
            
          </DialogActions>
        </Dialog>

      <div>
      <AppBar position="static">
        <Tabs value={tvalue} onChange={handleChangetab} variant="fullWidth" textColor="inherit" aria-label="full width tabs example">
          <Tab label="Raised By Others" {...a11yProps(0)} />
          <Tab label="Raised By You" {...a11yProps(1)} />
          
        </Tabs>
      </AppBar>



      <TabPanel value={tvalue} index={0}>
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
          
        <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'help')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'help')
              }>
                Export to PDF
            </Button>

            <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Raise Ticket
              </Button>
          </Box> 
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Help Tickets"
      columns={[
       
        { title: 'Sr. No.',render : rowData =>(
          <>
          {rowData.tableData.id +1}
          </>
        ) },
        { title: 'Issue', field: 'issue',editable: "never", },     
        { title: 'Priority', field: 'priority',editable: "never", },     
        { title: 'Description', field: 'message',editable: "never", },     
        { title: 'Status', field: 'status',editable: "never", },     
        { title: 'Created By', field: 'raised_by',editable: "never", },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>

      <Grid item xs={4}>
      <Tooltip title="Reply">
        {rowData.status==="Pending" ? 
        <ReplyAllIcon  onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
        :<></>
        }
        
      </Tooltip>

      </Grid>
      {role_permit[2] ? 
      <Grid item xs={4}>
      <Tooltip title="Delete">
        <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
        
      </Tooltip>
      </Grid>
      
      :<></>
      }
      
      </Grid>
          ),
        },
     

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </TabPanel>
      <TabPanel value={tvalue} index={1}>
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
          
        <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportAllField,listAllData, 'xlsx', 'help')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportAllField,listAllData, 'pdf', 'help')
              }>
                Export to PDF
            </Button>

            <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Raise Ticket
              </Button>
          </Box> 
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Help Tickets"
      columns={[
       
        { title: 'Sr. No.',render : rowData =>(
          <>
          {rowData.tableData.id +1}
          </>
        ) },
        { title: 'Title', field: 'title',editable: "never", },     
        { title: 'Priority', field: 'priority',editable: "never", },     
        { title: 'Detail', field: 'detail',editable: "never", },     
        { title: 'Status', field: 'status',editable: "never", },            
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>

              <Grid item xs={4}>
              <Tooltip title="View Reply">
                
                <ReplyAllIcon  onClick={()=>{handleOpen2(rowData)}} style={{cursor:"pointer"}} />
                
              </Tooltip>

              </Grid>
              
              <Grid item xs={4}>
              <Tooltip title="Delete">
              <DeleteIcon onClick={()=>{handleOpenTick(rowData)}} style={{cursor:"pointer"}} />
                
              </Tooltip>
              </Grid>
              
            </Grid>
          ),
        },
     

      ]}
      data={listAllData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </TabPanel>
        </div>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
