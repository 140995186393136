import React, { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import PropTypes from "prop-types";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import { useNavigate } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Card,
  CardContent,
  Divider,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
} from "@material-ui/core";
import Page from "../../../../src/components/Page";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getSites } from "../../../Actions/SubAdminActions/M_AddSites";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";
import { ToastContainer } from "react-toastify";
import { tostS, tostE } from "../../../Config/Toast";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const NewPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useSelector((state) => state.login.data.r_subadmin);
  const selected_site = useSelector((state) =>
    state.SelectedSite &&
    state.SelectedSite.sitedata &&
    state.SelectedSite.sitedata.site
      ? state.SelectedSite.sitedata.site
      : null
  );
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [boqReports, setBoqReports] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [values, setValues] = useState({
    sub_admin_id: id,
    site_id: selected_site !== null ? selected_site : "",
  });

  const [sites, setSites] = useState([]);
  const [billData, setBillData] = useState(null); // State variable to store bill data
  const [totalAmount, setTotalAmount] = useState(0); // State variable to store total amount

  const SiteData = useSelector((state) =>
    state.AddSites &&
    state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );

  const handleChangeSiteName = (event) => {
    setValues({ ...values, site_id: event.target.value });
  };

  useEffect(() => {
    dispatch(getSites(id));
    fetchBoqReports();
  }, [selected_site]);

  const fetchBoqReports = (subAdminId) => {
    axios
      .get(`${Base_URL}/subadmin/getBoqReports`, {
        params: {
          sub_admin_id: subAdminId,
          site_id: selected_site,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data) {
          setBoqReports(response.data);
        } else {
          tostE("Failed to fetch BOQ reports");
        }
      })
      .catch((err) => {
        tostE("Failed to fetch BOQ reports");
        console.error(err);
      });
  };

  const handleViewReportDetails = (report) => {
    console.log(report); // Accessing report_id directly
    navigate(`/app/ViewReportDetails/${report.report_id}`, { state: report });
  };
  
  const handleGenerateBill = () => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    const requestData = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      siteId: values.site_id,
    };

    // Reset billData state
    setBillData(null);

    axios
      .post(`${Base_URL}/subadmin/generateMbBill`, requestData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          if (response.data.measurementData.length > 0) {
            setBillData(response.data.measurementData); // Update bill data in state
            calculateTotalAmount(response.data.measurementData); // Calculate total amount
            tostS("Bill generated successfully");
          } else {
            tostE("No Data to generate bill");
          }
        } else {
          tostE("Failed to generate bill");
        }
      })
      .catch((err) => {
        tostE("No Data to generate bill ");
      });
  };

  const calculateTotalAmount = (data) => {
    let total = 0;
    data.forEach((row) => {
      total += row.quantity * row.unit_price;
    });
    setTotalAmount(total);
    console.log(total);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(6, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const handleExportExcel = () => {
    if (!billData || billData.length === 0) {
      tostE("No data available for export");
      console.log("No data available for export");
      return;
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(billData); // Use billData instead of boqData
    XLSX.utils.book_append_sheet(workbook, worksheet, "Bill Data");
    XLSX.writeFile(workbook, "Bill_Data.xlsx");
  };

  const handleExportPDF = () => {
    if (!billData || billData.length === 0) {
      tostE("No data available for export");
      return;
    }

    // Create new jsPDF instance
    const doc = new jsPDF();
    // Define table headers
    const headers = [
      ["Item Description", "Executed Quantity", "Unit Price", "Amount"]
    ];

    // Extract data from billData
    const data = billData.map((row) => [
      row.item_description,
      row.quantity,
      row.unit_price,
      (row.quantity * row.unit_price).toFixed(2), // Calculate amount per row and fix to 2 decimals
    ]);

    // Add total row to data
    const totalRow = ["Total", "", "", totalAmount.toFixed(2)]; // Total row data
    data.push(totalRow);

    // Set table options
    const options = {
      startY: 30, // Starting Y position for table (adjusted for date text)
      theme: 'grid',
      styles: {
        cellPadding: 4,
        fontSize: 10,
        halign: 'center',
        valign: 'middle'
      },
      columnStyles: {
        3: { halign: 'right' } // Right align the total amount column
      },
      foot: [[
        {content: "Total", colSpan: 3, styles: {halign: 'right'}},
        totalAmount.toFixed(2)
      ]],
    };

    // Add table to PDF
    doc.autoTable({
      head: headers,
      body: data,
      ...options
    });

    // Save PDF
    doc.save("measurement_bill.pdf");
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const [tvalue, setTValue] = React.useState(0);

  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
  };

  return (
    <Page className={classes.root} title="Generate Bill">
      <ToastContainer />

      <AppBar position="static">
        <Tabs
          value={tvalue}
          onChange={handleChangetab}
          variant="fullWidth"
          textColor="inherit"
          aria-label="full width tabs example"
        >
          <Tab label="Generate Bill" {...a11yProps(0)} />
          <Tab label="History" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={tvalue} index={0}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          margin="normal"
                          id="start-date-picker"
                          label="Start Date"
                          format="MM/dd/yyyy"
                          value={startDate}
                          onChange={setStartDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          margin="normal"
                          id="end-date-picker"
                          label="End Date"
                          format="MM/dd/yyyy"
                          value={endDate}
                          onChange={setEndDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="site-select-label">
                          Site Name
                        </InputLabel>
                        <Select
                          labelId="site-select-label"
                          id="site-select"
                          onChange={handleChangeSiteName}
                          label="Site Name"
                          value={values.site_id}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {SiteData.map((data, i) => (
                            <MenuItem key={i} value={data.site_id}>
                              {data.site_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    onClick={handleGenerateBill}
                    color="primary"
                    variant="contained"
                  >
                    Generate Bill
                  </Button>
                </Box>
              </Card>
            </Grid>
            {billData && (
              <Grid item lg={12} md={12} xs={12}>
                <Card>
                  <CardContent>
                    <TableContainer component={Paper}>
                      <Table aria-label="bill table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Measurement ID</TableCell>
                            <TableCell>Item Description</TableCell>

                            <TableCell>Executed Quantity</TableCell>
                            <TableCell>unit</TableCell>
                            <TableCell>Unit Price</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Remark</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {billData && billData.length > 0 ? (
                            billData.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {row.item_description || "-"}
                                </TableCell>

                                <TableCell>{row.quantity || "-"}</TableCell>
                                <TableCell>{row.unit || "-"}</TableCell>
                                <TableCell>{row.unit_price || "-"}</TableCell>
                                <TableCell>
                                  {row.quantity && row.unit_price
                                    ? (row.quantity * row.unit_price).toFixed(2)
                                    : "-"}
                                </TableCell>
                                <TableCell>{row.remark || "-"}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={10} align="center">
                                No Data
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      {billData && (
                        <>
                          <Button
                            onClick={handleExportExcel}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px" }}
                          >
                            Export to Excel
                          </Button>
                          <Button
                            onClick={handleExportPDF}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px" }}
                          >
                            Export to PDF
                          </Button>
                        </>
                      )}

                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        style={{ padding: "10px" }}
                      >
                        <Typography variant="h6">
                          Total Amount: {totalAmount}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
      </TabPanel>

      <TabPanel value={tvalue} index={1}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Bills Generated Till date</Typography>
                  <TableContainer component={Paper}>
                    <Table aria-label="boqreports table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Total Amount</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {boqReports.map((report, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {moment(report.date).format("MMM DD, YYYY")}
                            </TableCell>
                            <TableCell>{report.total_bill}</TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                  handleViewReportDetails(report)
                                }
                              >
                                View Details
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </TabPanel>
    </Page>
  );
};

export default NewPage;
