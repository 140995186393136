import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import tableIcons from '../../../Config/IconsFile'


import {getTickets, delTickets} from '../../../Actions/SuperAdminActions/Tickets'

import {cleanSuperTick} from '../../../Actions/ActionCreators'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {getExportFileBlob} from '../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  

  const [values, setValues] = useState({
   
    country:"",

  
    });



const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };




  const listData = useSelector((state) =>
  state.Tickets && state.Tickets.getData &&
    state.Tickets.getData.length &&
    Array.isArray(state.Tickets.getData)
      ? state.Tickets.getData
      : []
  );





  
 
  const DelMsg = useSelector(state => state.Tickets && state.Tickets.delData && state.Tickets.delData.message ? state.Tickets.delData.message : null  )
  const DelError = useSelector(state => state.Tickets && state.Tickets.delError && state.Tickets.delError.message ? state.Tickets.delError.message : null  )

 
  const editMsg = useSelector(state => state.Tickets && state.Tickets.editData && state.Tickets.editData.message ? state.Tickets.editData.message : null  )
  const editError = useSelector(state => state.Tickets && state.Tickets.editError && state.Tickets.editError.message ? state.Tickets.editError.message : null  )






  

  
useEffect( () => {
  dispatch(getTickets())

    let x  = new Promise((resolve ,reject)=>{
      if(DelMsg && typeof(DelMsg) === 'string')
      {
        tostS(DelMsg)
      }
      if(DelError && typeof(DelError) === 'string')
      {
          tostE(DelError)
      }
      if(editMsg && typeof(editMsg) === 'string')
      {
        tostS("Resolved SuccessFully")
      }
      if(editError && typeof(editError) === 'string')
      {
          tostE(editError)
      }

      resolve()

    })
    x.then(()=>{

      dispatch(cleanSuperTick())
      dispatch(getTickets())

    })


    
  }, [ DelMsg, DelError, editError, editMsg ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  
  navigate('/app/tickets/'+olData.t_id , { state: olData })
}


const onDel = (oldata) =>{

  dispatch(delTickets(oldata.t_id ,oldata.tableData.id ))
}
 


const exportField = [
  {
    field : "t_id" , 
    val : "Ticket ID"
  }, 
  {
    field : "title" , 
    val : "Title"
  }, 
  {
    field : "detail" , 
    val : "Details"
  }, 
  {
    field : "raised_by" , 
    val : "Raised By"
  }, 
  {
    field : "status", 
    val : "Status"
  }, 
  
]




  const addNewBornbutton = () =>{
    navigate('/app/gstadd')
    // console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Tickets List">
      <ToastContainer    />
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              {/* <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Place Order
              </Button> */}
              <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Tickets')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Tickets')
              }>
                Export to PDF
            </Button>
            </Box>
            {/* <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  xs={4}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
</ThemeProvider>

        </Grid>
        <Grid item xs={4}>
 
          <FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
        <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.country}
          onChange={handleChanged}
          label="Export As"
          style={{width:"100%" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 2" onClick = {() => exportToExcel([{field : "t_id",val:"Ticket Id" } ,{field : "title",val : "Title" } , {field : "detail",val : "Details"} , {field : "raise_by",val : "Raised By" } , {field : "status",val : "Status" }] , listData)}>Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem>


        </Select>
      </FormControl>
        </Grid>
        <Grid item xs={4} style={{justifyContent:"center"}}>
         
        </Grid>
      </Grid>
    </div> */}
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Tickets List"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'SR. No.', render : rowData =>(
          <>
          {rowData.tableData.id +1}
          </>
        ) },
        { title: 'Title', field: 'title',editable: "never", },
        { title: 'Details', field: 'detail',editable: "never", },
        { title: 'Raised By', field: 'raise_by',editable: "never", },
        { title: 'Status', field: 'status',editable: "never", },
        
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
      
      
      <Grid item xs={4}>
      <ReplyAllIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      <Grid item xs={4}>
        <DeleteIcon onClick={()=>{onDel(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
