import {ADD_PANEL_USER_LOADING , ADD_PANEL_USER_SUCCESS , ADD_PANEL_USER_FAILURE } from '../../Actions/Types';
import {GET_PANEL_USER_LOADING , GET_PANEL_USER_SUCCESS , GET_PANEL_USER_FAILURE } from '../../Actions/Types';
import {DEL_PANEL_USER_LOADING , DEL_PANEL_USER_SUCCESS , DEL_PANEL_USER_FAILURE } from '../../Actions/Types';

import {CLEAN_ALL_DATA , CLEAN_PANEL_USER_DATA} from '../../Actions/Types';





const AddPanelUser = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{}
            
        }

    
    case ADD_PANEL_USER_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case ADD_PANEL_USER_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case ADD_PANEL_USER_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }

    case DEL_PANEL_USER_LOADING:
        return {
            ...state,
            loading:true,
            delError : {},
            delData : [{}]            
        }
    case DEL_PANEL_USER_SUCCESS:
        return {
            ...state,
            loading:false,
            delError:{},
            delData:action.data
        }
    case DEL_PANEL_USER_FAILURE:
        return {
            ...state,
            loading:false,
            delError:action.error,
            delData:{},
        }

        case CLEAN_PANEL_USER_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getError:{},
                delData : {}
                
            }
    

        case GET_PANEL_USER_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                
            }
        case GET_PANEL_USER_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data
            }
        case GET_PANEL_USER_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                data:{},
            }   


    default:
        return state

}
}



export default AddPanelUser;
