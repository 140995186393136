import {EDIT_PROFILE_MAN_CRE_ADMIN_LOADING , EDIT_PROFILE_MAN_CRE_ADMIN_SUCCESS , EDIT_PROFILE_MAN_CRE_ADMIN_FAILURE} from '../../Actions/Types'; 
import {EDIT_PROFILE_MAN_PASS_ADMIN_LOADING , EDIT_PROFILE_MAN_PASS_ADMIN_SUCCESS , EDIT_PROFILE_MAN_PASS_ADMIN_FAILURE} from '../../Actions/Types'; 
import {EDIT_PROFILE_MAN_PIC_ADMIN_LOADING , EDIT_PROFILE_MAN_PIC_ADMIN_SUCCESS , EDIT_PROFILE_MAN_PIC_ADMIN_FAILURE} from '../../Actions/Types'; 
import {EDIT_SUB_LOGO_LOADING , EDIT_SUB_LOGO_SUCCESS , EDIT_SUB_LOGO_FAILURE} from '../../Actions/Types'; 
import {GET_PROFILE_MAN_CRE_ADMIN_LOADING , GET_PROFILE_MAN_CRE_ADMIN_SUCCESS , GET_PROFILE_MAN_CRE_ADMIN_FAILURE , CLEAN_PROFILE_MAN_CRE_ADMIN_DATA} from '../../Actions/Types'; 


import { CLEAN_ALL_DATA } from '../../Actions/Types';



const AddSubProfile = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {},
            logoData  : [{}] ,
            logoError: {}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                error:{},
                getData:[{}],
                getError:{},
                editData :[{}],
                editError : {},
                delData  : [{}] ,
                delError: {},
                
            }
    
        
        

        case GET_PROFILE_MAN_CRE_ADMIN_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                getData : [{}],
                
            }
        case GET_PROFILE_MAN_CRE_ADMIN_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
                
            }
        case GET_PROFILE_MAN_CRE_ADMIN_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        
                    
        case EDIT_PROFILE_MAN_CRE_ADMIN_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}]
                
            }
        case EDIT_PROFILE_MAN_CRE_ADMIN_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_PROFILE_MAN_CRE_ADMIN_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            
        case EDIT_PROFILE_MAN_PASS_ADMIN_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}]
                
            }
        case EDIT_PROFILE_MAN_PASS_ADMIN_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_PROFILE_MAN_PASS_ADMIN_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            
            case EDIT_PROFILE_MAN_PIC_ADMIN_LOADING:
                return {
                    ...state,
                    loading:true,
                    editError: {},
                    editData : [{}]
                    
                }
            case EDIT_PROFILE_MAN_PIC_ADMIN_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    editError:{},
                    editData:action.data
                }
            case EDIT_PROFILE_MAN_PIC_ADMIN_FAILURE:
                return {
                    ...state,
                    loading:false,
                    editError:action.error,
                    editData:{},
                }
            case EDIT_SUB_LOGO_LOADING:
                return {
                    ...state,
                    loading:true,
                    editError: {},
                    editData : [{}]
                    
                }
            case EDIT_SUB_LOGO_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    editError:{},
                    editData:action.data
                }
            case EDIT_SUB_LOGO_FAILURE:
                return {
                    ...state,
                    loading:false,
                    editError:action.error,
                    editData:{},
                }

            case CLEAN_PROFILE_MAN_CRE_ADMIN_DATA:
                return {
                    ...state,
                    editData : {},
                    // getData : [{}],
                    getError : {},
                    editError : {},
                    
                }

        default:
        return state
    
    }
    }
    
    
    
export default AddSubProfile;
    