import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate, matchPath } from 'react-router-dom';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,  
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Tool as ToolIcon,
  Radio as RadioIcon,
  Bookmark as BIcon,
  Navigation as NIcon
} from 'react-feather';
import NavItem from './NavItem';
import {useDispatch, useSelector} from 'react-redux'
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import CardMedia from '@material-ui/core/CardMedia';

import {logout} from '../../../Actions/Logout'
import {cleanAllData} from '../../../Actions/ActionCreators'
//-------------- ICON -----------------
import Home from "@material-ui/icons/Home";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import StoreIcon from '@material-ui/icons/Store';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import ScoreIcon from '@material-ui/icons/Score';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CachedIcon from '@material-ui/icons/Cached';
import LanguageIcon from '@material-ui/icons/Language';
import MoneyIcon from '@material-ui/icons/Money';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonIcon from '@material-ui/icons/Person';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import RedeemIcon from '@material-ui/icons/Redeem';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import SettingsIcon from '@material-ui/icons/Settings';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import InputIcon from '@material-ui/icons/Input';
import StarIcon from '@material-ui/icons/Star';
import BusinessIcon from '@material-ui/icons/Business';
import { ControlPoint } from '@material-ui/icons';
const user = {
  avatar: '/static/images/thekedar-logo.svg',
  jobTitle: 'Thekedar Admin',
  // name: 'Katarina Smith'
};


const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 120,
    height:100,    
    objectFit:"contain",
    textAlign:"center"
  },
  root: {
    width: '100%',
    textAlign:"left",
    justifyContent:"left",
    border: 0
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  sideLeft:{
    marginLeft:15,
    marginTop:0
  },
  icon: {
  fill: "orange",
}
}));


const Accordion = withStyles({
  root: {
    border: '0px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)' : {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);




const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState('');
  console.log(onMobileClose, openMobile)
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // const role = useSelector(state => state.login.data)
  const role = useSelector(state => state.login.data.role)
  const role_permit = useSelector(state => state.login.data.role_permit)
  const loginName = useSelector(state => state.login.data.name)
  const loginImage = useSelector(state => state.login.data && state.login.data.image && state.login.data.image.length ?state.login.data.image[0]:"")

  

  const uemail = useSelector(state => state.login.data.email)
  
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);


  const  handleSubmit = () =>{
    let x = new Promise((resolve , reject)=>{
      
     dispatch(cleanAllData())
    console.log("test")
    
    resolve()  
    })
    x.then(()=>{
    
      dispatch(logout(uemail)) 
    })
  }
  
  
  const handleSettings = () =>{
    if(window.location.href.includes('master'))
    {
      navigate('/app/dashboard')

    }
    else
    {
      navigate('/app/master/users_list')
    }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={loginImage}
          to="/app/dashboard"
        />
        
       
        
        <Typography
          color="textSecondary"
          variant="body2"
        >
          <br/>
          {loginName}
        </Typography>

        {openMobile ?
        <Box>
          <IconButton onClick={handleSubmit}  color="inherit">
            <InputIcon />
          </IconButton>
          {(role === "Subadmin") && (role_permit.master && role_permit.master.length && Array.isArray(role_permit.master)) && (role_permit.master[0]===true||role_permit.master[1]===true||role_permit.master[2]===true||role_permit.master[3]===true||role_permit.master[4]===true)?
          <IconButton onClick={handleSettings}  color="inherit">
            {! window.location.href.includes('/master') ?
              <SettingsIcon/>
              :
              <ReplyAllIcon/>
            }
          </IconButton>
          : null}

        </Box>
        :<></>
        }
      </Box>
      <Divider />
      <Box>


        <List>
       
         
 {/* Sub Admin routes */}

{role === "Subadmin" && ! window.location.href.includes('/master') ? 
    <div>

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={Home}  href="/app/dashboard"  key="Dashboard" title="Dashboard"/>
          </Typography>
        </AccordionSummary>
          </Accordion>

          {(role_permit.site_boq && role_permit.site_boq.length && Array.isArray(role_permit.site_boq)) && (role_permit.site_boq[0]===true||role_permit.site_boq[1]===true||role_permit.site_boq[2]===true||role_permit.site_boq[3]===true||role_permit.site_boq[4]===true)? 
      <Accordion square expanded={expanded === 'panel77'} onChange={handleChange('panel77')}>
          <AccordionSummary aria-controls="panel77d-content" id="panel77d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" style={{fontWeight:"bold"}}><ControlPoint/> BOQ</Typography>
          </AccordionSummary>
          <AccordionDetails style={{marginLeft:10}}>
            <Typography>
              <NavItem active={match} icon={""} href="/app/boq" title="BOQ"   />
              <br/>
              <NavItem active={match} icon={""}href="/app/generate_bill_mb" title="Smart bill"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/View_MB" title="Digital Measurement book" />
              <br/>
             
                            
            </Typography>
          </AccordionDetails>
        </Accordion>
        :<></>
      }
 {(role_permit.site_draw && role_permit.site_draw.length && Array.isArray(role_permit.site_draw)) && (role_permit.site_draw[0]===true||role_permit.site_draw[1]===true||role_permit.site_draw[2]===true||role_permit.site_draw[3]===true||role_permit.site_draw[4]===true)? 
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <Typography>

            <NavItem active={match} icon={DomainDisabledIcon} href="/app/site_drawings"   title="Site Drawings" />
            </Typography>
          </AccordionSummary>
        </Accordion>
      :<></>
      }
      {(role_permit.procurement && role_permit.procurement.length && Array.isArray(role_permit.procurement)) && (role_permit.procurement[0]===true||role_permit.procurement[1]===true||role_permit.procurement[2]===true||role_permit.procurement[3]===true||role_permit.procurement[4]===true)? 
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />} >
            <Typography style={{fontWeight:"bold"}}><MoneyIcon/> Procurement</Typography>
          </AccordionSummary>
          <AccordionDetails style={{marginLeft:10}}>
            <Typography>
                  <NavItem active={match}  icon={FormatIndentIncreaseIcon} href="/app/materialindentlist"   title="Material Indent"   />
                  <br />
                  <NavItem active={match}  icon={FormatIndentIncreaseIcon} href="/app/machineindentlist"   title="Machine Indent"   />
                  <br/>
                  <NavItem active={match} icon={StoreIcon} href="/app/purchaseorderlist"   title="Purchase Orders"   />
            </Typography>
          </AccordionDetails>
        </Accordion>
      :<></>
      }


      {(role_permit.site_report && role_permit.site_report.length && Array.isArray(role_permit.site_report)) && (role_permit.site_report[0]===true||role_permit.site_report[1]===true||role_permit.site_report[2]===true||role_permit.site_report[3]===true||role_permit.site_report[4]===true)? 
        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography style={{fontWeight:"bold"}}><LanguageIcon/> Site Report</Typography>
          </AccordionSummary>
          <AccordionDetails style={{marginLeft:10}}>
            <Typography>
              <NavItem active={match} icon={FormatColorFillIcon} href="/app/sitemaster_material"   title="Material Delivery"   />
              <br/>
              <NavItem active={match} icon={ScoreIcon} href="/app/sitemaster_mat_supply"   title="Material Supply"   />
              <br/>
              <NavItem active={match} icon={SupervisedUserCircleIcon} href="/app/sitemaster_manpower"   title="Manpower Report"   />
              <br/>
              <NavItem active={match} icon={LocalPharmacyIcon} href="/app/sitemaster_machine"   title="Machine Report"   />
              <br/>
              <NavItem active={match} icon={ScoreIcon} href="/app/sitemaster_expense"   title="Site Expense"   />
              <br/>
              <NavItem active={match} icon={CheckBoxIcon} href="/app/sitemaster_q&s"   title="Safety & Quality"   />
              <br/>
              <NavItem active={match} icon={CachedIcon} href="/app/sitemaster_progress"   title="Site Progress"   />
              <br/>
              <NavItem active={match} icon={StarIcon} href="/app/Test_Reports"   title="Site Test Reports"   />
            </Typography>
          </AccordionDetails>
        </Accordion>
      :<></>
      }
      {(role_permit.inventory && role_permit.inventory.length && Array.isArray(role_permit.inventory)) && (role_permit.inventory[0]===true||role_permit.inventory[1]===true||role_permit.inventory[2]===true||role_permit.inventory[3]===true||role_permit.inventory[4]===true)? 
      <Accordion square expanded={expanded === 'panel91'} onChange={handleChange('panel91')}>
          <AccordionSummary aria-controls="panel91d-content" id="panel91d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" style={{fontWeight:"bold"}}><BusinessIcon/> Inventory</Typography>
          </AccordionSummary>
          <AccordionDetails style={{marginLeft:10}}>
            <Typography>
              <NavItem active={match} icon={""} href="/app/inventory/dashboard" title="Dashboard"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/inventory/delivery" title="Delivery"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/inventory/accepted-delivery" title="Accepted Delivery" />
              <br/>
              <NavItem active={match} icon={""} href="/app/inventory/transfer" title="Transfer" />
              <br/>
              <NavItem active={match} icon={""} href="/app/inventory/grn" title="GRN"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/inventory/history" title="History"   />
                            
            </Typography>
          </AccordionDetails>
        </Accordion>
        :<></>
      }
      {(role_permit.report_gen && role_permit.report_gen.length && Array.isArray(role_permit.report_gen)) && (role_permit.report_gen[0]===true||role_permit.report_gen[1]===true||role_permit.report_gen[2]===true||role_permit.report_gen[3]===true||role_permit.report_gen[4]===true)? 
      <Accordion square expanded={expanded === 'panel30'} onChange={handleChange('panel30')}>
          <AccordionSummary aria-controls="panel30d-content" id="panel30d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" style={{fontWeight:"bold"}}><BusinessIcon/> Reports</Typography>
          </AccordionSummary>
          <AccordionDetails style={{marginLeft:10}}>
            <Typography>
              <NavItem active={match} icon={""} href="/app/report_gen" title="Report Generation"   />
              <br/>
              <NavItem active={match} icon={""}href="/app/View_Daily_Report" title="Daily Report"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/generate_Monthly_Report" title="Monthly Report" />
              <br/>
             
                            
            </Typography>
          </AccordionDetails>
        </Accordion>
        :<></>
      }

      {/* {(role_permit.report_gen && role_permit.report_gen.length && Array.isArray(role_permit.report_gen)) && (role_permit.report_gen[0]===true||role_permit.report_gen[1]===true||role_permit.report_gen[2]===true||role_permit.report_gen[3]===true||role_permit.report_gen[4]===true)? 
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <Typography>        
              <NavItem active={match} icon={AssessmentIcon} href="/app/report_gen"   title="Report Generation" />
            </Typography>
          </AccordionSummary>
        </Accordion>
      :<></>
      } */}
      {(role_permit.profile && role_permit.profile.length && Array.isArray(role_permit.profile)) && (role_permit.profile[0]===true||role_permit.profile[1]===true||role_permit.profile[2]===true||role_permit.profile[3]===true||role_permit.profile[4]===true)? 
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <Typography>

            <NavItem active={match} icon={AccountBoxIcon} href="/app/profile_management"   title="Profile Management" />
            </Typography>
          </AccordionSummary>
        </Accordion>
      :<></>
      }
     

{/* {(role_permit.site_boq && role_permit.site_boq.length && Array.isArray(role_permit.site_boq)) && (role_permit.site_boq[0]===true||role_permit.site_boq[1]===true||role_permit.site_boq[2]===true||role_permit.site_boq[3]===true||role_permit.site_boq[4]===true)? 
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <Typography>

            <NavItem active={match} icon={ControlPoint} href="/app/boq"   title="BOQ" />
            </Typography>
          </AccordionSummary>
        </Accordion>
      :<></>
      } */}

{/* {(role_permit.site_boq && role_permit.site_boq.length && Array.isArray(role_permit.site_boq)) && (role_permit.site_boq[0]===true||role_permit.site_boq[1]===true||role_permit.site_boq[2]===true||role_permit.site_boq[3]===true||role_permit.site_boq[4]===true)? 
      <Accordion square expanded={expanded === 'panel77'} onChange={handleChange('panel77')}>
          <AccordionSummary aria-controls="panel77d-content" id="panel77d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" style={{fontWeight:"bold"}}><ControlPoint/> BOQ</Typography>
          </AccordionSummary>
          <AccordionDetails style={{marginLeft:10}}>
            <Typography>
              <NavItem active={match} icon={""} href="/app/boq" title="BOQ"   />
              <br/>
              <NavItem active={match} icon={""}href="/app/generate_bill_mb" title="Smart bill"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/View_MB" title="Digital Measurement book" />
              <br/>
             
                            
            </Typography>
          </AccordionDetails>
        </Accordion>
        :<></>
      } */}
      {/* {(role_permit.site_draw && role_permit.site_draw.length && Array.isArray(role_permit.site_draw)) && (role_permit.site_draw[0]===true||role_permit.site_draw[1]===true||role_permit.site_draw[2]===true||role_permit.site_draw[3]===true||role_permit.site_draw[4]===true)? 
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <Typography>

            <NavItem active={match} icon={AssessmentIcon} href="/app/generate_Daily_Report"   title="Daily Report" />
            </Typography>
          </AccordionSummary>
        </Accordion>
      :<></>
      } */}
      {(role_permit.help && role_permit.help.length && Array.isArray(role_permit.help)) && (role_permit.help[0]===true||role_permit.help[1]===true||role_permit.help[2]===true||role_permit.help[3]===true||role_permit.help[4]===true)? 
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <Typography>

            <NavItem active={match} icon={HelpOutlineIcon}  href="/app/list_help"   title="Help" />
            </Typography>
          </AccordionSummary>
        </Accordion>
      :<></>
      }
      {(role_permit.faq && role_permit.faq.length && Array.isArray(role_permit.faq)) && (role_permit.faq[0]===true||role_permit.faq[1]===true||role_permit.faq[2]===true||role_permit.faq[3]===true||role_permit.faq[4]===true)? 
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <Typography>

            <NavItem active={match} icon={LiveHelpIcon} href="/app/faq_list"   title="FAQ" />
            </Typography>
          </AccordionSummary>
        </Accordion>
      :<></>
      }         
    </div>

    :
    role === "Subadmin" && window.location.href.includes("/master")  ? 
        <div>        
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />} >
          <Typography  style={{fontSize:"13px"}}><GroupAddIcon/> User Management</Typography>
        </AccordionSummary>
        <AccordionDetails style={{marginLeft:10}}>
          <Typography>
                <NavItem active={match} icon={PlayCircleOutlineIcon}  href="/app/master/role_list"   title="Role"   />
                <br/>
                <NavItem active={match} icon={PermIdentityIcon} href="/app/master/users_list"   title="User"   />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" >
          <Typography>

          <NavItem active={match} icon={ConfirmationNumberIcon} href="/app/master/site_list"   title="Site Master " />
          </Typography>
        </AccordionSummary>
          </Accordion>

          <Accordion>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" >
          <Typography>

          <NavItem active={match} icon={EmojiTransportationIcon} href="/app/master/vendor_list"   title="Vendor Master " />
          </Typography>
        </AccordionSummary>
          </Accordion>


          {/* <Accordion>
          <AccordionSummary aria-controls="panel12d-content" id="panel2d-header" >
          <Typography>

          <NavItem active={match}  href="/app/master/test"   title="Test" />
          </Typography>
        </AccordionSummary>
          </Accordion> */}


          


          <Accordion>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" >
          <Typography>

          <NavItem active={match} icon={PeopleAltIcon} href="/app/master/app_user_list" title="App User Management" />
          </Typography>
        </AccordionSummary>
          </Accordion>

          <Accordion>
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" >
          <Typography>

          <NavItem active={match} icon={FormatUnderlinedIcon} href="/app/master/unit_list"   title="Unit Master"   />
          </Typography>
        </AccordionSummary>
          </Accordion>
          <Accordion>
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" >
          <Typography>
          
          <NavItem active={match} icon={SettingsEthernetIcon}  href="/app/master/expense_list"   title="Site Expense Master"   />
          </Typography>
        </AccordionSummary>
          </Accordion>
          <Accordion>
          <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" >
          <Typography>

          <NavItem active={match} icon={CheckBoxIcon}  href="/app/master/issue_list"   title="Qual. & Safety Master"   />
          </Typography>
        </AccordionSummary>
          </Accordion>

          {/* <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" expandIcon={<ExpandMoreIcon />} >
          <Typography><NaturePeopleIcon/>Resource Master</Typography>
        </AccordionSummary>
        <AccordionDetails style={{marginLeft:10}}>
          <Typography>
                
                <br/>                                
                
                <br/>
                
          </Typography>
        </AccordionDetails>
      </Accordion> */}

      <Accordion square expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary aria-controls="panel11d-content" id="panel11d-header" expandIcon={<ExpandMoreIcon />} >
          <Typography><LocalShippingIcon/> Machine Master</Typography>
        </AccordionSummary>
        <AccordionDetails style={{marginLeft:10}}>
          <Typography>
                <NavItem active={match} icon={RedeemIcon}  href="/app/master/machine_cat_list"   title="Machine Category Master"   />
                <br/>
                <NavItem active={match} icon={LocalShippingIcon}  href="/app/master/machine_list"   title="Machine"   />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />} >
          <Typography><DomainDisabledIcon/> Material Master</Typography>
        </AccordionSummary>
        <AccordionDetails style={{marginLeft:10}}>
          <Typography>
                <NavItem active={match} icon={RedeemIcon}  href="/app/master/mat_category_list"   title="Material Category Master"   />
                <br/>
                <NavItem active={match} icon={ConfirmationNumberIcon}  href="/app/master/material_list"   title="Material"   />
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion square expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />} >
          <Typography  style={{fontSize:"13px"}}><SupervisedUserCircleIcon/> Manpower Master</Typography>
        </AccordionSummary>
        <AccordionDetails style={{marginLeft:10}}>
          <Typography>
                <NavItem active={match} icon={RedeemIcon}  href="/app/master/manpower_cat_list"   title="Manpower Category Master"   />
                <br/>
                <NavItem active={match} icon={SupervisedUserCircleIcon}  href="master/manpower_list"   title="Manpower"   />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion square expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />} >
          <Typography><DragIndicatorIcon/> Drawing Master</Typography>
        </AccordionSummary>
        <AccordionDetails style={{marginLeft:10}}>
          <Typography>
                <NavItem active={match} icon={DragIndicatorIcon}  href="/app/master/draw_cat_list"   title="Drawing Category"   />
                <br/>
                <NavItem active={match} icon={DragIndicatorIcon}  href="/app/master/draw_type_list"   title="Drawing Type"   />
                <br/>
                <NavItem active={match} icon={DragIndicatorIcon}  href="/app/master/floor_type_list"   title="Floor Type"   />
          </Typography>
          
        </AccordionDetails>
      </Accordion>
      
    </div>

    :

  role === 'Superadmin' ?
  
  <>
  
  <div>

  {(role_permit.dashboard  && role_permit.dashboard.length && Array.isArray(role_permit.dashboard) ) 
          &&
        
       ( role_permit.dashboard[0] === true  
        || role_permit.dashboard[1] === true
        || role_permit.dashboard[2] === true
        || role_permit.dashboard[3] === true
        || role_permit.dashboard[4] === true )
        ?

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={Home} href="/app/dashboard"  key="Dashboard" title="Dashboard"  />
          </Typography>
        </AccordionSummary>
          </Accordion>
  
  :
  <></>
  }
        
        {(role_permit.order_management  && role_permit.order_management.length && Array.isArray(role_permit.order_management) ) 
          &&
        
       ( role_permit.order_management[0] === true  
        || role_permit.order_management[1] === true
        || role_permit.order_management[2] === true
        || role_permit.order_management[3] === true
        || role_permit.order_management[4] === true )
        ?

      <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={TransferWithinAStationIcon} href="/app/orders"   title="Order Management" />
          </Typography>
        </AccordionSummary>
          </Accordion>


:
  <></>
  }



{(role_permit.role_master  && role_permit.role_master.length && Array.isArray(role_permit.role_master) ) 
          &&
        
       ( role_permit.role_master[0] === true  
        || role_permit.role_master[1] === true
        || role_permit.role_master[2] === true
        || role_permit.role_master[3] === true
        || role_permit.role_master[4] === true )
        ?
          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={ControlPointDuplicateIcon} href="/app/roles"   title="Role Master" />
          </Typography>
        </AccordionSummary>
          </Accordion>

:
  <></>
  }


{(role_permit.admin_panel_user  && role_permit.admin_panel_user.length && Array.isArray(role_permit.admin_panel_user) ) 
          &&
        
       ( role_permit.admin_panel_user[0] === true  
        || role_permit.admin_panel_user[1] === true
        || role_permit.admin_panel_user[2] === true
        || role_permit.admin_panel_user[3] === true
        || role_permit.admin_panel_user[4] === true )
        ?

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={SupervisorAccountIcon} href="/app/admin_panel_users"   title="Admin Panel User" />
          </Typography>
        </AccordionSummary>
          </Accordion>
:
<></>
}

{(role_permit.plan_management  && role_permit.plan_management.length && Array.isArray(role_permit.plan_management) ) 
          &&
        
       ( role_permit.plan_management[0] === true  
        || role_permit.plan_management[1] === true
        || role_permit.plan_management[2] === true
        || role_permit.plan_management[3] === true
        || role_permit.plan_management[4] === true )
        ?

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={TransferWithinAStationIcon} href="/app/plans"   title="Plan Management" />
          </Typography>
        </AccordionSummary>
          </Accordion>

:
<></>
}

{(role_permit.api_management  && role_permit.api_management.length && Array.isArray(role_permit.api_management) ) 
          &&
        
       ( role_permit.api_management[0] === true  
        || role_permit.api_management[1] === true
        || role_permit.api_management[2] === true
        || role_permit.api_management[3] === true
        || role_permit.api_management[4] === true )
        ?


          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={TransferWithinAStationIcon} href="/app/api"   title="API Management" />
          </Typography>
        </AccordionSummary>
          </Accordion>

:
<></>
}


{(role_permit.web_management  && role_permit.web_management.length && Array.isArray(role_permit.web_management) ) 
          &&
        
       ( role_permit.web_management[0] === true  
        || role_permit.web_management[1] === true
        || role_permit.web_management[2] === true
        || role_permit.web_management[3] === true
        || role_permit.web_management[4] === true )
        ?
          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={TransferWithinAStationIcon} href="/app/website"   title="Website Management" />
          </Typography>
        </AccordionSummary>
          </Accordion>
:
<></>
}


{(role_permit.inquiry_management  && role_permit.inquiry_management.length && Array.isArray(role_permit.inquiry_management) ) 
          &&
        
       ( role_permit.inquiry_management[0] === true  
        || role_permit.inquiry_management[1] === true
        || role_permit.inquiry_management[2] === true
        || role_permit.inquiry_management[3] === true
        || role_permit.inquiry_management[4] === true )
        ?
          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={TransferWithinAStationIcon} href="/app/enquiries"   title="Enquiry Management" />
          </Typography>
        </AccordionSummary>
          </Accordion>

:
<></>
}

{(role_permit.tickets_management  && role_permit.tickets_management.length && Array.isArray(role_permit.tickets_management) ) 
          &&
        
       ( role_permit.tickets_management[0] === true  
        || role_permit.tickets_management[1] === true
        || role_permit.tickets_management[2] === true
        || role_permit.tickets_management[3] === true
        || role_permit.tickets_management[4] === true )
        ?

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={TransferWithinAStationIcon} href="/app/tickets"   title="Tickets Management" />
          </Typography>
        </AccordionSummary>
          </Accordion>

:
<></>
}


{(role_permit.gst_management  && role_permit.gst_management.length && Array.isArray(role_permit.gst_management) ) 
          &&
        
       ( role_permit.gst_management[0] === true  
        || role_permit.gst_management[1] === true
        || role_permit.gst_management[2] === true
        || role_permit.gst_management[3] === true
        || role_permit.gst_management[4] === true )
        ?
          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={AccountBalanceIcon} href="/app/gst"   title="GST Module" />
          </Typography>
        </AccordionSummary>
          </Accordion>

:
<></>
}

{(role_permit.report_generation  && role_permit.report_generation.length && Array.isArray(role_permit.report_generation) ) 
          &&
        
       ( role_permit.report_generation[0] === true  
        || role_permit.report_generation[1] === true
        || role_permit.report_generation[2] === true
        || role_permit.report_generation[3] === true
        || role_permit.report_generation[4] === true )
        ?

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={AssessmentIcon} href="/app/reportgen"   title="Report Generation" />
          </Typography>
        </AccordionSummary>
          </Accordion>
:
<></>
}

{(role_permit.profile  && role_permit.profile.length && Array.isArray(role_permit.profile) ) 
          &&
        
       ( role_permit.profile[0] === true  
        || role_permit.profile[1] === true
        || role_permit.profile[2] === true
        || role_permit.profile[3] === true
        || role_permit.profile[4] === true )
        ?

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={PersonIcon} href="/app/profile"   title="Profile" />
          </Typography>
        </AccordionSummary>
          </Accordion>  
     :
     <></>
     }

    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
        <Typography>

          <NavItem active={match} icon={LiveHelpIcon} href="/app/faqs"   title="FAQ" />
        </Typography>
      </AccordionSummary>
    </Accordion>
    </div>
  
  </>
:
<>
<div>
          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={AddBoxIcon} href="/app/materialdeliveryreport"   title="New Material Delivery" />
          </Typography>
        </AccordionSummary>
          </Accordion>

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={AssessmentIcon} href="/app/machinereport"   title="Machine Report" />
          </Typography>
        </AccordionSummary>
          </Accordion>

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={SupervisedUserCircleIcon} href="/app/manpowerreport"   title="Manpower Report" />
          </Typography>
        </AccordionSummary>
          </Accordion>


          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={LanguageIcon} href="/app/expensereport"   title="Site Expense" />
          </Typography>
        </AccordionSummary>
          </Accordion>


          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={CachedIcon} href="/app/sitereport"   title="Site Progress" />
          </Typography>
        </AccordionSummary>
          </Accordion>
{/* 
          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={ReceiptIcon} href="/app/ClientBoq"   title="BOQ" />
          </Typography>
        </AccordionSummary>
          </Accordion> */}


          
      <Accordion square expanded={expanded === 'panel77'} onChange={handleChange('panel77')}>
          <AccordionSummary aria-controls="panel77d-content" id="panel77d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" style={{fontWeight:"bold"}}><ControlPoint/> BOQ</Typography>
          </AccordionSummary>
          <AccordionDetails style={{marginLeft:10}}>
            <Typography>
              <NavItem active={match} icon={""} href="/app/UploadBoq" title="Upload BOQ"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/ClientBoq" title="View BOQ"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/ClientMb" title="Digital Measurement book" />
              <br/>   
            </Typography>
          </AccordionDetails>
        </Accordion>
    
  
         
      <Accordion square expanded={expanded === 'panel39'} onChange={handleChange('panel39')}>
          <AccordionSummary aria-controls="panel39d-content" id="panel39d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" style={{fontWeight:"bold"}}><BusinessIcon/> Reports</Typography>
          </AccordionSummary>
          <AccordionDetails style={{marginLeft:10}}>
            <Typography>
            
              <NavItem active={match} icon={""}href="/app/ClientDailyReport" title="Daily Report"   />
              <br/>
              <NavItem active={match} icon={""} href="/app/ClientWeeklyReport" title="Monthly Report" />
              <br/>
             
                            
            </Typography>
          </AccordionDetails>
        </Accordion>
       

          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={CheckCircleIcon} href="/app/listticket"   title="Tickets" />
          </Typography>
        </AccordionSummary>
          </Accordion>


          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={DragIndicatorIcon} href="/app/sitedrawings"   title="Site Drawings" />
          </Typography>
        </AccordionSummary>
          </Accordion>


          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={LiveHelpIcon} href="/app/faq"   title="FAQ" />
          </Typography>
        </AccordionSummary>
          </Accordion>


          <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem active={match} icon={PersonIcon} href="/app/profile"   title="Profile" />
          </Typography>
        </AccordionSummary>
          </Accordion>


          {/* <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Typography>

          <NavItem icon={AccessibilityIcon} href="/app/demo"   title="Demo" />
          </Typography>
        </AccordionSummary>
          </Accordion>  */}







</div>
        
</>
  }












        </List>



      </Box>
      <Box flexGrow={1} />

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
