import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {ToastContainer} from 'react-toastify'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const {state} = useLocation();
  
  return (
    <Page className={classes.root} title="Site Progress Detail" >
      <ToastContainer    />
       

      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader="" title="Site Progress Detail" />
                <Divider />
                <CardContent>
                <Grid container spacing={3}>                  
                  <Grid item md={6} xs={12}>
                    <Typography>Work Progress Name :  {state.progress_name}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Site Name :  {state.site_name}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Date :  {moment(state.required_date).format("DD.MM.YYYY")}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
                  
                    <Typography>Location :  
                      {state.lat}, {state.long}                 
                    </Typography>
                  
                   : 
                   <Typography>Site Location: </Typography>
                   }
                    
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Remark :  {state.remark.map(data=>(data))}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Approved By :  {state.accepted_by}</Typography>
                  </Grid>                  
                    <Grid item md={12}>
                    <Typography>Images : {state.images.length ? "" : "Not Available"} </Typography>

                    </Grid>
                  <Grid container spacing={3} >
                    {state.images && state.images.length ? state.images.map(data=>(
                      <Grid item md={4} lg={4} sm={12}>
                        <CardMedia component="img" onClick={()=>{window.open(data)}} image={data} height="140" alt="Image Is Not Available"/>

                      </Grid>
                    )) :
                      <></>
                    }
                      
                    
                  </Grid>                  
                  
                </Grid>

                </CardContent>
                <Divider />
                
              </Card>
            </form>
          </Grid>
        </Grid>
        
      </Container>
    </Page>
  );
};

export default AddBusiness;
