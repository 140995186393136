// import axios from "../Config/Interceptor1";


import axios from '../../Config/Interceptor2'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addStockTransferBegin , addStockTransferSuccess , addStockTransferFailure} from './ActionCreators'
import {getStockTransferBegin , getStockTransferSuccess , getStockTransferFailure} from './ActionCreators'
import {getItemDataBegin , getItemDataSuccess , getItemDataFailure} from './ActionCreators'
import {delStockTransferBegin , delStockTransferSuccess , delStockTransferFailure} from './ActionCreators'
import {editStockTransferBegin , editStockTransferSuccess , editStockTransferFailure} from './ActionCreators';


export const addStockTransfer = (session) => {

    return (dispatch) => {
        dispatch(addStockTransferBegin());

        axios.post(Base_URL + '/inventory/siteTransfer', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addStockTransferSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addStockTransferFailure(err.response.data));

                }
            })
    }
}


export const getStockTransfer = (x) => {

    return (dispatch) => {
        dispatch(getStockTransferBegin());

        axios.get(Base_URL + '/inventory/getTransfer' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getStockTransferSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getStockTransferFailure(err.response.data));

                }
            })
    }
}


export const getItemData = (x) => {

    return (dispatch) => {
        dispatch(getItemDataBegin());

        axios.get(Base_URL + '/inventory/getMaterialName' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getItemDataSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getItemDataFailure(err.response.data));

                }
            })
    }
}



export const delStockTransfer = (x) => {

    return (dispatch) => {
        dispatch(delStockTransferBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delStockTransfer' , {params :{site_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delStockTransferSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delStockTransferFailure(err.response.data));

                }
            })
    }
}

export const editStockTransfer = (session) => {

    return (dispatch) => {
        dispatch(editStockTransferBegin());

        axios.post(Base_URL + '/subAdmin/editStockTransfer', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editStockTransferSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editStockTransferFailure(err.response.data));

                }
            })
    }
}

