import {CLEAN_ALL_DATA} from '../../Actions/Types';
import {MAT_IND_START_DATE, MAT_IND_END_DATE} from '../../Actions/Types'
import { MAC_IND_START_DATE, MAC_IND_END_DATE } from '../../Actions/Types';
import { MAT_DEL_START_DATE, MAT_DEL_END_DATE } from '../../Actions/Types';
import { MAT_SUPP_START_DATE, MAT_SUPP_END_DATE } from '../../Actions/Types';
import { MAN_REPO_START_DATE, MAN_REPO_END_DATE } from '../../Actions/Types';
import { MAC_REPO_START_DATE, MAC_REPO_END_DATE } from '../../Actions/Types';
import { SITE_EXP_START_DATE, SITE_EXP_END_DATE } from '../../Actions/Types';
import { QS_START_DATE, QS_END_DATE } from '../../Actions/Types';
import { SITE_PROGRESS_START_DATE, SITE_PROGRESS_END_DATE } from '../../Actions/Types';

const SubDateFilters = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            MatIndStartDate:new Date(Date.now()),
            MatIndEndDate:new Date(Date.now()),
            MacIndStartDate:new Date(Date.now()),
            MacIndEndDate:new Date(Date.now()),
            MatDelStartDate:new Date(Date.now()),
            MatDelEndDate:new Date(Date.now()),
            MatSuppStartDate:new Date(Date.now()),
            MatSuppEndDate:new Date(Date.now()),
            ManRepoStartDate:new Date(Date.now()),
            ManRepoEndDate:new Date(Date.now()),
            MacRepoStartDate:new Date(Date.now()),
            MacRepoEndDate:new Date(Date.now()),
            SiteExpStartDate:new Date(Date.now()),
            SiteExpEndDate:new Date(Date.now()),
            QsStartDate:new Date(Date.now()),
            QsEndDate:new Date(Date.now()),
            SiteProgressStartDate:new Date(Date.now()),
            SiteProgressEndDate:new Date(Date.now()),


        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,                
                loading:false,  
                MatIndStartDate:new Date(Date.now()),
                MatIndEndDate:new Date(Date.now()), 
                MacIndStartDate:new Date(Date.now()),
                MacIndEndDate:new Date(Date.now()),
                MatDelStartDate:new Date(Date.now()),
                MatDelEndDate:new Date(Date.now()),
                MatSuppStartDate:new Date(Date.now()),
                MatSuppEndDate:new Date(Date.now()),
                ManRepoStartDate:new Date(Date.now()),
                ManRepoEndDate:new Date(Date.now()),
                MacRepoStartDate:new Date(Date.now()),
                MacRepoEndDate:new Date(Date.now()),
                SiteExpStartDate:new Date(Date.now()),
                SiteExpEndDate:new Date(Date.now()),
                QsStartDate:new Date(Date.now()),
                QsEndDate:new Date(Date.now()),
                SiteProgressStartDate:new Date(Date.now()),
                SiteProgressEndDate:new Date(Date.now()),                  
            }                
        case MAT_IND_START_DATE:
            return {
                ...state,                
                loading:false,   
                MatIndStartDate:action.data                         
            }                
        case MAT_IND_END_DATE:
            return {
                ...state,                
                loading:false,   
                MatIndEndDate:action.data                         
            }                
        case MAC_IND_START_DATE:
            return {
                ...state,                
                loading:false,   
                MacIndStartDate:action.data                         
            }                
        case MAC_IND_END_DATE:
            return {
                ...state,                
                loading:false,   
                MacIndEndDate:action.data                         
            }                
        case MAT_DEL_START_DATE:
            return {
                ...state,                
                loading:false,   
                MatDelStartDate:action.data                         
            }                
        case MAT_DEL_END_DATE:
            return {
                ...state,                
                loading:false,   
                MatDelEndDate:action.data                         
            }                
        case MAT_SUPP_START_DATE:
            return {
                ...state,                
                loading:false,   
                MatSuppStartDate:action.data                         
            }                
        case MAT_SUPP_END_DATE:
            return {
                ...state,                
                loading:false,   
                MatSuppEndDate:action.data                         
            }                
        case MAN_REPO_START_DATE:
            return {
                ...state,                
                loading:false,   
                ManRepoStartDate:action.data                         
            }                
        case MAN_REPO_END_DATE:
            return {
                ...state,                
                loading:false,   
                ManRepoEndDate:action.data                         
            }                
        case MAC_REPO_START_DATE:
            return {
                ...state,                
                loading:false,   
                MacRepoStartDate:action.data                         
            }                
        case MAC_REPO_END_DATE:
            return {
                ...state,                
                loading:false,   
                MacRepoEndDate:action.data                         
            }                
        case SITE_EXP_START_DATE:
            return {
                ...state,                
                loading:false,   
                SiteExpStartDate:action.data                         
            }                
        case SITE_EXP_END_DATE:
            return {
                ...state,                
                loading:false,   
                SiteExpEndDate:action.data                         
            }                
        case QS_START_DATE:
            return {
                ...state,                
                loading:false,   
                QsStartDate:action.data                         
            }                
        case QS_END_DATE:
            return {
                ...state,                
                loading:false,   
                QsEndDate:action.data                         
            }                
        case SITE_PROGRESS_START_DATE:
            return {
                ...state,                
                loading:false,   
                SiteProgressStartDate:action.data                         
            }                
        case SITE_PROGRESS_END_DATE:
            return {
                ...state,                
                loading:false,   
                SiteProgressEndDate:action.data                         
            }                

        default:
        return state
    
    }
    }
    
    
    
export default SubDateFilters;
    