import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../../src/components/Page';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { useDispatch, useSelector } from 'react-redux';

import RegisterView from './GenOTP';
import VerifyView from './verifyotp';
import Password from './Password';

import {Genotp} from '../../Actions/resetPassword';
import {cleanResetPassData} from '../../Actions/ActionCreators'
import { tostE, tostS } from '../../Config/Toast';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#E36A26",
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {
    backgroundColor: "theme.palette.background.dark",
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    opacity:0.8,
    elevation:10,
    borderRadius:10
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const ResetPass = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = useState({
    genOTP:true,
    verify:false,
    resetPass:false,
    
  })


  const changeView = (data) =>{
    
    if (data!==null){

      if(data.status!==404){
        
        if(data.status===200 && ('obj' in data) && Object.keys(data.obj).length){
          
          return "resetPass"
        }
        else if(data.status===200 && !('obj' in data)){
          
          return "verify"
        }
  
      }

    }else{
      return "generate"
    }
  }


  const emailVal = useSelector(state => state.ResetPassword && state.ResetPassword.email && state.ResetPassword.email!=="" ? state.ResetPassword.email: null)
  const genStatus = useSelector(state => state.ResetPassword && 
    state.ResetPassword.data && state.ResetPassword.data.status && 
    state.ResetPassword.data.status===200 ? changeView(state.ResetPassword.data) : 
    changeView(null));


    useEffect(() => {
      if (performance.navigation.type === 1) {
        dispatch(cleanResetPassData())
      } else {
        console.log("This page is not reloaded");
      }
      
    }, [])
 

  



  return (
    <>
    {genStatus==="generate" ? 
    <RegisterView />
    :
    genStatus==="verify" ?
    <VerifyView />
    :
    genStatus==="resetPass" ?
    <Password />
    :
    <></>
    }
    </>
  );
};

export default ResetPass;
