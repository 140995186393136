import axios from "../../Config/Interceptor1";


// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {faqBegin , faqSuccess , faqFailure} from '../ActionCreators'


export const faqGet = () => {
   
    return (dispatch) => {
        dispatch(faqBegin());

        axios.get(Base_URL + '/client/getfaq' )
            .then((res) => {
                dispatch(faqSuccess(res.data));
                console.log("data======", res.data)
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    dispatch(faqFailure({}))
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(faqFailure(err.response.data));

                }
            })
    }
}


