import axios from "../../Config/Interceptor2";
import { Base_URL } from "../../Config/BaseUrlConfig";
import { DailyReportBegin, DailyReportFailure, DailyReportSucess, MonthlyReportBegin, MonthlyReportFailure, MonthlyReportSucess } from "../ActionCreators";

export const FetchDailyReports = (requestData) => {
  return (dispatch) => {
    dispatch(DailyReportBegin());

    axios
      .post(`${Base_URL}/subadmin/getDailyReport`, requestData)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.daily_report && response.data.daily_report.length > 0) {
            dispatch(DailyReportSucess(response.data));
          } else {
            // You can dispatch a specific action for empty reports if needed
            dispatch(DailyReportSucess({ daily_report: [] }));
          }
        } else {
          dispatch(DailyReportFailure(response.data));
        }
      })
      .catch((err) => {
        dispatch(DailyReportFailure(err.response ? err.response.data : "Network Error"));
        console.log(err);
      });
  };
};

export const FetchMonthlyReports = (requestData) => {
    return (dispatch) => {
      dispatch(MonthlyReportBegin());
  
      axios
        .post(`${Base_URL}/subadmin/getMonthlyReport`, requestData)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data.daily_report && response.data.daily_report.length > 0) {
              dispatch(MonthlyReportBegin(response.data));
            } else {
              // Dispatch success with an empty daily_report array if no reports are found
              dispatch(MonthlyReportSucess({ ...response.data, daily_report: [] }));
            }
          } else {
            dispatch(MonthlyReportFailure(response.data));
          }
        })
        .catch((err) => {
          dispatch(MonthlyReportFailure(err.response ? err.response.data : "Network Error"));
          console.log(err);
        });
    };
  };



