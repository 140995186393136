// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addVendorBegin , addVendorSuccess , addVendorFailure } from '../ActionCreators';
import {getVendorBegin , getVendorSuccess , getVendorFailure } from '../ActionCreators';
import {editVendorBegin , editVendorSuccess , editVendorFailure } from '../ActionCreators';
import {delVendorBegin , delVendorSuccess , delVendorFailure } from '../ActionCreators';


export const addVendor = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addVendorBegin());

        axios.post(Base_URL + '/subadmin/addVendorMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addVendorSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addVendorFailure(err.response.data));

                }
            })
    }
}


export const getVendor = (x) => {

    return (dispatch) => {
        dispatch(getVendorBegin());

        axios.get(Base_URL + '/subadmin/getVendorMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getVendorSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getVendorFailure(err.response.data));

                }
            })
    }
}

export const delVendor = (x) => {

    return (dispatch) => {
        dispatch(delVendorBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delVendorMaster' , {params :{vd_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delVendorSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delVendorFailure(err.response.data));

                }
            })
    }
}

export const editVendor = (session) => {

    return (dispatch) => {
        dispatch(editVendorBegin());

        axios.post(Base_URL + '/subadmin/editVendorMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editVendorSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editVendorFailure(err.response.data));

                }
            })
    }
}

