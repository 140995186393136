// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addDrawingFloorTypeBegin , addDrawingFloorTypeSuccess , addDrawingFloorTypeFailure } from '../ActionCreators';
import {getDrawingFloorTypeBegin , getDrawingFloorTypeSuccess , getDrawingFloorTypeFailure} from '../ActionCreators';
import {delDrawingFloorTypeBegin , delDrawingFloorTypeSuccess , delDrawingFloorTypeFailure} from '../ActionCreators';
import {editDrawingFloorTypeBegin , editDrawingFloorTypeSuccess , editDrawingFloorTypeFailure} from '../ActionCreators';

export const addDrawingFloorType = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addDrawingFloorTypeBegin());

        axios.post(Base_URL + '/subadmin/addDrawFloorMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addDrawingFloorTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addDrawingFloorTypeFailure(err.response.data));

                }
            })
    }
}


export const getDrawingFloorType = (x) => {

    return (dispatch) => {
        dispatch(getDrawingFloorTypeBegin());

        axios.get(Base_URL + '/subadmin/getDrawFloorMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getDrawingFloorTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getDrawingFloorTypeFailure(err.response.data));

                }
            })
    }
}

export const getClientDrawingFloorType = (x) => {

    return (dispatch) => {
        dispatch(getDrawingFloorTypeBegin());

        axios.get(Base_URL + '/client/getClientDrawFloorMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getDrawingFloorTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getDrawingFloorTypeFailure(err.response.data));

                }
            })
    }
}


export const delDrawingFloorType = (x) => {

    return (dispatch) => {
        dispatch(delDrawingFloorTypeBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delDrawFloorMaster' , {params :{sub_floor_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delDrawingFloorTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delDrawingFloorTypeFailure(err.response.data));

                }
            })
    }
}

export const editDrawingFloorType = (session) => {

    return (dispatch) => {
        dispatch(editDrawingFloorTypeBegin());

        axios.post(Base_URL + '/subadmin/editDrawFloorMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editDrawingFloorTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editDrawingFloorTypeFailure(err.response.data));

                }
            })
    }
}
