import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/CloudDownload';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardMedia,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {siteDraw} from '../../../Actions/Client/SiteDrawings'
import { cleanSiteDrawingData } from "../../../Actions/ActionCreators";
import {getExportFileBlob} from '../../../Config/excelPdfDownload'

import {getClientSites} from '../../../Actions/SubAdminActions/M_AddSites';
import {getClientDrawingFloorType } from '../../../Actions/SubAdminActions/M_DrawingFloorType'
import {getClientDrawType} from '../../../Actions/SubAdminActions/M_DrawType'




const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  rootq: {
    width: '100%',
    marginTop:"15px"
  },
  headingq: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeadingq: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const id = useSelector(state => state.login.data.admin_id);
  const SiteId = useSelector(state => state.login.data.site_id);

  const [values, setValues] = useState({
   
    sub_admin_id :id,
    sub_floor_id:"",
    site_id:SiteId,
    draw_type_id:"",
  
    });
  const handleChangeDrawType = (event) => {
    setValues({...values, ['draw_type_id']:event.target.value});
  };
  const handleChangeFloorType = (event) => {
    setValues({...values, ['sub_floor_id']:event.target.value});
  };
  const handleChangeSiteName = (event) => {
    setValues({...values, ['site_id']:event.target.value});
  };


  // const [listData, setListData] = useState({
  //   data:[]
  // });
  const listData = useSelector((state) =>
  state.ClientAllGet && state.ClientAllGet.siteDrawData &&
    state.ClientAllGet.siteDrawData.length &&
    Array.isArray(state.ClientAllGet.siteDrawData)
      ? (state.ClientAllGet.siteDrawData)
      : []
  );


  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);


  
  const DTypeData = useSelector((state) =>
  state.SubDrawType && state.SubDrawType.getData &&
    state.SubDrawType.getData.length &&
    Array.isArray(state.SubDrawType.getData)
      ? state.SubDrawType.getData
      : []
  );

  const FloorTypeData = useSelector((state) =>
  state.AddDrawingFloorType && state.AddDrawingFloorType.getData &&
    state.AddDrawingFloorType.getData.length &&
    Array.isArray(state.AddDrawingFloorType.getData)
      ? state.AddDrawingFloorType.getData
      : []
  );



  
  const SiteData = useSelector((state) =>
  state.AddSites &&  state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );

  const handleSubmit = () =>{
    console.log(values)
    if(values.sub_floor_id==="" || values.sub_floor_id===null || values.sub_floor_id===undefined){
      tostE("Please Select Floor !!")
    }else if(values.draw_type_id==="" || values.draw_type_id===null || values.draw_type_id===undefined){
      tostE("Please Select drawing Type !!")
    }else if(values.site_id==="" || values.site_id===null || values.site_id===undefined){
      tostE("Please Select Site !!")
    }else{

      dispatch(siteDraw(values))

    }


}
const onDownload = (oldata) =>{

  window.open(oldata.image[0], '_blank');

}
const site_id_list = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.site_ids !== null ? state.login.data.role_permit.site_ids:null)

useEffect(() => {
 console.log(id,'*******************************************************************************************');
  dispatch(getClientDrawType(id))
  dispatch(getClientDrawingFloorType(id))
  dispatch(getClientSites(id, site_id_list))
  dispatch(cleanSiteDrawingData())
},[])

  

  return (
    <>
      <Page className={classes.root} title="Site Drawings">
      <ToastContainer    />
        <Container maxWidth={false}>          
          <Box mt={3} className={clsx(classes.root, className)}>
            <Card className={clsx(classes.root2, className)} {...rest}>
              <CardHeader subheader="Please Select Site, Floor, Drawing Type To View Data"/>
              <Container>
                <Grid spacing={3} container>
                  <Grid item md={3}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Site Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"          
                      onChange={handleChangeSiteName}
                      label="Drawing Type"
                      value={values.site_id}
                      inputProps={{ readOnly: true }}
                    >
                                            
                      {SiteData.map((data , i)=>
                      <MenuItem key={i} value={data.site_id}>{data.site_name}</MenuItem>
                      )}        

                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item md={3}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Drawing Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"          
                      onChange={handleChangeDrawType}
                      label="Drawing Type"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      
                      {DTypeData.map((data , i)=>
                      <MenuItem key={i} value={data.sub_draw_type_id}>{data.draw_type_name}</MenuItem>
                      )}        

                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item md={3}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Floor</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"          
                      onChange={handleChangeFloorType}
                      label="Floor"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      
                      {FloorTypeData.map((data , i)=>
                      <MenuItem key={i} value={data.sub_floor_id}>{data.draw_floor_name}</MenuItem>
                      )}        

                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button onClick={handleSubmit} color="primary" variant="contained">
                      Get Drawings
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <Divider />
              <Grid item md={12} xs={12}>
              <div className={classes.rootq}>
                
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAcc('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                    <Typography variant="h4">Drawings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <>
                      <Grid container spacing={3}>
                      {Array.isArray(listData) && listData.length ?
                      
                        listData.map((data, i) => (
                          <>
                            <Grid item md={6} xs={6} lg={6}>
                              <Typography variant="body">{data.draw_title}</Typography>
                            </Grid>
                            <Grid item md={6} xs={6} lg={6}>
                              <Tooltip arrow title="Download Drawing">
                                <DownloadIcon onClick = {() => {onDownload(data)}} />

                              </Tooltip>
                            </Grid>
                          </>
                        ))
                        :
                        <Grid item md={12} xs={12} lg={12} style={{textAlign:"center"}}>
                          <Typography variant="body">No Drawing Is Available</Typography>

                        </Grid>
                      }
                        </Grid>
                      </>
                    </AccordionDetails>
                  </Accordion>
                
                
              </div>

            </Grid>
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
