
import {GEN_OTP_LOADING , GEN_OTP_SUCCESS , GEN_OTP_FAILURE , CLEAN_RESET_PASS_DATA} from '../Actions/Types';
import {VERIFY_OTP_LOADING , VERIFY_OTP_SUCCESS , VERIFY_OTP_FAILURE} from '../Actions/Types';
import {RESET_PASS_LOADING , RESET_PASS_SUCCESS , RESET_PASS_FAILURE} from '../Actions/Types';

import { OTP_EMAIL } from '../Actions/Types';

const ResetPassword = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            verifyData:{},
            verifyError:{},            
            resetData  : [{}] ,
            resetError: {},
            email:{}
        }
    }

    switch(action.type) {
        
        case CLEAN_RESET_PASS_DATA:
            return {
                ...state,
                loading:false,
                data: [{}],
                error:{},
                verifyData:{},
                verifyError:{},            
                resetData  : [{}] ,
                resetError: {}                
            }
    
        
        case GEN_OTP_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                
            }
        
        case OTP_EMAIL:
            return {
                ...state,
                loading:false,
                email : action.data,
                
            }
        case GEN_OTP_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
            }
        case GEN_OTP_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
            }

        case VERIFY_OTP_LOADING:
            return {
                ...state,
                loading:true,
                verifyError: {},
                data : {},
                
            }
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                loading:false,
                verifyError:{},
                data:action.data,
            }
        case VERIFY_OTP_FAILURE:
            return {
                ...state,
                loading:false,
                verifyError:action.error,
                data:{},
            }   
        
        case RESET_PASS_LOADING:
            return {
                ...state,
                loading:true,
                resetError: {},
                resetData : [{}]
                
            }
        case RESET_PASS_SUCCESS:
            return {
                ...state,
                loading:false,
                resetError:{},
                resetData:action.data,
            }
        case RESET_PASS_FAILURE:
            return {
                ...state,
                loading:false,
                resetError:action.error,
                resetData:{},
            }
        

        default:
        return state
    
    }
    }
    
    
    
export default ResetPassword;
    