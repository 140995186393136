// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addResourceUnitBegin , addResourceUnitSuccess , addResourceUnitFailure } from '../ActionCreators';
import {getResourceUnitBegin , getResourceUnitSuccess , getResourceUnitFailure } from '../ActionCreators';
import {delResourceUnitBegin , delResourceUnitSuccess , delResourceUnitFailure } from '../ActionCreators';
import {editResourceUnitBegin , editResourceUnitSuccess , editResourceUnitFailure } from '../ActionCreators';


export const addResourceUnit = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addResourceUnitBegin());

        axios.post(Base_URL + '/subAdmin/addUnitMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addResourceUnitSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addResourceUnitFailure(err.response.data));

                }
            })
    }
}

export const getResourceUnit = (x) => {

    return (dispatch) => {
        dispatch(getResourceUnitBegin());

        axios.get(Base_URL + '/subAdmin/getUnitMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getResourceUnitSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getResourceUnitFailure(err.response.data));

                }
            })
    }
}

export const delResourceUnit = (x) => {

    return (dispatch) => {
        dispatch(delResourceUnitBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delUnitMaster' , {params :{sub_unit_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delResourceUnitSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delResourceUnitFailure(err.response.data));

                }
            })
    }
}

export const editResourceUnit = (session) => {

    return (dispatch) => {
        dispatch(editResourceUnitBegin());

        axios.post(Base_URL + '/subAdmin/editUnitMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editResourceUnitSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editResourceUnitFailure(err.response.data));

                }
            })
    }
}

