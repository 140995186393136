import axios from "../../Config/Interceptor1";


// import axios from 'axios'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {ClientProfileBegin , ClientProfileSuccess , ClientProfileFailure} from '../ActionCreators'
import {ClientProfilePicBegin , ClientProfilePicSuccess , ClientProfilePicFailure} from '../ActionCreators'
import {ClientGetProfileBegin,ClientGetProfileSuccess,ClientGetProfileFailure} from './../ActionCreators'

export const ClientProfile = (session) => {

    return (dispatch) => {
        dispatch(ClientProfileBegin());

        axios.post(Base_URL + '/client/editAppUsers', session)
            .then((res) => {
                console.log(res.data)
                dispatch(ClientProfileSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")
                    dispatch(ClientProfileFailure({}));

                }
                else {
                    dispatch(ClientProfileFailure(err.response.data));

                }
            })
    }
}


export const ClientProfilePic = (session) => {

    return (dispatch) => {
        dispatch(ClientProfilePicBegin());

        axios.post(Base_URL + '/client/editProfile' , session)
            .then((res) => {
                dispatch(ClientProfilePicSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(ClientProfilePicFailure(err.response.data));

                }
            })
    }
}



export const ClientGetProfile = (x) => {

    return (dispatch) => {
        dispatch(ClientGetProfileBegin());

        axios.get(Base_URL + '/client/getAppUsers' , {params : {app_uid : x}})
            .then((res) => {
                dispatch(ClientGetProfileSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(ClientGetProfileFailure(err.response.data));

                }
            })
    }
}


