// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor3";
import { saveAs } from 'file-saver';

import { Base_URL } from '../../Config/BaseUrlConfig'


import {addClientsBegin , addClientsSuccess , addClientsFailure } from './../ActionCreators'
import {getClientsBegin , getClientsSuccess , getClientsFailure } from './../ActionCreators'
import {editClientsBegin , editClientsSuccess , editClientsFailure } from './../ActionCreators'
import {editClientsPassBegin , editClientsPassSuccess , editClientsPassFailure } from './../ActionCreators'
import {delClientsBegin , delClientsSuccess , delClientsFailure } from './../ActionCreators'
import {getBackupBegin , getBackupSuccess , getBackupFailure } from './../ActionCreators'





export const addClients = (session) => {

    return (dispatch) => {
        dispatch(addClientsBegin());

        axios.post(Base_URL + '/superadmin/regsubadmin', session)
            .then((res) => {
                dispatch(addClientsSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addClientsFailure(err.response.data));

                }
            })
    }
}



export const getClients = (x) => {

    return (dispatch) => {
        dispatch(getClientsBegin());

        axios.get(Base_URL + '/superadmin/getClients', {params:x})
            .then((res) => {
                dispatch(getClientsSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getClientsFailure(err.response.data));

                }
            })
    }
}




export const editClients = (session) => {
    console.log(session);
    return (dispatch) => {
        dispatch(editClientsBegin());

        axios.post(Base_URL + '/superadmin/editRegisterAdmin', session)
            .then((res) => {
                dispatch(editClientsSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editClientsFailure(err.response.data));

                }
            })
    }
}


export const delClients = (x, y) => {

    return (dispatch) => {
        dispatch(delClientsBegin());


        axios.get(Base_URL + '/superadmin/deleteRegisterAdmin' , {params :{admin_id : x, folder_name:y}})
            .then((res) => {
                console.log(res.data)
                dispatch(delClientsSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delClientsFailure(err.response.data));

                }
            })
    }
}
  



export const getBackup = (x , y) => {
    console.log(x,y);

    return (dispatch) => {
        dispatch(getBackupBegin());


        axios.get(Base_URL + '/compress/getzip' , {params :{folder_name : x,data:JSON.stringify(y)},})
            .then((res) => {
                console.log(res)
                dispatch(getBackupSuccess(res.data.backup));

                const zipBlob = new Blob( [new Uint8Array(res.data.data.data)]);
                saveAs(zipBlob, y.name+'.zip');
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getBackupFailure(err.response.data));

                }
            })
    }
}



export const editClientsPass = (session) => {
    console.log(session);
    return (dispatch) => {
        dispatch(editClientsPassBegin());

        axios.post(Base_URL + '/superadmin/editSubAdminPassword', session)
            .then((res) => {
                dispatch(editClientsPassSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editClientsPassFailure(err.response.data));

                }
            })
    }
}






