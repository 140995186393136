// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {getMachineIndentBegin , getMachineIndentSuccess , getMachineIndentFailure} from '../ActionCreators';
import {delMachineIndentBegin , delMachineIndentSuccess ,  delMachineIndentFailure} from '../ActionCreators';
import {acceptMacIndentBegin , acceptMacIndentSuccess ,  acceptMacIndentFailure} from '../ActionCreators';
import {orderMachineIndentBegin , orderMachineIndentSuccess ,  orderMachineIndentFailure} from '../ActionCreators';




export const getMachineIndent = (x) => {
    var parameter = x;
    if(parameter.start_date==="" || parameter.start_date===null || parameter.start_date===undefined){
        parameter.start_date=new Date('2001', '01', '01')
    }
    return (dispatch) => {
        dispatch(getMachineIndentBegin());

        axios.get(Base_URL + '/subadmin/getMachineIndent' , {params : parameter})
            .then((res) => {
                dispatch(getMachineIndentSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getMachineIndentFailure(err.response.data));

                }
            })
    }
}

export const delMachineIndent = (x) => {

    return (dispatch) => {
        dispatch(delMachineIndentBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/deleteMachineIndent' , {params :{mchne_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delMachineIndentSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delMachineIndentFailure(err.response.data));

                }
            })
    }
}



export const orderMachineIndent = (session) => {


    var formData = new FormData();
    formData.append("sub_admin_id",session.sub_admin_id)
    formData.append("mchne_id",session.mchne_id)
    formData.append("machine_details",JSON.stringify(session.machine_details))
    formData.append("message",session.message)
    formData.append("date",session.date)
    formData.append("priority",session.priority)
    formData.append("app_uid",session.app_uid)
    formData.append("vd_id",session.vd_id)
    formData.append("accepted_by",session.accepted_by)
    formData.append("site_id",session.site_id)
    formData.append("lat",session.lat)
    formData.append("long",session.long)
    formData.append("folder_name",session.folder_name)
    formData.append("order_id",session.order_id)
    formData.append("img",session.img)

    
  
    return (dispatch) => {
        dispatch(orderMachineIndentBegin());

        axios.post(Base_URL + '/subAdmin/addMachineReport', formData)
            .then((res) => {
                // console.log(res.data)
                dispatch(orderMachineIndentSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(orderMachineIndentFailure(err.response.data));

                }
            })
    }
}

export const acceptMacIndent = (session) => {

    return (dispatch) => {
        dispatch(acceptMacIndentBegin());

        axios.post(Base_URL + '/subAdmin/editMachineIndent', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(acceptMacIndentSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(acceptMacIndentFailure(err.response.data));

                }
            })
    }
}
