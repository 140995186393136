import {GET_SUB_TICK_LOADING , GET_SUB_TICK_SUCCESS , GET_SUB_TICK_FAILURE } from '../../Actions/Types';
import {GET_ALL_TICK_LOADING , GET_ALL_TICK_SUCCESS , GET_ALL_TICK_FAILURE } from '../../Actions/Types';
import {ADD_SUB_TICK_LOADING , ADD_SUB_TICK_SUCCESS , ADD_SUB_TICK_FAILURE } from '../../Actions/Types';
import {EDIT_SUB_TICK_LOADING , EDIT_SUB_TICK_SUCCESS , EDIT_SUB_TICK_FAILURE } from '../../Actions/Types';
import {DEL_SUB_TICK_LOADING , DEL_SUB_TICK_SUCCESS , DEL_SUB_TICK_FAILURE } from '../../Actions/Types';
import {DEL_TICKET_LOADING , DEL_TICKET_SUCCESS , DEL_TICKET_FAILURE } from '../../Actions/Types';

import {CLEAN_ALL_DATA, CLEAN_SUB_TICK_DATA} from '../../Actions/Types';





const AddTick = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],            
            error:{},
            getData:[{}],
            getError:{},
            allData:[{}],
            allError:{},
            editData:[{}],
            editError:{},
            delData:[{}],
            delError:{}
        }
    }




switch(action.type) {
   case CLEAN_ALL_DATA:
       return {
           ...state,
           loading:false,
           data: [{}],           
           error:{},
           getData:[{}],
           getError:{}
           
       }
   case CLEAN_SUB_TICK_DATA:
      return {
          ...state,
          loading:false,
          data: [{}],          
          error:{},
          editData:[{}],
          editError:{},
          delData:[{}],
          delError:{}

          
      }
 
    case ADD_SUB_TICK_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            data:[{}]
            
        }
 
    case ADD_SUB_TICK_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
 
    case ADD_SUB_TICK_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:[{}],
        } 
  
   case GET_SUB_TICK_LOADING:
       return {
           ...state,
           loading:true,
           error: {},
           
       }

   case GET_SUB_TICK_SUCCESS:
       return {
           ...state,
           loading:false,
           error:{},
           getData:action.data
       }

   case GET_SUB_TICK_FAILURE:
       return {
           ...state,
           loading:false,
           getError:action.error,
           getData:{},
       }   
   case GET_ALL_TICK_LOADING:
       return {
           ...state,
           loading:true,
           allError: {},
           allData:{}
       }

   case GET_ALL_TICK_SUCCESS:
       return {
           ...state,
           loading:false,
           allError:{},
           allData:action.data
       }

   case GET_ALL_TICK_FAILURE:
       return {
           ...state,
           loading:false,
           allError:action.error,
           allData:{},
       }   

    case EDIT_SUB_TICK_LOADING:
        return {
            ...state,
            loading:true,
            editError: {},
            editData:[{}]
            
        }
 
    case EDIT_SUB_TICK_SUCCESS:
        return {
            ...state,
            loading:false,
            editError:{},
            editData:action.data
        }
 
    case EDIT_SUB_TICK_FAILURE:
        return {
            ...state,
            loading:false,
            editError:action.error,
            editData:[{}],
        }   

    case DEL_SUB_TICK_LOADING:
        return {
            ...state,
            loading:true,
            delError: {},
            delData:[{}]
            
        }
    
    case DEL_SUB_TICK_SUCCESS:
        return {
            ...state,
            loading:false,
            delError:{},
            delData:action.data
        }
    
    case DEL_SUB_TICK_FAILURE:
        return {
            ...state,
            loading:false,
            delError:action.error,
            delData:[{}],
        }   
 
       
    case DEL_TICKET_LOADING:
        return {
            ...state,
            loading:true,
            delError: {},
            delData:[{}]
            
        }
    
    case DEL_TICKET_SUCCESS:
        return {
            ...state,
            loading:false,
            delError:{},
            delData:action.data
        }
    
    case DEL_TICKET_FAILURE:
        return {
            ...state,
            loading:false,
            delError:action.error,
            delData:[{}],
        }   
 
       


    default:
        return state

}
}



export default AddTick;
