import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";

import {getEnq} from '../../../Actions/SuperAdminActions/Enquiry'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  




  const listData = useSelector((state) =>
  state.Enquiry && state.Enquiry.getData &&
    state.Enquiry.getData.length &&
    Array.isArray(state.Enquiry.getData)
      ? state.Enquiry.getData
      : []
  );





  
 
  const Delmsg = useSelector(state => state.AddBusiness && state.AddBusiness.deldata && state.AddBusiness.deldata.message ? state.AddBusiness.deldata.message : null  )
  const Delerror = useSelector(state => state.AddBusiness && state.AddBusiness.delerror && state.AddBusiness.delerror.message ? state.AddBusiness.delerror.message : null  )






  

  
useEffect( () => {

    dispatch(getEnq())


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    // dispatch(cleanBusinessData())
    // dispatch(getBusiness())

    })


    
  }, [ Delmsg  ,Delerror ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
//   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
}


const onDel = (oldata) =>{

//   dispatch(DelBusiness(oldata.bp_id ,oldata.tableData.id ))
}
 







  


  const addNewBornbutton = () =>{
    navigate('/app/gstadd')
    console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Enquiries List">
      <ToastContainer    />
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              {/* <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Place Order
              </Button> */}
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Enquiry List"
      columns={[
        { title: 'Enquiry ID', field: 'e_id',editable: "never", },
        { title: 'Enquiry Title', field: 'title',editable: "never", },
        { title: 'Enquiry Details', field: 'detail',editable: "never", },
        { title: 'Enquiry By', field: 'enq_by',editable: "never", },
        { title: 'Email', field: 'email',editable: "never", },
        { title: 'Mobile', field: 'mobile',editable: "never", },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
      {/* <Grid item xs={4}>
        
      <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
      </Grid> */}
      <Grid item xs={4}>
        <DeleteIcon onClick={()=>{onDel(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      {/* <Grid item xs={4}>
        <VisibilityIcon />
      </Grid> */}
      </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
