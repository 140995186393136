import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../../src/layouts/DashboardLayout';
import DashboardView from '../../../src/views/reports/DashboardView/index';
import DashboardView2 from '../../../src/Screens/SubAdmin/Dasboard/index';
import ProductListView from '../../../src/views/product/ProductListView';
import SettingsView from '../../../src/views/settings/SettingsView';



import MaterialIndentList from '../../Screens/SubAdmin/Procurement/MaterialIndent/List'
import MaterialIndentAccept from '../../Screens/SubAdmin/Procurement/MaterialIndent/Accept'
import MachineIndentList from '../../Screens/SubAdmin/Procurement/MachineIndent/List'
import MachineIndentAccept from '../../Screens/SubAdmin/Procurement/MachineIndent/Accept'
import PurchaseOrderList from '../../Screens/SubAdmin/Procurement/PurchaseOrders/List'
import PurchaseOrderAdd from '../../Screens/SubAdmin/Procurement/PurchaseOrders/Add'
import SiteMaster_Material from '../../Screens/SubAdmin/SiteReport/Material/List'
import SiteMaster_Material_Detail from '../../Screens/SubAdmin/SiteReport/Material/Detail'
import SiteMaster_Material_Supply from '../../Screens/SubAdmin/SiteReport/MaterialSupply/List'
import SiteMaster_Material_Supply_Detail from '../../Screens/SubAdmin/SiteReport/MaterialSupply/Detail'
import SiteMaster_Manpower from '../../Screens/SubAdmin/SiteReport/Manpower/List'
import SiteMaster_Manpower_Detail from '../../Screens/SubAdmin/SiteReport/Manpower/Detail'
import SiteMaster_Machine from '../../Screens/SubAdmin/SiteReport/Machine/List'
import SiteMaster_Machine_Detail from '../../Screens/SubAdmin/SiteReport/Machine/Detail'
import SiteMaster_Expense from '../../Screens/SubAdmin/SiteReport/SiteExpense/List'
import SiteMaster_QS from '../../Screens/SubAdmin/SiteReport/QualitySafety/List'
import SiteMaster_Progress from '../../Screens/SubAdmin/SiteReport/SiteProgress/List'
import Report_Gen from '../../Screens/SubAdmin/ReportGeneration/Add'
import Profile_Management from '../../Screens/SubAdmin/ProfileManagement/Add'
import Site_Drawings from '../../Screens/SubAdmin/SiteDrawings/List'
import Site_Drawings_Add from '../../Screens/SubAdmin/SiteDrawings/Add'
import Site_Drawings_Edit from '../../Screens/SubAdmin/SiteDrawings/Edit'
import Help_Add from '../../Screens/SubAdmin/Help/Add'
import Help_Edit from '../../Screens/SubAdmin/Help/Edit'
import Help_List from '../../Screens/SubAdmin/Help/List'
import FAQ from '../../Screens/SubAdmin/FAQ/List'





//Master Routes
import M_User_Add from '../../Screens/SubAdmin/Master/User/Add'
import M_User_List from '../../Screens/SubAdmin/Master/User/List'
import M_User_Edit from '../../Screens/SubAdmin/Master/User/Edit'
import M_Site_List from '../../Screens/SubAdmin/Master/SiteMaster/List'
import M_Site_Add from '../../Screens/SubAdmin/Master/SiteMaster/Add'
import M_Site_Edit from '../../Screens/SubAdmin/Master/SiteMaster/Edit'
import M_App_User_Add from '../../Screens/SubAdmin/Master/AppUserManagement/Add'
import M_App_User_List from '../../Screens/SubAdmin/Master/AppUserManagement/List'
import M_App_User_Edit from '../../Screens/SubAdmin/Master/AppUserManagement/Edit'
import M_Unit_List from '../../Screens/SubAdmin/Master/UnitMaster/List'
import M_Unit_Add from '../../Screens/SubAdmin/Master/UnitMaster/Add'
import M_Mat_Category_List from '../../Screens/SubAdmin/Master/MaterialMaster/CategoryMaster/List'
import M_Mat_Category_Add from '../../Screens/SubAdmin/Master/MaterialMaster/CategoryMaster/Add'

import M_Machine_List from '../../Screens/SubAdmin/Master/MachineMaster/Machine/List'
import M_Mach_Category_List from '../../Screens/SubAdmin/Master/MachineMaster/CategoryMaster/List'
import M_Issue_List from '../../Screens/SubAdmin/Master/Quality&SafetyMaster/List'
import M_Issue_Add from '../../Screens/SubAdmin/Master/Quality&SafetyMaster/Add'
import M_Expense_Add from '../../Screens/SubAdmin/Master/SiteExpenseMaster/Add'
import M_Expense_List from '../../Screens/SubAdmin/Master/SiteExpenseMaster/List'

import M_Material_List from '../../Screens/SubAdmin/Master/MaterialMaster/Material/List'
import M_Drawing_Cat_List from '../../Screens/SubAdmin/Master/DrawingMaster/List'
import M_Drawing_Type_List from '../../Screens/SubAdmin/Master/DrawingType/List'
import M_Floor_Type_List from '../../Screens/SubAdmin/Master/FloorType/List'
import M_Drawing_Cat_Add from '../../Screens/SubAdmin/Master/DrawingMaster/Add'
import M_Manpower_Cat_Add from '../../Screens/SubAdmin/Master/ManpowerMaster/CategoryMaster/Add'
import M_Manpower_Cat_List from '../../Screens/SubAdmin/Master/ManpowerMaster/CategoryMaster/List'
import M_Manpower_List from '../../Screens/SubAdmin/Master/ManpowerMaster/Manpower/List'

import Roles_List from '../../Screens/SubAdmin/Master/Role/List'
import Roles_List_Add from '../../Screens/SubAdmin/Master/Role/Add'
import Role_Edit from '../../Screens/SubAdmin/Master/Role/Edit'
import Vendor_List from '../../Screens/SubAdmin/Master/VendorMaster/List'
import Vendor_List_Add from '../../Screens/SubAdmin/Master/VendorMaster/Add'
import Vendor_List_Edit from '../../Screens/SubAdmin/Master/VendorMaster/Edit'
import TestY from '../../Screens/SubAdmin/Master/VendorMaster/test'

import { useRoutes } from 'react-router-dom';

import {useSelector} from 'react-redux'


import All from './All' 
import Faq from './Faq';
import Help from './Help'
import Master from './All' 
import Procurement from './Procurement';
import Profile from './Profile';
import ReportGen from './report_gen';
import SiteDraw from './SiteDraw';
import SiteReport from './SiteReport';



// Admin


export default function Router() {


 const permit_data = useSelector(state => state.login && state.login.data && state.login.data.role_permit ? state.login.data.role_permit : [])
// console.log(permit_data)




  



  
  return (
    <>
  {/* {permit_data.role_name !== 'All'  && Array.isArray(permit_data.faq) && (permit_data.faq[1]) ?
  
    <Faq/>
    :permit_data.role_name !== 'All'  && Array.isArray(permit_data.help) && (permit_data.help[1])?
    
    <Help />
    :permit_data.role_name !== 'All'  && Array.isArray(permit_data.master) && (permit_data.master[1])?
    
    <Master />
    :permit_data.role_name !== 'All'  && Array.isArray(permit_data.procurement) && (permit_data.procurement[1])?
    
    <Procurement />
    :permit_data.role_name !== 'All'  && Array.isArray(permit_data.profile) && (permit_data.profile[1])?
    
    <Profile />
    :permit_data.role_name !== 'All'  && Array.isArray(permit_data.report_gen) && (permit_data.report_gen[1])?
    
    <ReportGen />
    :permit_data.role_name !== 'All'  && Array.isArray(permit_data.site_draw) && (permit_data.site_draw[1])?
    
    <SiteDraw />
    :permit_data.role_name !== 'All'  && Array.isArray(permit_data.site_report) && (permit_data.site_report[1])?
    
    <SiteReport />
    :<All/>
    } */}
    <All />
    </>

  );


}