import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';




import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PasswordIcon from '@material-ui/icons/VpnKey';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Tooltip,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";


import {cleanAppUserMgmt , cleanChpAum} from "../../../../Actions/ActionCreators";
import {getAppUser, delAppUser , changeAppUserPassword} from "../../../../Actions/SubAdminActions/AppUserManagement";
import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites'
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
})


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  
  const [open, setOpen] = useState({show:false, id:""});
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);


  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.apu_id
    });
  
  }
  
  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delAppUser(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }
  

  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)



  const listData = useSelector((state) =>
  state.AppUserMgmt && state.AppUserMgmt.getData &&
    state.AppUserMgmt.getData.length &&
    Array.isArray(state.AppUserMgmt.getData)
      ? (state.AppUserMgmt.getData).filter(data => !selected_site || data.site_id == selected_site)
      : []
  );



  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);


  
  
  
  const [values, setValues] = useState({
   
    country:"",
  
  
  });
  
  
  
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  
  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  
  
  
    const addmsg = useSelector(state => state.AppUserMgmt.data && state.AppUserMgmt.data.apu_id ? state.AppUserMgmt.data.apu_id : null  )
    const adderror = useSelector(state => state.AppUserMgmt.error && state.AppUserMgmt.error.message ? state.AppUserMgmt.error.message : null  )
       
    const Delmsg = useSelector(state => state.AppUserMgmt && state.AppUserMgmt.delData && state.AppUserMgmt.delData.message ? state.AppUserMgmt.delData.message : null  )
    const Delerror = useSelector(state => state.AppUserMgmt && state.AppUserMgmt.delError && state.AppUserMgmt.delError.message ? state.AppUserMgmt.delError.message : null  )

    const Editmsg = useSelector(state => state.AppUserMgmt && state.AppUserMgmt.editData && state.AppUserMgmt.editData.message ? state.AppUserMgmt.editData.message : null  )
    const Editerror = useSelector(state => state.AppUserMgmt && state.AppUserMgmt.editError && state.AppUserMgmt.editError.message ? state.AppUserMgmt.editError.message : null  )


    const chpMsg = useSelector(state => state.AppUserMgmt && state.AppUserMgmt.passData && state.AppUserMgmt.passData.message ? state.AppUserMgmt.passData.message : null  )
    const chpError = useSelector(state => state.AppUserMgmt && state.AppUserMgmt.passError && state.AppUserMgmt.passError.message ? state.AppUserMgmt.passError.message : null  )
const sub_aid = useSelector(state => state.login.data.r_subadmin)


  
useEffect( () => {

    dispatch(getAppUser(sub_aid))
    dispatch(getSites(sub_aid))


    let x  = new Promise((resolve ,reject)=>{
      if(addmsg!==null)
      {
        tostS("Added Successfully")
      }
      if(adderror && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }

      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }
      if(Editmsg && typeof(Editmsg) === 'string')
      {
        tostS(Editmsg)
      }
      if(Editerror && typeof(Editerror) === 'string')
      {
          tostE(Editerror)
      }
      if(chpMsg && typeof(chpMsg) === 'string')
      {
        tostS(chpMsg)
      }
      if(chpError && typeof(chpError) === 'string')
      {
          tostE(chpError)
      }

      resolve()

    })
    x.then(()=>{

      dispatch(cleanAppUserMgmt())
      dispatch(getAppUser(sub_aid))
      dispatch(cleanChpAum())

    })


    
  }, [addmsg,adderror, Delmsg  ,Delerror, Editmsg, Editerror , chpMsg ,chpError ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/master/app_user_edit/'+olData.apu_id , { state: olData })
}


// const onDel = (oldata) =>{

//   dispatch(delAppUser(oldata.apu_id ,oldata.tableData.id ))
// }
 








const exportField = [
  
  {
    field : "name" , 
    val : "Name"
  },
  {
    field : "mobile" , 
    val : "Mobile"
  },
  {
    field : "email" , 
    val : "Email"
  },
  {
    field : "site_name" , 
    val : "Site Name"
  },
  {
    field : "assign_role" , 
    val : "Assigned Role"
  },
  
  
]



  

  


  const addNewBornbutton = () =>{
    navigate('/app/master/app_user_list_add')
    console.log("Place order")
    
  }


  const [pass, setPass] = useState({
    apu_id:"",
    password:"",
    confirm_pass:"",
    showPassword:false,
    showConfirmPass:false
  });
  
  const [open5, setOpen5] = useState({show:false, id:"", showPass:false});


  const handleOpen2 = (data) =>{
  
    setPass({
      ['apu_id']:data.apu_id
    })
    
    setOpen5({
      ...open,
      showPass:true,
      show:false,      
      id:"",
    });

  }



  const handleChange = (event) => {
    setPass({
      ...pass,
      [event.target.name]: event.target.value,
    });
  };
  
  
  const handleClickShowPassword = () => {
    setPass({
      ...pass,
      showPassword: !pass.showPassword,
    });
  };
  const handleClickShowConfirmPass = () => {
    setPass({
      ...pass,
      showConfirmPass: !pass.showConfirmPass,
    });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleClose5 = () =>{

    setOpen5({...open, show:false, id:"", showPass:false});
  }
  
  const handleConfirm5 = () =>{
    console.log(pass)


    if(pass.password === "" || pass.password === null || pass.password === undefined)
    {
      tostE("Please Enter Password")
    }
    else if(pass.confirm_pass === "" || pass.confirm_pass === null || pass.confirm_pass === undefined)
    {
      tostE("Please Enter Confirm Password")

    }
    else if(pass.password !== pass.confirm_pass )
    {
        tostE("Password Didn't Match !!")
    }
    else
    {
      console.log(pass)
      dispatch(changeAppUserPassword({apu_id : pass.apu_id , password : pass.password}))
         setOpen5({
        ...open,
        show:false,        
        id:""
      })
    
    }
    // if(open5.id!=="" && open5.show!==false && open5.showPass===false){


    //   // dispatch(delClients(open.id))

  
    //   setOpen5({
    //     ...open,
    //     show:false,        
    //     id:""
    //   })
    // }

  }
  
  return (
    <>
      <Page className={classes.root} title="App Users">
      <ToastContainer    />


      <Dialog disableBackdropClick={true} open={open5.showPass} onClose={handleClose5} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="password"
                type={pass.showPassword ? 'text' : 'password'}
                value={pass.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {pass.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
            </Grid>
            <br/>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                
                id="outlined-adornment-password"
                name="confirm_pass"
                type={pass.showConfirmPass ? 'text' : 'password'}
                value={pass.confirm_pass}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPass}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {pass.showConfirmPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            </Grid>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose5} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm5} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      

      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this App User??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>





        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
          <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'App User')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'App User')
              }>
                Export to PDF
            </Button>
            <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Create User
              </Button>
          </Box> 
            {/* <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  // value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>

              </ThemeProvider>
        </Grid>
       

        
<Grid item md={6} lg={3}  xs={3}>

<FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
<InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
<Select
fullWidth
labelId="demo-simple-select-outlined-label"
id="demo-simple-select-outlined"
value={values.country}
onChange={handleChanged}
label="Export As"
style={{width:"100%" }}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 1">Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem>


</Select>
</FormControl>
</Grid>
        
      </Grid>
    </div> */}
    
       
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="App Users"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'Name', field: 'name',editable: "never", },
        { title: 'Mobile', field: 'mobile',editable: "never", },     
        { title: 'Email',
          render: rowData => (
            rowData.assign_role ==="Client"?
            rowData.email:"Not Available"
          )
        
         },     
        { title: 'Site Name', field: 'site_name',editable: "never", },     
        { title: 'Assigned Role', field: 'assign_role',editable: "never", }, 
        
        
        {
          title: 'Change Password',
          render: rowData => (

            <Tooltip title="Change Password" arrow>
          <PasswordIcon  onClick={()=>{handleOpen2(rowData)}} style={{cursor:"pointer"}} />
            
        </Tooltip>
      
          ),
        },
        
        // { title: 'Left Amount', field: 'mobile_number',editable: "never", },     
        // { title: 'Status', field: 'business',editable: "never", },     
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        (role_permit[2] || role_permit[4] ?        

        { title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ?
                <Grid item xs={4}>
                
                <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
                </Grid>
              :<></>
              }
              {role_permit[2] ?
                <Grid item xs={4}>
                  <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
                </Grid>
              :<></>
              }
            </Grid>
          ),
        
        }:{})
      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
