import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import CustomMap  from '../../../../components/MapContainer';
import Invoice2 from '../../../../components/NewOrder'
import invoice from '../../../../components/data/invoice'

export default function SimpleSelect() {
  
  const getCoordinate = () => {
    const data ={
      location:{
        lat:-25.363, 
        lng:131.044
      }
    }


    return data
  }
  
 
   
  return (
   
    <>
       <CustomMap data={getCoordinate()} />

    </>
    
  );
}