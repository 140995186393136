import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
  
import {tostE} from './Toast'
export const  getExportFileBlob = (columns, data, fileType, fileName) => {
        // var column  = [{field :"name", val :"myname"} , {field :"mobile",val :"abc"} , {field : "email", val :"pqe"} , {field:"permit_role" , val :"pd"}]
    if(data.length){
        console.log("111111")
        if (fileType === "csv") {
            // CSV example
            console.log("222222")
           const headerNames = columns.filter((c) => c.Header != 'Action').map((col) => col.exportValue);
           const csvString = Papa.unparse({ fields: headerNames, data });
           return new Blob([csvString], { type: "text/csv" });
       } else if (fileType === "xlsx") {
           // XLSX example
    
        //    const header = columns.filter((c) => c.Header != 'Action').map((c) => c.exportValue);
           const columnInfo = columns.reduce(
            (acc, columns) => {
                console.log(acc)
                  const headerLabel = columns.val;
                  acc.header.push(headerLabel);
                  acc.map[columns.field] = headerLabel;
                  return acc;
              },
              { map: {}, header: [] }
          );
            const mappedData = data.map(row =>
                Object.entries(row).reduce((acc, [key, value]) => {
                    if (columnInfo.map[key]) {
                        acc[columnInfo.map[key]] = value;
                    }
                    return acc;
                }, {})
            );
    
           let wb = XLSX.utils.book_new();
           let ws1 = XLSX.utils.json_to_sheet(mappedData, {
            header: columnInfo.header,
           });
           XLSX.utils.book_append_sheet(wb, ws1, "Sheet 1");
           XLSX.writeFile(wb, `${fileName}.xlsx`);
    
           // Returning false as downloading of file is already taken care of
        //    return false;
       }
       //PDF example
       if (fileType === "pdf") {
        //    const headerNames = columns.filter((c) => c.Header != 'Action').map((column) => column.exportValue);
    
           const columnInfo = columns.reduce(
            (acc, columns) => {
                console.log(acc)
                  const headerLabel = columns.val;
                  acc.header.push(headerLabel);
                  acc.map[columns.field] = headerLabel;
                  return acc;
              },
              { map: {}, header: [] }
          );
        //   const mappedData = data.map(row =>
        //     Object.entries(row).reduce((acc, [key, value]) => {
                
        //         if (columnInfo.map[key]) {
        //             acc[columnInfo.map[key]] = value;
        //         }
        //         console.log(acc)
        //         return acc;
        //     })
    
        // );
        
    var dict = {}
    const mappedData = data.map(row =>
    
       Object.keys(columnInfo.map).map((key) => {
        if (columnInfo.map[key] in dict)
       {
         (dict[columnInfo.map[key]]).push(row[key])
       }
      else{
      dict[columnInfo.map[key]] = [row[key]]
      }
      
    })     
     ) 
    
    
     var rs = Object.values(dict)
     
     var final = rs[0].map((_ , colIndex) => rs.map(row => row[colIndex]));
     
            //   const mappedData = (data) =>{
            //       console.log(data)
            //       return data;
            //   }
           const doc = new JsPDF();
           doc.autoTable({
               head: [columnInfo.header],
               body: final,
               tableLineColor: [189, 195, 199],
               tableLineWidth: 0.75,
               bodyStyles: {lineColor: [189, 195, 199]},
               styles: {                   
                   align: "center",
                   fontSize: 11,
                   padding:'2px'
               },
               theme:"grid",
               
           });
           doc.save(`${fileName}.pdf`);
    
           return false;
       }
    
       // Other formats goes here
       return false;
    }else{
        return(
            tostE("There is no data to export!!")
        )
    }
    
}