import {ADD_MACHINE_CAT_LOADING , ADD_MACHINE_CAT_SUCCESS , ADD_MACHINE_CAT_FAILURE , CLEAN_MACHINE_CAT_DATA} from '../../Actions/Types';
import {GET_MACHINE_CAT_LOADING , GET_MACHINE_CAT_SUCCESS , GET_MACHINE_CAT_FAILURE} from '../../Actions/Types';
import {DEL_MACHINE_CAT_LOADING , DEL_MACHINE_CAT_SUCCESS , DEL_MACHINE_CAT_FAILURE} from '../../Actions/Types';
import {EDIT_MACHINE_CAT_LOADING , EDIT_MACHINE_CAT_SUCCESS , EDIT_MACHINE_CAT_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const MachineCat = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{}
                
            }
    
    
        case CLEAN_MACHINE_CAT_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                // getData : [{}],
                editData : [{}],
                editError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_MACHINE_CAT_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                delData : [{}]
                
            }
        case ADD_MACHINE_CAT_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
                delData : [{}]
            }
        case ADD_MACHINE_CAT_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
                delData:[{}]
            }

        case GET_MACHINE_CAT_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                delData : [{}],
                
            }
        case GET_MACHINE_CAT_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data,
                delData:[{}]
            }
        case GET_MACHINE_CAT_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
                delData : [{}]
            }   
        
        case DEL_MACHINE_CAT_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}]
                
            }
        case DEL_MACHINE_CAT_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_MACHINE_CAT_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }
                    
        case EDIT_MACHINE_CAT_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}]
                
            }
        case EDIT_MACHINE_CAT_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data
            }
        case EDIT_MACHINE_CAT_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }
            

        default:
        return state
    
    }
    }
    
    
    
export default MachineCat;
    