// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addOtherSubAdminBegin , addOtherSubAdminSuccess , addOtherSubAdminFailure} from '../ActionCreators';
import {getOtherSubAdminBegin , getOtherSubAdminSuccess , getOtherSubAdminFailure } from '../ActionCreators';
import {delOtherSubAdminBegin , delOtherSubAdminSuccess , delOtherSubAdminFailure } from '../ActionCreators';
import {editOtherSubAdminBegin , editOtherSubAdminSuccess , editOtherSubAdminFailure } from '../ActionCreators';
import {blockOtherSubAdminBegin , blockOtherSubAdminSuccess , blockOtherSubAdminFailure } from '../ActionCreators';


export const addOtherSubAdmin = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addOtherSubAdminBegin());

        axios.post(Base_URL + '/subadmin/addothersubadmin', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addOtherSubAdminSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addOtherSubAdminFailure(err.response.data));

                }
            })
    }
}

export const getOtherSubAdmin = (x) => {

    return (dispatch) => {
        dispatch(getOtherSubAdminBegin());

        axios.get(Base_URL + '/subadmin/getSubadminUsers' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getOtherSubAdminSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getOtherSubAdminFailure(err.response.data));

                }
            })
    }
}

export const delOtherSubAdmin = (x) => {

    return (dispatch) => {
        dispatch(delOtherSubAdminBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delSubProfile' , {params :{admin_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delOtherSubAdminSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delOtherSubAdminFailure(err.response.data));

                }
            })
    }
}

export const blockOtherSubAdmin = (x,y) => {

    return (dispatch) => {
        dispatch(blockOtherSubAdminBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/blockothersubadmin' , {params :{admin_id : x, active:y}})
            .then((res) => {
                console.log(res.data)
                dispatch(blockOtherSubAdminSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(blockOtherSubAdminFailure(err.response.data));

                }
            })
    }
}

export const editOtherSubAdmin = (session) => {

    return (dispatch) => {
        dispatch(editOtherSubAdminBegin());

        axios.post(Base_URL + '/subadmin/editSubadminUsers', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editOtherSubAdminSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editOtherSubAdminFailure(err.response.data));

                }
            })
    }
}

