import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useLocation , useNavigate} from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';


import {addPlan} from '../../../Actions/SuperAdminActions/AddPlan'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import {editPlan} from '../../../Actions/SuperAdminActions/AddPlan';
import {cleanPlan} from '../../../Actions/ActionCreators';


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
const classes = useStyles();
const dispatch = useDispatch();
const {state} = useLocation();
const navigate = useNavigate();



console.log(state)
  const [state1, setState1] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC : false,
    
  });

  const handleChangeToggle = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };


 
  const handleChangef = (event) => {
    setValues({...values, ['storage']:event.target.value});
  };
  const handleChanget = (event) => {
    setValues({...values, ['type_of_business']:event.target.value});
  };



  const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   plan_id:state.plan_id,
    plan_name:state.plan_name,
    storage:state.storage,
    price :state.price,
    plan_number:state.plan_number,
    duration:state.duration,
    number_of_sites:state.number_of_sites,
    users :state.users,
    is_geo_api:state.is_geo_api,
    is_whatsapp_api:state.is_whatsapp_api,    
    is_active:state.is_active,
    fixImg : "/static/images/avatars/upload.png"

  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (date) =>{

    console.log(date)

  }




//   const handleChange = (event) => {
//       console.log(event.target.checked)
//     setValues({ ...values, ['mobile_visible']: event.target.checked });
//   };


  const handleSubmit = () =>{
    if(values.plan_name==="" || values.plan_name===null || values.plan_name===undefined){
      tostE("Please enter plan name!!")
    }else if(values.number_of_sites==="" || values.number_of_sites===null || values.number_of_sites===undefined){
      tostE("Please enter number of site!!")
    }else if(values.price==="" || values.price===null || values.price===undefined){
      tostE("Please enter plan price!!")
    }else if(values.duration==="" || values.duration===null || values.duration===undefined){
      tostE("Please enter plan duration!!")
    }else if(values.storage==="" || values.storage===null || values.storage===undefined){
      tostE("Please Select Storage!!")
    }else if(values.users==="" || values.users===null || values.users===undefined){
      tostE("Please Enter Number of Users!!")
    }else{
      
          var rty = {
            plan_id:values.plan_id,
            plan_name : values.plan_name,
            plan_number : values.plan_number,
            duration : values.duration,
            number_of_sites : values.number_of_sites,
            users : values.users,
            price : values.price,
            storage : values.storage,
            is_geo_api :values.is_geo_api,
            is_whatsapp_api: values.is_whatsapp_api,
            is_active: values.is_active
      
          }
            
        dispatch(editPlan(rty))
         navigate('/app/plans')

    }

  }







  const CData = useSelector((state) =>
  state.GetCountry && state.GetCountry.getdata &&
    state.GetCountry.getdata.length &&
    Array.isArray(state.GetCountry.getdata)
      ? state.GetCountry.getdata
      : []
  );









  





//   const addmsg = useSelector((state) => state.AddPlan.editData && state.AddPlan.editData.plan_id ? state.AddPlan.data.plan_id : null  )
//   const adderror = useSelector(state => state.Addplan.editError && state.AddPlan.editError.message ? state.AddPlan.editError.message : null  )


//      useEffect( () => {

//        let x  = new Promise((resolve ,reject)=>{
//          if(addmsg && typeof(addmsg) === 'number')
//          {
//            tostS("Updated Successfully")
//          }
//          if(adderror && typeof(adderror) === 'string')
//          {
//            tostE(adderror)
//          }

//          resolve()

//        })
//        x.then(()=>{

//        dispatch(cleanPlan())
//        navigate('/app/plans')  

//        })

   
      
      //  }, [addmsg , adderror])


 

  return (
    <Page
      className={classes.root}
      title="Edit Plan"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Edit Plan" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
         
             <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Plan Name"
                name="plan_name"
                onChange={handleChange}
                required
                value={values.plan_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Plan Number"
                name="plan_number"
                onChange={handleChange}
                required
                value={values.plan_number}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"  InputProps={{inputProps:{min:0}}}
                label="Number of Sites"
                name="number_of_sites"
                onChange={handleChange}
                required
                value={values.number_of_sites}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Users"
                name="users"
                onChange={handleChange}
                required
                value={values.users}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Price"
                name="price"
                onChange={handleChange}
                required
                value={values.price}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Duration (In Days)"
                name="duration"
                onChange={handleChange}
                required
                type="number"  InputProps={{inputProps:{min:0}}}
                value={values.duration}
                variant="outlined"
              />
            </Grid>
            
            
            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Storage Allocated</InputLabel>
      <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="storage"
          value={values.storage}
          onChange={handleChangef}
          label="Storage Allocated"
        >
          <MenuItem value={values.storage}>
            <em>None</em>
          </MenuItem>
        <MenuItem   value="10 GB">10 GB</MenuItem>
        <MenuItem   value="20 GB">20 GB</MenuItem>
        <MenuItem   value="100 MB">100 MB</MenuItem>

        </Select>
      </FormControl>
      </Grid>

          
      <Grid item md={6} xs={12}></Grid>




      <Grid item md={6} xs={12}>
      <FormGroup row>
      <FormControlLabel
        control={<Switch checked={values.is_geo_api} onChange={handleChangeToggle} name="is_geo_api" />}
        label="Geo Coding Api"
      />
      </FormGroup>
      </Grid>


            <Grid item md={6} xs={12}>
      <FormGroup row>
      <FormControlLabel
        control={<Switch checked={values.is_whatsapp_api} onChange={handleChangeToggle} name="is_whatsapp_api" />}
        label="Whatsapp Api"
      />
      </FormGroup>
            </Grid>

            
            


            <Grid item md={6} xs={12}>

      <FormGroup row>
      <FormControlLabel
        control={<Switch checked={values.is_active} onChange={handleChangeToggle} name="is_active" />}
        label="Active/Inactive"
      />
      </FormGroup>
          
            </Grid>
      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Update details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
