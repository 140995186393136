import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  IconButton,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";



import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {addMaterialCategory , getMaterialCategory , delMaterialCategory , editMaterialCategory} from '../../../../../Actions/SubAdminActions/M_MaterialCategory'
import { cleanMaterialCategoryData } from '../../../../../Actions/ActionCreators';
// import { addResourceUnit } from "../../../../../Actions/SubAdminActions/M_ResourceUnit";
import {getExportFileBlob} from '../../../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const [open3, setOpen3] = useState({show:false, id:""});
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);


  const handleClose3 = () =>{

    setOpen3({...open3, show:false, id:""});
  }
  
  const handleOpen3 = (data) =>{
  
    setOpen3({
      ...open3,
      show:true,
      id:data.mc_id
    });
  
  }
  
  const handleConfirm3 = () =>{
    if(open3.id!==""){
      
      dispatch(delMaterialCategory(open3.id))

  
      setOpen3({
        ...open3,
        show:false,
        id:""
      })
    }
  }


  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)



  const listData= useSelector((state) =>
  state.AddMaterialCategory && state.AddMaterialCategory.getData &&
    state.AddMaterialCategory.getData.length &&
    Array.isArray(state.AddMaterialCategory.getData)
      ? state.AddMaterialCategory.getData
      : []
  );



  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);


  
 



  
  
  
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  
  
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  
  const handleDateChange = (date) => {
    console.log(date)
    setSelectedDate(date);

    var t =  moment(date).format("DD/MM/YYYY")
    console.log(t)
    setValues({...values, ['end_date'] : t  })

  };
  
  
  
  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  
  
const navigate = useNavigate();

const onEdit = (olData) =>{
//   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
setOpen2(true)
setValues({...values, ['name'] : olData.name , ['mc_id'] : olData.mc_id })

}


const onDel = (oldata) =>{
  dispatch(delMaterialCategory(oldata.mc_id ,oldata.tableData.id ))
}
 

const [open, setOpen] = React.useState(false);
const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setValues({...values, ['name'] : "" , ['mc_id'] : "" })
    setOpen(true);
  };

  const handleClickOpen2 = () => {
  setOpen2(true);

};

  const handleClose = () => {
    setOpen(false);
  };

const handleClose2 = () => {
  setOpen2(false);
  setValues({...values, ['name'] : "" , ['mc_id'] : "" })

};

const id = useSelector(state => state.login.data.r_subadmin);

const [values , setValues] = useState({

  sub_admin_id : id,
  name : "",
  mc_id : ""

});

const handleChange = (event) => {
  //  console.log(event.target.value);
   setValues({
     ...values,
     [event.target.name]: event.target.value,
   });
 };


const addmsg = useSelector((state) => state.AddMaterialCategory.data && state.AddMaterialCategory.data.status && state.AddMaterialCategory.data.status === 200 ? state.AddMaterialCategory.data.message : null  )
const adderr = useSelector((state) => state.AddMaterialCategory.error && state.AddMaterialCategory.error.message ? state.AddMaterialCategory.error.message : null  )
const delmsg = useSelector((state) => state.AddMaterialCategory && state.AddMaterialCategory.delData && state.AddMaterialCategory.delData.message ? state.AddMaterialCategory.delData.message : null  )
const delerr = useSelector((state) => state.AddMaterialCategory && state.AddMaterialCategory.delError && state.AddMaterialCategory.delError.message ? state.AddMaterialCategory.delError.message : null  )
const editmsg = useSelector((state) => state.AddMaterialCategory && state.AddMaterialCategory.editData && state.AddMaterialCategory.editData.message ? state.AddMaterialCategory.editData.message : null )
  

useEffect( () => {

  // dispatch(getMaterialCategory(id))
  var r  = new Promise((resolve , reject)=>{
   if(addmsg !== null && typeof(addmsg) === 'string'){
    tostS(addmsg)
   }

   if (delerr !== null && typeof(delerr) === 'string')
   {
     tostW(delerr)
   }


  if(adderr !== null){
    tostE(adderr)
   }
 
  if (delmsg !== null && typeof(delmsg) === 'string')
  {
    tostS(delmsg)
  }

  if (editmsg !== null && typeof(editmsg) === 'string')
  {
    tostS(editmsg);
  }

   resolve()
  
  }) 
  r.then(()=>{
   dispatch(cleanMaterialCategoryData())
   dispatch(getMaterialCategory(id))
  //  dispatch()
  // alert("Done");
  })
  
}, [addmsg, ,adderr , delmsg , editmsg , delerr ])


const handleSubmit = () =>{
  //  console.log(values)
  if (values.name.length > 2)
  {
    if (values.mc_id !== '')
    {
      dispatch(editMaterialCategory(values))
    }
    else
    {
      // alert("start");
      dispatch(addMaterialCategory(values))
  
    }
   setValues({
     ...values,
     name : "",
     mc_id : ""
    
   });
  
  }
  else{
    tostE("Enter Valid Category")
  }
    
setOpen(false);
setOpen2(false);
// setValues({...values, ['name'] : "" , ['mc_id'] : "" })

}; 
const exportField = [
  {
    field : "mc_id" , 
    val : "Material Category ID"
  }, 
  {
    field : "name" , 
    val : "Category Name"
  }, 
  
]


  const addNewBornbutton = () =>{
    navigate('/app/master/mat_category_list_add')
    console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Material Category">
      <ToastContainer    />

      


      <Dialog disableBackdropClick={true} open={open3.show} onClose={handleClose3} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Material Category??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm3} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>



        <Container maxWidth={false}>
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
            

              <div>
              <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Material Category')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Material Category')
              }>
                Export to PDF
            </Button>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Category
      </Button>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Category Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Category
          </DialogContentText>
       
          <TextField
                fullWidth
                id="name"
                label="Category Name"
                type="text"
                required
                name = "name"
                value = {values.name}
                onChange = {handleChange}
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Category
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Category Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update Category
          </DialogContentText>
       
          <TextField
                fullWidth
                id="name"
                label="Category Name"
                type="text"
                required
                name = "name"
                value = {values.name}
                onChange = {handleChange}
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

    </div>


            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Added Category"
      columns={[
        
        { title: 'Category ID', render: rowData =>(
          rowData.tableData.id+1
          )},
        { title: 'Category Name', field: 'name',editable: "never", },     
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ?
              <Grid item xs={4}>
              {rowData.by_superadmin ? 
              <IconButton>
                <EditIcon onClick={()=>{tostW("Category Added by Super Admin can't be Edit")}} style={{cursor:"pointer"}} />

              </IconButton>
              :   
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              }
              </Grid>
              :<></>              
              }
              {role_permit[2] ?
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen3(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
