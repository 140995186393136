// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";

import { Base_URL } from '../../Config/BaseUrlConfig';

import {getSubTickBegin , getSubTickSuccess , getSubTickFailure } from '../ActionCreators';
import {getAllTickBegin , getAllTickSuccess , getAllTickFailure } from '../ActionCreators';
import {addSubTickBegin , addSubTickSuccess , addSubTickFailure } from '../ActionCreators';
import {editSubTickBegin , editSubTickSuccess , editSubTickFailure } from '../ActionCreators';
import {delSubTickBegin , delSubTickSuccess , delSubTickFailure } from '../ActionCreators';
import {delTicketBegin , delTicketSuccess , delTicketFailure } from '../ActionCreators';



export const addSubTick = (session) => {

    return (dispatch) => {
        dispatch(addSubTickBegin());

        axios.post(Base_URL + '/subadmin/addTick', session)
            .then((res) => {
                dispatch(addSubTickSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addSubTickFailure(err.response.data));

                }
            })
    }
}




export const getSubTick = (session) => {

    return (dispatch) => {
        dispatch(getSubTickBegin());

        axios.get(Base_URL + '/subadmin/getHelpQuery',{params:{sub_admin_id:session}})
            .then((res) => {
                
                dispatch(getSubTickSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getSubTickFailure(err.response.data));

                }
            })
    }
}



export const getAllTick = (session) => {

    return (dispatch) => {
        dispatch(getAllTickBegin());

        axios.get(Base_URL + '/subadmin/getticket',{params:{sub_admin_id:session}})
            .then((res) => {
                
                dispatch(getAllTickSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getAllTickFailure(err.response.data));

                }
            })
    }
}

export const editSubTick = (session) => {

    return (dispatch) => {
        dispatch(editSubTickBegin());

        axios.post(Base_URL + '/subadmin/editHelpDesk', session)
            .then((res) => {
                
                dispatch(editSubTickSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editSubTickFailure(err.response.data));

                }
            })
    }
}


export const delSubTick = (session) => {

    return (dispatch) => {
        dispatch(delSubTickBegin());

        axios.get(Base_URL + '/subadmin/deleteHelpDesk',{params:{ticket_id:session}})
            .then((res) => {
                
                dispatch(delSubTickSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delSubTickFailure(err.response.data));

                }
            })
    }
}




export const delTicket = (session) => {

    return (dispatch) => {
        dispatch(delTicketBegin());

        axios.get(Base_URL + '/subadmin/delticket',{params:{t_id:session}})
            .then((res) => {
                
                dispatch(delTicketSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delTicketFailure(err.response.data));

                }
            })
    }
}








