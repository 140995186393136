// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'



import {addPurchaseOrderBegin , addPurchaseOrderSuccess , addPurchaseOrderFailure } from './../ActionCreators'
import {getPurchaseOrderBegin , getPurchaseOrderSuccess , getPurchaseOrderFailure } from './../ActionCreators'
import {delPurchaseOrderBegin , delPurchaseOrderSuccess , delPurchaseOrderFailure } from './../ActionCreators'


export const getPurchaseOrder = (x) => {

    return (dispatch) => {
        dispatch(getPurchaseOrderBegin());

        axios.get(Base_URL + '/subadmin/getPurchaseOrder', {params:{sub_admin_id:x}})
            .then((res) => {
                dispatch(getPurchaseOrderSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getPurchaseOrderFailure(err.response.data));

                }
            })
    }
}


export const delPurchaseOrder = (x, y) => {

    return (dispatch) => {
        dispatch(delPurchaseOrderBegin());

        axios.get(Base_URL + '/subadmin/deletePurchaseOrder', {params:{type:x , pur_id : y}})
            .then((res) => {
                dispatch(delPurchaseOrderSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delPurchaseOrderFailure(err.response.data));

                }
            })
    }
}




export const addPurchaseOrder = (session) => {

    var formData = new FormData();

    formData.append('sub_admin_id',session.sub_admin_id);
    formData.append('app_uid',session.app_uid);
    formData.append('type',session.type);
    formData.append('site_id',session.site_id);
    formData.append('vd_id',session.vd_id);
    formData.append('gst_type',session.gst_type);
    formData.append('priority',session.priority);
    formData.append('mat_details',JSON.stringify(session.mat_details));
    formData.append('delivery_date',session.delivery_date);
    formData.append('folder_name',session.folder_name);
    formData.append('accepted_by',session.accepted_by);
    formData.append('img',session.img1);
    formData.append('img',session.img2);



    return (dispatch) => {
        dispatch(addPurchaseOrderBegin());

        axios.post(Base_URL + '/subAdmin/addPurchase', formData)
            .then((res) => {
                console.log(res.data)
                dispatch(addPurchaseOrderSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addPurchaseOrderFailure(err.response.data));

                }
            })
    }
}
