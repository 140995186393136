

// Inventory Types



export const ADD_STOCK_LOADING = "ADD_STOCK_LOADING";
export const ADD_STOCK_SUCCESS = 'ADD_STOCK_SUCCESS';
export const ADD_STOCK_FAILURE = 'ADD_STOCK_FAILURE';

export const GET_STOCK_LOADING = "GET_STOCK_LOADING";
export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS';
export const GET_STOCK_FAILURE = 'GET_STOCK_FAILURE';

export const EDIT_STOCK_LOADING = "EDIT_STOCK_LOADING";
export const EDIT_STOCK_SUCCESS = 'EDIT_STOCK_SUCCESS';
export const EDIT_STOCK_FAILURE = 'EDIT_STOCK_FAILURE';

export const DEL_STOCK_LOADING = "DEL_STOCK_LOADING";
export const DEL_STOCK_SUCCESS = 'DEL_STOCK_SUCCESS';
export const DEL_STOCK_FAILURE = 'DEL_STOCK_FAILURE';

export const CLEAN_STOCK_DATA = 'CLEAN_STOCK_DATA';




export const ADD_STOCK_TRANSFER_LOADING = "ADD_STOCK_TRANSFER_LOADING";
export const ADD_STOCK_TRANSFER_SUCCESS = 'ADD_STOCK_TRANSFER_SUCCESS';
export const ADD_STOCK_TRANSFER_FAILURE = 'ADD_STOCK_TRANSFER_FAILURE';

export const GET_STOCK_TRANSFER_LOADING = "GET_STOCK_TRANSFER_LOADING";
export const GET_STOCK_TRANSFER_SUCCESS = 'GET_STOCK_TRANSFER_SUCCESS';
export const GET_STOCK_TRANSFER_FAILURE = 'GET_STOCK_TRANSFER_FAILURE';


export const GET_ITEM_DATA_LOADING = "GET_ITEM_DATA_LOADING";
export const GET_ITEM_DATA_SUCCESS = 'GET_ITEM_DATA_SUCCESS';
export const GET_ITEM_DATA_FAILURE = 'GET_ITEM_DATA_FAILURE';


export const EDIT_STOCK_TRANSFER_LOADING = "EDIT_STOCK_TRANSFER_LOADING";
export const EDIT_STOCK_TRANSFER_SUCCESS = 'EDIT_STOCK_TRANSFER_SUCCESS';
export const EDIT_STOCK_TRANSFER_FAILURE = 'EDIT_STOCK_TRANSFER_FAILURE';

export const DEL_STOCK_TRANSFER_LOADING = "DEL_STOCK_TRANSFER_LOADING";
export const DEL_STOCK_TRANSFER_SUCCESS = 'DEL_STOCK_TRANSFER_SUCCESS';
export const DEL_STOCK_TRANSFER_FAILURE = 'DEL_STOCK_TRANSFER_FAILURE';

export const CLEAN_STOCK_TRANSFER_DATA = 'CLEAN_STOCK_TRANSFER_DATA';

export const GRN_ORDER_REQ_LOADING = "GRN_ORDER_REQ_LOADING";
export const GRN_ORDER_REQ_SUCCESS = 'GRN_ORDER_REQ_SUCCESS';
export const GRN_ORDER_REQ_FAILURE = 'GRN_ORDER_REQ_FAILURE';


export const GRN_ISSUE_LOADING = "GRN_ISSUE_LOADING";
export const GRN_ISSUE_SUCCESS = 'GRN_ISSUE_SUCCESS';
export const GRN_ISSUE_FAILURE = 'GRN_ISSUE_FAILURE';


export const GRN_ORDER_ACTION_LOADING = "GRN_ORDER_ACTION_LOADING";
export const GRN_ORDER_ACTION_SUCCESS = 'GRN_ORDER_ACTION_SUCCESS';
export const GRN_ORDER_ACTION_FAILURE = 'GRN_ORDER_ACTION_FAILURE';
export const CLEAN_ORDER_ACTION_DATA = 'CLEAN_ORDER_ACTION_DATA';



export const GET_DELIVERY_LOADING = "GET_DELIVERY_LOADING";
export const GET_DELIVERY_SUCCESS = 'GET_DELIVERY_SUCCESS';
export const GET_DELIVERY_FAILURE = 'GET_DELIVERY_FAILURE';

export const GET_ACCEPTED_DELIVERY_LOADING = "GET_ACCEPTED_DELIVERY_LOADING";
export const GET_ACCEPTED_DELIVERY_SUCCESS = 'GET_ACCEPTED_DELIVERY_SUCCESS';
export const GET_ACCEPTED_DELIVERY_FAILURE = 'GET_ACCEPTED_DELIVERY_FAILURE';

export const ACCEPT_DELIVERY_LOADING = "ACCEPT_DELIVERY_LOADING";
export const ACCEPT_DELIVERY_SUCCESS = 'ACCEPT_DELIVERY_SUCCESS';
export const ACCEPT_DELIVERY_FAILURE = 'ACCEPT_DELIVERY_FAILURE';

export const ISSUE_DELIVERY_LOADING = "ISSUE_DELIVERY_LOADING";
export const ISSUE_DELIVERY_SUCCESS = 'ISSUE_DELIVERY_SUCCESS';
export const ISSUE_DELIVERY_FAILURE = 'ISSUE_DELIVERY_FAILURE';

export const CLEAN_DELIVERY_DATA = 'CLEAN_DELIVERY_DATA';


export const GET_STOCK_SUMMARY_LOADING = "GET_STOCK_SUMMARY_LOADING";
export const GET_STOCK_SUMMARY_SUCCESS = 'GET_STOCK_SUMMARY_SUCCESS';
export const GET_STOCK_SUMMARY_FAILURE = 'GET_STOCK_SUMMARY_FAILURE';

export const GET_CONSUMED_DATA_LOADING = "GET_CONSUMED_DATA_LOADING";
export const GET_CONSUMED_DATA_SUCCESS = 'GET_CONSUMED_DATA_SUCCESS';
export const GET_CONSUMED_DATA_FAILURE = 'GET_CONSUMED_DATA_FAILURE';
