import { GETBOQ_FAILURE, GETBOQ_LOADING, GETBOQ_SUCCESS } from "../../Actions/Types";


console.log('hereeee');
const BOQDATA = (state , action) => {
    console.log(action.type) 
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],  
            editError : {},
            editPassData :[{}],
            editPassError : {},
            delData:[{}],
            delError:{}
        }
    }




switch(action.type) {
    
   
    case GETBOQ_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case GETBOQ_SUCCESS:

    console.log('hereeeeeeeee');
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case GETBOQ_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }

    default:
        return state

}
}



export default BOQDATA;
