// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'


import {getMaterialDeliveryBegin , getMaterialDeliverySuccess , getMaterialDeliveryFailure} from '../ActionCreators';
import {delMaterialDeliveryBegin , delMaterialDeliverySuccess ,  delMaterialDeliveryFailure} from '../ActionCreators';




export const getMaterialDelivery = (x) => {
    var parameter = x;
    if(parameter.start_date==="" || parameter.start_date===null || parameter.start_date===undefined){
        parameter.start_date=new Date('2001', '01', '01')
    }
    return (dispatch) => {
        dispatch(getMaterialDeliveryBegin());

        axios.get(Base_URL + '/subadmin/getMaterialDelivery' , {params : parameter})
            .then((res) => {
                dispatch(getMaterialDeliverySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getMaterialDeliveryFailure(err.response.data));

                }
            })
    }
}

export const delMaterialDelivery = (x) => {

   return (dispatch) => {
       dispatch(delMaterialDeliveryBegin());

       console.log(x);
       axios.get(Base_URL + '/subadmin/deleteMaterialDelivery' , {params :{dy_id : x}})
           .then((res) => {
               console.log(res.data)
               dispatch(delMaterialDeliverySuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   alert("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(delMaterialDeliveryFailure(err.response.data));

               }
           })
   }
}
