// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addManpowerManBegin , addManpowerManSuccess , addManpowerManFailure } from '../ActionCreators';
import {getManpowerManBegin , getManpowerManSuccess , getManpowerManFailure} from '../ActionCreators';
import {delManpowerManBegin , delManpowerManSuccess , delManpowerManFailure} from '../ActionCreators';
import {editManpowerManBegin , editManpowerManSuccess , editManpowerManFailure} from '../ActionCreators';

export const addManpowerMan = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addManpowerManBegin());

        axios.post(Base_URL + '/subAdmin/addManpowerManMaster', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addManpowerManSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addManpowerManFailure(err.response.data));

                }
            })
    }
}


export const getManpowerMan = (x) => {

    return (dispatch) => {
        dispatch(getManpowerManBegin());

        axios.get(Base_URL + '/subAdmin/getManpowerManMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getManpowerManSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getManpowerManFailure(err.response.data));

                }
            })
    }
}

export const delManpowerMan = (x) => {

    return (dispatch) => {
        dispatch(delManpowerManBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delManpowerManMaster' , {params :{mp_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delManpowerManSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delManpowerManFailure(err.response.data));

                }
            })
    }
}

export const editManpowerMan = (session) => {

    return (dispatch) => {
        dispatch(editManpowerManBegin());

        axios.post(Base_URL + '/subAdmin/editManpowerManMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editManpowerManSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editManpowerManFailure(err.response.data));

                }
            })
    }
}
