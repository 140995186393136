
import axios from "../../Config/Interceptor3";


import { Base_URL } from '../../Config/BaseUrlConfig'
import { getReportFailure, getReportSucess, getReportsBegin } from "./../ActionCreators";


export const GetReport = (session) => {

    return (dispatch) => {
        dispatch(getReportsBegin());

        axios.post(Base_URL + '/superadmin/getReport', session)
            .then((res) => {
                dispatch(getReportSucess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getReportFailure(err.response.data));

                }
            })
    }
}
