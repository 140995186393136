import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Chip,
  OutlinedInput
} from '@material-ui/core';
import Page from '../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';

import {addSubRole} from '../../../../Actions/SubAdminActions/SubRole'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



import { withStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {cleanSubRole} from '../../../../Actions/ActionCreators';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
    padding:0
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },

}));




const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#E36A26",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);





const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const theme = useTheme();

  const navigate = useNavigate()





  const id = useSelector(state => state.login.data.admin_id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
  sub_admin_id :id,  
  role_name : "",  
  role_type : "Other",
  site_ids:[],
  master_all  : false,   
  master_view : false,    
  master_del  : false,   
  master_add  : false,   
  master_upd    : false,
 
  sr_all    : false, 
  sr_view    : false, 
  sr_del     : false,
  sr_add     : false,
  sr_upd     : false,
 
  
 
  rg_all     : false,
  rg_view     : false,
  rg_del     : false,
  rg_add     : false,
  rg_upd     : false,
 
  profile_all     : false,
  profile_view    : false, 
  profile_del     : false,
  profile_add     : false,
  profile_upd     : false,
 
  sd_all     : false,
  sd_view    : false, 
  sd_del     : false,
  sd_add     : false,
  sd_upd     : false,

  boq_all     : false,
  boq_view    : false, 
  boq_del     : false,
  boq_add     : false,
  boq_upd     : false,
   
  help_all     : false,
  help_view     : false,
  help_del     : false,
  help_add     : false,
  help_upd     : false,
 
  faq_all     : false,
  faq_view     : false,
  faq_del     : false,
  faq_add     : false,
  faq_upd     : false,
   
  procurement_all     : false,
  procurement_view     : false,
  procurement_del     : false,
  procurement_add     : false,
  procurement_upd     : false,
  
   
  inventory_all     : false,
  inventory_view     : false,
  inventory_del     : false,
  inventory_add     : false,
  inventory_upd     : false,
  

  fixImg : "/static/images/avatars/upload.png"

  });

  const [personName, setPersonName] = useState([]);


  const handleChange = (event) => {
    
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
 
  const handleRoleTypeMenu = (e) => {
    setValues({
      ...values, 
      ['role_type']:e.target.value,
      ['site_ids']:[]
    })

    setPersonName([])
  }
 
 
  // const handleSiteMenu = (c) =>{
  //   setValues({...values ,  ['site_name'] : c.target.value})

  // }
  

 
 
 
  const handleChangeSite = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    
  };
  const [checked, setChecked] = React.useState(false);

  const handleChangecheck = (event) => {


    var str = event.target.name;



    const words = str.split('_');


    if(words[1] === 'all')
    {
      
    setValues({...values, [words[0]+'_'+'all']: event.target.checked, [words[0]+'_'+'view']: event.target.checked ,[words[0]+'_'+'del']: event.target.checked,[words[0]+'_'+'add']: event.target.checked,[words[0]+'_'+'upd']: event.target.checked});
    
    }
    else if(words[1] === 'del'  )
    {

          if( values[words[0]+'_'+'add'] === false && values[words[0]+'_'+'upd'] === false )
          {
          
            setValues({...values,[words[0]+'_'+'view']: event.target.checked ,[words[0]+'_'+'del']: event.target.checked});

          }
          else if( values[words[0]+'_'+'add'] === true && values[words[0]+'_'+'upd'] === true &&  values[words[0]+'_'+'view'])
          {
            setValues({...values,[words[0]+'_'+'all']: event.target.checked ,[words[0]+'_'+'del']: event.target.checked});

          }
          else
          {
                setValues({...values,[words[0]+'_'+'del']: event.target.checked});

          }

               



    }
    else if( words[1] === 'upd' )
    {
            if( values[words[0]+'_'+'add'] === false && values[words[0]+'_'+'del'] === false )
            {
            
              setValues({...values,[words[0]+'_'+'view']: event.target.checked ,[words[0]+'_'+'upd']: event.target.checked});

            }
            else if( values[words[0]+'_'+'add'] === true && values[words[0]+'_'+'del'] === true &&  values[words[0]+'_'+'view'])
            {
              setValues({...values,[words[0]+'_'+'all']: event.target.checked ,[words[0]+'_'+'upd']: event.target.checked});

            }
            else
            {
                  setValues({...values,[words[0]+'_'+'upd']: event.target.checked});

            }
  
      }
      else if( words[1] === 'add' )
      {
            if( values[words[0]+'_'+'upd'] === false && values[words[0]+'_'+'del'] === false )
            {
            
              setValues({...values,[words[0]+'_'+'view']: event.target.checked ,[words[0]+'_'+'add']: event.target.checked});

            }
            else if( values[words[0]+'_'+'upd'] === true && values[words[0]+'_'+'del'] === true &&  values[words[0]+'_'+'view'])
            {
              setValues({...values,[words[0]+'_'+'all']: event.target.checked ,[words[0]+'_'+'add']: event.target.checked});

            }
            else
            {
                  setValues({...values,[words[0]+'_'+'add']: event.target.checked});

            }


    
        }
        else if( words[1] === 'view' )
        
        {
          
                      var x =   words[0]+'_'+'add';
                        var  y =  words[0]+'_'+'upd';
                        var z = words[0]+'_'+'del';
                      
                      



                      if(values[x] === true || values[y] === true  || values[z] === true)
                      {
                          tostE("View Permission is needed for Add/Update/Delete")
                      }
                      else
                      {
                        setValues({...values,[words[0]+'_'+'view']: event.target.checked });

                      }

                  
          }
    else
    {
                        if(words[1] !== 'view')
                        {
                          setValues({
                            ...values,
                            [event.target.name]: event.target.checked,
                          });
                        }
      
    }
  };
 



 

  const handleSubmit = () =>{
// Sequence should be All , View , del , add , upd


var flag =  false

var x  = ['master' , 'sr' , 'rg' , 'profile' , 'sd' ,'boq','help' , 'faq' , 'procurement', 'inventory']
var y = ['all' , 'add' , 'del'  , 'upd' , 'view']


for(let i = 0 ; i < x.length ; i++)
{
  for(let j = 0 ; j < y.length ; j++)
  {
      if(values[x[i]+'_'+y[j]])
      {
        flag = true
      }
  }
}

if(flag === true)
{
  const f  =  {
    role_name : values.role_name,
    sub_admin_id:values.sub_admin_id,    
    role_type:values.role_type,
    site_ids:personName,
    master : [
        values.master_all,   
        values.master_view,    
        values.master_del,   
        values.master_add,   
        values.master_upd
    ], 
    site_report : [
        values.sr_all,   
        values.sr_view,    
        values.sr_del,   
        values.sr_add,   
        values.sr_upd
     ] , 
    report_gen : [
      values.rg_all,   
      values.rg_view,    
      values.rg_del,   
      values.rg_add,   
      values.rg_upd
     ] , 
    profile : [
      values.profile_all,   
      values.profile_view,    
      values.profile_del,   
      values.profile_add,   
      values.profile_upd
     ] , 
    site_draw : [
      values.sd_all,   
      values.sd_view,    
      values.sd_del,   
      values.sd_add,   
      values.sd_upd
    ], 
    site_boq : [
      values.boq_all,   
      values.boq_view,    
      values.boq_del,   
      values.boq_add,   
      values.boq_upd
    ], 

    
    help : [
      values.help_all,   
      values.help_view,    
      values.help_del,   
      values.help_add,   
      values.help_upd
     ] , 
    faq : [
      values.faq_all,   
      values.faq_view,    
      values.faq_del,   
      values.faq_add,   
      values.faq_upd
     ] , 
    procurement : [
      values.procurement_all,   
      values.procurement_view,    
      values.procurement_del,   
      values.procurement_add,   
      values.procurement_upd
    ], 
    inventory : [
      values.inventory_all,   
      values.inventory_view,    
      values.inventory_del,   
      values.inventory_add,   
      values.inventory_upd
    ], 
    
  }
  if (values.role_name === ""){
    tostE("Please Enter Role Name!!")
  }
  else{
    dispatch(addSubRole(f))
    setValues({
      ...values,
      role_name : "", 
      role_type :"Other",
      site_ids:[],
      master_all  : false,   
      master_view : false,    
      master_del  : false,   
      master_add  : false,   
      master_upd    : false,

      sr_all    : false, 
      sr_view    : false, 
      sr_del     : false,
      sr_add     : false,
      sr_upd     : false,



      rg_all     : false,
      rg_view     : false,
      rg_del     : false,
      rg_add     : false,
      rg_upd     : false,

      profile_all     : false,
      profile_view    : false, 
      profile_del     : false,
      profile_add     : false,
      profile_upd     : false,

      sd_all     : false,
      sd_view    : false, 
      sd_del     : false,
      sd_add     : false,
      sd_upd     : false,

      help_all     : false,
      help_view     : false,
      help_del     : false,
      help_add     : false,
      help_upd     : false,

      faq_all     : false,
      faq_view     : false,
      faq_del     : false,
      faq_add     : false,
      faq_upd     : false,

      procurement_all     : false,
      procurement_view     : false,
      procurement_del     : false,
      procurement_add     : false,
      procurement_upd     : false,
      
      inventory_all     : false,
      inventory_view     : false,
      inventory_del     : false,
      inventory_add     : false,
      inventory_upd     : false,
  
    })

    setPersonName([])
    // console.log(f)
  }
  
}
else
{
  tostE("Please Select At least one Permission!!");
}
  }





const CData = useSelector((state) =>
state.AddSites && state.AddSites.getData &&
  state.AddSites.getData.length &&
  Array.isArray(state.AddSites.getData)
    ? state.AddSites.getData
    : []
);
  
  
  // const SData = useSelector((state) =>
  // state.GetState && state.GetState.getdata &&
  //   state.GetState.getdata.length &&
  //   Array.isArray(state.GetState.getdata)
  //     ? state.GetState.getdata
  //     : []
  // );

  


 


  





const addmsg = useSelector(state => state.Sub_Role.data && state.Sub_Role.data.status && state.Sub_Role.data.status === 200 ? state.Sub_Role.data.status : null  )
//   const adderror = useSelector(state => state.Mh.error && state.Mh.error.message ? state.Mh.error.message : null  )

useEffect( () => {


  var r  = new Promise((resolve , reject)=>{

   if(addmsg === 200){
    tostS("Role Added Successfully"); 
   }resolve()
  }) 
  r.then(()=>{
   dispatch(cleanSubRole())
  }) } , [addmsg])



  return (
    <Page
      className={classes.root}
      title="Create Role"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Create Role" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Role Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.role_type}                   
                    onChange={handleRoleTypeMenu}
                    label="Role Type"
                    style={{margin:0}}
                  >
                    <MenuItem value="Other">Other</MenuItem>
                    <MenuItem value="Inventory">Inventory</MenuItem>
                    
                  </Select>
                </FormControl>
              </Grid>
             <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Role Name"
                name="role_name"
                onChange={handleChange}
                required
                value={values.role_name}
                variant="outlined" 
              />
            </Grid>

            
            
            <Grid item md={12} xs={12}>
            <FormControl variant="outlined" className = {classes.formControl}>
              <InputLabel id="demo-multiple-chip-label">Sites</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={personName}
                onChange={handleChangeSite}
                label="sites"
                // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                    {selected.map((value,i) => (
                      i<2?
                      <Chip key={i} label={(CData.filter(d=> d.site_id===value)[0].site_name).slice(0,20)} />
                      :
                      i==2 ?
                      <Chip key={i} label={`more +${selected.length-2}`} />
                      :<></>
                    ))}
                  </Box>

                )}
                MenuProps={MenuProps}
              >
                {CData.map((data, i) => (
                  <MenuItem
                    key={i}
                    value={data.site_id}
                    style={getStyles(data, personName, theme)}
                  >
                    {data.site_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
              </Grid>
           
            <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >

            <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Area</StyledTableCell>
            <StyledTableCell align="right">All</StyledTableCell>
            <StyledTableCell align="right"><VisibilityIcon/></StyledTableCell>
            <StyledTableCell align="right"><DeleteIcon/></StyledTableCell>
            <StyledTableCell align="right"><AddBoxIcon/></StyledTableCell>
            <StyledTableCell align="right"><EditIcon/></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Master</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="master_all" checked={values.master_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="master_view" checked={values.master_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="master_del" checked={values.master_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="master_add" checked={values.master_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="master_upd" checked={values.master_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Inventory</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inventory_all" checked={values.inventory_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inventory_view" checked={values.inventory_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inventory_del" checked={values.inventory_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inventory_add" checked={values.inventory_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="inventory_upd" checked={values.inventory_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Procurement</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="procurement_all" checked={values.procurement_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="procurement_view" checked={values.procurement_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="procurement_del" checked={values.procurement_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="procurement_add" checked={values.procurement_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="procurement_upd" checked={values.procurement_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>


            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Site Report</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sr_all" checked={values.sr_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sr_view" checked={values.sr_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sr_del" checked={values.sr_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sr_add" checked={values.sr_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sr_upd" checked={values.sr_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Report Generation</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rg_all" checked={values.rg_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rg_view" checked={values.rg_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rg_del" checked={values.rg_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rg_add" checked={values.rg_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="rg_upd" checked={values.rg_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Profile</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_all" checked={values.profile_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_view" checked={values.profile_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_del" checked={values.profile_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_add" checked={values.profile_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="profile_upd" checked={values.profile_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Site Drawing</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sd_all" checked={values.sd_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sd_view" checked={values.sd_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sd_del" checked={values.sd_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sd_add" checked={values.sd_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="sd_upd" checked={values.sd_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Site BOQ</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="boq_all" checked={values.boq_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="boq_view" checked={values.boq_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="boq_del" checked={values.boq_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="boq_add" checked={values.boq_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="boq_upd" checked={values.boq_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Help</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="help_all" checked={values.help_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="help_view" checked={values.help_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="help_del" checked={values.help_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="help_add" checked={values.help_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="help_upd" checked={values.help_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">Faq</StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="faq_all" checked={values.faq_all} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="faq_view" checked={values.faq_view} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="faq_del" checked={values.faq_del} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="faq_add" checked={values.faq_add} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
              <StyledTableCell align="right"><Checkbox name="faq_upd" checked={values.faq_upd} onChange={handleChangecheck} inputProps={{ 'aria-label': 'primary checkbox' }}/></StyledTableCell>
            </StyledTableRow>

        </TableBody>
      </Table>
    </TableContainer>
          
           </Grid> 
       
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;