import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getClientSites } from '../../../Actions/SubAdminActions/M_AddSites';
import {
  Container,
  Grid,
  makeStyles,
  Card,
  CardContent,
  Divider,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSites } from '../../../Actions/SubAdminActions/M_AddSites';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from '../../../Config/Interceptor2';
import { Base_URL } from '../../../Config/BaseUrlConfig';
import { ToastContainer } from 'react-toastify';
import { tostS, tostE } from '../../../Config/Toast';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableHeader: {
    fontWeight: 'bold',
  },
}));

const ClienDailyReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = useSelector(state => state.login.data.admin_id);
  const SiteId = useSelector(state => state.login.data.site_id);
//   const selected_site = useSelector(
//     (state) =>
//       state.SelectedSite &&
//       state.SelectedSite.sitedata &&
//       state.SelectedSite.sitedata.site
//         ? state.SelectedSite.sitedata.site
//         : null
//   );
  const SiteData = useSelector(
    (state) =>
      state.AddSites &&
      state.AddSites.getData &&
      state.AddSites.getData.length &&
      Array.isArray(state.AddSites.getData)
        ? state.AddSites.getData
        : []
  );

  const [date, setDate] = useState(new Date());

  const [values, setValues] = useState({
    sub_admin_id: id,
    site_id: SiteId,
  });

  const [dailyReport, setDailyReport] = useState(null);
  const [measurementBook, setMeasurementBook] = useState([]);

  useEffect(() => {
   
    dispatch(getClientSites(id));
  }, []);



  const fetchDailyReport = () => {
    const requestData = {
      sub_admin_id: id,
      site_id: values.site_id,
      date: formatDate(date),
    };
  
    axios
      .post(`${Base_URL}/client/ClientgetDailyReport`, requestData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          if (response.data.daily_report) {
            setDailyReport(response.data.daily_report);
            tostS('Daily report fetched successfully');
          } else {
            setDailyReport(null);
            tostE('No daily report data available for the selected date and site.');
          }
  
          if (response.data.measurement_book) {
            setMeasurementBook(response.data.measurement_book);
            tostS('Measurement book fetched successfully');
          } else {
            setMeasurementBook([]);
            tostE('No measurement book data available for the selected date and site.');
          }
        } else {
          tostE('Failed to fetch daily report: ');
          setDailyReport(null);
        }
      })
      .catch((err) => {
        tostE('Daily report not found for this day ');
        setDailyReport(null);
      });
  };
  

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const handleChangeSiteName = (event) => {
    setValues({ ...values, site_id: event.target.value });
  };

  const getSiteName = (site_id) => {
    const site = SiteData.find((site) => site.site_id === site_id);
    return site ? site.site_name : 'N/A';
  };

  const generateExcel = () => {
    if (measurementBook.length > 0) {
      const ws = XLSX.utils.json_to_sheet(measurementBook);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Measurement_Book');
      XLSX.writeFile(wb, 'Measurement_Book.xlsx');
    } else {
      alert('No data available to export.');
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
  
    // Set document properties
    doc.setProperties({
      title: 'Daily Report',
      subject: 'Daily Report and Measurement Book data',
      author: 'Your Name',
      keywords: 'daily report, measurement book, data',
    });
  
    // Define a function to format the Daily Report Details section
    const formatDailyReportDetails = () => {
      const { date, supervisor, weather, no_of_workers, machines_used, general_description } = dailyReport;
      let content = [
        { label: 'Date:', value: date },
        { label: 'Site Name:', value: getSiteName(values.site_id) },
        { label: 'Supervisor:', value: supervisor },
        { label: 'Weather:', value: weather },
        { label: 'No of Workers:', value: no_of_workers },
        { label: 'Machines Used:', value: machines_used },
        { label: 'Report Summary:', value: general_description },
      ];
  
      // Format content for PDF
      let formattedContent = content.map(item => `${item.label} ${item.value}`).join('\n');
      return formattedContent;
    };
  
    // Add content to the PDF
    doc.text('Daily Report Details', 14, 15);
    doc.text(formatDailyReportDetails(), 14, 25);
  
    // Add Measurement Book table
    doc.addPage(); // Add a new page for the table if needed
    doc.text('Measurement Book Data', 14, 15);
    doc.autoTable({
      head: [['Measurement ID', 'Item Description', 'Length', 'Breadth', 'Diameter', 'Height', 'No of Items', 'Quantity', 'Unit', 'Total used', 'Remarks']],
      body: measurementBook.map(row => [
        row.measurement_id,
        row.item_description,
        row.length,
        row.breadth,
        row.diameter,
        row.height,
        row.no_of_items,
        row.quantity,
        row.unit,
        row.total_used,
        row.remarks
      ]),
      startY: 25, // Adjust the Y position to leave space for the Daily Report Details
      theme: 'grid',
      styles: { cellPadding: 1.5, fontSize: 8 },
    });
  
    // Save the PDF
    doc.save('Daily_Report.pdf');
  };

  return (
    <Page className={classes.root} title="Daily Report">
      <ToastContainer />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <h3 style={{padding:"10px"}}>Daily Report</h3>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="date-picker"
                        label="Date"
                        format="MM/dd/yyyy"
                        value={date}
                        onChange={setDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">Site Name</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleChangeSiteName}
                        label="Site Name"
                        value={values.site_id}
                        inputProps={{ readOnly: true }}
                      >
                        {SiteData.map((data, i) =>
                          <MenuItem key={i} value={data.site_id}>{data.site_name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} style={{ float: "right" }}>
                    <Button onClick={fetchDailyReport} color="primary" variant="contained">
                      Get Measurement Book
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          {dailyReport && (
            <Grid item lg={12} md={12} xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Daily Report Details
                  </Typography>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <strong>Date:</strong> {dailyReport.date}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Site Name:</strong> {getSiteName(values.site_id)}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Supervisor:</strong> {dailyReport.supervisor}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Weather:</strong> {dailyReport.weather}
                    </Typography>
                    <Typography variant="body1">
                      <strong>No of Workers:</strong> {dailyReport.no_of_workers}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Machines Used:</strong> {dailyReport.machines_used}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Report Summary:</strong> {dailyReport.general_description}
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                    Measurement Book (MB)
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table aria-label="measurement book table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Measurement ID</TableCell>
                          <TableCell>Item Description</TableCell>
                          <TableCell>Length</TableCell>
                          <TableCell>Breadth</TableCell>
                          <TableCell>Diameter</TableCell>

                          <TableCell>Height</TableCell>
                          <TableCell>No of Items</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Unit</TableCell>
                          <TableCell>Total used</TableCell>
                          <TableCell>Remarks</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {measurementBook && measurementBook.length > 0 ? (
                          measurementBook.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{row.item_description}</TableCell>
                              <TableCell>{row.length}</TableCell>
                              <TableCell>{row.breadth}</TableCell>
                              <TableCell>{row.dia}</TableCell>
                              <TableCell>{row.height}</TableCell>
                              <TableCell>{row.noofitems}</TableCell>
                              <TableCell>{row.quantity}</TableCell>
                              <TableCell>{row.unit}</TableCell>
                              <TableCell>{row.used_quantity}</TableCell>
                              <TableCell>{row.remark}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              No Measurement Book Data
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button variant="contained" color="primary" onClick={generateExcel}>
                    Export to Excel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: '10px' }}
                    onClick={generatePDF}
                  >
                    Export to PDF
                  </Button>
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default ClienDailyReport;


    