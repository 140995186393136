import {CLEAN_ALL_DATA} from '../../Actions/Types';
import { CLIENT_MAT_DEL_START_DATE, CLIENT_MAT_DEL_END_DATE } from '../../Actions/Types';
import { CLIENT_MAN_REPO_START_DATE, CLIENT_MAN_REPO_END_DATE } from '../../Actions/Types';
import { CLIENT_MAC_REPO_START_DATE, CLIENT_MAC_REPO_END_DATE } from '../../Actions/Types';
import { CLIENT_SITE_EXP_START_DATE, CLIENT_SITE_EXP_END_DATE } from '../../Actions/Types';
import { CLIENT_SITE_PROGRESS_START_DATE, CLIENT_SITE_PROGRESS_END_DATE } from '../../Actions/Types';

const ClientDateFilters = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            MatDelStartDate:new Date(Date.now()),
            MatDelEndDate:new Date(Date.now()),
            ManRepoStartDate:new Date(Date.now()),
            ManRepoEndDate:new Date(Date.now()),
            MacRepoStartDate:new Date(Date.now()),
            MacRepoEndDate:new Date(Date.now()),
            SiteExpStartDate:new Date(Date.now()),
            SiteExpEndDate:new Date(Date.now()),
            SiteProgressStartDate:new Date(Date.now()),
            SiteProgressEndDate:new Date(Date.now()),


        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,                
                loading:false,  
                MatDelStartDate:new Date(Date.now()),
                MatDelEndDate:new Date(Date.now()),
                ManRepoStartDate:new Date(Date.now()),
                ManRepoEndDate:new Date(Date.now()),
                MacRepoStartDate:new Date(Date.now()),
                MacRepoEndDate:new Date(Date.now()),
                SiteExpStartDate:new Date(Date.now()),
                SiteExpEndDate:new Date(Date.now()),
                SiteProgressStartDate:new Date(Date.now()),
                SiteProgressEndDate:new Date(Date.now()),
                
            }                
                       
        case CLIENT_MAT_DEL_START_DATE:
            return {
                ...state,                
                loading:false,   
                MatDelStartDate:action.data                         
            }                
        case CLIENT_MAT_DEL_END_DATE:
            return {
                ...state,                
                loading:false,   
                MatDelEndDate:action.data                         
            }                
                    
        case CLIENT_MAN_REPO_START_DATE:
            return {
                ...state,                
                loading:false,   
                ManRepoStartDate:action.data                         
            }                
        case CLIENT_MAN_REPO_END_DATE:
            return {
                ...state,                
                loading:false,   
                ManRepoEndDate:action.data                         
            }                
        case CLIENT_MAC_REPO_START_DATE:
            return {
                ...state,                
                loading:false,   
                MacRepoStartDate:action.data                         
            }                
        case CLIENT_MAC_REPO_END_DATE:
            return {
                ...state,                
                loading:false,   
                MacRepoEndDate:action.data                         
            }                
        case CLIENT_SITE_EXP_START_DATE:
            return {
                ...state,                
                loading:false,   
                SiteExpStartDate:action.data                         
            }                
        case CLIENT_SITE_EXP_END_DATE:
            return {
                ...state,                
                loading:false,   
                SiteExpEndDate:action.data                         
            }                
                  
        case CLIENT_SITE_PROGRESS_START_DATE:
            return {
                ...state,                
                loading:false,   
                SiteProgressStartDate:action.data                         
            }                
        case CLIENT_SITE_PROGRESS_END_DATE:
            return {
                ...state,                
                loading:false,   
                SiteProgressEndDate:action.data                         
            }                

        default:
        return state
    
    }
    }
    
    
    
export default ClientDateFilters;
    