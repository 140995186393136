import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";


import {addManpowerMan, getManpowerMan, delManpowerMan, editManpowerMan} from "../../../../../Actions/SubAdminActions/M_ManpowerMan"
import { cleanManpowerManData } from '../../../../../Actions/ActionCreators';
import {getExportFileBlob} from '../../../../../Config/excelPdfDownload'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);

  const navigate = useNavigate();
  const [open3, setOpen3] = useState({show:false, id:""});


  const handleClose3 = () =>{

    setOpen3({...open3, show:false, id:""});
  }
  
  const handleOpen3 = (data) =>{
  
    setOpen3({
      ...open3,
      show:true,
      id:data.mp_id
    });
  
  }
  
  const handleConfirm3 = () =>{
    if(open3.id!==""){
      
      dispatch(delManpowerMan(open3.id))

  
      setOpen3({
        ...open3,
        show:false,
        id:""
      })
    }
  }


  const id = useSelector(state => state.login.data.r_subadmin);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  
  const [values, setValues] = useState({
   
    sub_admin_id:id,
    name:"",
    mp_id:"",    
    mp_cat_id:""
  
  
    });
  
    const handleChange = (event) => {
      //  console.log(event.target.value);
       setValues({
         ...values,
         [event.target.name]: event.target.value,
       });
     };
     const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)

  const listData= useSelector((state) =>
  state.ManpowerManMaster && state.ManpowerManMaster.getData &&
    state.ManpowerManMaster.getData.length &&
    Array.isArray(state.ManpowerManMaster.getData)
      ? state.ManpowerManMaster.getData
      : []
  );
  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);


  const CData = useSelector((state) =>
  state.AddManpowerCategory && state.AddManpowerCategory.getData &&
    state.AddManpowerCategory.getData.length &&
    Array.isArray(state.AddManpowerCategory.getData)
      ? state.AddManpowerCategory.getData
      : []
  );
     

  
  const addmsg = useSelector((state) => state.ManpowerManMaster.data && state.ManpowerManMaster.data.status && state.ManpowerManMaster.data.status === 200 ? state.ManpowerManMaster.data.message : null  )
  const adderr = useSelector((state) => state.ManpowerManMaster.error && state.ManpowerManMaster.error.message ? state.ManpowerManMaster.error.message : null  )
  
  const editmsg = useSelector((state) => state.ManpowerManMaster && state.ManpowerManMaster.editData && state.ManpowerManMaster.editData.message ? state.ManpowerManMaster.editData.message : null )
  
  const Delmsg = useSelector(state => state.ManpowerManMaster && state.ManpowerManMaster.delData && state.ManpowerManMaster.delData.message ? state.ManpowerManMaster.delData.message : null  )
  const Delerror = useSelector(state => state.ManpowerManMaster && state.ManpowerManMaster.delError && state.ManpowerManMaster.delError.message ? state.ManpowerManMaster.delError.message : null  )

  
   

  
useEffect( () => {

  console.log(CData.mp_cat_name);
  
  // dispatch(getManpowerMan(id))

  let x  = new Promise((resolve ,reject)=>{
    if(Delmsg !== null && typeof(Delmsg) === 'string')
    {
      tostS(Delmsg)
    }
    if(Delerror !== null && typeof(Delerror) === 'string')
    {
        tostE(Delerror)
    }
    if(addmsg !== null && typeof(addmsg) === 'string'){
      tostS(addmsg)
     }

    
    if(adderr !== null){
      tostE(adderr)
     }

     if (editmsg !== null && typeof(editmsg) === 'string')
       {
         tostS(editmsg);
       }
   
    resolve()

  })
  x.then(()=>{

  dispatch(cleanManpowerManData())
  dispatch(getManpowerMan(id))

  })


  
}, [ Delmsg  ,Delerror, addmsg, adderr, editmsg ])


const handleSubmit = () =>{
  if(((values.name).trim()).length>2){
    if(values.mp_id !== ""){
      dispatch(editManpowerMan(values));
    }else{
      dispatch(addManpowerMan(values));
    }
    setValues({
      ...values,
      name:"",
      mp_id:"",      
      mp_cat_id:""
  

    });
  }else{
    tostE("Enter Valid Name!!")
  }

  setOpen(false);
  setOpen2(false);
}


  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  
  

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  
  const handleChanged = (event) => {
    
    if(event.target.value!==""){
      setValues({...values, 
        ['mp_cat_id']:event.target.value        
      });
    }
    
  };


 
  

const onEdit = (olData) =>{
  setOpen(true);
  setValues({
    ...values,
    ['name']:olData.manp_mas,
    ['mp_id']:olData.mp_id,    
    ['mp_cat_id']:olData.mp_cat_id  
  });
  console.log(values)

}

const onDel = (olData) =>{
  dispatch(delManpowerMan(olData.mp_id, olData.tableData.id))
}
 



  const handleClickOpen = () => {
    setValues({...values, ['name'] : "" , ['mp_id'] : "" })
    setOpen(true);
  };

  const handleClickOpen2 = () => {
    // setValues({...values, ['name'] : values.name , ['mp_id'] : values.mp_id, ['mp_'] })
    setOpen2(true);

  };

  const handleClose = () => {
    setOpen(false);
    setValues({...values, ['name'] : "" , ['mp_id'] : ""})
  };

const handleClose2 = () => {
  setOpen2(false);
  setValues({...values, ['name'] : "" , ['mp_id'] : "" })

};


const exportField = [
  {
    field : "mp_id" , 
    val : "Manpower ID"
  }, 
  {
    field : "manp_mas" , 
    val : "Name"
  }, 
  {
    field : "manp_cat" , 
    val : "Manpower Category"
  }, 
  
]



  return (
    <>
      <Page className={classes.root} title="Manpower Master">
      <ToastContainer    />

      


      <Dialog disableBackdropClick={true} open={open3.show} onClose={handleClose3} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Manpower??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm3} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>


        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> 
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add
              </Button>*/}



                <div>
                <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Manpower')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Manpower')
              }>
                Export to PDF
            </Button>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Manpower
      </Button>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Manpower</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Manpower Details
          </DialogContentText>
         <Grid item md={12} xs={12}>
         <TextField
                fullWidth
                id="name"
                label="Manpower Name"
                name="name"
                type="text"
                required
                value={values.name}
                onChange={handleChange}
                variant="outlined"
              />
         </Grid>
          
            <br></br>

              <Grid item md={12} xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Manpower Category</InputLabel>
                  <Select
                  fullWidth
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={values.mp_cat_id}
                  onChange={handleChanged}
                  label="Manpower Category"
                  MenuProps={{
                        PaperProps: {
                          onScroll: (event) => {
                            console.log("we scroll");                            
                          }
                        },
                        style: { maxHeight: 300 },
                        id: "id-menu",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {CData.map((data, i) =>
                      <MenuItem key={i} value={data.mpc_id}>{data.name}</MenuItem>
                    )}              
                    
                  </Select>
                </FormControl>
            </Grid>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit Details
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Manpower</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update Detail
          </DialogContentText>
          <Grid item md={12} xs={12}>
          <TextField
                fullWidth
                id="name"
                label="Name"
                name="name"
                type="text"
                required
                value={values.name}
                onChange={handleChange}
                variant="outlined"
              />
         </Grid>
          
            <br></br>

              <Grid item md={12} xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Manpower</InputLabel>
                  <Select
                  fullWidth
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleChanged}
                  value={values.mp_cat_id}
                  label="Manpower"
                  MenuProps={{
                        PaperProps: {
                          onScroll: (event) => {
                            console.log("we scroll");                            
                          }
                        },
                        style: { maxHeight: 300 },
                        id: "id-menu",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {CData.map((data, i) =>
                      <MenuItem key={i} value={data.mpc_id}>{data.name}</MenuItem>
                    )}              
                    
                  </Select>
                </FormControl>
            </Grid>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update Details
          </Button>
        </DialogActions>
      </Dialog>
    </div>










            </Box>
            {/* <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  // value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>

              </ThemeProvider>
        </Grid>
       

        
<Grid item md={6} lg={3}  xs={3}>

<FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
<InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
<Select
fullWidth
labelId="demo-simple-select-outlined-label"
id="demo-simple-select-outlined"
value={values.country}
onChange={handleChanged}
label="Export As"
style={{width:"100%" }}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 1">Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem>


</Select>
</FormControl>
</Grid>
        
      </Grid>
    </div> */}
 
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Manpower Master"
      columns={[
       
        { title: 'Manpower ID', 
        render: rowData =>(
          rowData.tableData.id+1
          )
         },
        { title: 'Name', field: 'manp_mas',editable: "never", },     
        { title: 'Manpower Category', field: 'manp_cat',editable: "never", },     
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ?
                <Grid item xs={4}>
                  
                <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
                </Grid>
              :<></>
              
              }
              {role_permit[4] ?
                <Grid item xs={4}>
                  <DeleteIcon onClick={()=>{handleOpen3(rowData)}} style={{cursor:"pointer"}} />
                </Grid>
                :<></>
              }

            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
