import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "default",
    height: 100,
    width: 100
  },
  DashCard:{
    border:"1px solid #E36A26",
    background:"#FFEAE0"
  }
}));

const TasksProgress = ({ className,dashdata, ...rest }) => {
  const classes = useStyles();



  const DashBoardData = useSelector((state) =>
  state.DashBoard &&
  state.DashBoard.SubData &&
  Object.keys(state.DashBoard.SubData) &&
  Object.keys(state.DashBoard.SubData).length 
  ? state.DashBoard.SubData.open_issue
  : 0
  );

  return (
    <Card
      className={classes.DashCard}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Open Issues
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
            {DashBoardData}
            </Typography>
          </Grid>
         
        </Grid>
        
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string
};

export default TasksProgress;
