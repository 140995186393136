import {DEMO_LOGIN_USER_LOADING , DEMO_LOGIN_USER_SUCCESS , DEMO_LOGIN_USER_FAILURE } from '../Actions/Types';






const login = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            logoutdata:[{}],
            logouterror :{} 

        }
    }




switch(action.type) {
    
    case DEMO_LOGIN_USER_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case DEMO_LOGIN_USER_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.logindata
        }
    case DEMO_LOGIN_USER_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.loginerror,
            data:{},
        }




        // case DEMO_LOGOUT_USER_LOADING:
        //     return {
        //         ...state,
        //         loading:true,
        //         logouterror: {},
                
        //     }
        // case DEMO_LOGOUT_USER_SUCCESS:
        //     return {
        //         ...state,
        //         loading:false,
        //         error:{},
        //         data:[{}],
        //         logoutdata: {},
        //     }
        // case DEMO_LOGOUT_USER_FAILURE:
        //     return {
        //         ...state,
        //         loading:false,
        //         error:{}
        //     }    

    default:
        return state






}
}



export default login;
