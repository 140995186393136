// import axios from "../Config/Interceptor1";


import axios from '../../Config/Interceptor2'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {acceptDeliveryBegin , acceptDeliverySuccess , acceptDeliveryFailure} from './ActionCreators'
import {issueDeliveryBegin , issueDeliverySuccess , issueDeliveryFailure} from './ActionCreators'
import {getDeliveryBegin , getDeliverySuccess , getDeliveryFailure} from './ActionCreators'
import {getAcceptedDeliveryBegin , getAcceptedDeliverySuccess , getAcceptedDeliveryFailure} from './ActionCreators'


export const issueDelivery = (session) => {

    return (dispatch) => {
        dispatch(issueDeliveryBegin());

        axios.post(Base_URL + '/inventory/editDelivery', session)
            .then((res) => {
                console.log(res.data)
                dispatch(issueDeliverySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(issueDeliveryFailure(err.response.data));

                }
            })
    }
}


export const acceptDelivery = (session) => {

    return (dispatch) => {
        dispatch(acceptDeliveryBegin());

        axios.post(Base_URL + '/inventory/takedelivery', session)
            .then((res) => {
                console.log(res.data)
                dispatch(acceptDeliverySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(acceptDeliveryFailure(err.response.data));

                }
            })
    }
}


export const getAcceptedDelivery = (x) => {

    return (dispatch) => {
        dispatch(getAcceptedDeliveryBegin());

        axios.get(Base_URL + '/inventory/getDelivery' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getAcceptedDeliverySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getAcceptedDeliveryFailure(err.response.data));

                }
            })
    }
}

export const getDelivery = (x) => {

    return (dispatch) => {
        dispatch(getDeliveryBegin());

        axios.get(Base_URL + '/inventory/getDelInvent' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getDeliverySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getDeliveryFailure(err.response.data));

                }
            })
    }
}
