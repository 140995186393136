// Function for Report Manpower 

const getSumManpower  = (data) =>{
    var t  =  data.manpower_details.reduce((x  ,   y  , z)=>{
     if(x.rate ===  null  || x.rate === undefined || x.rate === "")
     {
       x = {rate : 0 } 
       if(y.rate ===  null  || y.rate === undefined || y.rate === "")
       {
         return   x = {rate : parseFloat(x.rate)  + 0} 
       }
       else
       {
         return    x = {rate : parseFloat(x.rate)  + parseFloat(y.rate)} 
       }
     }
     else
     {
       if(y.rate ===  null  || y.rate === undefined || y.rate === "")
       {
         return   x = {rate : parseFloat(x.rate)  + 0} 
       }
       else
       {
       return  x = {rate : parseFloat(x.rate)  + parseFloat(y.rate)} 
       }
     }
   
      
     })
   
     return parseFloat(t.rate).toFixed(2)
   }
   


export const  filterDataManpower = (Wdata) => {

    var Fdata=   Wdata.data;

        for(let i =  0  ; i <  Fdata.length ; i++)
        {

          Fdata[i]['get_sum'] = getSumManpower(Fdata[i])

          if(Fdata[i].manpower_details.length > 1)
          {
              Fdata[i]['man_cat'] = Fdata[i].manpower_details[0].manp_cat + ` (+${Fdata[i].manpower_details.length - 1} More)`
              Fdata[i]['man_name'] = Fdata[i].manpower_details[0].name + ` (+${Fdata[i].manpower_details.length - 1} More)`
          }
          else
          {
            Fdata[i]['man_cat'] = Fdata[i].manpower_details[0].manp_cat
            Fdata[i]['man_name'] = Fdata[i].manpower_details[0].name
          }
        }
      console.log(Fdata)


      return {data : Fdata , total_length : Wdata.total_length , page : Wdata.page}
  } 



  // Function for Material Delivery




  const getSumMD  = (data) =>{
  
    var t  =  data.mat_details.reduce((x  ,   y  , z)=>{
       if(x.amt ===  null  || x.amt === undefined || x.amt === "")
       {
         x = {amt : 0 } 
         if(y.amt ===  null  || y.amt === undefined || y.amt === "")
         {
           return   x = {amt : parseFloat(x.amt)  + 0} 
         }
         else
         {
           return    x = {amt : parseFloat(x.amt)  + parseFloat(y.amt)} 
         }
       }
       else
       {
         if(y.amt ===  null  || y.amt === undefined || y.amt === "")
         {
           return   x = {amt : parseFloat(x.amt)  + 0} 
         }
         else
         {
         return  x = {amt : parseFloat(x.amt)  + parseFloat(y.amt)} 
         }
       }
     
      
     })
     
     return parseFloat(t.amt).toFixed(2)
   }
   



  export const  filterDataMD = (Wdata) => {
    
    var Fdata=   Wdata.data;

    for(let i =  0  ; i <  Fdata.length ; i++)
    {

      Fdata[i]['get_sum'] = getSumMD(Fdata[i])

      if(Fdata[i].mat_details.length > 1)
      {
          Fdata[i]['mat_cat'] = Fdata[i].mat_details[0].mat_type + ` (+${Fdata[i].mat_details.length - 1} More)`
          Fdata[i]['mat_name'] = Fdata[i].mat_details[0].mat_name + ` (+${Fdata[i].mat_details.length - 1} More`
      }
      else
      {
        Fdata[i]['mat_cat'] = Fdata[i].mat_details[0].mat_type
        Fdata[i]['mat_name'] = Fdata[i].mat_details[0].mat_name
      }
    }
  console.log(Fdata)


  return {data : Fdata , total_length : Wdata.total_length , page : Wdata.page}



  } 






  
  // Function for Material Delivery




  const getSumMacD = (data) =>{
  
    
        var t  =  data.machine_details.reduce((x  ,   y  , z)=>{
         if(x.amt ===  null  || x.amt === undefined || x.amt === "")
         {
           x = {amt : 0 } 
           if(y.amt ===  null  || y.amt === undefined || y.amt === "")
           {
             return   x = {amt : parseFloat(x.amt)  + 0} 
           }
           else
           {
             return    x = {amt : parseFloat(x.amt)  + parseFloat(y.amt)} 
           }
         }
         else
         {
           if(y.amt ===  null  || y.amt === undefined || y.amt === "")
           {
             return   x = {amt : parseFloat(x.amt)  + 0} 
           }
           else
           {
           return  x = {amt : parseFloat(x.amt)  + parseFloat(y.amt)} 
           }
         }
       
          
         })
         return parseFloat(t.amt).toFixed(2)
    
   }
   



  export const  filterDataMacD = (Wdata) => {
    
    var Fdata=   Wdata.data;

    for(let i =  0  ; i <  Fdata.length ; i++)
    {

      Fdata[i]['get_sum'] = getSumMacD(Fdata[i])

      if(Fdata[i].machine_details.length > 1)
      {
          Fdata[i]['mac_cat'] = Fdata[i].machine_details[0].mac_type + ` (+${Fdata[i].machine_details.length - 1} More)`
          Fdata[i]['mac_name'] = Fdata[i].machine_details[0].mac_name + ` (+${Fdata[i].machine_details.length - 1} More`
      }
      else
      {
        Fdata[i]['mac_cat'] = Fdata[i].machine_details[0].mac_type
        Fdata[i]['mac_name'] = Fdata[i].machine_details[0].mac_name
      }
    }
  console.log(Fdata)


  return {data : Fdata , total_length : Wdata.total_length , page : Wdata.page}



  } 





