import { GET_Report_LOADING, GET_Report_SUCCESS, GET_Report_FAILURE } from '../../Actions/Types';

console.log('hereeee');
const GetReortAdmin = (state , action) => {
    console.log(action.type) 
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],  
            editError : {},
            editPassData :[{}],
            editPassError : {},
            delData:[{}],
            delError:{}
        }
    }




switch(action.type) {
    
   
    case GET_Report_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case GET_Report_SUCCESS:

    console.log('hereeeeeeeee');
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case GET_Report_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }

    default:
        return state

}
}



export default GetReortAdmin;
