import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal
} from '@material-ui/core';
import Page from '../../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DetailIcon from '@material-ui/icons/FileCopyOutlined';

import tableIcons from '../../../../Config/IconsFile'


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {PDFViewer} from '@react-pdf/renderer'

import invoice from '../../../../components/data/invoice'
import Invoice from '../../../../components/reports/Invoice'
import Invoice2 from '../../../../components/NewOrder'

import {getPurchaseOrder, delPurchaseOrder} from '../../../../Actions/SubAdminActions/PurchaseOrder';

import {cleanPurchaseOrderData} from "../../../../Actions/ActionCreators";

import {getProfileManCre} from '../../../../Actions/SubAdminActions/ProfileManagement'
const  TabPanel = (props)=> {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Logo Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  rootn: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState({show:false, id:"", type:""});
  const [open2, setOpen2] = useState({show:false, invoice:"", type:""});
  
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.procurement ? state.login.data.role_permit.procurement : []);
  const logo = useSelector(state=> state.AddSubProfile && state.AddSubProfile.getData && Array.isArray(state.AddSubProfile.getData) && (state.AddSubProfile.getData).length ? state.AddSubProfile.getData[0].logo : []);
  const handleClose = () =>{

    setOpen({...open, show:false, id:"", type:""});
  }
  
  const handleOpen = (t,id) =>{
  
    setOpen({
      ...open,
      show:true,
      type:t,
      id:id
    });
  
  }
  const handleClose2 = () =>{

    setOpen2({...open2, show:false, id:"", type:""});
  }
  
  const handleOpen2 = (data) =>{
    const invoice = {
      "order_id": data.order_id,
      "logo":logo,
      "name": data.name,
      "mobile": data.mobile,
      "email": data.email,
      "gst": data.gst,
      "address": data.address,
      "site_name": data.site_name,
      "site_address": data.site_address,
      "created_on": data.created_on,
      "delivery_date": data.delivery_date,
      "gst_type":data.gst_type,
      "items":  data.mat_details,
      "type":"material",
      "client_name":data.client_name,
      "client_address":data.client_address,
      "client_email":data.client_email,
      "client_mobile":data.client_mobile,
      "client_gst":data.client_gst,
    }
    setOpen2({
      ...open2,
      show:true,      
      invoice:invoice
    });
  
  }
  const handleOpen3 = (data) =>{
    const invoice = {
      "order_id": data.order_id,
      "name": data.name,
      "logo":logo,
      "address": data.address,
      "mobile": data.mobile,
      "email": data.email,
      "gst": data.gst,
      "site_name": data.site_name,
      "site_address": data.site_address,
      "created_on": data.created_on,
      "delivery_date": data.delivery_date,
      "gst_type":data.gst_type,
      "items":  data.machine_details,
      "type":"machine",
      "client_name":data.client_name,
      "client_address":data.client_address,
      "client_email":data.client_email,
      "client_mobile":data.client_mobile,
      "client_gst":data.client_gst,
    }
    setOpen2({
      ...open2,
      show:true,      
      invoice:invoice
    });
  
  }



  
  const handleConfirm = () =>{
    if(open.id!=="" && open.show!==false && open.type!==""){
      dispatch(delPurchaseOrder(open.type,open.id))

  
      setOpen({
        ...open,
        show:false,
        type:"",
        id:""
      })
    }
    
  }


  const id = useSelector(state => state.login.data.r_subadmin);


  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)

  const listData =  useSelector((state) =>
  state.PurchaseOrder && state.PurchaseOrder.getData &&
  Object.keys(state.PurchaseOrder.getData)&&
  Object.keys(state.PurchaseOrder.getData).length  
      ? state.PurchaseOrder.getData
      : []
  );


  const MatListData =  useSelector((state) =>
      state.PurchaseOrder && state.PurchaseOrder.getData &&
      Object.keys(state.PurchaseOrder.getData) && 
      Object.keys(state.PurchaseOrder.getData).length && 
      "Material_delivery" in state.PurchaseOrder.getData &&
      Array.isArray(state.PurchaseOrder.getData['Material_delivery']) && 
      state.PurchaseOrder.getData['Material_delivery'].length  
      ? (state.PurchaseOrder.getData['Material_delivery']).filter(data => !selected_site || data.site_id == selected_site)
      
      :[]);
  
  const MacListData =  useSelector((state) =>
      state.PurchaseOrder && state.PurchaseOrder.getData &&
      Object.keys(state.PurchaseOrder.getData) && 
      Object.keys(state.PurchaseOrder.getData).length && 
      "Machine_delivery" in state.PurchaseOrder.getData &&
      Array.isArray(state.PurchaseOrder.getData['Machine_delivery']) && 
      state.PurchaseOrder.getData['Machine_delivery'].length  
      ? (state.PurchaseOrder.getData['Machine_delivery']).filter(data => !selected_site || data.site_id == selected_site)
      
      :[]);



      const [values, setValues] = useState({
        MatData:MatListData,
        MacData:MacListData,
        from_date: new Date(Date.now()),
        end_date: new Date(Date.now()),
      })
     
     
     
  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);

  const [tvalue, setTValue] = React.useState(0);

  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
  };


  const Delmsg = useSelector(state => state.PurchaseOrder && state.PurchaseOrder.delData && state.PurchaseOrder.delData.message ? state.PurchaseOrder.delData.message : null  )
  const Delerror = useSelector(state => state.PurchaseOrder && state.PurchaseOrder.delError && state.PurchaseOrder.delError.message ? state.PurchaseOrder.delError.message : null  )





  const handleStartDateChange = (date) => {

    const DataList = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.required_on).format("DD.MM.YYYY")>=moment(date).format("DD.MM.YYYY")) && (moment(data.required_on).format("DD.MM.YYYY")<=moment(values.end_date).format("DD.MM.YYYY")));
  
    setValues({
      ...values,
      ['from_date']:date,
      ['data']:DataList
    })
    
  
  };
   
  const handleEndDateChange = (date) => {
  
  
    const DataList = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.required_on).format("DD.MM.YYYY")>=moment(values.from_date).format("DD.MM.YYYY")) && (moment(data.required_on).format("DD.MM.YYYY")<=moment(date).format("DD.MM.YYYY")));
  
    setValues({
      ...values,
      ['end_date']:date,
      ['data']:DataList
    })
    
  
  };
    
  


  

  
useEffect( () => {

    dispatch(getPurchaseOrder(id))
    dispatch(getProfileManCre(id))

    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

      dispatch(cleanPurchaseOrderData())
      dispatch(getPurchaseOrder(id))

    })


    
  }, [ Delmsg  ,Delerror ])


  

  // const onEdit = (olData) =>{
  // //   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
  // }



  const onViewMac = (olData) =>{
    navigate('/app/purchaseorderlist_macdetail/'+olData.rm_id , { state: olData })
  }
  
  // const onDel = (t, id) =>{
  //   console.log("id", id, "type", t)
  //   dispatch(delPurchaseOrder(t,id))
  // }
  const handleMatView = (data) =>{
    navigate('/app/purchaseorderlist_matdetail/'+data.dy_id, {state:data})
  }
  
  

  const addNewBornbutton = () =>{
    navigate('/app/purchaseorderlist_add')
    console.log("prchase order")
    
  }
  return (
    <Page className={classes.root} title="Purchase Orders">
      <ToastContainer    />

      
      
      <Modal className={classes.modal} open={open2.show} onClose={handleClose2} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">

          <PDFViewer 
            width="1020" 
            height="720" 
            style={
              {
                display:"table", 
                // margin:"auto", 
                // paddingBottom:"7%", 
                border:"none"
                }
            }
            >
              
            <Invoice2  invoice={open2.invoice}/>
          </PDFViewer> 
          
        </Modal>
      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Order??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      <div className={classes.rootn}>
      <AppBar position="static">
        <Tabs value={tvalue} onChange={handleChangetab} variant='fullWidth' textColor='inherit' aria-label="full-width tabs example">
          <Tab label="Material Order" {...a11yProps(0)} />
          <Tab label="Machine Order" {...a11yProps(1)} />
          
        </Tabs>
      </AppBar>

      
      <TabPanel value={tvalue} index={0}>
      <Container maxWidth={false}>
      {role_permit[3] ? (
  <Box display="flex" justifyContent="flex-end">              
    <Button onClick={addNewBornbutton} color="primary" variant="contained">
      Create
    </Button>
  </Box>
) : (
  <></>
)}
          
           
          
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Material Orders"
      columns={[
        
        { title: 'Supplier', field: 'name',editable: "never", },                          
        { title: 'Order Id', field: 'order_id',editable: "never", },        
        { title: 'Delivered to', field: 'accepted_by',editable: "never", },     
        { title: 'Approved By', field: 'approved_by',editable: "never", },              
        { title: 'Delivery Date', field: 'delivery_date',editable: "never",         
        render: rowData => (
            moment(rowData.delivery_date).format("DD/MM/YYYY")
          ),        
        },
        { title: 'Received Date', field: 'recieve_date',editable: "never", 
        render: rowData => (
          rowData.recieve_date!==null && rowData.recieve_date!==""?
          <Typography variant='overline'>{moment(rowData.recieve_date).format("DD/MM/YYYY")}</Typography>
            
          :<Typography variant='overline'>Not Available</Typography>
          
          ), },                               
        { title: 'Status', 
            render: rowData => (
             rowData.status==="Delivered" ? 
             <>
             <Typography style={{color:"blue"}} variant='overline'>Delivered</Typography>
             </>             
             :
             <>
             <Typography style={{color:"green"}} variant='overline'>Ordered</Typography>
             </>
             
          
            )
        },    
        { title: 'Priority', field: 'priority',editable: "never", },     

        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
      
      {rowData.status==="Pending" && role_permit[2] ? 
      <Grid item xs={4}>
        <DeleteIcon onClick={()=>{handleOpen("Material_delivery", rowData.dy_id)}} style={{cursor:"pointer"}} />
      </Grid>
      :<></>
      }
      <Grid item xs={4}>
        <VisibilityIcon onClick={() => {handleMatView(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      <Grid item xs={4}>
        <DetailIcon onClick={()=>{handleOpen2(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      </Grid>
          ),
        },

      ]}
      data={MatListData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                emptyRowsWhenPaging:false,
              }}

    />
             
            </Card>
          </Box>
        </Container>
     
      </TabPanel>
      <TabPanel value={tvalue} index={1}>
      <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Create
              </Button>
            </Box>

            {/* <div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  // value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
</ThemeProvider>

        </Grid>
       

        
<Grid item md={6} lg={3}  xs={3}>

<FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
<InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
<Select
fullWidth
labelId="demo-simple-select-outlined-label"
id="demo-simple-select-outlined"
value={values.country}
onChange={handleChanged}
label="Export As"
style={{width:"100%" }}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 1">Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem>


</Select>
</FormControl>
</Grid>
        
      </Grid>
    </div> */}
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Machine Orders"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ? rowData.images[0]:""}
        //   >
        //   </Avatar>
        //   ),\
        // 
        { title: 'Site Name', field: 'site_name',editable: "never", },  
        { title: 'Order Id', field: 'order_id',editable: "never", },     
        { title: 'Delivered to', field: 'accepted_by',editable: "never", },     
        { title: 'Approved By', field: 'approved_by',editable: "never", },     
        { title: 'Delivery Date', field: 'delivery_date',editable: "never",
        render: rowData => (
            moment(rowData.delivery_date).format("DD/MM/YYYY")
          ), },     
          { title: 'Received Date', field: 'recieve_date',editable: "never", 
        render: rowData => (
          rowData.recieve_date!==null && rowData.recieve_date!==""?
          <Typography variant='overline'>{moment(rowData.recieve_date).format("DD/MM/YYYY")}</Typography>
            
          :<Typography variant='overline'>Not Available</Typography>
          
          ), },   
            
        { title: 'Priority', field: 'priority',editable: "never", },     
        { title: 'Status', 
            render: rowData => (
             rowData.status==="Delivered" ? 
             <>
             <Typography style={{color:"blue"}} variant='overline'>Delivered</Typography>
             </>             
             :
             <>
             <Typography style={{color:"green"}} variant='overline'>Ordered</Typography>
             </>
             
          
            )
        },     
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
            {rowData.status==="Pending" ? 
              <Grid item xs={4}>
              <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
              
              <Grid item xs={4}>
                <VisibilityIcon onClick={()=>{onViewMac(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              
              <Grid item xs={4}>
                <DetailIcon onClick={()=>{handleOpen3(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              
            </Grid>
          ),
        },

      ]}
      data={MacListData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </TabPanel>      
         
    </div>
    </Page>
  );
};

export default AddBusiness;
