import {ADD_GST_LOADING , ADD_GST_SUCCESS , ADD_GST_FAILURE } from '../../Actions/Types';
import {GET_GST_LOADING , GET_GST_SUCCESS , GET_GST_FAILURE } from '../../Actions/Types';

import {EDIT_GST_LOADING , EDIT_GST_SUCCESS , EDIT_GST_FAILURE } from '../../Actions/Types';
import {DEL_GST_LOADING , DEL_GST_SUCCESS , DEL_GST_FAILURE } from '../../Actions/Types';
import {CLEAN_ALL_DATA , CLEAN_GST_DATA} from '../../Actions/Types';


function rmOrder(allOrders, index) {
    if (Array.isArray(allOrders)) {
      let newOrders = allOrders;
      newOrders.splice(index, 1);
      return newOrders;
    } else {
      return allOrders;
    }
  }



const AddGst = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData :[{}],
            editError : {},
            delData  : [{}] ,
            delError: {}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            getData:[{}],
            getError:{}
            
        }


        case CLEAN_GST_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            editData : [{}],
            editError : {},
            delData : [{}], 
            delError:{}
        }

    
    case ADD_GST_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            data : [{}]
            
        }
    case ADD_GST_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case ADD_GST_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }



         
    case EDIT_GST_LOADING:
        return {
            ...state,
            loading:true,
            editError: {},
            editData : [{}]
            
        }
    case EDIT_GST_SUCCESS:
        return {
            ...state,
            loading:false,
            editError:{},
            editData:action.data
        }
    case EDIT_GST_FAILURE:
        return {
            ...state,
            loading:false,
            editError:action.error,
            editData:{},
        }



    case DEL_GST_LOADING:
        return {
            ...state,
            loading:true,
            delError: {},
            delData : [{}]
            
        }
    case DEL_GST_SUCCESS:
        return {
            ...state,
            loading:false,
            delError:{},
            delData:action.data,
            getData :rmOrder(state.getData, action.index)
        }
    case DEL_GST_FAILURE:
        return {
            ...state,
            loading:false,
            delError:action.error,
            delData:{},
        }




        case GET_GST_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                
            }
        case GET_GST_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data
            }
        case GET_GST_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   


    default:
        return state

}
}



export default AddGst;
