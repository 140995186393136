// import axios from "../Config/Interceptor1";


import axios from 'axios'


import { Base_URL } from '../Config/BaseUrlConfig'


import {GenotpBegin , GenotpSuccess , GenotpFailure} from './ActionCreators'
import {verifyotpBegin , verifyotpSuccess , verifyotpFailure} from './ActionCreators'
import {resetPassBegin , resetPassSuccess , resetPassFailure} from './ActionCreators'


export const Genotp = (data) => {
    return (dispatch) => {
        dispatch(GenotpBegin());

        axios.post(Base_URL + '/subAdmin/genotp',data)
            .then((res) => {
                console.log(res.data)
                
                dispatch(GenotpSuccess(res.data));
            }).catch((err) => {
              console.log(err)
                dispatch(GenotpFailure(err));
            })
    }
}








export const verifyotp = (data) => {
    return (dispatch) => {
        dispatch(verifyotpBegin());

        axios.post(Base_URL + '/subAdmin/verifyotp',data)
            .then((res) => {
            //   console.log(res)
                dispatch(verifyotpSuccess(res.data));
            }).catch((err) => {
            //   console.log(err)
                dispatch(verifyotpFailure(err.response.data));
            })
    }
}








export const resetPass = (data) => {
    return (dispatch) => {
        dispatch(resetPassBegin());

        axios.post(Base_URL + '/subAdmin/otppassword',data)
            .then((res) => {
            //   console.log(res)
                dispatch(resetPassSuccess(res.data));
            }).catch((err) => {
            //   console.log(err)
                dispatch(resetPassFailure(err.response.data));
            })
    }
}







