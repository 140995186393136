import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getExportFileBlob} from '../../../../Config/excelPdfDownload'

import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import DetailIcon from '@material-ui/icons/List';


import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { ThemeProvider } from "@material-ui/styles";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {grnOrderReq} from '../../../../Actions/Inventory/GRN';
import {getStock} from '../../../../Actions/Inventory/Stock'
import {cleanOrderActionData} from '../../../../Actions/Inventory/ActionCreators'


const  TabPanel = (props)=> {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}





const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  issueButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const navigate = useNavigate();
  
  // const a_id = useSelector(state => state.login && state.login.data 
  //   && state.login.data.admin_id && (state.login.data.r_subadmin !==null 
  // && state.login.data.r_subadmin !==undefined && state.login.data.r_subadmin !=="")
  // ? state.login.data.r_subadmin : state.login.data.r_subadmin)
  const a_id = useSelector((state)=> state.login && state.login.data && state.login.data.r_subadmin ? state.login.data.r_subadmin : "")

  const [val, setVal] = useState({
    type:'Material'
  })


  const filterData =(data) =>{
    var result = []
    if(Array.isArray(data) && data.length)
      result = data.filter(data => (!selected_site || data.site_id == selected_site));


    return result;
  }

  const [tvalue, setTValue] = React.useState(0);

  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
  };

  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)

  const MatListData =  useSelector((state) =>
      state.GRN && state.GRN.getData &&
      Object.keys(state.GRN.getData) && 
      Object.keys(state.GRN.getData).length && 
      "Material" in state.GRN.getData &&
      Array.isArray(state.GRN.getData['Material']) && 
      state.GRN.getData['Material'].length  
      ? (state.GRN.getData['Material'])
      
      :[]);
  
  const MacListData =  useSelector((state) =>
      state.GRN && state.GRN.getData &&
      Object.keys(state.GRN.getData) && 
      Object.keys(state.GRN.getData).length && 
      "Machine" in state.GRN.getData &&
      Array.isArray(state.GRN.getData['Machine']) && 
      state.GRN.getData['Machine'].length  
      ? (state.GRN.getData['Machine'])
      
      :[]);


  // const listData = MatListData.concat(MacListData)

    const listData = useSelector(state => state.GRN && state.GRN.getData &&
      Object.keys(state.GRN.getData) && 
      Object.keys(state.GRN.getData).length ? 
      (state.GRN.getData) : {})

  const StockData = useSelector(state => state.Stock.getData && 
    Array.isArray(state.Stock.getData) && state.Stock.getData.length ?
    filterData((state.Stock.getData).filter(data => data.item_quan!==0)) : filterData([]))


    const addmsg = useSelector(state => state.GRN.data && state.GRN.data.message ? state.GRN.data.message : null  )
    const adderror = useSelector(state => state.GRN.error && state.GRN.error.message ? state.GRN.error.message : null  )
    
  

  useEffect(() => {
    dispatch(grnOrderReq(a_id));
    dispatch(getStock(a_id));
    let p = new Promise((resolve, reject) => {
      if(addmsg!==null && typeof(addmsg) === 'string')
      {
        tostS(addmsg)
      }
      if(adderror!==null && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }


      resolve()
   })
   p.then(() => {
      dispatch(cleanOrderActionData())
   })
  }, [addmsg, adderror])



  const handleCheck = (data) => {
    if(val.type==="Material"){
      navigate("/app/inventory/grn/approve-material/"+data.order_id, {state:data})
      
    }else{
      navigate("/app/inventory/grn/approve-machine/"+data.order_id, {state:data})

    }
  }

  const handleChangeType = (event) => {
    setVal({
      ['type']:event.target.value
    })
  }



  

  const issueButton = () =>{
    navigate('/app/inventory/grn/issue')
    
    
  }

  return (
    <>
      <Page className={classes.root} title="GRN">
      <ToastContainer    />
      
      <AppBar position="static">
        <Tabs value={tvalue} onChange={handleChangetab} variant="fullWidth" textColor="inherit" aria-label="full width tabs example">
          <Tab label="Order requests" {...a11yProps(0)} />
          <Tab label="Available Inventory" {...a11yProps(1)} />          
        </Tabs>
      </AppBar>
      
      
      <TabPanel value={tvalue} index={0}>
      <Container maxWidth={false}>
      <Grid container>
          <Grid item md={3} xs={6} lg={3}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Item Type</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={val.type}
                onChange={handleChangeType}
                label="Item Type"                                      
              >          
                  <MenuItem value="Material">Material</MenuItem>
                  <MenuItem value="Machine">Machine</MenuItem>
              </Select>
            </FormControl>
          </Grid>  
          <Grid item md={6} xs={0} lg={6}></Grid>        
          <Grid item md={3} xs={6} lg={3}>
              <Button 
              color="primary" 
              variant="contained"
              className={classes.issueButton} 
              onClick = {() => issueButton()}
              >
                Issue Item
            </Button>
          </Grid>   

          </Grid>               
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>
              <CardContent>
              <MaterialTable
                icons={tableIcons}      
                title="Request List"
                columns={[
                
                  { title: 'Order Id', field: 'order_id',editable: "never", },        
                  { title: 'Priority', field: 'priority',editable: "never", },     
                  { title: 'Approved by', field: 'accepted_by',editable: "never", },     
                  { title: 'Requested By', field: 'name',editable: "never", },     
                  { title: 'Required Date',
                    render:(rowData) => (
                      rowData.required_date  ? 
                      moment(rowData.required_date).format("DD.MM.YYYY"):
                      moment(rowData.required_on).format("DD.MM.YYYY")
                      
                      
                    )
                  
                  },        
                  { title:"Actions", 
                  render:(rowData) => (
                    
                    <Grid container spacing={3}>
                    
                    
                    <Grid item md={4}>      
                      <Tooltip title="Accept/Reject">
                        <CheckIcon onClick={()=>{handleCheck(rowData)}} />

                      </Tooltip>                
                    </Grid>
                    </Grid>
                  )

                  }      
                  

                ]}
                data={filterData(listData[val.type])}
                  options={{
                          selection: false,
                          textAlign: "center",
                          headerStyle: { textAlign: "left" },
                          rowStyle: { textAlign: "center" }
                        }}

              />
             </CardContent>
            </Card>
          </Box>
        </Container>
     
      </TabPanel>
      <TabPanel value={tvalue} index={1}>
      <Container maxWidth={false}>
          
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Available Inventory"
      columns={[
       
        { title: 'Site Name', field: 'site_name',editable: "never", },  
        { title: 'Item Type', field: 'item_type',editable: "never", },     
        { title: 'Item Category', field: 'item_cat',editable: "never", },     
        { title: 'Item Name', field: 'item_name',editable: "never", },     
        { title: 'Quantity', 
          render: rowData => (
            <Typography variant="text">
              {rowData.item_quan} {rowData.unit_name}
            </Typography>
          )
          }, 
        { title: 'Date', render:rowData => (moment(rowData.created_on).format("DD.MM.YYYY")) }, 
      ]}
      data={StockData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </TabPanel>      
         
    
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
