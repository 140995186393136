
import axios from '../../Config/Interceptor2'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {grnOrderReqBegin , grnOrderReqSuccess , grnOrderReqFailure} from './ActionCreators'
import {grnIssueBegin , grnIssueSuccess , grnIssueFailure} from './ActionCreators'
import {grnOrderActionBegin , grnOrderActionSuccess , grnOrderActionFailure} from './ActionCreators'



export const grnOrderReq = (x) => {

    return (dispatch) => {
        dispatch(grnOrderReqBegin());

        axios.get(Base_URL + '/inventory/grnOrderRequest' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(grnOrderReqSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(grnOrderReqFailure(err.response.data));

                }
            })
    }
}


export const grnIssue = (data) => {

    return (dispatch) => {
        dispatch(grnIssueBegin());

        axios.post(Base_URL + '/inventory/issueMaterial' ,data)
            .then((res) => {
                dispatch(grnIssueSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(grnIssueFailure(err.response.data));

                }
            })
    }
}


export const grnOrderAction = (data) => {

    return (dispatch) => {
        dispatch(grnOrderActionBegin());

        axios.post(Base_URL + '/inventory/grnOrderAction' , data)
            .then((res) => {
                dispatch(grnOrderActionSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(grnOrderActionFailure(err.response.data));

                }
            })
    }
}