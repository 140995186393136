import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/images/thekedar-logo-white.svg"
      style={{height:50}}
      {...props}
    />
  );
};

export default Logo;
