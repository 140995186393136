
// Inventory imports



import { ADD_STOCK_LOADING , ADD_STOCK_SUCCESS , ADD_STOCK_FAILURE , CLEAN_STOCK_DATA} from './Types';
import { GET_STOCK_LOADING , GET_STOCK_SUCCESS , GET_STOCK_FAILURE } from './Types';
import { EDIT_STOCK_LOADING , EDIT_STOCK_SUCCESS , EDIT_STOCK_FAILURE } from './Types';
import { DEL_STOCK_LOADING , DEL_STOCK_SUCCESS , DEL_STOCK_FAILURE } from './Types';



import { ADD_STOCK_TRANSFER_LOADING , ADD_STOCK_TRANSFER_SUCCESS , ADD_STOCK_TRANSFER_FAILURE , CLEAN_STOCK_TRANSFER_DATA} from './Types';
import { GET_STOCK_TRANSFER_LOADING , GET_STOCK_TRANSFER_SUCCESS , GET_STOCK_TRANSFER_FAILURE } from './Types';
import { GET_ITEM_DATA_LOADING , GET_ITEM_DATA_SUCCESS , GET_ITEM_DATA_FAILURE } from './Types';
import { EDIT_STOCK_TRANSFER_LOADING , EDIT_STOCK_TRANSFER_SUCCESS , EDIT_STOCK_TRANSFER_FAILURE } from './Types';
import { DEL_STOCK_TRANSFER_LOADING , DEL_STOCK_TRANSFER_SUCCESS , DEL_STOCK_TRANSFER_FAILURE } from './Types';


import { GRN_ORDER_REQ_LOADING , GRN_ORDER_REQ_SUCCESS , GRN_ORDER_REQ_FAILURE } from './Types';
import { GRN_ISSUE_LOADING , GRN_ISSUE_SUCCESS , GRN_ISSUE_FAILURE } from './Types';
import { GRN_ORDER_ACTION_LOADING , GRN_ORDER_ACTION_SUCCESS , GRN_ORDER_ACTION_FAILURE, CLEAN_ORDER_ACTION_DATA } from './Types';


import { ACCEPT_DELIVERY_LOADING , ACCEPT_DELIVERY_SUCCESS , ACCEPT_DELIVERY_FAILURE , CLEAN_DELIVERY_DATA} from './Types';
import { ISSUE_DELIVERY_LOADING , ISSUE_DELIVERY_SUCCESS , ISSUE_DELIVERY_FAILURE} from './Types';
import { GET_DELIVERY_LOADING , GET_DELIVERY_SUCCESS , GET_DELIVERY_FAILURE } from './Types';
import { GET_ACCEPTED_DELIVERY_LOADING , GET_ACCEPTED_DELIVERY_SUCCESS , GET_ACCEPTED_DELIVERY_FAILURE } from './Types';

import { GET_STOCK_SUMMARY_LOADING , GET_STOCK_SUMMARY_SUCCESS , GET_STOCK_SUMMARY_FAILURE } from './Types';
import { GET_CONSUMED_DATA_LOADING , GET_CONSUMED_DATA_SUCCESS , GET_CONSUMED_DATA_FAILURE } from './Types';
// Inventory start from here






export const addStockBegin = () => ({
   type : ADD_STOCK_LOADING
})

export const addStockSuccess = (data) => ({
   type : ADD_STOCK_SUCCESS,
   data
})

export const addStockFailure = (error) => ({
   type : ADD_STOCK_FAILURE,
   error
   
})

export const editStockBegin = () => ({
   type : EDIT_STOCK_LOADING
})

export const editStockSuccess = (data) => ({
   type : EDIT_STOCK_SUCCESS,
   data
})

export const editStockFailure = (error) => ({
   type : EDIT_STOCK_FAILURE,
   error
   
})


export const getStockBegin = () => ({
   type : GET_STOCK_LOADING
})

export const getStockSuccess = (data) => ({
   type : GET_STOCK_SUCCESS,
   data
})

export const getStockFailure = (error) => ({
   type : GET_STOCK_FAILURE,
   error
   
})

export const delStockBegin = () => ({
   type : DEL_STOCK_LOADING
})

export const delStockSuccess = (data) => ({
   type : DEL_STOCK_SUCCESS,
   data
})

export const delStockFailure = (error) => ({
   type : DEL_STOCK_FAILURE,
   error
   
})

export const cleanStockData = () => ({
   type : CLEAN_STOCK_DATA
   
})


export const addStockTransferBegin = () => ({
   type : ADD_STOCK_TRANSFER_LOADING
})

export const addStockTransferSuccess = (data) => ({
   type : ADD_STOCK_TRANSFER_SUCCESS,
   data
})

export const addStockTransferFailure = (error) => ({
   type : ADD_STOCK_TRANSFER_FAILURE,
   error
   
})

export const editStockTransferBegin = () => ({
   type : EDIT_STOCK_TRANSFER_LOADING
})

export const editStockTransferSuccess = (data) => ({
   type : EDIT_STOCK_TRANSFER_SUCCESS,
   data
})

export const editStockTransferFailure = (error) => ({
   type : EDIT_STOCK_TRANSFER_FAILURE,
   error
   
})


export const getStockTransferBegin = () => ({
   type : GET_STOCK_TRANSFER_LOADING
})

export const getStockTransferSuccess = (data) => ({
   type : GET_STOCK_TRANSFER_SUCCESS,
   data
})

export const getStockTransferFailure = (error) => ({
   type : GET_STOCK_TRANSFER_FAILURE,
   error
   
})


export const getItemDataBegin = () => ({
   type : GET_ITEM_DATA_LOADING
})

export const getItemDataSuccess = (data) => ({
   type : GET_ITEM_DATA_SUCCESS,
   data
})

export const getItemDataFailure = (error) => ({
   type : GET_ITEM_DATA_FAILURE,
   error
   
})

export const delStockTransferBegin = () => ({
   type : DEL_STOCK_TRANSFER_LOADING
})

export const delStockTransferSuccess = (data) => ({
   type : DEL_STOCK_TRANSFER_SUCCESS,
   data
})

export const delStockTransferFailure = (error) => ({
   type : DEL_STOCK_TRANSFER_FAILURE,
   error
   
})

export const cleanStockTransferData = () => ({
   type : CLEAN_STOCK_TRANSFER_DATA
   
})




export const grnOrderReqBegin = () => ({
   type : GRN_ORDER_REQ_LOADING
})

export const grnOrderReqSuccess = (data) => ({
   type : GRN_ORDER_REQ_SUCCESS,
   data
})

export const grnOrderReqFailure = (error) => ({
   type : GRN_ORDER_REQ_FAILURE,
   error
   
})



export const grnIssueBegin = () => ({
   type : GRN_ISSUE_LOADING
})

export const grnIssueSuccess = (data) => ({
   type : GRN_ISSUE_SUCCESS,
   data
})

export const grnIssueFailure = (error) => ({
   type : GRN_ISSUE_FAILURE,
   error
   
})


export const grnOrderActionBegin = () => ({
   type : GRN_ORDER_ACTION_LOADING
})

export const grnOrderActionSuccess = (data) => ({
   type : GRN_ORDER_ACTION_SUCCESS,
   data
})

export const grnOrderActionFailure = (error) => ({
   type : GRN_ORDER_ACTION_FAILURE,
   error
   
})



export const cleanOrderActionData = () => ({
   type : CLEAN_ORDER_ACTION_DATA
   
})



export const acceptDeliveryBegin = () => ({
   type : ACCEPT_DELIVERY_LOADING
})

export const acceptDeliverySuccess = (data) => ({
   type : ACCEPT_DELIVERY_SUCCESS,
   data
})

export const acceptDeliveryFailure = (error) => ({
   type : ACCEPT_DELIVERY_FAILURE,
   error
   
})


export const issueDeliveryBegin = () => ({
   type : ISSUE_DELIVERY_LOADING
})

export const issueDeliverySuccess = (data) => ({
   type : ISSUE_DELIVERY_SUCCESS,
   data
})

export const issueDeliveryFailure = (error) => ({
   type : ISSUE_DELIVERY_FAILURE,
   error
   
})



export const getDeliveryBegin = () => ({
   type : GET_DELIVERY_LOADING
})

export const getDeliverySuccess = (data) => ({
   type : GET_DELIVERY_SUCCESS,
   data
})

export const getDeliveryFailure = (error) => ({
   type : GET_DELIVERY_FAILURE,
   error
   
})



export const getAcceptedDeliveryBegin = () => ({
   type : GET_ACCEPTED_DELIVERY_LOADING
})

export const getAcceptedDeliverySuccess = (data) => ({
   type : GET_ACCEPTED_DELIVERY_SUCCESS,
   data
})

export const getAcceptedDeliveryFailure = (error) => ({
   type : GET_ACCEPTED_DELIVERY_FAILURE,
   error
   
})


export const cleanDeliveryData = () => ({
   type : CLEAN_DELIVERY_DATA
   
})


export const getStockSummaryBegin = () => ({
   type : GET_STOCK_SUMMARY_LOADING
})

export const getStockSummarySuccess = (data) => ({
   type : GET_STOCK_SUMMARY_SUCCESS,
   data
})

export const getStockSummaryFailure = (error) => ({
   type : GET_STOCK_SUMMARY_FAILURE,
   error
   
})


export const getConsumedDataBegin = () => ({
   type : GET_CONSUMED_DATA_LOADING
})

export const getConsumedDataSuccess = (data) => ({
   type : GET_CONSUMED_DATA_SUCCESS,
   data
})

export const getConsumedDataFailure = (error) => ({
   type : GET_CONSUMED_DATA_FAILURE,
   error
   
})

