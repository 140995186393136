import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
} from "@material-ui/core";
import Page from "../../../../src/components/Page";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import { ToastContainer } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";

import { addPlan } from "../../../Actions/SuperAdminActions/AddPlan";
import { cleanPlanData } from "../../../Actions/ActionCreators";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  city: "Los Angeles",
  country: "USA",
  jobTitle: "Senior Developer",
  name: "Upload Buisness Image",
  timezone: moment(Date.now()).format("DD/MM/YY"),
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250,
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: false,
  });

  const handleChangeToggle = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const handleChanged = (event) => {
    setValues({ ...values, ["country"]: event.target.value });
  };
  const handleChangee = (event) => {
    setValues({ ...values, ["state"]: event.target.value });
  };
  const handleChangef = (event) => {
    setValues({ ...values, ["storage"]: event.target.value });
  };
  const handleChanget = (event) => {
    setValues({ ...values, ["type_of_business"]: event.target.value });
  };

  const id = useSelector((state) => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  const [values, setValues] = useState({
    admin_id: id,
    added_date: selectedDate,
    added_time: selectedDate,
    plan_name: "",
    number_of_sites: "",
    is_geo_api: false,
    is_whatsapp_api: false,
    price: "",
    is_active: false,
    storage: "",
    duration: "",
    plan_number: "",
    users: "",

    fixImg: "/static/images/avatars/upload.png",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (date) => {
    console.log(date);
  };

  const handleChangec = (event) => {
    console.log(event.target.checked);
    setValues({ ...values, ["mobile_visible"]: event.target.checked });
  };

  const handleSubmit = () => {

    if(values.plan_name==="" || values.plan_name===null || values.plan_name===undefined){
      tostE("Please enter plan name!!")
    }else if(values.number_of_sites==="" || values.number_of_sites===null || values.number_of_sites===undefined){
      tostE("Please enter number of site!!")
    }else if(values.price==="" || values.price===null || values.price===undefined){
      tostE("Please enter plan price!!")
    }else if(values.duration==="" || values.duration===null || values.duration===undefined){
      tostE("Please enter plan duration!!")
    }else if(values.storage==="" || values.storage===null || values.storage===undefined){
      tostE("Please Select Storage!!")
    }else if(values.users==="" || values.users===null || values.users===undefined){
      tostE("Please Enter Number of Users!!")
    }else{

      dispatch(addPlan(values));
      setValues({
        ...values,
        plan_name: "",
        number_of_sites: "",
        is_geo_api: state.checkedA,
        is_whatsapp_api: state.checkedB,
        price: "",
        is_active: state.checkedC,
        storage: "",
        duration: "",
        plan_number: "",
        users: "",
      });


    }
  };

  // useEffect( () => {
  // dispatch(getBusinessCat())

  // dispatch(getCountryData())

  // }, [])

  const addmsg = useSelector((state) =>
    state.AddPlan.data && state.AddPlan.data.status
      ? state.AddPlan.data.status
      : null
  );
  const adderror = useSelector((state) =>
    state.AddPlan.error && state.AddPlan.error.message
      ? state.AddPlan.error.message
      : null
  );

  useEffect(() => {
    let x = new Promise((resolve, reject) => {
      if (addmsg === 200) {
        tostS("Added Successfully");
      }
      resolve();
    });
    x.then(() => {
      dispatch(cleanPlanData());
    });
  }, [addmsg]);

  return (
    <Page className={classes.root} title="Add Plan">
      <ToastContainer />

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <form
              autoComplete="off"
              noValidate
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader subheader="" title="Add Plan" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Plan Name"
                        name="plan_name"
                        onChange={handleChange}
                        required
                        value={values.plan_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Plan Number"
                        name="plan_number"
                        onChange={handleChange}
                        required
                        value={values.plan_number}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="number"  InputProps={{inputProps:{min:1}}}
                        label="Number of Sites"
                        name="number_of_sites"
                        onChange={handleChange}
                        required
                        value={values.number_of_sites}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Users"
                        name="users"
                        onChange={handleChange}
                        required
                        value={values.users}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="number"  InputProps={{inputProps:{min:1}}}
                        label="Price"
                        name="price"
                        onChange={handleChange}
                        required
                        value={values.price}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="number"  InputProps={{inputProps:{min:1}}}
                        label="Duration (In Days)"
                        name="duration"
                        onChange={handleChange}
                        required
                        // type="number"  InputProps={{inputProps:{min:1}}}
                        value={values.duration}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Storage Allocated
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.storage}
                          onChange={handleChangef}
                          label="Storage Allocated"
                        >                          
                          <MenuItem value="100 MB">100 MB</MenuItem>
                          <MenuItem value="10 GB">10 GB</MenuItem>
                          <MenuItem value="20 GB">20 GB</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}></Grid>

                    <Grid item md={6} xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.is_geo_api}
                              onChange={handleChangeToggle}
                              name="is_geo_api"
                            />
                          }
                          label="Geo Coding Api"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.is_whatsapp_api}
                              onChange={handleChangeToggle}
                              name="is_whatsapp_api"
                            />
                          }
                          label="Whatsapp Api"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.is_active}
                              onChange={handleChangeToggle}
                              name="is_active"
                            />
                          }
                          label="Active/Inactive"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
