import React, { useRef, useState, useEffect  , useCallback} from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {ClientManRepoStartDate, ClientManRepoEndDate} from '../../../Actions/ActionCreators'

import {manpowerReport} from '../../../Actions/Client/ManpowerReport'

import {getExportFileBlob} from '../../../Config/excelPdfDownload'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const useStyles3 = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  rootq: {
    width: '100%',
  },
  headingq: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeadingq: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  
}));


const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const tableRef = React.createRef();
  const navigate = useNavigate();




  

  



  

 
  
  
  const [values, setValues] = useState({
   
    finalFlag :false,
    start_date:"",

  
  
    });
  
  
  
  
 

  
  const start_date = useSelector(state => state.ClientDateFilters && state.ClientDateFilters.ManRepoStartDate ? state.ClientDateFilters.ManRepoStartDate:new Date(Date.now()))
  const end_date = useSelector(state => state.ClientDateFilters && state.ClientDateFilters.ManRepoEndDate ? state.ClientDateFilters.ManRepoEndDate:new Date(Date.now()))
    


  const  filterData = (Wdata,s_date, e_date) => {

    
    
    var Fdata = []
    if(Array.isArray(Wdata) && Wdata.length){
      var jt  = new Promise((resolve , reject)=>{

//         Fdata = Wdata.filter(data => (data.status==="Accepted") && (moment(data.date).format("YYYY.MM.DD")>=moment(s_date).format("YYYY.MM.DD")) && (moment(data.date).format("YYYY.MM.DD")<=moment(e_date).format("YYYY.MM.DD")));
// console.log(Fdata)
Fdata = Wdata
      resolve()
      })

      jt.then(()=>{

        
        for(let i =  0  ; i <  Fdata.length ; i++)
        {

          Fdata[i]['get_sum'] = getSum(Fdata[i])

          if(Fdata[i].manpower_details.length > 1)
          {
              Fdata[i]['man_cat'] = Fdata[i].manpower_details[0].manp_cat + ` ${Fdata[i].manpower_details.length - 1} More`
              Fdata[i]['man_name'] = Fdata[i].manpower_details[0].name + ` ${Fdata[i].manpower_details.length - 1} More`
          }
          else
          {
            Fdata[i]['man_cat'] = Fdata[i].manpower_details[0].manp_cat
            Fdata[i]['man_name'] = Fdata[i].manpower_details[0].name
          }
        }

      })
      console.log(Fdata)


      return Fdata
    }else{

      return Fdata
    }
    
  
  } 
  
//  const listData = useSelector((state) =>
//   state.ClientAllGet && state.ClientAllGet.manpowerReportData.data &&
//     state.ClientAllGet.manpowerReportData.data.length &&
//     Array.isArray(state.ClientAllGet.manpowerReportData.data)
//       ? filterData(state.ClientAllGet.manpowerReportData.data , start_date, end_date)
//       : filterData([], start_date, end_date)
//   );


const testSingle  =  (dj) =>{
  return dj
}


const loading  = useSelector((state)=> state.ClientAllGet && state.ClientAllGet.loading)

  const listData = useSelector((state) =>
  state.ClientAllGet && state.ClientAllGet.manpowerReportData.data &&
  Object.keys(state.ClientAllGet.manpowerReportData).length &&
    Array.isArray(state.ClientAllGet.manpowerReportData.data)
      ? state.ClientAllGet.manpowerReportData.data
      : []
  );

  


const countD  =  useSelector((state) => state.ClientAllGet && 
state.ClientAllGet.manpowerReportData.data &&
Array.isArray(state.ClientAllGet.manpowerReportData.data) ? 
parseInt(state.ClientAllGet.manpowerReportData.total_length) : 0 )


const countF  =  useSelector((state) => state.ClientAllGet && 
state.ClientAllGet.manpowerReportData.data &&
Array.isArray(state.ClientAllGet.manpowerReportData.data) ? 
parseInt(state.ClientAllGet.manpowerReportData.page) : 0 )



const sub_admin_id =  useSelector((state) => state.login.data.sub_admin_id)
const site_id =  useSelector((state) => state.login.data.site_id)

const [pagination , setPagination] = useState({
  per_page : 5,
  page : countF,

})
 
  const handleStartDateChange = (date) => {
    var t = {
      sub_admin_id : sub_admin_id,
                        page : pagination.page, 
                        per_page :pagination.per_page,  
                         start_date : date, 
                         end_date : moment( end_date ).add(1,'d')._d, 
                         site_id:site_id
                                                               
                       }
    dispatch(manpowerReport(t))
    dispatch(ClientManRepoStartDate(date))
    setValues({
      ...values,
      ['start_date']:date
    })
    // filterData(listData, date, end_date)
    //  const DataList = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.delivery_date).format("DD.MM.YYYY")>=moment(date).format("DD.MM.YYYY")) && (moment(data.delivery_date).format("DD.MM.YYYY")<=moment(values.end_date).format("DD.MM.YYYY")));
   
   };
    
   const handleEndDateChange = (date) => {
    var t = {
      sub_admin_id : sub_admin_id,
                        page : pagination.page, 
                        per_page :pagination.per_page,  
                         start_date : values.start_date, 
                         end_date : moment( date ).add(1,'d')._d, 
                         site_id:site_id
                                                               
                       }
      dispatch(manpowerReport(t)) 
    
    
    dispatch(ClientManRepoEndDate(date))
   
    // filterData(listData, start_date, date)
  
    //  const DataList = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.delivery_date).format("DD.MM.YYYY")>=moment(values.from_date).format("DD.MM.YYYY")) && (moment(data.delivery_date).format("DD.MM.YYYY")<=moment(date).format("DD.MM.YYYY")));
     
   
   };


     
   
  
useEffect( () => {
  
  console.log("hi")

var t = {
  sub_admin_id : sub_admin_id,
                    page : pagination.page, 
                    per_page :pagination.per_page,  
                     start_date : values.start_date, 
                     end_date : moment( end_date ).add(1,'d')._d, 
                     site_id:site_id
                                                           
                   }
         dispatch(manpowerReport(t))

  



    
  }, [  ])








const getSum  = (data) =>{

 var t  =  data.manpower_details.reduce((x  ,   y  , z)=>{
  if(x.rate ===  null  || x.rate === undefined || x.rate === "")
  {
    x = {rate : 0 } 
    if(y.rate ===  null  || y.rate === undefined || y.rate === "")
    {
      return   x = {rate : parseFloat(x.rate)  + 0} 
    }
    else
    {
      return    x = {rate : parseFloat(x.rate)  + parseFloat(y.rate)} 
    }
  }
  else
  {
    if(y.rate ===  null  || y.rate === undefined || y.rate === "")
    {
      return   x = {rate : parseFloat(x.rate)  + 0} 
    }
    else
    {
    return  x = {rate : parseFloat(x.rate)  + parseFloat(y.rate)} 
    }
  }

   
  })

  return parseFloat(t.rate).toFixed(2)
}


const handleSingleView = (oldata) =>{
  console.log("4567890")
  navigate('/app/manpowerreport/' + oldata.tableData.id , {state:oldata})

}
  





const exportField = [
  
  {
    field : "site_name" , 
    val : "Site Name"
  },  
  {
    field : "date" , 
    val : "Date"
  }, 
  {
    field : "status" , 
    val : "Status"
  },
]






  

const handlePageChange = (e , x) =>{
  
console.log("handlePageChange")
  
  console.log(e)
  console.log(x)
 
   var u   = new Promise((resolve ,reject)=>{
     
     if(x !== null  && x !== undefined )
     {
       dispatch(manpowerReport(
        {
       sub_admin_id : sub_admin_id  ,
       page : e, 
       per_page :pagination.per_page,
       start_date : values.start_date, 
       end_date : moment( end_date ).add(1,'d')._d, 
       site_id:site_id
     
     }
     ))
     }
     
     resolve()
     
   })
   u.then(()=>{
     
     setPagination({...pagination , ['page']  : e})
   })
 
 }


 
 
 const handleRPP = (e) =>{
   console.log("handleRPP")
   console.log(e)
 
   var u   = new Promise((resolve ,reject)=>{

    
   if(!loading){

  
    
       dispatch(manpowerReport(
         {
        sub_admin_id : sub_admin_id  ,
        page : pagination.page, 
        per_page :e ,
        start_date : values.start_date, 
        end_date : moment( end_date ).add(1,'d')._d, 
        site_id:site_id
      
      }
      ))

    }
       
     
     
     resolve()
     
   })
   u.then(()=>{
     
     setPagination({...pagination , ['per_page']  : e})
   })
 
 }
 

  
  return (
    <>
      <Page className={classes.root} title="Manpower Report">
      <ToastContainer    />
        <Container maxWidth={false}>
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              <Button 
                  color="primary" 
                  variant="contained"
                  className={classes.exportButton} 
                  onClick = {
                    () => getExportFileBlob(exportField,listData, 'xlsx', 'Machine Report')
                  }>
                    Export to Excel
                </Button>
                <Button 
                  color="primary" 
                  variant="contained"
                  className={classes.exportButton} 
                  onClick = {
                    () => getExportFileBlob(exportField,listData, 'pdf', 'Machine Report')
                  }>
                    Export to PDF
              </Button>
            </Box>
            <div className={classes.root3}>
            <Grid container spacing={3}>
              <Grid item  md={6} lg={3}  xs={3}>
              <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth                                        
                      id="date-picker-dialog"
                      label="From Date"
                      InputLabelProps={{shrink:true}}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      required={false}
                      value={values.start_date}
                      maxDate={end_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={handleStartDateChange}
                      inputVariant="outlined"
                      variant="outlined"
                      // borderColor="red"
                      invalidDateMessage=""
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  </ThemeProvider>
              </Grid>
              <Grid item  md={6} lg={3}  xs={3}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth                                            
                      id="date-picker-dialog"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={values.start_date}
                      value={end_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={handleEndDateChange}
                      inputVariant="outlined"
                      variant="outlined"
                      borderColor="red"              
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
            

            </Grid>
          </div>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>


            <MaterialTable
      tableRef={tableRef}  
      icons={tableIcons}   
  
      title="Manpower"
      columns={[
        
       { title: "Manpower Category", field: "man_cat" ,
     
          render: rowData => (
            <>
            { rowData.man_cat}

            </>
          )
        },
        { title: "Manpower Name", field :  "man_name" , 
          render: rowData => (
            <>
             
              { rowData.man_name}
              
            </>
          )
        },
        { title: 'Rate/Day',  editable: "never", field : "get_sum",

        render: rowData => (
          <>
            
          {/* { getSum(rowData) === '' || getSum(rowData) === 'NaN' || getSum(rowData) === null || getSum(rowData) === undefined || getSum(rowData) === 0  ? "NA" : getSum(rowData)  } */}
          {rowData.get_sum  }
          </>
        ),



        },
        { title: 'Date', editable: "never", field : "date" ,  render: rowData => (
            moment(rowData.date).format("DD/MM/YYYY")
          ),},             
        {
          title: 'Action',
          render: rowData => (
            
        <VisibilityIcon onClick={()=>{handleSingleView(rowData)}} style={{cursor:"pointer"}} />
      
          ),
        },

      ]}
     
      
      
      components={{
        Pagination: props => (
                     <TablePagination
                     {...props}
                     
                count={countD}
                page={countF}
                
                />
                ),
              }}
              
              onChangeRowsPerPage={(e)=>handleRPP(e)}
              onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}             
              
              data={listData }


              options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                emptyRowsWhenPaging:false,  

              }}

             

    
             

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
