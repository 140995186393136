import {ADD_SUB_ROLE_LOADING , ADD_SUB_ROLE_SUCCESS , ADD_SUB_ROLE_FAILURE} from '../../Actions/Types';
import {GET_SUB_ROLE_LOADING , GET_SUB_ROLE_SUCCESS , GET_SUB_ROLE_FAILURE} from '../../Actions/Types';
import {EDIT_SUB_ROLE_LOADING , EDIT_SUB_ROLE_SUCCESS , EDIT_SUB_ROLE_FAILURE} from '../../Actions/Types';
import {DEL_SUB_ROLE_LOADING , DEL_SUB_ROLE_SUCCESS , DEL_SUB_ROLE_FAILURE} from '../../Actions/Types';
import {CLEAN_ALL_DATA, CLEAN_SUB_ROLE_DATA} from '../../Actions/Types'



const Sub_Role = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            editData:[{}],
            editError:{},
            delData:[{}],
            delError:{}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                
            }
    
    
        case CLEAN_SUB_ROLE_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData : [{}],
                getError:{},
                editData : [{}],
                editError : {},
                delData : [{}], 
                delError:{}
            }
    
        
        case ADD_SUB_ROLE_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],
                
                
            }
        case ADD_SUB_ROLE_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,
                
            }
        case ADD_SUB_ROLE_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],
            }
        case GET_SUB_ROLE_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
                
            }
        case GET_SUB_ROLE_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
                
            }
        case GET_SUB_ROLE_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:[{}],
            }

        case EDIT_SUB_ROLE_LOADING:
            return {
                ...state,
                loading:true,
                editError: {},
                editData : [{}],
                
                
            }
        case EDIT_SUB_ROLE_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data,
                
            }
        case EDIT_SUB_ROLE_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:[{}],
            }            
        case DEL_SUB_ROLE_LOADING:
            return {
                ...state,
                loading:true,
                delError: {},
                delData : [{}],
                
                
            }
        case DEL_SUB_ROLE_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
                
            }
        case DEL_SUB_ROLE_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:[{}],
            }
        

        default:
        return state
    
    }
    }
    
    
    
export default Sub_Role;
    