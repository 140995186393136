import React, { useState, useEffect } from "react";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";
import { tostS, tostE } from "../../../Config/Toast";
import { useSelector, useDispatch } from "react-redux";
import { getSites } from "../../../Actions/SubAdminActions/M_AddSites";
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  CardHeader,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Page from "../../../../src/components/Page"; // Ensure the correct import path
import { ToastContainer } from "react-toastify";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddManualBoq = () => {
  const id = useSelector((state) => state.login.data.r_subadmin);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    site_id: "", // Initialize site_id as an empty string
    boqData: [{ item_description: "", quantity: "", unit: "", unit_price: "" }],
  });

  useEffect(() => {
    dispatch(getSites(id));
  }, [dispatch, id]);

  const SiteData = useSelector((state) =>
    state.AddSites &&
    state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );

  const handleChangeSiteName = (event) => {
    setValues({ ...values, site_id: event.target.value });
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedBoqData = [...values.boqData];
    updatedBoqData[index] = { ...updatedBoqData[index], [name]: value };
    setValues({ ...values, boqData: updatedBoqData });
  };

  const handleAddMore = () => {
    setValues({
      ...values,
      boqData: [
        ...values.boqData,
        { item_description: "", quantity: "", unit: "", unit_price: "" },
      ],
    });
  };

  const handleRemove = (index) => {
    const updatedBoqData = values.boqData.filter((_, i) => i !== index);
    setValues({ ...values, boqData: updatedBoqData });
  };

  const handleSubmit = () => {
    const isAnyFieldEmpty = values.boqData.some(
      (item) =>
        !item.item_description ||
        !item.quantity ||
        !item.unit ||
        !item.unit_price
    );

    if (values.site_id === "" || isAnyFieldEmpty) {
      // If any field is empty, show an error message
      tostE("Please fill in all fields.");
      return;
    }
    const newBoq = {
      site_id: values.site_id,
      sub_admin_id: id,
      boqData: values.boqData.map((item) => ({
        ...item,
      })),
    };

    axios
      .post(`${Base_URL}/subadmin/addManualBoq`, newBoq)
      .then((response) => {
        if (response.data.status === 200) {
          navigate("/app/boq");
          tostS("BOQ added successfully");
        } else {
          tostE("Failed to add BOQ: " + response.data.message);
        }
      })
      .catch((err) => {
        tostE("Error adding BOQ: " + err.message);
      });
  };

  return (
    <Page  className={classes.root} title="Add BOQ">
      <ToastContainer />
      <Container maxWidth="lg" style={{ marginTop: "50px" }}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <form autoComplete="off" noValidate className={clsx(classes.root)}>
              <Card>
                <CardHeader subheader="" title="Add BOQ" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="site-select-label">
                          Site Name
                        </InputLabel>
                        <Select
                          labelId="site-select-label"
                          id="site-select"
                          onChange={handleChangeSiteName}
                          label="Site Name"
                          value={values.site_id}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {SiteData.map((data, i) => (
                            <MenuItem key={i} value={data.site_id}>
                              {data.site_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {values.boqData.map((boqItem, index) => (
                    <Grid container spacing={3} key={index}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Item Description"
                          name="item_description"
                          required
                          value={boqItem.item_description}
                          variant="outlined"
                          onChange={(e) => handleChange(index, e)}
                          multiline

                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Quantity"
                          name="quantity"
                          required
                          value={boqItem.quantity}
                          variant="outlined"
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Unit"
                          name="unit"
                          required
                          value={boqItem.unit}
                          variant="outlined"
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Unit Price"
                          name="unit_price"
                          required
                          value={boqItem.unit_price}
                          variant="outlined"
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Button
                          onClick={() => handleRemove(index)}
                          color="secondary"
                          variant="contained"
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    onClick={handleAddMore}
                    color="primary"
                    variant="contained"
                  >
                    Add More
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddManualBoq;
