import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/CloudDownload';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardMedia
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {getSiteDraw, delSiteDraw} from '../../../Actions/SubAdminActions/SiteDrawing'
import { cleanSiteDraw } from "../../../Actions/ActionCreators";
import {getExportFileBlob} from '../../../Config/excelPdfDownload'


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
   
  const [open2, setOpen2] = React.useState({show:false, img:""});
  const handleOpen2 = (imageData) => setOpen2({show:true, img:imageData});
  const handleClose2 = () => setOpen2({show:false, img:""});
 

  
  const a_id = useSelector((state)=> state.login && state.login.data && state.login.data.r_subadmin ? state.login.data.r_subadmin : "")


  const [open, setOpen] = useState({show:false, id:""});


  const handleClose = () =>{

    setOpen({...open, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setOpen({
      ...open,
      show:true,
      id:data.drawing_id
    });
  
  }
  
  const handleConfirm = () =>{
    if(open.id!==""){
      
      dispatch(delSiteDraw(open.id))

  
      setOpen({
        ...open,
        show:false,
        id:""
      })
    }
  }
  
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.procurement ? state.login.data.role_permit.site_draw : []);


  const listData = useSelector((state) =>
  state.SubSiteDraw && state.SubSiteDraw.getData &&
    state.SubSiteDraw.getData.length &&
    Array.isArray(state.SubSiteDraw.getData)
      ? (state.SubSiteDraw.getData).filter(data => !selected_site || data.site_id == selected_site)
      : []
  );


  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);





  const [values, setValues] = useState({
   
    country:"",

  
    });



const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };



  
  const addmsg = useSelector(state => state.SubSiteDraw.data && state.SubSiteDraw.data.message ? state.SubSiteDraw.data.message : null  )
  const adderror = useSelector(state => state.SubSiteDraw.error && state.SubSiteDraw.error.message ? state.SubSiteDraw.error.message : null  )
  const Delmsg = useSelector(state => state.SubSiteDraw && state.SubSiteDraw.delData && state.SubSiteDraw.delData.message ? state.SubSiteDraw.delData.message : null  )
  const Delerror = useSelector(state => state.SubSiteDraw && state.SubSiteDraw.delError && state.SubSiteDraw.delError.message ? state.SubSiteDraw.delError.message : null  )

  const Editmsg = useSelector(state => state.SubSiteDraw && state.SubSiteDraw.editData && state.SubSiteDraw.editData.message ? state.SubSiteDraw.editData.message : null  )
  const Editerror = useSelector(state => state.SubSiteDraw && state.SubSiteDraw.editError && state.SubSiteDraw.editError.message ? state.SubSiteDraw.editError.message : null  )



  
useEffect( () => {

    dispatch(getSiteDraw(a_id))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg !==null && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror !==null && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }
      if(Editmsg !==null && typeof(Editmsg) === 'string')
      {
        tostS(Editmsg)
      }
      if(Editerror !==null && typeof(Editerror) === 'string')
      {
          tostE(Editerror)
      }
      if(addmsg!==null && typeof(addmsg) === 'string')
      {
        tostS(addmsg)
      }
      if(adderror!==null && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }
      resolve()

    })
    x.then(()=>{

    dispatch(cleanSiteDraw())
    dispatch(getSiteDraw(a_id))

    })


    
  }, [Delmsg  ,Delerror, Editerror, Editerror,addmsg , adderror])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/site_drawings_edit/'+olData.drawing_id , { state: olData })
}


const onDownload = (oldata) =>{

  window.open(oldata.image[0], '_blank');

}
 





const exportField = [
  
  {
    field : "drawing_id" , 
    val : "Drawing ID"
  },
  {
    field : "site_name" , 
    val : "Site Name"
  },
  {
    field : "draw_title" , 
    val : "Drawing Title"
  },
  {
    field : "draw_cat_name" , 
    val : "Drawing Category"
  },
  {
    field : "draw_type_name" , 
    val : "Drawing Type"
  },
  {
    field : "draw_floor_name" , 
    val : "Floor Type"
  }, 
  
]



  


  const addNewBornbutton = () =>{
    navigate('/app/site_drawings_add')
    // console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Site Drawings">
      <ToastContainer    />



      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Site Drawing??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>





        <Dialog open={open2.show} onClose={handleClose2} 
        aria-labelledby="form-dialog-title">
        <Card padding='25%'>
          
          <CardMedia component="img" image={open2.img} />
        </Card>

      </Dialog>
      




        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Site Drawings')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Site Drawings')
              }>
                Export to PDF
            </Button>
            {
              role_permit[3]?<Button onClick={addNewBornbutton} color="primary" variant="contained">
              Create
            </Button>:<></>
            }
              
            </Box>
{/*             
<div className={classes.root3}>
      <Grid container spacing={3}>
        <Grid item  md={6} lg={3}  xs={3}>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Filter By Date"
                  format="MM/dd/yyyy"
                  // value={selectedDate}
                  style={{width:'100%' , borderColor:"orange"}}
                  onChange={handleDateChange}
                  inputVariant="outlined"
                  variant="outlined"
                  borderColor="red"
                
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>

              </ThemeProvider>
        </Grid>
      <Grid item md={6} lg={3}  xs={3}>
 
          <FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
        <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Filter By Supplier</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.country}
          onChange={handleChanged}
          label="Filter By Supplier"
          style={{width:"100%" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>


<MenuItem   value="Resource Type 1">Supplier-1</MenuItem>
<MenuItem   value="Resource Type 1">Supplier-2</MenuItem>
<MenuItem   value="Resource Type 1">Supplier-3</MenuItem>


        </Select>
      </FormControl>
        </Grid> 

        <Grid item  md={6} lg={3}  xs={3}>

        <FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
        <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Drawing Type</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.country}
          onChange={handleChanged}
          label="Filter By Status"
          style={{width:"100%" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>


<MenuItem   value="Resource Type 1">Drawing-1</MenuItem>
<MenuItem   value="Resource Type 1">Drawing-2</MenuItem>
<MenuItem   value="Resource Type 1">Drawing-3</MenuItem>


        </Select>
      </FormControl>

</Grid>
<Grid item md={6} lg={3}  xs={3}>

<FormControl variant="outlined" style={{width:"100%", margin:'15px'}}>
<InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Export As</InputLabel>
<Select
fullWidth
labelId="demo-simple-select-outlined-label"
id="demo-simple-select-outlined"
value={values.country}
onChange={handleChanged}
label="Export As"
style={{width:"100%" }}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>


<MenuItem   value="Resource Type 1">CSV</MenuItem>
<MenuItem   value="Resource Type 1">Excel Sheet</MenuItem>
<MenuItem   value="Resource Type 1">Word</MenuItem>


</Select>
</FormControl>
</Grid>
        
      </Grid>
    </div>
     */}
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Site Drawings"
      columns={[
        { title: 'SR. No.', render : rowData =>(
          <>
          {rowData.tableData.id +1}
          </>
        ) },
        // {
        //   title: 'Drawing Image',
        //   field: 'image',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     onClick={() => {handleOpen2(rowData.image[0])}}
        //     className={useStyles.avatar}
        //     src={rowData.image && rowData.image.length && Array.isArray(rowData.image) ? rowData.image[0]:""}
        //   >
        //   </Avatar>
        //   ),
        // },
        
        { title: 'Drawing Title', field: 'draw_title',editable: "never", },
        { title: 'Drawing Category', field: 'draw_cat_name',editable: "never", },     
        { title: 'Drawing Type', field: 'draw_type_name',editable: "never", },    
        { title: 'Floor Type', field: 'draw_floor_name',editable: "never", },  
        { title: 'Site Name', field: 'site_name',editable: "never", },     
             
        // { title: 'Creator', field: 'mobile_number',editable: "never", },     
        // { title: 'Status', field: 'business',editable: "never", },     
        // {
        //   title: 'Added Date', field: "added_date",editable: "never",
        //   render: rowData => (
        //     moment(rowData.added_date).format("DD/MM/YYYY")
        //   ),
        // },
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
      {role_permit[4] ? 
      <Grid item xs={4}>
      <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      :<></>
      }
        {role_permit[2] ?
      <Grid item xs={4}>
        <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
        :<></>
        }
      <Grid item xs={4}>
        <VisibilityIcon onClick = {() => {onDownload(rowData)}} />
      </Grid>
      </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
