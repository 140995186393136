import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,  
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  IconButton,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {cleanResourceMachineData} from '../../../../../Actions/ActionCreators';
import {addResourceMachine, getResourceMachine, editResourceMachine, delResourceMachine} from '../../../../../Actions/SubAdminActions/M_ResourceMachine';
import {getExportFileBlob} from '../../../../../Config/excelPdfDownload'
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);
  
  const [open3, setOpen3] = useState({show:false, id:""});


  const handleClose3 = () =>{

    setOpen3({...open3, show:false, id:""});
  }
  
  const handleOpen3 = (data) =>{
  
    setOpen3({
      ...open3,
      show:true,
      id:data.sub_machine_id
    });
  
  }
  
  const handleConfirm3 = () =>{
    if(open3.id!==""){
      
      dispatch(delResourceMachine(open3.id))

  
      setOpen3({
        ...open3,
        show:false,
        id:""
      })
    }
  }



  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const listData= useSelector((state) =>
  state.AddResourceMachine && state.AddResourceMachine.getData &&
    state.AddResourceMachine.getData.length &&
    Array.isArray(state.AddResourceMachine.getData)
      ? state.AddResourceMachine.getData
      : []
  );
  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);

  const CData = useSelector((state) =>
  state.MachineCat && state.MachineCat.getData &&
    state.MachineCat.getData.length &&
    Array.isArray(state.MachineCat.getData)
      ? state.MachineCat.getData
      : []
  );
  



  
 
  const Delmsg = useSelector(state => state.AddResourceMachine && state.AddResourceMachine.delData && state.AddResourceMachine.delData.message ? state.AddResourceMachine.delData.message : null  )
  const Delerror = useSelector(state => state.AddResourceMachine && state.AddResourceMachine.delError && state.AddResourceMachine.delError.message ? state.AddResourceMachine.delError.message : null  )
  const addmsg = useSelector(state => state.AddResourceMachine && state.AddResourceMachine.data && state.AddResourceMachine.data.message ? state.AddResourceMachine.data.message : null  )
  const adderr = useSelector(state => state.AddResourceMachine && state.AddResourceMachine.error && state.AddResourceMachine.error.message ? state.AddResourceMachine.error.message : null  )
  const editmsg = useSelector(state => state.AddResourceMachine && state.AddResourceMachine.editData && state.AddResourceMachine.editData.message ? state.AddResourceMachine.editData.message : null )
  

  const sub_aid = useSelector(state => state.login.data.r_subadmin);

  const [values, setValues] = useState({
   
    machine_name:"",
    mch_cat_name : "",
    mch_cat_id : "",
    sub_machine_id:"",
    sub_admin_id:sub_aid,
  
    });
  
  
  
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  
  


  

  
useEffect( () => {

    dispatch(getResourceMachine(sub_aid))


    let x  = new Promise((resolve ,reject)=>{
      if(addmsg !== null && typeof(addmsg) === 'string'){
        tostS(addmsg)
       }
    
      else
      if(adderr !== null){
        tostE(adderr)
       }
      else
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      else
      if (editmsg !== null && typeof(editmsg) === 'string')
      {
        tostS(editmsg);
      }
      else    
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanResourceMachineData())
    dispatch(getResourceMachine(sub_aid))

    })


    
  }, [ Delmsg  ,Delerror, addmsg, adderr, editmsg ])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  setOpen2(true)
  setValues({
    ...values,
    ['machine_name']:olData.machine_name,
    ['mch_cat_name']:olData.name,
    ['mch_cat_id']:olData.mch_cat_id,    
    ['sub_machine_id']:olData.sub_machine_id
  })
  
//   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
}


const onDel = (oldata) =>{

  dispatch(delResourceMachine(oldata.sub_machine_id ,oldata.tableData.id ))
}
 


const [open, setOpen] = React.useState(false);
const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setValues({
      ...values,
      ['machine_name']:"",
      ['sub_machine_id']:""
    })    
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);

  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleClose2 = () => {
    setOpen2(false);
    setValues({
      ...values,
      ['machine_name']:"",
      ['sub_machine_id']:"",
      ['mch_cat_name']:"",
    })

  };



  const handleChange = (event) => {
    //  console.log(event.target.value);
     setValues({
       ...values,
       [event.target.name]: event.target.value,
     });
   };


   const handleChanged = (event) => {
    if(event.target.value!==""){
      const MachCatId = (CData.filter(machCat => machCat.name===event.target.value))[0].mch_id
      
      if(MachCatId!==null && MachCatId!==""){
        setValues({...values, 
          ['mch_cat_name']:event.target.value,
          ['mch_cat_id']:MachCatId
        });
      }
    }
  };
  

   const handleSubmit = () =>{
    //  console.log(values)
    if(((values.machine_name).trim()).length>2){
      if (values.sub_machine_id !== ''){
        dispatch(editResourceMachine(values))
      }
      else
      {
        dispatch(addResourceMachine(values))
      }
      setValues({
        ...values,
        machine_name : "",
        mch_cat_name :"",
        mch_cat_id :"",
        sub_machine_id:""
    
      });
    }else{
      tostE("Enter a Valid Machine Name!!");
    }
  setOpen(false);
  setOpen2(false);
  }; 
  

  const exportField = [
    {
      field : "sub_machine_id" , 
      val : "Machine ID"
    }, 
    {
      field : "machine_name" , 
      val : "Machine Name"
    }, 
    {
      field : "name" , 
      val : "Machine Category Name"
    }, 
    
  ]

  // const addNewBornbutton = () =>{
  //   navigate('/app/master/machine_list_add')
  //   console.log("Place order")
    
  // }

  return (
    <>
      <Page className={classes.root} title="Machine">
      <ToastContainer    />




      

      <Dialog disableBackdropClick={true} open={open3.show} onClose={handleClose3} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Machine??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm3} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>



        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> 
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add Machine
              </Button>*/}


            <div>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'machines')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'machines')
              }>
                Export to PDF
            </Button>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Machine
      </Button>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Machine Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Machine
          </DialogContentText>
          {/*<TextField
            autoFocus
            margin="dense"
            id="name"
            label="Machine Name"
            type="text"
            fullWidth
          />*/}
          <Grid item md={12} xs={12}>
          <TextField
                fullWidth
                id="name"
                label="Machine Name"
                name="machine_name"
                value={values.machine_name}
                onChange={handleChange}
                type="text"
                required
                variant="outlined"
              />

          </Grid>
            <br></br>
                    
            <Grid item md={12} xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
              <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Machine Category</InputLabel>
                <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.mch_cat_name}
                onChange={handleChanged}
                label="Machine Category"
                MenuProps={{
                        PaperProps: {
                          onScroll: (event) => {
                            console.log("we scroll");                            
                          }
                        },
                        style: { maxHeight: 300 },
                        id: "id-menu",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {CData.map((data, i) =>
                    <MenuItem key={i} value={data.name}>{data.name}</MenuItem>
                  )}              
                  
                </Select>
              </FormControl>
            </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Machine
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Machine Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit Machine
          </DialogContentText>
          {/*<TextField
            autoFocus
            margin="dense"
            id="name"
            label="Machine Name"
            type="text"
            fullWidth
          />*/}
          <Grid item md={12} xs={12}>
          <TextField
                fullWidth
                id="name"
                label="Machine Name"
                name="machine_name"
                value={values.machine_name}
                onChange={handleChange}
                type="text"
                required
                variant="outlined"
              />

          </Grid>
            <br></br>
                    
            <Grid item md={12} xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
              <InputLabel id="demo-simple-select-outlined-label" style={{width:"100%"}}>Machine Category</InputLabel>
                <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.mch_cat_name}
                onChange={handleChanged}
                label="Machine Category"
                MenuProps={{
                        PaperProps: {
                          onScroll: (event) => {
                            console.log("we scroll");                            
                          }
                        },
                        style: { maxHeight: 300 },
                        id: "id-menu",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {CData.map((data, i) =>
                    <MenuItem key={i} value={data.name}>{data.name}</MenuItem>
                  )}              
                  
                </Select>
              </FormControl>
            </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update Machine
          </Button>
        </DialogActions>
      </Dialog>
    </div>




            </Box>
            
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Machine"
      columns={[
       
        { title: 'Machine ID', render: rowData =>(
          rowData.tableData.id+1
          ) },
        { title: 'Machine Name', field: 'machine_name',editable: "never", },     
        { title: 'Machine Category Name', field: 'name',editable: "never", },     
        
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ?
              <Grid item xs={4}>    
              {rowData.by_superadmin ? 
              <IconButton>
                <EditIcon onClick={()=>{tostW("Machine Added by Super Admin can't be Edit")}} style={{cursor:"pointer"}} />

              </IconButton>
              :            
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              }
              </Grid>
              :<></>
              }
              {role_permit[2] ?
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen3(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
