
import {ACCEPT_DELIVERY_LOADING , ACCEPT_DELIVERY_SUCCESS , ACCEPT_DELIVERY_FAILURE , CLEAN_DELIVERY_DATA} from '../../Actions/Inventory/Types';
import {GET_DELIVERY_LOADING , GET_DELIVERY_SUCCESS , GET_DELIVERY_FAILURE} from '../../Actions/Inventory/Types';
import {ISSUE_DELIVERY_LOADING , ISSUE_DELIVERY_SUCCESS , ISSUE_DELIVERY_FAILURE} from '../../Actions/Inventory/Types';
import {GET_ACCEPTED_DELIVERY_LOADING , GET_ACCEPTED_DELIVERY_SUCCESS , GET_ACCEPTED_DELIVERY_FAILURE} from '../../Actions/Inventory/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const Delivery = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            acceptData: [{}],
            acceptError:{},
            getData:{},
            getError:{},
            issueData:{},
            issueError:{},
            getAccData:{},
            getAccError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                loading:false,
                acceptData: [{}],
                acceptError:{},
                getData:[{}],
                getError:{}
                
            }
    
    
        case CLEAN_DELIVERY_DATA:
            return {
                ...state,
                loading:false,
                acceptData: [{}],
                acceptError:{},                
                issueData:{},
                issueError:{},
            }
    
        
        case ACCEPT_DELIVERY_LOADING:
            return {
                ...state,
                loading:true,
                acceptError: {},
                acceptData : [{}],
                
            }
        case ACCEPT_DELIVERY_SUCCESS:
            return {
                ...state,
                loading:false,
                acceptError:{},
                acceptData:action.data,
            }
        case ACCEPT_DELIVERY_FAILURE:
            return {
                ...state,
                loading:false,
                acceptError:action.error,
                acceptData:[{}],
            }

        case GET_ACCEPTED_DELIVERY_LOADING:
            return {
                ...state,
                loading:true,
                getAccError: {},
                getAccData : {},
                
            }
        case GET_ACCEPTED_DELIVERY_SUCCESS:
            return {
                ...state,
                loading:false,
                getAccError:{},
                getAccData:action.data,
            }
        case GET_ACCEPTED_DELIVERY_FAILURE:
            return {
                ...state,
                loading:false,
                getAccError:action.error,
                getAccData:{},
            }   
        
        case ISSUE_DELIVERY_LOADING:
            return {
                ...state,
                loading:true,
                issueError: {},
                issueData : {},
                
            }
        case ISSUE_DELIVERY_SUCCESS:
            return {
                ...state,
                loading:false,
                issueError:{},
                issueData:action.data,
            }
        case ISSUE_DELIVERY_FAILURE:
            return {
                ...state,
                loading:false,
                issueError:action.error,
                issueData:{},
            }   
        
        case GET_DELIVERY_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : {},
                
            }
        case GET_DELIVERY_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
            }
        case GET_DELIVERY_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default Delivery;
    