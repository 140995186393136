// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addDrawTypeBegin , addDrawTypeSuccess , addDrawTypeFailure} from '../ActionCreators'
import {getDrawTypeBegin , getDrawTypeSuccess , getDrawTypeFailure} from '../ActionCreators'
import {delDrawTypeBegin , delDrawTypeSuccess , delDrawTypeFailure} from '../ActionCreators'
import {editDrawTypeBegin , editDrawTypeSuccess , editDrawTypeFailure} from '../ActionCreators';

export const addDrawType = (session) => {

    return (dispatch) => {
        dispatch(addDrawTypeBegin());

        axios.post(Base_URL + '/subadmin/addDrawTypeMaster', session)
            .then((res) => {
            
            
                dispatch(addDrawTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addDrawTypeFailure(err.response.data));

                }
            })
    }
}


export const getDrawType = (x) => {
    console.log('hereeeeee');

    return (dispatch) => {
        dispatch(getDrawTypeBegin());

        axios.get(Base_URL + '/subadmin/getDrawTypeMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                console.log(res);
                dispatch(getDrawTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getDrawTypeFailure(err.response.data));

                }
            })
    }
}


export const getClientDrawType = (x) => {
    console.log('hereeeeee');

    return (dispatch) => {
        dispatch(getDrawTypeBegin());

        axios.get(Base_URL + '/client/getClientDrawTypeMaster' , {params : {sub_admin_id : x}})
            .then((res) => {
                console.log(res);
                dispatch(getDrawTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getDrawTypeFailure(err.response.data));

                }
            })
    }
}

export const delDrawType = (x) => {

    return (dispatch) => {
        dispatch(delDrawTypeBegin());

        console.log(x);
        axios.get(Base_URL + '/subadmin/delDrawTypeMaster' , {params :{sub_draw_type_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delDrawTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delDrawTypeFailure(err.response.data));

                }
            })
    }
}

export const editDrawType = (session) => {

    return (dispatch) => {
        dispatch(editDrawTypeBegin());

        axios.post(Base_URL + '/subadmin/editDrawTypeMaster', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editDrawTypeSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editDrawTypeFailure(err.response.data));

                }
            })
    }
}

