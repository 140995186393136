import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MaterialTable from 'material-table';
import tableIcons from '../../../../Config/IconsFile'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites';
import {getVendor} from '../../../../Actions/SubAdminActions/M_Vendor';
import {getMachineCat} from '../../../../Actions/SubAdminActions/M_MachineCat';
import {getResourceMachine} from '../../../../Actions/SubAdminActions/M_ResourceMachine';
import {getMaterialCategory} from '../../../../Actions/SubAdminActions/M_MaterialCategory';
import {getMaterialMat} from '../../../../Actions/SubAdminActions/M_MaterialMat';
import {getGst} from '../../../../Actions/SubAdminActions/Gst';
import {getResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit'
import {getAppUser} from '../../../../Actions/SubAdminActions/AppUserManagement'

import {grnIssue} from '../../../../Actions/Inventory/GRN'

import {cleanOrderActionData} from '../../../../Actions/Inventory/ActionCreators'


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
    maxWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const a_id = useSelector((state)=> state.login && state.login.data && state.login.data.r_subadmin ? state.login.data.r_subadmin : "")

  // const a_id = useSelector(state => state.login.data.admin_id);
  const admin_name = useSelector(state => state.login.data.name);
  const folder = useSelector(state => state.login.data.folder_name);


  // const id = useSelector(state => state.login && state.login.data 
  //   && state.login.data.admin_id && 
  //   (state.login.data.r_subadmin ===null || state.login.data.r_subadmin ===undefined || state.login.data.r_subadmin ==="")
  //   ? state.login.data.admin_id:state.login.data.r_subadmin)


  // ******************* Dynamic Material Details ************************ //
  const [MatList, setMatList] = useState([{amt: "", amt_error: false, gst: "",gst_per: "", gst_error: false, mat_error: false, mat_id: "", mat_name: "", mat_type: "", mat_type_error: false, mat_type_id: "", quan: "", acc_quan:"",ind_quan:"",new_quan:"", quan_error: false, unit_id:"", unit:"",unit_error:false, getData : []}])

  // handle input change 
  
  const handleChangeMatInput = (event, idx) =>{
    const list = [...MatList];
    const index = idx;  
    const field_name = event.target.name;  
    list[index][field_name] = event.target.value;     
    setMatList([...list])
    
  }
  const handleChangeMatQuantity = (event, idx) =>{
    const list = [...MatList];
    const index = idx;  
    list[index]['quan'] = event.target.value;     
    list[index]['new_quan'] = event.target.value;     
    list[index]['ind_quan'] = event.target.value;     
    list[index]['acc_quan'] = event.target.value;     
    setMatList([...list])
    
  }
  
  const handleMatCatChange = (event, idx) =>{
    var list = [...MatList];
    const index = idx;
    const selected_id = event.target.value;
    var mat_type_insert =  MatCatData.filter(type_name => type_name.mc_id==selected_id)[0].name;
    list[index].mat_type_id = event.target.value;
    list[index].mat_type = mat_type_insert;
    list[index].getData = MatData.filter(m_type => m_type.mat_cat_id==selected_id)
    setMatList([...list])
    
    var avail_quan=[...values.available_quan]
    avail_quan[index] =[]
    setValues({
      ...values, 
      ['available_quan']:avail_quan
    });
    // dispatch(getPurchaseMatName(id, event.target.value))
  }
  
  const handleMatChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var mat_insert =  MatData.filter(type_name => type_name.mm_id==event.target.value)[0].mat_name;
    list[index].mat_id = event.target.value;
    list[index].mat_name = mat_insert;
    setMatList([...list])

    // available quantity

    const i_name = event.target.value;
    const i_cat = list[index].mat_type_id;
    var avail_quan=[...values.available_quan]
   
    avail_quan[index] = (StockData.filter(data => (data.mc_id==i_cat && data.mm_id==i_name && data.site_id==values.site_id)))      

    setValues({
        ...values, 
        ['available_quan']:avail_quan
    });
    
  }
  
  const handleUnitChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var unit_insert =  UnitData.filter(type_name => type_name.sub_unit_id==event.target.value)[0].unit_name;
    list[index].unit_id = event.target.value;
    list[index].unit = unit_insert;
    setMatList([...list])
    
  }
  
  
  const handleGstChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var gst_insert =  GstData.filter(type_name => type_name.gst_id==event.target.value)[0].percent;
    list[index].gst = event.target.value;
    list[index].gst_per = gst_insert;

    setMatList([...list])
    
  }
  

  // handle click event of remove


  const handleRemoveClick = (index) => {
    const list = [...MatList];
    list.splice(index, 1);
    setMatList(list);

    const AvailList = [...values.available_quan]
    AvailList.splice(index,1);
    setValues({
      ...values,
      ['available_quan']:AvailList
    })
  };

  // handle click of add button

  const handleAddClick = () => {
    var flag = true;       
    for(let a=0;a<MatList.length;a++){  
      if(MatList[a]['mat_type_id']!==""){
        if(MatList[a]['mat_id']!==""){               
          if(!isNaN(parseInt(MatList[a]['quan']))){
            if(MatList[a]['unit_id']!==""){
              console.log("All Validation Done!!")

            }else{
              flag = false;                  
              tostE(`Please Select unit for ${a+1}st  item`);
              break;
      
            }
          }else{
            flag = false;                
            tostE(`Please Enter Quantity for ${a+1}st  item`);
            break;
    
          }
        }else{
          flag = false;              
          tostE(`Please Select Material for ${a+1}st  item`);
          break;
  
        }
      }else{
        flag = false;            
        tostE(`Please Select Category for ${a+1}st  item`);
        break;

      }       
      
    }

    if (flag){

      setMatList([...MatList, {amt: "", amt_error: false, gst: "", gst_per: "", gst_error: false, mat_error: false, mat_id: "", mat_name: "", mat_type: "", mat_type_error: false, mat_type_id: "", quan: "", acc_quan:"",ind_quan:"",new_quan:"", quan_error: false, unit_id:"", unit:"",unit_error:false, getData : []}])
      
      setValues({...values, ['available_quan']:[...values.available_quan, []]})
    }
  }
  


   // ******************* Dynamic Machine Details ************************ //
   const [MacList, setMacList] = useState([
                                            {
                                              amt: "",
                                              amt_error: false,
                                              gst: "",
                                              gst_error: false,
                                              gst_per: "",
                                              mac_error: false,
                                              mac_id: "",
                                              mac_name: "",
                                              mac_error:false,
                                              mac_type: "",
                                              mac_type_error: false,
                                              mac_type_id: "",
                                              quan: "",
                                              ind_quan:"",
                                              acc_quan:"",
                                              new_quan:"",
                                              quan_error: false,
                                              getData : []
                                            }
                                          ])


  // handle input change 
  
  const handleChangeMacInput = (event, idx) =>{
    const list = [...MacList];
    const index = idx;  
    const field_name = event.target.name;  
    list[index][field_name] = event.target.value;     
    setMacList([...list])
    
  }
  
  const handleChangeMacQuantity = (event, idx) =>{
    const list = [...MacList];
    const index = idx;  
    list[index]['quan'] = event.target.value;     
    list[index]['new_quan'] = event.target.value;     
    list[index]['acc_quan'] = event.target.value;     
    list[index]['ind_quan'] = event.target.value;     
    setMacList([...list])
    
  }

  const handleMacCatChange = (event, idx) =>{
    var list = [...MacList];
    const index = idx;
    const selected_id = event.target.value;
    var mac_type_insert =  MacCatData.filter(type_name => type_name.mch_id==selected_id)[0].name;
    list[index].mac_type_id = event.target.value;
    list[index].mac_type = mac_type_insert;
    list[index].getData = MacData.filter(m_type => m_type.mch_cat_id==selected_id)
    setMacList([...list])

    var avail_quan=[...values.available_quan]
    avail_quan[index] =[]
    setValues({
      ...values, 
      ['available_quan']:avail_quan
    });
  }
  
  const handleMacChange = (event, idx) =>{
    const list = [...MacList];
    const index = idx;
    var mac_insert =  MacData.filter(type_name => type_name.sub_machine_id==event.target.value)[0].machine_name;
    list[index].mac_id = event.target.value;
    list[index].mac_name = mac_insert;
    setMacList([...list])

    
    // available quantity

    const i_name = event.target.value;
    const i_cat = list[index].mac_type_id;
    var avail_quan=[...values.available_quan]
   
    avail_quan[index] = (StockData.filter(data => (data.mch_id==i_cat && data.sub_machine_id==i_name && data.site_id==values.site_id)))      

    setValues({
        ...values, 
        ['available_quan']:avail_quan
    });
    
  }
  
  
  const handleMacGstChange = (event, idx) =>{
    const list = [...MacList];
    const index = idx;
    var gst_insert =  GstData.filter(type_name => type_name.gst_id==event.target.value)[0].percent;
    list[index].gst = event.target.value;
    list[index].gst_per = gst_insert;

    setMacList([...list])
    
  }
  

  // handle click event of remove


  const handleMacRemoveClick = (index) => {
    const list = [...MacList];
    list.splice(index, 1);
    setMacList(list);

    const AvailList = [...values.available_quan]
    AvailList.splice(index,1);
    setValues({
      ...values,
      ['available_quan']:AvailList
    })
  };

  // handle click of add button

  const handleMacAddClick = () => {
    var flag = true;
              
              
    for(let a=0;a<MacList.length;a++){
      if(MacList[a]['mac_type_id']!==""){
        if(MacList[a]['mac_id']!==""){               
          if(!isNaN(parseInt(MacList[a]['quan']))){
            if(MacList[a]['unit_id']!==""){
              console.log("All Validation Done")


            }else{
              flag = false;                  
              tostE(`Please Select unit for ${a+1}st item`);
              break;
      
            }
          }else{
            flag = false;                
            tostE(`Please Enter Quantity for ${a+1}st item`);
            break;
    
          }
        }else{
          flag = false;              
          tostE(`Please Select Machine for ${a+1}st item`);
          break;
  
        }
      }else{
        flag = false;            
        tostE(`Please Select Category for ${a+1}st item`);
        break;

      }   
    }
  
    if (flag){

      setMacList([
            ...MacList, 
          {
            amt: "",
            amt_error: false,
            gst: "",
            gst_error: false,
            gst_per: "",
            mac_error: false,
            mac_id: "",
            mac_name: "",
            mac_error:false,
            mac_type: "",
            mac_type_error: false,
            mac_type_id: "",
            quan: "",
            ind_quan:"",
            acc_quan:"",
            new_quan:"",
            quan_error: false,
            getData : []
          }
        ])
        setValues({...values, ['available_quan']:[...values.available_quan, []]})
    } 
  }
  

  const AppUserData = useSelector((stateData) =>
  stateData.AppUserMgmt && stateData.AppUserMgmt.getData &&
    stateData.AppUserMgmt.getData.length &&
    Array.isArray(stateData.AppUserMgmt.getData)
      ? (stateData.AppUserMgmt.getData).filter(userData => userData.assign_role==="Site_Engineer")
      : []
  );

  
  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const [engData, setEngData] = useState(AppUserData.filter(data =>  !selected_site || data.site_id===selected_site))

  const [values, setValues] = useState({
    app_uid:"",
    sub_admin_id :a_id,    
    engData:[],
    issue_type:"",
    site_id:selected_site!==null ? selected_site: "",
    required_on:new Date(Date.now()),
    priority:"High",
    mat_details:"",
    folder_name:folder,
    invt_name:admin_name,
    notes:"Issued by inventory manager",
    remark:"Issued by "+admin_name,
    available_quan:[],
  });

  const StockData = useSelector(state => state.Stock && state.Stock.getData && Array.isArray(state.Stock.getData) && state.Stock.getData.length ? state.Stock.getData : []);

  const [open, setOpen] = useState(false);


  const handleClose = () =>{
    setValues({
      ...values,
      ['mat_details']:""
    })
    setOpen(false);
  }
 

  const handleOpen = () =>{
    
    if(values.site_id===""){
      tostE("Please Select a Site!!")
    }else{      
        if(values.priority===""){
          tostE("Please Select Priority!!")
        }else{
          if(values.issue_type===""){
            tostE("Please Select Purchase Type!!")
          }else{

            if(values.issue_type==="Material" && values.mat_details===""){
              
              var flag = true;       
              for(let a=0;a<MatList.length;a++){  
                if(MatList[a]['mat_type_id']!==""){
                  if(MatList[a]['mat_id']!==""){               
                    if(!isNaN(parseInt(MatList[a]['quan']))){
                      if(MatList[a]['unit_id']!==""){
                        console.log("All Validation Done!!")
      
                      }else{
                        flag = false;                  
                        tostE(`Please Select unit for ${a+1}st item`);
                        break;
                
                      }
                    }else{
                      flag = false;                
                      tostE(`Please Enter Quantity for ${a+1}st item`);
                      break;
              
                    }
                  }else{
                    flag = false;              
                    tostE(`Please Select Material for ${a+1}st item`);
                    break;
            
                  }
                }else{
                  flag = false;            
                  tostE(`Please Select Category for ${a+1}st item`);
                  break;
          
                }       
                
              }
        
              if (flag){
        
                setValues({
                  ...values,
                  ['mat_details']:MatList,
                })
              
                setOpen(flag);
              }
            }
            if(values.issue_type==="Machine" && values.mat_details===""){
              var flag = true;
              
              
              for(let a=0;a<MacList.length;a++){
                if(MacList[a]['mac_type_id']!==""){
                  if(MacList[a]['mac_id']!==""){               
                    if(!isNaN(parseInt(MacList[a]['quan']))){
                      if(MacList[a]['unit_id']!==""){
                        console.log("All Validation Done")
      
                      }else{
                        flag = false;                  
                        tostE(`Please Select unit for ${a+1}st item`);
                        break;
                
                      }
                    }else{
                      flag = false;                
                      tostE(`Please Enter Quantity for ${a+1}st item`);
                      break;
              
                    }
                  }else{
                    flag = false;              
                    tostE(`Please Select Machine for ${a+1}st item`);
                    break;
            
                  }
                }else{
                  flag = false;            
                  tostE(`Please Select Category for ${a+1}st item`);
                  break;
          
                }   
              }
            
              if (flag){
        
                setValues({
                  ...values,
                  ['mat_details']:MacList,
                })                            
                setOpen(flag);
              }                                    
            }
          }
        }     
    }       
  }

  const handleConfirm = () =>{
   
    if(values.mat_details!==""){
      dispatch(grnIssue(values))

      setValues({
        ...values,
        type:"",
        site_id:"",
        vd_id:"",
        required_on:new Date(Date.now()),
        mat_details:"",
        folder_name:"",              
      })
      setMatList([{amt: "", amt_error: false, gst: "",gst_per: "", gst_error: false, index: 0, mat_error: false, mat_id: "", mat_name: "", mat_type: "", mat_type_error: false, mat_type_id: "", quan: "", quan_error: false, unit_id:"", unit:"", unit_error:false, getData : []}])
      setMacList([{amt: "", amt_error: false, gst: "", gst_error: false, gst_per: "", index: 0, mac_error: false, mac_id: "", mac_name: "", mac_type: "", mac_type_error: false, mac_type_id: "", quan: "", quan_error: false, getData : []}])
      setOpen(false)
    }
    
  }

  const handleChangePriority = (event) => {
    setValues({
      ...values,
      ['priority']: event.target.value,
    });
  };

 


  const handleDateChange = (date) => {
    setValues({
      ...values,
      ['required_on']:date
    })
  };


  const handleChangeSite = (event) => {
    const SiteValue = event.target.value;
    var arr = []

    AppUserData.map((data) =>{      
      if(data.site_id===SiteValue){
        arr.push({
          "apu_id":data.apu_id,
          "name":data.name
        })
      }      
    })

    
    setValues({
      ...values, 
      ['site_id']:event.target.value,
    });
    setEngData(arr);

  };
  const handleChangeVendor = (event) => {
    setValues({...values, ['vd_id']:event.target.value});
  };
  const handleChangeAppUser = (event) => {
    setValues({...values, ['app_uid']:event.target.value});
  };
 
  const handleChangeType = (event) => {

    setMacList([
      {
        amt: "",
        amt_error: false,
        gst: "",
        gst_error: false,
        gst_per: "",
        mac_error: false,
        mac_id: "",
        mac_name: "",
        mac_error:false,
        mac_type: "",
        mac_type_error: false,
        mac_type_id: "",
        quan: "",
        ind_quan:"",
        acc_quan:"",
        new_quan:"",
        quan_error: false,
        getData : []
      }
    ])
    setMatList([{amt: "", amt_error: false, gst: "",gst_per: "", gst_error: false, mat_error: false, mat_id: "", mat_name: "", mat_type: "", mat_type_error: false, mat_type_id: "", quan: "", acc_quan:"",ind_quan:"",new_quan:"", quan_error: false, unit_id:"", unit:"",unit_error:false, getData : []}])
    setValues({
      ...values, 
      ['issue_type']:event.target.value,
      ['available_quan']:[]
    });
  };



  const addmsg = useSelector(state => state.GRN && state.GRN.issueData && state.GRN.issueData.message ? state.GRN.issueData.message : null  )
  const adderror = useSelector(state => state.GRN && state.GRN.issueError && state.GRN.issueError.message ? state.GRN.issueError.message : null  )
  



useEffect(() => {
  dispatch(getSites(a_id))
  dispatch(getMachineCat(a_id))
  dispatch(getResourceMachine(a_id))
  dispatch(getMaterialCategory(a_id))
  dispatch(getMaterialMat(a_id))
  dispatch(getVendor(a_id))
  dispatch(getResourceUnit(a_id))
  dispatch(getGst())

  dispatch(getAppUser(a_id))

  
  let x  = new Promise((resolve ,reject)=>{
    if(addmsg && typeof(addmsg) === 'string')
    {
      tostS(addmsg)
    }
    if(adderror && typeof(adderror) === 'string')
    {
        tostE(adderror)
    }
    

    resolve()

  })
  x.then(()=>{

  dispatch(cleanOrderActionData())

  })


  
}, [addmsg, adderror])

const SiteData = useSelector((state) =>
state.AddSites && state.AddSites.getData &&
  state.AddSites.getData.length &&
  Array.isArray(state.AddSites.getData)
    ? state.AddSites.getData
    : []
);


const VendorData = useSelector((state) =>
state.AddVendor && state.AddVendor.getData &&
  state.AddVendor.getData.length &&
  Array.isArray(state.AddVendor.getData)
    ? state.AddVendor.getData
    : []
);

const UnitData = useSelector((state) =>
state.AddResourceUnit && state.AddResourceUnit.getData &&
  state.AddResourceUnit.getData.length &&
  Array.isArray(state.AddResourceUnit.getData)
    ? state.AddResourceUnit.getData
    : []
);


const GstData = useSelector((state) =>
state.SubGst && state.SubGst.getData &&
  state.SubGst.getData.length &&
  Array.isArray(state.SubGst.getData)
    ? state.SubGst.getData
    : []
);

const MatCatData = useSelector((state) =>
state.AddMaterialCategory && state.AddMaterialCategory.getData &&
  state.AddMaterialCategory.getData.length &&
  Array.isArray(state.AddMaterialCategory.getData)
    ? state.AddMaterialCategory.getData
    : []
);

const MatData = useSelector((state) =>
state.MaterialMatMaster && state.MaterialMatMaster.getData &&
  state.MaterialMatMaster.getData.length &&
  Array.isArray(state.MaterialMatMaster.getData)
    ? state.MaterialMatMaster.getData
    : []
);



const MacCatData = useSelector((state) =>
state.MachineCat && state.MachineCat.getData &&
  state.MachineCat.getData.length &&
  Array.isArray(state.MachineCat.getData)
    ? state.MachineCat.getData
    : []
);



const MacData = useSelector((state) =>
state.AddResourceMachine && state.AddResourceMachine.getData &&
  state.AddResourceMachine.getData.length &&
  Array.isArray(state.AddResourceMachine.getData)
    ? state.AddResourceMachine.getData
    : []
);




  const demoData1  = 
  [
    {
      amt: "200",
      amt_error: false,
      gst: 3,
      gst_error: false,
      gst_per: "5",
      index: 0,
      mac_error: false,
      mac_id: 10,
      mac_name: "Rat Trap",
      mac_type: "Rod Roller",
      mac_type_error: false,
      mac_type_id: 8,
      quan: "55",
      quan_error: false,
    }
  ]
  const demoData2 = [
    {amt: "",
    amt_error: false,
    gst: "",
    gst_error: false,
    index: 0,
    mat_error: false,
    mat_id: 1,
    mat_name: "Switch",
    mat_type: "Electrical",
    mat_type_error: false,
    mat_type_id: 5,
    quan: "500",
    quan_error: false,}]
  

  


  return (
    <Page
      className={classes.root}
      title="Issue Item"
    >
      <ToastContainer />

        <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to issue this item??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>


              

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
         
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Issue Item" />
        <Divider />
        <CardContent>
       
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>

              <Grid item md={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    style={{ border: "1px  black" }}
                    id="date-picker-dialog"
                    label="Issue Date"
                    format="dd.MM.yyyy"
                    value={values.required_on}
                    inputVariant="outlined"
                    onChange={handleDateChange}
                    variant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid> 


              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Site</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.site_id}
                    onChange={handleChangeSite}
                    label="Site"
                    style={{margin:0}}
                  >
                    
                    {SiteData.map((data , i)=>
                      <MenuItem key={i}  value={data.site_id}>{data.site_name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
          


              
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Engineer Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.app_uid}
                    onChange={handleChangeAppUser}
                    label="Engineer Name"
                    style={{margin:0}}
                  >
                    
                    {engData.map((data , i)=>
                      <MenuItem key={i}  value={data.apu_id}>{data.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <Typography variant="caption" style={{marginLeft:"5px"}}>Please select the site to view the engineers.</Typography>
              </Grid>
              
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Order Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.issue_type}
                    onChange={handleChangeType}
                    label="Order Type"
                    style={{margin:0}}
                  >
                    
                    <MenuItem value="Material">Material</MenuItem>
                    <MenuItem value="Machine">Machine</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                
                {values.issue_type==="Material"?
                
                  <Card>
                    <CardHeader subheader="" title="Material Details" />
                    <Divider />
                    <CardContent>
                        
                  <>     
                  {MatList && MatList.length > 0 && Array.isArray(MatList) ?
                    <>
                      { MatList.map((field, idx) => {
                        return (
                          <>
                          <Card style={{border:"1px solid #db630b", marginBottom:"5px", marginTop:"5px"}}>
                            <CardContent style={{borderBottomWidth:1 , borderBottomColor:"black"}}>
                              <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-outlined-label">Material Category</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={Object.keys(field) && Object.keys(field).length && field.mat_type_id ?field.mat_type_id:""}
                                      onChange={e => handleMatCatChange(e,idx)}
                                      label="Material Category"                                      
                                    >
                                      
                                      {MatCatData.map((data , i)=>
                                        <MenuItem key={i} value={data.mc_id}>{data.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-outlined-label">Material Name</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"                                      
                                      value={field.mat_id}
                                      onChange={e => handleMatChange(e,idx)}
                                      label="Material Name"
                                      style={{margin:0}}
                                    >
                                      {field.getData.map((data , i)=>
                                        <MenuItem key={i}  value={data.mm_id}>{data.mat_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Quantity"
                                    name="quan"                                    
                                    onChange={e => handleChangeMatQuantity(e, idx)}
                                    required
                                    helperText="Please Enter quantity less than or equal to Available quantity"
                                    type="number"  InputProps={{inputProps:{min:0}}}
                                    value={field.quan}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.unit_id}                                      
                                      onChange={e => handleUnitChange(e,idx)}
                                      label="Unit"
                                    >
                                      {UnitData.map((data , i)=>
                                        <MenuItem key={i}  value={data.sub_unit_id}>{data.unit_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {MatList.length>1?
                                
                                <Grid item md={3} xs={12}>
                                  <Box display="flex" justifyContent="flex-end" p={2}>
                                    <Button onClick={() => handleRemoveClick(idx)} color="primary" variant="contained">
                                    Delete
                                    </Button>
                                  </Box>
                                
                                    
                                </Grid>
                                :<></>
                                }
                                {Array.isArray(values.available_quan) && values.available_quan.length ?
                                  <Grid item md={6} xs={12}>
                                        <Typography variant="outlined">Available Quantity :</Typography>
                                        {values.available_quan[idx].map(data=>(                                 
                                          <>
                                          <Typography variant="caption" style={{color:"green"}}>
                                          {data.item_quan} {data.unit_name} | 
                                          </Typography>

                                          </>
                                        
                                        ))}
                                    
                                  </Grid>
                                  
                                  :
                                  <></>
                                  }
                                
                              </Grid>
                            </CardContent>
                          </Card>
                          
                          </>
                        
                        );
                      })}
                    </>: <></>
                  }
                  </>
                              
                        
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={() => handleAddClick()} color="primary" variant="contained">
                      Add More
                    </Button>
                  </Box>
                      
                  </CardContent>
                </Card>
                :<></>  
              }
                





              {values.issue_type==="Machine"?

                <Card>
                    <CardHeader subheader="" title="Machine Details" />
                    <Divider />
                    <CardContent>
                        
                  <>     
                  {MacList && MacList.length > 0 && Array.isArray(MacList) ?
                    <>
                      { MacList.map((field, idx) => {
                        return (
                          <>
                          <Card style={{border:"1px solid #db630b", marginBottom:"5px", marginTop:"5px"}}>
                            <CardContent style={{borderBottomWidth:1 , borderBottomColor:"black"}}>
                              <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Machine Category</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.mac_type_id}
                                      onChange={e => handleMacCatChange(e,idx)}
                                      label="Machine Category"
                                      style={{margin:0}}
                                    >
                                      
                                      {MacCatData.map((data , i)=>
                                        <MenuItem key={i}  value={data.mch_id}>{data.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Machine Name</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.mac_id}
                                      onChange={e => handleMacChange(e,idx)}
                                      label="Machine Name"
                                    >
                                      {field.getData.map((data , i)=>
                                        <MenuItem key={i}  value={data.sub_machine_id}>{data.machine_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Quantity"
                                    name="quan"
                                    helperText="Please Enter quantity less than or equal to Available quantity."
                                    type="number"  InputProps={{inputProps:{min:0}}}
                                    onChange={e => handleChangeMacQuantity(e, idx)}
                                    required
                                    value={field.quan}
                                    variant="outlined"
                                  />
                                </Grid>
                                
                                {MacList.length>1?
                                
                                <Grid item md={4} xs={12}>
                                  <Box display="flex" justifyContent="flex-end" p={2}>
                                    <Button onClick={() => handleMacRemoveClick(idx)} color="primary" variant="contained">
                                    Delete
                                    </Button>
                                  </Box>
                                
                                    
                                </Grid>
                                :<></>
                                }
                                {Array.isArray(values.available_quan) && values.available_quan.length ?
                                  <Grid item md={6} xs={12}>
                                        <Typography variant="outlined">Available Quantity :</Typography>
                                        {values.available_quan[idx].map(data=>(                                 
                                          <>
                                          <Typography variant="caption" style={{color:"green"}}>
                                          {data.item_quan} {data.unit_name} | 
                                          </Typography>

                                          </>
                                        
                                        ))}
                                    
                                  </Grid>
                                  
                                  :
                                  <></>
                                  }
                              </Grid>
                            </CardContent>
                          </Card>
                          
                          </>
                        
                        );
                      })}
                    </>: <></>
                  }
                  </>
                              
                        
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={() => handleMacAddClick()} color="primary" variant="contained">
                      Add More
                    </Button>
                  </Box>
                      
                  </CardContent>
                </Card>
                :<></>
              }


                
            </Grid>
        
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleOpen} color="primary" variant="contained">
            Issue
          </Button>
        </Box>
      </Card>
    </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
