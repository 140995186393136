import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  
  
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";




const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const {state} = useLocation();
  const [expanded, setExpanded] = React.useState(false);



  const listData = (state.manpower_details && Array.isArray(state.manpower_details) && state.manpower_details.length ? state.manpower_details : []);


   
   // const listData = {state.send_mat_details}

//   const [values, setValues] = useState({
   
//    //  quantity:state.send_mat_details.quan,

  
//     });



// const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


// const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };


//   const handleChanged = (event) => {
//     setValues({...values, ['country']:event.target.value});
//   };

  
 
//   const Delmsg = useSelector(state => state.AddBusiness && state.AddBusiness.deldata && state.AddBusiness.deldata.message ? state.AddBusiness.deldata.message : null  )
//   const Delerror = useSelector(state => state.AddBusiness && state.AddBusiness.delerror && state.AddBusiness.delerror.message ? state.AddBusiness.delerror.message : null  )





//   const sub_id = useSelector(state => state.login.data.r_subadmin)
  
  

  
// useEffect( () => {

//     // dispatch(getBusiness())


//     let x  = new Promise((resolve ,reject)=>{
       
//       if(Delmsg && typeof(Delmsg) === 'string')
//       {
//         tostS(Delmsg)
//       }
//       if(Delerror && typeof(Delerror) === 'string')
//       {
//           tostE(Delerror)
//       }

//       resolve()

//     })
//     x.then(()=>{

//    //  dispatch(cleanMaterialSupplyData())
//    //  dispatch(getMaterialSupply(sub_id, 1))

//     })


    
//   }, [ Delmsg  ,Delerror ])


//   const navigate = useNavigate();

// const onEdit = (olData) =>{
// //   navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
// }


// const onDel = (oldata) =>{

// //   dispatch(DelBusiness(oldata.bp_id ,oldata.tableData.id ))
// }
 







  


//   const addNewBornbutton = () =>{
//     // navigate('/app/materialindent')
//     console.log("prchase order")
    
//   }

  return (
    <>
      <Page className={classes.root} title="Material Delivery">
      <ToastContainer    />
        <Container maxWidth={false}>

        <Card sx={{ maxWidth: 345 }}>
      <CardHeader        
        title="Basic Details"
        subheader=""
      />
      
      <CardContent>
         <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
                Manpower ID : {state.rman_id}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
                 Site Name : {state.site_name}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Status : {state.status}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Accepted By : {state.accepted_by}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Date : {moment(state.date).format("DD/MM/YYYY")}
               </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                    
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
                  
                    <Typography variant="body2" color="text.secondary" >Location :  
                      {state.lat}, {state.long}                 
                    </Typography>
                  
                   : 
                   <Typography variant="body2" color="text.secondary" >Location: </Typography>
                   }
                    
                  </Grid>
            <Grid item xs={12} md={12}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Message : {state.message}
               </Typography>
            </Grid>
            
         </Grid>
         
        
        
      </CardContent>
      
      
    </Card>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Manpower Details"
      columns={[
        { title: 'Sr. No', field: 'name',editable: "never",
        render: rowData => (
          <Typography>{rowData.tableData.id + 1}</Typography>
        ),
       },
        { title: 'Name', field: 'name',editable: "never", },     
        { title: 'Rate/Day', field: 'rate',editable: "never", },     
        { title: 'Quantity', field: 'quantity',editable: "never", },
       

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" },
                search:false,
                paging:false
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
