import {
  Box,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  Button,
  Card,
} from "@material-ui/core";

import MaterialTable from "material-table";
import tableIcons from "../../../Config/IconsFile";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import XLSX from "xlsx";
import Page from "../../../../src/components/Page";
import clsx from "clsx";

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import { getClientSites } from "../../../Actions/SubAdminActions/M_AddSites";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";
import { Link } from "react-router-dom";

const ViewMB = ({ className, ...rest }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    root2: {},
    avatar: {
      marginRight: theme.spacing(2),
    },
    importButton: {
      marginRight: theme.spacing(1),
    },
    exportButton: {
      marginRight: theme.spacing(1),
    },
    root3: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    formControl: {
      margin: theme.spacing(0),
      minWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    rootq: {
      width: "100%",
      marginTop: "15px",
    },
    headingq: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeadingq: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));
  const id = useSelector((state) => state.login.data.admin_id);
  const SiteId = useSelector((state) => state.login.data.site_id);
  const classes = useStyles();
  const [boqData, setBoqData] = useState([]);
  const [values, setValues] = useState({
    sub_admin_id: id,
    site_id: SiteId,
  });

  const handleChangeSiteName = (event) => {
    setValues({ ...values, ["site_id"]: event.target.value });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(
      id,
      "======================================================================================================"
    );
    dispatch(getClientSites(id));
  }, []);

  const handleSubmit = () => {
    axios
      .get(Base_URL + "/client/ClientgetMB", {
        params: { site: values.site_id, sub_admin_id: values.measurement_id },
      })
      .then((res) => {
        if (res.data && res.data.boq_list && res.data.boq_list.length > 0) {
          setBoqData(res.data.boq_list);
          tostS("MB Fetched Successfully");
        } else {
          setBoqData([]);
          tostE("No MB Found");
        }
      })
      .catch((err) => {
        console.log(err);
        if (!err.response) {
          tostE("No mb found");
        }
      });
  };
  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(boqData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BOQ Data");
    XLSX.writeFile(workbook, "BOQ_Data.xlsx");
  };
  const SiteData = useSelector((state) =>
    state.AddSites &&
    state.AddSites.getData &&
    state.AddSites.getData.length &&
    Array.isArray(state.AddSites.getData)
      ? state.AddSites.getData
      : []
  );

  return (
    <Page title="Site Drawings">
      <ToastContainer />
      <Container maxWidth={false}>
        <Box mt={5} className={clsx(classes.root, className)}>
          <Card className={clsx(classes.root2, className)} {...rest}>
            <CardHeader subheader="Please Select Site" />
            <Container>
              <Grid spacing={3} container>
                <Grid item md={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Site Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      onChange={handleChangeSiteName}
                      label="Site Name"
                      value={values.site_id}
                      inputProps={{ readOnly: true }}
                    >
                      {SiteData.map((data, i) => (
                        <MenuItem key={i} value={data.site_id}>
                          {data.site_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3} style={{ float: "right" }}>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                  >
                    Get Measurement Book
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <Divider />
          </Card>
        </Box>
        <Box mt={3}>
          {boqData.length > 0 && (
            <Card>
              <MaterialTable
                icons={tableIcons}
                title="Measurement BOOK"
                columns={[
                  {
                    title: "SR. No.",
                    render: (rowData) => rowData.tableData.id + 1,
                  },
                  {
                    title: "Date",
                    field: "created_at",
                    render: (rowData) =>
                      moment(rowData.created_at).format("YYYY-MM-DD"),
                  },

                  { title: "Item Description", field: "item_description" },
                  { title: "Quantity", field: "quantity" },
                  { title: "Length", field: "length" },
                  { title: "Bredth", field: "breadth" },
                  { title: "noOfitems", field: "noofitems" },
                  { title: "Dia", field: "dia" },
                  { title: "Height", field: "height" },
                  { title: "Unit Weight", field: "unit_weight" },
                  { title: "Remarks", field: "remark" },
                ]}
                data={boqData}
              />
              <Button
                onClick={generateExcel}
                color="primary"
                style={{ float: "right" }}
                variant="contained"
              >
                Export to Excel
              </Button>
            </Card>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ViewMB;
