import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';
import {useLocation , useNavigate} from 'react-router-dom';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {getRoles} from '../../../Actions/SuperAdminActions/AddRoles'

import {editSuperProfileDetail} from '../../../Actions/SuperAdminActions/Profile'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const navigate = useNavigate();


  const handleChangef = (event) => {
    setValues({...values, ['permit_role']:event.target.value});
  };



  const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
   id :state.id,
   name :state.name,
   mobile :state.mobile,
   email : state.email,
   permit_role:state.permit_role,
  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 
  const [open, setOpen] = useState(false);

  const handleClose = () =>{

    setOpen(false);
  }
  
 
  const handleConfirm = () =>{
   dispatch(editSuperProfileDetail(values))
   navigate("/app/admin_panel_users")
  }

  useEffect(() =>{
    dispatch(getRoles())
  }, [])

  const handleOpen = () =>{
    var mobileReg = /^[6-9]{1}[0-9]{9}$/;
    var nameReg = /([a-zA-Z]{1,}\s?[a-zA-Z]+)+/;
   //  console.log("++++++++++++++" , values.mobile);
   //  console.log(mobileReg.test(values.mobile));
   //  console.log("+++++++++++++++");
   //  console.log(nameReg.test(values.name));

    if(mobileReg.test(values.mobile)!=true){
      tostW("Please Enter a Valid Mobile Number!!");

    }else if(nameReg.test(values.name)!=true){
      tostW("Please Enter a Valid Name!!")

    }else{

      if (values.name !== "" &&values.name !== null && values.name !== undefined){
        if (values.mobile !== "" &&values.mobile !== null && values.mobile !== undefined){
          if (values.email !== "" &&values.email !== null && values.email !== undefined){            
            if (values.permit_role !== "" &&values.permit_role !== null && values.permit_role !== undefined){
             
               setOpen(true);
               
            }else{
               tostE("Please Select role!!")
            }            
          }else{
            tostE("Please Enter Email !!")
          }
        }else{
          tostE("Please Enter Mobile number!!")
        }  
      }else{
        tostE("Please Enter Name!!")
      }
    }
    
    
  }



  const RData = useSelector((state) =>
  state.AddRoles && state.AddRoles.getData &&
    state.AddRoles.getData.length &&
    Array.isArray(state.AddRoles.getData)
      ? state.AddRoles.getData
      : []
  );

  return (
    <Page
      className={classes.root}
      title="Edit Super Admin User Detail"
    >
      <ToastContainer    />
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Edit Details of this User??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
      </Dialog>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
         
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Edit Super Admin User Detail" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
         
             <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Mobile"
                name="mobile"
                onChange={handleChange}
                required
                type="number"  InputProps={{inputProps:{min:0}}}
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>                        
            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.permit_role}
          onChange={handleChangef}
          label="Role"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          
           {RData.map((data , i)=>
          <MenuItem key={i}  value={data.role_id}>{data.role_name}</MenuItem>
        )}       

        </Select>
      </FormControl>
      </Grid>

          
            
       

      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleOpen} color="primary" variant="contained">
            Update details
          </Button>
        </Box>
      </Card>
    </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
