import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  CardMedia
  
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";




const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const {state} = useLocation();

  return (
    <>
      <Page className={classes.root} title="Quality Safety Details">
      <ToastContainer    />
        <Container maxWidth={false}>

        <Card sx={{ maxWidth: 345 }}>
      <CardHeader        
        title="Basic Details"
        subheader=""
      />
      
      <CardContent>
         <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
                Date: {moment(state.date).format("DD.MM.YYYY")}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Site Name : {state.site_name}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Engineer Name : {state.name}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Engineer Mobile : {state.mobile}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Issue Name : {state.qas_name}
               </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Issue Title : {state.issue_title}
               </Typography>
            </Grid>            
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Priority : {state.priority}
               </Typography>
            </Grid>
            
            <Grid item md={6} xs={12}>
                    
               {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
               
                  <Typography>Location :  
                     {state.lat}, {state.long}                 
                  </Typography>
               
                  : 
                  <Typography>Location: </Typography>
                  }
                    
            </Grid>
            <Grid item xs={6} md={6}>
               <Typography variant="body2" color="text.secondary" spacing={3}>
               Description : 
               {state.description.map((element,i) => (
                 <Typography>{element}</Typography>
               ))}
               </Typography>
            </Grid>
            {state.image && Array.isArray(state.image) && state.image.length ? 
               <Grid item lg={6}>
                  <Card>
                        <Typography> Image :</Typography>
                        <CardMedia onClick={()=>{window.open(state.image[0])}} component="img" image={state.image[0]} height="140" alt="Image Is Not Available"/>
                  </Card>
               </Grid>
            :<></>
            
            }
         </Grid>
         
        
        
      </CardContent>
      
      
    </Card>

    
          
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
