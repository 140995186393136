// Demo Api's 
export const  SELECTED_SITE = "SELECTED_SITE";

// Sub Admin Filter
export const  MAT_IND_START_DATE = "MAT_IND_START_DATE";
export const  MAT_IND_END_DATE = "MAT_IND_END_DATE";
export const  MAC_IND_START_DATE = "MAC_IND_START_DATE";
export const  MAC_IND_END_DATE = "MAC_IND_END_DATE";
export const  MAT_DEL_START_DATE = "MAT_DEL_START_DATE";
export const  MAT_DEL_END_DATE = "MAT_DEL_END_DATE";
export const  MAT_SUPP_START_DATE = "MAT_SUPP_START_DATE";
export const  MAT_SUPP_END_DATE = "MAT_SUPP_END_DATE";
export const  MAN_REPO_START_DATE = "MAN_REPO_START_DATE";
export const  MAN_REPO_END_DATE = "MAN_REPO_END_DATE";
export const  MAC_REPO_START_DATE = "MAC_REPO_START_DATE";
export const  MAC_REPO_END_DATE = "MAC_REPO_END_DATE";
export const  SITE_EXP_START_DATE = "SITE_EXP_START_DATE";
export const  SITE_EXP_END_DATE = "SITE_EXP_END_DATE";
export const  QS_START_DATE = "QS_START_DATE";
export const  QS_END_DATE = "QS_END_DATE";
export const  SITE_PROGRESS_START_DATE = "SITE_PROGRESS_START_DATE";
export const  SITE_PROGRESS_END_DATE = "SITE_PROGRESS_END_DATE";


// Client Date Filters
export const  CLIENT_MAT_DEL_START_DATE = "CLIENT_MAT_DEL_START_DATE";
export const  CLIENT_MAT_DEL_END_DATE = "CLIENT_MAT_DEL_END_DATE";
export const  CLIENT_MAC_REPO_START_DATE = "CLIENT_MAC_REPO_START_DATE";
export const  CLIENT_MAC_REPO_END_DATE = "CLIENT_MAC_REPO_END_DATE";
export const  CLIENT_MAN_REPO_START_DATE = "CLIENT_MAN_REPO_START_DATE";
export const  CLIENT_MAN_REPO_END_DATE = "CLIENT_MAN_REPO_END_DATE";
export const  CLIENT_SITE_EXP_START_DATE = "CLIENT_SITE_EXP_START_DATE";
export const  CLIENT_SITE_EXP_END_DATE = "CLIENT_SITE_EXP_END_DATE";
export const  CLIENT_SITE_PROGRESS_START_DATE = "CLIENT_SITE_PROGRESS_START_DATE";
export const  CLIENT_SITE_PROGRESS_END_DATE = "CLIENT_SITE_PROGRESS_END_DATE";



export const DEMO_LOGIN_USER_LOADING = "DEMO_LOGIN_USER_LOADING";
export const DEMO_LOGIN_USER_SUCCESS = 'DEMO_LOGIN_USER_SUCCESS';
export const DEMO_LOGIN_USER_FAILURE = 'DEMO_LOGIN_USER_FAILURE';


export const SUB_DASHBOARD_LOADING = "SUB_DASHBOARD_LOADING";
export const SUB_DASHBOARD_SUCCESS = 'SUB_DASHBOARD_SUCCESS';
export const SUB_DASHBOARD_FAILURE = 'SUB_DASHBOARD_FAILURE';

export const SUPER_DASHBOARD_LOADING = "SUPER_DASHBOARD_LOADING";
export const SUPER_DASHBOARD_SUCCESS = 'SUPER_DASHBOARD_SUCCESS';
export const SUPER_DASHBOARD_FAILURE = 'SUPER_DASHBOARD_FAILURE';


export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REFRESH_CLIENT_LOADING = "REFRESH_CLIENT_LOADING";
export const REFRESH_CLIENT_SUCCESS = 'REFRESH_CLIENT_SUCCESS';
export const REFRESH_CLIENT_FAILURE = 'REFRESH_CLIENT_FAILURE';

export const REFRESH_SUBADMIN_LOADING = "REFRESH_SUBADMIN_LOADING";
export const REFRESH_SUBADMIN_SUCCESS = 'REFRESH_SUBADMIN_SUCCESS';
export const REFRESH_SUBADMIN_FAILURE = 'REFRESH_SUBADMIN_FAILURE';

export const REFRESH_SUPERADMIN_LOADING = "REFRESH_SUPERADMIN_LOADING";
export const REFRESH_SUPERADMIN_SUCCESS = 'REFRESH_SUPERADMIN_SUCCESS';
export const REFRESH_SUPERADMIN_FAILURE = 'REFRESH_SUPERADMIN_FAILURE';

export const CLEAN_LOGIN_MSG = 'CLEAN_LOGIN_MSG';


// +++++++++++++++++++++++++PASSWORD RESET ++++++++++++++++++++++
export const GEN_OTP_LOADING = "GEN_OTP_LOADING";
export const GEN_OTP_SUCCESS = 'GEN_OTP_SUCCESS';
export const GEN_OTP_FAILURE = 'GEN_OTP_FAILURE';

export const OTP_EMAIL = 'OTP_EMAIL';


export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';


export const RESET_PASS_LOADING = "RESET_PASS_LOADING";
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS';
export const RESET_PASS_FAILURE = 'RESET_PASS_FAILURE';

export const CLEAN_RESET_PASS_DATA = 'CLEAN_RESET_PASS_DATA';

// +++++++++++++++++++++++++PASSWORD RESET ++++++++++++++++++++++

export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const CLEAN_ALL_DATA = "CLEAN_ALL_DATA"

export const ADD_PLAN_LOADING = "ADD_PLAN_LOADING";
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS';
export const ADD_PLAN_FAILURE = 'ADD_PLAN_FAILURE';

export const GET_PLAN_LOADING = "GET_PLAN_LOADING";
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE';

export const EDIT_PLAN_LOADING = "EDIT_PLAN_LOADING";
export const EDIT_PLAN_SUCCESS = 'EDIT_PLAN_SUCCESS';
export const EDIT_PLAN_FAILURE = 'EDIT_PLAN_FAILURE';

export const DEL_PLAN_LOADING = "DEL_PLAN_LOADING";
export const DEL_PLAN_SUCCESS = 'DEL_PLAN_SUCCESS';
export const DEL_PLAN_FAILURE = 'DEL_PLAN_FAILURE';

export const CLEAN_PLAN_DATA = "CLEAN_PLAN_DATA";



export const ADD_CLIENTS_LOADING = "ADD_CLIENTS_LOADING";
export const ADD_CLIENTS_SUCCESS = 'ADD_CLIENTS_SUCCESS';
export const ADD_CLIENTS_FAILURE = 'ADD_CLIENTS_FAILURE';

export const GET_CLIENTS_LOADING = "GET_CLIENTS_LOADING";
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';

export const GET_Report_LOADING = "GET_Report_LOADING";
export const GET_Report_SUCCESS = 'GET_Report_SUCCESS';
export const GET_Report_FAILURE = 'GET_Report_FAILURE';

export const EDIT_CLIENTS_LOADING = "EDIT_CLIENTS_LOADING";
export const EDIT_CLIENTS_SUCCESS = 'EDIT_CLIENTS_SUCCESS';
export const EDIT_CLIENTS_FAILURE = 'EDIT_CLIENTS_FAILURE';

export const EDIT_CLIENTS_PASS_LOADING = "EDIT_CLIENTS_PASS_LOADING";
export const EDIT_CLIENTS_PASS_SUCCESS = 'EDIT_CLIENTS_PASS_SUCCESS';
export const EDIT_CLIENTS_PASS_FAILURE = 'EDIT_CLIENTS_PASS_FAILURE';

export const DELETE_CLIENTS_LOADING = "DELETE_CLIENTS_LOADING";
export const DELETE_CLIENTS_SUCCESS = 'DELETE_CLIENTS_SUCCESS';
export const DELETE_CLIENTS_FAILURE = 'DELETE_CLIENTS_FAILURE';

export const GET_BACKUP_LOADING = "GET_BACKUP_LOADING";
export const GET_BACKUP_SUCCESS = 'GET_BACKUP_SUCCESS';
export const GET_BACKUP_FAILURE = 'GET_BACKUP_FAILURE';


export const CLEAN_CLIENTS_DATA = "CLEAN_CLIENTS_DATA";

export const ADD_GST_LOADING = "ADD_GST_LOADING";
export const ADD_GST_SUCCESS = 'ADD_GST_SUCCESS';
export const ADD_GST_FAILURE = 'ADD_GST_FAILURE';


export const GET_GST_LOADING = "GET_GST_LOADING";
export const GET_GST_SUCCESS = 'GET_GST_SUCCESS';
export const GET_GST_FAILURE = 'GET_GST_FAILURE';

export const EDIT_GST_LOADING = "EDIT_GST_LOADING";
export const EDIT_GST_SUCCESS = 'EDIT_GST_SUCCESS';
export const EDIT_GST_FAILURE = 'EDIT_GST_FAILURE';

export const DEL_GST_LOADING = "DEL_GST_LOADING";
export const DEL_GST_SUCCESS = 'DEL_GST_SUCCESS';
export const DEL_GST_FAILURE = 'DEL_GST_FAILURE';


export const CLEAN_GST_DATA = "CLEAN_GST_DATA";


export const GET_SUB_GST_LOADING = "GET_SUB_GST_LOADING";
export const GET_SUB_GST_SUCCESS = 'GET_SUB_GST_SUCCESS';
export const GET_SUB_GST_FAILURE = 'GET_SUB_GST_FAILURE';



export const ADD_ROLES_LOADING = "ADD_ROLES_LOADING";
export const ADD_ROLES_SUCCESS = 'ADD_ROLES_SUCCESS';
export const ADD_ROLES_FAILURE = 'ADD_ROLES_FAILURE';

export const GET_ROLES_LOADING = "GET_ROLES_LOADING";
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const EDIT_ROLES_LOADING = "EDIT_ROLES_LOADING";
export const EDIT_ROLES_SUCCESS = 'EDIT_ROLES_SUCCESS';
export const EDIT_ROLES_FAILURE = 'EDIT_ROLES_FAILURE';


export const DEL_ROLES_LOADING = "DEL_ROLES_LOADING";
export const DEL_ROLES_SUCCESS = 'DEL_ROLES_SUCCESS';
export const DEL_ROLES_FAILURE = 'DEL_ROLES_FAILURE';

export const CLEAN_ROLES_DATA = "CLEAN_ROLES_DATA"


export const ADD_PANEL_USER_LOADING = "ADD_PANEL_USER_LOADING";
export const ADD_PANEL_USER_SUCCESS = 'ADD_PANEL_USER_SUCCESS';
export const ADD_PANEL_USER_FAILURE = 'ADD_PANEL_USER_FAILURE';

export const GET_PANEL_USER_LOADING = "GET_PANEL_USER_LOADING";
export const GET_PANEL_USER_SUCCESS = 'GET_PANEL_USER_SUCCESS';
export const GET_PANEL_USER_FAILURE = 'GET_PANEL_USER_FAILURE';

export const DEL_PANEL_USER_LOADING = "DEL_PANEL_USER_LOADING";
export const DEL_PANEL_USER_SUCCESS = 'DEL_PANEL_USER_SUCCESS';
export const DEL_PANEL_USER_FAILURE = 'DEL_PANEL_USER_FAILURE';

export const CLEAN_PANEL_USER_DATA = 'CLEAN_PANEL_USER_DATA';


export const GET_SUPER_ENQ_LOADING = "GET_SUPER_ENQ_LOADING";
export const GET_SUPER_ENQ_SUCCESS = 'GET_SUPER_ENQ_SUCCESS';
export const GET_SUPER_ENQ_FAILURE = 'GET_SUPER_ENQ_FAILURE';

export const GET_SUPER_TICK_LOADING = "GET_SUPER_TICK_LOADING";
export const GET_SUPER_TICK_SUCCESS = 'GET_SUPER_TICK_SUCCESS';
export const GET_SUPER_TICK_FAILURE = 'GET_SUPER_TICK_FAILURE';

export const EDIT_SUPER_TICK_LOADING = "EDIT_SUPER_TICK_LOADING";
export const EDIT_SUPER_TICK_SUCCESS = 'EDIT_SUPER_TICK_SUCCESS';
export const EDIT_SUPER_TICK_FAILURE = 'EDIT_SUPER_TICK_FAILURE';

export const DEL_SUPER_TICK_LOADING = "DEL_SUPER_TICK_LOADING";
export const DEL_SUPER_TICK_SUCCESS = 'DEL_SUPER_TICK_SUCCESS';
export const DEL_SUPER_TICK_FAILURE = 'DEL_SUPER_TICK_FAILURE';

export const CLEAN_SUPER_TICK_DATA = 'CLEAN_SUPER_TICK_DATA';

export const GET_SUPER_PROFILE_DETAIL_LOADING = "GET_SUPER_PROFILE_DETAIL_LOADING";
export const GET_SUPER_PROFILE_DETAIL_SUCCESS = 'GET_SUPER_PROFILE_DETAIL_SUCCESS';
export const GET_SUPER_PROFILE_DETAIL_FAILURE = 'GET_SUPER_PROFILE_DETAIL_FAILURE';

export const EDIT_SUPER_PROFILE_DETAIL_LOADING = "EDIT_SUPER_PROFILE_DETAIL_LOADING";
export const EDIT_SUPER_PROFILE_DETAIL_SUCCESS = 'EDIT_SUPER_PROFILE_DETAIL_SUCCESS';
export const EDIT_SUPER_PROFILE_DETAIL_FAILURE = 'EDIT_SUPER_PROFILE_DETAIL_FAILURE';

export const EDIT_SUPER_PROFILE_PASSWORD_LOADING = "EDIT_SUPER_PROFILE_PASSWORD_LOADING";
export const EDIT_SUPER_PROFILE_PASSWORD_SUCCESS = 'EDIT_SUPER_PROFILE_PASSWORD_SUCCESS';
export const EDIT_SUPER_PROFILE_PASSWORD_FAILURE = 'EDIT_SUPER_PROFILE_PASSWORD_FAILURE';

export const EDIT_SUPER_PROFILE_PIC_LOADING = "EDIT_SUPER_PROFILE_PIC_LOADING";
export const EDIT_SUPER_PROFILE_PIC_SUCCESS = 'EDIT_SUPER_PROFILE_PIC_SUCCESS';
export const EDIT_SUPER_PROFILE_PIC_FAILURE = 'EDIT_SUPER_PROFILE_PIC_FAILURE';


export const CLEAN_SUPER_PROFILE_DETAIL_DATA = 'CLEAN_SUPER_PROFILE_DETAIL_DATA';


// SubAdmin

export const ADD_SITES_LOADING = "ADD_SITES_LOADING";
export const ADD_SITES_SUCCESS = 'ADD_SITES_SUCCESS';
export const ADD_SITES_FAILURE = 'ADD_SITES_FAILURE';

export const GET_SITES_LOADING = "GET_SITES_LOADING";
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_SITES_FAILURE = 'GET_SITES_FAILURE';

export const GET_SITES_MASTER_LOADING = "GET_SITES_MASTER_LOADING";
export const GET_SITES_MASTER_SUCCESS = 'GET_SITES_MASTER_SUCCESS';
export const GET_SITES_MASTER_FAILURE = 'GET_SITES_MASTER_FAILURE';

export const DEL_SITES_LOADING = "DEL_SITES_LOADING";
export const DEL_SITES_SUCCESS = 'DEL_SITES_SUCCESS';
export const DEL_SITES_FAILURE = 'DEL_SITES_FAILURE';

export const EDIT_SITES_LOADING = "EDIT_SITES_LOADING";
export const EDIT_SITES_SUCCESS = 'EDIT_SITES_SUCCESS';
export const EDIT_SITES_FAILURE = 'EDIT_SITES_FAILURE';

export const CLEAN_SITES_DATA = 'CLEAN_SITES_DATA';

export const ADD_SUB_ROLE_LOADING = "ADD_SUB_ROLE_LOADING";
export const ADD_SUB_ROLE_SUCCESS = 'ADD_SUB_ROLE_SUCCESS';
export const ADD_SUB_ROLE_FAILURE = 'ADD_SUB_ROLE_FAILURE';

export const GET_SUB_ROLE_LOADING = "GET_SUB_ROLE_LOADING";
export const GET_SUB_ROLE_SUCCESS = 'GET_SUB_ROLE_SUCCESS';
export const GET_SUB_ROLE_FAILURE = 'GET_SUB_ROLE_FAILURE';

export const EDIT_SUB_ROLE_LOADING = "EDIT_SUB_ROLE_LOADING";
export const EDIT_SUB_ROLE_SUCCESS = 'EDIT_SUB_ROLE_SUCCESS';
export const EDIT_SUB_ROLE_FAILURE = 'EDIT_SUB_ROLE_FAILURE';

export const DEL_SUB_ROLE_LOADING = "DEL_SUB_ROLE_LOADING";
export const DEL_SUB_ROLE_SUCCESS = 'DEL_SUB_ROLE_SUCCESS';
export const DEL_SUB_ROLE_FAILURE = 'DEL_SUB_ROLE_FAILURE';


export const CLEAN_SUB_ROLE_DATA = 'CLEAN_SUB_ROLE_DATA';


export const ADD_APP_USER_MGMT_LOADING = "ADD_APP_USER_MGMT_LOADING";
export const ADD_APP_USER_MGMT_SUCCESS = 'ADD_APP_USER_MGMT_SUCCESS';
export const ADD_APP_USER_MGMT_FAILURE = 'ADD_APP_USER_MGMT_FAILURE';

export const GET_APP_USER_MGMT_LOADING = "GET_APP_USER_MGMT_LOADING";
export const GET_APP_USER_MGMT_SUCCESS = 'GET_APP_USER_MGMT_SUCCESS';
export const GET_APP_USER_MGMT_FAILURE = 'GET_APP_USER_MGMT_FAILURE';

export const EDIT_APP_USER_MGMT_LOADING = "EDIT_APP_USER_MGMT_LOADING";
export const EDIT_APP_USER_MGMT_SUCCESS = 'EDIT_APP_USER_MGMT_SUCCESS';
export const EDIT_APP_USER_MGMT_FAILURE = 'EDIT_APP_USER_MGMT_FAILURE';

export const DEL_APP_USER_MGMT_LOADING = "DEL_APP_USER_MGMT_LOADING";
export const DEL_APP_USER_MGMT_SUCCESS = 'DEL_APP_USER_MGMT_SUCCESS';
export const DEL_APP_USER_MGMT_FAILURE = 'DEL_APP_USER_MGMT_FAILURE';


export const CLEAN_APP_USER_MGMT_DATA = 'CLEAN_APP_USER_MGMT_DATA';


export const GET_SUB_TICK_LOADING = "GET_SUB_TICK_LOADING";
export const GET_SUB_TICK_SUCCESS = 'GET_SUB_TICK_SUCCESS';
export const GET_SUB_TICK_FAILURE = 'GET_SUB_TICK_FAILURE';


export const GET_ALL_TICK_LOADING = "GET_ALL_TICK_LOADING";
export const GET_ALL_TICK_SUCCESS = 'GET_ALL_TICK_SUCCESS';
export const GET_ALL_TICK_FAILURE = 'GET_ALL_TICK_FAILURE';

export const ADD_SUB_TICK_LOADING = "ADD_SUB_TICK_LOADING";
export const ADD_SUB_TICK_SUCCESS = 'ADD_SUB_TICK_SUCCESS';
export const ADD_SUB_TICK_FAILURE = 'ADD_SUB_TICK_FAILURE';

export const EDIT_SUB_TICK_LOADING = "EDIT_SUB_TICK_LOADING";
export const EDIT_SUB_TICK_SUCCESS = 'EDIT_SUB_TICK_SUCCESS';
export const EDIT_SUB_TICK_FAILURE = 'EDIT_SUB_TICK_FAILURE';


export const DEL_SUB_TICK_LOADING = "DEL_SUB_TICK_LOADING";
export const DEL_SUB_TICK_SUCCESS = 'DEL_SUB_TICK_SUCCESS';
export const DEL_SUB_TICK_FAILURE = 'DEL_SUB_TICK_FAILURE';


export const DEL_TICKET_LOADING = "DEL_TICKET_LOADING";
export const DEL_TICKET_SUCCESS = 'DEL_TICKET_SUCCESS';
export const DEL_TICKET_FAILURE = 'DEL_TICKET_FAILURE';


export const CLEAN_SUB_TICK_DATA = 'CLEAN_SUB_TICK_DATA';

export const ADD_MANPOWER_CATEGORY_LOADING = "ADD_MANPOWER_CATEGORY_LOADING";
export const ADD_MANPOWER_CATEGORY_SUCCESS = 'ADD_MANPOWER_CATEGORY_SUCCESS';
export const ADD_MANPOWER_CATEGORY_FAILURE = 'ADD_MANPOWER_CATEGORY_FAILURE';

export const GET_MANPOWER_CATEGORY_LOADING = "GET_MANPOWER_CATEGORY_LOADING";
export const GET_MANPOWER_CATEGORY_SUCCESS = 'GET_MANPOWER_CATEGORY_SUCCESS';
export const GET_MANPOWER_CATEGORY_FAILURE = 'GET_MANPOWER_CATEGORY_FAILURE';

export const DEL_MANPOWER_CATEGORY_LOADING = "DEL_MANPOWER_CATEGORY_LOADING";
export const DEL_MANPOWER_CATEGORY_SUCCESS = 'DEL_MANPOWER_CATEGORY_SUCCESS';
export const DEL_MANPOWER_CATEGORY_FAILURE = 'DEL_MANPOWER_CATEGORY_FAILURE';

export const EDIT_MANPOWER_CATEGORY_LOADING = "EDIT_MANPOWER_CATEGORY_LOADING";
export const EDIT_MANPOWER_CATEGORY_SUCCESS = 'EDIT_MANPOWER_CATEGORY_SUCCESS';
export const EDIT_MANPOWER_CATEGORY_FAILURE = 'EDIT_MANPOWER_CATEGORY_FAILURE';


export const CLEAN_MANPOWER_CATEGORY_DATA = 'CLEAN_MANPOWER_CATEGORY_DATA';

export const ADD_MANPOWER_MAN_LOADING = "ADD_MANPOWER_MAN_LOADING";
export const ADD_MANPOWER_MAN_SUCCESS = 'ADD_MANPOWER_MAN_SUCCESS';
export const ADD_MANPOWER_MAN_FAILURE = 'ADD_MANPOWER_MAN_FAILURE';

export const GET_MANPOWER_MAN_LOADING = "GET_MANPOWER_MAN_LOADING";
export const GET_MANPOWER_MAN_SUCCESS = 'GET_MANPOWER_MAN_SUCCESS';
export const GET_MANPOWER_MAN_FAILURE = 'GET_MANPOWER_MAN_FAILURE';

export const DEL_MANPOWER_MAN_LOADING = "DEL_MANPOWER_MAN_LOADING";
export const DEL_MANPOWER_MAN_SUCCESS = 'DEL_MANPOWER_MAN_SUCCESS';
export const DEL_MANPOWER_MAN_FAILURE = 'DEL_MANPOWER_MAN_FAILURE';

export const EDIT_MANPOWER_MAN_LOADING = "EDIT_MANPOWER_MAN_LOADING";
export const EDIT_MANPOWER_MAN_SUCCESS = 'EDIT_MANPOWER_MAN_SUCCESS';
export const EDIT_MANPOWER_MAN_FAILURE = 'EDIT_MANPOWER_MAN_FAILURE';


export const CLEAN_MANPOWER_MAN_DATA = 'CLEAN_MANPOWER_MAN_DATA';

export const ADD_MATERIAL_CATEGORY_LOADING = "ADD_MATERIAL_CATEGORY_LOADING";
export const ADD_MATERIAL_CATEGORY_SUCCESS = 'ADD_MATERIAL_CATEGORY_SUCCESS';
export const ADD_MATERIAL_CATEGORY_FAILURE = 'ADD_MATERIAL_CATEGORY_FAILURE';

export const GET_MATERIAL_CATEGORY_LOADING = "GET_MATERIAL_CATEGORY_LOADING";
export const GET_MATERIAL_CATEGORY_SUCCESS = 'GET_MATERIAL_CATEGORY_SUCCESS';
export const GET_MATERIAL_CATEGORY_FAILURE = 'GET_MATERIAL_CATEGORY_FAILURE';

export const DEL_MATERIAL_CATEGORY_LOADING = "DEL_MATERIAL_CATEGORY_LOADING";
export const DEL_MATERIAL_CATEGORY_SUCCESS = 'DEL_MATERIAL_CATEGORY_SUCCESS';
export const DEL_MATERIAL_CATEGORY_FAILURE = 'DEL_MATERIAL_CATEGORY_FAILURE';

export const EDIT_MATERIAL_CATEGORY_LOADING = "EDIT_MATERIAL_CATEGORY_LOADING";
export const EDIT_MATERIAL_CATEGORY_SUCCESS = 'EDIT_MATERIAL_CATEGORY_SUCCESS';
export const EDIT_MATERIAL_CATEGORY_FAILURE = 'EDIT_MATERIAL_CATEGORY_FAILURE';

export const CLEAN_MATERIAL_CATEGORY_DATA = 'CLEAN_MATERIAL_CATEGORY_DATA';

export const ADD_MATERIAL_MAT_LOADING = "ADD_MATERIAL_MAT_LOADING";
export const ADD_MATERIAL_MAT_SUCCESS = 'ADD_MATERIAL_MAT_SUCCESS';
export const ADD_MATERIAL_MAT_FAILURE = 'ADD_MATERIAL_MAT_FAILURE';

export const GET_MATERIAL_MAT_LOADING = "GET_MATERIAL_MAT_LOADING";
export const GET_MATERIAL_MAT_SUCCESS = 'GET_MATERIAL_MAT_SUCCESS';
export const GET_MATERIAL_MAT_FAILURE = 'GET_MATERIAL_MAT_FAILURE';

export const DEL_MATERIAL_MAT_LOADING = "DEL_MATERIAL_MAT_LOADING";
export const DEL_MATERIAL_MAT_SUCCESS = 'DEL_MATERIAL_MAT_SUCCESS';
export const DEL_MATERIAL_MAT_FAILURE = 'DEL_MATERIAL_MAT_FAILURE';

export const EDIT_MATERIAL_MAT_LOADING = "EDIT_MATERIAL_MAT_LOADING";
export const EDIT_MATERIAL_MAT_SUCCESS = 'EDIT_MATERIAL_MAT_SUCCESS';
export const EDIT_MATERIAL_MAT_FAILURE = 'EDIT_MATERIAL_MAT_FAILURE';

export const CLEAN_MATERIAL_MAT_DATA = 'CLEAN_MATERIAL_MAT_DATA';


export const ADD_VENDOR_LOADING = "ADD_VENDOR_LOADING";
export const ADD_VENDOR_SUCCESS = 'ADD_VENDOR_SUCCESS';
export const ADD_VENDOR_FAILURE = 'ADD_VENDOR_FAILURE';

export const GET_VENDOR_LOADING = "GET_VENDOR_LOADING";
export const GET_VENDOR_SUCCESS = 'GET_VENDOR_SUCCESS';
export const GET_VENDOR_FAILURE = 'GET_VENDOR_FAILURE';

export const EDIT_VENDOR_LOADING = "EDIT_VENDOR_LOADING";
export const EDIT_VENDOR_SUCCESS = 'EDIT_VENDOR_SUCCESS';
export const EDIT_VENDOR_FAILURE = 'EDIT_VENDOR_FAILURE';

export const DEL_VENDOR_LOADING = "DEL_VENDOR_LOADING";
export const DEL_VENDOR_SUCCESS = 'DEL_VENDOR_SUCCESS';
export const DEL_VENDOR_FAILURE = 'DEL_VENDOR_FAILURE';

export const CLEAN_VENDOR_DATA = 'CLEAN_VENDOR_DATA';

export const ADD_RESOURCE_UNIT_LOADING = "ADD_RESOURCE_UNIT_LOADING";
export const ADD_RESOURCE_UNIT_SUCCESS = 'ADD_RESOURCE_UNIT_SUCCESS';
export const ADD_RESOURCE_UNIT_FAILURE = 'ADD_RESOURCE_UNIT_FAILURE';

export const GET_RESOURCE_UNIT_LOADING = "GET_RESOURCE_UNIT_LOADING";
export const GET_RESOURCE_UNIT_SUCCESS = 'GET_RESOURCE_UNIT_SUCCESS';
export const GET_RESOURCE_UNIT_FAILURE = 'GET_RESOURCE_UNIT_FAILURE';

export const DEL_RESOURCE_UNIT_LOADING = "DEL_RESOURCE_UNIT_LOADING";
export const DEL_RESOURCE_UNIT_SUCCESS = 'DEL_RESOURCE_UNIT_SUCCESS';
export const DEL_RESOURCE_UNIT_FAILURE = 'DEL_RESOURCE_UNIT_FAILURE';

export const EDIT_RESOURCE_UNIT_LOADING = "EDIT_RESOURCE_UNIT_LOADING";
export const EDIT_RESOURCE_UNIT_SUCCESS = 'EDIT_RESOURCE_UNIT_SUCCESS';
export const EDIT_RESOURCE_UNIT_FAILURE = 'EDIT_RESOURCE_UNIT_FAILURE';

export const CLEAN_RESOURCE_UNIT_DATA = 'CLEAN_RESOURCE_UNIT_DATA';

export const ADD_RESOURCE_MACHINE_LOADING = "ADD_RESOURCE_MACHINE_LOADING";
export const ADD_RESOURCE_MACHINE_SUCCESS = 'ADD_RESOURCE_MACHINE_SUCCESS';
export const ADD_RESOURCE_MACHINE_FAILURE = 'ADD_RESOURCE_MACHINE_FAILURE';

export const GET_RESOURCE_MACHINE_LOADING = "GET_RESOURCE_MACHINE_LOADING";
export const GET_RESOURCE_MACHINE_SUCCESS = 'GET_RESOURCE_MACHINE_SUCCESS';
export const GET_RESOURCE_MACHINE_FAILURE = 'GET_RESOURCE_MACHINE_FAILURE';

export const DEL_RESOURCE_MACHINE_LOADING = "DEL_RESOURCE_MACHINE_LOADING";
export const DEL_RESOURCE_MACHINE_SUCCESS = 'DEL_RESOURCE_MACHINE_SUCCESS';
export const DEL_RESOURCE_MACHINE_FAILURE = 'DEL_RESOURCE_MACHINE_FAILURE';

export const EDIT_RESOURCE_MACHINE_LOADING = "EDIT_RESOURCE_MACHINE_LOADING";
export const EDIT_RESOURCE_MACHINE_SUCCESS = 'EDIT_RESOURCE_MACHINE_SUCCESS';
export const EDIT_RESOURCE_MACHINE_FAILURE = 'EDIT_RESOURCE_MACHINE_FAILURE';

export const CLEAN_RESOURCE_MACHINE_DATA = 'CLEAN_RESOURCE_MACHINE_DATA';


export const ADD_MACHINE_CAT_LOADING = "ADD_MACHINE_CAT_LOADING";
export const ADD_MACHINE_CAT_SUCCESS = 'ADD_MACHINE_CAT_SUCCESS';
export const ADD_MACHINE_CAT_FAILURE = 'ADD_MACHINE_CAT_FAILURE';

export const GET_MACHINE_CAT_LOADING = "GET_MACHINE_CAT_LOADING";
export const GET_MACHINE_CAT_SUCCESS = 'GET_MACHINE_CAT_SUCCESS';
export const GET_MACHINE_CAT_FAILURE = 'GET_MACHINE_CAT_FAILURE';

export const DEL_MACHINE_CAT_LOADING = "DEL_MACHINE_CAT_LOADING";
export const DEL_MACHINE_CAT_SUCCESS = 'DEL_MACHINE_CAT_SUCCESS';
export const DEL_MACHINE_CAT_FAILURE = 'DEL_MACHINE_CAT_FAILURE';

export const EDIT_MACHINE_CAT_LOADING = "EDIT_MACHINE_CAT_LOADING";
export const EDIT_MACHINE_CAT_SUCCESS = 'EDIT_MACHINE_CAT_SUCCESS';
export const EDIT_MACHINE_CAT_FAILURE = 'EDIT_MACHINE_CAT_FAILURE';

export const CLEAN_MACHINE_CAT_DATA = 'CLEAN_MACHINE_CAT_DATA';


export const ADD_RESOURCE_QUALITY_LOADING = "ADD_RESOURCE_QUALITY_LOADING";
export const ADD_RESOURCE_QUALITY_SUCCESS = 'ADD_RESOURCE_QUALITY_SUCCESS';
export const ADD_RESOURCE_QUALITY_FAILURE = 'ADD_RESOURCE_QUALITY_FAILURE';

export const GET_RESOURCE_QUALITY_LOADING = "GET_RESOURCE_QUALITY_LOADING";
export const GET_RESOURCE_QUALITY_SUCCESS = 'GET_RESOURCE_QUALITY_SUCCESS';
export const GET_RESOURCE_QUALITY_FAILURE = 'GET_RESOURCE_QUALITY_FAILURE';

export const DEL_RESOURCE_QUALITY_LOADING = "DEL_RESOURCE_QUALITY_LOADING";
export const DEL_RESOURCE_QUALITY_SUCCESS = 'DEL_RESOURCE_QUALITY_SUCCESS';
export const DEL_RESOURCE_QUALITY_FAILURE = 'DEL_RESOURCE_QUALITY_FAILURE';

export const EDIT_RESOURCE_QUALITY_LOADING = "EDIT_RESOURCE_QUALITY_LOADING";
export const EDIT_RESOURCE_QUALITY_SUCCESS = 'EDIT_RESOURCE_QUALITY_SUCCESS';
export const EDIT_RESOURCE_QUALITY_FAILURE = 'EDIT_RESOURCE_QUALITY_FAILURE';

export const CLEAN_RESOURCE_QUALITY_DATA = 'CLEAN_RESOURCE_QUALITY_DATA';

export const ADD_SITE_EXPENSE_LOADING = "ADD_SITE_EXPENSE_LOADING";
export const ADD_SITE_EXPENSE_SUCCESS = 'ADD_SITE_EXPENSE_SUCCESS';
export const ADD_SITE_EXPENSE_FAILURE = 'ADD_SITE_EXPENSE_FAILURE';

export const GET_SITE_EXPENSE_LOADING = "GET_SITE_EXPENSE_LOADING";
export const GET_SITE_EXPENSE_SUCCESS = 'GET_SITE_EXPENSE_SUCCESS';
export const GET_SITE_EXPENSE_FAILURE = 'GET_SITE_EXPENSE_FAILURE';

export const DEL_SITE_EXPENSE_LOADING = "DEL_SITE_EXPENSE_LOADING";
export const DEL_SITE_EXPENSE_SUCCESS = 'DEL_SITE_EXPENSE_SUCCESS';
export const DEL_SITE_EXPENSE_FAILURE = 'DEL_SITE_EXPENSE_FAILURE';

export const EDIT_SITE_EXPENSE_LOADING = "EDIT_SITE_EXPENSE_LOADING";
export const EDIT_SITE_EXPENSE_SUCCESS = 'EDIT_SITE_EXPENSE_SUCCESS';
export const EDIT_SITE_EXPENSE_FAILURE = 'EDIT_SITE_EXPENSE_FAILURE';

export const CLEAN_SITE_EXPENSE_DATA = 'CLEAN_SITE_EXPENSE_DATA';

export const ADD_DRAW_LOADING = "ADD_DRAW_LOADING";
export const ADD_DRAW_SUCCESS = 'ADD_DRAW_SUCCESS';
export const ADD_DRAW_FAILURE = 'ADD_DRAW_FAILURE';

export const GET_DRAW_LOADING = "GET_DRAW_LOADING";
export const GET_DRAW_SUCCESS = 'GET_DRAW_SUCCESS';
export const GET_DRAW_FAILURE = 'GET_DRAW_FAILURE';

export const DEL_DRAW_LOADING = "DEL_DRAW_LOADING";
export const DEL_DRAW_SUCCESS = 'DEL_DRAW_SUCCESS';
export const DEL_DRAW_FAILURE = 'DEL_DRAW_FAILURE';

export const EDIT_DRAW_LOADING = "EDIT_DRAW_LOADING";
export const EDIT_DRAW_SUCCESS = 'EDIT_DRAW_SUCCESS';
export const EDIT_DRAW_FAILURE = 'EDIT_DRAW_FAILURE';

export const CLEAN_DRAW_DATA = 'CLEAN_DRAW_DATA';

export const ADD_DRAW_TYPE_LOADING = "ADD_DRAW_TYPE_LOADING";
export const ADD_DRAW_TYPE_SUCCESS = 'ADD_DRAW_TYPE_SUCCESS';
export const ADD_DRAW_TYPE_FAILURE = 'ADD_DRAW_TYPE_FAILURE';

export const GET_DRAW_TYPE_LOADING = "GET_DRAW_TYPE_LOADING";
export const GET_DRAW_TYPE_SUCCESS = 'GET_DRAW_TYPE_SUCCESS';
export const GET_DRAW_TYPE_FAILURE = 'GET_DRAW_TYPE_FAILURE';

export const DEL_DRAW_TYPE_LOADING = "DEL_DRAW_TYPE_LOADING";
export const DEL_DRAW_TYPE_SUCCESS = 'DEL_DRAW_TYPE_SUCCESS';
export const DEL_DRAW_TYPE_FAILURE = 'DEL_DRAW_TYPE_FAILURE';

export const EDIT_DRAW_TYPE_LOADING = "EDIT_DRAW_TYPE_LOADING";
export const EDIT_DRAW_TYPE_SUCCESS = 'EDIT_DRAW_TYPE_SUCCESS';
export const EDIT_DRAW_TYPE_FAILURE = 'EDIT_DRAW_TYPE_FAILURE';

export const CLEAN_DRAW_TYPE_DATA = 'CLEAN_DRAW_TYPE_DATA';

export const ADD_OTHER_SUB_ADMIN_LOADING = "ADD_OTHER_SUB_ADMIN_LOADING";
export const ADD_OTHER_SUB_ADMIN_SUCCESS = 'ADD_OTHER_SUB_ADMIN_SUCCESS';
export const ADD_OTHER_SUB_ADMIN_FAILURE = 'ADD_OTHER_SUB_ADMIN_FAILURE';

export const GET_OTHER_SUB_ADMIN_LOADING = "GET_OTHER_SUB_ADMIN_LOADING";
export const GET_OTHER_SUB_ADMIN_SUCCESS = 'GET_OTHER_SUB_ADMIN_SUCCESS';
export const GET_OTHER_SUB_ADMIN_FAILURE = 'GET_OTHER_SUB_ADMIN_FAILURE';

export const EDIT_OTHER_SUB_ADMIN_LOADING = "EDIT_OTHER_SUB_ADMIN_LOADING";
export const EDIT_OTHER_SUB_ADMIN_SUCCESS = 'EDIT_OTHER_SUB_ADMIN_SUCCESS';
export const EDIT_OTHER_SUB_ADMIN_FAILURE = 'EDIT_OTHER_SUB_ADMIN_FAILURE';

export const DEL_OTHER_SUB_ADMIN_LOADING = "DEL_OTHER_SUB_ADMIN_LOADING";
export const DEL_OTHER_SUB_ADMIN_SUCCESS = 'DEL_OTHER_SUB_ADMIN_SUCCESS';
export const DEL_OTHER_SUB_ADMIN_FAILURE = 'DEL_OTHER_SUB_ADMIN_FAILURE';

export const BLOCK_OTHER_SUB_ADMIN_LOADING = "BLOCK_OTHER_SUB_ADMIN_LOADING";
export const BLOCK_OTHER_SUB_ADMIN_SUCCESS = 'BLOCK_OTHER_SUB_ADMIN_SUCCESS';
export const BLOCK_OTHER_SUB_ADMIN_FAILURE = 'BLOCK_OTHER_SUB_ADMIN_FAILURE';

export const CLEAN_OTHER_SUB_ADMIN_DATA = 'CLEAN_OTHER_SUB_ADMIN_DATA';


export const EDIT_PROFILE_MAN_CRE_ADMIN_LOADING = "EDIT_PROFILE_MAN_CRE_ADMIN_LOADING";
export const EDIT_PROFILE_MAN_CRE_ADMIN_SUCCESS = 'EDIT_PROFILE_MAN_CRE_ADMIN_SUCCESS';
export const EDIT_PROFILE_MAN_CRE_ADMIN_FAILURE = 'EDIT_PROFILE_MAN_CRE_ADMIN_FAILURE';

export const GET_PROFILE_MAN_CRE_ADMIN_LOADING = "GET_PROFILE_MAN_CRE_ADMIN_LOADING";
export const GET_PROFILE_MAN_CRE_ADMIN_SUCCESS = 'GET_PROFILE_MAN_CRE_ADMIN_SUCCESS';
export const GET_PROFILE_MAN_CRE_ADMIN_FAILURE = 'GET_PROFILE_MAN_CRE_ADMIN_FAILURE';

export const EDIT_PROFILE_MAN_PASS_ADMIN_LOADING = "EDIT_PROFILE_MAN_PASS_ADMIN_LOADING";
export const EDIT_PROFILE_MAN_PASS_ADMIN_SUCCESS = 'EDIT_PROFILE_MAN_PASS_ADMIN_SUCCESS';
export const EDIT_PROFILE_MAN_PASS_ADMIN_FAILURE = 'EDIT_PROFILE_MAN_PASS_ADMIN_FAILURE';

export const EDIT_PROFILE_MAN_PIC_ADMIN_LOADING = "EDIT_PROFILE_MAN_PIC_ADMIN_LOADING";
export const EDIT_PROFILE_MAN_PIC_ADMIN_SUCCESS = 'EDIT_PROFILE_MAN_PIC_ADMIN_SUCCESS';
export const EDIT_PROFILE_MAN_PIC_ADMIN_FAILURE = 'EDIT_PROFILE_MAN_PIC_ADMIN_FAILURE';

export const EDIT_SUB_LOGO_LOADING = "EDIT_SUB_LOGO_LOADING";
export const EDIT_SUB_LOGO_SUCCESS = 'EDIT_SUB_LOGO_SUCCESS';
export const EDIT_SUB_LOGO_FAILURE = 'EDIT_SUB_LOGO_FAILURE';

export const CLEAN_PROFILE_MAN_CRE_ADMIN_DATA = 'CLEAN_PROFILE_MAN_CRE_ADMIN_DATA';

export const ADD_SITE_DRAW_LOADING = "ADD_SITE_DRAW_LOADING";
export const ADD_SITE_DRAW_SUCCESS = 'ADD_SITE_DRAW_SUCCESS';
export const ADD_SITE_DRAW_FAILURE = 'ADD_SITE_DRAW_FAILURE';


export const ADD_BOQ_SUCCESS= 'ADD_BOQ_SUCCESS';
export const ADD_BOQ_FAILURE = 'ADD_BOQ_FAILURE';



export const GET_SITE_DRAW_LOADING = "GET_SITE_DRAW_LOADING";
export const GET_SITE_DRAW_SUCCESS = 'GET_SITE_DRAW_SUCCESS';
export const GET_SITE_DRAW_FAILURE = 'GET_SITE_DRAW_FAILURE';

export const DEL_SITE_DRAW_LOADING = "DEL_SITE_DRAW_LOADING";
export const DEL_SITE_DRAW_SUCCESS = 'DEL_SITE_DRAW_SUCCESS';
export const DEL_SITE_DRAW_FAILURE = 'DEL_SITE_DRAW_FAILURE';

export const EDIT_SITE_DRAW_LOADING = "EDIT_SITE_DRAW_LOADING";
export const EDIT_SITE_DRAW_SUCCESS = 'EDIT_SITE_DRAW_SUCCESS';
export const EDIT_SITE_DRAW_FAILURE = 'EDIT_SITE_DRAW_FAILURE';

export const CLEAN_SITE_DRAW_DATA = 'CLEAN_SITE_DRAW_DATA';

export const ADD_DRAWING_FLOOR_TYPE_LOADING = "ADD_DRAWING_FLOOR_TYPE_LOADING";
export const ADD_DRAWING_FLOOR_TYPE_SUCCESS = 'ADD_DRAWING_FLOOR_TYPE_SUCCESS';
export const ADD_DRAWING_FLOOR_TYPE_FAILURE = 'ADD_DRAWING_FLOOR_TYPE_FAILURE';

export const GET_DRAWING_FLOOR_TYPE_LOADING = "GET_DRAWING_FLOOR_TYPE_LOADING";
export const GET_DRAWING_FLOOR_TYPE_SUCCESS = 'GET_DRAWING_FLOOR_TYPE_SUCCESS';
export const GET_DRAWING_FLOOR_TYPE_FAILURE = 'GET_DRAWING_FLOOR_TYPE_FAILURE';

export const EDIT_DRAWING_FLOOR_TYPE_LOADING = "EDIT_DRAWING_FLOOR_TYPE_LOADING";
export const EDIT_DRAWING_FLOOR_TYPE_SUCCESS = 'EDIT_DRAWING_FLOOR_TYPE_SUCCESS';
export const EDIT_DRAWING_FLOOR_TYPE_FAILURE = 'EDIT_DRAWING_FLOOR_TYPE_FAILURE';

export const DEL_DRAWING_FLOOR_TYPE_LOADING = "DEL_DRAWING_FLOOR_TYPE_LOADING";
export const DEL_DRAWING_FLOOR_TYPE_SUCCESS = 'DEL_DRAWING_FLOOR_TYPE_SUCCESS';
export const DEL_DRAWING_FLOOR_TYPE_FAILURE = 'DEL_DRAWING_FLOOR_TYPE_FAILURE';

export const CLEAN_DRAWING_FLOOR_TYPE_DATA = 'CLEAN_DRAWING_FLOOR_TYPE_DATA';


export const ACCEPT_MAT_INDENT_LOADING = "ACCEPT_MAT_INDENT_LOADING";
export const ACCEPT_MAT_INDENT_SUCCESS = 'ACCEPT_MAT_INDENT_SUCCESS';
export const ACCEPT_MAT_INDENT_FAILURE = 'ACCEPT_MAT_INDENT_FAILURE';


export const GET_MATERIAL_INDENT_LOADING = "GET_MATERIAL_INDENT_LOADING";
export const GET_MATERIAL_INDENT_SUCCESS = 'GET_MATERIAL_INDENT_SUCCESS';
export const GET_MATERIAL_INDENT_FAILURE = 'GET_MATERIAL_INDENT_FAILURE';


export const DEL_MATERIAL_INDENT_LOADING = "DEL_MATERIAL_INDENT_LOADING";
export const DEL_MATERIAL_INDENT_SUCCESS = 'DEL_MATERIAL_INDENT_SUCCESS';
export const DEL_MATERIAL_INDENT_FAILURE = 'DEL_MATERIAL_INDENT_FAILURE';

export const ORDER_MATERIAL_INDENT_LOADING = "ORDER_MATERIAL_INDENT_LOADING";
export const ORDER_MATERIAL_INDENT_SUCCESS = 'ORDER_MATERIAL_INDENT_SUCCESS';
export const ORDER_MATERIAL_INDENT_FAILURE = 'ORDER_MATERIAL_INDENT_FAILURE';

export const CLEAN_MATERIAL_INDENT_DATA = 'CLEAN_MATERIAL_INDENT_DATA';


export const ACCEPT_MAC_INDENT_LOADING = "ACCEPT_MAC_INDENT_LOADING";
export const ACCEPT_MAC_INDENT_SUCCESS = 'ACCEPT_MAC_INDENT_SUCCESS';
export const ACCEPT_MAC_INDENT_FAILURE = 'ACCEPT_MAC_INDENT_FAILURE';

export const GET_MACHINE_INDENT_LOADING = "GET_MACHINE_INDENT_LOADING";
export const GET_MACHINE_INDENT_SUCCESS = 'GET_MACHINE_INDENT_SUCCESS';
export const GET_MACHINE_INDENT_FAILURE = 'GET_MACHINE_INDENT_FAILURE';


export const DEL_MACHINE_INDENT_LOADING = "DEL_MACHINE_INDENT_LOADING";
export const DEL_MACHINE_INDENT_SUCCESS = 'DEL_MACHINE_INDENT_SUCCESS';
export const DEL_MACHINE_INDENT_FAILURE = 'DEL_MACHINE_INDENT_FAILURE';

export const ORDER_MACHINE_INDENT_LOADING = "ORDER_MACHINE_INDENT_LOADING";
export const ORDER_MACHINE_INDENT_SUCCESS = 'ORDER_MACHINE_INDENT_SUCCESS';
export const ORDER_MACHINE_INDENT_FAILURE = 'ORDER_MACHINE_INDENT_FAILURE';

export const CLEAN_MACHINE_INDENT_DATA = 'CLEAN_MACHINE_INDENT_DATA';


export const GET_MACHINE_DELIVERY_LOADING = "GET_MACHINE_DELIVERY_LOADING";
export const GET_MACHINE_DELIVERY_SUCCESS = 'GET_MACHINE_DELIVERY_SUCCESS';
export const GET_MACHINE_DELIVERY_FAILURE = 'GET_MACHINE_DELIVERY_FAILURE';


export const DEL_MACHINE_DELIVERY_LOADING = "DEL_MACHINE_DELIVERY_LOADING";
export const DEL_MACHINE_DELIVERY_SUCCESS = 'DEL_MACHINE_DELIVERY_SUCCESS';
export const DEL_MACHINE_DELIVERY_FAILURE = 'DEL_MACHINE_DELIVERY_FAILURE';

export const CLEAN_MACHINE_DELIVERY_DATA = 'CLEAN_MACHINE_DELIVERY_DATA';


export const GET_MATERIAL_SUPPLY_LOADING = "GET_MATERIAL_SUPPLY_LOADING";
export const GET_MATERIAL_SUPPLY_SUCCESS = 'GET_MATERIAL_SUPPLY_SUCCESS';
export const GET_MATERIAL_SUPPLY_FAILURE = 'GET_MATERIAL_SUPPLY_FAILURE';

export const DEL_MATERIAL_SUPPLY_LOADING = "DEL_MATERIAL_SUPPLY_LOADING";
export const DEL_MATERIAL_SUPPLY_SUCCESS = 'DEL_MATERIAL_SUPPLY_SUCCESS';
export const DEL_MATERIAL_SUPPLY_FAILURE = 'DEL_MATERIAL_SUPPLY_FAILURE';

export const CLEAN_MATERIAL_SUPPLY_DATA = 'CLEAN_MATERIAL_SUPPLY_DATA';

export const GET_MATERIAL_DELIVERY_LOADING = "GET_MATERIAL_DELIVERY_LOADING";
export const GET_MATERIAL_DELIVERY_SUCCESS = 'GET_MATERIAL_DELIVERY_SUCCESS';
export const GET_MATERIAL_DELIVERY_FAILURE = 'GET_MATERIAL_DELIVERY_FAILURE';

export const DEL_MATERIAL_DELIVERY_LOADING = "DEL_MATERIAL_DELIVERY_LOADING";
export const DEL_MATERIAL_DELIVERY_SUCCESS = 'DEL_MATERIAL_DELIVERY_SUCCESS';
export const DEL_MATERIAL_DELIVERY_FAILURE = 'DEL_MATERIAL_DELIVERY_FAILURE';

export const CLEAN_MATERIAL_DELIVERY_DATA = 'CLEAN_MATERIAL_DELIVERY_DATA';


export const GET_QUALITY_SAFETY_LOADING = "GET_QUALITY_SAFETY_LOADING";
export const GET_QUALITY_SAFETY_SUCCESS = 'GET_QUALITY_SAFETY_SUCCESS';
export const GET_QUALITY_SAFETY_FAILURE = 'GET_QUALITY_SAFETY_FAILURE';

export const DEL_QUALITY_SAFETY_LOADING = "DEL_QUALITY_SAFETY_LOADING";
export const DEL_QUALITY_SAFETY_SUCCESS = 'DEL_QUALITY_SAFETY_SUCCESS';
export const DEL_QUALITY_SAFETY_FAILURE = 'DEL_QUALITY_SAFETY_FAILURE';

export const CLEAN_QUALITY_SAFETY_DATA = 'CLEAN_QUALITY_SAFETY_DATA';


export const GET_SITE_PROGRESS_LOADING = "GET_SITE_PROGRESS_LOADING";
export const GET_SITE_PROGRESS_SUCCESS = 'GET_SITE_PROGRESS_SUCCESS';
export const GET_SITE_PROGRESS_FAILURE = 'GET_SITE_PROGRESS_FAILURE';

export const DEL_SITE_PROGRESS_LOADING = "DEL_SITE_PROGRESS_LOADING";
export const DEL_SITE_PROGRESS_SUCCESS = 'DEL_SITE_PROGRESS_SUCCESS';
export const DEL_SITE_PROGRESS_FAILURE = 'DEL_SITE_PROGRESS_FAILURE';

export const CLEAN_SITE_PROGRESS_DATA = 'CLEAN_SITE_PROGRESS_DATA';


export const GET_CASH_EXPENSE_LOADING = "GET_CASH_EXPENSE_LOADING";
export const GET_CASH_EXPENSE_SUCCESS = 'GET_CASH_EXPENSE_SUCCESS';
export const GET_CASH_EXPENSE_FAILURE = 'GET_CASH_EXPENSE_FAILURE';

export const DEL_CASH_EXPENSE_LOADING = "DEL_CASH_EXPENSE_LOADING";
export const DEL_CASH_EXPENSE_SUCCESS = 'DEL_CASH_EXPENSE_SUCCESS';
export const DEL_CASH_EXPENSE_FAILURE = 'DEL_CASH_EXPENSE_FAILURE';

export const CLEAN_CASH_EXPENSE_DATA = 'CLEAN_CASH_EXPENSE_DATA';


export const GET_SITE_MAN_LOADING = "GET_SITE_MAN_LOADING";
export const GET_SITE_MAN_SUCCESS = 'GET_SITE_MAN_SUCCESS';
export const GET_SITE_MAN_FAILURE = 'GET_SITE_MAN_FAILURE';

export const DEL_SITE_MAN_LOADING = "DEL_SITE_MAN_LOADING";
export const DEL_SITE_MAN_SUCCESS = 'DEL_SITE_MAN_SUCCESS';
export const DEL_SITE_MAN_FAILURE = 'DEL_SITE_MAN_FAILURE';

export const CLEAN_SITE_MAN_DATA = 'CLEAN_SITE_MAN_DATA';



export const ADD_FAQ_LOADING = "ADD_FAQ_LOADING";
export const ADD_FAQ_SUCCESS = 'ADD_FAQ_SUCCESS';
export const ADD_FAQ_FAILURE = 'ADD_FAQ_FAILURE';

export const GET_FAQ_LOADING = "GET_FAQ_LOADING";
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAILURE = 'GET_FAQ_FAILURE';

export const DEL_FAQ_LOADING = "DEL_FAQ_LOADING";
export const DEL_FAQ_SUCCESS = 'DEL_FAQ_SUCCESS';
export const DEL_FAQ_FAILURE = 'DEL_FAQ_FAILURE';

export const EDIT_FAQ_LOADING = "EDIT_FAQ_LOADING";
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS';
export const EDIT_FAQ_FAILURE = 'EDIT_FAQ_FAILURE';

export const CLEAN_FAQ_DATA = 'CLEAN_FAQ_DATA';


export const GET_SUB_FAQ_LOADING = "GET_SUB_FAQ_LOADING";
export const GET_SUB_FAQ_SUCCESS = 'GET_SUB_FAQ_SUCCESS';
export const GET_SUB_FAQ_FAILURE = 'GET_SUB_FAQ_FAILURE';



export const ADD_PURCHASE_ORDER_LOADING = "ADD_PURCHASE_ORDER_LOADING";
export const ADD_PURCHASE_ORDER_SUCCESS = 'ADD_PURCHASE_ORDER_SUCCESS';
export const ADD_PURCHASE_ORDER_FAILURE = 'ADD_PURCHASE_ORDER_FAILURE';

export const GET_PURCHASE_ORDER_LOADING = "GET_PURCHASE_ORDER_LOADING";
export const GET_PURCHASE_ORDER_SUCCESS = 'GET_PURCHASE_ORDER_SUCCESS';
export const GET_PURCHASE_ORDER_FAILURE = 'GET_PURCHASE_ORDER_FAILURE';


export const DEL_PURCHASE_ORDER_LOADING = "DEL_PURCHASE_ORDER_LOADING";
export const DEL_PURCHASE_ORDER_SUCCESS = 'DEL_PURCHASE_ORDER_SUCCESS';
export const DEL_PURCHASE_ORDER_FAILURE = 'DEL_PURCHASE_ORDER_FAILURE';

export const CLEAN_PURCHASE_ORDER_DATA = 'CLEAN_PURCHASE_ORDER_DATA';




// Bhanu

// CHP_AUM = Change passowrd in App User Management

export const CHP_AUM_LOADING = "CHP_AUM_LOADING";
export const CHP_AUM_SUCCESS = 'CHP_AUM_SUCCESS';
export const CHP_AUM_FAILURE = 'CHP_AUM_FAILURE';

export const CLEAN_CHP_AUM_DATA = 'CLEAN_CHP_AUM_DATA';





// CLIENT ACTIONS

// New Material


export const CLIENT_NEW_MATERIAL_LOADING = "CLIENT_NEW_MATERIAL_LOADING";
export const CLIENT_NEW_MATERIAL_SUCCESS = 'CLIENT_NEW_MATERIAL_SUCCESS';
export const CLIENT_NEW_MATERIAL_FAILURE = 'CLIENT_NEW_MATERIAL_FAILURE';


export const CLIENT_MACHINE_REPORT_LOADING = "CLIENT_MACHINE_REPORT_LOADING";
export const CLIENT_MACHINE_REPORT_SUCCESS = 'CLIENT_MACHINE_REPORT_SUCCESS';
export const CLIENT_MACHINE_REPORT_FAILURE = 'CLIENT_MACHINE_REPORT_FAILURE';


export const CLIENT_MANPOWER_REPORT_LOADING = "CLIENT_MANPOWER_REPORT_LOADING";
export const CLIENT_MANPOWER_REPORT_SUCCESS = 'CLIENT_MANPOWER_REPORT_SUCCESS';
export const CLIENT_MANPOWER_REPORT_FAILURE = 'CLIENT_MANPOWER_REPORT_FAILURE';

export const CLIENT_SITE_EXPENSE_LOADING = "CLIENT_SITE_EXPENSE_LOADING";
export const CLIENT_SITE_EXPENSE_SUCCESS = 'CLIENT_SITE_EXPENSE_SUCCESS';
export const CLIENT_SITE_EXPENSE_FAILURE = 'CLIENT_SITE_EXPENSE_FAILURE';


export const CLIENT_SITE_PROGRESS_LOADING = "CLIENT_SITE_PROGRESS_LOADING";
export const CLIENT_SITE_PROGRESS_SUCCESS = 'CLIENT_SITE_PROGRESS_SUCCESS';
export const CLIENT_SITE_PROGRESS_FAILURE = 'CLIENT_SITE_PROGRESS_FAILURE';

export const CLIENT_SITE_DRAWINGS_LOADING = "CLIENT_SITE_DRAWINGS_LOADING";
export const CLIENT_SITE_DRAWINGS_SUCCESS = 'CLIENT_SITE_DRAWINGS_SUCCESS';
export const CLIENT_SITE_DRAWINGS_FAILURE = 'CLIENT_SITE_DRAWINGS_FAILURE';

export const CLEAN_SITE_DRAWINGS_DATA = 'CLEAN_SITE_DRAWINGS_DATA';

export const CLIENT_FAQ_LOADING = "CLIENT_FAQ_LOADING";
export const CLIENT_FAQ_SUCCESS = 'CLIENT_FAQ_SUCCESS';
export const CLIENT_FAQ_FAILURE = 'CLIENT_FAQ_FAILURE';





export const CLIENT_ADD_TICKET_LOADING = "CLIENT_ADD_TICKET_LOADING";
export const CLIENT_ADD_TICKET_SUCCESS = 'CLIENT_ADD_TICKET_SUCCESS';
export const CLIENT_ADD_TICKET_FAILURE = 'CLIENT_ADD_TICKET_FAILURE';

export const CLIENT_GET_TICKET_LOADING = "CLIENT_GET_TICKET_LOADING";
export const CLIENT_GET_TICKET_SUCCESS = 'CLIENT_GET_TICKET_SUCCESS';
export const CLIENT_GET_TICKET_FAILURE = 'CLIENT_GET_TICKET_FAILURE';

export const CLEAN_TICKET_DATA = 'CLEAN_TICKET_DATA';

export const CLIENT_PROFILE_LOADING = "CLIENT_PROFILE_LOADING";
export const CLIENT_PROFILE_SUCCESS = 'CLIENT_PROFILE_SUCCESS';
export const CLIENT_PROFILE_FAILURE = 'CLIENT_PROFILE_FAILURE';

export const CLIENT_GET_PROFILE_LOADING = "CLIENT_GET_PROFILE_LOADING";
export const CLIENT_GET_PROFILE_SUCCESS = 'CLIENT_GET_PROFILE_SUCCESS';
export const CLIENT_GET_PROFILE_FAILURE = 'CLIENT_GET_PROFILE_FAILURE';

export const CLEAN_PROFILE_DATA = 'CLEAN_PROFILE_DATA';

export const CLIENT_PROFILE_PIC_LOADING = "CLIENT_PROFILE_PIC_LOADING";
export const CLIENT_PROFILE_PIC_SUCCESS = 'CLIENT_PROFILE_PIC_SUCCESS';
export const CLIENT_PROFILE_PIC_FAILURE = 'CLIENT_PROFILE_PIC_FAILURE';

export const CLEAN_PROFILE_PIC_DATA = 'CLEAN_PROFILE_PIC_DATA';


export const  DAILYREPORT_LOADING = " DAILYREPORT_LOADING";
export const DAILYREPORT_SUCCESS = 'DAILYREPORT_SUCCESS';
export const DAILYREPORT_FAILURE = 'DAILYREPORT_FAILURE';

export const  MONTHLYREPORT_LOADING = " MONTHLYREPORT_LOADING";
export const MONTHLYREPORT_SUCCESS = 'MONTHLYREPORT_SUCCESS';
export const MONTHLYREPORT_FAILURE = 'MONTHLYREPORT_FAILURE';


export const GETBOQ_LOADING = " GETBOQ_LOADING";
export const GETBOQ_SUCCESS = 'GETBOQ_SUCCESS';
export const GETBOQ_FAILURE = 'GETBOQ_FAILURE';
