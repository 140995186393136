import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import tableIcons from '../../../Config/IconsFile'

import {orderMaterialIndent} from '../../../Actions/SubAdminActions/Material_Indent'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const navigate = useNavigate();
  const listData = (state.mat_details && Array.isArray(state.mat_details) && state.mat_details.length ? state.mat_details : []);


  const id = useSelector(state => state.login.data.r_subadmin);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  const [data, setData] = useState(listData);
  const [open, setOpen] = useState({show:false, indent_id:"", mat_name:""});
  const [values, setValues] = useState({
  
    admin_id :id,
    indent_id: state.indent_id,
    mat_name:[],
  
    fixImg : "/static/images/avatars/upload.png"
  

  });


  const handleClose = () =>{

    setOpen({...open, show:false, indent_id:"", mat_name:""});
  }

const handleOpen = (dataVal) =>{

  setOpen({
    ...open,
    show:true,
    mat_name:data,
    indent_id:dataVal.indent_id
  });

}


const handleConfirm = () =>{
  if(open.indent_id!=="" && open.mat_name!==""){

    dispatch(orderMaterialIndent(open))
    navigate("/app/materialindentlist")

  }

}


  const [columns, setColumns] = useState([
    { title: 'Material Type', field: 'mat_type', editable:'never'},
    { title: 'Material Name', field: 'mat_name', editable:'never'},
    { title: 'Quantity', render:rowData=>(rowData.quan + " " + rowData.unit) },
    { title: 'Amount', field: 'amt', editable: 'never' , 
    render : (rowData) =>(
        <>
        {rowData.amt === undefined || rowData.amt === null || rowData.amt === "" ?  "NA" : rowData.amt}
        </>
    )
},
  ]);

  return (
    <Page className={classes.root} title="Material Details" >
      <ToastContainer    />

      

      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Place This Order??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader="" title="Material Detail" />
                <Divider />
                <CardContent>
                <Grid container spacing={3}>
                  
                  <Grid item md={6} xs={12}>
                    <Typography>Order ID :  {state.order_id}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Site Name :  {state.site_name}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Required Date :  {moment(state.required_on).format("DD.MM.YYYY")}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Priority :  {state.priority}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Status :  {state.status}</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    
                  {state.lat && state.lat!==null && state.lat!=="" && state.long && state.long!==null && state.long!==""? 
                  
                    <Typography>Location :  
                      {state.lat}, {state.long}                 
                    </Typography>
                  
                   : 
                   <Typography>Location: </Typography>
                   }
                    
                  </Grid>
                  {state.status!=="Pending"?
                  
                  <Grid item md={6} xs={12}>
                    <Typography>Accepted By :  {state.accepted_by}</Typography>
                  </Grid>
                  :<></>
                  }
                </Grid>

                </CardContent>
                <Divider />
                

              </Card>
            </form>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Card className={clsx(classes.root2, className)} {...rest}>
          <MaterialTable
            icons={tableIcons}      
            title="Material Details"
            columns={columns}

            data={data}
            options={{
                    selection: false,
                    search:false,
                    paging:false,
                    emptyRowsWhenPaging:false
                }}
            
          />
          
          </Card>
          </Box>
      </Container>
    </Page>
  );
};

export default AddBusiness;
