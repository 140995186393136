import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'


import {getGst , delGst} from '../../../Actions/SuperAdminActions/Gst'
import {cleanGst} from '../../../Actions/ActionCreators'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


import DialogContentText from '@material-ui/core/DialogContentText';
import {getExportFileBlob} from '../../../Config/excelPdfDownload'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const role_permit = useSelector(state => state.login.data.role_permit);




  const listData = useSelector((state) =>
  state.Gst 
  && state.Gst.getData 
  && state.Gst.getData.length &&

    Array.isArray(state.Gst.getData)

      ? state.Gst.getData
      : []
  );





  
 
  const Delmsg = useSelector(state => state.Gst && state.Gst.delData && state.Gst.delData.status ? state.Gst.delData.status : null  )
  const Delerror = useSelector(state => state.Gst && state.Gst.delError && state.Gst.delError.message ? state.Gst.delError.message : null  )
  const Editmsg = useSelector((state) => state.Gst && state.Gst.editData && state.Gst.editData.message ? state.Gst.editData.message : null  )





  const [open1, setOpen1] = useState({show:false, id:""});

  const handleClose1 = () =>{

    setOpen1({...open1, show:false, id:""});
  }
  
  const handleOpen1 = (data) =>{
  
    setOpen1({
      ...open1,
      show:true,
      id:data.gst_id
    });
  
  }

  const handleConfirm1 = () =>{
    if(open1.id!==""){
      
      dispatch(delGst(open1.id))

  
      setOpen1({
        ...open1,
        show:false,
        id:""
      })
    }
  }



  
useEffect( () => {
  dispatch(getGst())
  let x  = new Promise((resolve ,reject)=>{
    if(Delmsg && typeof(Delmsg) === 'string')
    {
      tostS(Delmsg)
    }
    if(Delerror && typeof(Delerror) === 'string')
    {
      tostS(Delerror)
    }
    
    if(Editmsg && typeof(Editmsg) === 'string')
    {
      tostS(Editmsg)
    }
    
    resolve()

  })
  x.then(()=>{

  dispatch(cleanGst())
  dispatch(getGst())

  })


   


    
  }, [Delmsg, Delerror, Editmsg])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/gst_edit/'+ olData.gst_id , { state: olData })
}


 




const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const exportField = [
    {
      field : "slab_name" , 
      val : "Name"
    }, 
    {
      field : "percent" , 
      val : "Percent"
    }, 
    
  ]




  const addNewBornbutton = () =>{
    navigate('/app/gst_add')
    console.log("ADD GST")
    
  }

  return (
    <>
      <Page className={classes.root} title="GST Modules List">
      <ToastContainer    />

      <Dialog disableBackdropClick={true} open={open1.show} onClose={handleClose1} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
            <DialogContent>
              <Typography variant="h6">
                Are you sure you want to Delete this Gst??
              </Typography>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose1} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleConfirm1} color="primary" variant="outlined">
                Confirm
              </Button>
            </DialogActions>
        </Dialog>

        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'GST')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'GST')
              }>
                Export to PDF
            </Button>
          {(role_permit.admin_panel_user && role_permit.admin_panel_user.length && Array.isArray(role_permit.admin_panel_user)) && role_permit.admin_panel_user[3]===true? 
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
               Add
              </Button>:<></>}
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="GST Modules List"
      columns={[
       
        { title: 'GST Module', field: 'slab_name',editable: "never", },
        { title: 'Percent', field: 'percent',editable: "never", },
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
            {(role_permit.admin_panel_user && role_permit.admin_panel_user.length && Array.isArray(role_permit.admin_panel_user)) && role_permit.admin_panel_user[4]===true? 
              <Grid item xs={4}>
                  
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              </Grid>:<></>}
              {(role_permit.admin_panel_user && role_permit.admin_panel_user.length && Array.isArray(role_permit.admin_panel_user)) && role_permit.admin_panel_user[2]===true? 
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen1(rowData)}} style={{cursor:"pointer"}} />
              </Grid>:<></>}
              
          </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
