// import axios from 'axios'
import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addAppUserMgmtBegin , addAppUserMgmtSuccess , addAppUserMgmtFailure} from './../ActionCreators'
import {getAppUserMgmtBegin , getAppUserMgmtSuccess , getAppUserMgmtFailure} from './../ActionCreators'
import {editAppUserMgmtBegin , editAppUserMgmtSuccess , editAppUserMgmtFailure} from './../ActionCreators'
import {delAppUserMgmtBegin , delAppUserMgmtSuccess , delAppUserMgmtFailure} from './../ActionCreators'
import {chpAumBegin , chpAumSuccess ,chpAumFailure } from './../ActionCreators'


export const addAppUser = (session) => {

    return (dispatch) => {
        dispatch(addAppUserMgmtBegin());

        axios.post(Base_URL + '/subadmin/addAppUsers', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addAppUserMgmtSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addAppUserMgmtFailure(err.response.data));

                }
            })
    }
}

export const getAppUser = (session) => {

    return (dispatch) => {
        dispatch(getAppUserMgmtBegin());

        axios.get(Base_URL + '/subAdmin/getAppUsers/' , {params : {sub_admin_id : session}})
            .then((res) => {
                dispatch(getAppUserMgmtSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getAppUserMgmtFailure(err.response.data));

                }
            })
    }
}


export const editAppUser = (session) => {

    return (dispatch) => {
        dispatch(editAppUserMgmtBegin());

        axios.post(Base_URL + '/subAdmin/editAppUsers', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editAppUserMgmtSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editAppUserMgmtFailure(err.response.data)); 

                }
            })
    }
}

export const delAppUser = (x) => {

    return (dispatch) => {
        dispatch(delAppUserMgmtBegin());

        // console.log(x);
        axios.get(Base_URL + '/subAdmin/delAppUser' , {params :{apu_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delAppUserMgmtSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delAppUserMgmtFailure(err.response.data));

                }
            })
    }
}



export const changeAppUserPassword = (x) => {

    return (dispatch) => {
        dispatch(chpAumBegin());

        // console.log(x);
        axios.post(Base_URL + '/subAdmin/editAppPassword' , x)
            .then((res) => {
                console.log(res.data)
                dispatch(chpAumSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(chpAumFailure(err.response.data));

                }
            })
    }
}


export const changeClientAppUserPassword = (x) => {

    return (dispatch) => {
        dispatch(chpAumBegin());

        // console.log(x);
        axios.post(Base_URL + '/client/editClientAppUsersPassword' , x)
            .then((res) => {
                console.log(res.data)
                dispatch(chpAumSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(chpAumFailure(err.response.data));

                }
            })
    }
}

