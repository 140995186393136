import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 12
    },
    remarkContainer:{
        flexDirection: 'column',
        marginTop: 12
    },
    signContainer:{
        flexDirection: 'column',
        marginTop: 12,
        width:250
    },
    reportTitle:{
        fontSize: 18,
        color:"#E36A26",
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    remarkTitle:{
        fontSize: 12,        
        textAlign: 'left',        
        borderBottom:"1px #000000"
    },
    remarkSubTitle:{
        fontSize: 10,        
        textAlign: 'left',        
        borderBottom:"1px #000000"
    },
    signTitle:{
        fontSize: 12,        
        textAlign: 'center', 
        color:"#ffffff",       
        backgroundColor:"#E36A26"
    },
    remarkBox:{        
        backgroundColor:"#fff0f0",                
        height:50,      
        width:250,      
        borderColor:"#000000",
        borderWidth:1
    },
    signBox:{        
        backgroundColor:"#fff0f0",                
        height:50,      
        width:250,      
        marginTop:"5%",
        borderColor:"#E36A26",
        borderWidth:1
    }
  });


  const InvoiceThankYouMsg = () => (
      <>
    <View style={styles.remarkContainer}>
        <Text style={styles.remarkTitle}>Remark/Instructions</Text>
        <View style={styles.remarkBox}></View>
        <Text style={styles.remarkSubTitle}>Please make check payable to Your Company Name.</Text>
    </View>
    <View style={styles.titleContainer}>

        <Text style={styles.reportTitle}>Thank you</Text>
    </View>
    <View style={styles.signContainer}>

        <Text style={styles.signTitle}>AUTHORIZED SIGNATURE</Text>
        <View style={styles.signBox}></View>
    </View>
    </>
  );
  
  export default InvoiceThankYouMsg