import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {addManpowerCategory} from '../../../../../Actions/SubAdminActions/M_ManpowerCategory'
import { cleanManpowerCategoryData } from '../../../../../Actions/ActionCreators';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()


  // const id = useSelector(state => state.login.data.id);
  // const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   category_name : "Gourav",

  });


  const handleChange = (event) => {
    console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 



  const handleSubmit = () =>{
      console.log(values)
    dispatch(addManpowerCategory(values))
    setValues({
      ...values,
      category_name : "",
     
    });

  }



  const addmsg = useSelector((state) => state.AddManpowerCategory.data && state.AddManpowerCategory.data.status && state.AddManpowerCategory.data.status === 200 ? state.AddManpowerCategory.data.status : null  )
  const adderr = useSelector((state) => state.AddManpowerCategory.error && state.AddManpowerCategory.error.mgs ? state.AddManpowerCategory.error.mgs : null  )
  
    useEffect( () => {
      var r  = new Promise((resolve , reject)=>{
       if(addmsg === 200){
        tostS("Site Added Successfully")
       }
  
      else
      if(adderr !== null){
        tostE(adderr)
       }
    
       resolve()
      
      }) 
      r.then(()=>{
       dispatch(cleanManpowerCategoryData())
      })
      
    }, [addmsg, ,adderr])
  


  
  // const BlistData = useSelector((state) =>
  // state.BusinessMaster &&  state.BusinessMaster.getdata &&
  //   state.BusinessMaster.getdata.length &&
  //   Array.isArray(state.BusinessMaster.getdata)
  //     ? state.BusinessMaster.getdata
  //     : []
  // );


  

  // useEffect( () => {
    // dispatch(getBusinessCat())

    // dispatch(getCountryData())
     
    // }, [])



  return (
    <Page
      className={classes.root}
      title="Add Category"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
        
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Addt Category" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Category Name"
                name="category_name"
                onChange={handleChange}
                required
                value={values.category_name}
                variant="outlined"
              />
            </Grid>
            
            

           
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
