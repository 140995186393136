import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {ClientMatDelStartDate, ClientMatDelEndDate} from '../../../Actions/ActionCreators'
import {newMatDelivery} from '../../../Actions/Client/NewMaterial'

import {getExportFileBlob} from '../../../Config/excelPdfDownload'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const useStyles3 = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  rootq: {
    width: '100%',
  },
  headingq: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeadingq: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

}));


const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const tableRef = React.createRef();


  const start_date = useSelector(state => state.ClientDateFilters && state.ClientDateFilters.MatDelStartDate ? state.ClientDateFilters.MatDelStartDate:new Date(Date.now()))
  const end_date = useSelector(state => state.ClientDateFilters && state.ClientDateFilters.MatDelEndDate ? state.ClientDateFilters.MatDelEndDate:new Date(Date.now()))
    
  const [values, setValues] = useState({
    start_date:""
  })



  const  filterData = (Wdata,s_date, e_date) => {
    
    var Fdata = []
    if(Array.isArray(Wdata) && Wdata.length){

      Fdata = Wdata.filter(data => (moment(data.delivery_date).format("YYYY.MM.DD")>=moment(s_date).format("YYYY.MM.DD")) && (moment(data.delivery_date).format("YYYY.MM.DD")<=moment(e_date).format("YYYY.MM.DD")));
      return Fdata
    }else{

      return Fdata
    }
    
  
  } 
  

 const listData = useSelector((state) =>
  state.ClientAllGet && state.ClientAllGet.newMatData &&
  state.ClientAllGet.newMatData.data &&
  Array.isArray(state.ClientAllGet.newMatData.data) &&
  state.ClientAllGet.newMatData.data.length 
      ? state.ClientAllGet.newMatData.data
      : []
  );


  const countD  =  useSelector((state) => state.ClientAllGet && 
state.ClientAllGet.newMatData.data &&
Array.isArray(state.ClientAllGet.newMatData.data) ? 
parseInt(state.ClientAllGet.newMatData.total_length) : 0 )


const countF  =  useSelector((state) => state.ClientAllGet && 
state.ClientAllGet.newMatData.data &&
Array.isArray(state.ClientAllGet.newMatData.data) ? 
parseInt(state.ClientAllGet.newMatData.page) : 0 )



  

const sub_admin_id =  useSelector((state) => state.login.data.admin_id)
const site_id =  useSelector((state) => state.login.data.site_id)




 
 
  const handleStartDateChange = (date) => {
    var t = {
      sub_admin_id : sub_admin_id,
                        page : pagination.page, 
                        per_page :pagination.per_page,  
                         start_date : date, 
                         end_date : moment( end_date ).add(1,'d')._d, 
                         site_id:site_id
                                                               
                       }
             dispatch(newMatDelivery(t))
    // filterData(listData, date, end_date)
   
    //  const DataList = listData.filter(data => (!selected_site || data.site_id==selected_site) && (moment(data.delivery_date).format("DD.MM.YYYY")>=moment(date).format("DD.MM.YYYY")) && (moment(data.delivery_date).format("DD.MM.YYYY")<=moment(values.end_date).format("DD.MM.YYYY")));
   dispatch(ClientMatDelStartDate(date))
   setValues({
     ...values,
     ['start_date']:date
   })
   };
    
   const handleEndDateChange = (date) => {
    var t = {
      sub_admin_id : sub_admin_id,
                        page : pagination.page, 
                        per_page :pagination.per_page,  
                         start_date : values.start_date, 
                         end_date : moment( date ).add(1,'d')._d, 
                         site_id:site_id
                                                               
                       }
             dispatch(newMatDelivery(t))
    // filterData(listData, start_date, date)
  
   dispatch(ClientMatDelEndDate(date))
     
   
   };
     
  
  
     
   const [pagination , setPagination] = useState({
    per_page : 5,
    page : countF,
  
  })
  
useEffect( () => {


    var t = {
      sub_admin_id : sub_admin_id,
                        page : pagination.page, 
                        per_page :pagination.per_page,  
                         start_date : values.start_date, 
                         end_date : moment( end_date ).add(1,'d')._d, 
                         site_id:site_id
                                                               
                       }
             dispatch(newMatDelivery(t))


    
  }, [ ])


  const navigate = useNavigate();






const getSum  = (data) =>{
  
 var t  =  data.mat_details.reduce((x  ,   y  , z)=>{
    if(x.amt ===  null  || x.amt === undefined || x.amt === "")
    {
      x = {amt : 0 } 
      if(y.amt ===  null  || y.amt === undefined || y.amt === "")
      {
        return   x = {amt : parseFloat(x.amt)  + 0} 
      }
      else
      {
        return    x = {amt : parseFloat(x.amt)  + parseFloat(y.amt)} 
      }
    }
    else
    {
      if(y.amt ===  null  || y.amt === undefined || y.amt === "")
      {
        return   x = {amt : parseFloat(x.amt)  + 0} 
      }
      else
      {
      return  x = {amt : parseFloat(x.amt)  + parseFloat(y.amt)} 
      }
    }
  
   
  })
  
  return parseFloat(t.amt).toFixed(2)
}


const handleSingleView = (oldata) =>{
  navigate('/app/materialdeliveryreport/' + oldata.tableData.id , {state:oldata})

}
  



const exportField = [
  
  {
    field : "index" , 
    val : "SR. No."
  },
  {
    field : "order_id" , 
    val : "Order ID"
  },  
  {
    field : "mat_cat" , 
    val : "Material Category"
  },  
  {
    field : "mat_name" , 
    val : "mat_name"
  },    
  {
    field : "delivery_date" , 
    val : "Delivery Date"
  }, 
  {
    field : "amount" , 
    val : "Amount"
  }, 
  
]
const loading  = useSelector((state)=> state.ClientAllGet && state.ClientAllGet.loading)




const getExportData = () => {
  // const FilterData = listData.filter(data => (moment(data.delivery_date).format("DD.MM.YYYY")>=moment(start_date).format("DD.MM.YYYY")) && (moment(data.delivery_date).format("DD.MM.YYYY")<=moment(end_date).format("DD.MM.YYYY")));
  var exportData = []

  if(Array.isArray(listData) && listData.length){
    listData.map((element,i) => {
      exportData.push(
        {
          "index":i+1,
          "order_id":element.order_id,
          "mat_cat":element.mat_cat,
          "mat_name":element.mat_name,
          "delivery_date":moment(element.delivery_date).format("DD/MM/YYYY"),
          "amountb":element.get_sum,
        }
      )
    })
  }

  return exportData;
}
  

const handleExportDataExcel = () => {

  getExportFileBlob(exportField,getExportData(), 'xlsx', 'Material Delivery')
};

const handleExportDataPDF = () => {
  getExportFileBlob(exportField,getExportData(), 'pdf', 'Material Delivery')
};



const handlePageChange = (e , x) =>{
  
     var u   = new Promise((resolve ,reject)=>{

     
       
       if(x !== null  && x !== undefined )
       {
         dispatch(newMatDelivery(
          {
         sub_admin_id : sub_admin_id  ,
         page : e, 
         per_page :pagination.per_page,
         start_date : values.start_date, 
         end_date : moment( end_date ).add(1,'d')._d, 
         site_id:site_id
       
       }
       ))
       }
       
       resolve()
       
     })
     u.then(()=>{
       
       setPagination({...pagination , ['page']  : e})
     })
   
   }
  
  
   
   
   const handleRPP = (e) =>{
    
     var u   = new Promise((resolve ,reject)=>{
  
      
     if(!loading){
  
    
      
         dispatch(newMatDelivery(
           {
          sub_admin_id : sub_admin_id  ,
          page : pagination.page, 
          per_page :e ,
          start_date : values.start_date, 
          end_date : moment( end_date ).add(1,'d')._d, 
          site_id:site_id
        
        }
        ))
  
      }
         
       
       
       resolve()
       
     })
     u.then(()=>{
       
       setPagination({...pagination , ['per_page']  : e})
     })
   
   }


  


  
  return (
    <>
      <Page className={classes.root} title="Material Delivery Report">
      <ToastContainer    />
        <Container maxWidth={false}>
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              <Button 
                color="primary" 
                variant="contained"
                className={classes.exportButton} 
                onClick = {handleExportDataExcel}>
                  Export to Excel
              </Button>
              <Button 
                color="primary" 
                style={{marginLeft:"5px"}}
                variant="contained"
                className={classes.exportButton} 
                onClick = {handleExportDataPDF}>
                  Export to PDF
              </Button>
            </Box>
            <div className={classes.root3}>
      
              <Grid container spacing={3}>
                <Grid item  md={6} lg={3}  xs={3}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth                                        
                      id="date-picker-dialog"
                      label="From Date"
                      InputLabelProps={{shrink:true}}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      required={false}
                      value={values.start_date}
                      maxDate={end_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={handleStartDateChange}
                      inputVariant="outlined"
                      variant="outlined"
                      // borderColor="red"
                      invalidDateMessage=""
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
                <Grid item  md={6} lg={3}  xs={3}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        style={{ border: "1px  black" }}
                        id="date-picker-dialog"
                        label="To Date"
                        format="dd/MM/yyyy"
                        value={end_date}
                        minDate={values.start_date}
                        style={{width:'100%' , borderColor:"orange"}}
                        onChange={(date)=>{handleEndDateChange(date)}}
                        inputVariant="outlined"
                        variant="outlined"
                        borderColor="red"              
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
                
              </Grid>
            </div>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      tableRef={tableRef}

      title="Material Delivery Report"
      columns={[
    
       
        { title: "Order ID", field:"order_id"},
        { title: "Material Category",  field:"mat_cat"},
        { title: "Material Name", field:"mat_name"},
        { title: 'Delivery Date',
        render: rowData => (
          <>
          {rowData.delivery_date ===  null || rowData.delivery_date === "" || rowData.delivery_date === undefined ?
          <Typography>Not Delivered</Typography>
          :
          moment(rowData.delivery_date).format("DD/MM/YYYY")
        }
          </>
        ),
      },
        { title: 'Amount', editable: "never", field:"get_sum"},   
        {
          title: 'Action',editable: "never",
          render: rowData => (
            <>
           <VisibilityIcon onClick={() => {handleSingleView(rowData)}} style={{cursor:"pointer"}} />

            </>
            // moment(rowData.added_date).format("DD/MM/YYYY")
          ),
        },
    

      ]}

      components={{
        Pagination: props => (
                     <TablePagination
                     {...props}
                     
                count={countD}
                page={countF}
                
                />
                ),
              }}
              
              onChangeRowsPerPage={(e)=>handleRPP(e)}
              onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}           
      data={listData}
             
    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
