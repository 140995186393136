import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Container,
  Typography,
  Link
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Page from '../../../src/components/Page';

import { tostE, tostS } from '../../Config/Toast';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {resetPass} from "../../Actions/resetPassword";
import { cleanResetPassData, otpEmail } from '../../Actions/ActionCreators';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#E36A26",
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {
    backgroundColor: "theme.palette.background.dark",
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    opacity:0.8,
    elevation:10,
    borderRadius:10
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const userTypeVal = useSelector((state) => state.ResetPassword && state.ResetPassword.data && state.ResetPassword.data.obj  && state.ResetPassword.data.obj.user_type ? state.ResetPassword.data.obj.user_type :"" );
  const userEmailVal = useSelector((state) => state.ResetPassword && state.ResetPassword.data && state.ResetPassword.data.obj && state.ResetPassword.data.obj.user_email  ? state.ResetPassword.data.obj.user_email :"" );


  const [values, setValues] = useState({
    password: '',
    confirm: '',
    user_type:userTypeVal,
    user_email:userEmailVal,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const resetMsg = useSelector(state => state.ResetPassword && state.ResetPassword.resetData && state.ResetPassword.resetData.message ? state.ResetPassword.resetData.message : null)
  const resetError = useSelector(state => state.ResetPassword && state.ResetPassword.data && Object.keys(state.ResetPassword.data) && Object.keys(state.ResetPassword.data).length && state.ResetPassword.data.status===404 ? state.ResetPassword.data.status : null)


  const handleSubmit = () => {
    if (values.password !== values.confirm)
    {
      tostE("Please Enter Same Password !!");
        
    }else{
      // console.log(values)
      dispatch(resetPass(values))
      navigate("/app/login")
    }
    
  
  }

  return (
    <Page
      className={classes.root}
      title="Register"
    >
    <ToastContainer />
    
    <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
        <Card className={classes.root2 , {elevation:10 , boxShadow:10}}>
          <CardContent>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Update Password
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Create New Password.
              </Typography>
            </Box>
            
            <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Reset Password Now
              </Button>
            </Box> 
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Have an account?
              {' '}
              <Link
                component={RouterLink}
                to="/login"
                variant="h6"
              >
                Sign in
              </Link>
            </Typography>
          </CardContent>
          </Card>
        </Container>
      </Box>
      
    </Page>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
