import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Page from '../../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import tableIcons from '../../../../Config/IconsFile'

import {getMaterialCategory} from '../../../../Actions/SubAdminActions/M_MaterialCategory';
import {getMaterialMat} from '../../../../Actions/SubAdminActions/M_MaterialMat';
import {getResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit'
import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites';

import {acceptMatIndent} from '../../../../Actions/SubAdminActions/Material_Indent'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate();
  const listData = (state.mat_name && Array.isArray(state.mat_name) && state.mat_name.length ? state.mat_name : []);


  const id = useSelector(state => state.login.data.admin_id);
  const name = useSelector(state => state.login &&state.login.data && state.login.data.name ? state.login.data.name:null);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  const [data, setData] = useState(listData);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  
  const [values, setValues] = useState({
    app_uid:state.app_uid,
    admin_id :id,
    indent_id: state.indent_id,
    mat_name:state.values,
    required_on:state.required_on,
    priority:state.priority,
    status:state.status,
    accepted_by:name,
    site_id:state.site_id,
    fixImg : "/static/images/avatars/upload.png"
  

  });

  const handleClose = () =>{

    setOpen(false);
    setOpen2(false);
    
  }

const handleOpen = () =>{
  setOpen(true)
  setValues({
    ...values,
    ['mat_name']:MatList,
    ['status']:"Accepted"
  })

}

const handleOpen2 = () =>{
  setValues({
    ...values,
    ['status']:"Rejected"
  })
  setOpen2(true)
  
}


const handleReject = () =>{
  dispatch(acceptMatIndent(values))
  navigate("/app/materialindentlist")
  setOpen2(false);

}

const handleAccept = () =>{
  if(values.mat_name!=""){

    dispatch(acceptMatIndent(values))
    navigate("/app/materialindentlist")
    // console.log("accepted bu=", values)

  }
  setOpen(false);

}


const handleChangeSite = (event) => {
  setValues({...values, ['site_id']:event.target.value});
};
 
const handleDateChange = (date) => {
  setValues({
    ...values,
    ['required_on']:date
  })
};


const SiteData = useSelector((state) =>
state.AddSites && state.AddSites.getData &&
  state.AddSites.getData.length &&
  Array.isArray(state.AddSites.getData)
    ? state.AddSites.getData
    : []
);



const VendorData = useSelector((state) =>
state.AddVendor && state.AddVendor.getData &&
  state.AddVendor.getData.length &&
  Array.isArray(state.AddVendor.getData)
    ? state.AddVendor.getData
    : []
);

const UnitData = useSelector((state) =>
state.AddResourceUnit && state.AddResourceUnit.getData &&
  state.AddResourceUnit.getData.length &&
  Array.isArray(state.AddResourceUnit.getData)
    ? state.AddResourceUnit.getData
    : []
);


const GstData = useSelector((state) =>
state.SubGst && state.SubGst.getData &&
  state.SubGst.getData.length &&
  Array.isArray(state.SubGst.getData)
    ? state.SubGst.getData
    : []
);

const MatCatData = useSelector((state) =>
state.AddMaterialCategory && state.AddMaterialCategory.getData &&
  state.AddMaterialCategory.getData.length &&
  Array.isArray(state.AddMaterialCategory.getData)
    ? state.AddMaterialCategory.getData
    : []
);

const MatData = useSelector((state) =>
state.MaterialMatMaster && state.MaterialMatMaster.getData &&
  state.MaterialMatMaster.getData.length &&
  Array.isArray(state.MaterialMatMaster.getData)
    ? state.MaterialMatMaster.getData
    : []
);



const MacCatData = useSelector((state) =>
state.MachineCat && state.MachineCat.getData &&
  state.MachineCat.getData.length &&
  Array.isArray(state.MachineCat.getData)
    ? state.MachineCat.getData
    : []
);



const MacData = useSelector((state) =>
state.AddResourceMachine && state.AddResourceMachine.getData &&
  state.AddResourceMachine.getData.length &&
  Array.isArray(state.AddResourceMachine.getData)
    ? state.AddResourceMachine.getData
    : []
);


useEffect(() => {
  dispatch(getSites(id))
  dispatch(getResourceUnit(id))
  dispatch(getMaterialCategory(id))
  dispatch(getMaterialMat(id))

  

  
}, [])


// **************** Dynamic Material ********************** //
var getMatListData = state.mat_name;
for(let i=0;i<getMatListData.length;i++){
  const selected_id = getMatListData[i]['mat_type_id']
  getMatListData[i]['getData']=MatData.filter(m_type => m_type.mat_cat_id==selected_id);
}
const [MatList, setMatList] = useState(getMatListData)

  // handle input change 
  
  const handleChangeMatInput = (event, idx) =>{
    const list = [...MatList];
    const index = idx;  
    const field_name = event.target.name;  
    list[index][field_name] = event.target.value;     
    setMatList([...list])
    
  }
  

  
const handleChangeMatQuantity = (event, idx) =>{
  const list = [...MatList];
  const index = idx;  
  list[index]["quan"] = event.target.value;     
  list[index]["acc_quan"] = event.target.value;     
  list[index]["new_quan"] = event.target.value;     
  setMatList([...list])
  
  }
  const handleMatCatChange = (event, idx) =>{
    var list = [...MatList];
    const index = idx;
    const selected_id = event.target.value;
    var mat_type_insert =  MatCatData.filter(type_name => type_name.mc_id==selected_id)[0].name;
    list[index].mat_type_id = event.target.value;
    list[index].mat_type = mat_type_insert;
    list[index].getData = MatData.filter(m_type => m_type.mat_cat_id==selected_id)
    setMatList([...list])


    // dispatch(getPurchaseMatName(id, event.target.value))
  }
  
  const handleMatChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var mat_insert =  MatData.filter(type_name => type_name.mm_id==event.target.value)[0].mat_name;
    list[index].mat_id = event.target.value;
    list[index].mat_name = mat_insert;
    setMatList([...list])
    
  }
  
  const handleUnitChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var unit_insert =  UnitData.filter(type_name => type_name.sub_unit_id==event.target.value)[0].unit_name;
    list[index].unit_id = event.target.value;
    list[index].unit = unit_insert;
    setMatList([...list])
    
  }
  
  


  const handleGstChange = (event, idx) =>{
    const list = [...MatList];
    const index = idx;
    var gst_insert =  GstData.filter(type_name => type_name.gst_id==event.target.value)[0].percent;
    list[index].gst = event.target.value;
    list[index].gst_per = gst_insert;

    setMatList([...list])
    
  }
  

  // handle click event of remove


  const handleRemoveClick = (index) => {
    const list = [...MatList];
    list.splice(index, 1);
    setMatList(list);
  };

  // handle click of add button

  const handleAddClick = () => {

    setMatList([...MatList, {amt: "", amt_error: false, gst: "", gst_per: "", gst_error: false, mat_error: false, mat_id: "", mat_name: "", mat_type: "", mat_type_error: false, mat_type_id: "", quan: "", quan_error: false, unit_id:"", unit:"", getData : []}])
  }
  





  return (
    <Page className={classes.root} title="Business" >
      <ToastContainer    />

      

      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Accept??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleAccept} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

      

      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Reject??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleReject} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader={"Order Id : "+state.order_id} title="Material Indent View" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>                    
                    <Grid item md={12} xs={12}>
                      <Grid item md={6} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            margin="normal"
                            style={{ border: "1px  black" }}
                            id="date-picker-dialog"
                            label="Required Date"
                            format="dd.MM.yyyy"
                            value={values.required_on}
                            inputVariant="outlined"
                            onChange={handleDateChange}
                            variant="outlined"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Site</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.site_id}
                          onChange={handleChangeSite}
                          label="Site"
                          style={{margin:0}}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          {SiteData.map((data , i)=>
                            <MenuItem key={i}  value={data.site_id}>{data.site_name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Priority</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.priority}
                          onChange={handleChangeSite}
                          label="Priority"
                        >
                          <MenuItem value="Low">Low</MenuItem>
                          <MenuItem value="Medium">Medium</MenuItem>
                          <MenuItem value="High">High</MenuItem>
                          
                        </Select>
                      </FormControl>
                    </Grid>
                    
                  </Grid>

                </CardContent>
                <Divider />
                
                <>     
                  {MatList && MatList.length > 0 && Array.isArray(MatList) ?
                    <>
                      { MatList.map((field, idx) => {
                        return (
                          <>
                          <Card style={{border:"1px solid #db630b", marginBottom:"5px", marginTop:"5px"}}>
                            <CardContent style={{borderBottomWidth:1 , borderBottomColor:"black"}}>
                              <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Material Category</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={Object.keys(field) && Object.keys(field).length && field.mat_type_id ?field.mat_type_id:""}
                                      onChange={e => handleMatCatChange(e,idx)}
                                      label="Material Category"                                      
                                    >
                                      
                                      {MatCatData.map((data , i)=>
                                        <MenuItem key={i} value={data.mc_id}>{data.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Material Name</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.mat_id}
                                      onChange={e => handleMatChange(e,idx)}
                                      label="Material Name"
                                      style={{margin:0}}
                                    >
                                      {field.getData.map((data , i)=>
                                        <MenuItem key={i}  value={data.mm_id}>{data.mat_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Quantity"
                                    name="acc_quan"
                                    onChange={e => handleChangeMatQuantity(e, idx)}
                                    required
                                    value={field.acc_quan}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.unit_id}
                                      onChange={e => handleUnitChange(e,idx)}
                                      label="Unit"
                                    >
                                      {UnitData.map((data , i)=>
                                        <MenuItem key={i}  value={data.sub_unit_id}>{data.unit_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                
                                {MatList.length>1?
                                
                                <Grid item md={3} xs={12}>
                                  <Box display="flex" justifyContent="flex-end" p={2}>
                                    {/* <Button onClick={() => handleRemoveClick(idx)} color="primary" variant="contained">
                                    Delete
                                    </Button> */}
                                  </Box>
                                
                                    
                                </Grid>
                                :<></>
                                }
                              </Grid>
                            </CardContent>
                          </Card>
                          
                          </>
                        
                        );
                      })}
                    </>: <></>
                  }
                  </>
                              
                        
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    {/* <Button onClick={() => handleAddClick()} color="primary" variant="contained">
                      Add More
                    </Button> */}
                  </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" p={2}>
                  <Button onClick={() => handleOpen()}   color="primary" variant="contained" style={{marginRight:"5px"}}>
                    Accept
                  </Button>
                  <Button onClick={() => handleOpen2()}   color="secondary" variant="contained" style={{marginLeft:"5px"}}>
                    Reject
                  </Button>                  
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                                   
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
        
      </Container>
    </Page>
  );
};

export default AddBusiness;
