// import axios from "../Config/Interceptor1";


import axios from "../../Config/Interceptor2";


import { Base_URL } from '../../Config/BaseUrlConfig'

import {addManpowerCategoryBegin , addManpowerCategorySuccess , addManpowerCategoryFailure } from '../ActionCreators';
import {getManpowerCategoryBegin , getManpowerCategorySuccess , getManpowerCategoryFailure} from '../ActionCreators';
import {delManpowerCategoryBegin , delManpowerCategorySuccess , delManpowerCategoryFailure} from '../ActionCreators';
import {editManpowerCategoryBegin , editManpowerCategorySuccess , editManpowerCategoryFailure} from '../ActionCreators';

export const addManpowerCategory = (session) => {

    // console.log("Gourav");
    return (dispatch) => {
        dispatch(addManpowerCategoryBegin());

        axios.post(Base_URL + '/subAdmin/addmanpowercat', session)
            .then((res) => {
                console.log(res.data)
                dispatch(addManpowerCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addManpowerCategoryFailure(err.response.data));

                }
            })
    }
}


export const getManpowerCategory = (x) => {

    return (dispatch) => {
        dispatch(getManpowerCategoryBegin());

        axios.get(Base_URL + '/subAdmin/getManpowerCat' , {params : {sub_admin_id : x}})
            .then((res) => {
                dispatch(getManpowerCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getManpowerCategoryFailure(err.response.data));

                }
            })
    }
}

export const delManpowerCategory = (x) => {

    return (dispatch) => {
        dispatch(delManpowerCategoryBegin());

        console.log(x);
        axios.get(Base_URL + '/subAdmin/delManCat' , {params :{mpc_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(delManpowerCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delManpowerCategoryFailure(err.response.data));

                }
            })
    }
}

export const editManpowerCategory = (session) => {

    return (dispatch) => {
        dispatch(editManpowerCategoryBegin());

        axios.post(Base_URL + '/subAdmin/editManpowerCat', session)
            .then((res) => {
                // console.log(res.data)
                dispatch(editManpowerCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editManpowerCategoryFailure(err.response.data));

                }
            })
    }
}
