import {LOGIN_LOADING , LOGIN_SUCCESS , LOGIN_FAILURE } from '../Actions/Types';
// import {REFRESH_TOKEN_LOADING , REFRESH_TOKEN_SUCCESS , REFRESH_TOKEN_FAILURE} from '../Actions/Types';
import {LOGOUT_LOADING , LOGOUT_SUCCESS , LOGOUT_FAILURE} from '../Actions/Types';
import {CLEAN_LOGIN_MSG} from '../Actions/Types'
import {CLEAN_ALL_DATA} from '../Actions/Types';

import {REFRESH_CLIENT_LOADING , REFRESH_CLIENT_SUCCESS , REFRESH_CLIENT_FAILURE} from '../Actions/Types';







const login = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            logoutdata:[{}],
            logouterror :{} 

        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            logoutdata:[{}],
            logouterror :{} 
            
        }
        case CLEAN_LOGIN_MSG:
            return {
                ...state,
                
                loading:false,
                error:{},
                
            }
    
    case LOGIN_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case LOGIN_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.logindata
        }
    case LOGIN_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.loginerror,
            data:{},
        }


    case REFRESH_CLIENT_LOADING:
        return {
            ...state,
            loading:true,
            error: {}
        }
    case REFRESH_CLIENT_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data: {...state.data, token: action.data}
        }
    case REFRESH_CLIENT_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error
        }



        case LOGOUT_LOADING:
            console.log("dsjdksjdksjdsjdlk90909009")
            return {
                ...state,
                loading:true,
                logouterror: {}
            }
        case LOGOUT_SUCCESS:
            console.log("tesdsjdsjdjl")
            return {
                ...state,
                loading:false,
                error:{},
                data:[{}],
                logoutdata: action.logoutdata
            }
        case LOGOUT_FAILURE:
            console.log("dsdskjddshdhdk")
            return {
                ...state,
                loading:false,
                logouterror:action.logouterror,
                logoutdata:{}
            }    

    default:
        return state






}
}



export default login;
