import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";


import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {addResourceUnit, getResourceUnit , delResourceUnit, editResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit'
import {cleanResourceUnitData} from '../../../../Actions/ActionCreators' ;
import {getExportFileBlob} from '../../../../Config/excelPdfDownload'


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  
  const role_permit = useSelector(state => state.login && state.login.data && state.login.data.role_permit && state.login.data.role_permit.master ? state.login.data.role_permit.master : []);



  const [notice, setNotice] = useState({show:false, id:""});


  const handleCloseNotice = () =>{

    setNotice({...notice, show:false, id:""});
  }
  
  const handleOpen = (data) =>{
  
    setNotice({
      ...notice,
      show:true,
      id:data.sub_unit_id
    });
  
  }
  
  const handleConfirm = () =>{
    if(notice.id!==""){
      
      dispatch(delResourceUnit(notice.id))

  
      setNotice({
        ...notice,
        show:false,
        id:""
      })
    }
  }


  const selected_site = useSelector((state) => state.SelectedSite && state.SelectedSite.sitedata && state.SelectedSite.sitedata.site ? state.SelectedSite.sitedata.site : null)


  const listData= useSelector((state) =>
  state.AddResourceUnit && state.AddResourceUnit.getData &&
    state.AddResourceUnit.getData.length &&
    Array.isArray(state.AddResourceUnit.getData)
      ? state.AddResourceUnit.getData
      : []
  );
  // const listData = AllListData.filter(data => !selected_site || data.site_id == selected_site);

const id = useSelector(state => state.login.data.r_subadmin);

const [values , setValues] = useState({

  sub_admin_id : id,
  unit_name : "",
  sub_unit_id :""
  

});
  

  const addmsg = useSelector((state) => state.AddResourceUnit.data && state.AddResourceUnit.data.status && state.AddResourceUnit.data.status === 200 ? state.AddResourceUnit.data.message : null  )
  const adderr = useSelector((state) => state.AddResourceUnit.error && state.AddResourceUnit.error.message ? state.AddResourceUnit.error.message : null  )
  const delmsg = useSelector((state) => state.AddResourceUnit && state.AddResourceUnit.delData && state.AddResourceUnit.delData.message ? state.AddResourceUnit.delData.message : null  )
  const editmsg = useSelector((state) => state.AddResourceUnit && state.AddResourceUnit.editData && state.AddResourceUnit.editData.message ? state.AddResourceUnit.editData.message : null )
  
  useEffect( () => {
  
    // dispatch(getMaterialCategory(id))
    var r  = new Promise((resolve , reject)=>{
     if(addmsg !== null && typeof(addmsg) === 'string'){
      tostS(addmsg)
     }
  
    else
    if(adderr !== null){
      tostE(adderr)
     }
    else
    if (delmsg !== null && typeof(delmsg) === 'string')
    {
      tostS(delmsg)
    }
    else
    if (editmsg !== null && typeof(editmsg) === 'string')
    {
      tostS(editmsg);
    }
  
     resolve()
    
    }) 
    r.then(()=>{
     dispatch(cleanResourceUnitData())
     dispatch(getResourceUnit(id))
    //  dispatch()
    // alert("Done");
    })
  }, [addmsg, ,adderr , delmsg , editmsg])




const navigate = useNavigate();

const onEdit = (olData) =>{
  setOpen2(true)
  setValues({...values, ['unit_name'] : olData.unit_name , ['sub_unit_id'] : olData.sub_unit_id })
  // navigate('/app/editbusiness/'+olData.sub_unit_id , { state: olData })
}


// const onDel = (oldata) =>{
//   dispatch(delResourceUnit(oldata.sub_unit_id ,oldata.tableData.id ))
// }
 




const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


const handleDateChange = (date) => {
  setSelectedDate(date);
};


const handleChanged = (event) => {
  setValues({...values, ['country']:event.target.value});
};




const [open, setOpen] = React.useState(false);
const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setValues({...values, ['unit_name'] : "" , ['sub_unit_id'] : "" })

    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);

  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
    setValues({...values, ['unit_name'] : "" , ['sub_unit_id'] : "" })

  };

  const handleChange = (event) => {
    //  console.log(event.target.value);
     setValues({
       ...values,
       [event.target.name]: event.target.value,
     });
   };
  
  const handleSubmit = () =>{
  //  console.log(values)
  var unitReg = /^[\w ]{2,}$/;
  if(unitReg.test(values.unit_name.trim()) != true   ){
    tostW("Unit Cannot contain number or symbols and should be more than 1 character!!");

  } else{
    if (values.sub_unit_id !== ''){
    
      dispatch(editResourceUnit(values))
    }
    else
    {
      dispatch(addResourceUnit(values))
    }
    setValues({
      ...values,
      name : "",
    });

    setOpen(false);
    setOpen2(false);
  }
  
}; 
  
const exportField = [
  {
    field : "sub_unit_id" , 
    val : "Unit ID"
  }, 
  {
    field : "unit_name" , 
    val : "Unit Name"
  }, 
  
]


  const addNewBornbutton = () =>{
    navigate('/app/master/unit_list_add')
    console.log("Place order")
    
  }

  return (
    <>
      <Page className={classes.root} title="Unit Master">
      <ToastContainer    />




      <Dialog disableBackdropClick={true} open={notice.show} onClose={handleCloseNotice} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Delete this Unit??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNotice} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>








        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">

              <div>
              <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'xlsx', 'Units')
              }>
                Export to Excel
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              className={classes.exportButton} 
              onClick = {
                () => getExportFileBlob(exportField,listData, 'pdf', 'Units')
              }>
                Export to PDF
            </Button>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Unit
      </Button>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Unit</DialogTitle>
        <DialogContent>
          
          <TextField
                fullWidth
                id="name"
                label="Unit Name"
                type="text"
                name = "unit_name"
                value = {values.unit_name}
                onChange = {handleChange}
                required
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Unit
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Unit</DialogTitle>
        <DialogContent>
          
          <TextField
                fullWidth
                id="name"
                label="Unit Name"
                type="text"
                name = "unit_name"
                
                value = {values.unit_name}
                onChange = {handleChange}
                required
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update 
          </Button>
        </DialogActions>
      </Dialog>

      


    </div>



            </Box>
            
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Unit Master"
      columns={[

        { title: 'Unit ID', render: rowData =>(
          rowData.tableData.id+1
          ) },
        { title: 'Unit', field: 'unit_name',editable: "never", },     
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
              {role_permit[4] ?
              <Grid item xs={4}>
                
              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }
              {role_permit[2] ?
              <Grid item xs={4}>
                <DeleteIcon onClick={()=>{handleOpen(rowData)}} style={{cursor:"pointer"}} />
              </Grid>
              :<></>
              }

            </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
