import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import Page from '../../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";



import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import tableIcons from '../../../../Config/IconsFile'
import {getSites} from '../../../../Actions/SubAdminActions/M_AddSites';
import {getVendor} from '../../../../Actions/SubAdminActions/M_Vendor';
import {getMachineCat} from '../../../../Actions/SubAdminActions/M_MachineCat';
import {getResourceMachine} from '../../../../Actions/SubAdminActions/M_ResourceMachine';
import {getMaterialCategory} from '../../../../Actions/SubAdminActions/M_MaterialCategory';
import {getMaterialMat} from '../../../../Actions/SubAdminActions/M_MaterialMat';
import {getGst} from '../../../../Actions/SubAdminActions/Gst';
import {getResourceUnit} from '../../../../Actions/SubAdminActions/M_ResourceUnit'
import {getAppUser} from '../../../../Actions/SubAdminActions/AppUserManagement'

import {acceptMacIndent, orderMachineIndent} from '../../../../Actions/SubAdminActions/Machine_Indent'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Buisness Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate();
  const listData = (state.mat_name && Array.isArray(state.mat_name) && state.mat_name.length ? state.mat_name : []);

  const AppUserData = useSelector((stateData) =>
  stateData.AppUserMgmt && stateData.AppUserMgmt.getData &&
    stateData.AppUserMgmt.getData.length &&
    Array.isArray(stateData.AppUserMgmt.getData)
      ? (stateData.AppUserMgmt.getData).filter(userData => userData.assign_role==="Site_Engineer")
      : []
  );

  // const a_id = AppUserData.length ?  AppUserData.filter(data => data.site_id==state.site_id)[0].apu_id : null


  const id = useSelector(state => state.login.data.r_subadmin);
  const name = useSelector(state => state.login &&state.login.data && state.login.data.name ? state.login.data.name:null);
  const folder = useSelector(state => state.login.data.folder_name);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  const [data, setData] = useState(listData);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  
  const [values, setValues] = useState({
  
    sub_admin_id :id,
    mchne_id: state.mchne_id,
    machine_details:"",
    message:state.notes,
    date:new Date(Date.now()),
    priority:state.priority,
    app_uid:state.app_uid,
    vd_id:"",
    gst_type:"IGST",
    accepted_by:name,
    site_id:state.site_id,   
    lat:"",
    long:"",
    folder_name:folder,
    order_id:state.order_id,
    img:"", 
    fixImg : "/static/images/avatars/upload.png",

  });

  const handleClose = () =>{
    setValues({
      ...values,
      ['machine_details']:"",
      
    })
    setOpen(false);
    setOpen2(false);
    
  }

const handleOpen = () =>{


  var flag = true;
              
              
    for(let a=0;a<MacList.length;a++){
      if(MacList[a]['mac_type_id']!==""){
        if(MacList[a]['mac_id']!==""){               
          if(MacList[a]['quan']!==""){
            if(MacList[a]['unit_id']!==""){
              console.log("All Validation Done")

            }else{
              flag = false;                  
              tostE(`Please Select unit for ${a+1}st Purchase item`);
              break;
      
            }
          }else{
            flag = false;                
            tostE(`Please Enter Quantity for ${a+1}st Purchase item`);
            break;
    
          }
        }else{
          flag = false;              
          tostE(`Please Select Machine for ${a+1}st Purchase item`);
          break;
  
        }
      }else{
        flag = false;            
        tostE(`Please Select Category for ${a+1}st Purchase item`);
        break;

      }   
    }
  
    if (flag){

      setValues({
        ...values,
        ['machine_details']:MacList,
        
      })
      setOpen(true)
    } 

}

const handleOpen2 = () =>{
  setValues({
    ...values,
    ['status']:"Rejected"
  })
  setOpen2(true)
  
}


const handleReject = () =>{
  const SData = {
      
    status : "Rejected",
    accepted_by : values.accepted_by,      
    indent_id : values.mchne_id
 }
  dispatch(acceptMacIndent(SData))
  navigate("/app/machineindentlist")
  setOpen2(false);

}

const handleAccept = () =>{
  if(values.vd_id!=="" && values.vd_id!==null && values.vd_id!==undefined){

    if(values.machine_details!=""){
  
      const FData = {
    
        sub_admin_id :values.sub_admin_id,
        mchne_id: values.mchne_id,
        machine_details:MacList,
        message:values.message,
        date:values.date,
        priority:values.priority,
        app_uid:values.app_uid,
        vd_id:values.vd_id,
        gst_type:values.gst_type,
        accepted_by:values.accepted_by,
        site_id:values.site_id,   
        lat:values.lat,
        long:values.long,
        folder_name:values.folder_name,
        order_id:values.order_id,
        img:values.img, 
    
      }
      dispatch(orderMachineIndent(FData))
      navigate("/app/machineindentlist")
  
    }else{
      tostE("Order Can't be generated!!")
    }

  }else{
    tostE("Please Select a vendor !!")
  }




  setOpen(false);

}


  

const handleChangeGstType = (event) =>{
  setValues({
    ...values,
    ['gst_type']:event.target.value
  })
}




const handleChangePriority = (event) => {
  setValues({
    ...values,
    ['priority']: event.target.value,
  });
};

const handleChangeSite = (event) => {
  setValues({...values, ['site_id']:event.target.value});
};
 
const handleDateChange = (date) => {
  setValues({
    ...values,
    ['date']:date
  })
};

const handleChangeVendor = (event) => {
  setValues({...values, ['vd_id']:event.target.value});


};

const handleChange = (event) => {
  setValues({...values, [event.target.name]:event.target.value});
};

const SiteData = useSelector((state) =>
state.AddSites && state.AddSites.getData &&
  state.AddSites.getData.length &&
  Array.isArray(state.AddSites.getData)
    ? state.AddSites.getData
    : []
);



const VendorData = useSelector((state) =>
state.AddVendor && state.AddVendor.getData &&
  state.AddVendor.getData.length &&
  Array.isArray(state.AddVendor.getData)
    ? state.AddVendor.getData
    : []
);

const UnitData = useSelector((state) =>
state.AddResourceUnit && state.AddResourceUnit.getData &&
  state.AddResourceUnit.getData.length &&
  Array.isArray(state.AddResourceUnit.getData)
    ? state.AddResourceUnit.getData
    : []
);


const GstData = useSelector((state) =>
state.SubGst && state.SubGst.getData &&
  state.SubGst.getData.length &&
  Array.isArray(state.SubGst.getData)
    ? state.SubGst.getData
    : []
);

const MatCatData = useSelector((state) =>
state.AddMaterialCategory && state.AddMaterialCategory.getData &&
  state.AddMaterialCategory.getData.length &&
  Array.isArray(state.AddMaterialCategory.getData)
    ? state.AddMaterialCategory.getData
    : []
);

const MatData = useSelector((state) =>
state.MaterialMatMaster && state.MaterialMatMaster.getData &&
  state.MaterialMatMaster.getData.length &&
  Array.isArray(state.MaterialMatMaster.getData)
    ? state.MaterialMatMaster.getData
    : []
);



const MacCatData = useSelector((state) =>
state.MachineCat && state.MachineCat.getData &&
  state.MachineCat.getData.length &&
  Array.isArray(state.MachineCat.getData)
    ? state.MachineCat.getData
    : []
);



const MacData = useSelector((state) =>
state.AddResourceMachine && state.AddResourceMachine.getData &&
  state.AddResourceMachine.getData.length &&
  Array.isArray(state.AddResourceMachine.getData)
    ? state.AddResourceMachine.getData
    : []
);


useEffect(() => {
  dispatch(getSites(id))
  dispatch(getMachineCat(id))
  dispatch(getResourceMachine(id))
  dispatch(getGst())
  dispatch(getAppUser(id))
  dispatch(getVendor(id))

  
}, [])


   // ******************* Dynamic Machine Details ************************ //
   
   var getMacListData = state.machine_details;
    for(let i=0;i<getMacListData.length;i++){
      const selected_id = getMacListData[i]['mac_type_id']
      getMacListData[i]['getData']=MacData.filter(m_type => m_type.mch_cat_id==selected_id);
    }
   
   const [MacList, setMacList] = useState(getMacListData)


// handle input change 

const handleChangeMacInput = (event, idx) =>{
const list = [...MacList];
const index = idx;  
const field_name = event.target.name;  
list[index][field_name] = event.target.value;     
setMacList([...list])

}


const handleChangeMacQuantity = (event, idx) =>{
const list = [...MacList];
const index = idx;  
list[index]["quan"] = event.target.value;     
list[index]["new_quan"] = event.target.value;     
setMacList([...list])

}



const handleMacCatChange = (event, idx) =>{
var list = [...MacList];
const index = idx;
const selected_id = event.target.value;
var mac_type_insert =  MacCatData.filter(type_name => type_name.mch_id==selected_id)[0].name;
list[index].mac_type_id = event.target.value;
list[index].mac_type = mac_type_insert;
list[index].getData = MacData.filter(m_type => m_type.mch_cat_id==selected_id)
setMacList([...list])


// dispatch(getPurchaseMatName(id, event.target.value))
}

const handleMacChange = (event, idx) =>{
const list = [...MacList];
const index = idx;
var mac_insert =  MacData.filter(type_name => type_name.sub_machine_id==event.target.value)[0].machine_name;
list[index].mac_id = event.target.value;
list[index].mac_name = mac_insert;
setMacList([...list])

}


const handleMacGstChange = (event, idx) =>{
const list = [...MacList];
const index = idx;
var gst_insert =  GstData.filter(type_name => type_name.gst_id==event.target.value)[0].percent;
list[index].gst = event.target.value;
list[index].gst_per = gst_insert;

setMacList([...list])

}


// handle click event of remove


const handleMacRemoveClick = (index) => {
const list = [...MacList];
list.splice(index, 1);
setMacList(list);
};

// handle click of add button

const handleMacAddClick = () => {

setMacList([
...MacList, 
{
amt: "",
amt_error: false,
gst: "",
gst_error: false,
gst_per: "",
mac_error: false,
mac_id: "",
mac_name: "",
mac_type: "",
mac_type_error: false,
mac_type_id: "",
quan: "",
new_quan:"",
quan_error: false,
getData : []
}
])
}




  return (
    <Page className={classes.root} title="Order Machine" >
      <ToastContainer    />

      

      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to place this order??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleAccept} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

      

      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to Reject??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleReject} color="primary" variant="outlined">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3}>         
          <Grid item lg={12} md={12} xs={12} >
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest} >
              <Card>
                <CardHeader subheader={"Order Id : "+state.order_id} title="Machine Indent Order" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>                    
                    <Grid item md={12} xs={12}>
                      <Grid item md={6} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            margin="normal"
                            style={{ border: "1px  black" }}
                            id="date-picker-dialog"
                            label="Date"
                            format="dd.MM.yyyy"
                            value={values.date}
                            inputVariant="outlined"
                            onChange={handleDateChange}
                            variant="outlined"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Site</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.site_id}
                          onChange={handleChangeSite}
                          label="Site"
                          style={{margin:0}}
                        >
                          {SiteData.map((data , i)=>
                            <MenuItem key={i}  value={data.site_id}>{data.site_name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Vendor</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.vd_id}
                          onChange={handleChangeVendor}
                          label="Vendor"
                          style={{margin:0}}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          {VendorData.map((data , i)=>
                            <MenuItem key={i}  value={data.vd_id}>{data.name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    
                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel component="legend">GST Type</FormLabel>
                      <RadioGroup row aria-label="GST Type" value={values.gst_type} onChange={(e) => handleChangeGstType(e)} name="row-radio-buttons-group">
                        <FormControlLabel value="IGST" control={<Radio size="small" color={"primary"} />} label="IGST" />
                        <FormControlLabel value="CGST+SGST" control={<Radio size="small" color={"primary"} />} label="CGST+SGST" />                  
                      </RadioGroup>
                    </FormControl>
                  </Grid>


                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Priority</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.priority}
                          onChange={handleChangePriority}
                          label="Priority"
                        >
                          <MenuItem value="Low">Low</MenuItem>
                          <MenuItem value="Medium">Medium</MenuItem>
                          <MenuItem value="High">High</MenuItem>
                          
                        </Select>
                      </FormControl>
                    </Grid>
                    
                  </Grid>

                </CardContent>
                <Divider />
                
                <>     
                  {MacList && MacList.length > 0 && Array.isArray(MacList) ?
                    <>
                      { MacList.map((field, idx) => {
                        return (
                          <>
                          <Card style={{border:"1px solid #db630b", marginBottom:"5px", marginTop:"5px"}}>
                            <CardContent style={{borderBottomWidth:1 , borderBottomColor:"black"}}>
                              <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Machine Category</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.mac_type_id}
                                      onChange={e => handleMacCatChange(e,idx)}
                                      label="Machine Category"
                                      style={{margin:0}}
                                    >
                                      
                                      {MacCatData.map((data , i)=>
                                        <MenuItem key={i}  value={data.mch_id}>{data.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Machine Name</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.mac_id}
                                      onChange={e => handleMacChange(e,idx)}
                                      label="Machine Name"
                                    >
                                      {field.getData.map((data , i)=>
                                        <MenuItem key={i}  value={data.sub_machine_id}>{data.machine_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Quantity"
                                    name="quan"
                                    type="number"  InputProps={{inputProps:{min:0}}}
                                    onChange={e => handleChangeMacQuantity(e, idx)}
                                    required                                    
                                    value={field.quan}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Amount"
                                    name="amt"
                                    type="number"  
                                    InputProps={{inputProps:{min:0}}}
                                    onChange={e => handleChangeMacInput(e, idx)}
                                    required
                                    value={field.amt}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">GST</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={field.gst}
                                      onChange={e => handleMacGstChange(e,idx)}
                                      label="GST"
                                      style={{margin:0}}
                                    >
                                      {GstData.map((data , i)=>
                                        <MenuItem key={i}  value={data.gst_id}>{data.percent}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {MacList.length>1?
                                
                                <Grid item md={4} xs={12}>
                                  <Box display="flex" justifyContent="flex-end" p={2}>
                                    <Button onClick={() => handleMacRemoveClick(idx)} color="primary" variant="contained">
                                    Delete
                                    </Button>
                                  </Box>
                                
                                    
                                </Grid>
                                :<></>
                                }
                              </Grid>
                            </CardContent>
                          </Card>
                          
                          </>
                        
                        );
                      })}
                    </>: <></>
                  }
                  </>
                              
                        
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={() => handleMacAddClick()} color="primary" variant="contained">
                      Add More
                    </Button>
                  </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" p={2}>
                  <Button onClick={() => handleOpen()}   color="primary" variant="contained" style={{marginRight:"5px"}}>
                    Create Order
                  </Button>
                  {state.status==="Pending"?
                  
                     <Button onClick={() => handleOpen2()}   color="secondary" variant="contained" style={{marginLeft:"5px"}}>
                     Reject
                     </Button>                  
                  :<></>
                  }                
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                                   
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
        
      </Container>
    </Page>
  );
};

export default AddBusiness;
