import React from 'react';
import { Page, Document, StyleSheet, Text } from '@react-pdf/renderer';

import Header from './Header';
import Bill from './Bill';
import InvoiceItemTable from './Table';
import InvoiceTableFooter from './InvoiceTableFooter'


const styles = StyleSheet.create({
   page: {
       fontFamily: 'Helvetica',
       fontSize: 11,
       paddingTop: 10,
       paddingLeft:10,
       paddingRight:10,
       lineHeight: 1.5,
       flexDirection: 'column',
       padding:"5%"
   }, 
   
 });


const Invoice = ({invoice})=>(
   <Document>
       <Page size="A4" style={styles.page}>                    
         <Header data={invoice} />
         <Bill data={invoice} />
         <InvoiceItemTable invoice={invoice} />
         <InvoiceTableFooter items={invoice.items} gst_type={invoice.gst_type} />
         
         <Text style={{textAlign:"center", marginTop:'5%'}}>If you have any questions about this purchase order, please contact</Text>
       </Page>
   </Document>
);

export default Invoice;