import {GET_SUPER_TICK_LOADING , GET_SUPER_TICK_SUCCESS , GET_SUPER_TICK_FAILURE } from '../../Actions/Types';
import {DEL_SUPER_TICK_LOADING , DEL_SUPER_TICK_SUCCESS , DEL_SUPER_TICK_FAILURE } from '../../Actions/Types';
import {EDIT_SUPER_TICK_LOADING , EDIT_SUPER_TICK_SUCCESS , EDIT_SUPER_TICK_FAILURE } from '../../Actions/Types';


import {CLEAN_ALL_DATA, CLEAN_SUPER_TICK_DATA} from '../../Actions/Types';





const AddTick = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getData:[],
            getError:{},
            delData:[{}],
            delError:{},
            editData:[{}],
            editError:{}
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            getData:[],
            getError:{},
            editData:[],
            editError:{}
        }

    
        case CLEAN_SUPER_TICK_DATA:
            return {
                ...state,
                loading:false,                
                getData:[],
                getError:{},
                delData : [], 
                delError:{},
                editData:[],
                editError:{}
            }



        case GET_SUPER_TICK_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                
            }
        case GET_SUPER_TICK_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                getData:action.data
            }
        case GET_SUPER_TICK_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                data:{},
            }   

        case DEL_SUPER_TICK_LOADING:
            return {
                ...state,
                loading:true,
                delData:[{}],
                delError: {},
                
            }
        case DEL_SUPER_TICK_SUCCESS:
            return {
                ...state,
                loading:false,
                delError:{},
                delData:action.data,
            }
        case DEL_SUPER_TICK_FAILURE:
            return {
                ...state,
                loading:false,
                delError:action.error,
                delData:{},
            }   

        case EDIT_SUPER_TICK_LOADING:
            return {
                ...state,
                loading:true,
                editData:[{}],
                editError: {},
                
            }
        case EDIT_SUPER_TICK_SUCCESS:
            return {
                ...state,
                loading:false,
                editError:{},
                editData:action.data,
            }
        case EDIT_SUPER_TICK_FAILURE:
            return {
                ...state,
                loading:false,
                editError:action.error,
                editData:{},
            }   


    default:
        return state

}
}



export default AddTick;
