import React, { useState, useEffect } from "react";
import axios from "../../../Config/Interceptor2";
import { Base_URL } from "../../../Config/BaseUrlConfig";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import { useSelector } from "react-redux";
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  CardHeader,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../../../src/components/Page"; // Ensure the correct import path
import { ToastContainer } from "react-toastify";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const EditBoq = () => {
  const id = useSelector((state) => state.login.data.r_subadmin);
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [values, setValues] = useState({
    item_description: "",
    quantity: "",
    unit: "",
    unit_price: "",
    total_price: "",
  });

  useEffect(() => {
    if (state) {
      setValues({
        item_description: state.item_description || "",
        quantity: state.quantity || "",
        unit: state.unit || "",
        unit_price: state.unit_price || "",
        total_price: state.total_price || "",
      });
    }
  }, [state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const { item_description, quantity, unit, unit_price, site_id } = values;
    if (!item_description) {
      tostE("Item description is required");
      return;
    }

    if (!quantity || isNaN(quantity) || quantity <= 0) {
      tostE("Valid quantity is required");
      return;
    }

    if (!unit) {
      tostE("Unit is required");
      return;
    }

    if (!unit_price || isNaN(unit_price) || unit_price <= 0) {
      tostE("Valid unit price is required");
      return;
    }

    const updatedBoq = {
      ...values,
      sub_admin_id: id,
      boq_id: state.boq_id,
      site_id: state.site_id,
    };

    axios
      .post(`${Base_URL}/subadmin/updateBoq`, updatedBoq)
      .then((response) => {
        if (response.data.status === 200) {
          navigate("/app/boq");
          tostS("Editing successful");
        } else {
          tostE("Failed to update BOQ: " + response.data.message);
        }
      })
      .catch((err) => {
        tostE("Error updating BOQ: " + err.message);
      });
  };

  return (
    <Page className={classes.root} title="Edit BOQ">
      <ToastContainer />
      <Container maxWidth="lg" style={{ marginTop: "50px" }}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <form autoComplete="off" noValidate className={clsx(classes.root)}>
              <Card>
                <CardHeader subheader="" title="Edit BOQ" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Item Description"
                        name="item_description"
                        required
                        value={values.item_description}
                        variant="outlined"
                        onChange={handleChange}
                        multiline
                       
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Quantity"
                        name="quantity"
                        required
                        value={values.quantity}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Unit"
                        name="unit"
                        required
                        value={values.unit}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Unit Price"
                        name="unit_price"
                        required
                        value={values.unit_price}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Total Price"
                        name="total_price"
                        required
                        value={values.total_price}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid> */}
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default EditBoq;
