// import axios from "../Config/Interceptor1";


// import axios from 'axios'
import axios from '../../Config/Interceptor1'


import { Base_URL } from '../../Config/BaseUrlConfig'

import {manpowerReportBegin , manpowerReportSuccess , manpowerReportFailure} from '../ActionCreators'


export const manpowerReport = (x) => {
   
    return (dispatch) => {
        dispatch(manpowerReportBegin());

        axios.get(Base_URL + '/client/getReportMan' , {params :x} )
            .then((res) => {
                dispatch(manpowerReportSuccess(res.data));
                console.log("data======", res.data)
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")
                    dispatch(manpowerReportFailure({}));

                }
                else {
                    dispatch(manpowerReportFailure(err.response.data));

                }
            })
    }
}


