import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../../Config/IconsFile'

import {AddBox , ArrowDownward} from '@material-ui/icons'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";

import DialogContentText from '@material-ui/core/DialogContentText';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {addFaq, getFaq, editFaq, delFaq} from '../../../Actions/SuperAdminActions/Faq'
import {cleanFaqData} from '../../../Actions/ActionCreators'


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root3: {
    flexGrow: 1,
    
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  const [expanded, setExpanded] = React.useState(false);
  
  const handleChangeacc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  const listData = useSelector((state) =>
  state.AddFaq && state.AddFaq.getData &&
    state.AddFaq.getData.length &&
    Array.isArray(state.AddFaq.getData)
      ? state.AddFaq.getData
      : []
  );  

  const [values, setValues] = useState({
    faq_id:"",
    question:"",
    answer:"",
  
  
    });
  
    const Delmsg = useSelector(state => state.AddFaq && state.AddFaq.delData && state.AddFaq.delData.message ? state.AddFaq.delData.message : null  )
    const Delerror = useSelector(state => state.AddFaq && state.AddFaq.delError && state.AddFaq.delError.message ? state.AddFaq.delError.message : null  )
    const addmsg = useSelector(state => state.AddFaq && state.AddFaq.data && state.AddFaq.data.message ? state.AddFaq.data.message : null  )
    const adderr = useSelector(state => state.AddFaq && state.AddFaq.error && state.AddFaq.error.message ? state.AddFaq.error.message : null  )
    const editmsg = useSelector(state => state.AddFaq && state.AddFaq.editData && state.AddFaq.editData.message ? state.AddFaq.editData.message : null )
    
useEffect( () => {

  // dispatch(getBusiness())


  let x  = new Promise((resolve ,reject)=>{
    if(addmsg !== null && typeof(addmsg) === 'string'){
      tostS(addmsg)
     }
  
    else
    if(adderr !== null){
      tostE(adderr)
     }
    else
    if(Delmsg && typeof(Delmsg) === 'string')
    {
      tostS(Delmsg)
    }
    else
    if (editmsg !== null && typeof(editmsg) === 'string')
    {
      tostS(editmsg);
    }
    else
    if(Delerror && typeof(Delerror) === 'string')
    {
        tostE(Delerror)
    }

    resolve()

  })
  x.then(()=>{

  dispatch(cleanFaqData())
  dispatch(getFaq())

  })


  
}, [ Delmsg  ,Delerror, addmsg, adderr, editmsg ])


const navigate = useNavigate();

const onEdit = (olData) =>{
  setOpen2(true)
  setValues({
    ...values,
    ['question']:olData.question,
    ['faq_id']:olData.faq_id,
    ['answer']:olData.answer
  })
  // navigate('/app/editbusiness/'+olData.bp_id , { state: olData })
}


  
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  
  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  
    const handleClickOpen = () => {


      setValues({
        ...values,
        ['question']:"",
        ['answer']:"",
        ['faq_id']:""
      })
      setOpen(true);
  
    };
    const handleClickOpen2 = () => {
      setOpen2(true);
  
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleClose2 = () => {
      setOpen2(false);
      setValues({
        ...values,
        ['faq_id']:"",
        ['question']:"",
        ['answer']:""
      })
  
    };
  
    const handleChange = (event) => {
      //  console.log(event.target.value);
       setValues({
         ...values,
         [event.target.name]: event.target.value,
       });
     };
  
     const handleSubmit = () =>{
      console.log(values);
     
      if (values.faq_id !== ''){
        dispatch(editFaq(values))
      }
      else
      {

        if(!values.question || !values.answer){
          tostE('Enter both fields')
          return
        }
        dispatch(addFaq(values))
      }
      setValues({
        ...values,
        question : "",
        answer : "",
      });
    setOpen(false);
    setOpen2(false);
    }; 
      


    const [open1, setOpen1] = useState({show:false, id:""});

    const handleClose1 = () =>{
  
      setOpen1({...open1, show:false, id:""});
    }
    
    const handleOpen1 = (data) =>{
    
      setOpen1({
        ...open1,
        show:true,
        id:data.faq_id
      });
    
    }
  
    const handleConfirm1 = () =>{
      if(open1.id!==""){
        
        dispatch(delFaq(open1.id))
  
    
        setOpen1({
          ...open1,
          show:false,
          id:""
        })
      }
    }
  


//   const addNewBornbutton = () =>{
//     navigate('/app/master/draw_cat_list_add')
//     console.log("Place order")
    
//   }

  return (
    <>
      <Page className={classes.root} title="Drawing Type">
      <ToastContainer    />

      <Dialog disableBackdropClick={true} open={open1.show} onClose={handleClose1} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
            <DialogContent>
              <Typography variant="h6">
                Are you sure you want to Delete this FAQ??
              </Typography>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose1} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleConfirm1} color="primary" variant="outlined">
                Confirm
              </Button>
            </DialogActions>
        </Dialog>

        <Container maxWidth={false}>          
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add FAQ
      </Button>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">FAQ</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add FAQ
          </DialogContentText>
          
          <TextField
                fullWidth
                multiline
                rows={3}
                id="question"
                label="Question"
                type="text"
                name="question"
                value={values.question}
                onChange={handleChange}
                required
                variant="outlined"
              />
              <br /> <br />
          <TextField
                fullWidth
                multiline
                rows={3}
                id="answer"
                label="Answer"
                type="text"
                name="answer"
                value={values.answer}
                onChange={handleChange}
                required
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add FAQ
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">FAQ</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit FAQ
          </DialogContentText>
          <TextField
                fullWidth
                multiline
                row={3}
                id="question"
                label="Question"
                type="text"
                name="question"
                value={values.question}
                onChange={handleChange}
                required
                variant="outlined"
              />
              <br /> <br />
          <TextField
                fullWidth
                multiline
                row={3}
                id="answer"
                label="Answer"
                type="text"
                name="answer"
                value={values.answer}
                onChange={handleChange}
                required
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update FAQ
          </Button>
        </DialogActions>
      </Dialog>
    </Box>         
  </div>

  <Card style={{marginTop:"3%"}}>
        <CardHeader subheader="" title="FAQ List" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
           
          <div className={classes.root3} >
      
      {listData.map((item, index) => (
        
        <Accordion expanded={expanded === 'panel'+index} onChange={handleChangeacc('panel'+index)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Question - {index+1}</Typography>
          <Typography className={classes.secondaryHeading} >{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          
          <Grid container className={classes.root2}>
            <Grid item xs={12} md={12}>
            <Typography>
              {item.answer}
            </Typography>
            </Grid>

            <Grid item xs={10} md={10}>        
            </Grid>
            <Grid item xs={1} md={1}>
              <EditIcon onClick={()=>{onEdit(item)}} style={{cursor:"pointer"}} />
              
            </Grid>
            <Grid item xs={1} md={1}>
              
              <DeleteIcon onClick={()=>{handleOpen1(item)}} style={{cursor:"pointer"}} />
            </Grid>
          </Grid>
        </AccordionDetails>
        </Accordion>

        
      ))}
      
      
      
    </div>
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {/* <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button> */}
        </Box>
      </Card>

  </Container>
  </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
